import { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import Select from "react-select";
import {
	STAR_RATING_TOOLTIP_ARRAY,
	STAR_RATING_FILLCOLOR_ARRAY,
	STAR_RATING_STAR_SIZE,
	COMMENT_TEXTAREA_MAXLENGTH,
	FIRST_NAME_MAXLENGTH,
	LAST_NAME_MAXLENGTH,
	ADDRESS_MAXLENGTH,
	EMAIL_MAXLENGTH,
	EMAIL_POLICY,
	REGEX_ALPHABET
} from "../config/commonConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faHotel
} from "@fortawesome/free-solid-svg-icons";
import CommonButton from "../components/commonComponents/CommonButton";
import { Rating } from 'react-simple-star-rating';
import InputGroup from "react-bootstrap/InputGroup";
import ExitButton from "../components/commonComponents/ExitButton";
import Switch from "react-switch";
import { useTranslation } from "react-i18next";
import { COUNTRY_NAME_ARRAY } from "../config/countryConstant";
import { useSelector } from "react-redux";
import { selectedMarket as currentMarket, selectedMarketId as SMID } from "../redux/marketRedux";
import { alphabetValidation, divRef, getLoungeTypeAccordingMarket, getMarketLoungeArray, getProductDropDownAccordingMarket, renderErrorMessages, scrollToDiv, translateArray } from "../components/commonComponents/commonFunctions";
import { addcommentcard } from "../redux/apiCalls";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/commonComponents/Loader";
import { showError } from "../components/commonComponents/Swal";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessages from "../components/commonComponents/ErrorMessages";
import CommonTextArea from "../components/commonComponents/CommonTextArea";


function Feedback() {
	const { t } = useTranslation(["common", "feedback"]);
	const selectedMarketId = useSelector(SMID);
	const LOUNGE_TYPE = getLoungeTypeAccordingMarket(selectedMarketId)
	const [countryName, setCountryName] = useState({ value: COUNTRY_NAME_ARRAY[0], error: "", });
	const [lounge, setLounge] = useState({ value: "", error: "", });
	const [loungeType, setLoungeType] = useState({ value: LOUNGE_TYPE[0], error: "", });

	const selectedMarket = useSelector(currentMarket);
	const marketLoungenames = getMarketLoungeArray(selectedMarket?.airports);
	const validationSchema = Yup.object().shape({
		lounge: Yup.object().required(t("feedback:errors.lounge.required")),
		loungeType: Yup.object().required(t("feedback:errors.loungeType.required")),
		customerService: Yup.string().required(t("feedback:errors.customerService.required")),
		facilities: Yup.string().required(t("feedback:errors.facilities.required")),
		cleanliness: Yup.string().required(t("feedback:errors.cleanliness.required")),
		foodandBar: Yup.string().required(t("feedback:errors.foodandbar.required")),
		businessCentre: Yup.string().required(t("feedback:errors.businesscentre.required")),
		culture: Yup.string().required(t("feedback:errors.culture.required")),
		priceValue: Yup.string().required(t("feedback:errors.pricevalue.required")),
		overallExperience: Yup.string().required(t("feedback:errors.overallexperience.required")),
		comments: Yup.string().trim().required(t("feedback:errors.comments.required")),
		firstName: Yup.string().trim().required(t("feedback:errors.firstName.required")),
		lastName: Yup.string().trim().required(t("feedback:errors.lastName.required")),
		address1: Yup.string().trim().required(t("feedback:errors.address1.required")),
		address2: Yup.string().trim().required(t("feedback:errors.address2.required")),
		email: Yup.string().trim().required(t("feedback:errors.email.required")).matches(EMAIL_POLICY, t("feedback:errors.email.valid")),
		country: Yup.object().required(t("feedback:errors.country.required")),
	});
	const formik = useFormik({
		initialValues: {
			lounge: "",
			loungeType: "",
			firstName: "",
			lastName: "",
			address1: "",
			address2: "",
			email: "",
			country: "",
			comments: "",
			enterDrawing: false,
			acceptEmail: false,
			customerService: "",
			facilities: "",
			cleanliness: "",
			foodandBar: "",
			businessCentre: "",
			culture: "",
			priceValue: "",
			overallExperience: ""
		},
		validationSchema,
		onSubmit: () => handleSubmit(formik.values),
	});

	useEffect(() => {
		// setlounge(marketLoungenames);
	}, []);
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (formik) => {
		// let tmplounge = lounge.value
		// let tmploungetype = loungeType.value
		// let tmpcountry = countryName.value;
		const data = {
			"lounge": formik.lounge.value,
			"loungetype": formik.loungeType.value,
			"comments": formik.comments,
			"enterdrawing": formik.enterDrawing ? "Y" : "N",
			"acceptemail": formik.acceptEmail ? "Y" : "N",
			"guestinfo": {
				"firstname": formik.firstName,
				"lastname": formik.lastName,
				"email": formik.email,
				"address1": formik.address1,
				"address2": formik.address2,
				"country": formik.country.value
			},
			"loungeratings": {
				"customerservice": formik.customerService,
				"facilities": formik.facilities,
				"cleanliness": formik.cleanliness,
				"foodandbar": formik.foodandBar,
				"businesscentre": formik.businessCentre,
				"culture": formik.culture,
				"value": formik.priceValue,
				"overallexperience": formik.overallExperience
			}
		};

		if(location?.pathname === "/feedback/qrcode"){
			data.source = "QRCODE"
		}

		try {
			setLoading(true);
			const response = await addcommentcard(dispatch, data);

			if (response.status === 0) {
				const successInfo = {
					messageHeading: t("feedback:commentCard"),
					successMessage: t('feedback:successMessage')
				};

				navigate("/common-success", { state: successInfo });
			} else {
				showError(t("error"), response.statusMessage);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}

	}
	return (
		<>
			<section>
				<Container className="my-4" ref={divRef}>
					<Loader loading={loading} message={t('feedback:loaderMessage')} />
					<h3 className="my-3 d-flex justify-content-center heading">
						<span className="heading">{t('feedback:feedback')}</span>
					</h3>
					<Row className="my-3 d-flex justify-content-around align-content-left">
						<Col md={6}></Col>
						<Col md={6} className="d-flex justify-content-end">
							<ExitButton />
						</Col>
					</Row>

					<Card className="my-3"  >
						<Card.Body>
						<h6 className="field mb-3">
							{t('fillAllFields')}
						</h6>
							<Form onSubmit={(e)=>scrollToDiv(e,formik)}>
								<ErrorMessages formik={formik} />
								<Row className="my-1 d-flex justify-content-between">
									<Col md={6} sm={12}>
										<Form.Label className="d-flex">
											<FontAwesomeIcon
												icon={faHotel}
												size="lg"
												className="me-2 icon-color"
											/>
											{t('loungeType')} <span className="field">*</span>
										</Form.Label>
										<Form.Group className="">
											<Select
												options={LOUNGE_TYPE}
												name="loungeType"
												value={formik.values.loungeType}
												onChange={(d) => formik.setFieldValue('loungeType', d)}
												onBlur={(d) => formik.handleBlur}
												className={formik.touched.loungeType && formik.errors.loungeType ? " error rounded-2" : ""}
											/>
										</Form.Group>
									</Col>
									<Col md={6} sm={12}>
										<Form.Label className="d-flex mt-2 mt-md-0">
											<FontAwesomeIcon
												icon={faHotel}
												size="lg"
												className="me-2 icon-color"
											/>
											{t('selectLounge')} <span className="field">*</span>
										</Form.Label>
										<Form.Group className="">
											<Select
												options={marketLoungenames}
												name="lounge"
												value={formik.values.lounge}
												onChange={(d) => formik.setFieldValue('lounge', d)}
												onBlur={(d) => formik.handleBlur}
												className={formik.touched.lounge && formik.errors.lounge ? " error rounded-2" : ""}

											/>

										</Form.Group>
									</Col>
								</Row>

								<h6 className="my-3">
									{t('feedback:enterFeedback')}<span className="field">*</span>
								</h6>
								<Row className="my-1">
									<Col md={2} sm={12}>
										<h6 className="mt-3">{t('feedback:customerService')}</h6>
									</Col>
									<Col md={4} sm={12} className="">
										<Rating
											name="customerService"
											value={formik.values.customerService}
											size={STAR_RATING_STAR_SIZE}
											tooltipClassName="badge-l bg-white text-dark ms-0 ms-0"
											showTooltip
											tooltipDefaultText=""
											tooltipArray={translateArray(STAR_RATING_TOOLTIP_ARRAY, "STAR_RATING_TOOLTIP_ARRAY")}
											fillColorArray={STAR_RATING_FILLCOLOR_ARRAY}
											onClick={(d) => formik.setFieldValue('customerService', d)}
										/>
									</Col>
									<Col md={2} sm={12}>
										<h6 className="mt-3">{t('feedback:loungeFacilities')}</h6>
									</Col>
									<Col md={4} sm={12} className="">
										<Rating
											name="facilities"
											value={formik.values.facilities}
											size={STAR_RATING_STAR_SIZE}
											tooltipClassName="badge-l bg-white text-dark ms-0"
											showTooltip
											tooltipDefaultText=""
											tooltipArray={translateArray(STAR_RATING_TOOLTIP_ARRAY, "STAR_RATING_TOOLTIP_ARRAY")}
											fillColorArray={STAR_RATING_FILLCOLOR_ARRAY}
											onClick={(d) => formik.setFieldValue('facilities', d)}
										/>
									</Col>
								</Row>
								<Row className="my-1">
									<Col md={2} sm={12}>
										<h6 className="mt-3">{t('feedback:cleanlinessFacilities')}</h6>
									</Col>
									<Col md={4} sm={12} className="">
										<Rating
											name="cleanliness"
											onClick={(d) => formik.setFieldValue('cleanliness', d)}
											value={formik.values.cleanliness}
											size={STAR_RATING_STAR_SIZE}
											tooltipClassName="badge-l bg-white text-dark ms-0 "
											showTooltip
											tooltipDefaultText=""
											tooltipArray={translateArray(STAR_RATING_TOOLTIP_ARRAY, "STAR_RATING_TOOLTIP_ARRAY")}
											fillColorArray={STAR_RATING_FILLCOLOR_ARRAY}
										/>
									</Col>
									<Col md={2} sm={12}>
										<h6 className="mt-3">{t('feedback:snackBarService')} </h6>
									</Col>
									<Col md={4} sm={12} className="">
										<Rating
											name="foodandBar"
											onClick={(d) => formik.setFieldValue('foodandBar', d)}
											value={formik.values.foodandbar}
											size={STAR_RATING_STAR_SIZE}
											tooltipClassName="badge-l bg-white text-dark ms-0 "
											showTooltip
											tooltipDefaultText=""
											tooltipArray={translateArray(STAR_RATING_TOOLTIP_ARRAY, "STAR_RATING_TOOLTIP_ARRAY")}
											fillColorArray={STAR_RATING_FILLCOLOR_ARRAY}
										/>
									</Col>
								</Row>
								<Row className="my-1">
									<Col md={2} sm={12}>
										<h6 className="mt-3">{t('feedback:businessCenter')} </h6>
									</Col>
									<Col md={4} sm={12} className="">
										<Rating
											name="businessCentre"
											onClick={(d) => formik.setFieldValue('businessCentre', d)}
											value={formik.values.businessCentre}
											size={STAR_RATING_STAR_SIZE}
											tooltipClassName="badge-l bg-white text-dark ms-0 "
											showTooltip
											tooltipDefaultText=""
											tooltipArray={translateArray(STAR_RATING_TOOLTIP_ARRAY, "STAR_RATING_TOOLTIP_ARRAY")}
											fillColorArray={STAR_RATING_FILLCOLOR_ARRAY}
										/>
									</Col>
									<Col md={2} sm={12}>
										<h6 className="mt-3">{t('feedback:culturalContent')} </h6>
									</Col>
									<Col md={4} sm={12} className="">
										<Rating
											name="culture"
											onClick={(d) => formik.setFieldValue('culture', d)}
											value={formik.values.culture}
											size={STAR_RATING_STAR_SIZE}
											tooltipClassName="badge-l bg-white text-dark ms-0"
											showTooltip
											tooltipDefaultText=""
											tooltipArray={translateArray(STAR_RATING_TOOLTIP_ARRAY, "STAR_RATING_TOOLTIP_ARRAY")}
											fillColorArray={STAR_RATING_FILLCOLOR_ARRAY}
										/>
									</Col>
								</Row>
								<Row className="my-1">
									<Col md={2} sm={12}>
										<h6 className="mt-3">{t('feedback:priceValueYourStay')}</h6>
									</Col>
									<Col md={4} sm={12} className="">
										<Rating
											name="priceValue"
											onClick={(d) => formik.setFieldValue('priceValue', d)}
											value={formik.values.priceValue}
											size={STAR_RATING_STAR_SIZE}
											tooltipClassName="badge-l bg-white text-dark ms-0 "
											showTooltip
											tooltipDefaultText=""
											tooltipArray={translateArray(STAR_RATING_TOOLTIP_ARRAY, "STAR_RATING_TOOLTIP_ARRAY")}
											fillColorArray={STAR_RATING_FILLCOLOR_ARRAY}
										/>
									</Col>
									<Col md={2} sm={12}>
										<h6 className="mt-3">{t('feedback:overallExperience')}</h6>
									</Col>
									<Col md={4} sm={12} className="">
										<Rating
											name="overallExperience"
											value={formik.values.overallExperience}
											size={STAR_RATING_STAR_SIZE}
											tooltipClassName="badge-l bg-white text-dark ms-0"
											showTooltip
											tooltipDefaultText=""
											tooltipArray={translateArray(STAR_RATING_TOOLTIP_ARRAY, "STAR_RATING_TOOLTIP_ARRAY")}
											fillColorArray={STAR_RATING_FILLCOLOR_ARRAY}
											onClick={(d) => formik.setFieldValue('overallExperience', d)}
										/>
									</Col>
								</Row>
								{/* <div className="my-2 mt-4">
									<label htmlFor="commentTextarea" className="form-label">{t('feedback:tellUsABoutYourExperience')}</label>
									<textarea className="form-control"
										name="comments"
										value={formik.values.comments}
										maxLength={COMMENT_TEXTAREA_MAXLENGTH}
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										id="commentTextarea" rows="6"></textarea>
								</div>
								{<h6 className="d-flex justify-content-end">{COMMENT_TEXTAREA_MAXLENGTH - (formik.values.comments?.length ? formik.values.comments?.length : 0)} {t('feedback:charactersRemaining')}</h6>} */}
								<CommonTextArea
									id="comments"
									name="comments"
									md={12}
									mandatory={true}
									heading={t('feedback:tellUsABoutYourExperience')}
									text={formik.values.comments}
									onBlur={(key, e) => {
										formik.handleBlur(e);
									}}
									onChange={(key, e) => {
										formik.handleChange(e);
									}}
									maxLength={COMMENT_TEXTAREA_MAXLENGTH}
									className={formik.touched.comments && formik.errors.comments ? "error" : ""}
								/>
								<Row className="my-1 px-2">
									<Col md={"auto"} sm={12} className="px-0">
										<Switch
											onChange={(d) => formik.setFieldValue('enterDrawing', d)}
											checked={formik.values.enterDrawing}
											value={formik.values.enterDrawing}
											className="react-switch"
											height={23}
										/>
									<span className="mb-0 ms-2">{t('feedback:checkQuarterlyPrizeInclude')}</span>
									</Col>
								</Row>
								<Row className="my-1 px-2">
									<Col md={"auto"} sm={12} className="px-0">
										<Switch
											onChange={(d) => formik.setFieldValue('acceptEmail', d)}
											checked={formik.values.acceptEmail}
											value={formik.values.acceptEmail}
											className="react-switch"
											height={23}
										/>  <span className="mb-0 ms-2">{t('feedback:contactWithSpecialOffer')}</span>
									</Col>
								</Row>

								<h5 className="d-flex my-3">{t('guestInformation')}</h5>
								<Row>
									<Col
										md={5}
										className="mb-3"
									>
										<Form.Label className="d-flex" htmlFor="validationCustomFirstName">
											{t('firstName')}<span className="field">*</span>
										</Form.Label>
										<InputGroup className="d-flex">
											<Form.Control
												name="firstName"
												type="text"
												id="validationCustomFirstName"
												placeholder={t('firstName')}
												maxLength={FIRST_NAME_MAXLENGTH}
												aria-describedby="inputGroupPrepend"
												value={formik.values.firstName}
												onBlur={formik.handleBlur}
												// onChange={(e) => {
												// 	e.target.value = e.target.value?.replace(REGEX_ALPHABET,"");
												// 	formik.handleChange(e);
												//   }}
												onChange={(e) => {
													e.target.value = alphabetValidation(e.target.value)
													formik.handleChange(e);
												}}
												className={formik.touched.firstName && formik.errors.firstName ? "error" : ""}
											/>
										</InputGroup>
									</Col>
									<Col
										md={5}
										className="mb-3"
									>
										<Form.Label className="d-flex" htmlFor="validationCustomLastName">
											{t('lastName')}<span className="field">*</span>
										</Form.Label>
										<InputGroup className="d-flex">
											<Form.Control
												type="text"
												name="lastName"
												id="validationCustomLastName"
												placeholder={t('lastName')}
												value={formik.values.lastName}
												maxLength={LAST_NAME_MAXLENGTH}
												aria-describedby="inputGroupPrepend"
												onBlur={formik.handleBlur}
												// onChange={(e) => {
												// 	e.target.value = e.target.value?.replace(REGEX_ALPHABET,"");
												// 	formik.handleChange(e);
												//   }}		
												onChange={(e) => {
													e.target.valuev = alphabetValidation(e.target.value)
													formik.handleChange(e);
												}}
												className={formik.touched.lastName && formik.errors.lastName ? "error" : ""}
											/>
										</InputGroup>
									</Col>
									<Col
										md={5}
										className="mb-3"
									>
										<Form.Label className="d-flex" htmlFor="validationCustomAddress1">
											{t('address1')}<span className="field">*</span>
										</Form.Label>
										<InputGroup className="d-flex">
											<Form.Control
												name="address1"
												type="text"
												id="validationCustomAddress1"
												placeholder={t('address1')}
												value={formik.values.address1}
												aria-describedby="inputGroupPrepend"
												maxLength={ADDRESS_MAXLENGTH}
												onBlur={formik.handleBlur}
												onChange={formik.handleChange}
												className={formik.touched.address1 && formik.errors.address1 ? "error" : ""}

											/>
										</InputGroup>
									</Col>
									<Col
										md={5}
										className="mb-3"
									>
										<Form.Label className="d-flex" htmlFor="validationCustomAddress2">
											{t('address2')}<span className="field">*</span>
										</Form.Label>
										<InputGroup className="d-flex">
											<Form.Control
												name="address2"
												type="text"
												id="validationCustomAddress2"
												placeholder={t('address2')}
												value={formik.values.address2}
												aria-describedby="inputGroupPrepend"
												onBlur={formik.handleBlur}
												maxLength={ADDRESS_MAXLENGTH}
												onChange={formik.handleChange}
												className={formik.touched.address2 && formik.errors.address2 ? "error" : ""}
											/>
										</InputGroup>
									</Col>
									<Col
										md={5}
										className="mb-3"
									>
										<Form.Label className="d-flex" htmlFor="validationCustomEmail">
											{t("email")}<span className="field">*</span>
										</Form.Label>
										<InputGroup hasValidation>
											<InputGroup.Text id="inputGroupPrepend">
												@
											</InputGroup.Text>
											<Form.Control
												type="email"
												id="validationCustomEmail"
												placeholder={t("email")}
												aria-describedby="inputGroupPrepend"
												name="email"
												maxLength={EMAIL_MAXLENGTH}
												value={formik.values.email}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												className={formik.touched.email && formik.errors.email ? "error" : ""}
											/>
											{formik.touched.email}
										</InputGroup>
									</Col>
									<Col
										md={5}
										className="mb-3"
									>
										<Form.Label className="d-flex" htmlFor="validationCustomCountry">
											{t('country')}<span className="field">*</span>
										</Form.Label>
										<Select
											options={COUNTRY_NAME_ARRAY}
											name="country"
											value={formik.values.country}
											onChange={(d) => formik.setFieldValue('country', d)}
											onBlur={(d) => formik.handleBlur}
											className={formik.touched.country && formik.errors.country ? " error rounded-2" : ""}

										/>
									</Col>
								</Row>
								<Row>
									<Col md={12} className="d-flex justify-content-center">
										<CommonButton
											type="submit"
											classname="gradient-btn "
											size="md"
											title={t('submit')}
										//onclick={handleSubmit}
										/>
									</Col>
								</Row>
							</Form>
						</Card.Body>
					</Card>
				</Container>
			</section>
		</>
	);
}

export default Feedback;
