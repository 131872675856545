import { Card, Col, Container, Row } from "react-bootstrap";
import CommonButton from "../components/commonComponents/CommonButton";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";
import HtmlToReactConverter from "../components/commonComponents/HtmlToReactConverter";
import { useSelector } from "react-redux";
import { selectedMarket as currentMarket } from "../redux/marketRedux";

function SuccessCommon() {
  const { t } = useTranslation(['common','groupbooking','successpage']);
  const selectedMarket = useSelector(currentMarket);
  const location = useLocation();
  const stateVariable = location.state;

  useEffect(() => {
  }, []);

  return (
    <>
      <section>
        <Container className="my-4">
          <h3 className="my-3 d-flex justify-content-center text-center">
            <span className="heading">{t('groupbooking:groupBookingSuccessHeading')}</span>
          </h3>
          <Row>
            <Col md={12} className="mx-auto xl">
              <Card className="text-center mt-2">
                <Card.Body className="text-center">
                  <Card className="mb-3 my-bg-info">
                    <Row>
                      <Col md={12} className="d-flex justify-content-center">
                        <h5 className="lh-base">
                          <HtmlToReactConverter value={t('groupbooking:groupSuccessInfo', { coordinatorName : stateVariable?.coordinatorname, manifestId: stateVariable?.response?.data?.manifestid , email: "groups@vipattractions.com"})} />
                        </h5>
                      </Col>
                    </Row>
                  </Card>
                  <Row>
                    <Col className="m-4 d-flex justify-content-center">
                      <Link to="/" className="me-1 text-decoration-none">
                        <CommonButton
                          type="button"
                          size="lg"
                          classname="gradient-btn mx-2"
                          title={t('return')}
                        />
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="d-flex justify-content-center">
                      <h5 className="lead mb-3 lh-base">
                        <HtmlToReactConverter value={t('successpage:contactBottomArea', { phone: selectedMarket?.contact?.phone, email: selectedMarket?.contact?.email, skype: selectedMarket?.contact?.skype })} />
                      </h5>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
export default SuccessCommon;
