import { Card, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import CommonButton from "../components/commonComponents/CommonButton";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { selectedMarket as currentMarket } from "../redux/marketRedux";
import HtmlToReactConverter from "../components/commonComponents/HtmlToReactConverter";
import { useSelector } from "react-redux";
import { PRODUCTID_ARRIVALBUNDLE } from "../config/commonConstants";
import { getAdultMinimumAge, getMarketLoungenamesWithAirport, getProductName, isSubDistributorProfile, myDateFormat } from "../components/commonComponents/commonFunctions";
import Promotions from "../components/commonComponents/Promotions";
import { subDistributorProfileData as SDPD } from "../redux/loggedInUserDetailRedux";


function GiftRedeemSuccess() {
  const { t } = useTranslation(['common', 'successpage']);
  const selectedMarket = useSelector(currentMarket);
  const location = useLocation();
  const successInfo = location.state;
  const distributorProfile = useSelector(SDPD);

  const marketLoungenameswithairport = getMarketLoungenamesWithAirport(
    selectedMarket?.airports
  );

  const getLoungeNameWithAirpot = (value) => {
    return marketLoungenameswithairport.find(a => a?.value === value)?.label;
  }

  const getBookedClubName = (e) => {
    let clubName = "";
    const bookingDetail = e?.value?.currentCartItem?.productid === PRODUCTID_ARRIVALBUNDLE ? [e?.value?.bookingDetail[0],e?.value?.bookingDetail[1]] : e?.value?.bookingDetail;
      if (e?.value?.currentCartItem?.productid === PRODUCTID_ARRIVALBUNDLE)
        clubName = getLoungeNameWithAirpot(bookingDetail[0]?.lounge) //(bookingDetail[0]?.lounge);
      else
        clubName = getLoungeNameWithAirpot(bookingDetail?.lounge) //(bookingDetail?.lounge?.label);
    return clubName;
  }

  const getBookedDate = (e) => {
    let date = "";
    const bookingDetail = e?.value?.currentCartItem?.productid === PRODUCTID_ARRIVALBUNDLE ? [e?.value?.bookingDetail[0],e?.value?.bookingDetail[1]] : e?.value?.bookingDetail;
    if (e?.value?.currentCartItem?.productid === PRODUCTID_ARRIVALBUNDLE) {
      const arrivalDate = bookingDetail[0]?.flightDate + " " + (bookingDetail[0]?.flightTime_hour);
      const departureDate = bookingDetail[1]?.flightDate + " " + (bookingDetail[1]?.flightTime_hour);
      date = arrivalDate + " " +  t('and') + " " + departureDate;
    }
    else {
        date = bookingDetail?.flightDate + " " + bookingDetail?.flightTime_hour;
    }
    return date;
  }

  return (
    <>
      <section>
        <Container className="my-4">
          <h3 className="my-3 d-flex justify-content-center text-center">
            <span className="heading">{t('successpage:giftRedemptionComplete')}</span>
          </h3>
          <Row>
            <Col md={12} className="mx-auto xl">
              <Card className="text-center mt-2">
                <Card.Body className="text-center">
                  <Row>
                    <Col md={12} className="d-flex justify-content-center">
                      <h4 className="lh-base">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          size="3x"
                          className="mb-3"
                          color="green"
                        />
                      </h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="d-flex justify-content-center">
                      <h5><HtmlToReactConverter value={t('successpage:thanksForPaymentMessage', { currencyCode: successInfo?.currencyCode, amount: successInfo?.amount, email: successInfo?.confirmationEmail })} /></h5>
                    </Col>
                  </Row>
                  
                  {successInfo?.cartData?.map((e, index) => (
                    <Card key={index} className="mt-3 mb-3 my-bg-info">
                      <Row>
                        <Col md={12} className="d-flex justify-content-center">
                          <h5 className="mb-1 lh-base">
                            <HtmlToReactConverter value={t('successpage:GiftRedeemSuccessMessage', {
                              confirmationnumber: successInfo?.confirmationnumber,
                              club: getBookedClubName(e),
                              product:  getProductName(e?.value?.currentCartItem?.productid),
                              date: getBookedDate(e),
                              adultCount: e?.value?.adultCount?.value,
                              childCount: e?.value?.childCount?.value,
                              email: successInfo?.confirmationEmail,
                              adultAge: getAdultMinimumAge()
                            })} />
                          </h5>
                        </Col>
                      </Row>
                    </Card>))}
                  <Row>
                    <Col md={12} className="d-flex justify-content-center">
                      <p>
                        {t('successpage:presentEmailUponArrival')}
                      </p>
                    </Col>
                  </Row>
                  <Promotions
                    jePromotion={true}
                    jemPromotion={true}
                  />
                  <Row>
                    <Col className="m-4 d-flex justify-content-center">
                      <Link to="/" className="me-1 text-decoration-none">
                        <CommonButton
                          type="button"
                          size="lg"
                          classname="gradient-btn mx-2"
                          title={t('return')}
                        />
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="d-flex justify-content-center">
                      <h5 className="lead mb-3 lh-base">
                        <HtmlToReactConverter value={t('successpage:contactBottomArea', { phone: selectedMarket?.contact?.phone, email: selectedMarket?.contact?.email, skype: selectedMarket?.contact?.skype })} />
                      </h5>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
export default GiftRedeemSuccess;