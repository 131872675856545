import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudSun } from "@fortawesome/free-solid-svg-icons";

function Weather(props) {
  const [inputValue, setInputValue] = useState(props.cityname);
  const citylabel = props.citylabel;
  const [data, setData] = useState("");
  const [city, setCity] = useState("");
  const API_KEY = process.env.REACT_APP_WEAHTER_API_KEY;
  
  // const getTempData = (api, query) => {
  //   let url = `https://api.openweathermap.org/data/2.5/weather?q=${query}&units=metric&appid=${api}`;
  //   fetch(url)
  //     .then((res) => {
  //       return res.json();
  //     })
  //     .then((res) => {
  //       setData(res.main);
  //       setCity(query);

  //     })
  //     .catch((err) => {
  //       setData(null);
  //     });
  // };
  const getTempData = async (api, query) => {
    try {
      let url = `https://api.openweathermap.org/data/2.5/weather?q=${query}&units=metric&appid=${api}`;
      const res = await fetch(url);
      const data = await res.json();
      setData(data.main);
      setCity(query);
    } catch (err) {
      setData(null);
    }
  };
  

  // call use Effect for render data every search input
  useEffect(() => {
    getTempData(API_KEY, inputValue);
  }, [inputValue]);

  return (
    <div className="d-flex p-1">
      <FontAwesomeIcon icon={faCloudSun} style={{ color: "white" }} />
      <span style={{ color: "white" }}>
        {citylabel}: {Math.round(data?.temp)} °C
      </span>
    </div>
  );
}

export default Weather;
