import { Card, Col, Container, Row } from "react-bootstrap";
import CommonButton from "../components/commonComponents/CommonButton";
import { Link, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Promotions from "../components/commonComponents/Promotions";
import { clearVIPERCart, unlockMembercard } from '../redux/apiCalls';
import HtmlToReactConverter from "../components/commonComponents/HtmlToReactConverter";
import Loader from '../components/commonComponents/Loader';
import React, { useState, useEffect } from 'react';
import { HandleAPIError, externalLogin, getLoginDetails, isObjectEmpty } from "../components/commonComponents/commonFunctions";
import { selectedMarket as currentMarket } from "../redux/marketRedux";
import { useDispatch, useSelector, useStore } from "react-redux";
import { logindetails as LD } from "../redux/signInRedux";
import LoadingSkeleton from "../components/commonComponents/LoadingSkeleton";
// import { store } from "../store/store";


function UnlockMemberCardLink() {
  const { t } = useTranslation(['common', 'memberandcardinformation']);
  const [loading, setLoading] = useState(true);
  const [displayMessage, setDisplayMessage] = useState();
  const selectedMarket = useSelector(currentMarket);
  const { loginKey } = useParams();
  const logindetails = useSelector(LD);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sendBroadcastMessage = useOutletContext();
  const location = useLocation();

  const unlock_membercard = async () => {
    const data = {
      "loginkey": loginKey,
    };
    try {
      setLoading(true);
      const response = await unlockMembercard(data);
      if (response?.status === 0) {
        setDisplayMessage(t("memberandcardinformation:unlockCardMessage"));
      } else {
        setDisplayMessage(response?.statusMessage);
      }

    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  }

  const [isFirstComplete,setIsFirstComplete] = useState(false);
 
  useEffect(()=>{
    externalLogin(location,sendBroadcastMessage,navigate,dispatch,setIsFirstComplete)
  },[])

  useEffect(() => {
    if(isFirstComplete){
      getLoginDetails()?.sessionid && unlock_membercard();
    }
  }, [logindetails,isFirstComplete]);

  return (
    <>
      <section>
        <Container className="my-4">
          <Loader
            loading={loading}
            message={t("memberandcardinformation:loaderMessage")}
          />
          <h3 className="my-3 d-flex justify-content-center text-center">
            <span className="heading">
              {t("memberandcardinformation:unlockCardHeading")}
            </span>
          </h3>
          {loading ? (
            <LoadingSkeleton />
          ) : (
            <Row>
              <Col md={12} className="mx-auto xl">
                <Card className="text-center mt-2">
                  <Card.Body className="text-center">
                    {/* <Row>
                      <Col md={12} className="d-flex justify-content-center">
                        <h4 className="lh-base">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            size="3x"
                            className="mb-3"
                            color="green"
                          />
                        </h4>
                      </Col>
                    </Row> */}
                    <Card className="mb-3 my-bg-info">
                      <Row>
                        <Col md={12} className="d-flex justify-content-center">
                          <h5 className="lh-base">{displayMessage}</h5>
                        </Col>
                      </Row>
                    </Card>
                    <Promotions jePromotion={true} jemPromotion={true} />
                    <Row>
                      <Col className="m-4 d-flex justify-content-center">
                        <Link to="/" className="me-1 text-decoration-none">
                          <CommonButton
                            type="submit"
                            size="lg"
                            classname="gradient-btn mx-2"
                            title={t("return")}
                          />
                        </Link>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="d-flex justify-content-center">
                        <h5 className="lead mb-3 lh-base">
                          <HtmlToReactConverter
                            value={t("successpage:contactBottomArea", {
                              phone: selectedMarket?.contact?.phone,
                              email: selectedMarket?.contact?.email,
                              skype: selectedMarket?.contact?.skype,
                            })}
                          />
                        </h5>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </section>
    </>
  );
}
export default UnlockMemberCardLink;
