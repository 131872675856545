import { Row, Form, Card, Container, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { divRef, externalLogin, numericValidation, renderErrorMessages, scrollToDiv } from "../components/commonComponents/commonFunctions";
import CommonButton from "../components/commonComponents/CommonButton";
import { authenticateGift } from "../redux/apiCalls";
import { HandleAPIError } from "../components/commonComponents/commonFunctions";
import Loader from "../components/commonComponents/Loader";
import { showError } from "../components/commonComponents/Swal";
import ErrorMessages from "../components/commonComponents/ErrorMessages";
import { useDispatch } from "react-redux";
import { GIFT_CARD_NUMBER_MAXLENGTH, GIFT_CARD_PIN_MAXLENGTH } from "../config/commonConstants";
import ExitButton from "../components/commonComponents/ExitButton";

function GiftAuth() {
  const { t } = useTranslation(["common", "giftauth", "standbybookinglist"]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const sendBroadcastMessage = useOutletContext();

  useEffect(()=>{
    externalLogin(location,sendBroadcastMessage,navigate,dispatch)
  },[])

  const validationSchema = Yup.object().shape({

    cardNumber: Yup.string()
      .required(t("giftauth:errors.cardNumber.required")),
    pin: Yup.string()
      .required(t("giftauth:errors.pin.required"))

  });
  const formik = useFormik({
    initialValues: {
      cardNumber: "",
      pin: "",
    },
    validationSchema,
    onSubmit: () => { handleSubmit(formik.values) },
  });

  async function handleSubmit(formik) {
    const data = {
      "confirmationnumber": formik.cardNumber,
      "cartitemid": formik.pin,
    };
    try {
      setLoading(true)
      const response = await authenticateGift(data);
      if (response.status !== 0) {
        showError(t("error"), response.statusMessage);
      } else {
        if (response.status === 0) {
          let giftData = {
            cartitemid: 0,
            productid: response.data.productid,
            giftBookingInfo: response.data,
            giftcartitemid: formik.pin
          };
          setErrorMessage("")
          navigate("/gift-redeem", { state: giftData });
        } else {
          showError(t("error"), response.statusMessage);
        }
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      <section>
        <Container className="my-4" ref={divRef}>
          <Loader loading={loading} message={t("giftauth:loaderMessage")} />
          <h3 className="my-4 d-flex justify-content-center heading">
            <span className="heading">{t("giftauth:giftAuthHeading")}</span>
          </h3>
          <div className="my-3 px-1 d-flex justify-content-end">
            <ExitButton />
          </div>
          <Card className="p-3">
            <Row className="d-flex justify-content-start">
              <Col md={4}>
                <h6 className="field">{t("fillAllFields")}</h6>
              </Col>
            </Row>
            <ErrorMessages formik={formik} />
            <Form onSubmit={(e) => scrollToDiv(e, formik)}>
              <Row className="d-flex justify-content-center align-items-center ">
                <Col md={3}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      {t("giftauth:cardNumber")}
                      <span className="field">*</span>
                    </Form.Label>
                    <Form.Control
                      name="cardNumber"
                      type="text"
                      placeholder={t("giftauth:cardNumberID")}
                      maxLength={GIFT_CARD_NUMBER_MAXLENGTH}
                      value={formik.values.cardNumber}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      className={
                        formik.touched.cardNumber && formik.errors.cardNumber
                          ? "error"
                          : ""
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center ">
                <Col md={3}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      {t("giftauth:giftPin")}
                      <span className="field">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="pin"
                      placeholder={t("giftauth:pinID")}
                      value={formik.values.pin}
                      maxLength={GIFT_CARD_PIN_MAXLENGTH}
                      onChange={(e) => {
                        e.target.value = numericValidation(e.target.value);
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      className={
                        formik.touched.pin && formik.errors.pin ? "error" : ""
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center ">
                <Col md={3}>
                  <CommonButton
                    type="submit"
                    classname="gradient-btn"
                    size="md"
                    title={t("giftauth:getDetails")}
                  />
                </Col>
              </Row>
            </Form>
          </Card>
        </Container>
      </section>
    </>
  );
}
export default GiftAuth;

