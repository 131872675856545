import { STAND_BY_LINKS, CC_EXPIRY_YEAR_DROPDOWN_LIST_LIMIT, DIGICELBLACKCARD_LOGIN, LOGIN_TYPE, PRIORITYPASS_LOGIN, ROUTENAME_ARRIVAL, ROUTENAME_ARRIVALBUNDLE, ROUTENAME_DEPARTURE, ROUTENAME_DIGICELBLACKCARD, ROUTENAME_PRIORITYPASS, ADULT_MINIMUM, CHILD_MINIMUM, PASSWORD_POLICY, VALIDATE_LOGIN_DATA, PRODUCTID_PRIORITYPASS, PRODUCTID_DIGICELBLACKCARD, EMAIL_POLICY, RESTRICTED_PATHNAME_FOR_AUTOLOGIN, EXTERNAL_LINKS, SINGLE_BOOKING_MAX_COUNT_DEPARTURE, PRODUCTNAME_ARRIVAL, PRODUCTNAME_DEPARTURE, PRODUCTNAME_ARRIVALBUNDLE, PROMOTION_LINK, AFFILIATE_COOKIE_EXPIRE_TIME, LOUNGE_PARTNERS_PRODUCTS, DRAGONPASSCARD_LOGIN, PRODUCTID_DRAGONPASS, COOKIE_EXPIRE_TIME, DEFAULT_COOKIE, LOGIN_BUTTON_SHOW_TO_USERS, AFFILIATE_SHOW_LINKS_REGEX, REGEX_ALPHABET, REGEX_ALPHANUMERIC_WITHOUT_SPACE, REGEX_ALPHANUMERIC_WITH_SPACE, SESSION, TERMS_AND_CONDITIONS_FOLDER_PATH, CONFIRMATION_APIS, SECRET_KEY, TESTING_URLS, BASE_URL} from "../../config/commonConstants";
import { parse, format, differenceInYears, differenceInMonths } from "date-fns";
import {
  CUSTOMER_LOGIN,
  ARR_PRODUCTS,
  PRODUCTID_ARRIVAL,
  PRODUCTID_DEPARTURE,
  PRODUCTID_ARRIVALBUNDLE,
  REGEX_NUMERIC
} from "../../config/commonConstants";
import { store } from "../../store/store";
import { removeSession, logout, subDistributorProfile, resetSignInRedux, updateSession } from "../../redux/signInRedux";
import { standByLogout } from "../../redux/standByRedux";
import { clearCart } from "../../redux/cartRedux";
import mySwal, { showConfirm, showError, showInfo } from "./Swal";
import {
  addBillPaymentLog, addConfirmationLog, addMemberCardLog, addTopupAccountLog, login, addTopupMemberCardLog, addGiftLog,
  addConvertStandByLog, addCreditBookingPaymentLog, clearVIPERCart, altLogin2,
  extendSession,
  addLog,
  sendMail,
  getJamaicanTime
} from "../../redux/apiCalls";
import { affiliateDetails, resetAffiliates } from "../../redux/affiliatesRedux";
import { resetPartner } from "../../redux/partnerRedux";
import { getOrderId, getmappedmembercards } from "../../redux/apiCalls";
import CryptoJS from 'crypto-js';
import { giftCartclear } from "../../redux/giftCartRedux";
import { paxCountClear } from "../../redux/increasePaxCountRedux";
import i18n from "i18next";
import { clearLoggedInUserDetail, clearSubDistributorProfile, setLoggedInUserDetail } from "../../redux/loggedInUserDetailRedux";
import { Cookies } from "react-cookie";
import React from "react";
import mixpanel from "mixpanel-browser";
import { object, string } from "yup";
import { resetMarketRedux, setJamaicanTime } from "../../redux/marketRedux";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import CommonButton from "./CommonButton";
import Swal from "sweetalert2";
import moment from "moment";
import { closeModal } from "../../utils/Fac";
import { clearDemographicInformation, setDemographicInformation } from "../../redux/demographicInformationRedux";
// const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

export function getMonthDropdownList() {
  return Array.from(new Array(12), (v, i) => (
    <option key={i + 1} value={String(i + 1).padStart(2, "0")}>
      {String(i + 1).padStart(2, "0")}
    </option>
  ));
}

export function getCCExpiryYearDropdownList() {
  const year = new Date().getFullYear();
  return Array.from(new Array(CC_EXPIRY_YEAR_DROPDOWN_LIST_LIMIT), (v, i) => (
    <option key={i} value={year + i}>
      {year + i}
    </option>
  ));
}

export function getMarketLoungeArray(airports) {
  const loungeNames = [];
  for (var k = 0; k < airports?.length; k++) {
    loungeNames.push({
      value: airports[k].loungeid,
      label: airports[k].lounge,
    });
  }
  return loungeNames;
}

export function getMarketLoungenames(airports, separator = " / ") {
  const loungeNames = getMarketLoungeArray(airports);
  const explodedNames = loungeNames
    .map((loungeName) => loungeName.label)
    .join(" / ");
  return explodedNames;
}

export function getMarketLoungenamesWithAirport(airports) {
  const loungeNamesWithAirport = [];
  for (var k = 0; k < airports?.length; k++) {
    loungeNamesWithAirport.push({
      value: airports[k].airportid,
      label: airports[k].lounge + " / " + airports[k].airport,
    });
  }
  return loungeNamesWithAirport;
}

export function getAirlineNames(airlines) {
  const airlineList = [];
  for (var k = 0; k < airlines?.length; k++) {
    airlineList.push({
      value: airlines[k]?.airlineid,
      label: airlines[k]?.description,
    });
  }
  return airlineList;
}

export function getFlightNumbers(flights) {
  const airlineList = [];
  for (var k = 0; k < flights?.length; k++) {
    airlineList.push({
      value: flights[k]?.flightid,
      label: flights[k]?.flightid,
    });
  }
  return airlineList;
}

export function getMarketCityArray(airports) {
  const cities = [];

  airports?.map((v) => {
    cities.push({ value: v.city, label: v.citylabel });
  });

  return cities;
}

// export function getDateInYYYYMMDD(val) {
//    const datechange = `${val?.getFullYear()}${"0" + (val?.getMonth() + 1)}${val?.getDate()}`;
//   // const datechange = `{val?.getFullYear()}{"0" + (val?.getMonth() + 1)}{val?.getDate()}`;
//   return datechange;
// }

export function getMarketRules(Rules) {
  var cancellationPolicyRule = "";
  var cancellationpolicyArr = [];
  var count = 0;
  Rules?.cancellationpolicy.forEach((v, key) => {
    if (key === Rules.appendmailtoline - 1) {
      cancellationPolicyRule = v;
      cancellationPolicyRule +=
        ' <a target="_blank" href="mailto:' +
        Rules.mailto +
        "?subject=" +
        Rules.subject +
        '">' +
        Rules.mailto +
        "</a> ";
    } else if (key === Rules.appendmailtoline) {
      cancellationPolicyRule += v;
      cancellationpolicyArr[count] = cancellationPolicyRule;
      cancellationPolicyRule = "";
      count++;
    } else if (key === Rules.appendurlline - 1) {
      cancellationPolicyRule =
        v + " <a target='_blank' href=" + Rules.url + ">" + Rules.url + "</a>";
      cancellationpolicyArr[count] = cancellationPolicyRule;
      count++;
    } else {
      cancellationpolicyArr[count] = v;
      count++;
    }
  });
  return cancellationpolicyArr;
}

export function getSpecialOccasionsArray(arrivalreasons) {
  const getSpecialOccasions = [];
  getSpecialOccasions.push({ value: "", label: "Select Special Occasion" });

  arrivalreasons?.map((v) => {
    getSpecialOccasions.push({ value: v.reasonid, label: v.description });
  });

  return getSpecialOccasions;
}

export function myDateFormat(strDate, inputFormat = "yyyy-MM-dd", outputFormat = "MM/dd/yyyy") {
  let tmpDate = format(parse(strDate, inputFormat, new Date()), outputFormat);
  return tmpDate;
}

export function dateFormatForDisplay(date, dateFormat) {
  let tmpDate = format(new Date(date), dateFormat);
  return tmpDate;
}


export function formatTime(timeString) {

  let utcTime = moment.utc(timeString);
  let formattedTime = utcTime.format('HHmm');
  return formattedTime;

  // const parsedTime = new Date(timeString);
  // const formattedTime = parsedTime.toISOString('en-US');
  // const [_, timePart] = formattedTime.split('T');
  // const [hours, minutes] = timePart.split(':');
  // return hours + minutes;

  // timeString.setHours(timeString.getHours() - 5);
  // timeString.setMinutes(timeString.getMinutes() - 21);
  //   const formattedTime = timeString.toUTCString('en-US', { timeZone: 'Asia/Kolkata',hour12: false });
  //   const [hours, minutes, seconds] = formattedTime.split(':');
  //   return hours + minutes;
};

export function dateDifferenceInYears(bookingDate, dob) {
  const diffYears = differenceInYears(
    new Date(dateFormatForDisplay(bookingDate, "MM/dd/yyyy")),
    new Date(dob)
  );

  return diffYears;
};

export function getClubName(airportid) {
  const clubname = [];
  const state = store.getState();
  let availableMarkets = state?.marketRedux?.availableMarkets;
  availableMarkets?.data?.markets?.map((market) => {
    market.airports?.map((airport) => {
      if (airportid === airport.airportid) {
        clubname.push({ value: airport.airportid, label: airport.lounge });
      }
    });
  });

  const club = clubname.map(function (v) {
    if (v.value == airportid) {
      return v.label;
    }
  });

  return club;
}

export function getAirportName(airportid) {
  const airportnames = [];
  const state = store.getState();
  let availableMarkets = state?.marketRedux?.availableMarkets;
  availableMarkets?.data?.markets?.map((market) => {
    market.airports?.map((airport) => {
      if (airportid === airport.airportid) {
        airportnames.push({ value: airport.airportid, label: airport.airport });
      }
    });
  });

  const airportname = airportnames.map(function (v) {
    if (v.value === airportid) {
      return v.label;
    }
  });

  return airportname;
}

export function getAirpotId(lounge) {
  let airpotId = "";
  const state = store.getState();
  let noloungeFound = true;
  let availableMarkets = state?.marketRedux?.availableMarkets;
  availableMarkets?.data?.markets?.map((market) => {
    market.airports?.map((airport) => {
      if (lounge === airport.lounge) {
        noloungeFound = false
        airpotId = airport.airportid
      }
    });
  });
  if (noloungeFound) {
    airpotId = availableMarkets?.data?.markets?.filter((x) => x?.marketid === availableMarkets?.data?.defaultmarket)[0]?.airports[0]?.airportid;
  }

  return airpotId;
}

export function getMarketIdFromAirpotId(club) {
  let marketId = "";
  const state = store.getState();
  let noloungeFound = true;
  let availableMarkets = state?.marketRedux?.availableMarkets;
  availableMarkets?.data?.markets?.map((market) => {
    let id = market.marketid;
    market.airports?.map((airport) => {
      if (club === airport.lounge) {
        noloungeFound = false;
        marketId = id
      }
    });
  });
  if (noloungeFound) {
    marketId = availableMarkets?.data?.defaultmarket;
  }
  return marketId;
}

export function getProductName(productid) {
  let product = "";
  ARR_PRODUCTS.find((obj) => {
    if (obj.value === productid) {
      product = obj.label;
    }
  });
  return product;
}

export function getCommissionPeriodReportData(commissionreportdata) {
  let commission_periods_report_data = [];
  commissionreportdata?.map((v) => {
    let total_ticket_amount = 0;
    let adult_rate = 0;
    let child_rate = 0;
    let adult_commission_amount = 0;
    let child_commission_amount = 0;
    let adult_ticket_amount = 0;
    let child_ticket_amount = 0;

    if (v.productid === PRODUCTID_ARRIVAL) {
      adult_ticket_amount = v.distributoradultarrivalrate * v.adulttickets;
      child_ticket_amount = v.distributorchildarrivalrate * v.childtickets;
      total_ticket_amount = adult_ticket_amount + child_ticket_amount;
      adult_rate = v.adultarrivalrate;
      child_rate = v.childarrivalrate;
      adult_commission_amount = adult_rate - v.distributoradultarrivalrate;
      child_commission_amount = child_rate - v.distributorchildarrivalrate;
    } else if (v.productid === PRODUCTID_DEPARTURE) {
      adult_ticket_amount = v.distributoradultdeparturerate * v.adulttickets;
      child_ticket_amount = v.distributorchilddeparturerate * v.childtickets;
      total_ticket_amount = adult_ticket_amount + child_ticket_amount;
      adult_rate = v.adultdeparturerate;
      child_rate = v.childdeparturerate;
      adult_commission_amount = adult_rate - v.distributoradultdeparturerate;
      child_commission_amount = child_rate - v.distributorchilddeparturerate;
    } else if (v.productid === PRODUCTID_ARRIVALBUNDLE) {
      adult_ticket_amount = v.distributoradultdeparturerate * v.adulttickets;
      child_ticket_amount = v.distributorchilddeparturerate * v.childtickets;
      total_ticket_amount = adult_ticket_amount + child_ticket_amount;
      let departure_adult_rate = v.adultdeparturerate;
      let departure_child_rate = v.childdeparturerate;

      let arrival_adult_rate = v.adultarrivalrate;
      let arrival_child_rate = v.childarrivalrate;

      adult_rate = arrival_adult_rate;
      child_rate = arrival_child_rate;

      adult_commission_amount =
        arrival_adult_rate -
        v.distributoradultarrivalrate +
        (departure_adult_rate - v.distributoradultdeparturerate);
      child_commission_amount =
        arrival_child_rate -
        v.distributorchildarrivalrate +
        (departure_child_rate - v.distributorchilddeparturerate);
    }

    if (v.childtickets > 0) {
      child_commission_amount = child_commission_amount.toFixed(2);
    } else {
      child_commission_amount = 0;
    }
    if (v.adulttickets > 0) {
      adult_commission_amount = adult_commission_amount.toFixed(2);
    } else {
      adult_commission_amount = 0;
    }

    commission_periods_report_data.push({
      sequenceid: v.sequenceid,
      airportid: v.airportid,
      productid: v.productid,
      confirmationnumber: v.confirmationnumber,
      reservationnumber: v.reservationnumber,
      contactname: v.contactname,
      transactiondate: v.transactiondate,
      onlineentry: v.onlineentry,
      totalticketamount: total_ticket_amount,
      adultrate: adult_rate,
      childrate: child_rate,
      adulttickets: v.adulttickets,
      childtickets: v.childtickets,
      commissionamount: v.commissionamount,
      adultcommissionamount: adult_commission_amount,
      childcommissionamount: child_commission_amount,
    });
  });

  return commission_periods_report_data;
}
const cookies = new Cookies();
export function get_affiliate_subaffiliate_data() {
  const state = store.getState();
  let affiliateid = "!";
  let subaffiliateid = 0;
  let affiliatename = "";
  if (!state?.partnerRedux?.partnerDetails?.affiliatename) {
    const affiliateData = state?.affiliatesRedux?.affiliateDetails;
    let affiliateid_cookie = affiliateData?.affiliateid;
    let subaffiliateid_cookie = affiliateData?.subaffiliateid;
    if (!affiliateid_cookie) {
      affiliateid = "!";
      subaffiliateid = 0;
    } else {
      let userDefault = state?.signInRedux?.userDefault;
      let default_user = !userDefault?.username
        ? "Y"
        : userDefault?.username === CUSTOMER_LOGIN.username
          ? "Y"
          : "N";

      if (default_user === "Y") {
        affiliateid = affiliateid_cookie;
        subaffiliateid = subaffiliateid_cookie ? subaffiliateid_cookie : 0;
        affiliatename = affiliateDetails?.affiliatename
      }
    }
  }
  let arr = { affiliateid: affiliateid, subaffiliateid: subaffiliateid, affiliatename: affiliatename };
  return arr;
}

export function closeSession(sendBroadcastMessage, standBySession) {
  if (standBySession) {
    store.dispatch(standByLogout())
  } else {
    clearSession(sendBroadcastMessage)
    store.dispatch(logout());

    // store.dispatch(clearCart());
    // store.dispatch(giftCartclear());
    store.dispatch(paxCountClear());
    store.dispatch(clearLoggedInUserDetail());
  }

}

export function clearSession(sendBroadcastMessage, standBySession) {
  if (standBySession) {
    store.dispatch(standByLogout());
  } else {
    const state = store.getState();
    sendBroadcastMessage({
      sender: "clearSession",
      content: { title: i18n.t('sessionCleared.title'), message: i18n.t("sessionCleared.message") },
      redirectTo: "/"
    })
    if (state?.signInRedux?.distributorProfile?.loungepartner === "Y") {
      store.dispatch(logout());
    }
    store.dispatch(removeSession());
    store.dispatch(clearCart());
    store.dispatch(giftCartclear());
  }
  // store.dispatch(paxCountClear());
}

export function getCurrentDateTime() {
  const currentDate = new Date();
  const year = currentDate.getFullYear().toString();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");
  const hours = currentDate.getHours().toString().padStart(2, "0");
  const minutes = currentDate.getMinutes().toString().padStart(2, "0");
  const seconds = currentDate.getSeconds().toString().padStart(2, "0");
  return year + month + day + hours + minutes + seconds;
}

export function HandleAPIError(error) {
  if (error?.code === "ERR_NETWORK") {
    showError(i18n.t("error"), i18n.t('serverUnavailableMessage'));
  } else {
    showError(i18n.t("error"), error?.message);
  }
}

export function formatCreditCardExpiryFAC(cardMonth, cardYear) {
  let cardExpiry = cardMonth + cardYear?.slice(-2);
  return cardExpiry;
}

export function show_distributor_logo(location) {
  const arr = [
    '/login',
    '/subscriber-login',
    '/partner-form',
    '/subscriber-registration',
    '/comment-card',
    // '/get-update-link',
    // '/add',
    // '/topup',
    // '/change-pin',
    // '/reset-pin',
    // '/card-profile',
    // '/member-card-unlock',
    // '/stand-by',
    // '/standby-redeem',
    // '/standby-complete',
    // '/link-booking'
  ];
  let show_distributor_logo = true;
  if (arr.some(item => location.includes(item))) {
    show_distributor_logo = false;
  }
  return show_distributor_logo;
}

export function getCurrentMonth() {
  const today = new Date();
  const tmpMonth = ("0" + (today.getMonth() + 1)).slice(-2);
  return tmpMonth;
}

export function getMemberCardsArray(memberCardsArray) {
  const memberCards = [];

  memberCardsArray?.map((v) => {
    memberCards.push({ value: v.cardid, label: v.cardname, showvalue: v.showvalue });
  });

  return memberCards;
}

export function getProductsIdForCurrentCart(cartData) {
  const productIds = [];
  cartData?.map(item => {
    productIds.push(item?.value?.currentCartItem?.productid);
  });
  return productIds;
}

export function _getOrderId(source, amount) {
  let data = {
    source: source,
    amount: amount,
  };
  const orderIdResponse = getOrderId(data);
  return orderIdResponse;
}

export const renderErrorMessages = (formik) => {
  return (
    formik.errors && !isObjectEmpty(formik.touched) && ( //!isObjectEmpty(formik.errors)
      <div className={formik.touched ? "bg-danger bg-opacity-10 rounded-1 mb-3" : ""}>
        {/* <h5 className="text-danger "> {i18n.t("errorTitle")}</h5> */}
        {
          Object.keys(formik.errors).map(k => {
            if (formik.touched[k]) {
              if (Array.isArray(formik?.errors[k])) {
                return formik?.errors[k].map((o, i) => {
                  if (o !== null && o !== undefined) {
                    return Object?.keys(o)?.map(ky => {
                      if (formik?.touched?.[k]?.[i]?.[ky]) {
                        return <p className="text-danger ps-2 m-0 p-0" key={`${k}_${ky}`}>{formik.errors[k][i][ky]?.toString() + " #" + (i + 1).toString()}</p>
                      }
                      return undefined;
                    })
                  }

                })
              } else if (typeof (formik?.errors[k]) === "object") {
                return Object?.keys(formik?.errors[k])?.map(ky => {
                  if (formik?.touched[k][ky]) {
                    return <p className="text-danger ps-2 m-0" key={`${k}_${ky}`}>{formik.errors[k][ky]?.toString()}</p>
                  }
                  return undefined;
                })

              } else {
                return <p className="text-danger ps-2 m-0" key={k}>{formik.errors[k]?.toString()}</p>
              }

            }

            return undefined;
          })
        }
      </div>
    )
  );
};

export const loginService = async (dispatch, data, sendBroadcastMessage, standByLogin) => {
  let response;
  response = await login(dispatch, data, sendBroadcastMessage, standByLogin);
  if (response.status === 0) {
    // clearCart && dispatch(clearCart());
    // if (response?.data?.distributorprofile?.distributorbookingallowed === "Y") {
    //   dispatch(setSubDistributorProfile(subDistributorProfileData));
    // }
  }
  else {
    console.log(response);
  }
  return response;
}


// export async function autoLogin(dispatch, clearCart, restrictAutoLogin, loginType) {
//   const state = store.getState();
//   let cartData = state?.cartRedux?.cartData;

//   let selectedMarketId = state?.marketRedux?.selectedMarketId;
//   let selectedMarketLanguage = state?.marketRedux?.selectedMarketLanguage;

//   let loginDetail = "";
//   let data = {};
//   let response = "";
//   let callAutoLogin = false;
//   let username = "";
//   let password = "";
//   let loggedInUserDetails = "";
//   let subDistributorProfileData = state?.loggedInUserDetailRedux?.subDistributorProfileData;

//   let OldLoggedInUserDetails = "";

//   // reading local storage data 
//   if (localStorage?.getItem("loginDetail")) {
//     loginDetail = decryptData("loginDetail");
//   }

//   // reading logged in user stored in redux
//   loggedInUserDetails = state?.loggedInUserDetailRedux?.loggedInUserDetails;
//   if (Object.keys(loggedInUserDetails).length !== 0) {
//     OldLoggedInUserDetails = JSON.parse(CryptoJS.AES.decrypt(loggedInUserDetails, SECRET_KEY)?.toString(CryptoJS.enc.Utf8));
//   }

//   if (Object.keys(loginDetail).length === 0) {

//     if (loginType === LOGIN_TYPE.priority_pass) {
//       username = PRIORITYPASS_LOGIN.username;
//       password = PRIORITYPASS_LOGIN.password;
//       callAutoLogin = true;
//     }
//     else if (loginType === LOGIN_TYPE.digicel_black_card) {
//       username = DIGICELBLACKCARD_LOGIN.username;
//       password = DIGICELBLACKCARD_LOGIN.password;
//       callAutoLogin = true;
//     }
//     else {
//       if (OldLoggedInUserDetails !== "" && OldLoggedInUserDetails?.username !== CUSTOMER_LOGIN.username && OldLoggedInUserDetails?.username !== PRIORITYPASS_LOGIN.username && OldLoggedInUserDetails?.username !== DIGICELBLACKCARD_LOGIN.username) {
//         username = OldLoggedInUserDetails.username;
//         password = OldLoggedInUserDetails.password;
//         callAutoLogin = true;
//       }
//       else {
//         username = CUSTOMER_LOGIN.username;
//         password = CUSTOMER_LOGIN.password;
//         callAutoLogin = true;
//       }
//     }
//   }

//   else if (cartData.length !== 0) {
//     if (loginType === LOGIN_TYPE.priority_pass && loginDetail?.username !== PRIORITYPASS_LOGIN.username) {
//       username = PRIORITYPASS_LOGIN.username;
//       password = PRIORITYPASS_LOGIN.password;
//       callAutoLogin = true;
//     }
//     else if (loginType === LOGIN_TYPE.digicel_black_card && loginDetail?.username !== DIGICELBLACKCARD_LOGIN.username) {
//       username = DIGICELBLACKCARD_LOGIN.username;
//       password = DIGICELBLACKCARD_LOGIN.password;
//       callAutoLogin = true;
//     }
//     else {
//       if (OldLoggedInUserDetails !== "" && OldLoggedInUserDetails?.username !== CUSTOMER_LOGIN.username && OldLoggedInUserDetails?.username !== PRIORITYPASS_LOGIN.username && OldLoggedInUserDetails?.username !== DIGICELBLACKCARD_LOGIN.username) {
//         if (OldLoggedInUserDetails?.username !== loginDetail?.username) {
//           username = OldLoggedInUserDetails.username;
//           password = OldLoggedInUserDetails.password;
//           callAutoLogin = true;
//         }
//         else {
//           callAutoLogin = false;
//         }
//       }
//       else {
//         if (loginDetail?.username !== CUSTOMER_LOGIN.username && loginDetail?.username !== PRIORITYPASS_LOGIN.username && loginDetail?.username !== DIGICELBLACKCARD_LOGIN.username) {
//           username = CUSTOMER_LOGIN.username;
//           password = CUSTOMER_LOGIN.password;
//           callAutoLogin = true;
//         }
//         else {
//           callAutoLogin = false;
//         }

//       }
//     }
//   }
//   else if (cartData.length === 0) {
//     if (loginType === LOGIN_TYPE.priority_pass) {
//       if (loginDetail?.username !== PRIORITYPASS_LOGIN.username) {
//         username = PRIORITYPASS_LOGIN.username;
//         password = PRIORITYPASS_LOGIN.password;
//         callAutoLogin = true;
//       }
//       else {
//         callAutoLogin = false;
//       }

//     }
//     else if (loginType === LOGIN_TYPE.digicel_black_card) {
//       if (loginDetail?.username !== DIGICELBLACKCARD_LOGIN.username) {
//         username = DIGICELBLACKCARD_LOGIN.username;
//         password = DIGICELBLACKCARD_LOGIN.password;
//         callAutoLogin = true;
//       }
//       else {
//         callAutoLogin = false;
//       }
//     }
//     else {
//       if (OldLoggedInUserDetails !== "" && OldLoggedInUserDetails?.username !== CUSTOMER_LOGIN.username) {
//         if (OldLoggedInUserDetails?.username !== loginDetail?.username) {
//           username = OldLoggedInUserDetails.username;
//           password = OldLoggedInUserDetails.password;
//           callAutoLogin = true;
//         }
//         else {
//           callAutoLogin = false;
//         }
//       }
//       else {
//         if (loginDetail?.username !== CUSTOMER_LOGIN.username) {
//           username = CUSTOMER_LOGIN.username;
//           password = CUSTOMER_LOGIN.password;
//           callAutoLogin = true;
//         }
//         else {
//           callAutoLogin = false;
//         }
//       }
//     }
//   }

//   data = {
//     username: username,
//     password: password,
//     marketid: selectedMarketId,
//     languageid: selectedMarketLanguage,
//     affiliateid: 0,
//   };

//   response = callAutoLogin && await loginService(dispatch, data, subDistributorProfileData);

//   return response;

// }

export async function autoLogin(dispatch, loginType, sendBroadcastMessage, forceLogin, standByLogin, affiliateId) {
  const state = store.getState();
  let cartData = state?.cartRedux?.cartData;

  let loginDetail = getLoginDetails(state);
  const affiliateData = get_affiliate_subaffiliate_data();
  let selectedMarketId = state?.marketRedux?.selectedMarketId;
  let selectedMarketLanguage = state?.marketRedux?.selectedMarketLanguage;

  let data = {};
  let response = "";
  let callAutoLogin = false;
  let username = "";
  let password = "";
  let loggedInUserDetails = "";
  let sessionId = "";
  let affiliateid = affiliateId || affiliateData?.affiliateid || 0;
  let loginAffiliateid = 0;
  let OldLoggedInUserDetails = "";

  // reading local storage data 
  if (loginDetail && Object.values(loginDetail).length !== 0) {
    sessionId = loginDetail?.sessionid;
  }

  // reading logged in user stored in redux
  loggedInUserDetails = state?.loggedInUserDetailRedux?.loggedInUserDetails;
  if (loggedInUserDetails) {
    OldLoggedInUserDetails = JSON.parse(CryptoJS.AES.decrypt(loggedInUserDetails, SECRET_KEY)?.toString(CryptoJS.enc.Utf8));
  }

  if (sessionId === "" || forceLogin || standByLogin) {
    if (loginType === LOGIN_TYPE.priority_pass) {
      username = PRIORITYPASS_LOGIN.username;
      password = PRIORITYPASS_LOGIN.password;
      callAutoLogin = true;
    }
    else if (loginType === LOGIN_TYPE.digicel_black_card) {
      username = DIGICELBLACKCARD_LOGIN.username;
      password = DIGICELBLACKCARD_LOGIN.password;
      callAutoLogin = true;
    }
    else if (loginType === LOGIN_TYPE.dragon_pass) {
      username = DRAGONPASSCARD_LOGIN.username;
      password = DRAGONPASSCARD_LOGIN.password;
      callAutoLogin = true;
    }
    else {
      if (OldLoggedInUserDetails !== "" && OldLoggedInUserDetails?.username !== CUSTOMER_LOGIN.username && OldLoggedInUserDetails?.username !== PRIORITYPASS_LOGIN.username && OldLoggedInUserDetails?.username !== DIGICELBLACKCARD_LOGIN.username && OldLoggedInUserDetails?.username !== DRAGONPASSCARD_LOGIN.username) {
        username = OldLoggedInUserDetails.username;
        password = OldLoggedInUserDetails.password;
        callAutoLogin = true;
      }
      else {
        username = CUSTOMER_LOGIN.username;
        password = CUSTOMER_LOGIN.password;
        loginAffiliateid = affiliateid;
        callAutoLogin = true;
      }
    }
  }

  else if (cartData.length !== 0) {
    if (loginType === LOGIN_TYPE.priority_pass && loginDetail?.username !== PRIORITYPASS_LOGIN.username) {
      username = PRIORITYPASS_LOGIN.username;
      password = PRIORITYPASS_LOGIN.password;
      callAutoLogin = true;
    }
    else if (loginType === LOGIN_TYPE.digicel_black_card && loginDetail?.username !== DIGICELBLACKCARD_LOGIN.username) {
      username = DIGICELBLACKCARD_LOGIN.username;
      password = DIGICELBLACKCARD_LOGIN.password;
      callAutoLogin = true;
    }
    else if (loginType === LOGIN_TYPE.dragon_pass && loginDetail?.username !== DRAGONPASSCARD_LOGIN.username) {
      username = DRAGONPASSCARD_LOGIN.username;
      password = DRAGONPASSCARD_LOGIN.password;
      callAutoLogin = true;
    }
    else {
      if (OldLoggedInUserDetails !== "" && OldLoggedInUserDetails?.username !== CUSTOMER_LOGIN.username && OldLoggedInUserDetails?.username !== PRIORITYPASS_LOGIN.username && OldLoggedInUserDetails?.username !== DIGICELBLACKCARD_LOGIN.username && OldLoggedInUserDetails?.username !== DRAGONPASSCARD_LOGIN.username) {
        if (OldLoggedInUserDetails?.username !== loginDetail?.username) {
          username = OldLoggedInUserDetails.username;
          password = OldLoggedInUserDetails.password;
          callAutoLogin = true;
        }
        else {
          callAutoLogin = false;
        }
      }
      else {
        if (loginDetail?.username !== CUSTOMER_LOGIN.username && loginDetail?.username !== PRIORITYPASS_LOGIN.username && loginDetail?.username !== DIGICELBLACKCARD_LOGIN.username && loginDetail?.username !== DRAGONPASSCARD_LOGIN.username) {
          username = CUSTOMER_LOGIN.username;
          password = CUSTOMER_LOGIN.password;
          loginAffiliateid = affiliateid;
          callAutoLogin = true;
        }
        else {
          callAutoLogin = false;
        }

      }
    }
  }
  else if (cartData.length === 0) {
    if (loginType === LOGIN_TYPE.priority_pass) {
      if (loginDetail?.username !== PRIORITYPASS_LOGIN.username) {
        username = PRIORITYPASS_LOGIN.username;
        password = PRIORITYPASS_LOGIN.password;
        callAutoLogin = true;
      }
      else {
        callAutoLogin = false;
      }

    }
    else if (loginType === LOGIN_TYPE.digicel_black_card) {
      if (loginDetail?.username !== DIGICELBLACKCARD_LOGIN.username) {
        username = DIGICELBLACKCARD_LOGIN.username;
        password = DIGICELBLACKCARD_LOGIN.password;
        callAutoLogin = true;
      }
      else {
        callAutoLogin = false;
      }
    }
    else if (loginType === LOGIN_TYPE.dragon_pass) {
      if (loginDetail?.username !== DRAGONPASSCARD_LOGIN.username) {
        username = DRAGONPASSCARD_LOGIN.username;
        password = DRAGONPASSCARD_LOGIN.password;
        callAutoLogin = true;
      }
      else {
        callAutoLogin = false;
      }
    }
    else {
      if (OldLoggedInUserDetails !== "" && OldLoggedInUserDetails?.username !== CUSTOMER_LOGIN.username) {
        if (OldLoggedInUserDetails?.username !== loginDetail?.username) {
          username = OldLoggedInUserDetails.username;
          password = OldLoggedInUserDetails.password;
          callAutoLogin = true;
        }
        else {
          callAutoLogin = false;
        }
      }
      else {
        if (loginDetail?.username !== CUSTOMER_LOGIN.username) {
          username = CUSTOMER_LOGIN.username;
          password = CUSTOMER_LOGIN.password;
          loginAffiliateid = affiliateid;
          callAutoLogin = true;
        }
        else {
          callAutoLogin = false;
        }
      }
    }
  }

  data = {
    username: username,
    password: password,
    marketid: selectedMarketId,
    languageid: selectedMarketLanguage,
    affiliateid: loginAffiliateid,
  };

  response = callAutoLogin && await loginService(dispatch, data, sendBroadcastMessage, standByLogin);

  return response;
}

export function isPaxCountUpdate() {
  const state = store.getState();
  let paxCountBookingDetails = state?.increasePaxCountRedux?.paxCountBookingDetails;
  let flag = false;
  flag = (Object?.values(paxCountBookingDetails)?.length !== 0) ? true : false;
  return flag;
}

export function check_magna_allowed(userDefault) {
  let affiliate_subaffiliate_data = get_affiliate_subaffiliate_data();
  let affiliateid = affiliate_subaffiliate_data.affiliateid;

  let is_magna_payment_method_allowed = false;
  if (userDefault?.username === CUSTOMER_LOGIN.username || userDefault?.subscriber === "Y") {
    if (affiliateid !== "!" || userDefault?.subscriber === "Y") {
      is_magna_payment_method_allowed = false;
    } else {
      const state = store.getState();
      const selectedMarket = state.marketRedux?.availableMarkets?.data?.markets?.find((m) => m.marketid === state.marketRedux?.selectedMarketId)
      if (selectedMarket?.magnaallowed === 'Y') {
        is_magna_payment_method_allowed = true;
      }
    }
  }
  if (isPaxCountUpdate()) {
    is_magna_payment_method_allowed = false;
  }
  return is_magna_payment_method_allowed;
}

export function is_member_card_payment_method_allowed(userDefault) {

  let is_member_card_payment_method_allowed = false;
  let affiliate_subaffiliate_data = get_affiliate_subaffiliate_data();
  let affiliateid = affiliate_subaffiliate_data.affiliateid;

  if (userDefault?.username === CUSTOMER_LOGIN.username || checkLoggedInUserIsLoungePartner() || userDefault?.subscriber === "Y") {
    if (affiliateid !== "!") {
      is_member_card_payment_method_allowed = false;
    } else {
      is_member_card_payment_method_allowed = true;
    }
  }
  // if (selected_market.topupallowed != 'Y') {
  //     is_member_card_payment_method_allowed = false;
  // }
  return is_member_card_payment_method_allowed;
}

export function addlog(source, orderId, addLogRequest) {
  addLogRequest.orderid = orderId;

  if (source === "OBI-MAIN") {
    addConfirmationLog(addLogRequest);
  } else if (source === "OBI-BILLING") {
    addBillPaymentLog(addLogRequest);
  } else if (source === "OBI-PRIORITYPASS") {
    addConfirmationLog(addLogRequest);
  } else if (source === "OBI-ADDMEMBERCARD") {
    addMemberCardLog(addLogRequest);
  } else if (source === "OBI-TOPUP") {
    addTopupAccountLog(addLogRequest);
  } else if (source === "OBI-TOPUPMEMBERCARD") {
    addTopupMemberCardLog(addLogRequest);
  } else if (source === "OBI-GIFTCARD") {
    addGiftLog(addLogRequest);
  } else if (source === "OBI-CREDITBOOKING") {
    addCreditBookingPaymentLog(addLogRequest);
  } else if (source === "OBI-STANDBY") {
    addConvertStandByLog(addLogRequest);
  } else if (source === "OBI-DIGICELBLACK") {
    addConfirmationLog(addLogRequest);
  }
}

export function omit(target, props) {
  return Object.fromEntries(
    Object.entries(target).filter(([key]) => !props.includes(key))
  )
}

export function findAndConcatMenuItems(menuItems, menuKey, menuUpdate) {
  if (!menuItems.find(item => item.key === menuKey)) {
    menuItems = menuItems.concat(menuUpdate);
  }
  return menuItems;
}

// export function findAndConcatMenuItems(menuItems, menuKey, menuUpdate) {
//   return produce(menuItems, draft => {
//     if (!draft.find(item => item.key === menuKey)) {
//       draft.push(...menuUpdate);
//     }
//   });
// }

export function checkLoggedInUserIsLoungePartner() {
  const state = store.getState();
  const loginDetail = getLoginDetails(state);
  let flag = false;
  if (!isObjectEmpty(loginDetail)) {
    LOUNGE_PARTNERS_PRODUCTS.forEach((data) => {
      if (loginDetail?.username === data?.userName) {
        flag = true
      }
    })
    return flag ? flag : false;
    // return (loginDetail?.username === PRIORITYPASS_LOGIN.username ? true : loginDetail?.username === DIGICELBLACKCARD_LOGIN.username ? true : false);
  }
  else
    return false;
}

export function getLoggedInUsername() {
  const state = store.getState();
  const loginDetail = getLoginDetails(state);
  if (!isObjectEmpty(loginDetail)) {
    return (loginDetail?.username);
  }
}

// export function isCurrentCartContainPriorityPass(cartData) {
//   let flag = false;
//   if (cartData?.length === 0 && isPriorityPassProduct())
//     flag = true;
//   else if ((cartData?.length === 0 && !isPriorityPassProduct()))
//     flag = false;
//   else {
//     cartData.forEach(p => {
//       (p?.value?.data?.passengerInfo.hasOwnProperty("priorityPass") ? flag = true
//         : flag = false)});
//   }
//   return flag;
// }

export function isCurrentCartContainOtherThanRegularProduct(cartData, otherPass) {
  let flag = false;
  cartData.forEach(p => {
    (p?.value?.data?.hasOwnProperty(otherPass) ? flag = true
      : flag = false)
  });
  return flag;
}

export const encryptData = (data) => {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
  store.dispatch(setLoggedInUserDetail(encrypted))
}

export const decryptData = () => {
  const state = store.getState();
  const encrypted = state.loggedInUserDetailRedux?.loggedInUserDetails;
  const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(CryptoJS.enc.Utf8);
  return JSON.parse(decrypted);
}



// export function summaryParser(data, type, response) {
// const result = {};
// if (data.arrivalbundles && Object.keys(data.arrivalbundles).length > 0) {
//   const arrivalbundles = data.arrivalbundles;
//   arrivalbundles.forEach((arrivalbundle, index) => {
//     const arrivalrecord = arrivalbundle.arrivalrecord || arrivalbundle.arrival;
//     const departurerecords = arrivalbundle.departurerecords || arrivalbundle.departurelist;
//     result[`arrivalrecords`] = arrivalrecord;
//     convertArrivalDate(result.arrivalrecords , `arrivaldate`,`flightdate`)
//     result[`departurerecords`] = departurerecords; 
//     convertArrivalDate(result.departurerecords , `arrivaldate`,`flightdate`)
//     updateBookingProductID(type)
//   });
// } else if (
//   (data.departurerecords && Object.keys(data.departurerecords).length > 0) ||
//   (data.departures && Object.keys(data.departures).length > 0)
// ) {
//   const departurerecords = data.departurerecords || data.departures;
//   result[`departurerecords`] = departurerecords;
//   if(type === "update_booking"){
//     result.departurerecords.productid ="departure only"
//   }
//   else{
//     result.departurerecords.productid = data.productid || response.data.productid;
//   }
//   convertArrivalDate(result.departurerecords , `arrivaldate`,`flightdate`)
// } else if (
//   (data.arrivalrecords && Object.keys(data.arrivalrecords).length > 0) ||
//   (data.arrivals && Object.keys(data.arrivals).length > 0)
// ) {
//   const arrivalrecords = data.arrivalrecords || data.arrivals;
//   result[`arrivalrecords`] = arrivalrecords;

//   if(type === "update_booking"){
//     result.arrivalrecords.productid ="arrival only"
//   }
//   else{
//     result.arrivalrecords.productid = data.productid || response.data.productid;
//   }
//   convertArrivalDate(result.arrivalrecords , `arrivaldate`,`flightdate`)
// }
// return result;
export function summaryParser(type, response) {
  let data = {};
  if (type === "UPDATE-FLIGHT-DETAILS") {
    data = response.data.order;
  }
  else if (type === "CREDITBOOKING") {
    data = response.data.orderdetail;
  }
  else if (type === "STANDBY-PAYMENT") {
    data = response.data.authenticated
  }
  else if (type === "INCREASE-PAX-COUNT") {
    data = response.data
  } else if (type === "SUBSCRIBER-UPDATE-FLIGHT-DETAILS") {
    data = response.data
  } else if (type === "UPDATE-FLIGHT-DETAILS-FORM") {
    data = response.data.bookings[0];
  }

  const convertArrivalDate = async (records, arrivaldate, flightdate) => {
    // for (let i = 0; i < records.length; i++) {
    if (records[arrivaldate]) {
      records[flightdate] = records[arrivaldate];
      delete records[arrivaldate];
    }
    else {

    }
    // }
  };
  const convertArrivalDate2 = async (records, arrivaldate, flightdate) => {
    for (let i = 0; i < records.length; i++) {
      if (records[i][arrivaldate]) {
        records[i][flightdate] = records[i][arrivaldate];
        delete records[i][arrivaldate];
      }
      else {

      }
    }
  };

  const convertDepartureDate = async (records, departuredate, flightdate) => {
    for (let i = 0; i < records.length; i++) {
      if (records[i][departuredate]) {
        records[i][flightdate] = records[i][departuredate];
        delete records[i][departuredate];
      }
    }
  };

  const convertPassengerList = async (records, passengerlist, guests) => {
    for (let i = 0; i < records.length; i++) {
      if (records[i][passengerlist]) {
        records[i][guests] = records[i][passengerlist];
        delete records[i][passengerlist];
      }
    }
  };

  const convertPassengerList2 = async (records, passengerlist, guests) => {
    if (records[passengerlist]) {
      records[guests] = records[passengerlist];
      delete records[passengerlist];
    }
  };

  const result = {};
  const arrivalbundles = data?.arrivalbundles;
  if (data?.arrivalbundles && Object.keys(data?.arrivalbundles).length > 0) {
    arrivalbundles.forEach((arrivalbundle, index) => {
      const arrivalrecord = arrivalbundle?.arrivalrecord || arrivalbundle?.arrival;
      const departurerecords = arrivalbundle?.departurerecords || arrivalbundle?.departurelist;
      result[`arrivalrecords`] = arrivalrecord;
      result[`departurerecords`] = departurerecords;

      if (type === "UPDATE-FLIGHT-DETAILS-FORM") {
        result.arrivalrecords.arrivaldate = myDateFormat(result.arrivalrecords?.arrivaldate, "yyyy-MM-dd HH:mm:ss.S", "yyyyMMdd HHmm");
        result.departurerecords[0].departuredate = myDateFormat(result.departurerecords[0]?.departuredate, "yyyy-MM-dd HH:mm:ss.S", "yyyyMMdd HHmm");
      }
      convertArrivalDate(result.arrivalrecords, `arrivaldate`, `flightdate`)
      convertPassengerList2(result.arrivalrecords, `passengerlist`, `guests`)

      convertDepartureDate(result.departurerecords, `departuredate`, `flightdate`)
      convertPassengerList(result.departurerecords, `passengerlist`, `guests`)

      result.arrivalrecords.productid = PRODUCTID_ARRIVALBUNDLE;
      result.departurerecords[0].productid = PRODUCTID_ARRIVALBUNDLE;

    });
  } else if (
    (data?.departurerecords && Object.keys(data?.departurerecords).length > 0) ||
    (data?.departures && Object.keys(data?.departures).length > 0)
  ) {
    const departurerecords = data?.departurerecords || data?.departures;
    result[`departurerecords`] = departurerecords;
    if (type === "UPDATE-FLIGHT-DETAILS") {
      result.departurerecords[0].productid = PRODUCTID_DEPARTURE
    }
    else {
      result.departurerecords[0].productid = data?.productid || response?.data?.productid;
    }

    if (type === "INCREASE-PAX-COUNT") {
      //  result.departurerecords[0].productid = PRODUCTID_DEPARTURE
      result.departurerecords[0] = { ...(result.departurerecords[0]), productid: PRODUCTID_DEPARTURE }
    }
    else {
      result.departurerecords[0].productid = data?.productid || response?.data?.productid;
    }
    if (type === "UPDATE-FLIGHT-DETAILS-FORM") {
      result.departurerecords[0].departuredate = myDateFormat(result.departurerecords[0]?.departuredate, "yyyy-MM-dd HH:mm:ss.S", "yyyyMMdd HHmm");
    }
    convertPassengerList(result.departurerecords, `passengerlist`, `guests`)
    convertDepartureDate(result.departurerecords, `departuredate`, `flightdate`)

  } else if (
    (data?.arrivalrecords && Object.keys(data?.arrivalrecords).length > 0) ||
    (data?.arrivals && Object.keys(data?.arrivals).length > 0)
  ) {
    let arrivalrecords = {};
    if (type === "STANDBY-PAYMENT" || type === "UPDATE-FLIGHT-DETAILS-FORM") {
      arrivalrecords = data?.arrivals;
    }
    else {
      arrivalrecords = data?.arrivalrecords[0] || data?.arrivals;
    }
    result[`arrivalrecords`] = arrivalrecords;

    if (type === "UPDATE-FLIGHT-DETAILS") {
      result.arrivalrecords.productid = PRODUCTID_ARRIVAL
    }
    else {
      result.arrivalrecords.productid = PRODUCTID_ARRIVAL;
    }

    if (type === "INCREASE-PAX-COUNT") {
      result.arrivalrecords = { ...(result.arrivalrecords), productid: PRODUCTID_ARRIVAL }
      // result.arrivalrecords.productid = PRODUCTID_ARRIVAL
    }
    else {
      result.arrivalrecords = { ...(result.arrivalrecords), productid: PRODUCTID_ARRIVAL }
      //  result.arrivalrecords.productid = PRODUCTID_ARRIVAL;
    }

    if (type === "STANDBY-PAYMENT") {
      convertPassengerList(result.arrivalrecords, `passengerlist`, `guests`)
      convertArrivalDate2(result.arrivalrecords, `arrivaldate`, `flightdate`)
    }
    if (type === "UPDATE-FLIGHT-DETAILS-FORM") {
      result.arrivalrecords[0].arrivaldate = myDateFormat(result.arrivalrecords[0]?.arrivaldate, "yyyy-MM-dd HH:mm:ss.S", "yyyyMMdd HHmm");
      convertPassengerList2(result.arrivalrecords[0], `passengerlist`, `guests`)
      convertArrivalDate(result.arrivalrecords[0], `arrivaldate`, `flightdate`)
      result.arrivalrecords = { ...(result.arrivalrecords[0]), productid: result.arrivalrecords.productid }
    }

  }
  return result;
}

export function isObjectEmpty(obj) {
  if (obj) {
    return Object.keys(obj).length === 0;
  }
}


export function getSubDistributorId() {
  let subDistributorid = "";

  const state = store.getState();
  let subDistributorProfile = state?.signInRedux?.subDistributorProfile;

  if (Object.keys(subDistributorProfile).length !== 0) {
    subDistributorid = subDistributorProfile?.distributorprofile?.distributorid;
  }

  return subDistributorid;
}

export const urlForRedirect = (productid, pageName = "", pathName = "") => {
  const symbol = "/";
  if (productid === PRODUCTID_ARRIVALBUNDLE) {
    if (checkLoggedInUserIsLoungePartner()) {
      return symbol + loungePartnerProductOperation("getRouteNameWithType","","ArrivalBundle");
    } else {
      return symbol + ROUTENAME_ARRIVALBUNDLE;
    }
  } else {
    if (productid === PRODUCTID_ARRIVAL) {
      if (checkLoggedInUserIsLoungePartner()) {
        return symbol + loungePartnerProductOperation("getRouteNameWithType","","Arrival");
      } else {
        return symbol + ROUTENAME_ARRIVAL;
      }
    } else {
      if(pageName !== ""){
        return pathName
      } else {
        if (checkLoggedInUserIsLoungePartner()) {
          return symbol + loungePartnerProductOperation("getRouteNameWithType","","Departure");
        } else {
          return symbol + ROUTENAME_DEPARTURE;
        }
      }
    }
  }
}

export function checkMarketAllowed(market, key) {
  if (market) {
    if (market.hasOwnProperty(key)) {
      return market[key];
    } else {
      return "";
    }
  }
}

export function showPriorityPassProduct() {
  let showPriorityPassProduct = false;

  let affiliate_subaffiliate_data = get_affiliate_subaffiliate_data();
  let affiliateid = affiliate_subaffiliate_data.affiliateid;

  if (affiliateid === "!") {
    const state = store.getState();
    const loginDetail = getLoginDetails(state);
    let userDefault = state?.signInRedux?.userDefault;
    if (loginDetail?.username) {
      const selectedMarket = state.marketRedux?.availableMarkets?.data?.markets?.find((m) => m.marketid === state.marketRedux?.selectedMarketId)
      if (selectedMarket?.prioritypassallowed === 'Y') {
        if (loginDetail?.username === CUSTOMER_LOGIN.username) {
          showPriorityPassProduct = true;
        } else if (loginDetail?.username === PRIORITYPASS_LOGIN.username) {
          showPriorityPassProduct = true;
        } else if (loginDetail?.username === DIGICELBLACKCARD_LOGIN.username) {
          showPriorityPassProduct = true;
        } else if (userDefault?.subscriber === "Y") {
          showPriorityPassProduct = false;
        }
      }
    } else {
      showPriorityPassProduct = true;
    }
  }

  return showPriorityPassProduct;
}

export function showDigicelBlackProduct() {
  let showDigicelBlackProduct = false;

  let affiliate_subaffiliate_data = get_affiliate_subaffiliate_data();
  let affiliateid = affiliate_subaffiliate_data.affiliateid;

  if (affiliateid === "!") {
    const state = store.getState();
    const loginDetail = getLoginDetails(state);
    let userDefault = state?.signInRedux?.userDefault;
    if (loginDetail?.username) {
      const selectedMarket = state.marketRedux?.availableMarkets?.data?.markets?.find((m) => m.marketid === state.marketRedux?.selectedMarketId)
      if (selectedMarket?.digicelcardallowed === 'Y') {
        if (loginDetail?.username === CUSTOMER_LOGIN.username) {
          showDigicelBlackProduct = true;
        } else if (loginDetail?.username === PRIORITYPASS_LOGIN.username) {
          showDigicelBlackProduct = true;
        } else if (loginDetail?.username === DIGICELBLACKCARD_LOGIN.username) {
          showDigicelBlackProduct = true;
        } else if (userDefault?.subscriber === "Y") {
          showDigicelBlackProduct = false;
        }
      }
    } else {
      showDigicelBlackProduct = true;
    }
  }

  return showDigicelBlackProduct;
}

export function translateArray(text_array, arr_name) {
  let translatedArray = [];
  translatedArray = text_array.map((text) => i18n.t('constants:' + arr_name + '.' + text));
  return translatedArray;
}

export function translateArrayKeyValue(text_array, arr_name) {
  const translatedArray = [];
  text_array?.forEach((v) => {
    translatedArray.push({ value: v.value, label: i18n.t('constants:' + arr_name + '.' + v.value) });
  });

  return translatedArray;
}

export function isSubDistributorProfile() {
  let flag;
  const state = store.getState();
  let subDistributorProfileData = state?.loggedInUserDetailRedux?.subDistributorProfileData;
  Object.values(subDistributorProfileData).length !== 0 ? flag = true : flag = false;
  return flag;
}

export function isCurrentCartContainGiftItem() {
  const state = store.getState();
  let giftCartData = state?.giftCartRedux?.giftCartData;
  let flag;
  giftCartData.length > 0 ? flag = true : flag = false;
  return flag;
}

export function getDistributorSubDistributorProfile() {
  const state = store.getState();
  const subDistributorProfile = state?.signInRedux?.subDistributorProfile;
  const distributorProfile = state?.signInRedux?.distributorProfile;

  if (subDistributorProfile && Object.keys(subDistributorProfile).length !== 0) {
    return subDistributorProfile?.distributorprofile;
  } else {
    return distributorProfile;
  }
}

export function getAdultMinimumAge() {
  let adultminimum = ADULT_MINIMUM;
  // const state = store.getState();
  // const distributorProfile = state?.signInRedux?.distributorProfile;
  const distributorProfile = getDistributorSubDistributorProfile();
  if (distributorProfile?.adultminimum) {
    adultminimum = distributorProfile?.adultminimum;
    adultminimum = adultminimum - 1;
  }

  return adultminimum;
}
export function getChildMinimumAge() {
  let childminimum = CHILD_MINIMUM;
  const distributorProfile = getDistributorSubDistributorProfile();
  if (distributorProfile?.childminimum) {
    childminimum = distributorProfile?.childminimum;
  }
  return childminimum;
}
export function getAdultDisplayAge() {
  let adultminimum = getAdultMinimumAge();

  adultminimum = "(" + i18n.t('above') + " " + adultminimum + ")";
  return adultminimum;
}

export function getChildDisplayAge() {
  let age_range = getChildAgeRange();
  return " (" + age_range + ")" + i18n.t('years');
}

export function getChildAgeRange() {
  let adultminimum = ADULT_MINIMUM;
  let childminimum = CHILD_MINIMUM;
  let age_range;
  // const state = store.getState();
  // const distributorProfile = state?.signInRedux?.distributorProfile;
  const distributorProfile = getDistributorSubDistributorProfile();

  if (distributorProfile?.adultminimum) {
    adultminimum = distributorProfile?.adultminimum;
  }
  if (distributorProfile?.childminimum) {
    childminimum = distributorProfile?.childminimum;
  }
  if (!distributorProfile) {
    age_range = childminimum + "-" + adultminimum;
  } else {
    adultminimum = adultminimum - 1;
    age_range = childminimum + "-" + adultminimum;
  }

  return age_range;
}

export function getInfantDisplayAge() {
  let childminimum = getInfantAge();

  return "(" + i18n.t('under') + " " + childminimum + ")";
}

export function getInfantAge() {
  let childminimum = CHILD_MINIMUM;
  // const state = store.getState();
  // const distributorProfile = state?.signInRedux?.distributorProfile;

  const distributorProfile = getDistributorSubDistributorProfile();

  if (distributorProfile?.childminimum) {
    childminimum = distributorProfile?.childminimum;
  }

  return childminimum;
}

export function is_manual_payment_method_allowed(userDefault) {
  let is_manual_payment_method_allowed = false;
  if (checkLoggedInUserIsLoungePartner()) {
    is_manual_payment_method_allowed = true;
  }
  return is_manual_payment_method_allowed;
}

export async function getLoggedInUserMappedMemberCards(productId) {
  let arr_Request = {}
  let arr_Cart = {};
  const state = store.getState();
  let cartData = state?.cartRedux?.cartData;

  if(productId) {
    const tmpProductId = productId?.toLowerCase();
    arr_Cart[tmpProductId] = "Y";
  } else {
    cartData?.map((product) => {
      const cartItem = product?.value.currentCartItem;
      const productid = cartItem?.productid?.toLowerCase();
      arr_Cart[productid] = "Y";
    });
  }


  arr_Request["cart"] = arr_Cart;
  var data = JSON.parse(JSON.stringify(arr_Request));
  let response = [];
  try {
    const res = await getmappedmembercards(data);
    response = res?.data
    //setLinkCardData([...response?.data]); // Create a new array with the fetched data
  } catch (error) {
    // console.error("Error fetching data:", error);
  } finally {
  }

  return response;
}

export function convertAmountToUSD(amount) {
  const state = store.getState();
  const selectedMarket = state.marketRedux?.availableMarkets?.data?.markets?.find((m) => m.marketid === state.marketRedux?.selectedMarketId)
  const exchangerate = selectedMarket?.exchangerate;
  const usdAmount = (amount / exchangerate).toFixed(2);

  return usdAmount;
}

export function convertAmountToUSDFloat(amount) {
  const usdAmount = parseFloat(convertAmountToUSD(amount));
  return usdAmount;
}

export async function stripBlankNamesArray(names, delimiter = ",") {
  const arr = [];
  const arrNames = names.split(delimiter);

  arrNames.forEach((name) => {
    name = name.trim();
    if (name !== null || name !== "") {
      arr.push(name);
    }
  });

  return arr;
}

export function passwordValidationErrorMessages() {
  return i18n.t("changepassword:errors.newPassword.notValid", {
    specialCharacters: PASSWORD_POLICY.specialCharacter.characters,
    minLowerCase: PASSWORD_POLICY.lowerCase.length,
    minUpperCase: PASSWORD_POLICY.upperCase.length,
    minDigit: PASSWORD_POLICY.digit.length,
    minSpecialCharacters: PASSWORD_POLICY.specialCharacter.length,
  })
}

export function passwordValidationErrorExpresssion() {
  return function (value) {
    const pattern = new RegExp(
      `^(?=(?:[^${PASSWORD_POLICY.specialCharacter.characters}]*[${PASSWORD_POLICY.specialCharacter.characters}]){${PASSWORD_POLICY.specialCharacter.length},})(?=(?:[^a-z]*[a-z]){${PASSWORD_POLICY.lowerCase.length}})(?=(?:[^A-Z]*[A-Z]){${PASSWORD_POLICY.upperCase.length}})(?=(?:\\D*\\d){${PASSWORD_POLICY.digit.length}}).*$`
    );
    return pattern.test(value);
  }
}
export function getMarketIdFromAirportName(airportid) {
  let marketId = "";
  const state = store.getState();
  let markets = state?.marketRedux?.availableMarkets?.data?.markets;
  markets?.forEach((airport) => {
    airport?.airports?.forEach((airportId) => {
      if (airportId?.airportid === airportid) {
        marketId = airport?.marketid
      }
    });
  });
  return marketId
}

export function getMarketNameFromMarketId(marketId) {
  let marketName = "";
  const state = store.getState();
  let markets = state?.marketRedux?.availableMarkets?.data?.markets;
  markets?.forEach((market) => {
    if (market?.marketid === marketId) {
      marketName = market?.marketname
    }
  });
  return marketName;
}

export function getCurrencyCodeFromMarket(marketId) {
  let currencyCode = "";
  const state = store.getState();
  let markets = state?.marketRedux?.availableMarkets?.data?.markets;
  markets?.forEach((airport) => {
    if (airport?.marketid === marketId) {
      currencyCode = airport?.currencycode
    }
  });
  return currencyCode
}

export function updatePassword(loginDetail, data) {
  const loginDetailUpdate = {
    username: loginDetail?.username,
    password: data.newpassword,
    sessionid: loginDetail?.sessionid,
  };
  // ******* save login detail into local storage ********//
  encryptData(loginDetailUpdate);
}


export function checkSession(dispatch, sendBroadcastMessage, navigate) {
  const state = store.getState();
  const session = state?.signInRedux?.session;
  const extendModalOccurrence = session?.extendModalOccurrence;
  if (session !== undefined && session?.startTime !== null) {
    const currentTime = new Date();
    currentTime.setMilliseconds(0);
    let showErrorFlag = false;
    const sessionEndTime = new Date(session.endTime)
    const showExtendModalTime = new Date(sessionEndTime.getTime() - SESSION.extendConfigrationBefore * 60000)

    if ((currentTime.getTime() === showExtendModalTime.getTime() && currentTime.toDateString() === showExtendModalTime.toDateString()) && extendModalOccurrence < SESSION.extendModalLimit) {
      showConfirm(i18n.t("sessionExpiring.title"), i18n.t("sessionExpiring.message", { expireTime: SESSION.extendConfigrationBefore })).then((res) => {
        if (res.isConfirmed) {
          extendSession().then((response) => {
            if (response.status === 0) {
              dispatch(updateSession({ extendTime: response.data.extendedtime }))
            } else {
              showError(i18n.t("error"),response.statusMessage)
            }
          }).catch(() => {
            showError(i18n.t("error"), i18n.t("unableExtend"))
          })
        }
      }).catch(() => {
        HandleAPIError();
      });
    }

    if (currentTime >= sessionEndTime) {
      showErrorFlag = true;
      mySwal.clickCancel()
      closeModal();
      dispatch(clearCart());
      dispatch(giftCartclear());
      dispatch(paxCountClear());
      dispatch(clearSubDistributorProfile());
      dispatch(removeSession());
      dispatch(standByLogout());
      dispatch(clearDemographicInformation());
      navigate("/", { state: showErrorFlag });
      sendBroadcastMessage({
        sender: "sessionExpired",
        content: { title: i18n.t('sessionExpired.title'), message: i18n.t("sessionExpired.message") },
        redirectTo: '/',
        state: showErrorFlag
      })
    }
  }
}


export function getSelectedMarketDetails() {
  const state = store.getState();
  const data = {
    marketId: state?.marketRedux?.selectedMarketId,
    marketCurrency: state?.marketRedux?.selectedMarketCurrency,
    marketLanguage: state?.marketRedux?.selectedMarketLanguage
  }
}

export async function marketChangeBroadcast(sendBroadcastMessage, market, cartClear) {
  const state = store.getState()
  const selectedMarketId = state?.marketRedux?.selectedMarketId;
  const selectedMarketData = state?.marketRedux?.availableMarkets?.data?.markets?.filter((x) => x.marketid === market)[0]
  const user = state?.signInRedux?.logindetails?.username;
  let broadcastPending = true
  if (market !== selectedMarketId && selectedMarketData) {
    VALIDATE_LOGIN_DATA.forEach(({ product, productAllowed, userName }) => {
      if (selectedMarketData[productAllowed] === "N" && user === userName) {
        broadcastPending = false;
        sendBroadcastMessage({
          sender: "validateLogin",
          content: {
            title: i18n.t('validateLogin.title'), message: i18n.t("validateLogin.message", {
              product: product,
              marketName: selectedMarketData.marketname
            })
          },
          redirectTo: "/"
        })
        showInfo(i18n.t('validateLogin.title'), i18n.t("validateLogin.message", { product: product, marketName: selectedMarketData.marketname }))
        store.dispatch(logout());
        store.dispatch(standByLogout());
        store.dispatch(clearCart());
        store.dispatch(giftCartclear());
        store.dispatch(paxCountClear());
        store.dispatch(clearLoggedInUserDetail());
      }
    })
    if (broadcastPending) {
      broadcastPending = false;
      sendBroadcastMessage({
        sender: "marketChange",
        content: { title: i18n.t('marketChange.title'), message: i18n.t("marketChange.message") },
        redirectTo: "/"
      })
      if (cartClear === true) {
        getLoginDetails()?.sessionid && await clearVIPERCart();
        store.dispatch(clearCart());
        store.dispatch(giftCartclear());
      }
    }
  }
}

export function sessionChangeBroadcast(sendBroadcastMessage) {
  sendBroadcastMessage({
    sender: "sessionChange",
    content: { title: i18n.t('sessionChange.title'), message: i18n.t("sessionChange.message") },
    redirectTo: "/"
  })
}

export function getTmpSelectedMarkets(club) {
  let markets = "";
  const state = store.getState();
  let availableMarkets = state?.marketRedux?.availableMarkets;
  let noloungeFound = true;
  availableMarkets?.data?.markets?.map((market) => {
    let tmpmarket = market;
    market.airports?.map((airport) => {
      if (club === airport.lounge) {
        noloungeFound = false
        markets = tmpmarket
      }
    });
  });
  if (noloungeFound) {
    markets = availableMarkets?.data?.markets?.filter((x) => x?.marketid === availableMarkets?.data?.defaultmarket)[0]
  }
  return markets;
}

export const divRef = React.createRef();

export const scrollToDiv = (e, formik) => {
  e.preventDefault();
  formik.handleSubmit();
  if (divRef.current) {
    divRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};

export async function subDistributorProfileBroadcast(
  sendBroadcastMessage,
  distributorId
) {
  let setSubdistributor = false;
  const state = store.getState();
  const subDistributorProfile =
    state?.signInRedux?.subDistributorProfile?.distributorprofile
      ?.distributorid;
  const cartData = state?.cartRedux?.cartData;
  const giftCartData = state?.giftCartRedux?.giftCartData;
  if (subDistributorProfile !== distributorId.value) {
    if (cartData.length === 0 && giftCartData.length === 0) {
      // formik.setFieldValue('distributorId', distributorId)
      setSubdistributor = true;
      sendBroadcastMessage({
        sender: "subDistributorChange",
        content: {
          title: i18n.t("subDistributorChange.title"),
          message: i18n.t("subDistributorChange.message"),
        },
        redirectTo: "/",
      });
    } else if (cartData.length !== 0 || giftCartData.length !== 0) {
      const res = await showConfirm(
        i18n.t("distributorbookinguser:subDistributorChange.title"),
        i18n.t("distributorbookinguser:subDistributorChange.message")
      );
      if (res.isConfirmed) {
        setSubdistributor = true;
        getLoginDetails()?.sessionid && await clearVIPERCart();
        store.dispatch(clearCart());
        store.dispatch(giftCartclear());
        sendBroadcastMessage({
          sender: "subDistributorChange",
          content: {
            title: i18n.t("subDistributorChange.title"),
            message: i18n.t("subDistributorChange.message"),
          },
          redirectTo: "/",
        });
      }
    }
  }
  else {
    setSubdistributor = true;
  }
  return setSubdistributor;
}


export function dateDifferenceInMonth(currentDate, flightDate) {
  const diffMonth = differenceInMonths(
    new Date(flightDate),
    new Date(dateFormatForDisplay(currentDate, "MM/dd/yyyy"))
  );

  return diffMonth;
};

export function checkCartItemUse(
  cartData,
  currentCartItemId,
  navigate,
  isGiftCard
) {
  if (isGiftCard) {
    let isItemAvailable = cartData?.find(
      (c) => c.giftCartItemId === currentCartItemId
    )
      ? false
      : true;
    if (isItemAvailable) {
      showInfo(
        i18n.t("itemNotFound.title"),
        i18n.t("itemNotFound.message")
      ).then((res) => {
        if (res.isConfirmed || res.dismiss) {
          navigate("/");
        }
      });
    }
  } else {
    let cartId = currentCartItemId;
    if (cartId) {
      let isItemAvailable = cartData?.some((obj) => obj.key === cartId);
      if (isItemAvailable === false) {
        showInfo(
          i18n.t("itemNotFound.title"),
          i18n.t("itemNotFound.message")
        ).then((res) => {
          if (res.isConfirmed || res.dismiss) {
            navigate("/");
          }
        });
        cartId = 0;
      }
    }
  }
}

export function getAirportNamesArrayForGroupBoooking() {
  const airportList = [];
  const state = store.getState();
  let markets = state?.marketRedux?.availableMarkets?.data?.markets;
  markets?.forEach((market) => {
    market.airports?.map((airport) => {
      airportList.push({
        key: airport?.airportid,
        name: airport?.airport
      });
    });
  });
  return airportList
};

export function checkForLoggedInUserSessionExist() {
  const state = store.getState();
  let logindetails = state.signInRedux?.logindetails;
  let currentSessionId = "";
  if (!isObjectEmpty(logindetails)) {
    currentSessionId = logindetails?.sessionid;
  }
  return currentSessionId;
}

export function checkMarketIDExistorNot(marketId) {
  const state = store.getState();
  let tmpmarketID = state?.marketRedux?.availableMarkets?.data?.defaultmarket;
  let markets = state?.marketRedux?.availableMarkets?.data?.markets;
  if (marketId) {
    markets?.forEach((market) => {
      if (market?.marketid === marketId) {
        tmpmarketID = market?.marketid;
      }
    });
    return tmpmarketID;
  }
  return null;
}

export function log(eventName, data, operation, error,url) {
  const date = new Date();
  const details = JSON.parse(JSON.stringify(data));
  if (eventName === "processcard") {
    if (details?.request?.creditcard) {
      let creditCardNumber = details.request.creditcard.cardnumber?.substr(-4);
      if (details?.request?.creditcard?.cvv) {
        delete details.request.creditcard.cvv;
      }
      details.request.creditcard.cardnumber = creditCardNumber;
    }
  }
  const dataObj = {
    details,
    operation,
    url: window.location.href,
    time: date.toISOString(),
    sessionid: details?.sessionid || details?.data?.sessionid,
    status: details?.status,
    error
  }

  const logObj = {
    logid: eventName,
    logmessage:
      operation === "request"
        ? url + " ? " + JSON.stringify(details)
        : url + " => " + JSON.stringify(details),
  };

  addLog(logObj)
  
  mixpanel.track(eventName, dataObj)
}
export function getLoginDetails() {
  const state = store.getState();
  let result = state?.signInRedux?.logindetails
  STAND_BY_LINKS.forEach((regex) => {
    if (window.location.pathname.match(regex)) {
      result = state?.standByRedux?.logindetails
    }
  })
  return result
}

export function changeUrlAccordingUser(pathname) {
  const state = store.getState();
  if (!isObjectEmpty(subDistributorProfile(state)) || pathname === "/distributor-booking-user" || state?.signInRedux?.distributorProfile?.distributorbookingallowed === "Y") {
    return true
  } else if (pathname === "/login" || pathname === "/subscriber-login") {
    return false
  }
};

export function getProductId(productId) {
  let tmpProductId;
  if (loungePartnerProductOperation("checkExistenceWithType", productId, "ArrivalBundle")) {
    tmpProductId = PRODUCTID_ARRIVALBUNDLE;
  } else if (loungePartnerProductOperation("checkExistenceWithType", productId, "Arrival")) {
    tmpProductId = PRODUCTID_ARRIVAL;
  } else if (loungePartnerProductOperation("checkExistenceWithType", productId, "Departure"))
    tmpProductId = PRODUCTID_DEPARTURE;
  else {
    tmpProductId = productId;
  }

  return tmpProductId;
};

export function secondaryContactDetailsSchemaFilled(secondaryContactDetails, type) {
  return object().shape({
    firstName: type === "UPDATE-CONTACT-DETAILS" ? string().trim().required(i18n.t("registration:errors.secondaryContactUpdateContactFullName.required")) : string().trim().required(i18n.t("registration:errors.secondaryContactFirstName.required")),
    lastName: type !== "UPDATE-CONTACT-DETAILS" && string().trim().required(
      i18n.t("registration:errors.secondaryContactLastName.required")
    ),
    email: string()
      .trim().required(
        i18n.t("registration:errors.secondaryContactEmail.required")
      )
      .matches(
        EMAIL_POLICY,
        i18n.t("registration:errors.secondaryContactEmail.match")
      ),
    confirmEmail: string()
      .trim().required(
        i18n.t("registration:errors.secondaryContactConfirmEmail.required")
      )
      .matches(
        EMAIL_POLICY,
        i18n.t("registration:errors.secondaryContactConfirmEmail.match")
      )
      .test(
        "email-validate",
        i18n.t(
          "registration:errors.secondaryContactConfirmEmailEqual.equal"
        ),
        (confirmEmail) => {
          if (
            (secondaryContactDetails.email &&
              confirmEmail) === ""
          ) {
            return true;
          } else {
            let flag =
              confirmEmail === secondaryContactDetails.email
                ? true
                : false;
            if (!flag) {
              return false;
            }
            return true;
          }
        }
      ),
    phone: string().trim().required(
      i18n.t("registration:errors.secondaryContactPhoneNumber.required")
    ),
  })
}

export function secondaryContactDetailsSchemaToggle(setSecondaryContactDetails, secondaryContactDetails, type = "") {
  if (secondaryContactDetails) {
    const isShowErrors = Object.keys(
      secondaryContactDetails
    ).some((key) => {
      if (key !== "title" && key !== "errors") {
        return secondaryContactDetails[key];
      }
      return false;
    });
    if (isShowErrors) {
      setSecondaryContactDetails(secondaryContactDetailsSchemaFilled(secondaryContactDetails, type));
    } else {
      setSecondaryContactDetails(object().nullable());
    }
  }
}

export const checkingCartItemsForPassengerDetailsAvailable = (cartData) => {
  let flag = true;
  cartData?.forEach((product) => {
    flag = product?.value?.data?.passengerInfo !== "" ? false : true;
  })
  return flag;
}


export const checkForCartItem = (location, navigate) => {
  const state = store.getState();
  const cartData = state?.cartRedux?.cartData;
  const giftCartData = state?.giftCartRedux?.giftCartData;
  if (location.pathname === "/payment") {
    if (cartData?.length === 0)
      navigate("/");
    else
      checkingCartItemsForPassengerDetailsAvailable(cartData) && navigate("/bookingSummary");
  }
  if (location.pathname === "/gift-payment" && giftCartData?.length === 0)
    navigate("/giftcard");
}

export function isCartEmpty() {
  const state = store.getState();
  const cartData = state?.cartRedux?.cartData
  const giftCartData = state?.giftCartRedux?.giftCartData

  if (cartData?.length === 0 && giftCartData?.length === 0) {
    return true;
  } else if (cartData?.length !== 0 || giftCartData?.length !== 0) {
    return false;
  }
}

export function logoutOldSession(dispatch, sendBroadcastMessage) {
  if (getLoginDetails()?.sessionid) {
    logout();
    closeSession(sendBroadcastMessage)
  } else {
    closeSession(sendBroadcastMessage)
  }
}


export function loginFunction(navigate, location, sendBroadcastMessage, dispatch, forceLogin, standByLogin) {
  const state = store.getState();
  // const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
  const loggedInUserDetails = state?.loggedInUserDetailRedux?.loggedInUserDetails;
  let loginType = "";
  let OldLoggedInUserDetails = "";
  if (loggedInUserDetails) {
    OldLoggedInUserDetails = JSON.parse(CryptoJS.AES.decrypt(loggedInUserDetails, SECRET_KEY)?.toString(CryptoJS.enc.Utf8));
    loginType = (OldLoggedInUserDetails.username === CUSTOMER_LOGIN.username || loungePartnerProductOperation("isLoungePartnerLogin","","",OldLoggedInUserDetails?.username)) ? LOGIN_TYPE.regular : "";
  }
  else {
    loginType = LOGIN_TYPE.regular;
  }

  checkSession(dispatch, sendBroadcastMessage, navigate)
  let restrictAutoLogin = RESTRICTED_PATHNAME_FOR_AUTOLOGIN?.find((regex) => regex.test(location.pathname)) ? true : false;

  if (!restrictAutoLogin || forceLogin) {
    autoLogin(dispatch, loginType, sendBroadcastMessage, false, standByLogin)
      .then((res) => {
      })
      .catch((e) => console.log(e));
  }
}

export function getLanguageIdFromAirportName(airportid) {
  let languageid = "";
  const state = store.getState();
  let markets = state?.marketRedux?.availableMarkets?.data?.markets;
  markets?.forEach((airport) => {
    airport?.airports.forEach((airportId) => {
      if (airportId?.airportid === airportid) {
        languageid = airport?.language
      }
    });
  });
  return languageid
}

export function maxSeatsChecker(productid, adultCount, childCount) {
  const distributorProfile = getDistributorSubDistributorProfile();
  if (adultCount || childCount) {
    let maxPaxCount = parseInt(adultCount.value) + parseInt(childCount.value);
    if (productid === PRODUCTID_DEPARTURE || productid === PRODUCTID_PRIORITYPASS || productid === PRODUCTID_DIGICELBLACKCARD) {
      if (maxPaxCount > SINGLE_BOOKING_MAX_COUNT_DEPARTURE) {
        showInfo(i18n.t("maxSeatAllowed"), i18n.t('common:maxPaxCount', { maxPaxCountBooking: SINGLE_BOOKING_MAX_COUNT_DEPARTURE }));
        return false;
      }
      return true;
    } else if (productid === PRODUCTID_ARRIVAL || productid === PRODUCTID_ARRIVALBUNDLE) {
      if (maxPaxCount > distributorProfile.arrivalmaxseats) {
        showInfo(i18n.t("maxSeatAllowed"), i18n.t('common:maxPaxCount', { maxPaxCountBooking: distributorProfile.arrivalmaxseats }));
        return false;
      }
      return true;
    }
  }
}

export function checkClubExistorNot(club) {
  const state = store.getState();
  let markets = state?.marketRedux?.availableMarkets?.data?.markets;
  let defaultMarket = state?.marketRedux?.availableMarkets?.data?.defaultmarket;
  let tmpClub;

  if (club) {
    markets?.forEach((market) => {
      market.airports?.forEach((airport) => {
        if (airport?.lounge.toLowerCase() === club) {
          tmpClub = airport?.lounge;
        } else {
          if (market.marketid === defaultMarket) {
            tmpClub = market.airports[0]?.lounge
          }
        }
      });
    });
    return tmpClub;
  }
  return null;
}

export function isExternalLink(location) {
  let externalLink = false
  EXTERNAL_LINKS?.forEach((link) => {
    if (location.pathname.match(link)) {
      externalLink = true;
    }
  })
  return externalLink;
}

export function externalLogin(
  location,
  sendBroadcastMessage,
  navigate,
  dispatch,
  setIsComplete,
  affiliateid
) {
  const externalLink = isExternalLink(location);
  const state = store.getState();
  const sessionId = state?.signInRedux?.logindetails?.sessionid;
  const authed = state?.signInRedux?.authed
  if (externalLink) {
    let loginType = LOGIN_TYPE.regular;
    if (sessionId || authed) {
      showConfirm(i18n.t('externalLoginSessionChange'), i18n.t('externalLinkSessionChange')).then((res) => {
        if (res.isConfirmed) {
          logoutOldSession(dispatch, sendBroadcastMessage);
          autoLogin(dispatch, loginType, sendBroadcastMessage, true, "", affiliateid)
            .then((res) => { })
            .catch((e) => console.log(e));
          if (setIsComplete) {
            setIsComplete(true);
          }
        } else {
          navigate(-1);
        }
      });
    } else {
      autoLogin(dispatch, loginType, sendBroadcastMessage, true, "", affiliateid)
        .then((res) => {
          if (setIsComplete) {
            setIsComplete(true);
          }
        })
        .catch((e) => console.log(e));
    }
  } else {
    if (setIsComplete) {
      setIsComplete(true);
    }
  }
}

export function getLoungeTypeAccordingMarket(marketid) {
  const state = store.getState();
  const productDropdown = [];
  let markets = state?.marketRedux?.availableMarkets?.data?.markets;

  markets?.forEach((market) => {
    if (market.marketid === marketid) {
      if (market?.arrivalbundleallowed === "Y") {
        if (!productDropdown.some((item) => item.label === i18n.t('common:loungTypeArrival'))) {
          productDropdown.push({ value: "A", label: i18n.t('common:loungTypeArrival') });
        }
        if (!productDropdown.some((item) => item.label === i18n.t('common:loungTypeDeparture'))) {
          productDropdown.push({ value: "D", label: i18n.t('common:loungTypeDeparture') });
        }
      }
      if (market?.arrivalonlyallowed === "Y" && !productDropdown.some((item) => item.label === i18n.t('common:loungTypeArrival'))) {
        productDropdown.push({ value: "A", label: i18n.t('common:loungTypeArrival') });
      }
      if (market?.departureloungeallowed === "Y" && !productDropdown.some((item) => item.label === i18n.t('common:loungTypeDeparture'))) {
        productDropdown.push({ value: "D", label: i18n.t('common:loungTypeDeparture') });
      }
    }
  });

  return productDropdown;
}

export function getProductDropdownAccordingMarket(marketid) {
  const state = store.getState();
  const productDropdown = [];
  let markets = state?.marketRedux?.availableMarkets?.data?.markets;

  markets?.forEach((market) => {
    if (market.marketid === marketid) {
      if (market?.arrivalbundleallowed === "Y" && !productDropdown.some((item) => item.label === PRODUCTNAME_ARRIVALBUNDLE)) {
        productDropdown.push({ value: PRODUCTID_ARRIVALBUNDLE, label: PRODUCTNAME_ARRIVALBUNDLE });
      }
      if (market?.arrivalonlyallowed === "Y" && !productDropdown.some((item) => item.label === PRODUCTNAME_ARRIVAL)) {
        productDropdown.push({ value: PRODUCTID_ARRIVAL, label: PRODUCTNAME_ARRIVAL });
      }
      if (market?.departureloungeallowed === "Y" && !productDropdown.some((item) => item.label === PRODUCTNAME_DEPARTURE)) {
        productDropdown.push({ value: PRODUCTID_DEPARTURE, label: PRODUCTNAME_DEPARTURE });
      }
    }
  });

  return productDropdown;
}

export function validExpiryOrNot(cardYear, cardMonth, bookingDate = "") {
  // This function takes the month in number and javascript takes months [ 0-11 ], thats why 10 becomes november here, 
  // therefore we check midnight of 1st of nov to last date of october (i.e 31 oct ). 
  let compareDate = bookingDate !== "" ? bookingDate : dateFormatForDisplay(new Date(), "MM/dd/yyyy")
  if (!cardMonth || !cardYear) {
    return false
  }
  var d = new Date(cardYear, (cardMonth).toString(1, 2), 1, 0, 0, 0, 0);
  let flag = (new Date(dateFormatForDisplay(d, "MM/dd/yyyy")) > new Date(compareDate)) ? true : false;
  if (!flag) {
    return false
  }
  return true

}
export function findDuplicates(arr) {
  const duplicateElements = {};
  const result = [];
  for (const item of arr) {
    if (duplicateElements[item]) {
      if (duplicateElements[item] === 1) {
        result.push(item);
      }
      duplicateElements[item]++;
    } else {
      duplicateElements[item] = 1;
    }
  }

  return result;
}

function allKeysAreEmptyString(obj) {
  let result = true
  Object.keys(obj).filter((obj) => {
    if (obj !== "") {
      result = false;
    }
  })
  return result;
}


export function getDuplicatesIndex(array) {
  let arr = [];
  for (let i = 0; i < array.length; i++) {
    if (array[i]?.cardNumber || array[i]?.cardNumber === "") {
      arr.push(array[i]?.cardNumber)
    }
  }
  const duplicateIndexes = {};

  for (let i = 0; i < arr.length; i++) {
    const element = arr[i];
    if (duplicateIndexes[element]) {
      duplicateIndexes[element].push(i);
    } else {
      duplicateIndexes[element] = [i];
    }
  }
  const isEmpty = allKeysAreEmptyString(duplicateIndexes);

  const result = Object.values(duplicateIndexes)
    .filter(indexes => indexes.length > 1)
    .reduce((acc, indexes) => acc.concat(indexes), []);

  if (isEmpty) {
    return []
  } else {
    return result;
  }
}

export const isCardNumberUnique = (array) => {
  if (array) {
    let arr = [];
    for (let i = 0; i < array.length; i++) {
      if (array[i]?.cardNumber) {
        arr.push(array[i]?.cardNumber)
      }
    }
    const duplicateElements = findDuplicates(arr);
    if (duplicateElements.length !== 0) {
      return false;
    }
    return true;
  }
};

export function translateConstantText(text) {
  let translatedText = "";
  translatedText = i18n.t('constants:' + text);
  return translatedText;
};

export function promotionLogin(
  location,
  sendBroadcastMessage,
  navigate,
  request,
  dispatch
) {
  if (location.pathname.match(PROMOTION_LINK)) {
    const state = store.getState();
    const sessionId = state?.signInRedux?.logindetails?.sessionid;
    if (sessionId) {
      showConfirm(i18n.t('externalLoginSessionChange'), i18n.t('externalLinkSessionChange')).then((res) => {
        if (res.isConfirmed) {
          altLogin2(dispatch, request, sendBroadcastMessage).then(() => {
            navigate("/")
          }).catch((e) => console.log(e));
        } else {
          navigate(-1);
        }
      });
    } else {
      altLogin2(dispatch, request, sendBroadcastMessage).then(() => {
        navigate("/")
      }).catch((e) => console.log(e));
    }
  }
}

export function checkIfPromotionUser() {
  let isPromotionLoggedIn = false;
  const state = store.getState();
  const loginDetail = getLoginDetails(state);
  const promotionCookieData = cookies.get("promotionLoginData");
  if (loginDetail?.username === promotionCookieData?.username && promotionCookieData?.username !== undefined) {
    isPromotionLoggedIn = true;
  }
  return isPromotionLoggedIn;
}

export function getAffiliateExpiryTime() {
  const expirationTimeInSeconds = AFFILIATE_COOKIE_EXPIRE_TIME * 86400;
  const futureDate = new Date(Date.now() + expirationTimeInSeconds * 1000);
  return futureDate;
}

export function checkPromotionCookieExpiry() {
  const state = store.getState();
  const promotionCookie = cookies.get("promotionLoginData");
  const isPromotion = state?.signInRedux?.promotion
  if (!promotionCookie && isPromotion) {
    store.dispatch(logout());
    store.dispatch(clearLoggedInUserDetail());
  }
}

export function setAndRemovieCookie(allowedCookies) {
  cookies.set("allowedCookies", allowedCookies, { maxAge: COOKIE_EXPIRE_TIME * 86400, path: "/" })
  if (allowedCookies?.functional === false) {
    cookies.remove("market", { path: "/" });
  }
}

export function isCookieAllowed() {
  const allowedCookie = cookies.get("allowedCookies");
  if (allowedCookie) {
    return allowedCookie
  } else {
    return DEFAULT_COOKIE;
  }
}

export function getAdultValidationsForDob() {
  const state = store.getState();
  const paxCountBookingDetails = state?.increasePaxCountRedux?.paxCountBookingDetails;
  let adults;
  let passngerInfo;
  let flightDate;
  let flag = true;
  let productId;

  state?.cartRedux?.cartData.forEach((data) => {

    adults = data?.value?.data?.passengerInfo?.adults;
    productId = data?.value?.currentCartItem?.productid
    if (productId === PRODUCTID_ARRIVALBUNDLE) {
      flightDate = myDateFormat(data?.value?.bookingDetail[0]?.flightDate, Object.values(paxCountBookingDetails)?.length === 0 ? "MM/dd/yyyy" : "yyyyMMdd", "MM/dd/yyyy");
    } else {
      flightDate = myDateFormat(data?.value?.bookingDetail?.flightDate, Object.values(paxCountBookingDetails)?.length === 0 ? "MM/dd/yyyy" : "yyyyMMdd", "MM/dd/yyyy");
    }
    passngerInfo = data?.value?.data?.passengerInfo === "" ? false : true;
  })

  let adultMinimum = getAdultMinimumAge();
  if (passngerInfo) {
    adults.forEach((adult) => {
      const years = dateDifferenceInYears(flightDate, adult?.dob);
      if (years < (adultMinimum + 1)) {
        flag = false;
      }
    })
  }

  return flag;
}

export function getChildValidationsForDob() {
  const state = store.getState();
  const paxCountBookingDetails = state?.increasePaxCountRedux?.paxCountBookingDetails;
  let childs;
  let flightDate;
  let passngerInfo;
  let flag = true;
  let productId;

  state?.cartRedux?.cartData.forEach((data) => {
    childs = data?.value?.data?.passengerInfo?.childs;
    productId = data?.value?.currentCartItem?.productid
    if (productId === PRODUCTID_ARRIVALBUNDLE) {
      flightDate = myDateFormat(data?.value?.bookingDetail[0]?.flightDate, Object.values(paxCountBookingDetails)?.length === 0 ? "MM/dd/yyyy" : "yyyyMMdd", "MM/dd/yyyy");;
    } else {
      flightDate = myDateFormat(data?.value?.bookingDetail?.flightDate, Object.values(paxCountBookingDetails)?.length === 0 ? "MM/dd/yyyy" : "yyyyMMdd", "MM/dd/yyyy");
    }
    passngerInfo = data?.value?.data?.passengerInfo === "" ? false : true;
  })

  let adultMinimum = getAdultMinimumAge();
  let childMinimum = getChildMinimumAge();
  if (passngerInfo) {
    childs?.forEach((child) => {
      const years = dateDifferenceInYears(flightDate, child?.dob);
      if ((years < childMinimum || years > (adultMinimum))) {
        flag = false;
      }
    })
  }
  return flag;
}

export function getInfantValidationsForDob() {
  const state = store.getState();
  const paxCountBookingDetails = state?.increasePaxCountRedux?.paxCountBookingDetails;
  let infant;
  let flightDate;
  let passngerInfo;
  let flag = true;
  let productId;

  state?.cartRedux?.cartData.forEach((data) => {
    infant = data?.value?.data?.passengerInfo?.infant;
    productId = data?.value?.currentCartItem?.productid
    if (productId === PRODUCTID_ARRIVALBUNDLE) {
      flightDate = myDateFormat(data?.value?.bookingDetail[0]?.flightDate, Object.values(paxCountBookingDetails)?.length === 0 ? "MM/dd/yyyy" : "yyyyMMdd", "MM/dd/yyyy");
    } else {
      flightDate = myDateFormat(data?.value?.bookingDetail?.flightDate, Object.values(paxCountBookingDetails)?.length === 0 ? "MM/dd/yyyy" : "yyyyMMdd", "MM/dd/yyyy");
    }
    passngerInfo = data?.value?.data?.passengerInfo === "" ? false : true;
  })

  let childMinimum = getChildMinimumAge();
  if (passngerInfo) {
    infant?.forEach((infant) => {
      const years = dateDifferenceInYears(flightDate, infant?.dob);
      if ((years > childMinimum - 1)) {
        flag = false;
      }
    })
  }
  return flag;
}

export function checkAffiliateExpiry() {
  const state = store.getState();
  if (state?.affiliatesRedux?.expiresAt && state.affiliatesRedux?.expiresAt <= new Date()) {
    store.dispatch(resetAffiliates());
  }
}

export function getAffiliateMarketFromRedux() {
  const state = store.getState();
  if (state?.affiliatesRedux?.marketid) {
    return state?.affiliatesRedux?.marketid
  }
}

export function isDefaultUser() {
  const state = store.getState();
  let userDefault = state?.signInRedux?.userDefault;
  let default_user = !userDefault?.username
    ? "Y"
    : userDefault?.username === CUSTOMER_LOGIN.username
      ? "Y"
      : "N";
  return default_user === "Y";

}

export function getAffiliateLink() {
  // return "https://mentem.in/test2"; 
  if (isDefaultUser()) {
    const state = store.getState();
    if (state?.affiliatesRedux?.affiliateDetails?.callbackurl) {
      return state?.affiliatesRedux?.affiliateDetails?.callbackurl;
    }
  }
}

export function isloungePartnerProductAddAnotheBookingButton() {
  // need to change the logic with type

  let productid;
  let title;
  let route;
  let flag = false;

  LOUNGE_PARTNERS_PRODUCTS.forEach((data) => {
    if (getLoggedInUsername() === data?.userName) {
      if(data.productId === PRODUCTID_PRIORITYPASS){
        title = i18n.t('priorityPassCard');
      } else if(data.productId === PRODUCTID_DIGICELBLACKCARD){
        title = i18n.t('digicelBlackCard');
      } else if(data.productId === PRODUCTID_DRAGONPASS){  
        title = i18n.t('dragonPassCard');
      }
      productid = data?.productId;
      title = title;
      route = data?.route;
      flag = true;
    }
  })
  let data = {
    "productid": productid,
    "title": title,
    "route": route,
    "flag": flag
  }

  return data;
}

export function loungePartnerProductOperation(operation, productId = "", type = "", userName = "") {
  switch (operation) {

    case "checkExistence": {
      let flag = false;
      LOUNGE_PARTNERS_PRODUCTS.forEach((data) => {
        if (productId === data?.productId) {
          flag = true;
        }
      })
      return flag;
    }

    case "getProductFromUserName": {
      let productid;
      let tmpType;
      if (productId === PRODUCTID_ARRIVALBUNDLE) {
        tmpType = "ArrivalBundle";
      } else if (productId === PRODUCTID_ARRIVAL) {
        tmpType = "Arrival"
      } else {
        tmpType = "Departure";
      }
      LOUNGE_PARTNERS_PRODUCTS.forEach((data) => {
        if (getLoggedInUsername() === data?.userName && data.productType === tmpType) {
          productid = data?.productId
        }
      })
      return productid;
    }

    case "getProductById": {
      let productid;
      LOUNGE_PARTNERS_PRODUCTS.forEach((data) => {
        if (data?.productId === productId) {
          productid = data?.productId;
        }
      })
      return productid;
    }

    case "getRouteName": {
      let routName;
      LOUNGE_PARTNERS_PRODUCTS.forEach((data) => {
        if (productId === data?.productId) {
          routName = data?.route;
        }
      })
      return routName;
    }

    case "checkExistenceWithType": {
      let flag = false;
      LOUNGE_PARTNERS_PRODUCTS.forEach((data) => {
        if (productId === data?.productId && data.productType === type) {
          flag = true;
        }
      })
      return flag;
    }

    case "getRouteNameWithType": {
      let routName;
      LOUNGE_PARTNERS_PRODUCTS.forEach((data) => {
        if (getLoggedInUsername() === data?.userName && data.productType === type) {
          routName = data?.route;
        }
      })
      return routName;
    }

    case "isLoungePartnerLogin": {
      let flag = false;
      LOUNGE_PARTNERS_PRODUCTS.forEach((data) => {
        if (userName === data?.userName) {
          flag = true;
        }
      })
      return flag;
    }

    case "loungePartnerLoginTypeWithUserName": {
      let loginType;
      LOUNGE_PARTNERS_PRODUCTS.forEach((data) => {
        if (getLoggedInUsername() === data?.userName && productId === data?.productId) {
          loginType = data.loginType;
        }
      })
      return loginType;
    }

    case "loungePartnerLoginTypeWithId": {
      let loginType;
      LOUNGE_PARTNERS_PRODUCTS.forEach((data) => {
        if (productId === data?.productId) {
          loginType = data.loginType;
        }
      })
      return loginType;
    }
    // this is not applied yet
    case "isNotLoungePartnerLogin": {
      let flag = false;
      LOUNGE_PARTNERS_PRODUCTS.forEach((data) => {
        if (userName !== data?.userName) {
          flag = true;
        }
      })
      return flag;
    }

    case "loungePartnerOBISource": {
      let obiSource;
      LOUNGE_PARTNERS_PRODUCTS.forEach((data) => {
        if (userName === data?.userName) {
          obiSource = data.obiSource;
        }
      })
      return obiSource;
    }
    default:
      return null;
  }
}


export function showLoungePartnerProduct() {
  let showLoungePartnerProduct = false;

  let affiliate_subaffiliate_data = get_affiliate_subaffiliate_data();
  let affiliateid = affiliate_subaffiliate_data.affiliateid;

  if (affiliateid === "!") {
    const state = store.getState();
    const loginDetail = getLoginDetails(state);
    let userDefault = state?.signInRedux?.userDefault;
    if (loginDetail?.username) {
      const selectedMarket = state.marketRedux?.availableMarkets?.data?.markets?.find((m) => m.marketid === state.marketRedux?.selectedMarketId)
      if (selectedMarket?.prioritypassallowed === 'Y' || selectedMarket?.digicelcardallowed === 'Y') { // need to change
        if (loginDetail?.username === CUSTOMER_LOGIN.username) {
          showLoungePartnerProduct = true;
        } else if (checkLoggedInUserIsLoungePartner()) {
          showLoungePartnerProduct = true;
        } else if (userDefault?.subscriber === "Y") {
          showLoungePartnerProduct = false;
        }
      }
    } else {
      showLoungePartnerProduct = true;
    }
  }

  return showLoungePartnerProduct;
}

export function showPartnerName() {
  let showPartner = false;
  const state = store.getState();
  // if (isDefaultUser()) {
  if (state?.partnerRedux?.partnerDetails?.affiliatename) {
    showPartner = true;
  }
  // }
  return showPartner;
}

export function sendPartnerToLogin(navigate) {
  const state = store.getState();
  const userDefault = getLoginDetails()

  // if(!isDefaultUser()){
  if (state?.partnerRedux?.partnerDetails?.affiliatename && userDefault?.username === undefined) {
    navigate("/login");
  }
  // }
}

// export function sendToHome(navigate) {
//   const userDefault = getLoginDetails()
//   if(LOGIN_BUTTON_SHOW_TO_USERS.find((option) => option === userDefault?.username)
//    || userDefault?.username === undefined){
//   } else{
//     navigate("/");
//   }
// }

export function sendToHome(navigate) {
  const userDefault = getLoginDetails()
  if (!LOGIN_BUTTON_SHOW_TO_USERS.includes(userDefault?.username) && userDefault?.username !== undefined) {
    navigate("/");
  }
}

export function checkIfPartnerLogin() {
  const state = store.getState();
  return state?.partnerRedux?.partnerDetails?.affiliatename ? true : false;
}

export function hideUpdateFlightDetailsLink(location) {
  return (location.pathname.startsWith("/login")
    || location.pathname.startsWith("/subscriber-login")
    || location.pathname.startsWith("/partner"))
}

export function showSubscriberLoginButton(location) {
  let toShowButton = false;
  if (checkIfPartnerLogin()|| checkIfInIframe()) {
    // Do nothing
  } else {
    toShowButton = location.pathname.startsWith("/login") || location.pathname === "/";
  }
  return toShowButton;
}

export function showPartnerLoginButton(location) {
  let toShowButton = false;
  if (checkIfPartnerLogin() || checkIfInIframe()) {
    // Do nothing 
  } else {
    toShowButton = location.pathname.startsWith("/subscriber-login") || location.pathname === "/";
  }
  return toShowButton;
}

export function checkIfInIframe () {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function checkPartnerExpiry() {
  const state = store.getState();
  if (state?.partnerRedux?.expiresAt && state.partnerRedux.expiresAt <= new Date()) {
    store.dispatch(resetPartner());
  }
}

export function getPartnerExpiryTime() {
  const expirationTimeInSeconds = AFFILIATE_COOKIE_EXPIRE_TIME * 10 * 60;
  const futureDate = new Date(Date.now() + expirationTimeInSeconds * 1000);
  return futureDate;
}

export const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

export function getMenuItemUrl(item) {
  const userDefault = getLoginDetails()
  let url = item.url;
  if (item.key === 'home' && checkIfPartnerLogin() && userDefault?.username === undefined) {
    url = '/login'
  }
  return url;
};

export function showAffiliateName(location) {
  let flag = false
  AFFILIATE_SHOW_LINKS_REGEX?.forEach((link) => {
    if (location.pathname.match(link)) {
      flag = true;
    }
  })
  return flag;
}

export function showMenuItemForExternalLink(default_user, location) {
  const affiliate_subaffiliate_data = get_affiliate_subaffiliate_data();
  const state = store.getState();
  const affiliateid = affiliate_subaffiliate_data?.affiliateid;
  if (
    default_user === "Y" &&
    (affiliateid !== "!" || affiliateid !== "0") &&
    AFFILIATE_SHOW_LINKS_REGEX.some((link) => location.pathname.match(link)) &&
    !isObjectEmpty(state.affiliatesRedux?.affiliateDetails)
  ) {
    return false;
  }

  return true;
}

export const compareDates = (bookingDetail) => {
  const flag =
    new Date(
      bookingDetail[0].flightDate + " " + bookingDetail[0].flightTime_hour
    ) <
    new Date(
      bookingDetail[1].flightDate + " " + bookingDetail[1].flightTime_hour
    );
  return flag;
};

export const checkJamaicanTime = async (dispatch,navigate)=>{
  const state = store.getState();
  const marketTime = state?.marketRedux?.jamaicanTime
  const currentTime = await getJamaicanTime()
  const storedDate = marketTime?.split("T")[0]
  const currentDate = currentTime?.datetime?.split("T")[0]
  if(storedDate && (storedDate !== currentDate)){
    dispatch(resetMarketRedux())
    window.location.href = "/"
  }
}

export const numericValidation=(value)=>{
  return value.replace(REGEX_NUMERIC, "")
}
export const alphabetValidation = (value) => {
  return value.replace(REGEX_ALPHABET, "")
}
export const alphanumericWithout = (value) => {
  return value.replace(REGEX_ALPHANUMERIC_WITHOUT_SPACE, "")
}

export const alphanumericWithSpace = (e) => {
  return e.replace(REGEX_ALPHANUMERIC_WITH_SPACE, "");
}

export const generatePaymentMenu = (distributorProfile, userDefault) => {
  const state = store.getState();
  let paymentType = "";
  let cardType = "both";
  let cardprofile = [];
  let allowedPaymentTypes = [];

  if (
    distributorProfile.invoiceonly === "Y" &&
    distributorProfile.groupbookingallowed === "Y"
  ) {
    paymentType = "invoice";
  }
  if (distributorProfile.invoiceonly === "Y" && isObjectEmpty(state?.increasePaxCountRedux?.paxCountBookingDetails)) {
    paymentType = "invoice";
  } else if (distributorProfile.guestcardonly === "Y") {
    paymentType = "creditcard";
  } else if (distributorProfile.distributorcardonly === "Y") {
    paymentType = "creditcard";
  }

  if (allowedPaymentTypes.length === 0) {
    if (distributorProfile.invoiceallowed === "Y" && isObjectEmpty(state?.increasePaxCountRedux?.paxCountBookingDetails)) {
      allowedPaymentTypes.push("invoice");
      paymentType = "invoice";
      if (
        distributorProfile.guestcardallowed === "Y" ||
        distributorProfile.distributorcardallowed === "Y"
      ) {
        paymentType = "both";
      }
      if (
        distributorProfile.prepaidallowed === "Y" ||
        distributorProfile.barterallowed === "Y"
      ) {
        paymentType = "both";
      }
    }

    if (
      distributorProfile.guestcardallowed === "Y" ||
      distributorProfile.distributorcardallowed === "Y"
    ) {
      allowedPaymentTypes.push("creditcard");
      if (
        distributorProfile.invoiceallowed === "Y" ||
        distributorProfile.prepaidallowed === "Y" ||
        distributorProfile.barterallowed === "Y"
      ) {
        paymentType = "both";
      } else {
        paymentType = "creditcard";
      }
    }
    if (distributorProfile.prepaidallowed === "Y" && isObjectEmpty(state?.increasePaxCountRedux?.paxCountBookingDetails)) {
      allowedPaymentTypes.push("prepaid");
      // $bucketid = "PREPAID";
    }
    if (distributorProfile.barterallowed === "Y" && isObjectEmpty(state?.increasePaxCountRedux?.paxCountBookingDetails)) {
      allowedPaymentTypes.push("barter");
      // $bucketid = "BARTER";
    }
    if (distributorProfile.invoiceonly === "Y" && isObjectEmpty(state?.increasePaxCountRedux?.paxCountBookingDetails)) {
      paymentType = "invoice";
      allowedPaymentTypes.push("invoice");
    } else if (distributorProfile.guestcardonly === "Y") {
      paymentType = "creditcard";
      allowedPaymentTypes.push("creditcard");
    } else if (distributorProfile.distributorcardonly === "Y") {
      paymentType = "creditcard";
      allowedPaymentTypes.push("creditcard");
    }
  }

  if (allowedPaymentTypes.find((p) => p === "creditcard")) {
    if (distributorProfile.guestcardonly === "Y") {
      cardType = "guest";
    }
    if (distributorProfile.distributorcardonly === "Y") {
      cardType = "distributor";
    }
    if (
      distributorProfile.guestcardallowed === "Y" &&
      distributorProfile.distributorcardallowed === "Y"
    ) {
      cardType = "both";
    }
    if (
      distributorProfile.guestcardallowed === "Y" &&
      distributorProfile.distributorcardallowed === "N"
    ) {
      cardType = "guest";
    }
    if (
      distributorProfile.guestcardallowed === "N" &&
      distributorProfile.distributorcardallowed === "Y"
    ) {
      cardType = "distributor";
    }
    if (!isObjectEmpty(state?.increasePaxCountRedux?.paxCountBookingDetails)){
      let tmpCardType = state?.increasePaxCountRedux?.cardType === "DISTRIBUTORCARD" ? "distributor" : "guest";
      cardType = tmpCardType;
    }
  }

  if (cardType === "both") {
    cardprofile.push(
      { value: "GUESTCARD", label: "GUEST CARD" },
      { value: "DISTRIBUTORCARD", label: "DISTRIBUTOR CARD" }
    );
  } else if (cardType === "guest") {
    cardprofile.push({ value: "GUESTCARD", label: "GUEST CARD" });
  } else if (cardType === "distributor") {
    cardprofile.push({ value: "DISTRIBUTORCARD", label: "DISTRIBUTOR CARD" });
  }

  if (check_magna_allowed(userDefault)) {
    allowedPaymentTypes.push("magnacard");
  }

  if (is_member_card_payment_method_allowed(userDefault)) {
    allowedPaymentTypes.push("vipmembership");
  }

  if (is_manual_payment_method_allowed(userDefault)) {
    allowedPaymentTypes.push("manualauthorization");
  }
  return {
    allowedPaymentTypes: allowedPaymentTypes,
    cardType: cardType,
    cardprofile: cardprofile,
  };
};

export const isPassedFlightDate = (flightDate) => {
  const currentDate = new Date();
  const tmpCurrentDate = new Date(dateFormatForDisplay(currentDate, "MM/dd/yyyy"));
  const tmpFlightDate = new Date(myDateFormat(flightDate, "yyyyMMdd HHmm", "MM/dd/yyyy"));
  let editable = tmpCurrentDate < tmpFlightDate ? true : false;

  return editable;
}

export const showCardType = (paymentTypes) =>  {
  const state = store.getState();
  let flag = false;
  if (!isObjectEmpty(state?.increasePaxCountRedux?.paxCountBookingDetails)) {
    if (state.signInRedux.userDefault?.subscriber === "N") {
      if (paymentTypes.cardType !== "both" && paymentTypes?.cardprofile.length > 0) {
        flag = true;
      }
    }
  }  else {
    if((paymentTypes.cardType === "both" && paymentTypes?.cardprofile.length > 1)){
      flag = true;
    }
  }
  return flag;
}

export const setDemographicInformationToRedux = (formik, dispatch) => {
  // dispatch(
  //   setDemographicInformation({
  //     adults: formik.values.adults,
  //     childs: formik.values.childs,
  //     infants: formik.values.infants,
  //     primaryContactDetails: formik.values.primaryContactDetails,
  //     secondaryContactDetails: formik.values.secondaryContactDetails,
  //     greetingDetails: formik?.values?.greetingDetails,
  //   })
  // );
}

export const getPassengerDetails = (orderDetail) => {
  let passengerDetails = {
    adultCount: 0,
    childCount: 0,
    infantCount: 0,
    date: null,
    club: null
  };
  // let arrivalDate, departureDate;
  if (orderDetail && orderDetail?.arrivalbundles) {
    orderDetail?.arrivalbundles?.forEach((record) => {
      const arrivalDate = myDateFormat(record?.arrivalrecord?.flightdate, "yyyyMMdd HHmm", "MM/dd/yyyy hh:mm a");
      const arrivalClub = `${getClubName(record?.arrivalrecord?.airportid)} / ${getAirportName(record?.arrivalrecord?.airportid)}`;

      let departureDate = "";
      let departureClub = "";
      record.departurerecords?.forEach((departureRecord) => {
        departureDate = myDateFormat(departureRecord?.flightdate, "yyyyMMdd HHmm", "MM/dd/yyyy hh:mm a");
        departureClub = `${getClubName(departureRecord?.airportid)} / ${getAirportName(departureRecord?.airportid)}`;
      });
    
      passengerDetails = {
        adultCount: record?.arrivalrecord.adulttickets,
        childCount: record?.arrivalrecord.childtickets,
        infantCount: record?.arrivalrecord.infanttickets,
        date: `${arrivalDate} ${i18n.t('and')} ${departureDate}`,
        club: `${arrivalClub} ${i18n.t('and')} ${departureClub}`
      };

    });
    
  } 

  if (orderDetail && orderDetail?.arrivalrecords) {
    orderDetail?.arrivalrecords?.forEach((record) => {
      passengerDetails = {
        adultCount: record.adulttickets,
        childCount: record.childtickets,
        infantCount: record.infanttickets,
        date: myDateFormat(record?.flightdate, "yyyyMMdd HHmm", "MM/dd/yyyy hh:mm a"),
        club: getClubName(record?.airportid) + " / "+ getAirportName(record?.airportid)
      };
    });
  }

  if (orderDetail && orderDetail?.departurerecords) {
    orderDetail?.departurerecords?.forEach((record) => {
      passengerDetails = {
        adultCount: record.adulttickets,
        childCount: record.childtickets,
        infantCount: record.infanttickets,
        date: myDateFormat(record?.flightdate, "yyyyMMdd HHmm", "MM/dd/yyyy hh:mm a"),
        club: getClubName(record?.airportid) + " / "+ getAirportName(record?.airportid)
      };
    });
  }

  return passengerDetails;
};

export const getTermsandConditionsPdfFilename = (type, selectedMarketId) => {
  const termAndConditionsUrl = `${BASE_URL}${TERMS_AND_CONDITIONS_FOLDER_PATH}${selectedMarketId}/${selectedMarketId?.toLowerCase()}`
  let filename;

  switch (type) {
    case "regular": {
      filename = `${termAndConditionsUrl}-regular-terms-conditions.pdf`
      break;
    }
    case "lounge-partner": {
      filename = `${termAndConditionsUrl}-priority-digicel-terms-conditions.pdf`
      break;
    }
    case "registration": {
      filename = `${termAndConditionsUrl}-registration-terms-conditions.pdf`
      break;
    }
    case "membership": {
      filename = `${termAndConditionsUrl}-membercard-terms-conditions.pdf`
      break;
    }
    default:
  }

  return filename;
}

export const sendMailResponseNullConfirmFailure = async(endpoint, request, response, url) => {
  const logObj = {
    subject: "viper api null response",
    body: `<b>Date:</b> ${dateFormatForDisplay(new Date(), "yyyy-MM-dd HH:MM:ss")} <br /> <b>API Name:</b> ${endpoint} <br /> <b>URL:</b> ${url} <br /> <b>Request:</b> ${JSON.stringify(request)}`
  }
  try{
    if (response?.data?.status !== 0 && CONFIRMATION_APIS.includes(endpoint)) {
      logObj.subject = endpoint + " confirmation failure"
      logObj.body = `<b>Date:</b> ${dateFormatForDisplay(new Date(), "yyyy-MM-dd HH:MM:ss")} <br /> <b>API Name:</b> ${endpoint} <br /> <b>URL:</b> ${url} <br /><br /> <b>Request:</b> ${JSON.stringify(request)} <br /><br /> <b>Response:</b> ${JSON.stringify(response?.data)}`                   
      await sendMail(logObj)
    }
  
    if(!response|| isObjectEmpty(response?.data)){
      await sendMail(logObj)
    }
  }catch(error){
    HandleAPIError(error)
  }
}

export function isFromTestingOrigin(url) {
  const urlObj = new URL(url);
  const hostname = urlObj.hostname;

  return TESTING_URLS.includes(hostname);
}
