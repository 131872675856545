
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { getBookingDetails } from "../../redux/apiCalls";
import Loader from "./Loader";
import { HandleAPIError } from "./commonFunctions"
import BookingSummaryComponent from "./BookingSummaryComponent";
import { useLocation } from "react-router-dom";

export default function MyBookingDetailModal(props) {
  const cancelHandler = () => {
    props.cancelModal();
  };
  
  const { t } = useTranslation(["mybookings", "orderreport"]);
  const [loading, setLoading] = useState(true);
  const bookingDetails = props.detail;
  const [orderDetail, setOrderDetail] = useState([]);
  const [referenceNumber,setReferenceNumber]=useState("")
  const [confirmationNumber,setConfirmationNumber]=useState("")
  const [primaryContact, setPrimaryContact] = useState("");
  const [secondaryContact, setSecondaryContact] = useState("");

  const location = useLocation();

  const fetchBookingDetails = async () => {
    try {
      setLoading(true);
      const data = {
        reservationid: bookingDetails.reservationid,
        productid: bookingDetails.productid,
        getschedule: "N",
      };
      const response = await getBookingDetails(data);
      if (response.status === 0) {
        setOrderDetail(response.data.orderdetail);
        setReferenceNumber(response.data.referencenumber);
        setConfirmationNumber(response.data.confirmationnumber)
        setPrimaryContact(response?.data?.primary);
        setSecondaryContact(response?.data?.secondary);
      }
    }
    catch(e){
      HandleAPIError(e);
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(()=>{
    fetchBookingDetails();
  },[])

  return (
    <>
      <Loader loading={loading} message={t('loaderMessage')}/>
      {loading === false && (
      <Modal
        show={true}
        onHide={cancelHandler}
        size="xl"
        style={{ zIndex: "9998" }}
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton className="py-0">
          <div className="my-3">
            <h3 className="fw-bold modalHeading">
              <span className=""> {props.headingType === "Order-Details" ? t("orderreport:orderDetails") : t("mybookings:viewbookingDetailsHeading")}</span>
            </h3>
          </div>
        </Modal.Header>
        <Modal.Body >
          <BookingSummaryComponent
           renderType={"CREDIT-BOOKING"}
           orderDetail={orderDetail}
           productid={orderDetail.productid}
           referenceNumber={referenceNumber}
           confirmationNumber={confirmationNumber}
           primaryContact={primaryContact}
           secondaryContact={secondaryContact}
          />
        </Modal.Body>
      </Modal>
      )}
    </>
  );
  
}

