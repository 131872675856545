import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import Loader from '../components/commonComponents/Loader'
import { t } from 'i18next'
import ExitButton from '../components/commonComponents/ExitButton'
import ErrorMessages from '../components/commonComponents/ErrorMessages'
import { HandleAPIError, divRef, getDistributorSubDistributorProfile } from '../components/commonComponents/commonFunctions'
import { DEFAULT_CURRENCYCODE } from '../config/commonConstants'
import { showError } from '../components/commonComponents/Swal'
import { logindetails as LD } from "../redux/signInRedux";
import { useSelector } from 'react-redux'
import { getBalance } from '../redux/apiCalls'

const BarterBalance = () => {
    const loginDetails = useSelector(LD);
    const [loading, setLoading] = useState(false);
    const [distributorProfile] = useState(getDistributorSubDistributorProfile());
    const [barterArray, setBarterArray] = useState([]);

    const handleGetBalance = async () => {
        try {
          setLoading(true);
          let getBalanceReponse = await getBalance({
            buckettype: "BARTER",
            distributorid: distributorProfile?.distributorid
          });
    
          if (getBalanceReponse !== null) {
            if (distributorProfile.barterproductallowed === "Y") {
              setBarterArray(getBalanceReponse?.data?.buckets.filter(bucket => bucket.bucketid !== 'BARTER'));
            } else {
              setBarterArray(getBalanceReponse?.data?.buckets.filter(bucket => bucket.bucketid === 'BARTER'));
            }
          } else {
            showError(t("error"), getBalanceReponse.statusMessage);
            return null;
          }
        } catch (e) {
          HandleAPIError(e);
          return null;
        } finally {
          setLoading(false);
        }
      };
    
      useEffect(() => {
        loginDetails?.sessionid && handleGetBalance();
      }, [loginDetails]);


  return (
    <>
    <section>
      <Container className="my-4" ref={divRef}>
        <Loader loading={loading} message={t("loaderMessage")} />
        <h3 className="my-3 d-flex justify-content-center heading heading">
          <span className="heading">{t("navigationbar:barterbalance")}</span>
        </h3>
        <div className="my-3 px-2 pt-0 d-flex justify-content-end">
          <ExitButton />
        </div>
          <Card className="rounded-3 p-4 mb-2 mx-2">
            {/* <ErrorMessages  /> */}
            <Row className="g-3">
              <Col md={12}>
                <table className="prepaid my-3">
                  <tbody>
                    <tr key={0}>
                      <th>{t("payment:accountType")}</th>
                      <th className="text-end">
                        {t("payment:accountBalance")}
                      </th>
                    </tr>
                    {barterArray?.map((data, i) => (
                      <tr key={i}>
                        <td>
                          {
                            distributorProfile?.buckets?.find(
                              (bucket) => bucket?.bucketid === data.bucketid
                            ).description
                          }
                        </td>
                        <td className="text-end">
                          {DEFAULT_CURRENCYCODE + " " + data?.amount.toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Col>
            </Row>
          </Card>
      </Container>
    </section>
  </>
  )
}

export default BarterBalance
