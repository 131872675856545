import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { giftCartData as GCD } from "../../redux/giftCartRedux";
import { availableMarkets as AM, selectedMarketId as SM, setSelectedMarket } from "../../redux/marketRedux";
import { cartData as CD } from "../../redux/cartRedux";
import { useTranslation } from "react-i18next";
import { setMarketId } from "../../redux/apiCalls";
import { showConfirm } from "./Swal";
import { AFFILIATE_COOKIE_EXPIRE_TIME, FLAG_URL } from "../../config/commonConstants";
import { Col, Navbar, Row } from "react-bootstrap";
import { getLoginDetails, get_affiliate_subaffiliate_data, isCookieAllowed, marketChangeBroadcast } from "./commonFunctions";
import { logindetails as LD } from "../../redux/signInRedux";
import { useCookies } from "react-cookie";
import { setAffiliateMarketId } from "../../redux/affiliatesRedux";

function MarketSelect(props) {
  const { t } = useTranslation(["header"]);
  const [cookies,setCookie] = useCookies(["market"])
  const dispatch = useDispatch();
  const availableMarkets = useSelector(AM);
  const selectedMarketId = useSelector(SM);
  const giftCartData = useSelector(GCD);
  const cartData = useSelector(CD);
  const marketSelectionZIndex = 1021; //9998
  // const request = {marketid:selectedMarketId};
  const loginDetails = useSelector(LD)
  const country_name_with_flag = [];

  availableMarkets?.data?.markets?.forEach((m) => {
    country_name_with_flag.push({
      value: m?.marketid,
      label:m,
    });
  });

  const setMarketIdService = async (val) => {
    if (getLoginDetails()?.sessionid) {
      let selectedMarketLanguage = availableMarkets?.data?.markets?.find(
        (id) => id.marketid === val
      )?.language;
      const data = {
        marketid: val,
        languageid: selectedMarketLanguage,
      };
      await setMarketId(data);
    }
  };

  const changeMarket = async (val, label, cartClear) => {
    await marketChangeBroadcast(props.sendBroadcastMessage,val,cartClear);
    if (val === selectedMarketId) {
      // Do nothing
    } else {
      props?.initialCallbackFunction && props.initialCallbackFunction();
      dispatch(setSelectedMarket(val));
      setMarketIdService(val);
      props?.callbackFunction && props.callbackFunction(val);
    }
  };

  const styleObj = {
    color: "black",
    zIndex: props.zIndex && marketSelectionZIndex,
    width: "230px",
  };
  

  const renderMarketDropDown = () => {
    return (
      <div style={styleObj}>
       <Col md="auto">
        <Select
          autosize={true}
          value={country_name_with_flag.find((x) => x.value === selectedMarketId)}
          options={country_name_with_flag}
          getOptionLabel={(m) => (
            <div >
              <img
               src={FLAG_URL + m?.label?.image}
               width="30"
               className="d-inline-block align-left mx-1"
               alt={m?.label?.marketname}
              />{" "}
              {m?.label.marketname}
            </div>
          )}
        getOptionValue={(m) => m?.label?.marketname}

          onChange={async (d) => {
            if (cartData.length === 0 && giftCartData.length === 0) {
              changeMarket(d.value, d.label, false);
              if(isCookieAllowed()?.functional){
                get_affiliate_subaffiliate_data()?.affiliateid !== "!" && dispatch(setAffiliateMarketId(d.value))
              }
            } else if (cartData.length !== 0 || giftCartData.length !== 0) {
              const confirmed = await showConfirm(
                t("header:changeMarketConfirmHeading"),
                t("header:changeMarketConfirmMessage")
              );
              if (confirmed.isConfirmed) {
                if(isCookieAllowed()?.functional){
                  get_affiliate_subaffiliate_data()?.affiliateid !== "!" && (setAffiliateMarketId(d.value))
                }
                changeMarket(d.value, d.label, true);
              }
            }
          }}
        />
      </Col> 
      </div>
    );
  };

  const renderMarketLabel = () => {
    const marketData = country_name_with_flag?.find((x) => x?.value === selectedMarketId);
    return (
      <div
        style={{
          zIndex: marketSelectionZIndex, // "9998",
          width: "190px",
        }}
      >
        <Navbar.Text
          style={{
            color: props?.editLabel === true ? "black" : "white",
            borderTop: props?.editLabel === true ? "1px solid black" : "1px solid white",
            borderBottom: props?.editLabel === true ? "1px solid black" : "1px solid white",
          }}
          className="d-flex justify-content-center"
        >
          <div>  
              <img
                src={FLAG_URL + marketData?.label?.image}
                width="30"
                className="d-inline-block align-left mx-1"
                alt={marketData?.label?.marketname}
              />{" "}
              {marketData?.label?.marketname}
        </div>
        </Navbar.Text>
      </div>
    );
  };

  return (
    (
      availableMarkets?.data?.hide === "Y" && availableMarkets?.data?.hidemarkets === "Y"
    ) ? null : (
        availableMarkets?.data?.markets?.length > 1 && (props.location.pathname === "/" || props.editLabel === true) ? (
          props.editLabel === true ? 
            <Row className="d-flex mb-2">
              <Col md="auto" sm="auto" className="pt-2">
                {t("mybookings:marketLabel")}
              </Col>
              {renderMarketDropDown()}
            </Row> : (
              renderMarketDropDown()
            )
        ) : (
          props?.editLabel === true ? (
            <Row className="d-flex mb-2">
              <Col md="1" sm="6" className="pt-2">
                {t("mybookings:marketLabel")}
              </Col>
              <Col>
                {renderMarketLabel()}
              </Col>
            </Row>
          ) : (
            renderMarketLabel()
          )
        )
    ) 
);
}
export default MarketSelect;