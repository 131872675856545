import Footer from "../components/Footer";
import Header from "../components/Header";
import Navigationbar from "../components/Navigationbar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";
import { useEffect,useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AFFILIATE_LINK, BC_SESSION, GA4_MEASUREMENT_ID, RESTRICTED_LOGIN_LINK } from "../config/commonConstants";
import { resetSignInRedux } from "../redux/signInRedux";
import { checkAffiliateExpiry, checkPromotionCookieExpiry, checkSession, loginFunction, checkPartnerExpiry, checkJamaicanTime, isFromTestingOrigin } from "../components/commonComponents/commonFunctions";
import useBroadcastChannel from "../hooks/useBroadcastChannel";
import { showInfo } from "../components/commonComponents/Swal";
// import { sessionStartTime as SST } from "../redux/signInRedux"
import { useCookies } from "react-cookie";
import CookieModal from "../components/CookieModal";
import { useState } from 'react';
import ReactGA from 'react-ga4';

if (!isFromTestingOrigin(window.location.href)) {
  ReactGA.initialize(GA4_MEASUREMENT_ID);
}

const MasterLayout = () => {
  const navigate = useNavigate();
  const sendBroadcastMessage = useBroadcastChannel(BC_SESSION, showInfo,navigate);
  const location = useLocation();
  const dispatch = useDispatch();
  const cookies = useCookies();
  const [showCookieModal, setShowCookieModal] = useState(false)
  const allowedCookies = cookies[0]?.allowedCookies;

  const cookieModalHandler = () =>{
    setShowCookieModal(false)
  }

  useEffect(() => {   
    if(allowedCookies?.showModal){
      setShowCookieModal(true);
    }else{
      cookieModalHandler();
    }  
  }, [allowedCookies]);

  useEffect(() => {
    const checkInterval = setInterval(() => {
      checkSession(dispatch,sendBroadcastMessage,navigate);
     
      checkAffiliateExpiry();
      // checkPartnerExpiry();
      checkPromotionCookieExpiry()
    }, 1000);

    return () => {
      clearInterval(checkInterval);
    };
  }, [dispatch,sendBroadcastMessage,navigate]);

  // Call the function with the pathname
  useLayoutEffect(() => {
    if (!RESTRICTED_LOGIN_LINK.some(linkRegex => location.pathname.match(linkRegex))){
      loginFunction(navigate,location,sendBroadcastMessage,dispatch);
    }
    checkJamaicanTime(dispatch,navigate);
  },[location.pathname]);


  useEffect(() => {
    if(!isFromTestingOrigin(window.location.href)){
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    }
  }, [location.pathname]);
 
  return (
    <div>
      <ScrollToTop />
      <Header sendBroadcastMessage={sendBroadcastMessage} />
      <Navigationbar sendBroadcastMessage={sendBroadcastMessage} />
      <Outlet context={sendBroadcastMessage} />
      {showCookieModal && 
        <CookieModal cookieModalHandler={cookieModalHandler}/>}
      <Footer />
    </div>
  );
};

export default MasterLayout;