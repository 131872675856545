import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import * as en from "./locales/en";
import * as fr from "./locales/fr";
import * as es from "./locales/es";

export const defaultNS = "common";

export const resources = {
  "en":en,
  "fr":fr,
  "es":es
};

export const init = async () => {
  try {
    await i18n
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({
        ns: ["common", "loginPage"],
        resources,
        fallbackLng: "en",
        interpolation: {
          escapeValue: false,
        },
        lng: "en",
        defaultNS,
      });
  } catch (error) {
    console.log("Failed to initiate i18n.");
  }
};
