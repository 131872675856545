import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyBill1Wave,
  faTicket,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import HtmlToReactConverter from "./HtmlToReactConverter";
import { selectedMarket as currentMarket, selectedMarketCurrency } from "../../redux/marketRedux";


function PriorityPassPriceModal(props) {
  const selectedMarket = useSelector(currentMarket);
  const currencyCode = useSelector(selectedMarketCurrency);
  const priceList = selectedMarket?.pricelist;

  const cancelHandler = () => {
    props.cancelModal();
  };

  const { t } = useTranslation(["common", "prioritypasspricemodal"]);

  return (
    <div>
      <Modal
        show={props.visible}
        onHide={cancelHandler}
        size="xl"
        // dialogClassName="modal-90w"
        // fullscreen={true}
        style={{ zIndex: "9998" }}
        backdrop="static"
      >
        <Modal.Header closeButton><h3 className="fw-bold modalHeading">{t("prioritypasspricemodal:title")}</h3></Modal.Header>
        <Modal.Body>
          <div style={{ padding: "0 30px" }}>
            <Row>
              <Col md={"12"}>
              {priceList
                ?.filter((item) => item?.productid === props?.productid)
                .map(
                  (value, index, elements) =>
                    !elements[index + 1] && (
                      <Row key={index}>
                        <Col md={"12"}>
                          <ul className="list-unstyled mx-2 text">
                            <li>
                              {" "}
                              <p className="justifyContent">{value?.line1}</p>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    )
                )}
                </Col>
            </Row>

            {priceList
              ?.filter((item) => item?.productid === props?.productid)
              .map((item,key) => (
                <div key={key}>
                  <h4>{item?.lounge}</h4>
                  <hr className="" />
                  <Row>
                    <Col md={4}>
                      <h5 className="">
                        <FontAwesomeIcon
                          icon={faTicket}
                          size="sm"
                          className="mx-2 icon-color"
                        />
                        {t("prioritypasspricemodal:departure")}
                      </h5>
                      <hr />
                      <ul className="list-unstyled mx-2 text">
                        <li> {t("prioritypasspricemodal:adultTicket")}</li>
                      </ul>
                    </Col>

                    <Col md={4}>
                      <h5 className="">
                        <FontAwesomeIcon
                          icon={faMoneyBill1Wave}
                          size="sm"
                          className="mx-2 icon-color"
                        />
                        {t("prioritypasspricemodal:membershipRate")}
                      </h5>
                      <hr />
                      <ul className="list-unstyled mx-2" key={item?.lounge}>
                        <li>
                          {currencyCode} {item?.adultrate?.toFixed(2)}{" "}
                          {t("prioritypasspricemodal:processingFee")}
                        </li>
                      </ul>
                    </Col>

                    <Col md={4}>
                      <h5 className="">
                        <FontAwesomeIcon
                          icon={faMoneyBill1Wave}
                          size="sm"
                          className="mx-2 icon-color"
                        />
                        {t("prioritypasspricemodal:regularRate")}
                      </h5>
                      <hr />
                      <ul className="list-unstyled mx-2" key={item?.lounge}>
                        <li>
                          {" "}
                          {currencyCode} {item?.normaladultrate?.toFixed(2)}{" "}
                          {t("prioritypasspricemodal:perPerson")}
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              ))}
            <Row className="my-3">
              <Col md={12}>
                <p className="my-bg-info text-center p-3">
                  {t("prioritypasspricemodal:importantMessage")}
                </p>
              </Col>
            </Row>

            <Row>
              <Col md="12">
              {priceList
                ?.filter((item) => item?.productid === props?.productid)
                .map(
                  (value, index, elements) =>
                    !elements[index + 1] && (
                      <Row key={index}>
                        <Col md="12">
                        <ul className="list-unstyled mx-2 text">
                          <li className="mb-3">
                            <div className="justifyContent">{value?.line1}</div>
                          </li>
                          <li className="mb-3">
                            <div className="justifyContent"> {value?.line2} </div>
                          </li>
                          <li>
                            <div className="justifyContent">
                              <HtmlToReactConverter value={value?.line3} />
                            </div>
                          </li>
                        </ul>
                        </Col>
                      </Row>
                    )
                    )}
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PriorityPassPriceModal;
