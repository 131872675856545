import { Row, Form, Card, Container, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { divRef, externalLogin, numericValidation, renderErrorMessages, scrollToDiv } from "../components/commonComponents/commonFunctions";
import CommonButton from "../components/commonComponents/CommonButton";
import { authenticateStandby } from "../redux/apiCalls";
import { HandleAPIError } from "../components/commonComponents/commonFunctions";
import Loader from "../components/commonComponents/Loader";
import { showError } from "../components/commonComponents/Swal";
import ErrorMessages from "../components/commonComponents/ErrorMessages";
import { STANDBY_TRACKINGID_MAXLENGTH, STANDBY_CONFIRMATIONID_MAXLENGTH } from "../config/commonConstants";
import { useDispatch } from "react-redux";
import ExitButton from "../components/commonComponents/ExitButton";

function StandByPaymentAuth() {
  const { t } = useTranslation(["common", "standbypaymentauth", "standbybookinglist"]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch()
  const location = useLocation();
  const sendBroadcastMessage = useOutletContext();
  const validationSchema = Yup.object().shape({
    tracking: Yup.string().required(t("standbypaymentauth:errors.tracking.required")),
      // .test('len', t("standbypaymentauth:errors.tracking.len", {length:STANDBY_TRACKINGID_MAXLENGTH}), val => val.length === STANDBY_TRACKINGID_MAXLENGTH),
    confirmationId: Yup.string()
      .required(t("standbypaymentauth:errors.ConfirmationID.required"))
  });
  const formik = useFormik({
    initialValues: {
      tracking: "",
      confirmationId: "",
    },
    validationSchema,
    onSubmit: () => { handleSubmit(formik.values) },
  });

  useEffect(()=>{
    externalLogin(location,sendBroadcastMessage,navigate,dispatch)
  },[])

  async function handleSubmit(formik) {
    const data = {
      "standbyid": formik.tracking,
      "confirmationnumber": formik.confirmationId,
    };
    try {
      setLoading(true)
      const response = await authenticateStandby(data);
        if (response.status === 0) {
          navigate("/standby", { state:{ response:response, navigatedFrom : "StandByPaymentAuth"}});
        } else {
          showError(t("error"), response.statusMessage);
        }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      <section>
        <Container className="my-4" ref={divRef}>
          <Loader loading={loading} message={t('standbybookinglist:loaderMessage')} />
          <section className="d-flex justify-content-center align-content-center mt-3">
            <h3 className="my-3 d-flex justify-content-center heading heading">
              <span className="heading">{t("standbypaymentauth:title")}</span>
            </h3>
          </section>
          <h6 className="field px-3">
              {t('fillAllFields')}
          </h6>
           <div className="my-3 px-1 d-flex justify-content-end">
            {/* <Link to="/" className=" mx-1 text-decoration-none">
              <CommonButton classname="back" title={t('back')} />
            </Link> */}
            <ExitButton />
          </div>
          <Card className="my-4 p-3 d-flex justify-content-center align-items-center ">
            <ErrorMessages formik={formik} />
            <Form onSubmit={(e)=>scrollToDiv(e,formik)}>
              <Row>
                <Col md={12}>
                  <Form.Group
                    className="mb-3"
                  >
                    <Form.Label>{t("standbypaymentauth:TrackingID")}<span className="field">*</span></Form.Label>
                    <Form.Control
                      name="tracking"
                      type="text"
                      placeholder={t("standbypaymentauth:TrackingID")}
                      maxLength={STANDBY_TRACKINGID_MAXLENGTH}
                      value={formik.values.tracking}
                      onChange={e => {
                        e.target.value = numericValidation(e.target.value);
                        formik.handleChange(e)
                      }}
                      onBlur={formik.handleBlur}
                      className={
                        formik.touched.tracking && formik.errors.tracking
                          ? "error"
                          : ""
                      }
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                  >
                    <Form.Label>
                      {t("standbypaymentauth:ConfirmationID")}<span className="field">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="confirmationId"
                      placeholder={t("standbypaymentauth:ConfirmationID")}
                      value={formik.values.confirmationId.toUpperCase()}
                      maxLength={STANDBY_CONFIRMATIONID_MAXLENGTH}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={
                        formik.touched.confirmationId && formik.errors.confirmationId
                          ? "error"
                          : ""
                      }
                    />
                  </Form.Group>
                  <CommonButton
                    type="submit"
                    classname="gradient-btn"
                    size="md"
                    title={t("submit")}
                  />
                </Col>
              </Row>
            </Form>
          </Card>
        </Container>
      </section>
    </>
  );
}
export default StandByPaymentAuth;

