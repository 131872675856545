import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function CommonTextArea(props) {
  const { t } = useTranslation(["common"]);
  return (
    <>
    <Row className="my-3">
        <Col
        md={props?.md}
        controlid="exampleForm.ControlTextarea1"
        >
        <Form.Label>
            {" "}
            <FontAwesomeIcon 
            icon={props?.icon}
            size="lg"
            className="me-2 icon-color"
            />
            {props?.heading}
            {props?.mandatory === true && <span className="field">*</span> }
        </Form.Label>
        <Form.Control
            id={props?.id}
            name={props?.name}
            value={props?.text}
            onChange={(e) => {
                props.onChange(props?.name, (props?.event ? e.target.value : e))
            }}
            maxLength={props?.maxLength}
            onBlur={(e) => props?.onBlur(props?.name,  e)}
            className={props?.className}
            as="textarea"
            rows={3}
        />
        </Col>
    </Row>
    <Row className="my-3">
        <Col md={props?.md} controlid="exampleForm.ControlTextarea1">
            <h6 className="d-flex justify-content-end">{props?.maxLength - (props?.text?.length ? props?.text?.length : 0)} {t('feedback:charactersRemaining')}</h6>
        </Col>
    </Row>
    </>
  );
}

export default CommonTextArea;
