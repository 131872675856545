// Import useState hook from react
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useState } from "react";
import { InputGroup } from "react-bootstrap";

import Form from "react-bootstrap/Form";


// Input Password Component
export default function Password(props) {
  const [displayPassword, setDisplayPassword] = useState(false);
  const [password, setPassword] = useState("");

  const togglePassword = () => {
    setDisplayPassword(!displayPassword);
  };

  return (
    <InputGroup className="d-flex">
      <Form.Control
        type={displayPassword ? "text" : "password"}
        id={props.id}
        placeholder={props.placeholder}
        name={props.name}
        value={props.value}
        maxLength={props.maxLength}
        onChange={props.onChange}
        onBlur={props.onBlur}
        isInvalid={props.isInvalid}
        className={props.className}
        autoComplete={props.autoComplete}
      />
      {props.errorMessage}
      <FontAwesomeIcon
        icon={displayPassword ? faEye : faEyeSlash}
        size="sm"
        className="icon-color"
        onClick={() => togglePassword()}
        style={{
          position: "absolute",
          top: 11,
          right: 10,
          cursor: "pointer",
          zIndex: "5",
        }}
      />
    </InputGroup>
  );
}