import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import range from "lodash/range";
import {
  EMAIL_MAXLENGTH,
  FIRST_NAME_MAXLENGTH,
  LAST_NAME_MAXLENGTH,
  REGEX_ALPHABET,
  SALUTATION,
  MAX_DOB_DATE,
  DOB_YEARS,
  maxDOBDate
} from "../../config/commonConstants";
import DatepickerComponent from "./DatepickerComponent";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { alphabetValidation, dateDifferenceInYears, getAdultMinimumAge, translateArrayKeyValue } from "./commonFunctions";
import { showConfirm } from "./Swal";
import { getYear } from "date-fns";

function AdultDetails(props) {
  const [adultDob, setadultDob] = useState();
  const { t } = useTranslation(["common"]);

  const validateDateOfBirth = (val) => {
    const years = dateDifferenceInYears(props.bookingDate, val);
    let adultMinimum = getAdultMinimumAge();
    if (years < (adultMinimum + 1)) {
      const confirmed = showConfirm(t('confirm'), t('adultDobValidationError', { adultMinimum: (adultMinimum + 1) }))
      if (confirmed.isConfirmed) {
        props.onChange("dob", "")
      }
      else {
        props.onChange("dob", "")
      }
    }
    else
      props.onChange("dob", val)
  }
  const [title,setTitle]= useState(translateArrayKeyValue(SALUTATION, "SALUTATION")[0]);

  return (
    <div>
      <Row className="mt-3">
        <Col md="auto" controlid="validationCustom01">
          {props.isMandatory === true ? (
            <Form.Label className="d-flex">
              <FontAwesomeIcon
                icon={faUser}
                className="me-2 icon-color"
                size="lg"
              />
              {t('adult')} <span className="field">*</span>
            </Form.Label>
          ) : (
            <Form.Label ></Form.Label>
          )}
          <Select
            name={`adults.${props.index}.title`}
            options={translateArrayKeyValue(SALUTATION, "SALUTATION")}
            className={props.isMandatory === true ? "my-0 select_width" : "mt-2 select_width"}
            autosize={true}
            value={props.title}
            onChange={(d) =>{props.onChange("title", d);setTitle(d)}}
            onBlur={(e)=>props.onBlur("title", e,title)}
            getOptionValue={(v) => v.value}
            getOptionLabel={(v) => v.label}
          />
        </Col>
        <Col
          md="2"
          controlid="validationCustom01"
          className="mt-2"
        >
          <Form.Label></Form.Label>
          <Form.Control
            type="text"
            name={`adults.${props.index}.firstName`}
            id="validationCustom01"
            placeholder={t('firstName')}
            maxLength={FIRST_NAME_MAXLENGTH}
            // onChange={(e) => {
            //   // props.onChange("firstName", e.target.value)
            //   const newValue = e.target.value.replace(REGEX_ALPHABET, "");
            //   props.onChange("firstName", newValue);
            // }}
            onChange={(e) => {
              // const newValue = e.target.value.replace(REGEX_ALPHABET, "");
              props.onChange("firstName", alphabetValidation(e.target.value));
            }}
            onBlur={(e) => props.onBlur("firstName", e)}
            minLength={1}
            value={props.formik?.values?.adults?.[props.index]?.firstName}
            className={props?.formik?.touched["adults"]?.[props.index]?.["firstName"] && props?.formik?.errors["adults"]?.[props.index]?.["firstName"] ? "error rounded-2" : ""}
          />
        </Col>
        <Col
          md="2"
          controlid="validationCustom02"
          className="mt-2"
        >
          <Form.Label></Form.Label>
          <Form.Control
            type="text"
            name={`adults.${props.index}.lastName`}
            placeholder={t('lastName')}
            // onChange={(e) => {
            //   const newValue = e.target.value.replace(REGEX_ALPHABET, "");
            //   props.onChange("lastName", newValue)}}
            onChange={(e) => {
              // const newValue = e.target.value.replace(REGEX_ALPHABET, "");
              props.onChange("lastName", alphabetValidation(e.target.value));
            }}
            onBlur={(e) => props.onBlur("lastName", e)}
            maxLength={LAST_NAME_MAXLENGTH}
            minLength={1}
            value={props.formik?.values?.adults?.[props.index]?.lastName}
            className={props?.formik?.touched["adults"]?.[props.index]?.["lastName"] && props?.formik?.errors["adults"]?.[props.index]?.["lastName"] ? "error rounded-2" : ""}
          />
        </Col>
        <Col
          md="3"
          controlid="validationCustomUsername"
          className="mt-2"
        >
          <Form.Label></Form.Label>
          <InputGroup>
            <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
            <Form.Control
              type="email"
              placeholder={t('emailAddress')}
              name={`adults.${props.index}.email`}
              aria-describedby="inputGroupPrepend"
              onChange={(e) => props.onChange("email", e.target.value)}
              onBlur={(e) => props.onBlur("email", e)}
              maxLength={EMAIL_MAXLENGTH}
              minLength={1}
              value={props.formik?.values?.adults?.[props.index]?.email}
              className={props?.formik?.touched["adults"]?.[props.index]?.["email"] && props?.formik?.errors["adults"]?.[props.index]?.["email"] ? "error rounded-2" : ""}

            />
          </InputGroup>
        </Col>
        <Col md="3" controlid="validationFormikadultDate" className="mt-4 mt-md-0 d-flex flex-column">
          <Form.Label>{t('dateOfBirthOptional')}</Form.Label>
          <DatepickerComponent
            range={range(getYear(new Date()) - DOB_YEARS, getYear(new Date())+1, 1)}
            variableName="dob"
            onChangeProps={false}
            name={`adults.${props.index}.dob`}
            onBlur={(e) => props.onBlur("dob", e)}
            showClearIcon={true}
            maxLength={LAST_NAME_MAXLENGTH}
            minLength={1}
            value={props.formik?.values?.adults?.[props.index]?.dob}
            maxDate={new Date()}
            minDate={MAX_DOB_DATE}
            onchange={async (val) => {
              validateDateOfBirth(val);
            }}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Col>
      </Row>
    </div >
  );
}

export default AdultDetails;
