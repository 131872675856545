import { VIPATTRACTIONSURL } from "./config/commonConstants";

export const menuDefault = [
  {
    key: "home",
    title: "Home",
    url: "/",
  },
];

export const vipMenus = [
  {
    key: "services",
    title: "SERVICES",
    url: "#",
    submenu: [
      {
        key: "vipLoungeMembershipCard",
        title: "VIP LOUNGE MEMBERSHIP CARD",
        url: VIPATTRACTIONSURL + "page/vip-lounge-membership-card",
        target: "_blank",
      },
      {
        key: "arrival",
        title: "ARRIVAL",
        url: VIPATTRACTIONSURL + "meet-greet",
        target: "_blank",
      },
      {
        key: "departure",
        title: "DEPARTURE",
        url: VIPATTRACTIONSURL + "departure",
        target: "_blank",
      },
      {
        key: "bundle",
        title: "BUNDLE",
        url: VIPATTRACTIONSURL + "bundle-service",
        target: "_blank",
      },
      {
        key: "otherAirportService",
        title: "OTHER AIRPORT SERVICES",
        url: "#",
        submenu: [
          {
            key: "destinationWedding",
            title: "DESTINATION WEDDING",
            url: VIPATTRACTIONSURL + "destination-wedding",
            target: "_blank",
          },
          {
            key: "families",
            title: "FAMILIES",
            url: VIPATTRACTIONSURL + "family",
            target: "_blank",
          },
          {
            key: "group",
            title: "GROUP",
            url: VIPATTRACTIONSURL + "group",
            target: "_blank",
          },
          {
            key: "businessTraveller",
            title: "BUSINESS TRAVELERS",
            url: VIPATTRACTIONSURL + "business-traveller",
            target: "_blank",
          },
          {
            key: "seniorCitizens",
            title: "SENIOR CITIZENS",
            url: VIPATTRACTIONSURL + "senior-ciitzens",
            target: "_blank",
          },
          {
            key: "unaccompainedMinor",
            title: "UNACCOMPAINED MINOR",
            url: VIPATTRACTIONSURL + "unaccompanied-minors",
            target: "_blank",
          },
        ],
      },
      {
        key: "telecomService",
        title: "TELECOM SERVICES",
        url: "http://www.stayconnectedjamaica.com/",
        target: "_blank"
      },
      // {
      //   key: 'updateFlight',
      //   title: 'UPDATE FLIGHT',
      //   url: 'update-flight',
      // },
      {
        key: "retail",
        title: "RETAIL",
        url: VIPATTRACTIONSURL + "page/retail#",
        target: "_blank",
      },
      {
        key: "accessAndMembership",
        title: "ACCESS & MEMBERSHIP",
        url: VIPATTRACTIONSURL + "page/access-and-membership",
        target: "_blank",
      },
      {
        key: "spa",
        title: "SPA",
        url: VIPATTRACTIONSURL + "page/mango-spa-club-mobay",
        target: "_blank",
      },
    ],
  },
  {
    key: "communication",
    title: "COMMUNICATION",
    url: "#",
    submenu: [
      {
        key: "vipNews",
        title: "VIP NEWS",
        url: "#",
        submenu: [
          {
            key: "media",
            title: "MEDIA",
            url: VIPATTRACTIONSURL + "blogs/category/media",
            target: "_blank",
          },
          {
            key: "ourRewards",
            title: "OUR REWARDS",
            url: VIPATTRACTIONSURL + "blogs/category/our-awards",
            target: "_blank",
          },
          {
            key: "vipSocialResponsibilities",
            title: "VIP SOCIAL RESPONSIBILITIES",
            url:
              VIPATTRACTIONSURL +
              "blogs/category/vip-s-social-responsibilities",
            target: "_blank",
          },
          {
            key: "thingsToDoInJamaica",
            title: "THINGS TO DO IN JAMAICA",
            url: VIPATTRACTIONSURL + "blogs/category/things-to-do-in-jamaica",
            target: "_blank",
          },
        ],
      },
      {
        key: "gallery",
        title: "GALLERY",
        url: VIPATTRACTIONSURL + "page/gallery",
        target: "_blank",
      },
      {
        key: "videos",
        title: "VIDEOS",
        url: VIPATTRACTIONSURL + "page/videos",
        target: "_blank",
      },
      {
        key: "brochuresAndForms",
        title: "BROCHURES & FORMS",
        url: VIPATTRACTIONSURL + "page/download-media",
        target: "_blank",
      },
      {
        key: "testimonials",
        title: "TESTIMONIALS",
        url: VIPATTRACTIONSURL + "page/testimonials",
        target: "_blank",
      },
      {
        key: "thanksForMakingThingsBetter",
        title: "THANKS FOR MAKING THINGS BETTER",
        url: VIPATTRACTIONSURL + "page/thanks-for-making-things-better",
        target: "_blank",
      },
      {
        key: "faq",
        title: "FAQ",
        url: VIPATTRACTIONSURL + "page/faqs ",
        target: "_blank",
      },
      {
        key: "safetyProtocols",
        title: "SAFETY PROTOCOLS",
        url: VIPATTRACTIONSURL + "page/safety-protocols",
        target: "_blank",
      },
    ],
  },
  {
    key: "vipAttractions",
    title: "VIP ATTRACTIONS",
    url: "#",
    submenu: [
      {
        key: "whoWeAre",
        title: "WHO WE ARE",
        url: VIPATTRACTIONSURL + "page/who-we-are",
        target: "_blank",
      },
      {
        key: "directorAndManagement",
        title: "THE TEAM DIRECTOR",
        url: VIPATTRACTIONSURL + "page/the-team-directors-and-management",
        target: "_blank",
      },
      {
        key: "clubMobayVacancies",
        title: "CLUB MOBAY VACANCIES",
        url: VIPATTRACTIONSURL + "page/club-mobay-vacancies",
        target: "_blank",
      },
      {
        key: "clubKingstonVacancies",
        title: "CLUB KINGSTON VACANCIES",
        url: VIPATTRACTIONSURL + "page/club-kingston-vacancies",
        target: "_blank",
      },
      {
        key: "corporateOfficeVacancies",
        title: "CORPORATE OFFICE VACANCIES",
        url: VIPATTRACTIONSURL + "page/corporate-office-vacancy",
        target: "_blank",
      },
      {
        key: "airports",
        title: "AIRPORT",
        url: VIPATTRACTIONSURL + "page/airports",
        target: "_blank",
      },
      {
        key: "contact",
        title: "CONTACT",
        url: VIPATTRACTIONSURL + "page/contact",
        target: "_blank",
      },
    ],
  },
  // {
  //   key: "travelSpecialist",
  //   title: "TRAVEL SPECIALIST",
  //   url: "#",
  //   submenu: [
  //     {
  //       key: "LOGIN",
  //       title: "LOGIN",
  //       url: "/login",
  //     },
  //     {
  //       key: "REGISTER",
  //       title: "REGISTER",
  //       url: "/subscriber-registration",
  //     },
  //   ],
  // },
];

export const menuManage = [
  {
    key: "manage",
    title: "MANAGE",
    url: "#",
    submenu: [
      {
        key: "standByBookings",
        title: "STANDBY BOOKINGS",
        url: "/standby-list",
      },
    ],
  },
];

export const menuItemManageUsers = [
  {
    key: "manageUsers",
    title: "MANAGE USERS",
    url: "/distributor-users",
  },
];

export const menuReports = [
  {
    key: "reports",
    title: "REPORTS",
    url: "#",
    submenu: [
      {
        key: "commissionReport",
        title: "commission",
        url: "/commission",
      },
      {
        key: "orderReport",
        title: "order-report",
        url: "/order-report",
      },
      {
        key: "billing",
        title: "billingReport",
        url: "/billing-report",
      },
    ],
  },
];

export const menuVIPMembershipCard = [
  {
    key: "vipMembershipCard",
    title: "VIP MEMBERSHIP CARD",
    url: "#",
    submenu: [
      {
        key: "purchaseMembership",
        title: "PURCHASE MEMBERSHIP",
        url: "/purchasemembership",
      },
      {
        key: "topupMembership",
        title: "TOPUP MEMBERSHIP",
        url: "/topupmembership",
      },
      {
        key: "changeMemberPin",
        title: "CHANGE MEMBERSHIP PIN",
        url: "/member-card/change-pin",
      },
      {
        key: "resetMemberPin",
        title: "RESET MEMBERSHIP PIN",
        url: "/member-card/reset-pin",
      },
      {
        key: "memberProfile",
        title: "MEMBERSHIP PROFILE",
        url: "/member-card/card-profile",
      },
      {
        key: "unlockMemberCard",
        title: "UNLOCK MEMBERSHIP",
        url: "/member-card-unlock",
      },
    ],
  },
];

export const menuFeedback = [
  {
    key: "feedback",
    title: "FEEDBACK",
    url: "/feedback",
  },
];

export const menuGetUpdateFlightDetailsLink = [
  {
    key: "getUpdateFlightDetailsLink",
    title: "UPDATE FLIGHT DETAILS",
    url: "/booking/get-update-link",
  },
];

export const menuItemLinkedMemberCard = [
  {
    key: "linkedMemberCard",
    title: "LINKED MEMBERSHIP",
    url: "/linked-membercards",
  },
];

export const menuItemMyBookings = [
  {
    key: "myBookings",
    title: "myBookings",
    url: "/my-bookings",
  },
];

export const menuItemLinkBooking = [
  {
    key: "linkBooking",
    title: "linkBooking",
    url: "/link-booking-queue",
  },
];

export const menuItemDownloadUserMannual = [
  {
    key: "downloadUserMannual",
    title: "DOWNLOAD USER MANUAL",
    url: "https://obi-final.mentem.in/static/VIP%20Attractions%20Online%20Booking%20Services%20-%20Distributor%20User%20Guide.pdf",
    target: "_blank",
  },
];

export const menuItemTopup = [
  {
    key: "topup",
    title: "topup",
    url: "/topup",
  }
];

export const menuItemBarterBalance = [
  {
    key: "barterbalance",
    title: "barter",
    url: "/barter-balance",
  }
];

export const menuItemUpdateFlightDetailsForm = [
  // {
  //   key: "updateFlightDetailsForm",
  //   title: "updateFlightDetailsForm",
  //   url: "/update-booking-form",
  // },
  {
    key: "searchbooking",
    title: "searchbooking",
    url: "/search-booking",
  }
];

export const menuItemStandbyBundle = [
  {
    key: "standByBundle",
    title: "STANDBY BUNDLE",
    url: "/standby-bundle",
  },
];

export const menuItemStandbyArrival = [
  {
    key: "standByArrival",
    title: "STANDBY ARRIVAL",
    url: "/standby-arrival",
  },
];

export const menuItemStandbyDeparture = [
  {
    key: "standByDeparture",
    title: "STANDBY DEPARTURE",
    url: "/standby-departure",
  },
];