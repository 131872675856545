
import { createSlice } from "@reduxjs/toolkit";
import { getJamaicanTime } from "../components/commonComponents/commonFunctions";
const initialState = {
  availableMarkets: {},
  selectedMarketId: null,
  selectedMarketCurrency: null,
  selectedMarketLanguage: null,
  jamaicanTime: null,
  // defaultMarketCurrency: null,
  // defaultMarketLanguage: null,
  error: false,
}
const marketSlice = createSlice({
  name: "getMarkets",
  initialState: initialState,
  reducers: {
    marketFetchSuccess: (state, action) => {
      state.availableMarkets = action.payload;
      state.selectedMarketId = action.payload.data.defaultmarket;
      state.selectedMarketCurrency = state?.availableMarkets?.data?.markets?.find((m) => m.marketid === action.payload.data.defaultmarket).currencycode ?? "USD";
      state.selectedMarketLanguage = state?.availableMarkets?.data?.markets?.find((m) => m.marketid === action.payload.data.defaultmarket).language ?? "en";
    },
    setJamaicanTime:(state,action)=>{
      state.jamaicanTime = action.payload
    },
    setSelectedMarket: (state, action) => {
      state.selectedMarketId = action.payload;
      state.selectedMarketCurrency = state?.availableMarkets?.data?.markets?.find((m) => m.marketid === action.payload).currencycode ?? "USD";
      state.selectedMarketLanguage = state?.availableMarkets?.data?.markets?.find((m) => m.marketid === action.payload).language ?? "en";
    },
    marketFetchFailure: (state) => {
      state.error = true;
    },
    resetMarketRedux:()=>{
      return initialState;
    }
  },
});

export const { marketFetchSuccess, marketFetchFailure, setSelectedMarket,resetMarketRedux,setJamaicanTime } = marketSlice.actions;
export const selectedMarket = (state) => state?.marketRedux?.availableMarkets?.data?.markets?.find((m) => m.marketid === state.marketRedux?.selectedMarketId) ?? {};
export const selectedMarketId = (state) => state.marketRedux?.selectedMarketId;
export const availableMarkets = (state) => state.marketRedux?.availableMarkets;
export const selectedMarketCurrency = (state) => state.marketRedux?.selectedMarketCurrency || "USD";
export const selectedMarketLanguage = (state) => state.marketRedux?.selectedMarketLanguage || "en";
export default marketSlice.reducer;