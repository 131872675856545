import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getProductName, getClubName, getAirportName, myDateFormat, dateFormatForDisplay } from "./commonFunctions";
import { useSelector } from "react-redux";
import { selectedMarket as currentMarket, availableMarkets as AM } from "../../redux/marketRedux";
import ContactDetailsDisplay from "./ContactDetailsDisplay";
import BookingDetailsContactDetailsDisplay from "./BookingDetailsContactDetailsDisplay";
import { PRODUCTID_ARRIVAL, PRODUCTID_ARRIVALBUNDLE, PRODUCTID_DEPARTURE } from "../../config/commonConstants";
import HtmlToReactConverter from "./HtmlToReactConverter";
import { useState } from "react";
import StandByPayment from "../../pages/StandByPayment";
import { faChild, faPersonBreastfeeding, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function BookingSummaryComponent(props) {
  const { t } = useTranslation(["common", "bookingsummarycomponent"]);
  const selectedMarket = useSelector(currentMarket);
  const availableMarkets = useSelector(AM);
  const [currentDate, setCurrentDate] = useState(Date());
  const airports = selectedMarket?.airports;
  let allDatePassed = false;
  const allFlightsEditable = props.allFlightsEditable;
  
  const renderBundleInfoAndMessage = () => {
    allDatePassed = true;
    props.orderDetail.arrivalbundles && props.orderDetail.arrivalbundles.forEach((record, key) => {
      if (record.arrivalrecord.flighteditable === "Y") {
        allDatePassed = false;
        return;
      }

      record.departurerecords.forEach((departureRecord) => {
        if (departureRecord.flighteditable === "Y") {
          allDatePassed = false;
          return;
        }
      });
    });

    return (
      props.orderDetail.arrivalbundles && props.orderDetail.arrivalbundles.map((record, key) => (
        <>
          <div key={"bundle_" + key}>
            {renderBookingSummaryComponent(t("arrivalDetails"), record.arrivalrecord, t("bookingsummarycomponent:arrivalId"), allDatePassed)}
            {record.departurerecords.map((departureRecord, departureKey) => (
              <div key={"departure_" + departureKey}>
                {renderBookingSummaryComponent(t("departureDetails"), departureRecord, t("bookingsummarycomponent:departureId"), allDatePassed)}
              </div>
            ))
            }
          </div> 

          {(allFlightsEditable === "Y" && allDatePassed === true && props?.renderType === "UPDATE-FLIGHT-DETAILS") && (
            <>
              <hr />
              <Row>
                <Col>
                  <h5 className="my-bg-info fs-5 lh-base">
                    <HtmlToReactConverter value={t("noteEditableMessage", { skype: selectedMarket?.contact?.skype, email: selectedMarket?.contact?.email })} />
                  </h5>
                </Col>
              </Row>
            </>
          )}
        </>
      ))
    );
  };
  
  const renderBookingRecordHeader = () => {
    return (
      <>
        <Row className="d-flex justify-content-center align-content-center mb-1">
          <Col md={12} sm={12}>
            {/* <h4 className="my-2">{props?.orderDetail.heading}</h4> */}
            {props.orderDetail.productid ? (
              <h4 className="mb-1">
                <b>{t("bookingsummary:serviceCategory")}:</b>{" "}
                {getProductName(props?.orderDetail.productid)}
              </h4>
            ) : (
              ""
            )}
          </Col>
        </Row>

        {props?.renderType === "STANDBY-PAYMENT" && (
          <Row className="d-flex justify-content-center align-content-center mb-1">
            <Col md={12} sm={12}>
              <h5 className="mb-1">
              <b>{t("bookingsummarycomponent:tracking")}:</b>{" "}
                {props?.trackingNumber}
              </h5>
            </Col>
          </Row>
        )
        }

        <Row className="d-flex align-content-center mb-1">
          <>
            {(props.confirmationNumber !== undefined && props?.renderType !== "STANDBY-PAYMENT" && props.confirmationNumber !== '') &&
              (
                <Col md={6} sm={6}>
                  <h5 className="mb-1">
                    <b>{t("bookingsummarycomponent:confirmationNumber")}:</b>{" "}
                    {props?.confirmationNumber}
                  </h5>
                </Col>
              )
            }
            {(props.referenceNumber !== undefined && props.referenceNumber !== '' && props.referenceNumber !== "ONLINE BOOKING") &&
              (
                <Col md={6} sm={6}>
                  <h5 className="mb-1">
                    <b>{t("bookingsummarycomponent:referenceNumber")}:</b>{" "}
                    {props?.referenceNumber}
                  </h5>
                </Col>
              )
            }
          </>
        </Row>
        {props?.showContactDetails !== false && props.primaryContact && props.primaryContact.contactname !== "" &&
          <BookingDetailsContactDetailsDisplay contactType="primary" contactDetails={props.primaryContact} />
        }
        {props?.showContactDetails !== false && props.secondaryContact && props.secondaryContact.contactname !== "" && props.secondaryContact.contactname !== " " &&
          <BookingDetailsContactDetailsDisplay contactType="secondary" contactDetails={props.secondaryContact} />
        }
      </>
    );
  };

  const renderBookingSummaryComponent = (heading, bookingRecord, reservationIdHeading, allDatePassedCheck) => { 
    return (
      <>
        {/* {!(props.bookingRecord.productid === "ARRIVALBUNDLE" && props.productType === "DEPARTURELOUNGE") ?
        <Row className="d-flex justify-content-center align-content-center mb-1">
          <Col md={12} sm={12}>
            <h4 className="my-2">{props?.bookingRecord.heading}</h4>
            {props.bookingRecord.productid ?
              <h4 className="mb-1">
                <b>{t("bookingsummary:serviceCategory")}:</b> {getProductName(props?.bookingRecord.productid)}
              </h4> : ""}
          </Col>
        </Row> : null} */}
        <hr />
        <Row className="d-flex justify-content-center align-content-center mb-1">
          <Col md={12} sm={12}>
            <h5 className="mb-1">
              <b>{heading}</b>
            </h5>
          </Col>
        </Row>
        
        {(props?.renderType !== "STANDBY-PAYMENT") &&
          <Row className="d-flex justify-content-center align-content-center mb-1">
            <Col md={12} sm={12}>
              <h5 className="mb-1">
                <b>{reservationIdHeading}:</b> {bookingRecord.reservationid}
              </h5>
            </Col>
          </Row>
        }

        <Row className="d-flex justify-content-center align-content-center mb-1">
          <Col md={12} sm={12}>
            <h5 className="mb-1">
              <b>{t("bookingsummarycomponent:loungeService")}:</b> {getClubName(bookingRecord?.airportid)} {" / "} {getAirportName(bookingRecord?.airportid)}
            </h5>
          </Col>
        </Row>

        <Row className="d-flex justify-content-center align-content-center mb-1">
          <Col md={4} sm={12}>
            {/* {props.renderType === "STANDBY-PAYMENT" ? (
              <h5 className="mb-1">
                <b>{t("bookingsummarycomponent:flightDate")}:</b>{myDateFormat(bookingRecord?.flightdate, "yyyyMMdd HHmm", "MM/dd/yyyy hh:mm a")}
                </h5>
            ) : ( */}
              <h5 className="mb-1">
                <b>{t("bookingsummarycomponent:flightDate")}: </b>{myDateFormat(bookingRecord?.flightdate, "yyyyMMdd HHmm", "MM/dd/yyyy hh:mm a")}
              </h5>
            {/* )} */}
          </Col>
          <Col md={4} sm={12}>
            <h5 className="mb-1">
              <b>{t("bookingsummarycomponent:airline")}:</b> {bookingRecord?.airline}
            </h5>
          </Col>
          <Col md={4} sm={12}>
            <h5 className="mb-1">
              <b>{t("bookingsummarycomponent:flightNumber")}: </b>{bookingRecord?.flightid}
            </h5>
          </Col>
        </Row>

        <Row className="d-flex align-content-center mb-1">
          <Col md={12} sm={12}>
            <h5 className="mb-1">
              <b>{t("serviceFor")}: </b>
              <FontAwesomeIcon
                icon={faUser}
                className="mx-1 icon-color"
                size="sm"
              />{" "}
              {t("adults")}: {bookingRecord.adulttickets}{" "}
              <FontAwesomeIcon
                icon={faChild}
                size="sm"
                className="mx-1  icon-color"
              />{" "}
              {t("children")}: {bookingRecord.childtickets}{" "}
              <FontAwesomeIcon
                icon={faPersonBreastfeeding}
                size="sm"
                className="mx-1  icon-color"
              />{" "}
              {t("infants")}: {bookingRecord.infanttickets}
            
            </h5>
          </Col>
          {/* <Col md={4} sm={12}>
            <h5 className="mb-1">
              <b>{t("bookingsummarycomponent:adultTicket")}:</b> {bookingRecord.adulttickets}
            </h5>
          </Col>
          <Col md={4} sm={12}>
            <h5 className="mb-1">
              <b>{t("bookingsummarycomponent:childTicket")}: </b>{bookingRecord.childtickets}
            </h5>
          </Col>
          <Col md={4} sm={12}>
            <h5 className="mb-1">
              <b>{t("bookingsummarycomponent:infantTicket")}: </b>{bookingRecord.infanttickets}
            </h5>
          </Col> */}
        </Row>
        <Row className="d-flex align-content-center mb-1">
          <Col md={12} sm={12}>
            {/* <h5 className="mb-1">
              <b>{t("bookingsummarycomponent:guestName")}:</b> {`${bookingRecord.guests[0].firstname} ${bookingRecord.guests[0].lastname}`}
            </h5> */}
            <h5 className="mb-1">
              <b>{t("bookingsummarycomponent:guestName")}: </b>
              {bookingRecord?.guests.map((departureRecord) => `${departureRecord.firstname} ${departureRecord.lastname}`).join(", ")}
            </h5>
            {/* {bookingRecord?.guests.map((departureRecord, index) => (
              <div key={index}>
                <h5 className="mb-1">
                  {`${departureRecord.firstname} ${departureRecord.lastname}`}
                </h5>
              </div>
            ))
            } */}
            
          </Col>
          
          {props.renderType === "UPDATE-FLIGHT-DETAILS" ?
            <Col md={4} sm={12}>
              <h5 className="mb-1">
                <b>{t("bookingsummarycomponent:bookingNumber")}:</b> {bookingRecord?.lookupkey}
              </h5>
            </Col> : ""}
        </Row>
  
        {(allFlightsEditable === "Y" && allDatePassedCheck === false && bookingRecord.flighteditable === "N" && props?.renderType === "UPDATE-FLIGHT-DETAILS") && (
          <Row>
            <Col>
              <h5 className="my-bg-info fs-5 lh-base">
                <HtmlToReactConverter value={t("noteEditableMessage", { skype: selectedMarket?.contact?.skype, email: selectedMarket?.contact?.email })} />
              </h5>
            </Col>
          </Row>
        )}

      </>
    );
  };

  return (
    <>
      {renderBookingRecordHeader()}

      {renderBundleInfoAndMessage()}
      
      {props.orderDetail.arrivalrecords && props.orderDetail.arrivalrecords.map((record, key) => (
        <div key={"arrival_" + key}>
          {renderBookingSummaryComponent(t("arrivalDetails"), record, t("bookingsummarycomponent:arrivalId"), false)}
        </div>
      ))
      }

      {props.orderDetail.departurerecords && props.orderDetail.departurerecords.map((record, key) => (
        <div key={"departure_" + key}>
          {renderBookingSummaryComponent(t("departureDetails"), record, t("bookingsummarycomponent:departureId"), false)}
        </div>
      ))
      }

      {(allFlightsEditable === "N") && (
        <>
          <hr />
          <Row>
            <Col>
              <h5 className="my-bg-info fs-5 lh-base">
                <HtmlToReactConverter value={t("multipleFlightEditableMessage", { skype: selectedMarket?.contact?.skype, email: selectedMarket?.contact?.email })} />
              </h5>
            </Col>
          </Row>
        </>
      )}

    </>
  );
}

export default BookingSummaryComponent;
