import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Form } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function OverlayTooltip(props) {
  const [data, setData] = useState(props.msg);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {data}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <div>
        <Form.Label className="mb-0" htmlFor={props.for}> {props.title}{props.mandatory==true?<span className="field">*</span>:null}</Form.Label>
        <FontAwesomeIcon icon={faCircleInfo} className="mx-2 icon-color" />
      </div>
    </OverlayTrigger>
  );
}

export default OverlayTooltip;
