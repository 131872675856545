import { faUser, faPhone, faAt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { PRIORITYPASS_LOGIN } from "../../config/commonConstants";
import {logindetails as LD} from "../../redux/signInRedux"
import { useSelector } from "react-redux"

function PriorityPassDetailsDisplay(props) {
  const loginDetail = useSelector(LD);

  const { t } = useTranslation(["common"]);
  return (
    <div >
      <Row className="d-flex justify-content-center align-content-center mb-2">
        <Col md={12} sm={12}>
          <h5 className="mb-1">
            <b>{t('membershipCardholderInformation')}</b>
          </h5>
        </Col>
      </Row>

      <Row className="d-flex justify-content-start align-content-center mb-1">
        <Col md={4} sm={12}>
          <h5>
            {/* <FontAwesomeIcon
              icon={faPhone}
              className="mx-1 icon-color"
              size="sm"
            /> */}
            <b>{t('name')}: </b>{props?.priorityPassDetails?.name}
          </h5>
        </Col>
        <Col md={4} sm={12}>
          <h5>
            {/* <FontAwesomeIcon
              icon={faUser}
              className="mx-1 icon-color"
              size="sm"
            />{" "} */}
            <b>{t('last4digitCard')}: </b>{props?.priorityPassDetails?.cardNumber}
          </h5>
        </Col>
      { loginDetail?.username === PRIORITYPASS_LOGIN.username && <Col md={4} sm={12}>
          <h5>
            {/* <FontAwesomeIcon
              icon={faAt}
              size="sm"
              className="mx-1 icon-color"
            /> */}
            <b>{t('cardtype')}: </b>{props?.priorityPassDetails?.cardType}
          </h5>
        </Col>}
        <Col md={4} sm={12}>
          <h5>
            {/* <FontAwesomeIcon
              icon={faAt}
              size="sm"
              className="mx-1 icon-color"
            /> */}
            <b>{t('expirationDate')}: </b>{props?.priorityPassDetails?.cardMonth + "/" + props?.priorityPassDetails?.cardYear}
          </h5>
        </Col>
      </Row>
    </div>
  );
}

export default PriorityPassDetailsDisplay;
