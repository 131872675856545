import Modal from "react-bootstrap/Modal";
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import CommonButton from "./CommonButton";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";
import { showError, showSuccess } from "./Swal";
import {
  HandleAPIError,
  alphabetValidation,
  divRef,
  loginFunction,
  numericValidation,
  scrollToDiv,
} from "./commonFunctions";
import { ref, object, string } from "yup";
import { useFormik } from "formik";
import { CONTACT_NUMBER_MAXLENGTH, EMAIL_MAXLENGTH, EMAIL_POLICY, FIRST_NAME_MAXLENGTH, LAST_NAME_MAXLENGTH, MANAGEUSER_STATUS, REGEX_ALPHABET } from "../../config/commonConstants";
import ErrorMessages from "./ErrorMessages";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logindetails as LD, userDefault as UD, updateSubscriberInfo } from "../../redux/signInRedux";
import * as Yup from "yup";
import { addEditUser } from "../../redux/apiCalls";
import { selectedMarketId as SM } from "../../redux/marketRedux";
import Select from "react-select";

export default function UpdateProfileModal(props) {
  const [render,setRender] = useState(false)
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginDetails = useSelector(LD);
  const selectedMarketId = useSelector(SM);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(["common", "subscriberregistration"]);
  const [Status, setStatus] = useState({ value: "", error: "", });

  const validationSchema = object().shape({
    userName: Yup.string().trim().required(t("subscriberregistration:errors.updateProfileUserName.required")),
    firstName: Yup.string().trim().required(t("subscriberregistration:errors.updateProfileFirstName.required")),
    lastName: Yup.string().trim().required(t("subscriberregistration:errors.updateProfileLastName.required")),
    email: Yup.string().trim().required(t("subscriberregistration:errors.updateProfileEmail.required"))
      .matches(EMAIL_POLICY, t("subscriberregistration:errors.updateProfileEmail.match")),
    phone: Yup.string().trim().required(t("subscriberregistration:errors.updateProfilePhone.required")),   
  });

  const formik = useFormik({
    initialValues: {
      userName: "",
      firstName: "",
      lastName: "",
      email:"",
      phone:"",
      status:""
    },
    validationSchema,
    onSubmit: () => {
      handleSubmit(formik.values);
    },
  });

  const handleSubmit = async () => {
    const data = {
      "username": formik?.values?.userName,
      "firstname": formik?.values?.firstName,
      "lastname": formik?.values?.lastName,
      "email": formik?.values?.email,
      "marketid": selectedMarketId,
      "subscriber": "Y",
      "reportagent": "N",
      "adminuser": "N",
      "phone": formik?.values?.phone,
      "status": "A"
    };
    let editMode = "Y";
    try {
      setLoading(true);
      const response = await addEditUser(data, editMode, "N");
      let successMessage = t('subscriberregistration:updateProfileSuccessMessage');
      if (response?.status === 0) {
        const subscriberObj = {
          "firstname" : data.firstname,
          "lastname":data.lastname,
          "displayname": data.firstname.trim() + " " + data.lastname.trim(),
          "email" : data.email,
          "phone" : data.phone
        }
        dispatch(updateSubscriberInfo(subscriberObj));
        await showSuccess(response?.statusMessage, successMessage);
        props.cancelModal();
      }
      else {
        showError(t("error"), response?.statusMessage);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  };

  const cancelHandler = () => {
    formik.resetForm();
    setRender(!render)
    props.cancelModal();
  };

  const formReset = () => {
    formik.resetForm();
  };

  useEffect(()=>{
    props.visible && loginFunction(navigate,location,props.sendBroadcastMessage,dispatch,true);
  },[props.visible])

  useEffect(()=>{
    formik.setFieldValue("userName", props?.userDefault?.username);
    formik.setFieldValue("firstName", props?.userDefault?.firstname);
    formik.setFieldValue("lastName", props?.userDefault?.lastname);
    formik.setFieldValue("email", props?.userDefault?.email);
    formik.setFieldValue("phone", props?.userDefault?.phone);
    setStatus({ value: MANAGEUSER_STATUS[0]});
    // formik.setFieldValue("status", {value: MANAGEUSER_STATUS[0].value, label:MANAGEUSER_STATUS[0].label});
  },[props])

  useEffect(()=>{
    formik.setErrors({}, false);
    formik.setTouched({}, false);
  },[render])
  return (
    <>
      <Modal
        show={props.visible}
        onHide={cancelHandler}
        size="md"
        style={{ zIndex: "9998" }}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton><h3 className="fw-bold modalHeading">{t("updateProfile")}</h3></Modal.Header>
        <Loader loading={loading} message={t("changepassword:Processing")} />
        <Form onSubmit={(e)=> scrollToDiv(e,formik)}  ref={divRef} style={{whiteSpace: "pre-line"}}>
          <Modal.Body className="mb-3">
            <Row className="d-flex justify-content-center">
              <Col md={10}>
                <Card className="bg-light bg-opacity-10 rounded-3 ">
                  <Card.Body>
                    <Row className="d-flex justify-content-start">
                      <Col md={12}>
                        <h6 className="field">{t('fillAllFields')}</h6>
                      </Col>
                    </Row>
                    <ErrorMessages formik={formik} />
                    <Row>
                      <Col md="12" className="mb-3">     
                      <Form.Label className="d-flex" htmlFor="validationCustomname">
                          {t('userName')}<span className="field">*</span>
                        </Form.Label>
                        <InputGroup className="d-flex">
                          <Form.Control
                            type="text"
                            name="userName"
                            id="validationCustomname"
                            placeholder={t('userName')}
                            value={formik.values.userName}
                            aria-describedby="inputGroupPrepend"
                            // onChange={(e) => {
                            //   e.target.value = e.target.value?.replace(REGEX_ALPHABET,"");
                            //   formik.handleChange(e);
                            //   }}	 
                            onChange={(e) => {
                              e.target.value = alphabetValidation(e.target.value)
                              formik.handleChange(e);
                            }}                          
                            disabled={formik.values.userName}
                            onBlur={formik.handleBlur}
                            className={formik.touched.userName && formik.errors.userName ? "error" : ""}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" className="mb-3">     
                      <Form.Label className="d-flex" htmlFor="validationCustomname">
                          {t('firstName')}<span className="field">*</span>
                        </Form.Label>
                        <InputGroup className="d-flex">
                          <Form.Control
                            type="text"
                            name="firstName"
                            id="validationCustomname"
                            placeholder={t('firstName')}
                            value={formik.values.firstName}
                            aria-describedby="inputGroupPrepend"
                            // onChange={(e) => {
                            //   e.target.value = e.target.value?.replace(REGEX_ALPHABET,"");
                            //   formik.handleChange(e);
                            //   }}	    
                            onChange={(e) => {
                              e.target.value = alphabetValidation(e.target.value)
                              formik.handleChange(e);
                            }}                          
                            onBlur={formik.handleBlur}
                            className={formik.touched.firstName && formik.errors.firstName ? "error" : ""}
                            maxLength={FIRST_NAME_MAXLENGTH}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" className="mb-3">     
                      <Form.Label className="d-flex" htmlFor="validationCustomname">
                          {t('lastName')}<span className="field">*</span>
                        </Form.Label>
                        <InputGroup className="d-flex">
                          <Form.Control
                            type="text"
                            name="lastName"
                            id="validationCustomname"
                            placeholder={t('lastName')}
                            value={formik.values.lastName}
                            aria-describedby="inputGroupPrepend"
                            // onChange={(e) => {
                            //   e.target.value = e.target.value?.replace(REGEX_ALPHABET,"");
                            //   formik.handleChange(e);
                            //   }}	   
                            onChange={(e) => {
                              e.target.value = alphabetValidation(e.target.value)
                              formik.handleChange(e);
                            }}                          
                            onBlur={formik.handleBlur}
                            className={formik.touched.lastName && formik.errors.lastName ? "error" : ""}
                            maxLength={LAST_NAME_MAXLENGTH}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" className="mb-3">  
                      <Form.Label className="d-flex" htmlFor="validationCustomEmail">
                          {t('email')}<span className="field">*</span>
                        </Form.Label>
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            @
                          </InputGroup.Text>
                          <Form.Control
                            type="email"
                            name="email"
                            id="validationCustomEmail"
                            placeholder={t('emailAddress')}
                            value={formik.values.email}
                            aria-describedby="inputGroupPrepend"
                            maxLength={EMAIL_MAXLENGTH}
                            minLength={1}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={formik.touched.email && formik.errors.email ? "error" : ""}
                          />
                        </InputGroup>                     
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" className="mb-3">
                        <Form.Label className="d-flex" htmlFor="validationPhone">
                          {t('manageuser:phone')}<span className="field">*</span>
                        </Form.Label>
                        <InputGroup className="d-flex">
                          <Form.Control
                            type="text"
                            name="phone"
                            id="validationPhone"
                            placeholder={t('manageuser:phone')}
                            value={formik.values.phone}
                            aria-describedby="inputGroupPrepend"
                            onChange={(e) => {
                              e.target.value = numericValidation(e.target.value)
                              formik.handleChange(e);
                              }}	                              
                            onBlur={formik.handleBlur}
                            className={formik.touched.phone && formik.errors.phone ? "error" : ""}
                            maxLength={CONTACT_NUMBER_MAXLENGTH}
                          />
                        </InputGroup>
                      </Col>
                    </Row>

                    {/* <Row>
                        <Col
                          md="12"
                          className="mb-3"
                        >
                          <Form.Label className="d-flex">
                            {t('manageuser:status')}
                          </Form.Label>
                          <Form.Group className="">
                            <Select
                              className="my-0 mb-3"
                              options={MANAGEUSER_STATUS}
                              defaultValue={MANAGEUSER_STATUS[0]}
                              // value={formik?.values?.status.value}
                              value={Status.value}
                              onChange={(value) =>
                                setStatus({
                                  value: value,
                                  error: "",
                                })
                              }
                            />
                          </Form.Group>
                        </Col>
                    </Row> */}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <CommonButton
              classname="gradient-btn"
              title={t("updateProfile")}
              type="submit"
            />
            <CommonButton
              classname="exit ms-2"
              title={t("close")}
              onclick={cancelHandler}
              type="button"
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
