import { useState } from "react";
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import magna from "../../images/magna.png";
import CommonButton from "./CommonButton";
import Password from "./Password";
import { EMAIL_MAXLENGTH, MEGNA_CARD_CONTACT, MEGNA_CARD_CONTACT2, MEGNA_CARD_WHATSAPP_CONTACT, MEGNA_CARD_EMAIL, PASSWORD_MAXLENGTH } from "../../config/commonConstants";
import { t } from "i18next";
import HtmlToReactConverter from "./HtmlToReactConverter";
import { processMagnaTransaction } from "../../redux/apiCalls";
import Loader from "./Loader";
import { showError } from "./Swal";
import { HandleAPIError, scrollToDiv } from "./commonFunctions";

function MagnaCard(props) {
  const [validated, setValidated] = useState(false);
  const [password, setPassword] = useState("")
  const [pointsData, setPointsData] = useState(null);
  const [dollarValue, setDollarValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isTableVisible, setTableVisible] = useState(false);
  // const [dollarValue,setDollarVallue] = useState({valuee:"",error:""});
  const passwordHandler = (password) => {
    setPassword(password);
  };

  const memberid = props.formik.values.magnaEmail;
  const pin = props.formik.values.magnaPassword;
  const amount = props.formik.values.amount;

  const handleVerify = async (e) => {
    if (memberid === "" || pin === "") {
      scrollToDiv(e,props.formik)
      // props.formik.handleSubmit();
      return;
    }
    else {
      setLoading(true);
      try {
        const getPointsData = await processMagnaTransaction({
          memberid,
          pin,
          amount,
          action: 'GETPOINTS',
        });

        if (getPointsData.status === 0) {
          setPointsData(getPointsData.data.points);
          setDollarValue(getPointsData.data.value); // Update the state with the received points data
          if (getPointsData.data.value < amount) {
            showError(t("error"), t('insufficientBalance'));
          }
          setTableVisible(true);
        }
        else {
          showError(t("error"), getPointsData.statusMessage);
        }
      } catch (error) {
        HandleAPIError();
      } finally {
        setLoading(false)
      }
      setValidated(true);
    }
  }
  return (
    <div>
      <h4 className="px-1 mb-3 ">{t('payment:magnaCard')}</h4>
      <Card className="bg-success  bg-opacity-10 rounded-3 px-0 mb-2">
        <Loader loading={loading} />
        <Card.Body>
          <Row>
            <Col md={8} sm={12}>
              <div>
                <HtmlToReactConverter value={t('magnacard:magnaCardInformationText', { contact1: MEGNA_CARD_CONTACT, contact2: MEGNA_CARD_CONTACT2, whatsapp: MEGNA_CARD_WHATSAPP_CONTACT, email: MEGNA_CARD_EMAIL })} />
              </div>
            </Col>
            <Col md={4} sm={6}>
              <Card.Img
                variant="top"
                src={magna}
                className="img-responsive center-block"
                height="75"
                alt=""
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <h4 className=" py-3 px-1 ">{t('magnacard:confirmationDetails')}</h4>
      <Card className="bg-light  bg-opacity-10 rounded-3 px-0 mb-2">
        <Card.Body>
          <Row>
            <Col
              md={5}
            >
              <Form.Label className="d-flex" htmlFor="validationCustomMagnaCardEmail">
                {t('payment:sendConfirmationEmailTo')}:
                <span className="field">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                <Form.Control
                  type="email"
                  id="validationCustomMagnaCardEmail"
                  placeholder={t('emailAddress')}
                  aria-describedby="inputGroupPrepend"
                  name="confirmationEmail"
                  value={props.formik.values.confirmationEmail}

                  maxLength={EMAIL_MAXLENGTH}
                  minLength={1}
                  className={props.formik.touched.confirmationEmail && props.formik.errors.confirmationEmail ? "error" : ""}

                  onBlur={props.formik.handleBlur}
                  onChange={props.formik.handleChange}

                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {t('chooseEmailAddress')}.
                </Form.Control.Feedback>
              </InputGroup>
            </Col>

            <Col
              md={5}
              className="mt-2"
            >
              <Form.Label htmlFor="validationCustomMagnaCardCopyToEmail">{t('payment:copyTo')} ({t('payment:optional')})</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                <Form.Control
                  type="email"
                  id="validationCustomMagnaCardCopyToEmail"
                  placeholder={t('emailAddress')}
                  aria-describedby="inputGroupPrepend"
                  maxLength={EMAIL_MAXLENGTH}
                  minLength={1}
                  name="copytoEmail"
                  value={props.formik.values.copytoEmail}
                  onBlur={props.formik.handleBlur}
                  onChange={props.formik.handleChange}
                  className={props.formik.touched.copytoEmail && props.formik.errors.copytoEmail ? "error" : ""}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {t('chooseEmailAddress')}.
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Row>

          <Row>
            <Col
              md={5}
            >
              <Form.Label className="d-flex" htmlFor="validationCustomMagnaAmount">
                {t('amount')} ({t("usd")}) 
              </Form.Label>
              <Form.Control
                id="validationCustomMagnaAmount"
                disabled
                type="text"
                placeholder=""
                name="amount"
                value={props.formik.values.amount && props.formik.values.amount.toFixed(2)}
                className=""
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <h4 className=" py-3 px-1 ">{t('cardholderDetails')}</h4>
      <Card className="bg-light  bg-opacity-10 rounded-3 px-0 mb-2">
        <Card.Body>
          <Row className="g-3">
            <Col
              md="6"
            >
              <Form.Label className="d-flex" htmlFor="validationCustomUsername">
                {t('magnacard:magnaEmail')}<span className="field">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                <Form.Control
                  type="email"
                  id="validationCustomUsername"
                  name="magnaEmail"
                  placeholder={t('emailAddress')}
                  aria-describedby="inputGroupPrepend"


                  //  onChange={(e) => props.onChange("magnaEmail", e.target.value)}
                  maxLength={EMAIL_MAXLENGTH}
                  minLength={1}
                  value={props.formik.values.magnaEmail}
                  // onChange={(e) => props.onChange("confirmationEmail", e.target.value)}

                  className={props.formik.touched.magnaEmail && props.formik.errors.magnaEmail ? "error" : ""}

                  onBlur={props.formik.handleBlur}
                  onChange={props.formik.handleChange}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {t('chooseEmailAddress')}.
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
            <Col md="6">
              <Form.Label className="d-flex" htmlFor="validationCustom01">
                {t('magnacard:magnaPassword')} <span className="field">*</span>
              </Form.Label>

              <Password
                placeholder={t("currentPassword")}
                id="validationCustomPassword"
                name="magnaPassword"
                maxLength={PASSWORD_MAXLENGTH}
                autoComplete="new-password"
                value={props.formik.values.magnaPassword}
                onChange={props.formik.handleChange}
                onBlur={props.formik.handleBlur}
                className={props.formik.touched.magnaPassword && props.formik.errors.magnaPassword ? "error rounded-2" : "rounded-2"}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Col>

            <Col md="12" controlid="validationCustom02">
              <CommonButton
                classname="gradient-btn  w-100"
                title={t('verify')}
                size="md"
                type="button"
                onclick={(e) => handleVerify(e)}
              // onclick={props.onClick}
              />
            </Col>
            {isTableVisible && (
              <Col md="12">
                <table className="table table-bordered table-striped">
                  <thead className="thead-dark">
                    <tr>
                      <th className="text-danger">Points</th>
                      <th className="text-danger">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{pointsData}</td>
                      <td>{dollarValue}</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            )}
            <Col md="12" controlid="validationCustom02">
              {isTableVisible && (
                <CommonButton
                  classname="gradient-btn  w-100"
                  title={t('redeem')}
                  size="md"
                  // type="submit"
                  disabled={props.submitOnClickDisable}
                  onclick={props.onClick}
                />
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>

    </div>
  );
}

export default MagnaCard;