import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  demographicInformation: {},
  error: false,
}

const demographicInformationSlice = createSlice({
  name: "getDemographicInformation",
  initialState: initialState,
  reducers: {
    setDemographicInformation: (state, action) => {
      state.demographicInformation = action.payload;
    },
    clearDemographicInformation: state => {
      return initialState;
    },
  },
});

export const { setDemographicInformation, clearDemographicInformation } = demographicInformationSlice.actions;
export const demographicInformation = (state) => state.demographicInformationRedux?.demographicInformation;

export default demographicInformationSlice.reducer;