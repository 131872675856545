import Navbar from "react-bootstrap/Navbar";
import Weather from "./commonComponents/Weather";
import SocialMediaIcon from "./commonComponents/SocialMediaIcon";
import Button from "react-bootstrap/Button";
import {
  faCartShopping,
  faSquarePhone,
} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useTranslation } from "react-i18next";
import {
  faFacebookSquare,
  faSquareXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  FLAG_URL,
  LOGIN_BUTTON_SHOW_TO_USERS,
} from "../config/commonConstants";
import {
  selectedMarket as currentMarket,
  selectedMarketId as SM,
  availableMarkets as AM,
} from "../redux/marketRedux";
import { getMarketCityArray, showPartnerLoginButton, showSubscriberLoginButton } from "./commonComponents/commonFunctions";
import { useLocation } from "react-router-dom";
import { cartData as CD } from "../redux/cartRedux";
import { Nav, OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "react-bootstrap/Tooltip";
import { giftCartData as GCD } from "../redux/giftCartRedux";
import { userDefault as UD } from "../redux/signInRedux";
import MarketSelect from "./commonComponents/MarketSelect";

const Header = ({sendBroadcastMessage}) => {
  const { t } = useTranslation(["common", "header"]);
  const location = useLocation();
  const country_name_with_flag = [];
  const selectedMarket = useSelector(currentMarket);
  const selectedMarketId = useSelector(SM);
  const availableMarkets = useSelector(AM);
  const marketCities = getMarketCityArray(selectedMarket?.airports);
  const cartData = useSelector(CD);
  const giftCartData = useSelector(GCD);
  const marketSelectionZIndex = 1021; //9998
  const userDefault = useSelector(UD);

  availableMarkets?.data?.markets?.forEach((m) => {
    country_name_with_flag.push({
      value: m?.marketid,
      label: (
        <div>
          <img
            src={FLAG_URL + m?.image}
            width="30"
            className="d-inline-block align-left"
            alt={m?.marketname}
          />{" "}
          {m?.marketname}
        </div>
      ),
    });
  });

  const cartIconClickHandler = () => {
    return giftCartData.length > 0 ? "/giftSummary" : "/bookingSummary";
  };

  const tooltip = (
    <Tooltip id="tooltip-cart" className="tooltip" style={{ fontSize: "15px" }}>
      {t("emptyCartTooltipText")}
    </Tooltip>
  );

  const renderFontAwesomeCartIcon = () => {
    return (
      <FontAwesomeIcon
        icon={faCartShopping}
        size="2xl"
        className="text-light pe-3"
      />
    );
  };

  const renderCartIcon = () => {
    return (
      <div className="cart position-relative">
        {cartData?.length > 0 || giftCartData?.length > 0 ? (
          renderFontAwesomeCartIcon()
        ) : (
          <OverlayTrigger placement="bottom" overlay={tooltip}>
            {renderFontAwesomeCartIcon()}
          </OverlayTrigger>
        )}
        <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-danger">
          {giftCartData.length > 0 ? giftCartData.length : cartData.length}
        </span>
      </div>
    );
  };

  return (
    <>
    <Navbar bg="dark" >
      <div className="container-xxl d-block">
      <Row className="justify-content-around align-items-center">
          <Col lg={"auto"} md={12}>
            <Row>
              <Col lg={"auto"} md={12} className="m-p-0">
                <Navbar.Collapse className="justify-content-center">
                  <Navbar.Text className="d-flex">
                    {marketCities?.map((v) =>
                     v.label !== "BOSCOBEL" &&
                      <Weather
                        key={v.label}
                        cityname={v.value}
                        citylabel={v.label}
                      />
                    )}
                  </Navbar.Text>
                </Navbar.Collapse>
              </Col>
              <Col lg={"auto"} md={12}>
                <Navbar.Collapse className="justify-content-center">
                  <Navbar.Text className="d-flex">
                    <SocialMediaIcon
                      size={"2xl"}
                      iconType={faFacebookSquare}
                      url={selectedMarket?.header?.facebook}
                      title={t("facebook")}
                    />
                    <SocialMediaIcon
                      size={"2xl"}
                      iconType={faSquareXTwitter}
                      url={selectedMarket?.header?.twitter}
                      title={t("twitter")}
                    />
                  </Navbar.Text>
                </Navbar.Collapse>
              </Col>

              <Col lg={"auto"} md={12} className="m-p-0">
                <Navbar.Collapse className="justify-content-center">
                  <Navbar.Text
                    className="d-flex pb-0"
                    style={{ color: "white" }}
                  >
                    <SocialMediaIcon size={"2xl"} iconType={faSquarePhone} />

                    <p className="mt-1">
                      &nbsp; {t("header:contactUs")}: {t("header:usa")}{" "}
                      {selectedMarket?.contact?.phone} | {t("header:ca")}{" "}
                      {selectedMarket?.contact?.skype}
                    </p>
                  </Navbar.Text>
                </Navbar.Collapse>
              </Col>
            </Row>
          </Col>
          <Col lg={"auto"} md={12}>
            <Row className="align-items-center">
              <Col lg={"auto"} md={12}>
                <Navbar.Collapse
                  id="basic-navbar-nav"
                  className={
                    // availableMarkets?.data?.markets?.length > 1
                    //   ? "justify-content-center my-2"
                      "justify-content-center my-2"
                  }
                >
                  {(
                    giftCartData.length > 0
                      ? giftCartData.length
                      : cartData.length
                  ) ? (
                    <Nav.Link href={cartIconClickHandler()}>
                      {renderCartIcon()}
                    </Nav.Link>
                  ) : (
                    location.pathname.startsWith("/login") ||
                    location.pathname.startsWith("/subscriber-login") ||
                    renderCartIcon()
                  )}
                  {/* handle all the market show dropdown/label in this component */}
                  <MarketSelect zIndex={true} location={location} sendBroadcastMessage={sendBroadcastMessage}/> 
                </Navbar.Collapse>
              </Col>

              {(LOGIN_BUTTON_SHOW_TO_USERS.find(
                (option) => option === userDefault?.username) || userDefault?.username === undefined) && (
                <Col lg={"auto"} md={12} className="m-p-0">
                  <Navbar.Collapse
                    className="justify-content-center"
                    id="basic-navbar-nav"
                  >
                    {showSubscriberLoginButton(location) && (
                      <Link to="/subscriber-login" className="text-decoration-none">
                        <Button className="header-button" variant="success">
                          {t("header:subscriberLogin")}
                        </Button>
                      </Link>
                    )}
                    
                    {showPartnerLoginButton(location) && (
                      <Link to="/login" className="mx-1 text-decoration-none">
                        <Button variant="success" className="header-button">
                          {t("header:partnerLogin")}
                        </Button>
                      </Link>
                    )}
                  </Navbar.Collapse>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        </div>
      </Navbar>
    </>
  );
};

export default Header;
