import { Card, Col, Container, Row } from "react-bootstrap";
import { selectedMarket as currentMarket } from "../redux/marketRedux";
import HtmlToReactConverter from "../components/commonComponents/HtmlToReactConverter";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

function InvalidAffiliate(props) {
  const { t } = useTranslation(['common']);
  const selectedMarket = useSelector(currentMarket);

  return (
    <>
      <section>
        <Container className="my-4">
          <h3 className="my-3 d-flex justify-content-center text-center">
            <span className="heading">{t('invalidAffiliateHeading')}</span>
          </h3>

          <Row>
            <Col md={12} className="mx-auto xl">
              <Card className="text-center mt-2">
                <Card.Body className="text-center">
                  <Row>
                    <Col md={12} className="d-flex justify-content-center">
                      <HtmlToReactConverter value={t('invalidAffiliateText', { affiliateid: props.affiliateid, email: selectedMarket?.contact?.email })} />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
export default InvalidAffiliate;
