import { appAxios } from "./config/axios-config"
import { sendMailResponseNullConfirmFailure, getLoginDetails, log, HandleAPIError, isFromTestingOrigin } from "./components/commonComponents/commonFunctions";
import { store } from "./store/store";
import { showInfo } from "./components/commonComponents/Swal";

export const _post = async (url, data, headers = null) => {
  const endpoint = url.split('/').filter(Boolean).pop();
  const state = store.getState();
  const sessionid = getLoginDetails(state)?.sessionid;
  const isAddLog = endpoint === "addlog"
  const isSendEmail = endpoint === "sendmail"
  const endpoints = ["resetpassword", "addcommentcard", "getaffiliates", "getmarkets", "getpaymentgateway", "login", "initaffiliatelog", "linkbooking", "altlogin","addlog","sendmail"];
  if (sessionid || endpoints.includes(endpoint)) {
    let response;
    try {
      if (isFromTestingOrigin(window.location.href)) {
        console.log("request for ", url, data);
      }

      if(!isAddLog && !isSendEmail) {
        log("info", data, "request", null, url);
      }
      if (headers) {
        response = await appAxios.post(`${url}`, data, { headers })
      } else {
        response = await appAxios.post(`${url}`, data)
      }
      
      if (isFromTestingOrigin(window.location.href)) {
        console.log("response for ", url, response?.data);
      }
      
      !isSendEmail && sendMailResponseNullConfirmFailure(endpoint, data, response, url);
      if(!isAddLog && !isSendEmail) {
        log("info", response?.data, "response", null, url);
      }

      return response?.data;
      // if (response.data.status === VIPER_Response_Co des.SUCCESS) {
      //   return response
      // } else {
      //   // throw new ResponseCodeError(response.data.responseCode)
      // }
    } catch (error) {
      !isSendEmail && sendMailResponseNullConfirmFailure(endpoint, data, response, url);
      if(!isAddLog && !isSendEmail) {
        log("info", null, "error", error, url);
      }
      HandleAPIError(error)
    }
  }else{
    // showInfo(endpoint,"No Sessionid Found")
    console.log("No Sessionid Found",endpoint)
  }
}

export const _get = async (
  url,
  data,
  headers
) => {
  try {
    let response;
    if (headers) {
      response = await appAxios.get(url, {
        headers,
      });
    } else {
      response = await appAxios.get(url);
    }
    if (response.status === 200) {
      return response.data;
    } else {
      return null
    }
  } catch (error) {
    HandleAPIError(error)
  }
};

export const HandleError = (error) => {
  // if (error instanceof ResponseCodeError) throw error
  // if (error instanceof AxiosError) {
  //   switch (error?.response?.status) {
  //     case 400:
  //       return new BadRequestError(error.response.data)
  //     default:
  //       return new UnexpectedError(error.message)
  //   }
  // }
  return error;
}

