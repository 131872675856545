import { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import Select from "react-select";
import CommonButton from "../components/commonComponents/CommonButton";
import InputGroup from "react-bootstrap/InputGroup";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getBookings } from "../redux/apiCalls";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../components/commonComponents/Loader";
import { showError, showInfo } from "../components/commonComponents/Swal";
import { selectedMarket as currentMarket, selectedMarketId as SMID } from "../redux/marketRedux";
import { HandleAPIError, alphabetValidation, divRef, getProductDropdownAccordingMarket, scrollToDiv, translateArrayKeyValue } from "../components/commonComponents/commonFunctions";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessages from "../components/commonComponents/ErrorMessages";
import { CONFIRMATION_NUMBER_MAXLENGTH, PRODUCTID_ALL, PRODUCTNAME_ALL, REGEX_ALPHABET, REGEX_ALPHANUMERIC_WITHOUT_SPACE, REGEX_NUMERIC, SEARCH_PARTNER } from "../config/commonConstants";
import { setSearchBooking } from "../redux/searchBookingRedux";

function SearchBooking() {
  const selectedMarket = useSelector(currentMarket);
  const { t } = useTranslation(["common", "getupdateflightdetailslink"]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const selectedMarketId = useSelector(SMID);
  const ARR_PRODUCTS = getProductDropdownAccordingMarket(selectedMarketId)
  const [searchBy, setSearchBy] = useState("");
  const dispatch = useDispatch();
  
  const validationSchema = ()=>{
   switch (searchBy) {
    case "A":
      return Yup.object().shape({
        searchBy: Yup.string().trim().required(
          t("getupdateflightdetailslink:errors.searchBy.required")
        ),
        product: Yup.string().trim().required(
          t("getupdateflightdetailslink:errors.product.required"),
        ),
        confirmationNumber: Yup.string().trim().required(
          t("getupdateflightdetailslink:errors.confirmationNumber.required")
        ),
      });
    case "B":
      return Yup.object().shape({
        searchBy: Yup.string().trim().required(
          t("getupdateflightdetailslink:errors.searchBy.required")
        ),
        product: Yup.string().trim().required(
          t("getupdateflightdetailslink:errors.product.required")
        ),
        referenceNumber: Yup.string().trim().required(
          t("getupdateflightdetailslink:errors.referenceNumber.required")
        ),
        contactName: Yup.string().trim().required(
          t("getupdateflightdetailslink:errors.contactName.required")
        ),
      });
    case "C":
      return Yup.object().shape({
        searchBy: Yup.string().trim().required(
          t("getupdateflightdetailslink:errors.searchBy.required")
        ),
        product: Yup.string().trim().required(
          t("getupdateflightdetailslink:errors.product.required")
        ),
        contactName: Yup.string().trim().required(
          t("getupdateflightdetailslink:errors.contactName.required")
        ),
      });

    default:
      return Yup.object().shape({
        searchBy: Yup.string().trim().required(
          t("getupdateflightdetailslink:errors.searchBy.required")
        ),
        product: Yup.string().trim().required(
          t("getupdateflightdetailslink:errors.product.required")
        )
      });
   }
}

  const formik = useFormik({
    initialValues: {
      searchBy: "",
      product: "",
      confirmationNumber: "",
      referenceNumber: "",
      contactName: "",
    },
    validationSchema,
    onSubmit: () => {
      handleSubmit(formik.values);
    },
  }); 

  const handleSubmit = async (formik) => {
    const data = {
      ...(formik.product !== "ALL" && { "productid": formik.product }),
      ...(formik.searchBy === "A" && { "confirmationnumber": formik.confirmationNumber }),
      "lookupid": formik.searchBy,
      ...(formik.searchBy === "B" && { "referencenumber": formik.referenceNumber }),
      ...(formik.searchBy === "B" || formik.searchBy === "C") && { "contactname": formik.contactName }
    };

    try {
      setLoading(true);
      const response = await getBookings(data);
      const params = {
        searchBookingHeading: '',
        searchBookingHeading_2: '',
        searchBookingParam: '',
        searchBookingParam_2: ''
      }
  
      if(formik.searchBy === "A"){
        params.searchBookingHeading = "confirmationNumber"  // t('getupdateflightdetailslink:confirmationNumber');
        params.searchBookingParam = formik.confirmationNumber;
      } else if(formik.searchBy === "B"){
        params.searchBookingHeading = "referenceNumber" //t('getupdateflightdetailslink:referenceNumber');
        params.searchBookingParam = formik.referenceNumber;
        params.searchBookingHeading_2 = "contactName" // t('getupdateflightdetailslink:contactName');
        params.searchBookingParam_2 = formik.contactName;
      } else if(formik.searchBy === "B" || formik.searchBy === "C") {
        params.searchBookingHeading = "contactName" // t('getupdateflightdetailslink:contactName');
        params.searchBookingParam = formik.contactName;
      }
      
      if (response?.status === 0) {
          dispatch(
            setSearchBooking({
              orderDetails: response?.data?.orders,
              requestData: data,
              searchBookingHeading: params.searchBookingHeading,
              searchBookingParam: params.searchBookingParam,
              searchBookingHeading_2: params.searchBookingHeading_2,
              searchBookingParam_2: params.searchBookingParam_2,
              contactDetails: {
                primary: response?.data?.primaryContact,
                secondary: response?.data?.secondaryContact
              }
            })
          );
          navigate("/search-booking-results", { 
            state: { 
              response, 
              searchBookingHeading: params.searchBookingHeading, 
              searchBookingParam: params.searchBookingParam, 
              searchBookingParam_2: params.searchBookingParam_2, 
              searchBookingHeading_2: params.searchBookingHeading_2, 
              data 
            } 
          });
      } else {
        showError(t("error"), response?.statusMessage);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  }
  
  const addAllProductOption = () => {
    const updatedSearchPartner = [...ARR_PRODUCTS];
    if (searchBy === "B" || searchBy === "C") {
      updatedSearchPartner.unshift( { value: PRODUCTID_ALL, label:PRODUCTNAME_ALL});
    }
    return updatedSearchPartner;
  }

  

  return (
    <>
      <section>
        <Container className="my-4" ref={divRef}>
          <Loader loading={loading} message={t('getupdateflightdetailslink:loaderMessage')} />
          <h3 className="my-3 d-flex justify-content-center searchBookingHeading">
            <span className="searchBookingHeading">{t('getupdateflightdetailslink:searchBooking')}</span>
          </h3>
          <Row className="my-3 d-flex justify-content-around align-content-left">
            <Col md={6}></Col>
            <Col md={6} className="d-flex justify-content-end">
              <ExitButton />
            </Col>
          </Row>
          <Card className="my-3"  >
            <Card.Body>
              <h6 className="field mb-3">
                {t('fillAllFields')}
              </h6>
              <Form onSubmit={(e) => scrollToDiv(e, formik)}>
                <ErrorMessages formik={formik} />
                <h5 className="d-flex justify-content-center">{t('getupdateflightdetailslink:updateBookingHeading')}</h5>
                <Row className="d-flex justify-content-center">
                  <Col lg={3} md={4} sm={12}>
                    <Form.Group className="my-2">
                      <Form.Label className="d-flex">
                      {t('getupdateflightdetailslink:searchBy')}
                        <span className="field">*</span>
                      </Form.Label>
                      <Select
                        name="searchBy"
                        options={translateArrayKeyValue(SEARCH_PARTNER, "SEARCHPARTNER")}
                        value={formik.values.searchBy.Label}
                        onChange={(d) => {
                          setSearchBy(d.value)
                          formik.setFieldValue("searchBy", d.value);
                        }}
                        onBlur={formik.handleBlur}
                        className={
                          formik.touched.searchBy && formik.errors.searchBy
                            ? "error rounded-2 my-0 mb-3"
                            : "my-0 mb-3"
                        }
                      />
                      {formik.touched.searchBy}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                  <Col lg={3} md={4} sm={12}>
                    <Form.Group className="my-2">
                      <Form.Label className="d-flex">
                        {t('getupdateflightdetailslink:product')}
                        <span className="field">*</span>
                      </Form.Label>
                      <Select
                        name="product"
                        options={addAllProductOption(ARR_PRODUCTS)}
                        value={formik.values.product.Label}
                        onChange={(d) => {
                          formik.setFieldValue("product", d.value);
                        }}
                        onBlur={formik.handleBlur}
                        className={
                          formik.touched.product && formik.errors.product
                            ? "error rounded-2 my-0 mb-3"
                            : "my-0 mb-3"
                        }
                      />
                      {formik.touched.product}
                    </Form.Group>
                  </Col>
                </Row>
                {formik.values.searchBy === "A" &&
                  <Row className="d-flex justify-content-center">
                    <Col
                      lg={3} md={4} sm={12}
                      className="mb-3"
                    >
                      <Form.Label className="d-flex" htmlFor="ConfirmationNumber">
                        {t('getupdateflightdetailslink:confirmationNumber')}<span className="field">*</span>
                      </Form.Label>
                      <InputGroup className="d-flex">
                        <Form.Control
                          type="text"
                          name="confirmationNumber"
                          id="ConfirmationNumber"
                          placeholder={t('getupdateflightdetailslink:confirmationNumber')}
                          aria-describedby="inputGroupPrepend"
                          maxLength={CONFIRMATION_NUMBER_MAXLENGTH}
                          value={formik.values.confirmationNumber}
                          onChange={(e) => {
                            e.target.value = e.target.value?.replace(REGEX_NUMERIC, "");
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.confirmationNumber &&
                              formik.errors.confirmationNumber
                              ? "error my-0 mb-3"
                              : "my-0 mb-3"
                          }
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                }
                {formik.values.searchBy === "B" &&
                  <Row className="d-flex justify-content-center">
                    <Col
                      lg={3} md={4} sm={12}
                      className="mb-3"
                    >
                      <Form.Label className="d-flex" htmlFor="referenceNumber">
                      {t('getupdateflightdetailslink:referenceNumber')}<span className="field">*</span>
                      </Form.Label>
                      <InputGroup className="d-flex">
                        <Form.Control
                          type="text"
                          name="referenceNumber"
                          id="referenceNumber"
                          placeholder={t('getupdateflightdetailslink:referenceNumber')}
                          aria-describedby="inputGroupPrepend"
                          maxLength={CONFIRMATION_NUMBER_MAXLENGTH}
                          value={formik.values.referenceNumber}
                          onChange={(e) => {
                            
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.referenceNumber &&
                              formik.errors.referenceNumber
                              ? "error my-0 mb-3"
                              : "my-0 mb-3"
                          }
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                }
                {(formik.values.searchBy === "B" || formik.values.searchBy === "C") &&
                  <Row className="d-flex justify-content-center">
                    <Col
                      lg={3} md={4} sm={12}
                      className="mb-3"
                    >
                      <Form.Label className="d-flex" htmlFor="contactName">
                      {t('getupdateflightdetailslink:contactName')}<span className="field">*</span>
                      </Form.Label>
                      <InputGroup className="d-flex">
                        <Form.Control
                          type="text"
                          name="contactName"
                          id="contactName"
                          placeholder={t('getupdateflightdetailslink:contactName')}
                          aria-describedby="inputGroupPrepend"
                          maxLength={20}
                          value={formik.values.contactName}
                          // onChange={(e) => {
                          //   e.target.value = e.target.value?.replace(REGEX_ALPHABET, "");
                          //   formik.handleChange(e);
                          // }}
                          onChange={(e) => {
                            e.target.value = alphabetValidation(e.target.value)
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.contactName &&
                              formik.errors.contactName
                              ? "error my-0 mb-3"
                              : "my-0 mb-3"
                          }
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                }
                <Row>
                  <Col md={12} className="d-flex justify-content-center">
                    <CommonButton
                      type="submit"
                      classname="gradient-btn "
                      size="md"
                      title={t('submit')}
                    />
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
}

export default SearchBooking;