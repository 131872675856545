import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { selectedMarket as currentMarket } from "../../redux/marketRedux";
import { useSelector } from "react-redux";
import { dateFormatForDisplay } from "./commonFunctions";
import fr from "date-fns/locale/fr";
import es from "date-fns/locale/es";

function DatepickerComponent(props) {
  const years = props?.range;
  const selectedMarket = useSelector(currentMarket);
  const Locale = selectedMarket?.language;
  registerLocale(`fr`, fr);
  registerLocale(`es`, es);

  const DateChange = (value) => {
    const dt = value ? dateFormatForDisplay(value, "MM/dd/yyyy") : "";
    props.onchange(dt);
  };

  return (
    <DatePicker
      locale={Locale}
      showIcon
      dateFormatCalendar="MM/dd/yyyy"
      showYearDropdown
      showMonthDropdown
      scrollableYearDropdown
      popperPlacement="bottom-end"
      yearDropdownItemNumber={years.length}
      onChange={(d) => {
        props.onChangeProps
          ? props.onchange(
              props.variableName,
              d ? dateFormatForDisplay(d, "MM/dd/yyyy") : ""
            )
          : DateChange(d);
      }}
      selected={props.value}
      className={props.className}
      format="MM/dd/yyyy"
      {...(!props.showClearIcon && { clearIcon: null })}
      minDate={props.minDate}
      maxDate={props.maxDate}
      onKeyDown={(e) => {
        e.preventDefault();
      }}
      placeholderText="--/--/----"
    />
  );
}

export default DatepickerComponent;
