import React from 'react'
import { Modal } from 'react-bootstrap';

const IframePdfModal = (props) => {
  const cancelHandler = () => {
    props.cancelModal();
  };

  return (
    <div>
      <Modal
        show={props.visible}
        onHide={cancelHandler}
        size="xl"
        style={{ zIndex: "9998" }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <h3 className="fw-bold modalHeading">{props.title}</h3>
        </Modal.Header>
        <Modal.Body className="p-0">
          <iframe src={props.filename} className="iframe" />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default IframePdfModal