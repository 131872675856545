import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authed: false,
  logindetails: {},
  distributorProfile: {},
  giftPackages: {},
  userDefault: {},
  subDistributorProfile: {},
  error: null,
}

const standBySlice = createSlice({
  name: "standBy",
  initialState: initialState,
  reducers: {
    standByServiceSuccess: (state, action) => {
      state.authed = true;
      state.error = null;
      state.logindetails = action.payload;
      state.distributorProfile = action.payload.distributorprofile;
      state.giftPackages = action.payload.giftpackages;
      state.userDefault = action.payload.userdefault;
    },
    standByServicePending: (state, action) => {
      state.error = null;
    },
    standByServiceError: (state, action) => {
      state.error = action.payload;
      state.authed = false;
    },
    setIsAuthenicated: (state, action) => {
      state.authed = action.payload;
    },
    standByLogout: state => {
      return initialState;
    },
  },
});

export const {
    standByServiceSuccess,
    standByServicePending,
    standByServiceError,
    setIsAuthenicated,
    standByLogout,
} = standBySlice.actions;

export const distributorProfile = (state) => state.standByRedux?.distributorProfile;
export const userDefault = (state) => state.standByRedux?.userDefault;
export const authed = (state) => state.standByRedux?.authed;
export const error = (state) => state.standByRedux?.error;
export const giftPackages = (state) => state.standByRedux?.giftPackages;
export const logindetails = (state) => state.standByRedux?.logindetails;

export default standBySlice.reducer;
