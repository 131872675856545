import { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import Select from "react-select";
import {
  PEOPLE_COUNT,
  // ADULT_AGE_TEXT,
  // CHILD_AGE_TEXT,
  // INFANT_AGE_TEXT,
  LOUNGE_TYPE,
  PRODUCTID_ARRIVALBUNDLE,
  PRODUCTID_ARRIVAL,
  PRODUCTID_DEPARTURE,
  SALUTATION,
  RESERVATION_SOURCE,
  PRIORITYPASS_LOGIN,
  PRODUCTID_PRIORITYPASS,
  PRODUCTID_DIGICELBLACKCARD,
  EMAIL_POLICY,
} from "../config/commonConstants";
import OverlayTooltip from "../components/commonComponents/OverlayTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChild,
  faCircleInfo,
  faPeopleRoof,
  faPersonBreastfeeding,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import CommonButton from "../components/commonComponents/CommonButton";
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import SoldOutDataModal from "../components/commonComponents/SoldOutDataModal";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectedMarket as currentMarket } from "../redux/marketRedux";
import {
  checkLoggedInUserIsLoungePartner,
  clearSession,
  dateFormatForDisplay,
  getAirlineNames,
  getCurrentMonth,
  getFlightNumbers,
  getLoggedInUsername,
  getMarketLoungenames,
  getMarketLoungenamesWithAirport,
  myDateFormat,
  translateArrayKeyValue,
  divRef,
  scrollToDiv,
  secondaryContactDetailsSchemaToggle,
  loginFunction,
  getLoginDetails,
  closeSession,
  getProductId,
  validExpiryOrNot,
  isObjectEmpty,
  getDistributorSubDistributorProfile,
  loungePartnerProductOperation,
  compareDates,
  setDemographicInformationToRedux,
} from "../components/commonComponents/commonFunctions";
import {
  addStandBy,
  reserveCartItem,
  getspecialoccassions,
  mergeStandBySession,
  cancelCartItem,
} from "../redux/apiCalls";
import Loader from "../components/commonComponents/Loader";
import { showConfirm, showError } from "../components/commonComponents/Swal";
import {
  cartBookingDetailUpdate,
  cartData,
  cartFetchSuccess,
  deleteProduct,
} from "../redux/cartRedux";
import { arrivaldata, departuredata } from "../redux/flightScheduleRedux";
import { specialOccasions as SO } from "../redux/specialOccasionsRedux";
import AdultDetails from "../components/commonComponents/AdultDetails";
import ChildDetails from "../components/commonComponents/ChildDetails";
import ContactDetails from "../components/commonComponents/ContactDetails";
import useToggle from "../hooks/useToggle";
import {
  logindetails as LD,
} from "../redux/signInRedux";
import {
  HandleAPIError,
  getAdultDisplayAge,
  getChildDisplayAge,
  getInfantDisplayAge,
} from "../components/commonComponents/commonFunctions";
import PriorityPassCard from "../components/commonComponents/PriorityPassCard";
import FlightDetailsComponent from "../components/commonComponents/FlightDetailsComponent";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessages from "../components/commonComponents/ErrorMessages";
import { setDemographicInformation } from "../redux/demographicInformationRedux";
import {
  demographicInformation as DI,
  clearDemographicInformation,
} from "../redux/demographicInformationRedux";
import { VIPER_Response_Codes } from "../config/viper-response-codes";

function StandByBookingForm(props) {
  const location = useLocation();
  const sendBroadcastMessage = useOutletContext();
  var urlElement = window?.location?.pathname.replace("/", "");
  const urlProductid = urlElement === "standby-bundle" ? PRODUCTID_ARRIVALBUNDLE : urlElement === "standby-arrival" ? PRODUCTID_ARRIVAL : PRODUCTID_DEPARTURE;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const distributorProfile = getDistributorSubDistributorProfile();
  let productid = location?.state?.productid ? location?.state?.productid : urlProductid;
  const currentCartItemId = location?.state?.cartItemId !== undefined ? location?.state?.cartItemId : undefined;
  const ascheduleID = location?.state?.arrivalscheduleid;
  const dscheduleID = location?.state?.departurescheduleid;
  const distributorid = distributorProfile?.distributorid;
  const selectedMarket = useSelector(currentMarket);
  const marketLoungenameswithairport = getMarketLoungenamesWithAirport(
    selectedMarket?.airports
  );
  const [isTitleChangeable, setIsTitleChangeable] = useState(true);
  const [cartItemData, setCartItemData] = useState({});

  const initialBookingDetails = {
    lounge: "",
    flightDate: null,
    airlineName: "",
    airlineId: "",
    flightNumber: "",
    flightTime_hour: "",
    errors: "",
  };
  const [salutation, setSalutation] = useState(
    translateArrayKeyValue(SALUTATION, "SALUTATION")
  );

  const initialAdult = {
    title: salutation[0],
    firstName: "",
    lastName: "",
    email: "",
    dob: "",
    errors: {},
  };

  const initialChild = {
    title: salutation.filter((x) => x.value === "MASTER")[0],
    firstName: "",
    lastName: "",
    dob: null,
    errors: {},
  };

  const initialPrimaryContact = {
    heading: "",
    title: salutation[0],
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    phone: "",
    errors: {},
  };

  const initialSecondaryContact = {
    heading: "",
    title: salutation[0],
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    phone: "",
    errors: {},
  };

  const [priorityPassDetails, setdPriorityPassDetails] = useState({
    cardType: "Priority Pass",
    name: "",
    cardNumber: "",
    cardMonth: getCurrentMonth(),
    cardYear: new Date()?.getFullYear()?.toString(),
    errors: {},
  });

  // paramters for update booking detail for edit
  const adultCountForEdit = location?.state?.adultCount
    ? location?.state?.adultCount
    : { value: "1", label: "1" };
  const childCountForEdit = location?.state?.childCount
    ? location?.state?.childCount
    : { value: "0", label: "0" };
  const infantCountForEdit = location?.state?.infantCount
    ? location?.state?.infantCount
    : { value: "0", label: "0" };

  const currentBookingDetail = location?.state?.currentBookingDetail;
  const flightDetailsForEdit = location?.state?.flightDetails;
  const airlineIdForEdit = location?.state?.airlineId;
  const infantallowed = distributorProfile?.infantallowed;
  const demographicInformation = useSelector(DI);
  const currentPassengerInfo =
    useSelector(cartData)?.find((c) => c.key === currentCartItemId)?.value?.data
      ?.passengerInfo ?? "";
  const { t } = useTranslation([
    "common",
    "registration",
    "greetSign",
    "common",
    "journeydetails",
    "standbybookingform",
  ]);
  const [loading, setLoading] = useState(false);
  const [airlineId, setAirlineId] = useState([]);
  const [adultCount, setAdultCount] = useState({
    value: { value: "1", label: "1" },
    error: "",
  });
  const [childCount, setChildCount] = useState({
    value: { value: "0", label: "0" },
    error: "",
  });
  const [infantCount, setInfantCount] = useState({
    value: { value: "0", label: "0" },
    error: "",
  });
  const [visibleSoldOut, setvisibleSoldOut] = useState(false);
  const [clubType, setClubType] = useState([]);
  const [date, setDate] = useState([]);
  const [bookingDetail, setBookingDetail] = useState([]);
  const bookingType = [
    t("standbybookingform:arrivalDetails"),
    t("standbybookingform:departureDetails"),
  ];
  const hideSoldOutModal = () => setvisibleSoldOut(false);
  const showSoldOutModal = () => setvisibleSoldOut(true);
  const [arrivalscheduleid, setArrivalscheduleid] = useState(0);
  const [departurescheduleid, setDeparturescheduleid] = useState(0);
  const [currentCartItem, setCurrentCartItem] = useState([]);
  const [cartItemId, setCartItemId] = useState();
  const [flightDetails, setFlightDetails] = useState([]);
  const [tmparrivalscheduleid, setTmpArrivalscheduleid] = useState(null);
  const [tmpdeparturescheduleid, setTmpDeparturescheduleid] = useState(null);

  const [selectedclubtype, setSelectedclubtype] = useState();
  //  marketLoungenameswithairport[0].value

  const marketLoungenames = getMarketLoungenames(selectedMarket?.airports);
  const [adults, setAdults] = useState([]);
  const [childs, setChilds] = useState([]);
  const [infant, setInfant] = useState([]);
  const [primaryContactDetails, setPrimaryContactDetails] = useState(
    initialPrimaryContact
  );
  const [secondaryContactDetails, setSecondaryContactDetails] = useState(
    Yup.object().nullable()
  );
  const heading = [
    t("standbybookingform:primaryContact"),
    t("standbybookingform:secondaryContact"),
  ];
  const kidsType = [t("child"), t("infant")];
  const adata = useSelector(arrivaldata);
  const ddata = useSelector(departuredata);
  const data = [adata, ddata];
  const [formValidity, setformValidity] = useState("");
  const specialOccasions = useSelector(SO);
  const loginDetail = useSelector(LD);

  const getBookingDate = () => {
    let dates = [];
    formik.values.bookingDetail.forEach((item) => {
      dates.push(item.flightDate);
    });
    //const dates = ["2021-02-05", "2021-05-20", "2021-01-02"]
    const min = dates.reduce((acc, date) => {
      return acc && new Date(acc) < new Date(date) ? acc : date;
    }, "");
    //  const max = dates.reduce((acc, date) => { return acc && new Date(acc) > new Date(date) ? acc : date }, '')
    return min;
  };

  const validationSchema = Yup.object().shape({
    bookingDetail: Yup.array().of(
      Yup.object().shape({
        lounge: Yup.object()
          // .required(t("journeydetails:errors.lounge.required"))
          .test(
            "arrival-lounge",
            productid === PRODUCTID_ARRIVALBUNDLE ||
              productid === PRODUCTID_ARRIVAL
              ? t("journeydetails:errors.arrivalLounge.required")
              : t("journeydetails:errors.departureLounge.required"),
            function (value) {
              return this.path !== "bookingDetail[0].lounge" || value;
            }
          )
          .test(
            "deaprture-lounge",
            t("journeydetails:errors.departureLounge.required"),
            function (value) {
              return this.path !== "bookingDetail[1].lounge" || value;
            }
          ),
        flightDate: Yup.date()
          .nullable()
          .test(
            "arrival-flightDate",
            productid === PRODUCTID_ARRIVALBUNDLE ||
              productid === PRODUCTID_ARRIVAL
              ? t("journeydetails:errors.arrivalFlightDate.required")
              : t("journeydetails:errors.departureFlightDate.required"),
            function (value) {
              return this.path !== "bookingDetail[0].flightDate" || value;
            }
          )
          .test(
            "deaprture-flightDate",
            t("journeydetails:errors.departureFlightDate.required"),
            function (value) {
              return this.path !== "bookingDetail[1].flightDate" || value;
            }
          ),
        airlineName: Yup.object()
          .test(
            "arrival-airlineName",
            productid === PRODUCTID_ARRIVALBUNDLE ||
              productid === PRODUCTID_ARRIVAL
              ? t("journeydetails:errors.arrivalAirlineName.required")
              : t("journeydetails:errors.deaptureAirlineName.required"),
            function (value) {
              return this.path !== "bookingDetail[0].airlineName" || value;
            }
          )
          .test(
            "deaprture-airlineName",
            t("journeydetails:errors.deaptureAirlineName.required"),
            function (value) {
              return this.path !== "bookingDetail[1].airlineName" || value;
            }
          ),
        flightNumber: Yup.object()
          .test(
            "arrival-flightNumber",
            productid === PRODUCTID_ARRIVALBUNDLE ||
              productid === PRODUCTID_ARRIVAL
              ? t("journeydetails:errors.arrivalFlightNumber.required")
              : t("journeydetails:errors.departureFlightNumber.required"),
            function (value) {
              return this.path !== "bookingDetail[0].flightNumber" || value;
            }
          )
          .test(
            "deaprture-flightNumber",
            t("journeydetails:errors.departureFlightNumber.required"),
            function (value) {
              return this.path !== "bookingDetail[1].flightNumber" || value;
            }
          ),
      })
    ),
    adults: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().trim().required(
          t("registration:errors.adultFirstName.required")
        ),
        lastName: Yup.string().trim().required(
          t("registration:errors.adultLastName.required")
        ),
        // email: Yup.string()
        //   .required(t("registration:errors.adultEmail.required"))
        //   .matches(EMAIL_POLICY, t("registration:errors.adultEmail.match")),
        // dob: Yup.string().required(t("feedback:errors.firstName.required")),
      })
    ),
    childs: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().trim().required(
          t("registration:errors.childFirstName.required")
        ),
        lastName: Yup.string().trim().required(
          t("registration:errors.childLastName.required")
        ),
        dob: Yup.string().trim().required(t("registration:errors.childDob.required")),
      })
    ),
    ...(productid !== PRODUCTID_DEPARTURE && {
      infants: Yup.array().of(
        Yup.object().shape({
          firstName: Yup.string().trim().required(
            t("registration:errors.infantFirstName.required")
          ),
          lastName: Yup.string().trim().required(
            t("registration:errors.infantLastName.required")
          ),
          dob: Yup.string().trim().required(
            t("registration:errors.infantDob.required")
          ),
        })
      ),
    }),
    primaryContactDetails: Yup.object().shape({
      firstName: Yup.string().trim().required(
        t("registration:errors.primaryContactFirstName.required")
      ),
      lastName: Yup.string().trim().required(
        t("registration:errors.primaryContactLastName.required")
      ),
      email: Yup.string()
        .trim().required(t("registration:errors.primaryContactEmail.required"))
        .matches(
          EMAIL_POLICY,
          t("registration:errors.primaryContactEmail.match")
        ),
      confirmEmail: Yup.string()
        .trim().required(t("registration:errors.primaryContactConfirmEmail.required"))
        .matches(
          EMAIL_POLICY,
          t("registration:errors.primaryContactConfirmEmail.match")
        )
        .test(
          "email-validate",
          t("registration:errors.primaryContactConfirmEmailEqual.equal"),
          (confirmEmail) => {
            let flag =
              confirmEmail === formik.values.primaryContactDetails.email
                ? true
                : false;
            if (!flag) {
              return false;
            }
            return true;
          }
        ),
      phone: Yup.string().trim().required(
        t("registration:errors.primaryContactPhoneNumber.required")
      ),
    }),
    secondaryContactDetails: secondaryContactDetails,
    //The age of the Adult must be 13 years old at the first travel date. Please re-enter under the correct classification. Proof of age will be required at the lounge.
    priorityPassDetails: checkLoggedInUserIsLoungePartner()
      ? Yup.object().shape({
          name: Yup.string().trim().required(
            t("registration:errors.priorityPassName.required")
          ),
          cardNumber: Yup.string().trim().required(
            t("registration:errors.priorityPassCardNumber.required")
          ),
          cardMonth: Yup.string().test(
            "test-credit-card-expiration-date",
            t("registration:errors.priorityPassExpMonth.required"),
            (cardmonth) => {
              let bookingDate = getBookingDate();
              return validExpiryOrNot(
                formik.values.priorityPassDetails.cardYear,
                cardmonth,
                bookingDate
              );
            }
          ),
          cardYear: Yup.string().test(
            "test-credit-card-expiration-date",
            t(""),
            (cardyear) => {
              let bookingDate = getBookingDate();
              return validExpiryOrNot(
                cardyear,
                formik.values.priorityPassDetails.cardMonth,
                bookingDate
              );
            }
          ),
        })
      : "",
  });

  const initial = {
    // ... other fields
    bookingDetail: [],
    adultCount:
      currentCartItemId !== undefined
        ? adultCountForEdit
        : { value: "1", label: "1" },
    childCount:
      currentCartItemId !== undefined
        ? childCountForEdit
        : { value: "0", label: "0" },
    infantCount:
      currentCartItemId !== undefined
        ? infantCountForEdit
        : { value: "0", label: "0" },
    adults: [],
    childs: [],
    infants: [],
    greetingDetails: [],
    primaryContactDetails: {
      primaryContactDetailsheading: "",
      title: salutation[0],
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
      phone: "",
    },
    secondaryContactDetails: {
      heading: "",
      title: salutation[0],
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
      phone: "",
    },
    priorityPassDetails: {
      cardType: "Priority Pass",
      name: "",
      cardNumber: "",
      cardMonth: getCurrentMonth(),
      cardYear: new Date()?.getFullYear()?.toString(),
    },
  };

  const formik = useFormik({
    initialValues: initial,
    validationSchema,
    onSubmit: () => {
      handleSubmit(formik.values);
    },
  });

  useEffect(() => {
    closeSession(sendBroadcastMessage, true);
    loginFunction(
      navigate,
      location,
      sendBroadcastMessage,
      dispatch,
      true,
      true
    );
    if(!loginDetail?.sessionid){
      loginFunction(
        navigate,
        location,
        sendBroadcastMessage,
        dispatch,
        true
      )
    }

  }, []);

  useEffect(() => {
    secondaryContactDetailsSchemaToggle(
      setSecondaryContactDetails,
      formik?.values?.secondaryContactDetails
    );
  }, [formik.values.secondaryContactDetails]);

  useEffect(() => {
    formik.setFieldValue("adults", formik.values.adults);
  }, [formik.values.adults]);

  const resetForm = () => {
    if (productid === PRODUCTID_ARRIVALBUNDLE) {
      setBookingDetail([
        { ...initialBookingDetails },
        { ...initialBookingDetails },
      ]);
    } else {
      setBookingDetail([{ ...initialBookingDetails }]);
    }
  };

  const [isVisibleStandByModal, setIsVisibleStandByModal] = useToggle();

  const [maxAdultCount, setMaxAdultCount] = useState([]);
  const [maxChildCount, setMaxChildCount] = useState([]);
  const [maxInfantCount, setMaxInfantCount] = useState([]);
  const [maxAllowed, setMaxAllowed] = useState();

  const getMaxSeats = () => {
    let adultMaxCount = 0;
    let childMaxCount = 0;
    let infantMaxCount = 0;
    let maxCount = 0;

    if (productid === PRODUCTID_DEPARTURE || loungePartnerProductOperation("checkExistenceWithType", productid, "Departure")) {
      adultMaxCount = distributorProfile.departuremaxseats;
      childMaxCount = distributorProfile.departuremaxseats;
      infantMaxCount = distributorProfile.departuremaxseats;
      maxCount = distributorProfile.departuremaxseats;

      if (loungePartnerProductOperation("checkExistenceWithType", productid, "Departure")) {
        childMaxCount = 0;
      }
    } else {
      adultMaxCount = distributorProfile.arrivalmaxseats;
      childMaxCount = distributorProfile.arrivalmaxseats;
      infantMaxCount = distributorProfile.arrivalmaxseats;
      maxCount = distributorProfile.arrivalmaxseats;
    }

    let objMaxSeats = {
      adult: adultMaxCount,
      child: childMaxCount,
      infant: infantMaxCount,
      max: maxCount,
    };

    return objMaxSeats;
  };
  const setMaxSeats = () => {
    const objMaxSeats = getMaxSeats();

    const updatedAdultCount = Array.from(
      { length: objMaxSeats.adult + 1 },
      (_, i) => ({
        value: i.toString(),
        label: i.toString(),
      })
    );

    const updatedChildCount = Array.from(
      { length: objMaxSeats.child + 1 },
      (_, i) => ({
        value: i.toString(),
        label: i.toString(),
      })
    );

    const updatedInfantCount = Array.from(
      { length: objMaxSeats.infant + 1 },
      (_, i) => ({
        value: i.toString(),
        label: i.toString(),
      })
    );

    setMaxAdultCount(updatedAdultCount);
    setMaxChildCount(updatedChildCount);
    setMaxInfantCount(updatedInfantCount);
    setMaxAllowed(objMaxSeats.max);
  };

  const maxChecker = async (value, type) => {
    let returnValue = true;
    let tmpAdultCount = 0;
    let tmpChildCount = 0;

    if (type === "child") {
      tmpAdultCount = parseInt(formik.values.adultCount.value);
      tmpChildCount = parseInt(value);
    } else if (type === "adult") {
      tmpAdultCount = parseInt(value);
      tmpChildCount = parseInt(formik.values.childCount.value);
    }

    let tmpPaxCount = tmpAdultCount + tmpChildCount;

    if (tmpPaxCount > maxAllowed) {
      returnValue = false;
      await showError(
        t("error"),
        t("common:maxSeats", { maxAllowed: maxAllowed })
      );

      if (type === "child") {
        formik.setFieldValue("childCount", { value: "0", label: "0" });
      } else if (type === "adult") {
        formik.setFieldValue("adultCount", { value: "0", label: "0" });
      }
    } else {
      formik.setFieldValue("adultCount", {
        value: tmpAdultCount.toString(),
        label: tmpAdultCount.toString(),
      });
      formik.setFieldValue("childCount", {
        value: tmpChildCount.toString(),
        label: tmpChildCount.toString(),
      });
    }

    return returnValue;
  };

  useEffect(() => {
    setMaxSeats();
  }, []);

  const handleArrivalDepartureScheduleIdChange = async (
    scheduleid,
    i,
    productid
  ) => {
    if (productid === PRODUCTID_ARRIVALBUNDLE) {
      if (i === 0) {
        setArrivalscheduleid(scheduleid);
        setTmpArrivalscheduleid(scheduleid); // tmp for schedule id
        setDeparturescheduleid(tmpdeparturescheduleid);
      } else {
        setDeparturescheduleid(scheduleid);
        setArrivalscheduleid(tmparrivalscheduleid);
        setTmpDeparturescheduleid(scheduleid); // tmp for schedule id
      }
    } else if (productid === PRODUCTID_ARRIVAL && i === 0) {
      setArrivalscheduleid(scheduleid);
    } else if (productid === PRODUCTID_DEPARTURE && i === 0) {
      setDeparturescheduleid(scheduleid);
    }
  };

  const setIsVisibleStandByModalHandle = (cartItemId, cartItemData) => {
    setCartItemId(cartItemId);
    bookingAlertPopup(cartItemId ,cartItemData)
  };


  const standByOkHandler = (cartItemId, cartItemData) => {
    const state = {
      cartItemId: cartItemId,
      standBy: true,
      productid: productid,
      arrivalscheduleid: arrivalscheduleid,
      departurescheduleid: departurescheduleid,
      adultCount: formik.values.adultCount.value,
      childCount: formik.values.childCount.value,
      infantCount: formik.values.infantCount.value,
      currentstandByBookingDetail: [...formik.values.bookingDetail],
      flightDetails: { ...flightDetails },
      airlineId: airlineId,
    };
    const ticketConfirmed =
      parseInt(formik.values.adultCount.value) +
      parseInt(formik.values.childCount.value);
    mergeStandBySession(
      getProductId(productid),
      arrivalscheduleid,
      departurescheduleid,
      cartItemId,
      ticketConfirmed
    )
      .then((res) => {
        if (res?.status === 0) {
          let tmpBookingDetails;
          if (productid === PRODUCTID_ARRIVALBUNDLE) {
            tmpBookingDetails = formik.values.bookingDetail
          } else {
            tmpBookingDetails = formik.values.bookingDetail[0]
          }
          setDemographicInformationToRedux(formik, dispatch)
          if (currentCartItemId !== 0 && currentCartItemId !== undefined) {
            dispatch(
              cartBookingDetailUpdate({
                bookingDetail: { ...tmpBookingDetails },
                currentCartItem: cartItemData,
                cartItemId: cartItemId,
                adultCount: formik.values.adultCount?.value,
                childCount: formik.values.childCount?.value,
                infantCount: formik.values.infantCount?.value,
                data: {
                  passengerInfo: currentPassengerInfo,
                },
              })
            );
            
          } else {
            dispatch(
              cartFetchSuccess({
                key: cartItemId,
                value: {
                  bookingDetail: {  ...tmpBookingDetails },
                  currentCartItem: cartItemData,
                  adultCount: formik.values.adultCount?.value,
                  childCount: formik.values.childCount?.value,
                  infantCount: formik.values.infantCount?.value,
                  data: {
                    passengerInfo: currentPassengerInfo,
                  },
                },
              })
            );
          }
          navigate(
            productid === PRODUCTID_ARRIVALBUNDLE
              ? "/product-bundle"
              : productid === PRODUCTID_ARRIVAL
              ? "/product-arrival"
              : "/product-departure",
            { state: state }
          );
        } else {
          showError(t('error'), res?.statusMessage)
        }
        
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let redirectProps = {
    cartItemId: cartItemId,
    standBy: true,
    productid: productid,
    arrivalscheduleid: arrivalscheduleid,
    departurescheduleid: departurescheduleid,
    adultCount: formik.values.adultCount.value,
    childCount: formik.values.childCount.value,
    infantCount: formik.values.infantCount.value,
    currentstandByBookingDetail: [...formik.values.bookingDetail],
    flightDetails: { ...flightDetails },
    airlineId: airlineId,
  };

  useEffect(() => {
    // let tmpBookingDetails;
    let i = 0;
    const tmpAdults = [];
    const tmpChildren = [];
    const tmpInfants = [];

    // if (currentCartItemId !== undefined) {
    //   setFlightDetails(flightDetailsForEdit);
    //   setAirlineId({ ...airlineIdForEdit });
    //   if (productid === PRODUCTID_ARRIVALBUNDLE) {
    //     tmpBookingDetails = [
    //       currentBookingDetail[0],
    //       currentBookingDetail[1],
    //     ];
    //   } else {
    //     tmpBookingDetails = [currentBookingDetail[0]];
    //   }
    // } else {
    //   if (productid === PRODUCTID_ARRIVALBUNDLE) {
    //     tmpBookingDetails = [
    //       { ...initialBookingDetails },
    //       { ...initialBookingDetails }
    //     ];
    //   }
    //   else {
    //     tmpBookingDetails = [initialBookingDetails];
    //   }
    // }

    // for (i = 0; i < formik.values.adultCount.value; i++) {
    //   tmpAdults.push({ ...initialAdult });
    // }

    if (isObjectEmpty(demographicInformation)) {
      for (i = 0; i < formik?.values?.adultCount.value; i++) {
        tmpAdults.push({ ...initialAdult });
      }
    } else {
      for (i = 0; i < formik?.values?.adultCount.value; i++) {
        const temp = demographicInformation?.adults?.find(
          (a, index) => index === i
        );
        
        if (temp) tmpAdults.push({ ...temp });
        else tmpAdults.push({ ...initialAdult });
      }
    }

    // for (i = 0; i < formik.values.childCount.value; i++) {
    //   tmpChildren.push({ ...initialChild });
    // }

    if (isObjectEmpty(demographicInformation)) {
      for (i = 0; i < formik?.values?.childCount.value; i++) {
        tmpChildren.push({ ...initialChild });
      }
    } else {
      for (i = 0; i < formik?.values?.childCount.value; i++) {
        const temp = demographicInformation?.childs?.find(
          (a, index) => index === i
        );
        if (temp) tmpChildren.push({ ...temp });
        else tmpChildren.push({ ...initialChild });
      }
    }

    // for (i = 0; i < formik.values.infantCount.value; i++) {
    //   tmpInfants.push({ ...initialChild });
    // }

    if (isObjectEmpty(demographicInformation)) {
      if (getProductId(productid) !== PRODUCTID_DEPARTURE) {
        for (let i = 0; i < formik?.values?.infantCount.value; i++) {
          tmpInfants.push({ ...initialChild });
        }
      }
    } else {
      for (i = 0; i < formik?.values?.infantCount.value; i++) {
        const temp = demographicInformation?.infants?.find(
          (a, index) => index === i
        );
        if (temp) tmpInfants.push({ ...temp });
        else tmpInfants.push({ ...initialChild });
      }
    }
    

    formik.setFieldValue("adults", tmpAdults);
    formik.setFieldValue("childs", tmpChildren);
    formik.setFieldValue("infants", tmpInfants);
    if (
      isObjectEmpty(demographicInformation) ||
      (parseInt(formik.values.childCount.value) === 0 &&
        parseInt(formik.values.adultCount.value) === 0)
    ) {
      dispatch(clearDemographicInformation());
      formik.setFieldValue("primaryContactDetails", {
        ...initialPrimaryContact,
      });
    } else {
      if (
        formik.values.adultCount.value !== 0 &&
        !isObjectEmpty(demographicInformation.primaryContactDetails)
      ) {
        formik.setFieldValue("primaryContactDetails", {
          ...demographicInformation.primaryContactDetails,
        });
      } else if (
        formik.values.childCount.value !== 0 &&
        !isObjectEmpty(demographicInformation.primaryContactDetails)
      ) {
        formik.setFieldValue("primaryContactDetails", {
          ...demographicInformation.primaryContactDetails,
        });
      } else if (
        formik.values.adultCount.value === 0 &&
        formik.values.childCount.value !== 0 &&
        !isObjectEmpty(demographicInformation.primaryContactDetails)
      ) {
        formik.setFieldValue("primaryContactDetails", {
          ...demographicInformation.primaryContactDetails,
        });
      } else if (
        formik.values.adultCount.value !== 0 &&
        formik.values.childCount.value === 0 &&
        !isObjectEmpty(demographicInformation.primaryContactDetails)
      ) {
        formik.setFieldValue("primaryContactDetails", {
          ...demographicInformation.primaryContactDetails,
        });
      } else {
        formik.setFieldValue("primaryContactDetails", {
          ...initialPrimaryContact,
        });
      }
    }
    // formik.setFieldValue("primaryContactDetails", { ...initialPrimaryContact });
    formik.setFieldValue("secondaryContactDetails", {
      ...initialSecondaryContact,
    });
    //  formik.setFieldValue("priorityPassDetails", { ...currentPriorityPassDetails });
    getLoginDetails()?.sessionid &&
      specialOccasions && isObjectEmpty(specialOccasions) &&
      getspecialoccassions(dispatch);
    // formik.setFieldValue("bookingDetail", tmpBookingDetails);
    setClubType(marketLoungenameswithairport);
    setTimeout(() => {
      const data = translateArrayKeyValue(SALUTATION, "SALUTATION");
      if (salutation[0].label !== data[0].label) {
        setSalutation(data);
      }
    }, 1);
  }, [
    salutation,
    formik.values.adultCount,
    formik.values.childCount,
    formik.values.infantCount,
  ]);

  useEffect(() => {
    let tmpBookingDetails;
    if (currentCartItemId !== undefined) {
      setFlightDetails(flightDetailsForEdit);
      setAirlineId({ ...airlineIdForEdit });
      if (productid === PRODUCTID_ARRIVALBUNDLE) {
        tmpBookingDetails = [currentBookingDetail[0], currentBookingDetail[1]];
      } else {
        tmpBookingDetails = [currentBookingDetail[0]];
      }
    } else {
      if (productid === PRODUCTID_ARRIVALBUNDLE) {
        tmpBookingDetails = [
          { ...initialBookingDetails },
          { ...initialBookingDetails },
        ];
      } else {
        tmpBookingDetails = [initialBookingDetails];
      }
    }
    formik.setFieldValue("bookingDetail", tmpBookingDetails);
  }, [productid]);

  const getDirection = (i) => {
    const Direction =
      productid === PRODUCTID_ARRIVALBUNDLE
        ? LOUNGE_TYPE[i]?.value
        : productid === PRODUCTID_ARRIVAL
        ? LOUNGE_TYPE[0]?.value
        : LOUNGE_TYPE[1]?.value;
    return Direction;
  };

  const generateAddStandByRequest = async () => {
    const passengersArray = [];
    formik.values.adults.forEach((a) => {
      passengersArray.push({
        passengertype: "ADULT",
        title: a?.title?.value,
        firstname: a?.firstName,
        lastname: a?.lastName,
        email: a?.email,
        guestName: a?.firstName + " " + a?.lastName,
        birthdate: a?.dob ? dateFormatForDisplay(a?.dob, "yyyyMMdd") : "",
      });
    });

    formik.values.childs.forEach((a) => {
      passengersArray.push({
        passengertype: "CHILD",
        title: a?.title?.value,
        firstname: a?.firstName,
        lastname: a?.lastName,
        email: a?.email,
        guestName: a?.firstName + " " + a?.lastName,
        birthdate: dateFormatForDisplay(a?.dob, "yyyyMMdd"),
      });
    });

    productid !== PRODUCTID_DEPARTURE &&
      formik.values.infants.forEach((a) => {
        passengersArray.push({
          passengertype: "INFANT",
          title: a?.title?.value,
          firstname: a?.firstName,
          lastname: a?.lastName,
          email: a?.email,
          guestName: a?.firstName + " " + a?.lastName,
          birthdate: dateFormatForDisplay(a?.dob, "yyyyMMdd"),
        });
      });

    const data = {
      loungepartner: {
        distributorid: distributorProfile?.distributorid,
        cardholder: formik.values.priorityPassDetails?.name,
        cardnumber: formik.values.priorityPassDetails?.cardNumber,
        expirydate:
          formik.values.priorityPassDetails?.cardYear +
          formik.values.priorityPassDetails?.cardMonth +
          "01",
      },
      standby: {
        quantity: 1,
        username: loginDetail?.username,
        bookingentryby: loginDetail?.username,
        distributorid: distributorid,
        cartitems: [
          {
            productid: productid,
            reservationsource: RESERVATION_SOURCE,
            referencenumber: "",
            primarycontact: {
              title: formik.values.primaryContactDetails?.title?.value,
              name:
                formik.values.primaryContactDetails?.firstName +
                " " +
                formik.values.primaryContactDetails.lastName,
              email: formik.values.primaryContactDetails?.email,
              telephone: formik.values.primaryContactDetails?.phone,
            },
            secondarycontact: {
              title: formik.values.secondaryContactDetails?.title?.value,
              name:
                formik.values.secondaryContactDetails?.firstName +
                " " +
                formik.values.secondaryContactDetails.lastName,
              email: formik.values.secondaryContactDetails?.email,
              telephone: formik.values.secondaryContactDetails?.phone,
            },
            ...(productid === PRODUCTID_ARRIVAL ||
            productid === PRODUCTID_ARRIVALBUNDLE
              ? {
                  arrival: {
                    airportid: formik.values.bookingDetail[0]?.lounge.value,
                    flightdate:
                      dateFormatForDisplay(
                        formik.values.bookingDetail[0]?.flightDate,
                        "yyyyMMddHHmm"
                      ) +
                      myDateFormat(
                        formik.values.bookingDetail[0]?.flightTime_hour,
                        "hh:mm a",
                        "hhmm"
                      ),
                    flightid:
                      formik.values.bookingDetail[0]?.flightNumber?.value,
                    scheduleid: arrivalscheduleid,
                    adulttickets: formik.values.adultCount?.value,
                    childtickets: formik.values.childCount?.value,
                    infanttickets: formik.values.infantCount?.value,
                    guests: passengersArray,
                  },
                }
              : {
                  departure: {
                    airportid: formik.values.bookingDetail[0]?.lounge.value,
                    flightdate:
                      dateFormatForDisplay(
                        formik.values.bookingDetail[0]?.flightDate,
                        "yyyyMMdd"
                      ) +
                      myDateFormat(
                        formik.values.bookingDetail[0]?.flightTime_hour,
                        "hh:mm a",
                        "hhmm"
                      ),
                    flightid:
                      formik.values.bookingDetail[0]?.flightNumber?.value,
                    scheduleid: departurescheduleid,
                    adulttickets: formik.values.adultCount?.value,
                    childtickets: formik.values.childCount?.value,
                    infanttickets: formik.values.infantCount?.value,
                    guests: passengersArray,
                  },
                }),
            ...(formik.values.bookingDetail[1] && {
              departure: {
                airportid: formik.values.bookingDetail[1]?.lounge.value,
                flightdate:
                  dateFormatForDisplay(
                    formik.values.bookingDetail[1]?.flightDate,
                    "yyyyMMdd"
                  ) +
                  myDateFormat(
                    formik.values.bookingDetail[1]?.flightTime_hour,
                    "hh:mm a",
                    "hhmm"
                  ),
                flightid: formik.values.bookingDetail[1]?.flightNumber?.value,
                scheduleid: departurescheduleid,
                adulttickets: formik.values.adultCount?.value,
                childtickets: formik.values.childCount?.value,
                infanttickets: formik.values.infantCount?.value,
                guests: passengersArray,
              },
            }),
          },
        ],
      },
    };

    return data;
  };

  const handleSubmit = async () => {
    let error = false;
    if (productid === PRODUCTID_ARRIVALBUNDLE) {
      if (!compareDates(formik.values.bookingDetail)) {
        const confirmed = await showConfirm(t("confirm"), t("pastDateError"));
        if (confirmed.isConfirmed) {
          error = false;
        } else {
          error = true;
        }
      }
    }
    if (!error) {
      const addStandByRequest = await generateAddStandByRequest();
      let callAddStandBy = false;
      callAddStandBy = await callReserveCartItemApi();
      if (callAddStandBy) {
        try {
          setLoading(true);
          const response = await addStandBy(addStandByRequest);
          if (response.status === 0) {
            const successInfo = {
              messageHeading: t("standbybookingform:successHeading"),
              successMessage: t("standbybookingform:successMessage", {
                trackingNumber: response?.data?.cartitems[0]?.standbyid,
                email: formik.values.primaryContactDetails.email,
              }),
            };
            setLoading(false);
            //closeSession();
            dispatch(clearDemographicInformation());
            clearSession(sendBroadcastMessage, true);
            navigate("/common-success", {
              state: { successinfo: successInfo, form: "standbybookingform" },
            });
          } else {
            setLoading(false);
            showError(t("error"), response.statusMessage);
            resetForm();
          }
        } catch (e) {
          HandleAPIError(e);
        } finally {
          setLoading(false);
        }
      }
}
  };

  const getDirectionTranslationKey = () => {
    let translationKey = "";
    if (productid === PRODUCTID_ARRIVALBUNDLE) {
      translationKey = "arrivalDeparture";
    } else if (productid === PRODUCTID_ARRIVAL) {
      translationKey = "arrival";
    } else {
      // DEPARTURE, PRIORITY PASS, DIGICEL EXECUTIVE CARD
      translationKey = "departure";
    }
    return translationKey;
  };

  const setFlightDetailsForEdit = (data) => {
    setFlightDetails(data);
    setArrivalscheduleid(ascheduleID);
    setDeparturescheduleid(dscheduleID);
  };

  const deleteProductHandler = async (cartItemId) => {
    try {
      const data = { "cartitemid": cartItemId };
      setLoading(true);
      const response = await cancelCartItem(data);
      if (response?.status === 0) {
        dispatch(deleteProduct(cartItemId));
      }
    } catch (error) {
      HandleAPIError(error);
    } finally {
      setLoading(false);
    }
  };

  const callReserveCartItemApi = async () => {
    const data = {
      cartitemid: currentCartItemId ? currentCartItemId : 0,
      productid: productid,
      ticketsrequested: parseInt(formik.values.adultCount?.value) + parseInt(formik.values.childCount?.value),
      adulttickets: parseInt(formik.values.adultCount?.value),
      childtickets: parseInt(formik.values.childCount?.value),
      paymenttype: "GUESTCARD",
      distributorid: "",
    };
    try {
      setLoading(true);
      let tmpArrivalScheduleId, tmpDepartureScheduleId;
      let tmpBookingDetails;

      if (productid === PRODUCTID_ARRIVALBUNDLE) {
        tmpArrivalScheduleId = arrivalscheduleid;
        tmpDepartureScheduleId = departurescheduleid;
        tmpBookingDetails = formik.values.bookingDetail;
      } else if (productid === PRODUCTID_ARRIVAL) {
        tmpArrivalScheduleId =
        productid === PRODUCTID_ARRIVAL ? arrivalscheduleid : arrivalscheduleid !== 0 ? arrivalscheduleid : departurescheduleid;
        tmpDepartureScheduleId = 0;
        tmpBookingDetails = formik.values.bookingDetail[0];
      } else {
        productid = productid === PRODUCTID_PRIORITYPASS || productid === PRODUCTID_DIGICELBLACKCARD ? PRODUCTID_DEPARTURE : productid;
        tmpArrivalScheduleId = 0;
        tmpDepartureScheduleId = currentCartItemId !== 0? departurescheduleid !== 0 ? departurescheduleid : arrivalscheduleid : departurescheduleid;
        tmpBookingDetails = formik.values.bookingDetail[0];
      }

      const res = await reserveCartItem(dispatch, {
        ...data,
        productid: productid,
        arrivalscheduleid: tmpArrivalScheduleId,
        departurescheduleid: tmpDepartureScheduleId,
      });

      if (res.status === 0) {
        setCartItemId(res?.data?.cartitemid);
        setCartItemData(res?.data);
        setLoading(false);
        setIsVisibleStandByModalHandle(res?.data?.cartitemid, res?.data);
      } else {
        if (VIPER_Response_Codes.STANDBY_BOOKING_STATUS_CODE?.find((code) => code === res.status)) {
          setLoading(false);
          return true;
        } 
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  }

  const bookingAlertPopup = async (cartItemId, cartItemData) => {
    let confirmed;
    confirmed = await showConfirm(t('standByHeading'), t('standByBookingMessage'),t("yes"),t("no"))
    if (confirmed.isConfirmed) {
       standByOkHandler(cartItemId, cartItemData);
    } else {
      deleteProductHandler(cartItemId)
    }
  }

  return (
    <>
      <section>
        <Container className="my-4" ref={divRef}>
          <Loader loading={loading} />
          {visibleSoldOut && (
            <SoldOutDataModal
              message={""}
              cancelModal={hideSoldOutModal}
              direction={getDirection(0)}
            />
          )}
          <h3 className="my-3 d-flex justify-content-center heading">
            <span className="heading">
              {t("standByBookingHeading", {
                product: t(getDirectionTranslationKey()),
              })}
            </span>
          </h3>

          <Row className="my-3 d-flex align-content-left">
            <Col md={12} className="d-flex justify-content-end">
              {location?.state?.productid != undefined && 
              <Link
                to={
                  productid === PRODUCTID_ARRIVALBUNDLE
                    ? "/product-bundle"
                    : productid === PRODUCTID_ARRIVAL
                    ? "/product-arrival"
                    : "/product-departure"
                }
                state={redirectProps}
                className="mx-1 text-decoration-none"
              >
                <CommonButton
                  type="submit"
                  classname="back"
                  title={t("back")}
                  onclick={() => {
                    dispatch(clearDemographicInformation());
                  }}
                />
              </Link>
              }
              <ExitButton />
            </Col>
          </Row>
          <Card className="my-3">
            <Card.Body>
              <Form onSubmit={(e) => scrollToDiv(e, formik)}>
                <h6 className="field">{t("fillAllFields")}</h6>
                <ErrorMessages formik={formik} />
                <Row>
                  <h5 className="my-2 d-flex">
                    <FontAwesomeIcon
                      icon={faPeopleRoof}
                      size="sm"
                      className="me-2 icon-color"
                    />
                    {t("numberOfGuests")}
                    <span className="field">*</span>
                  </h5>
                  <Col md={"auto"} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <FontAwesomeIcon
                          icon={faUser}
                          className="icon-color"
                          size="lg"
                        />{" "}
                        {t("adult")} {getAdultDisplayAge()}
                      </Form.Label>
                      <Select
                        // options={PEOPLE_COUNT}

                        value={formik.values.adultCount}
                        name="adultCount"
                        options={maxAdultCount}
                        defaultValue={maxAdultCount[1]}
                        onChange={async (d) => {
                          await formik.setFieldValue("adultCount", d);
                          maxChecker(d.value, "adult");
                        }}
                        onBlur={(e) => formik.handleBlur(e)}
                        className={
                          formik.touched.adultCount && formik.errors.adultCount
                            ? " error rounded-2"
                            : ""
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={"auto"} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label className="d-flex">
                        <FontAwesomeIcon
                          icon={faChild}
                          size="lg"
                          className="mx-1 mt-1 icon-color"
                        />{" "}
                        <OverlayTooltip
                          title={t("child") + getChildDisplayAge()}
                          msg={t("childAgeMessage")}
                        ></OverlayTooltip>
                      </Form.Label>
                      <Select
                        // options={PEOPLE_COUNT}
                        options={maxChildCount}
                        defaultValue={maxAdultCount[1]}
                        value={formik.values.childCount}
                        name="childCount"
                        onChange={async (d) => {
                          await formik.setFieldValue("childCount", d);
                          maxChecker(d.value, "child");
                        }}
                        onBlur={(e) => formik.handleBlur(e)}
                        className={
                          formik.touched.childCount && formik.errors.adultCount
                            ? " error rounded-2"
                            : ""
                        }
                      />
                    </Form.Group>
                  </Col>
                  {infantallowed === "Y" ? (
                    <Col md={"auto "} sm={12}>
                      <Form.Group>
                        <Form.Label className="d-flex">
                          <FontAwesomeIcon
                            icon={faPersonBreastfeeding}
                            size="lg"
                            className="mx-1 mt-1 icon-color"
                          />
                          <OverlayTooltip
                            title={t("infant") + getInfantDisplayAge()}
                            msg={t("infantAgeMessage")}
                          ></OverlayTooltip>
                        </Form.Label>
                        <Select
                          options={maxInfantCount}
                          value={formik.values.infantCount}
                          name="infantCount"
                          onChange={async (d) => {
                            await formik.setFieldValue("infantCount", d);
                          }}
                          onBlur={(e) => formik.handleBlur(e)}
                        />
                      </Form.Group>
                    </Col>
                  ) : null}
                </Row>
                {/* booking detail component  */}
                {formik.values.bookingDetail?.map((a, i) => (
                  <FlightDetailsComponent
                    setFlightDetailsForEdit={setFlightDetailsForEdit}
                    key={i}
                    isLoungeDisplay={true}
                    clubType={clubType}
                    productid={productid}
                    flightNames={getAirlineNames(flightDetails[i]?.airlines)}
                    flightNumbers={getFlightNumbers(
                      flightDetails[i]?.flights?.filter(
                        (f) => f.airlineid === airlineId[i]
                      )
                    )}
                    heading={bookingType[i]}
                    product
                    index={i}
                    lounge={formik?.values?.bookingDetail?.[i]?.lounge}
                    flightDate={formik?.values?.bookingDetail?.[i]?.flightDate}
                    airlineName={
                      formik?.values?.bookingDetail?.[i]?.airlineName
                    }
                    airlineId={formik?.values?.bookingDetail?.[i]?.airlineId}
                    flightNumber={
                      formik?.values?.bookingDetail?.[i]?.flightNumber
                    }
                    flightTime_hour={
                      formik?.values?.bookingDetail?.[i]?.flightTime_hour
                    }
                    scheduleId={handleArrivalDepartureScheduleIdChange}
                    errors={a.errors}
                    formik={formik}
                    onBlur={async (key, e) => {
                      formik.handleBlur(e);
                      if (key === "flightNumber") {
                        callReserveCartItemApi();
                      }
                    }}
                    onChange={(key, val) => {
                      formik.setFieldValue(`bookingDetail.${i}.${key}`, val);
                    }}
                  />
                ))}

                <Row className="d-flex justify-content-around align-content-center mt-3">
                  <Col>
                    <h3>{t("registration:airlineDemographicInformation")}</h3>
                  </Col>
                </Row>
                <div>
                  <h6 className="my-bg-info">{t("enterDetail")}</h6>
                </div>
                <Row className="d-flex justify-content-around align-content-center my-2">
                  <Col>
                    <h5>{t("customerInformation")}</h5>
                  </Col>
                </Row>
                {productid === PRODUCTID_DEPARTURE && (
                  <h6 className="normalMessageColor">
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="me-1 icon-color"
                    />
                    {t("infantNameNotRequiredDeparture")}
                  </h6>
                )}

                {/* adult component  */}
                <div className="mt-4">
                  {formik.values.adults?.map((a, i) => (
                    <AdultDetails
                      key={i}
                      isMandatory={i === 0 ? true : false}
                      title={a.title}
                      formik={formik}
                      index={i}
                      bookingDate={getBookingDate()}
                      onBlur={(key, e, title) => {
                        formik.handleBlur(e);
                        if (key === "title" && isTitleChangeable) {
                          setIsTitleChangeable(false);
                          formik.setFieldValue(
                            `primaryContactDetails.${key}`,
                            title
                          );
                        }
                        if (
                          key === "firstName" &&
                          formik.values.primaryContactDetails?.firstName === ""
                        ) {
                          formik.setFieldValue(
                            `primaryContactDetails.${key}`,
                            e.target.value
                          );
                        }
                        if (
                          key === "lastName" &&
                          formik.values.primaryContactDetails?.lastName === ""
                        )
                          formik.setFieldValue(
                            `primaryContactDetails.${key}`,
                            e.target.value
                          );
                        if (
                          key === "email" &&
                          formik.values.primaryContactDetails?.email === ""
                        )
                          formik.setFieldValue(
                            `primaryContactDetails.${key}`,
                            e.target.value
                          );
                      }}
                      onChange={(key, val) => {
                        formik.setFieldValue(`adults.${i}.${key}`, val);
                      }}
                    />
                  ))}
                </div>
                {/* child component */}
                <div className="mt-4">
                  {formik.values.childs?.map((a, i) => (
                    <ChildDetails
                      key={i}
                      heading={kidsType[0]}
                      isMandatory={i === 0 ? true : false}
                      title={a.title}
                      type={"child"}
                      formik={formik}
                      index={i}
                      bookingDate={getBookingDate()}
                      onBlur={(key, e, title) => {
                        formik.handleBlur(e);
                        if (formik.values.adults.length === 0) {
                          if (key === "title" && isTitleChangeable) {
                            setIsTitleChangeable(false);
                            formik.setFieldValue(
                              `primaryContactDetails.${key}`,
                              title
                            );
                          }
                          if (
                            key === "firstName" &&
                            formik.values.primaryContactDetails?.firstName ===
                              ""
                          ) {
                            formik.setFieldValue(
                              `primaryContactDetails.${key}`,
                              e.target.value
                            );
                          }
                          if (
                            key === "lastName" &&
                            formik.values.primaryContactDetails?.lastName === ""
                          )
                            formik.setFieldValue(
                              `primaryContactDetails.${key}`,
                              e.target.value
                            );
                          if (
                            key === "email" &&
                            formik.values.primaryContactDetails?.email === ""
                          )
                            formik.setFieldValue(
                              `primaryContactDetails.${key}`,
                              e.target.value
                            );
                        }
                      }}
                      onChange={(key, val) => {
                        formik.setFieldValue(`childs.${i}.${key}`, val);
                      }}
                    />
                  ))}
                </div>
                {/*same  child component used for infant  */}
                <div className="mt-4">
                  {productid !== PRODUCTID_DEPARTURE &&
                    formik.values.infants?.map((a, i) => (
                      <ChildDetails
                        key={i}
                        type={"infant"}
                        heading={kidsType[1]}
                        isMandatory={i === 0 ? true : false}
                        title={a.title}
                        index={i}
                        formik={formik}
                        bookingDate={getBookingDate()}
                        onBlur={(key, e) => {
                          formik.handleBlur(e);
                        }}
                        onChange={(key, val) => {
                          formik.setFieldValue(`infants.${i}.${key}`, val);
                        }}
                      />
                    ))}
                </div>
                {/* primary and secondary contact component  */}
                <div className="mt-4">
                  <ContactDetails
                    key={0}
                    isMandatory={true}
                    type={"primary"}
                    heading={heading[0]}
                    title={formik.values.primaryContactDetails.title}
                    formik={formik}
                    onChange={(key, val) => {
                      formik.setFieldValue(`primaryContactDetails.${key}`, val);
                    }}
                    onBlur={(key, e) => {
                      formik.handleBlur(e);
                    }}
                  />

                  <ContactDetails
                    key={1}
                    isMandatory={false}
                    heading={heading[1]}
                    title={formik.values.secondaryContactDetails.title}
                    type={"secondary"}
                    formik={formik}
                    onChange={(key, val) => {
                      formik.setFieldValue(
                        `secondaryContactDetails.${key}`,
                        val
                      );
                    }}
                    onBlur={(key, e) => {
                      formik.handleBlur(e);
                    }}
                  />
                </div>
                {checkLoggedInUserIsLoungePartner() && (
                  <Col md={12} sm={12}>
                    <PriorityPassCard
                      key={0}
                      ispriorityPass={
                        getLoggedInUsername() === PRIORITYPASS_LOGIN.username
                          ? true
                          : false
                      }
                      formik={formik}
                      onChange={(key, val) => {
                        formik.setFieldValue(`priorityPassDetails.${key}`, val);
                      }}
                      onBlur={(key, e) => {
                        formik.handleBlur(e);
                      }}
                    />
                  </Col>
                )}
                <Row className="mt-4 ">
                  <Col
                    md={12}
                    sm={12}
                    className="d-flex justify-content-around align-content-center"
                  >
                    <CommonButton
                      type="submit"
                      classname=" gradient-btn mb-2 mx-1"
                      title={t("registration:submitDetails")}
                      // onsubmit={handleSubmit}
                    />
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
}

export default StandByBookingForm;
