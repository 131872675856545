import { Card, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import CommonButton from "../components/commonComponents/CommonButton";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { selectedMarket as currentMarket } from "../redux/marketRedux";
import HtmlToReactConverter from "../components/commonComponents/HtmlToReactConverter";
import { useSelector } from "react-redux";
import { getAdultMinimumAge, getMarketLoungenamesWithAirport, getProductName, isSubDistributorProfile, myDateFormat } from "../components/commonComponents/commonFunctions";
import Promotions from "../components/commonComponents/Promotions";

function GiftPaymentSuccess() {
  const { t } = useTranslation(['common', 'successpage']);
  const selectedMarket = useSelector(currentMarket);
  const location = useLocation();
  const successInfo = location.state;

  const marketLoungenameswithairport = getMarketLoungenamesWithAirport(
    selectedMarket?.airports
  );

  const getLoungeNameWithAirpot = (value) => {
    return marketLoungenameswithairport.find(a => a?.value === value)?.label;
  }

  const redirectURL = () => {
    let link = isSubDistributorProfile() ? "/distributor-booking-user" : "/";
    if (successInfo?.isPaxCountUpdate) {
      link = "/my-bookings";
    }
    return link;
  }

  const getBookedClubName = (e) => {
    let clubName = "";
    clubName = getLoungeNameWithAirpot((e?.airportid));
    return clubName;
  }

  const getBookedDate = (e) => {
    let date = "";
    date = myDateFormat(e?.deliverydate, "yyyyMMdd", "MM/dd/yyyy");
    return date;
  }

  return (
    <>
      <section>
        <Container className="my-4">
          <h3 className="my-3 d-flex justify-content-center text-center">
            <span className="heading">{t('successpage:giftPurchaseComplete')}</span>
          </h3>
          <Row>
            <Col md={12} className="mx-auto xl">
              <Card className="text-center mt-2">
                <Card.Body className="text-center">
                  <Row>
                    <Col md={12} className="d-flex justify-content-center">
                      <h4 className="lh-base">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          size="3x"
                          className="mb-3"
                          color="green"
                        />
                      </h4>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12} className="d-flex justify-content-center">
                      <h5><HtmlToReactConverter value={t('successpage:thanksForGiftCardPaymentMessage', { confirmationnumber: successInfo?.confirmationnumber, currencyCode: successInfo?.currencyCode, amount: successInfo?.amount, email: successInfo?.selectedPaymentType === "INVOICE" ? successInfo?.userDeafultEmail : successInfo?.confirmationEmail })} /></h5>
                    </Col>
                  </Row>
                  {successInfo?.successResponse?.giftcartitems?.map((e, index) => (
                    <Card key={index} className="mt-3 mb-3 my-bg-info">
                      <Row>
                        <Col md={12} className="d-flex justify-content-center">
                          <h5 className="mb-1 lh-base">
                            <HtmlToReactConverter value={t('successpage:GiftPaymentSuccessMessage', {
                              confirmationnumber: e?.confirmationnumber,
                              club: getBookedClubName(e),
                              product: getProductName(e?.productid),
                              date: getBookedDate(e),
                              adultCount: e?.adulttickets,
                              childCount: e?.childtickets,
                              email: e?.contactemail,
                              adultAge: getAdultMinimumAge()
                            })} />
                          </h5>
                        </Col>
                      </Row>
                    </Card>))}
                  <Row>
                    <Col md={12} className="d-flex justify-content-center">
                      <p>
                        {t('successpage:presentEmailUponArrival')}
                      </p>
                    </Col>
                  </Row>
                  <Promotions
                    jePromotion={true}
                    jemPromotion={true}
                  />
                  <Row>
                    <Col className="m-4 d-flex justify-content-center">
                      <Link to={redirectURL()} className="me-1 text-decoration-none">
                        <CommonButton
                          type="button"
                          size="lg"
                          classname="gradient-btn mx-2"
                          title={t('return')}
                        />
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="d-flex justify-content-center">
                      <h5 className="lead mb-3 lh-base">
                        <HtmlToReactConverter value={t('successpage:contactBottomArea', { phone: selectedMarket?.contact?.phone, email: selectedMarket?.contact?.email, skype: selectedMarket?.contact?.skype })} />
                      </h5>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default GiftPaymentSuccess;