import { Card, Col, Form, Row, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import Password from "./Password";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";
import {
  changeMemberCardPin,
  mapmembercard,
  processMemberCard,
} from "../../redux/apiCalls";
import md5 from "md5";
import { showError, showSuccess } from "./Swal";
// import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { HandleAPIError, divRef, renderErrorMessages, scrollToDiv, translateConstantText,numericValidation } from "./commonFunctions";
import { MEMBERCARD_CARD_NUMBER_LABEL, MEMBERCARD_CARD_TYPE_LABEL, MEMBERCARD_PIN_MAXLENGTH} from "../../config/commonConstants";
import CommonButton from "./CommonButton";
import { object, ref, string } from "yup";
import { useFormik } from "formik";
import ErrorMessages from "./ErrorMessages";

export default function ChangeMemberPinModal(props) {
  const [memberCardNumber, setMemberCardNumber] = useState();
  const [memberCardType, setMemberCardType] = useState();
  const [cardHolderName, setCardHolderName] = useState();
  const { t } = useTranslation(["common", "memberandcardinformation"]);

  const navigate = useNavigate();
  const location = useLocation();
  const sendToMemberProfile = location.state;

  const [loading, setLoading] = useState(false);

  const validationSchema = object().shape({
    oldPin: string().required(t("memberandcardinformation:errors.oldPin.required")).min(MEMBERCARD_PIN_MAXLENGTH).max(MEMBERCARD_PIN_MAXLENGTH),
    newPin: string().required(t("memberandcardinformation:errors.newPin.required")).min(MEMBERCARD_PIN_MAXLENGTH).max(MEMBERCARD_PIN_MAXLENGTH),
    confirmPin: string().required(t("memberandcardinformation:errors.confirmPin.required")).min(MEMBERCARD_PIN_MAXLENGTH).max(MEMBERCARD_PIN_MAXLENGTH).oneOf(
      [ref("newPin")],
      t("memberandcardinformation:errors.compareNewPin")
    ), 
  });

  const handleSubmit = async () => {
    const data = {
      memberid: memberCardType?.value + memberCardNumber,
      oldpin: md5(formik.values.oldPin),
      newpin: md5(formik.values.newPin),
    };

    const arrGetValueResponse = {
      action: "VALIDATE",
      memberid: memberCardType?.value + memberCardNumber,
      pin: md5(formik.values.oldPin),
      allowexpired: "N",
    };
    try {
      setLoading(true);
      const response = await processMemberCard(arrGetValueResponse);
      const memberProfileData = response?.data;
      if (response?.status === 0) {
        try {
          const response = await changeMemberCardPin(data);
          if (response?.status === 0) {
            const memberCardProfileData = {
              cardNumber: memberCardNumber,
              cardType: memberCardType?.value,
              showvalue: "Y", /// hardcode
              pin: formik.values.newPin,
            };
            if (props?.linkCardStatus) {
              props?.setConvertMd5(true)
              const mapMemberCardData = {
                action: "UPDATE",
                memberid: memberCardType?.value + memberCardNumber,
                membername: cardHolderName,
                pin: md5(formik.values.newPin),
              };
              let mapMemberCardResponse = await mapmembercard(mapMemberCardData);
              if (mapMemberCardResponse?.status === 0) {
              } else {
                showError(t("error"), response?.statusMessage);
              }
            }
            await showSuccess(
              response.statusMessage,
              t("memberandcardinformation:pinUpdateMessage")
            );
            props?.onSuccess(formik.values.newPin);
            props?.setResetMemberPinStatus(true);
            props?.cancelModal();
            if (sendToMemberProfile?.sendToMemberProfile === true) {
              navigate("/member-card/member-profile", {
                state: { memberProfileData, memberCardProfileData },
              });
            }
          } else {
            showError(t("error"), response?.statusMessage);
          }
        } catch (e) {
          HandleAPIError(e);
        } finally {
          setLoading(false);
        }
      } else {
        showError(t("error"), response?.statusMessage);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setMemberCardNumber(props.vipmembershipcardObject?.cardNumber);
    setMemberCardType(props.vipmembershipcardObject?.memberCards);
    setCardHolderName(props.vipmembershipcardObject?.cardHolderName);
  }, [props.vipmembershipcardObject]);

  const formik = useFormik({
    initialValues: {
      oldPin: "",
      newPin: "",
      confirmPin: "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Modal
        show={props.visible}
        onHide={(status) => {
          props.setResetMemberPinStatus(false);
          props.cancelModal(status);
        }}
        size="md"
        style={{ zIndex: "9998" }}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton><h3 className="fw-bold modalHeading">{t("memberandcardinformation:changeMemberPinHeader")}</h3></Modal.Header>
       
        <Loader loading={loading} message={t("changepassword:Processing")} />

        <Modal.Body className="mb-3">
          <Form onSubmit={(e)=> scrollToDiv(e,formik)}  ref={divRef}>
            <Row className="d-flex justify-content-center mb-3">
              <Col md={10}>
                <Card className="bg-light bg-opacity-10 rounded-3 ">
                  <Card.Body>
                  <Row className="d-flex justify-content-start">
                      <Col md={12}>
                        <h6 className="field">{t('fillAllFields')}</h6>
                      </Col>
                    </Row>
                    <ErrorMessages formik={formik} />
                    <Row className="d-flex justify-content-center">
                      <Col md={12} sm={12}>
                        <Form.Group className="my-2">
                          <Form.Label className="d-flex">
                          {t('memberandcardinformation:cardType',{membershipType: translateConstantText("MEMBERCARD_CARD_TYPE_LABEL")})}:&nbsp;<b>{memberCardType?.label}</b>
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="d-flex justify-content-center">
                      <Col md={12}>
                        <Form.Label className="d-flex" htmlFor="cardNumber">
                        {t('memberandcardinformation:cardNumber',{membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL")})}:&nbsp;<b>{memberCardNumber}</b>
                        </Form.Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" className="mb-3">
                        <Form.Label htmlFor="validationCustomOldPassword">
                          {t("memberandcardinformation:oldPin")}<span className="field">*</span>
                        </Form.Label>
                        <Password
                          name="oldPin"
                          placeholder={t("memberandcardinformation:oldPin")}
                          id="validationCustomOldPassword"
                          value={formik.values.oldPin}
                          minLength={MEMBERCARD_PIN_MAXLENGTH}
                          maxLength={MEMBERCARD_PIN_MAXLENGTH}
                          onBlur={formik.handleBlur}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "oldPin",
                              numericValidation(e.target.value)
                            )
                          }
                          className={
                            formik.errors.oldPin && formik.touched.oldPin
                              ? "error"
                              : ""
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" className="mb-3">
                        <Form.Label htmlFor="validationCustomNewPassword">
                          {t("memberandcardinformation:newPin")}<span className="field">*</span>
                        </Form.Label>
                        <Password
                          name="newPin"
                          id="validationCustomNewPassword"
                          placeholder={t("memberandcardinformation:newPin")}
                          value={formik.values.newPin}
                          maxLength={MEMBERCARD_PIN_MAXLENGTH}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "newPin",
                              numericValidation(e.target.value)
                            )
                          }
                          onBlur={formik.handleBlur}
                          className={
                            formik.errors.newPin && formik.touched.newPin
                              ? "error"
                              : ""
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" className="mb-3">
                        <Form.Label htmlFor="validationCustomConfirmPassword">
                          {t("memberandcardinformation:confirmPin")}<span className="field">*</span>
                        </Form.Label>
                        <Password
                          name="confirmPin"
                          id="validationCustomConfirmPassword"
                          placeholder={t("memberandcardinformation:confirmPin")}
                          value={formik.values.confirmPin}
                          maxLength={MEMBERCARD_PIN_MAXLENGTH}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "confirmPin",
                              numericValidation(e.target.value)
                            )
                          }
                          onBlur={formik.handleBlur}
                          className={
                            formik.errors.confirmPin &&
                            formik.touched.confirmPin
                              ? "error"
                              : ""
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="d-flex justify-content-end">
                        <CommonButton
                          classname="gradient-btn mx-1"
                          title={t("memberandcardinformation:changePin")}
                          type="submit"
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        {/* <Modal.Footer /> */}
      </Modal>
    </>
  );
}
