import { Card, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import CommonButton from "../components/commonComponents/CommonButton";
import Promotions from "../components/commonComponents/Promotions";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { selectedMarket as currentMarket } from "../redux/marketRedux";
import HtmlToReactConverter from "../components/commonComponents/HtmlToReactConverter";
import { useSelector } from "react-redux";

function Failure() {
  const { t } = useTranslation(['common', 'successpage']);
  const selectedMarket = useSelector(currentMarket);

  return (
    <>
      <section>
        <Container className="my-4">
          <h3 className="my-3 d-flex justify-content-center text-center">
            <span className="heading">{t('failure:paymentSuccess')}</span>
          </h3>

          <Row>
            <Col md={12} className="mx-auto xl">
              <Card className="text-center mt-2">
                <Card.Body className="text-center">
                  <Row>
                    <Col md={12} className="d-flex justify-content-center">
                      <h4 className="lh-base">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          size="3x"
                          className="mb-3"
                          color="green"
                        />
                      </h4>
                    </Col>
                  </Row>

                  <Card className="mb-3 my-bg-info">
                    <Row>
                      <Col md={12} className="d-flex justify-content-center">
                        <h5 className="lh-base">
                          <HtmlToReactConverter value={t('failure:reservationAcceptedText')} />
                        </h5>
                      </Col>
                    </Row>
                  </Card>
                  <Promotions
                    jemPromotion={true}
                  />
                  <Row>
                    <Col className="m-4 d-flex justify-content-center">
                      <Link to="/" className="me-1 text-decoration-none">
                        <CommonButton
                          type="submit"
                          size="lg"
                          classname="gradient-btn mx-2"
                          title={t('return')}
                        />
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="d-flex justify-content-center">
                      <h5 className="lead mb-3 lh-base">
                        <HtmlToReactConverter value={t('successpage:contactBottomArea', { phone: selectedMarket?.contact?.phone, email: selectedMarket?.contact?.email, skype: selectedMarket?.contact?.skype })} />
                      </h5>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
export default Failure;
