import Modal from "react-bootstrap/Modal";
import CommonButton from "./CommonButton";
import { Card, Col, Form, Row } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Select from "react-select";
import { ARRAY_YES_NO, USER_NAME_MAXLENGTH, MANAGEUSER_STATUS, EMAIL_POLICY, USERNAME_MAXLENGTH, EMAIL_MAXLENGTH, LAST_NAME_MAXLENGTH, FIRST_NAME_MAXLENGTH, REGEX_ALPHABET, CONTACT_NUMBER_MAXLENGTH,  } from "../../config/commonConstants";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import Loader from "./Loader";
import { selectedMarketId as SM } from "../../redux/marketRedux";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { divRef, numericValidation, renderErrorMessages, scrollToDiv, alphabetValidation } from "./commonFunctions";
import ErrorMessages from "./ErrorMessages";
import Switch from "react-switch";

export default function AddUserModal(props) {
  const selectedMarketId = useSelector(SM);

  const [loading, setLoading] = useState(false)
  const [ReportAgent, setReportAgent] = useState(false);
  const [ManageUser, setManageUser] = useState(false);
  const [Status, setStatus] = useState({ value: "", error: "", });
  const Blank = useState({ value: "", error: "" })
  const[render,setRender] = useState(false) 

  const userData = props.formData;
  const resetModal = () => {
    if (userData?.editMode === "Y") {
        formik.setValues({
          ...formik.values,
          userName: userData?.username !== "" ? userData?.username : "",
          firstName: userData?.firstname !== "" ? userData?.firstname : "",
          lastName: userData?.lastname !== "" ? userData?.lastname : "",
          email: userData?.email !== "" ? userData?.email : "",
          phone: userData?.phone !== "" ? userData?.phone : "",
        });
    } else {
      formik.resetForm(); 
    }
  };

  const cancelHandler = () => {
    // resetModal(); 
    props.cancelModal(); 
    setRender(!render)
  };

  const { t } = useTranslation(["common", "manageuser"]);

  const validationSchema = Yup.object().shape({
    userName: Yup.string().trim().required(t("manageuser:errors.userName.required")),
    firstName: Yup.string().trim().required(t("manageuser:errors.firstName.required"))
    .matches((/^[A-Za-z]+$/), t("manageuser:errors.firstName.match")),
    lastName: Yup.string().trim().required(t("manageuser:errors.lastName.required")),
    email: Yup.string()
      .trim().required(t("manageuser:errors.email.required"))
      .matches(EMAIL_POLICY, t("manageuser:errors.email.match")),
    phone: Yup.string().trim().required(t("manageuser:errors.phone.required")),   
  });
  const formik = useFormik({
    initialValues: {
      userName: "",
      firstName: "",
      lastName: "",
      email:"",
      phone:""
    },
    validationSchema,
    onSubmit: () => { handleSubmit(formik.values) },
  });

  const handleSubmit = (values) => {
    const data = {
      "username": formik.values.userName,
      "firstname": formik.values.firstName,
      "lastname": formik.values.lastName,
      "email": formik.values.email,
      "phone": formik.values.phone,
      "reportagent": ReportAgent ? "Y" : "N",
      "adminuser": ManageUser ? "Y" : "N",
    };
    if (userData?.editMode === "Y") {
      data.status = Status.value.value;
    } else {
      data.marketid = selectedMarketId;
    }

    props.handleSubmit(data, userData?.editMode || "N");
    props.cancelModal();
    setRender(!render)
  }

  useEffect(() => {
    formik.setFieldValue("userName", props?.formData?.username || "");
    formik.setFieldValue("firstName", props?.formData?.firstname || "");
    formik.setFieldValue("lastName", props?.formData?.lastname || "");
    formik.setFieldValue("email", props?.formData?.email || "");
    formik.setFieldValue("phone", props?.formData?.phone || "");
    setReportAgent(props?.formData?.reportagent === 'Y' ? true : false );
    setManageUser( props?.formData?.adminuser === 'Y' ? true : false );
    setStatus({ value: props?.formData?.status === 'A' ? MANAGEUSER_STATUS[0] : MANAGEUSER_STATUS[1] });
  }, [props.formData]);

  useEffect(()=>{
    formik.setErrors({}, false);
    formik.setTouched({}, false);
  },[render])

  return (
    <>
      <Loader loading={loading} />
      <Modal
        show={props.visible}
        onHide={cancelHandler}
        size="lg"
        style={{ zIndex: "9998" }}
        centered
        scrollable={true}
        backdrop="static"
      >
      <Modal.Header closeButton><h3 className="fw-bold modalHeading">{t("manageuser:modalHeading")}</h3></Modal.Header>
        <Modal.Body>
        <Form className="" ref={divRef}>
          <Row className="d-flex justify-content-center">
            <Col md={10}>
              <Card className="bg-light bg-opacity-10 rounded-3 ">
                <Card.Body>
                    <Row>
                      <Col md="12" className="mb-3">
                        <h6 className="field">{t('fillAllFields')} </h6>
                      </Col>
                    </Row>
                  <ErrorMessages formik={formik} />     
                    <Row>
                      <Col
                        md="12"
                        className="mb-3"
                      >
                        <Form.Label className="d-flex" htmlFor="validationCustomUsername">
                          {t('userName')}<span className="field">*</span>
                        </Form.Label>
                        <InputGroup className="d-flex">
                          <Form.Control
                            type="text"
                            name="userName"
                            id="validationCustomUsername"
                            maxLength={USERNAME_MAXLENGTH}
                            placeholder={t('userName')}
                            value={formik.values.userName}
                            disabled={userData.username}
                            aria-describedby="inputGroupPrepend"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={formik.touched.userName && formik.errors.userName ? "error" : ""}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md="12"
                        className="mb-3"
                      >
                        <Form.Label className="d-flex" htmlFor="validationFirstName">
                          {t('manageuser:firstName')}<span className="field">*</span>
                        </Form.Label>
                        <InputGroup className="d-flex">
                          <Form.Control
                            type="text"
                            name="firstName"
                            id="validationFirstName"
                            placeholder={t('manageuser:firstName')}
                            // value={formik.values.firstName?.replace(REGEX_ALPHABET,"")}
                            value={formik.values.firstName}
                            aria-describedby="inputGroupPrepend"
                            maxLength={FIRST_NAME_MAXLENGTH}
                            // onChange={formik.handleChange}
                            onChange={(e) => {
                              e.target.value = alphabetValidation(e.target.value)
                              formik.handleChange(e);
                            }}
                            // onChange={(e) => {
                            //   formik.setFieldValue('firstName',  alphabetValidation(e.target.value));
                            // }}
                            
                            
                            onBlur={formik.handleBlur}
                            className={formik.touched.firstName && formik.errors.firstName ? "error" : ""}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md="12"
                        className="mb-3"
                      >
                        <Form.Label className="d-flex" htmlFor="validationLastName">
                          {t('manageuser:lastName')}<span className="field">*</span>
                        </Form.Label>
                        <InputGroup className="d-flex">
                          <Form.Control
                            type="text"
                            name="lastName"
                            id="validationLastName"
                            placeholder={t('manageuser:lastName')}
                            // value={formik.values.lastName?.replace(REGEX_ALPHABET,"")}
                            value={formik.values.lastName}
                            maxLength={LAST_NAME_MAXLENGTH}
                            aria-describedby="inputGroupPrepend"
                            // onChange={(e)=>{
                            //   e.target.value=e.target.value?.replace(REGEX_ALPHABET,"")
                            //   formik.handleChange(e);
                            // }}
                            onChange={(e) => {
                              e.target.value = alphabetValidation(e.target.value)
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            className={formik.touched.lastName && formik.errors.lastName ? "error" : ""}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md="12"
                        className="mb-3"
                      >
                        <Form.Label className="d-flex" htmlFor="validationCustomEmail">
                          {t('email')}<span className="field">*</span>
                        </Form.Label>
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            @
                          </InputGroup.Text>
                          <Form.Control
                            type="email"
                            name="email"
                            id="validationCustomEmail"
                            placeholder={t('emailAddress')}
                            value={formik.values.email}
                            aria-describedby="inputGroupPrepend"
                            maxLength={EMAIL_MAXLENGTH}
                            minLength={1}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={formik.touched.email && formik.errors.email ? "error" : ""}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md="12"
                        className="mb-3"
                      >
                        <Form.Label className="d-flex" htmlFor="validationPhone">
                          {t('manageuser:phone')}<span className="field">*</span>
                        </Form.Label>
                        <InputGroup className="d-flex">
                          <Form.Control
                            type="text"
                            name="phone"
                            id="validationPhone"
                            placeholder={t('manageuser:phone')}
                            // value={formik.values.phone.replace(REGEX_NUMERIC, "")}
                            value={formik.values.phone}
                            aria-describedby="inputGroupPrepend"
                            onChange={(e)=>{
                            e.target.value=numericValidation(e.target.value)
                            formik.handleChange(e)
                            }}
                            onBlur={formik.handleBlur}
                            className={formik.touched.phone && formik.errors.phone ? "error" : ""}
                            maxLength={CONTACT_NUMBER_MAXLENGTH}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" className="mb-3">
                        <Form.Label className="d-flex">
                          {t('manageuser:reportAgent')}

                          <Switch
                            onChange={(nextChecked) => {
                              setReportAgent(nextChecked)
                            }}
                            checked={ReportAgent}
                            className="react-switch me-2"
                            height={23}
                          />
                        </Form.Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" className="mb-3">
                        <Form.Label className="d-flex">
                          {t('manageuser:manageUser')}
                          <Switch
                            name="manageUser"
                            onChange={(nextChecked) => {
                              setManageUser(nextChecked)
                            }}
                            checked={ManageUser}
                            className="react-switch me-2"
                            height={23}
                          />
                        </Form.Label>
                      </Col>
                    </Row>
                    {userData.username ? (
                      <Row>
                        <Col
                          md="12"
                          className="mb-3"
                        >
                          <Form.Label className="d-flex">
                            {t('manageuser:status')}
                          </Form.Label>
                          <Form.Group className="">
                            <Select
                              className="my-0 mb-3"
                              options={MANAGEUSER_STATUS}
                              defaultValue={MANAGEUSER_STATUS[0]}
                              value={Status.value}
                              onChange={(value) =>
                                setStatus({
                                  value: value,
                                  error: "",
                                })
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    ) : ""}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          
        </Form>
        </Modal.Body>
        <Modal.Footer> 
          <Row>
              <Col
                md="12"
                className=""
                >
                <CommonButton
                  classname="gradient-btn"
                  title={t('save')}
                  onclick={(e)=> scrollToDiv(e,formik)}
                  />
                <CommonButton
                  classname="exit ms-2"
                  title={t('close')}
                  onclick={cancelHandler}
                  />
              </Col>
          </Row>
        </Modal.Footer>
      
      </Modal>
    </>
  );
}
