import { Card, Col, Container, Row } from "react-bootstrap";
import CommonButton from "../components/commonComponents/CommonButton";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Promotions from "../components/commonComponents/Promotions";
import HtmlToReactConverter from "../components/commonComponents/HtmlToReactConverter";

function PurchaseMembershipSuccess() {
  const { t } = useTranslation(['common']);
  const location = useLocation();
  const successInfo = location.state;
  
  useEffect(() => {
  }, []);

  return (
    <>
      <section>
        <Container className="my-4">
          <h3 className="my-3 d-flex justify-content-center text-center">
            <span className="heading">{successInfo?.messageHeading}</span>
          </h3>
          <Row>
            <Col md={12} className="mx-auto xl">
              <Card className="text-center mt-2">
                <Card.Body className="text-center">
                  <Row>
                    <Col md={12} className="d-flex justify-content-center">
                      <h4 className="lh-base">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          size="3x"
                          className="mb-3"
                          color="green"
                        />
                      </h4>
                    </Col>
                  </Row>
                  <Card className="mb-3 my-bg-info">
                    <Row>
                      <Col md={12} className="d-flex justify-content-center">
                        <h5 className="lh-base">
                          <HtmlToReactConverter value={t('successpage:purchaseMembershipSuccessMessage', {
                              currencyCode:t("usd"),
                              amount:successInfo.amount,
                              email: successInfo.email
                            })} />
                        </h5>
                      </Col>
                    </Row>
                  </Card>
                  <Promotions
                    jePromotion={true}
                    jemPromotion={true}
                  />
                  <Row>
                    <Col className="m-4 d-flex justify-content-center">
                      <Link to="/" className="me-1 text-decoration-none">
                        <CommonButton
                          type="submit"
                          size="lg"
                          classname="gradient-btn mx-2"
                          title={t('return')}
                        />
                      </Link>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
export default PurchaseMembershipSuccess;
