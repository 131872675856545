import { faUser, faPhone, faAt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function BookingDetailsContactDetailsDisplay(props) {
  const { t } = useTranslation(["common"]);
  
  return (
    <div key={0}>
      <Row className="d-flex justify-content-center align-content-center mb-2">
        <Col md={12} sm={12}>
          <h5 className="mb-1">
            <b>{props?.contactType === "secondary" ? t('secondaryContact') : t('primaryContact') }</b>
          </h5>
        </Col>
      </Row>

      <Row className="d-flex justify-content-start align-content-center mb-1">
        {props?.contactDetails?.contactname && 
        <Col md={3} sm={12}>
          <h5>
            <FontAwesomeIcon
              icon={faUser}
              className="mx-1 icon-color"
              size="sm"
            />{" "}
            <b>{t('name')}: </b>{props?.contactDetails?.contactname}
          </h5>
        </Col>
        }
        {(props?.contactDetails?.email || props?.contactDetails?.contactemail) && 
          <Col md={5} sm={12}>
            <h5>
              <FontAwesomeIcon
                icon={faAt}
                size="sm"
                className="mx-1 icon-color"
              />
              {props?.contactDetails?.email ?
                <><b>{t('email')}: </b>{props?.contactDetails?.email}</> :
                <><b>{t('email')}: </b>{props?.contactDetails?.contactemail}</>
              }
            </h5>
          </Col>
        }
        {(props?.contactDetails?.phone || props?.contactDetails?.contactphone) && 
          <Col md={4} sm={12}>
            <h5>
              <FontAwesomeIcon
                icon={faPhone}
                className="mx-1 icon-color"
                size="sm"
              />
              {props?.contactDetails?.phone ?
                <><b>{t('contactNumber')}: </b>{props?.contactDetails?.phone}</> :
                <><b>{t('contactNumber')}: </b>{props?.contactDetails?.contactphone}</>
              }
            </h5>
          </Col>
        }
      </Row>
    </div>
  );
}

export default BookingDetailsContactDetailsDisplay;
