import { Col, Container, Row } from "react-bootstrap";
import CommonButton from "../components/commonComponents/CommonButton";
import { Card, Form, InputGroup } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from "react-i18next";
import { myDateFormat, HandleAPIError, scrollToDiv, translateConstantText, numericValidation, alphabetValidation } from "../components/commonComponents/commonFunctions"
import md5 from "md5";
import { getMemberCardImage, processMemberCard, updateCardProfile } from "../redux/apiCalls";
import { showSuccess, showError } from "../components/commonComponents/Swal";
import Loader from "../components/commonComponents/Loader";
import GetTransactionsModal from "../components/commonComponents/MemberCardTransactionsModal"
import { CONTACT_NUMBER_MAXLENGTH, EMAIL_MAXLENGTH, EMAIL_POLICY, MEMBERCARD_CARD_NUMBER_LABEL, MEMBERCARD_CARD_TYPE_LABEL, NAME_LENGTH, REGEX_ALPHABET } from "../config/commonConstants";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessages from "../components/commonComponents/ErrorMessages";

function MemberCardProfile() {
  const { t } = useTranslation(["common", "memberandcardinformation"]);
  const location = useLocation();
  const memberProfileData = location.state.memberProfileData;
  const memberCardProfileData = location.state.memberCardProfileData;

  const [memberCardImage, setMemberCardImage] = useState({});
  const [cardType, setCardType] = useState({ value: "", error: "" });
  const [cardNumber, setCardNumber] = useState({ value: "", error: "" });
  const [balance, setBalance] = useState({ value: 0, error: "" });

  const [loading, setLoading] = useState(false);
  const [isMemberCardTransactionModalVisible, setIsMemberCardTransactionModalVisible] = useState(false);
  const hideMemberCardTransactionModal = () => setIsMemberCardTransactionModalVisible(false);
  const [transactionData, setTransactionData] = useState(null);

  const validationSchema = Yup.object().shape({
    memberCardName: Yup.string().trim().required(t("memberandcardinformation:errors.memberProfile.memberName.required")),
    email: Yup.string().trim().required(t("memberandcardinformation:errors.memberProfile.email.required"))
        .matches(EMAIL_POLICY, t("memberandcardinformation:errors.memberProfile.email.valid")),
    phone: Yup.string().trim().required(t("memberandcardinformation:errors.memberProfile.phone.required")),
  });
  const formik = useFormik({
    initialValues: {
      memberid: memberCardProfileData.cardTypeValue + memberCardProfileData.cardNumber,
      pin:  md5(memberCardProfileData?.pin),
      memberCardName: "",
      email: "",
      phone: ""
    },
    validationSchema,
    onSubmit: () => { handleSubmit(formik.values) },
  });

  const showTransactionModal = () => {
    setIsMemberCardTransactionModalVisible(true);
    setTransactionData({
      memberCardTypeId: memberCardProfileData.cardTypeValue,
      memberCardNumber: memberCardProfileData.cardNumber,
      pin: memberCardProfileData?.pin,
      showValue: memberCardProfileData?.showvalue
    });
  };

  const refreshProfile = async () => {
    const data = {
      "action": "VALIDATE",
      "memberid": memberCardProfileData.cardTypeValue + memberCardProfileData?.cardNumber,
      "pin": md5(memberCardProfileData?.pin),
      "allowexpired": "Y"
    }
    
    try {
      setLoading(true);
      const response = await processMemberCard(data);
      if (response?.status === 0) {
        formik.setFieldValue("memberCardName", response?.data?.name || "");
        formik.setFieldValue("email", response?.data?.email || "");
        formik.setFieldValue("phone", response?.data?.phone || "");
      }
      else {
        showError(t("error"), response?.statusMessage);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    const data = {
      "memberid": formik.values?.memberid,
      "pin": formik.values?.pin,
      "name": formik.values?.memberCardName,
      "email": formik.values?.email,
      "phone": formik.values?.phone
    }

    try {
      setLoading(true);
      const response = await updateCardProfile(data);
      if (response?.status === 0) {
        showSuccess(response.statusMessage, t('memberandcardinformation:updateMessage'));
      }
      else {
        showError(t("error"), response?.statusMessage);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
      getMemberCardImageFromApi();
    }
  }

  const getMemberCardImageFromApi = async () => {
    const arrMemberCardImageRequest = {
      memberid: memberCardProfileData.cardTypeValue + memberCardProfileData.cardNumber,
      pin: md5(memberCardProfileData.pin),
    };
    // const memberCardImageResponse = await getMemberCardImage(arrMemberCardImageRequest);
    // memberCardImageResponse.data.image = "iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANCSURBVEiJtZZPbBtFFMZ/M7ubXdtdb1xSFyeilBapySVU8h8OoFaooFSqiihIVIpQBKci6KEg9Q6H9kovIHoCIVQJJCKE1ENFjnAgcaSGC6rEnxBwA04Tx43t2FnvDAfjkNibxgHxnWb2e/u992bee7tCa00YFsffekFY+nUzFtjW0LrvjRXrCDIAaPLlW0nHL0SsZtVoaF98mLrx3pdhOqLtYPHChahZcYYO7KvPFxvRl5XPp1sN3adWiD1ZAqD6XYK1b/dvE5IWryTt2udLFedwc1+9kLp+vbbpoDh+6TklxBeAi9TL0taeWpdmZzQDry0AcO+jQ12RyohqqoYoo8RDwJrU+qXkjWtfi8Xxt58BdQuwQs9qC/afLwCw8tnQbqYAPsgxE1S6F3EAIXux2oQFKm0ihMsOF71dHYx+f3NND68ghCu1YIoePPQN1pGRABkJ6Bus96CutRZMydTl+TvuiRW1m3n0eDl0vRPcEysqdXn+jsQPsrHMquGeXEaY4Yk4wxWcY5V/9scqOMOVUFthatyTy8QyqwZ+kDURKoMWxNKr2EeqVKcTNOajqKoBgOE28U4tdQl5p5bwCw7BWquaZSzAPlwjlithJtp3pTImSqQRrb2Z8PHGigD4RZuNX6JYj6wj7O4TFLbCO/Mn/m8R+h6rYSUb3ekokRY6f/YukArN979jcW+V/S8g0eT/N3VN3kTqWbQ428m9/8k0P/1aIhF36PccEl6EhOcAUCrXKZXXWS3XKd2vc/TRBG9O5ELC17MmWubD2nKhUKZa26Ba2+D3P+4/MNCFwg59oWVeYhkzgN/JDR8deKBoD7Y+ljEjGZ0sosXVTvbc6RHirr2reNy1OXd6pJsQ+gqjk8VWFYmHrwBzW/n+uMPFiRwHB2I7ih8ciHFxIkd/3Omk5tCDV1t+2nNu5sxxpDFNx+huNhVT3/zMDz8usXC3ddaHBj1GHj/As08fwTS7Kt1HBTmyN29vdwAw+/wbwLVOJ3uAD1wi/dUH7Qei66PfyuRj4Ik9is+hglfbkbfR3cnZm7chlUWLdwmprtCohX4HUtlOcQjLYCu+fzGJH2QRKvP3UNz8bWk1qMxjGTOMThZ3kvgLI5AzFfo379UAAAAASUVORK5CYII=";
    // setMemberCardImage(memberCardImageResponse?.data);
  }

  const downloadMemberCardImage = async (base64Image) => {
    const a = document.createElement("a");
    a.href = "data:image/png;base64," + base64Image;
    a.download = memberCardProfileData.cardNumber + ".png";
    a.click();
  };

  useEffect(() => {
    formik.setFieldValue("memberCardName", memberProfileData?.name || "");
    formik.setFieldValue("email", memberProfileData?.email || "");
    formik.setFieldValue("phone", memberProfileData?.phone || "");
    // setCardTypeValue(memberProfileData.cardTypeValue);
    setCardType({ value: memberCardProfileData?.cardType || "" });
    setCardNumber({ value: memberCardProfileData?.cardNumber || "" });
    setBalance({ value: memberProfileData?.amount || "" });
  }, [memberProfileData, memberCardProfileData]);

  useEffect(() => {
    getMemberCardImageFromApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section>
        {isMemberCardTransactionModalVisible && (
          <GetTransactionsModal
            message={""}
            cancelModal={hideMemberCardTransactionModal}
            data={transactionData}
          />
        )}
        <Loader loading={loading} />
        <Container className="my-4">
          <h3 className="my-3 d-flex justify-content-center heading heading">
            <span className="heading">{t('memberProfile')}</span>
          </h3>
          <div className="my-3 d-flex justify-content-end">
            <ExitButton />
          </div>
          <Card className="mt-2">
            <Card.Body>
              <Row className="d-flex justify-content-start">
                <Col md={6}>
                  <h6 className="field">{t('fillAllFields')}</h6>
                </Col>
              </Row>
              <ErrorMessages formik={formik} />
              <Form onSubmit={(e)=>scrollToDiv(e,formik)} >
                <Row className="justify-content-center my-3">
                  <Col md="8">
                    <Row className="mb-3 justify-content-center">
                      <Col md="4">
                        <Form.Label className="d-flex">
                        {t('memberandcardinformation:cardType',{membershipType: translateConstantText("MEMBERCARD_CARD_TYPE_LABEL")})}
                        </Form.Label>
                      </Col>
                      <Col md="5">
                        <Form.Label className="d-flex">
                          <b> {cardType.value} </b>
                        </Form.Label>
                      </Col>
                    </Row>
                    <Row className="mb-3 justify-content-center">
                      <Col md="4">
                        <Form.Label className="d-flex">
                          {t('memberandcardinformation:cardNumber',{membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL")})}
                        </Form.Label>
                      </Col>
                      <Col md="5">
                        <Form.Label className="d-flex">
                          <b> {cardNumber.value} </b>
                        </Form.Label>
                      </Col>
                    </Row>
                    <Row className="mb-3 justify-content-center">
                      <Col md="4">
                        <Form.Label className="d-flex">
                          {t('memberandcardinformation:memberName')}
                          <span className="field">*</span>
                        </Form.Label>
                      </Col>
                      <Col md="5">
                        <Form.Control
                          placeholder={t('memberandcardinformation:memberNamePlaceholder')}
                          type="text"
                          name="memberCardName"
                          value={formik.values.memberCardName}
                          // onChange={formik.handleChange}
                          // onChange={(e) => {
                          //   e.target.value = e.target.value?.replace(REGEX_ALPHABET,"");
                          //   formik.handleChange(e)
                          // }}
                          onChange={(e) => {
                            e.target.value = alphabetValidation(e.target.value)
                            formik.handleChange(e);
                          }}
                          maxLength={NAME_LENGTH}
                          onBlur={formik.handleBlur}
                          className={formik.touched.memberCardName && formik.errors.memberCardName ? "error":""}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3 justify-content-center">
                      <Col md="4">
                        <Form.Label className="d-flex">
                          {t('email')}<span className="field">*</span>
                        </Form.Label>
                      </Col>
                      <Col md="5">
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            @
                          </InputGroup.Text>
                          <Form.Control
                            type="email"  
                            maxLength={EMAIL_MAXLENGTH}
                            minLength={1}
                            id="memberinformation_email"
                            placeholder={t('emailAddress')}
                            aria-describedby="inputGroupPrepend"
                            value={formik?.values?.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name={'email'}
                            className={formik?.touched.email && formik?.errors.email ? "error rounded-2" : ""}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row className="mb-3 justify-content-center">
                      <Col md="4">
                        <Form.Label className="d-flex">{t('phone')}<span className="field">*</span></Form.Label>
                      </Col>
                      <Col md="5">
                        <Form.Control
                          type="text"
                          placeholder={t('phoneNumber')}
                          value={formik?.values?.phone}
                          onChange={(e)=>{
                            e.target.value = numericValidation(e.target.value);
                            formik.handleChange(e)
                          }}
                          onBlur={formik.handleBlur}
                          maxLength={CONTACT_NUMBER_MAXLENGTH}
                          name={"phone"}
                          className={formik?.touched.phone && formik?.errors.phone ? "error rounded-2" : ""}
                        />
                      </Col>
                    </Row>
                    {memberCardProfileData?.showvalue === "Y" ?
                      (<Row className="mb-3 justify-content-center">
                        <Col md="4">
                          <Form.Label className="d-flex">
                            {t('balance')} {" "} ({t("usd")})
                          </Form.Label>
                        </Col>
                        <Col md="5">
                          <Form.Label className="d-flex"><b>{balance.value.toFixed(2)}</b></Form.Label>
                        </Col>
                      </Row>)
                      : (
                        <Row className="mb-3 justify-content-center">
                          <Col md="4">
                            <Form.Label className="d-flex">
                              {t('memberandcardinformation:expiryDate')}
                            </Form.Label>
                          </Col>
                          <Col md="5">
                            <Form.Label className="d-flex">
                             <b>{myDateFormat(memberProfileData?.expirydate, "yyyyMMdd")}</b>
                            </Form.Label>
                          </Col>
                        </Row>
                      )
                    }
                    <Row className="my-2 d-flex justify-content-center mt-md-4">
                      {memberProfileData?.expired !== "Y" ? (
                        <Col md={"auto"} sm={12} className="mb-2 mb-md-0 pe-md-1">
                          <CommonButton
                            type="submit"
                            classname="gradient-btn "
                            size="md"
                            title={t('updateProfile')}
                            // onclick={handleSubmit}
                          />
                        </Col>
                      ) : null
                      }
                      {memberProfileData?.expired !== "Y" ? (
                        <Col md={"auto"} sm={6} className="mb-2 mb-md-0 px-md-1">
                          <CommonButton
                            classname=" gradient-btn "
                            title={t('memberandcardinformation:refreshProfile')}
                            onclick={refreshProfile}
                          />
                        </Col>
                      ) : null
                      }
                      <Col md={"auto"} sm={6} className="px-md-1">
                        <CommonButton
                          type="button"
                          classname=" gradient-btn"
                          title={t("getTransactions")}
                          onclick={showTransactionModal}
                        />
                      </Col>
                    </Row>
                  </Col>

                  {/* <Col md="5">
                    {memberCardImage?.image ? (
                      <Row>
                        <Col
                          md={12}
                          className="d-flex justify-content-end mt-3"
                        >
                          <Card.Img
                            variant="top"
                            src={"data:image/png;base64," + memberCardImage?.image}
                            className="img-fluid center-block"
                            alt=""
                            sizes="md"
                          />
                        </Col>
                      </Row>
                    ) : null}
                    {memberCardImage?.image && memberProfileData?.expired === "N" ? (
                      <Row>
                        <Col
                          md={12}
                          className="d-flex justify-content-end mt-3"
                        >
                          <CommonButton
                            type="button"
                            classname="gradient-btn "
                            size="md"
                            title={t('download')}
                            onclick={() => downloadMemberCardImage(memberCardImage?.image)}
                          />
                        </Col>
                      </Row>
                    ) : null}
                  </Col> */}
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
}

export default MemberCardProfile;