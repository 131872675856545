import Button from "react-bootstrap/Button";

function CommonButton(props) {
  return (
    <Button
      disabled={props.disabled ? props.disabled : false}
      type={props.type || "button"}
      className={props.classname}
      size={props.size}
      onSubmit={props.onsubmit}
      onMouseOver={props.onMouseOver}
      onClick={props.onclick}
    >
      {props?.icon?.props.position === "left" ? props?.icon : null}
      {props?.title?.toUpperCase()}
      {props?.icon?.props.position === "right" ? props?.icon : null}
    </Button>
  );
}

export default CommonButton;
