import { Card, Col, Container, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import CommonButton from "../components/commonComponents/CommonButton";
import { useEffect, useState } from "react";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from 'react-i18next';
import { getCommissionPeriods, getCommissionReports, getDistributorBooking } from "../redux/apiCalls";
import Loader from "../components/commonComponents/Loader";
import Select from "react-select";
import { myDateFormat, getClubName, getProductName, getCommissionPeriodReportData, HandleAPIError, renderErrorMessages, scrollToDiv, divRef, omit, dateFormatForDisplay, getDistributorSubDistributorProfile, isObjectEmpty, getSubDistributorId } from "../components/commonComponents/commonFunctions";
import { useSelector } from "react-redux";
import {
  createColumnHelper,
} from "@tanstack/react-table"
import { Table } from "../components/commonComponents/Table";
import { selectedMarket as currentMarket } from "../redux/marketRedux";
import { useFormik } from "formik";
import { mixed, object } from "yup";
import ErrorMessages from "../components/commonComponents/ErrorMessages";
import { logindetails as LD, subDistributorProfile as SDP } from "../redux/signInRedux";
import { CSVLink } from "react-csv";

function CommissionReport() {
  const { t } = useTranslation(['common', 'commissionreport']);
  const [commissionperiods, setCommissionPeriods] = useState([]);
  const [commissiondetails, setCommissionDetails] = useState([]);
  const [CommissionReportTable, setCommissionReportTable] = useState([]);
  const [loading, setLoading] = useState(true);
  const selectedMarket = useSelector(currentMarket);
  const airports = selectedMarket?.airports;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const loginDetails = useSelector(LD);
  const subDistributorProfile = useSelector(SDP);
  const [commisstionReportsexport, setCommisstionReportExport] = useState([]);

  const validationSchema = object().shape({
    commissionPeriod: mixed().nullable(),
  })

  const formik = useFormik({
    initialValues: {
      commissionPeriod: null
    },
    validationSchema,
    onSubmit: () => {
      handleSubmit(formik.values);
    },
  })

  const handleSubmit = async (commissionPeriod) => {
    let tmp = [];
    const status = commissionperiods?.filter((data) => data.periodid === commissionPeriod.value)[0]?.status

    if (status === "Open") {
      setCommissionReportTable([])
      setFormSubmitted(true);
    } else {
      const data = {
        "periodid": commissionPeriod.value,
      }
      if (!isObjectEmpty(subDistributorProfile)) {
          data.distributorid = getSubDistributorId()
      }
      setLoading(true)
      try {
        const response = await getCommissionReports(data);
        if (response?.status === 0) {
          setFormSubmitted(true);
          setCommissionDetails(response.data.commissiondetails);
          tmp = getCommissionPeriodReportData(response.data.commissiondetails);
          setCommissionReportTable(tmp);
        }
      } catch (e) {
        HandleAPIError(e);
      } finally {
        setLoading(false);
      }
    }
  }
  const getCommissionPeriods_async = async () => {
    try {
      let request = {};
      if (!isObjectEmpty(subDistributorProfile)) {
        request = {
          "distributorid": getSubDistributorId()
        }
      }
      const response = await getCommissionPeriods(request);
      if (response?.status === 0) {
        setCommissionPeriods(response.data?.periods);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    loginDetails?.sessionid && getCommissionPeriods_async();
  }, [loginDetails]);

  const arr_CommissionReportDate = [];
  commissionperiods?.map((e) => {
    arr_CommissionReportDate.push({ value: e.periodid, label: myDateFormat(e.startdate, "yyyyMMdd") + " - " + myDateFormat(e.enddate, "yyyyMMdd") + " (" + e.status + ")" });
  });

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("airportid", {
      cell: info => <div style={{ textAlign: "left" }}>{getClubName(info.getValue())}</div>,
      header: t('commissionreport:airport')
    }),
    columnHelper.accessor("productid", {
      cell: info => <div style={{ textAlign: "left" }}>{getProductName(info.getValue())}</div>,
      header: t('commissionreport:product'),
      className: "right"
    }),
    columnHelper.accessor("reservationnumber", {
      cell: info => <div style={{ textAlign: "left" }}>{info.getValue()}</div>,
      header: t('commissionreport:reservationNo')
    }),
    columnHelper.accessor("transactiondate", {
      cell: info => <div style={{ textAlign: "left" }}>{dateFormatForDisplay(info.getValue(), "MM/dd/yyyy")}</div>,
      header: t('commissionreport:transactionDate')
    }),
    columnHelper.accessor("onlineentry", {
      cell: info => <div style={{ textAlign: "left" }}>{info.getValue() === "N" ? t('commissionreport:reservationTeam') : t('commissionreport:online')}</div>,
      header: t('commissionreport:source')
    }),
    columnHelper.accessor("commissionamount", {
      cell: info => <div style={{ textAlign: "right" }}>{(info.getValue()).toFixed(2)}</div>,
      header: t('commissionreport:commissionAmount')
    }),
    columnHelper.accessor("adulttickets", {
      cell: info => <div style={{ textAlign: "right" }}>{info.getValue()}</div>,
      header: t('commissionreport:adultTicket')
    }),
    columnHelper.accessor("adultcommissionamount", {
      cell: info => <div style={{ textAlign: "right" }}>{info.getValue()}</div>,
      header: t('commissionreport:adultRate')
    }),
    columnHelper.accessor("childtickets", {
      cell: info => <div style={{ textAlign: "right" }}>{info.getValue()}</div>,
      header: t('commissionreport:childTicket')
    }),
    columnHelper.accessor("childcommissionamount", {
      cell: info => <div style={{ textAlign: "right" }}>{info.getValue()}</div>,
      header: t('commissionreport:childRate')
    }),
  ];

  const exportCommisstionReport = () => {
    const tmp = (CommissionReportTable.map((l) => {
      if (l.airportid || l.onlineentry) {
        l.airportid = getClubName(l.airportid);
        l.onlineentry = l.onlineentry === "N" ? t('commissionreport:reservationTeam') : t('commissionreport:online')
        l.transactiondate = dateFormatForDisplay(l.transactiondate, "MM/dd/yyyy");
        return omit(l, ['sequenceid', 'pdfexists'])
      }
    }));
    setCommisstionReportExport(tmp);
    return commisstionReportsexport;
  }

  const csv_headers = [
    { key: "airportid", label: t('commissionreport:airport') },
    { key: "productid", label: t('commissionreport:product') },
    { key: "confirmationnumber", label: t('commissionreport:confirmation') },
    { key: "reservationnumber", label: t('commissionreport:reservationNo') },
    { key: "contactname", label: t('commissionreport:contactName') },
    { key: "transactiondate", label: t('commissionreport:transactionDate') },
    { key: "onlineentry", label: t('commissionreport:source') },
    { key: "adulttickets", label: t('commissionreport:adultTicket') },
    { key: "childtickets", label: t('commissionreport:childTicket') },
    { key: "adultcommissionamount", label: t('commissionreport:adultRateExport') },
    { key: "childcommissionamount", label: t('commissionreport:childRateExport') },
    { key: "commissionamount", label: t('commissionreport:commissionAmount') },
  ];

  return (
    <>
      <section>
        <Container className="my-4" ref={divRef}>
          <Loader loading={loading} message={t('commissionreport:loaderMessage')} />
          <div className="my-3">
            <h3 className="d-flex justify-content-center heading heading">
              <span className="heading">{t('commissionreport:commissionReport')}</span>
            </h3>
          </div>

          <div className="my-3 px-1 d-flex justify-content-end">
            {/* <Link to="/" className=" mx-1 text-decoration-none">
              <CommonButton classname="back" title={t('back')} />
            </Link> */}

            <ExitButton />
          </div>

          <Card className="">
            <Card.Body>
              <Row className="d-flex justify-content-start">
                <Col md={5}>
                  <h6 className="field">{t("fillAllFields")}</h6>
                </Col>
              </Row>
              <Form>
                <ErrorMessages formik={formik} />
                <Row>
                  <Col md={4} sm={12}>
                    <Form.Group className="my-2 ">
                      <Form.Label className="d-flex">
                        {t('commissionreport:commissionPeriod')}
                        <span className="field">*</span>
                      </Form.Label>
                      <Select
                        name="commissionPeriod"
                        options={arr_CommissionReportDate}
                        value={formik.values.commissionPeriod}
                        onChange={(v) => { formik.setFieldValue("commissionPeriod", v); handleSubmit(v) }}
                        onBlur={formik.handleBlur}
                        className={formik.touched.commissionPeriod && formik.errors.commissionPeriod ? "error my-0 rounded" : "my-0"}

                      />
                    </Form.Group>
                  </Col>
                  {/* <Col md={"auto"} className="d-flex justify-content-start" style={{ height: "38px", marginTop: "40px" }}>
                    <CommonButton
                      type="submit"
                      classname="gradient-btn "
                      size="md"
                      title={t('submit')}
                    />
                  </Col> */}
                </Row>
              </Form>
              {formSubmitted && (
                <Row>
                  <Col md={12}>
                    <Table columns={columns} data={CommissionReportTable} noDataMessage={t("noDataAvailable")} />
                  </Col>
                </Row>
              )}
              {CommissionReportTable.length > 0 &&
                <Row className="my-3 d-flex justify-content-end">
                  <Col md={"auto"} className="d-flex justify-content-end">
                    <CSVLink
                      filename={"commission_report_" + formik.values.commissionPeriod?.label + ".csv"}
                      asyncOnClick={true}
                      onClick={exportCommisstionReport}
                      headers={csv_headers}
                      data={commisstionReportsexport}
                    >
                      <CommonButton
                        type="button"
                        classname="gradient-btn"
                        title={t('export')}
                      />
                    </CSVLink>
                  </Col>
                </Row>
              }
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
}

export default CommissionReport;
