import { Card, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChild,
  faHotel,
  faMessage,
  faPersonArrowUpFromLine,
  faPersonBreastfeeding,
  faUser,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import CommonButton from "../components/commonComponents/CommonButton";
import { Link } from "react-router-dom";
import Barcode from "../images/gift-cards/barcode.png";
import shop_online from "../images/shop_online.png";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from 'react-i18next';
import { giftCartData as GCD, giftCartDeleteItem, currentgiftCartItemId as GCII } from "../redux/giftCartRedux";
import { useDispatch, useSelector } from "react-redux";
import { selectedMarketCurrency } from "../redux/marketRedux";
import DeleteButton from "../components/commonComponents/DeleteButton";
import { getMarketLoungenamesWithAirport, getProductName, isObjectEmpty } from "../components/commonComponents/commonFunctions";
import HtmlToReactConverter from "../components/commonComponents/HtmlToReactConverter";
import { selectedMarket as currentMarket } from "../redux/marketRedux";


function GiftSummary() {
  const giftCartData = useSelector(GCD);
  const currentgiftCartItemId = useSelector(GCII);
  const selectedMarket = useSelector(currentMarket);

  const dispatch = useDispatch();
  const currencyCode = useSelector(selectedMarketCurrency);

  const marketLoungenameswithairport = getMarketLoungenamesWithAirport(
    selectedMarket?.airports
  );

  const getLoungeNameWithAirpot = (value) => {
    return marketLoungenameswithairport.find(a => a?.value === value)?.label;
  }

  let totalAmount = 0;
  giftCartData.map((e) => {
    totalAmount = totalAmount + e.retailamount;
  });

  const DeleteGiftCardItem = (giftItemKey) => {
    dispatch(giftCartDeleteItem(giftItemKey));
  }

  const { t } = useTranslation(['common']);
  return (
    <>
      <section>
        <Container className="my-3">
          <h3 className="my-3  d-flex justify-content-center heading heading">
            <span className="heading">{t('giftsummary:giftSummary')}</span>
          </h3>

          <div className="my-3  d-flex justify-content-end">
            {giftCartData.length !== 0 &&
              <Link to="/giftcard" state={{ giftCartItemId: currentgiftCartItemId }} className=" mx-1 text-decoration-none">
                <CommonButton classname="back" title={t('back')} />
              </Link>
            }
            <ExitButton />
          </div>

          <Card>
            <Card.Body>
              <Row>
                <Col sm={12} md={12}>
                  <h3>{t('giftsummary:eGiftCardPreview')}</h3>
                </Col>
              </Row>
              {giftCartData?.map((item,i) =>
                <div key={i}>
                  <Row className="my-3">
                    <Col md={4}>
                      <Row>
                      <Col md={12}>
                        <Card.Img
                          variant="top"
                          src={item.selectedCard}
                          className="img-fluid center-block"
                          alt=""
                        />
                        </Col>
                      </Row>
                      <Row className="my-2">
                        <Col md={12}>
                          <div className="my-bg-info">
                                <b>{t('giftsummary:value')}: </b>{currencyCode}{" "}{item.retailamount.toFixed(2)}
                                <br />
                                <b>{t('giftsummary:cardNumber')}: </b> XXXXXXXXXX
                                <br />
                                <b>{t('pin')}: </b> XXXX
                                <br />
                          </div>
                        </Col>
                    </Row>
                    </Col>
                    <Col md={8}>
                      <div className="my-bg-info">
                        <Row>
                          <Col md={12}>
                            <h5 className="mb-2">
                              <b>{t('giftsummary:youREeceivedVipAttractionGiftCard')}</b>
                            </h5>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <h5 className="mb-2">
                              <FontAwesomeIcon
                                icon={faHotel}
                                size="sm"
                                className="me-2 icon-color"
                              />
                              <b>{t('product')}:</b> {getProductName(item.productid)}
                            </h5>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <h5 className="mb-2">
                              <FontAwesomeIcon
                                icon={faHotel}
                                size="sm"
                                className="me-2 icon-color"
                              />
                              <b>{t('airport')}:</b> {getLoungeNameWithAirpot(item.loungeType)}
                            </h5>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <h5 className="mb-2">
                              <FontAwesomeIcon
                                icon={faUser}
                                size="sm"
                                className="me-2 icon-color"
                              />{" "}
                              <b>{t('noOfAdult')}:</b> {item.adult_count.value}
                            </h5>
                          </Col>
                          <Col md={4}>
                            <h5 className="mb-2">
                              <FontAwesomeIcon
                                icon={faChild}
                                size="sm"
                                className="me-2  icon-color"
                              />{" "}
                              <b>{t('noOfChild')}:</b> {item.child_count.value}
                            </h5>
                          </Col>
                          {/* <Col md={4}>
                            <h5 className="mb-2">
                              <FontAwesomeIcon
                                icon={faPersonBreastfeeding}
                                size="sm"
                                className="me-2  icon-color"
                              />{" "}
                              <b>{t('noOfInfant')}:</b> {item.infant_count.value}
                            </h5>
                          </Col> */}
                        </Row>
                        <Row>
                          <Col md={12}>
                            <h5 className="mb-2">
                              <FontAwesomeIcon
                                icon={faPersonArrowUpFromLine}
                                size="sm"
                                className="me-2 icon-color"
                              />
                              <b>{t('from')}:</b> {item.purchase_firstname} {item.purchase_lastname}
                            </h5>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <h5 className="mb-2">
                              <FontAwesomeIcon
                                icon={faMessage}
                                size="sm"
                                className="me-2 icon-color"
                              />
                              <b>{t('giftsummary:personalizedMessage')}:</b>
                            </h5>
                            <div style={{ height: "150px", overflow: "overlay", padding: "5px", border: "1px solid gray" }}>
                              <h6 className="mb-2">{item.message}</h6>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col md={12} className="mb-1">
                      {t('giftsummary:eGiftCardInformationMessage')}
                    
                      <img
                        src={shop_online}
                        width="124"
                        alt="Shop Online"
                        title={t('giftsummary:shopOnline')}
                        className="mx-3"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="my-bg-info">
                        <h5 className="mb-2">
                          <b>{t('giftsummary:eGiftCardTermsCondition')}</b>
                        </h5>
                        <ul style={{ listStyle: "decimal" }}>
                          <li>
                            {t('giftsummary:eGiftCardTermsCondition1')}
                          </li>
                          <li>
                            {t('giftsummary:eGiftCardTermsCondition2')}
                          </li>
                          <li>
                            {t('giftsummary:eGiftCardTermsCondition3')}
                          </li>
                          <li>
                            {t('giftsummary:eGiftCardTermsCondition4')}
                          </li>
                          <li>
                            {t('giftsummary:eGiftCardTermsCondition5')}
                          </li>
                          <li>
                            {t('giftsummary:eGiftCardTermsCondition6')}
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                  <Row className="my-2 d-flex justify-content-start ">
                    <Col md={"auto"} sm={12} className="mb-2 mb-md-0 pe-md-0">
                      <Link to="/giftcard" state={{ giftCartItemId: item.giftCartItemId }} className=" me-1 text-decoration-none">
                        <CommonButton
                          classname="edit-btn me-1"
                          title={t('edit')}
                          icon={
                            <FontAwesomeIcon
                              icon={faPencil}
                              className="mx-1"
                              size="sm"
                              position="right"
                            />
                          }
                        />
                      </Link>
                    </Col>
                    <Col md={"auto"} sm={6} className="mb-2 mb-md-0 px-md-0">
                      <Link className=" me-1 my-sm-auto text-decoration-none">
                        <DeleteButton
                          heading={t('delete')}
                          message={t('deleteMessage')}
                          onClick={() => {
                            DeleteGiftCardItem(item.key)
                          }}
                        />
                      </Link>
                    </Col>
                  </Row>
                  <hr />
                </div>)}
                {
                (giftCartData.length === 0) &&
                <>
                  <Row className="my-2 d-flex justify-content-around align-content-center">
                    <Col>
                      <h4 className="my-bg-info text-center"><HtmlToReactConverter value={t('giftsummary:emptyCartDataDescription')} /></h4>
                    </Col>
                  </Row>
                  {/* <hr /> */}
                </>
              }
            </Card.Body>
          </Card>
        </Container>
      </section>
      {(giftCartData.length !== 0) &&
        <section>
          <Container className="mb-4">
            <Card>
              <Card.Body>
                <Row className="my-2 d-flex justify-content-start ">
                  <Col md={"auto"} sm={12} className="mb-2 mb-md-0 pe-md-0">
                    <Link to="/giftcard" className=" me-1 text-decoration-none">
                      <CommonButton
                        classname=" gradient-btn "
                        title={t('giftsummary:addAnothorGiftCard')}
                      />
                    </Link>
                  </Col>
                  <Col md={"auto"} sm={6} className="mb-2 mb-md-0 px-md-0">
                    <Link to="/gift-payment" className=" me-1 my-sm-auto text-decoration-none">
                      <CommonButton classname=" gradient-btn " title={t('checkout')} />
                    </Link>
                  </Col>
                  <Col className="d-flex justify-content-end ">
                    <h4>{t('grandTotal')}: {currencyCode}{" "}{totalAmount.toFixed(2)}</h4>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
        </section>
      }
    </>
  );
}

export default GiftSummary;
