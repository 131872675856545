import i18n from "i18next";
import PriorityPassCardImg from "../images/Priority_Pass.jpg";
import DigicelBlackCardImg from "../images/Digicel-black-card.jpg";
import DragonCardImg from "../images/Dragon-Pass-Card.png";
import { addYears, subYears } from 'date-fns';
import { store } from "../store/store";
const state = store.getState();

export const CARD_NUMBER_MAXLENGTH = 16;
export const CONTACT_NUMBER_MAXLENGTH = 20;
export const CARDHOLDERNAME_MAXLENGTH = 32;
export const CARD_EXP_MONTHLENGTH = 2;
export const CARD_EXP_YEARLENGTH = 4;
export const CARD_CVV_LENGTH = 3;
export const NAME_LENGTH = 50;
export const FIRST_NAME_MAXLENGTH = 49;
export const LAST_NAME_MAXLENGTH = 49;
export const EMAIL_MAXLENGTH = 50;
export const ADDRESS_MAXLENGTH = 100;
export const IATA_NO_MAXLENGTH = 8;
export const BUSINESSNAME_MAXLENGTH = 100;
export const MANUAL_AUTH_CARD_NUMBER_MAXLENGTH = 4;
export const MEMBERCARD_NUMBER_MAXLENGTH = 16;
export const MEMBERCARD_PIN_MAXLENGTH = 4;
export const PARTNER_REFERENCE_NUMBER_MAXLENGTH = 20;
export const GREET_NAME_CHARACTER_MAXLENGTH = 80;
export const COMMENT_TEXTAREA_MAXLENGTH = 4000;
export const GIFTCARD_MESSAGE_TEXTAREA_MAXLENGTH = 1000;
export const USER_NAME_MAXLENGTH = 20;
export const STANDBY_TRACKINGID_MAXLENGTH = 7;
export const STANDBY_CONFIRMATIONID_MAXLENGTH = 6;
export const SPECIAL_OCCASSION_TEXT_AREA_MAXLENGTH = 200;
export const PASSWORD_MAXLENGTH = 20;
export const MANUAL_AUTH_AUTHORIZATION_NUMBER_MAXLENGTH = 10;
export const AIRLINE_MAXLENGTH = 30;
export const FLIGHT_NUMBER_MAXLENGTH = 10;
export const USERNAME_MAXLENGTH = 32;
export const SINGLE_BOOKING_MAX_COUNT_DEPARTURE = 25;
export const UPDATE_FLIGHT_CONFIRMATIONID_MAXLENGTH = 7;
export const PRIORITY_PASS_CARD_NUMBER_MAXLENGTH = 4;
export const GIFT_CARD_NUMBER_MAXLENGTH = 6;
export const GIFT_CARD_PIN_MAXLENGTH = 6;
export const GROUP_BOOKING_MAXLENGTH = 50;
export const GIFT_FIRST_NAME_MAXLENGTH = 40;
export const GIFT_LAST_NAME_MAXLENGTH = 40;
export const CONFIRMATION_NUMBER_MAXLENGTH = 9;

export const PRODUCTID_ARRIVAL = "ARRIVALONLY";
export const PRODUCTID_DEPARTURE = "DEPARTURELOUNGE";
export const PRODUCTID_ARRIVALBUNDLE = "ARRIVALBUNDLE";
export const PRODUCTID_PRIORITYPASS = "DEPARTUREADMIN";
export const PRODUCTID_DIGICELBLACKCARD = "DIGICELBLACKCARD";
export const PRODUCTID_DRAGONPASS = "DRAGONPASSCARD";
export const PRODUCTID_ALL = "ALL";

export const ROUTENAME_ARRIVAL = "product-arrival";
export const ROUTENAME_DEPARTURE = "product-departure";
export const ROUTENAME_ARRIVALBUNDLE = "product-bundle";
export const ROUTENAME_PRIORITYPASS = "product-priority-pass-departure";
export const ROUTENAME_DIGICELBLACKCARD = "product-digicel-black-card-departure";
 
export const ROUTENAME_DRAGONPASSCARD = "product-dragon-card-departure";
 
export const PRODUCTNAME_ALL = "All Products";
export const PRODUCTNAME_ARRIVAL = "Arrival Only";
export const PRODUCTNAME_DEPARTURE = "Departure Lounge";
export const PRODUCTNAME_ARRIVALBUNDLE = "Arrival Bundle";

// export const PRODUCTNAME2_ARRIVAL = "ARRIVAL ONLY";
// export const PRODUCTNAME2_DEPARTURE = "DEPARTURE LOUNGE";
// export const PRODUCTNAME2_ARRIVALBUNDLE = "ARRIVAL & DEPARTURE";

const VIPER_URL = "https://viperapp.onvipattractions.com/";
const VIPER_URL_LOGWS = "https://viperlog.onvipattractions.com";


export const VIPER_CONST = {
  base_url: VIPER_URL + "VIPERWS/",
  alwaysOnUsername: "esite3@viponline",
  alwaysOnSessionid: "00009223581026309436128527",
};

export const VIPER_LOG_CONST = {
  base_url: VIPER_URL_LOGWS+"/LOGWS/"
}

export const FLAG_URL = VIPER_URL + "images/flags/";
export const LOGOS_URL = VIPER_URL + "images/logos/";
export const BILLING_URL = VIPER_URL + "billing/";
export const BASE_URL = "/";

export const LOGIN_TYPE = {
  priority_pass: "prioritypass",
  digicel_black_card: "digicelblackcard",
  regular: "regular",
  dragon_pass: "dragonpass"
};

export const CUSTOMER_LOGIN = {
  username: "esite3@viponline",
  password: "0c817deb97b5c89929869addd02785c1",
};

export const PRIORITYPASS_LOGIN = {
  username: "agent@prioritypass",
  password: "935c840ef2554a9c14d98cc055628f0d",
};

export const DIGICELBLACKCARD_LOGIN = {
  username: "agent@digiexecca",
  password: "9cd6f48b29f5417c7d14d8d3fcf22ce4",
};

export const DRAGONPASSCARD_LOGIN = {
  username: "ragent@dragonpass",
  password: "5f4dcc3b5aa765d61d8327deb882cf99",
};

export const PEOPLE_COUNT = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
];

export const LOUNGE_TYPE = [
  { value: "A", label: "Arrival" },
  { value: "D", label: "Departure" },
];

export const STAR_RATING_TOOLTIP_ARRAY = [
  "Poor",
  "Fair",
  "Average",
  "Good",
  "Excellent",
];

export const STAR_RATING_FILLCOLOR_ARRAY = [
  "#f17a45",
  "#f19745",
  "#f1a545",
  "#f1b345",
  "#f1d045",
];

export const STAR_RATING_STAR_SIZE = 40;

// export const ADULT_AGE_TEXT = "(above 12)";
// export const CHILD_AGE_TEXT = "(2-12)";
// export const INFANT_AGE_TEXT = "(under 2)";

export const MEGNA_CARD_CONTACT = "1-800-744-3733";
export const MEGNA_CARD_CONTACT2 = "+1(876)968-9975";
export const MEGNA_CARD_WHATSAPP_CONTACT = "+1 (876) 870-7738";
export const MEGNA_CARD_EMAIL = "customercareja@magnarewards.com";
export const VIP_MEMBERSHIP_CARD_CONTACT = "1-876-619-1565";
export const VIP_MEMBERSHIP_CARD_CONTACT2 = "1-954-837-6290";
export const VIP_MEMBERSHIP_CARD_EMAIL = "amc.operations@vipattractions.com";

export const SALUTATION = [
  { value: "MR", label: "Mr." },
  { value: "MRS", label: "Mrs." },
  { value: "MISS", label: "Miss" },
  { value: "MASTER", label: "Master" },
];

export const CC_EXPIRY_YEAR_DROPDOWN_LIST_LIMIT = 23;

export const ORDER_REPORT_FILTERBY = [
  { value: "N", label: "Travel Date" },
  { value: "Y", label: "Entry Date" },
];

export const ARR_PRODUCTS = [
  { value: PRODUCTID_ARRIVAL, label: PRODUCTNAME_ARRIVAL },
  { value: PRODUCTID_DEPARTURE, label: PRODUCTNAME_DEPARTURE },
  { value: PRODUCTID_ARRIVALBUNDLE, label: PRODUCTNAME_ARRIVALBUNDLE },
];

export const AFFILIATE_COOKIE_EXPIRE_TIME = 30; // in days
export const PROMOTION_KEY_EXPIRY_TIME = 1; // in days
export const COOKIE_EXPIRE_TIME = 365; // in days

export const ARRAY_YES_NO = [
  { value: "N", label: "No" },
  { value: "Y", label: "Yes" },
];

export const PARTNER_BUSSINESS_TYPES = [
  { value: "AIRLINEPARTNER", label: "Airline Partners" },
  { value: "CARRENTAL", label: "Car Rental" },
  { value: "CORPORATELOCAL", label: "Corporate - Local" },
  { value: "HOTELKGN", label: "Hotel (Kingston)" },
  { value: "HOTEL", label: "Hotel" },
  { value: "LOUNGEPARTNER", label: "Lounge Partner - Cards" },
  { value: "ONLINESEARCHENGINE", label: "Online - Search Engines" },
  { value: "RESTAURANT", label: "Restaurant" },
  { value: "TOURCOMPANY", label: "Tour Company" },
  { value: "TOUROPERATORINTL", label: "Tour Operator - Intl" },
  { value: "TOURLOCAL", label: "Tour Operator - Local" },
  { value: "TOURSANDATTRACT", label: "Tours & Attraction" },
  { value: "TRAVELAGENTINTL", label: "Travel Agent - Intl" },
  { value: "TRAVELAGENTLOCAL", label: "Travel Agent - Local" },
  { value: "VILLA", label: "Villa" },
];

export const REGISTRATION_PLACE = [
  { value: "HEADOFFICE", label: "Head Office" },
  { value: "BRANCH", label: "Branch" },
];

export const VIPATTRACTIONSURL = "http://www.vipattractions.com/";

export const MANAGEUSER_STATUS = [
  { value: "A", label: "Active" },
  { value: "I", label: "Inactive" },
];

export const RESERVATION_SOURCE = "O";

export const RESTRICTED_PATHNAME_FOR_AUTOLOGIN = [
  /^\/$/,
  /^\/subscriber-login$/,
  /^\/login$/,
  /^\/partner(?:\/\d+)?$/,
  /^\/success$/,
  /^\/failure$/,
  /^\/feedback$/,
  /^\/feedback-success$/,
  /^\/subscriber-registration-success$/,
  /^\/common-success$/,
  /^\/standby-success$/,
  /^\/purchaseMembership-success$/,
  /^\/gift-redeem-success$/,
  /^\/product-priority-pass-departure$/,
  /^\/product-digicel-black-card-departure$/,
  /^\/gift-payment-success$/,
  /^\/market$/,
  /^\/standby-arrival$/,
  /^\/standby-departure$/,
  /^\/standby-bundle$/,
  /^\/product-digicel-black-card-bundle$/,
  /^\/product-priority-pass-arrival$/,
  /^\/product-dragon-card-departure$/,
  /^\/product-digicel-black-card-arrival$/,
  /^\/manual-booking$/,
];

export const ADULT_MINIMUM = 12;
export const CHILD_MINIMUM = 2;

export const LOGIN_BUTTON_SHOW_TO_USERS = [
  "esite3@viponline",
  "agent@prioritypass",
  "agent@digiexecca",
];

export const GROUP_BOOKING_ADULT_COUNT = 1;
export const GROUP_BOOKING_CHILD_COUNT = 25;
export const GROUP_BOOKING_INFANT_COUNT = 25;
export const GROUP_BOOKING_MINIMUM_PAXCOUNT = 25;

export const DEFAULT_CURRENCYCODE = "USD";

export const AIRPORTS = [
  { key: "NMIA", name: "Norman Manley International Airport" },
  { key: "SIA", name: "Sangster International Airport" },
  { key: "GCM", name: "Owen Roberts Intl" },
  { key: "BGI", name: "Grantley Adams Intl" },
  { key: "NAS", name: "Lynden Pindling Intl" },
  { key: "SKB", name: "Robert L. Bradshaw Intl" },
];

export const PASSWORD_POLICY = {
  minLength: 8,
  upperCase: {
    length: 2,
  },
  lowerCase: {
    length: 3,
  },
  specialCharacter: {
    length: 1,
    characters: "!@#$%^&*()",
  },
  digit: {
    length: 2,
  },
};

export const EMAIL_POLICY = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);

export const ORIGIN = window.location.origin;

export const BC_SESSION = "bc_vacationpriorities";


export const VALIDATE_LOGIN_DATA = [
  {
    product:"Priority Pass",
    productAllowed:"prioritypassallowed",
    userName:"agent@prioritypass"
  }
  ,{
    product:"Digicel Card",
    productAllowed:"digicelcardallowed",
    userName:"agent@digiexecca"
  }
];

export const TRAVEL_CHECK_MONTHS = 10;
export const CAPTCHA_SITEKEY = "6LcKL6EcAAAAAG46vpaj4uNORKyPNuArK0d2vyWs"; // gajendrabang@gmail.com
export const MIX_PANEL_PROJECT_TOKEN = "8fa7e2e542f60850356ed6917ff2825f"


export const MEMBERCARD_DROPDOWN_TEXT = "VIP Airport Lounge Membership";
export const MEMBERCARD_CARD_VALUE_LABEL = "Membership Value";
export const MEMBERCARD_CARD_TYPE_LABEL = "Membership Type";
export const MEMBERCARD_CARD_NUMBER_LABEL = "Membership Number";
export const MEMBERCARD_LABEL = "VIP AIRPORT LOUNGE MEMBERSHIP";

// export const MEMBERCARD_DROPDOWN_TEXT = i18n.t('constants:memberCardDropDownText');
// export const MEMBERCARD_CARD_VALUE_LABEL = i18n.t('constants:memberCardValueLabel');
// export const MEMBERCARD_CARD_TYPE_LABEL = i18n.t('constants:memberCardTypeLabel');
// export const MEMBERCARD_CARD_NUMBER_LABEL = i18n.t('constants:memberCardNumberLabel');
// export const MEMBERCARD_LABEL = i18n.t('constants:memberCardLabel');

export const EXTERNAL_LINKS = [
  /^\/link-booking\/([^/]+)\/([^/]+)\/([^/]+)$/,
  /^\/update-booking\/([^/]+)$/,
  /^\/credit-booking\/([^/]+)$/,
  /^\/unlock-member-card\/([^/]+)$/,
  /^\/member-card-voucherdiscount\/([^/]+)$/,
  /^\/gift-auth$/,
  /^\/stand-by$/,
  /^\/registration\/([^/]+)$/,
  /^\/affiliate\/([^/]+)$/,
  /^\/market$/,
  /^\/referrer$/,
  /^\/manual-booking$/,
]

export const STAND_BY_LINKS = [
  /^\/standby-arrival$/,
  /^\/standby-bundle$/,
  /^\/standby-departure$/,
]
export const REGEX_ALPHANUMERIC_WITHOUT_SPACE = new RegExp(/[^0-9a-zA-Z]+/ig);
export const REGEX_ALPHABET = new RegExp(/[^a-z A-Z]+/ig);
export const REGEX_NUMERIC = new RegExp(/[^0-9]/g);
export const REGEX_ALPHANUMERIC_WITH_SPACE = new RegExp(/[^0-9a-z A-Z]+/ig);
export const GIFT_CARD_VALID_UPTO_MONTHS = 12;
export const PROMOTION_LINK = /^\/promotion\/([^/]+)$/;
export const RESTRICTED_LOGIN_LINK = [/^\/affiliate\/([^/]+)$/, /^\/promotion\/([^/]+)$/];

export const FLIGHT_YEARS = 2;
export const MAX_FLIGHT_DATE = addYears(new Date(), FLIGHT_YEARS);

export const GIFT_CARD_VALID_UPTO_YEARS = 1;
export const MAX_GIFT_CARD_VALID = addYears(new Date(), GIFT_CARD_VALID_UPTO_YEARS);

export const DOB_YEARS = 100;
export const MAX_DOB_DATE = subYears(new Date(), DOB_YEARS);

export const LOUNGE_PARTNERS_PRODUCTS = [
  // {
  //   product: "Arrival Priority Pass",
  //   productAllowed: "prioritypassallowed",
  //   userName: "agent@prioritypass",
  //   productId: "ARRIVALADMIN",
  //   route: "product-priority-pass-arrival",
  //   image: PriorityPassCardImg,
  //   direction: "A",
  //   productType: "Arrival",
  //   loginType: LOGIN_TYPE.priority_pass,
  //   obiSource: "OBI-PRIORITYPASS"
  // },
  {
    product: "Departure Priority Pass",
    productAllowed: "prioritypassallowed",
    userName: "agent@prioritypass",
    productId: PRODUCTID_PRIORITYPASS,
    route: ROUTENAME_PRIORITYPASS,
    image: PriorityPassCardImg,
    direction: "D",
    productType: "Departure",
    loginType: LOGIN_TYPE.priority_pass,
    obiSource: "OBI-PRIORITYPASS"
  },
  // {
  //   product: "Arrival Bundle Digicel Card",
  //   productAllowed: "digicelcardallowed",
  //   userName: "agent@digiexecca",
  //   productId: "ARRIVALBUNDLEDIGICELBLACKCARD",
  //   route: "product-digicel-black-card-bundle",
  //   image: DigicelBlackCardImg,
  //   direction: "A",
  //   productType: "ArrivalBundle",
  //   loginType: LOGIN_TYPE.digicel_black_card,
  //   obiSource: "OBI-DIGICELBLACK"
  // },
  // {
  //   product: "Arrival Digicel Card",
  //   productAllowed: "digicelcardallowed",
  //   userName: "agent@digiexecca",
  //   productId: "ARRIVALDIGICELBLACKCARD",
  //   route: "product-digicel-black-card-arrival",
  //   image: DigicelBlackCardImg,
  //   direction: "A",
  //   productType: "Arrival",
  //   loginType: LOGIN_TYPE.digicel_black_card,
  //   obiSource: "OBI-DIGICELBLACK"
  // },
  {
    product: "Departure Digicel Card",
    productAllowed: "digicelcardallowed",
    userName: "agent@digiexecca",
    productId: PRODUCTID_DIGICELBLACKCARD,
    route: ROUTENAME_DIGICELBLACKCARD,
    image: DigicelBlackCardImg,
    direction: "D",
    productType: "Departure",
    loginType: LOGIN_TYPE.digicel_black_card,
    obiSource: "OBI-DIGICELBLACK"
  },
  // {
  //   product: "Dragon Pass",
  //   productAllowed: "digicelcardallowed", // temp this need to be change
  //   userName: "ragent@dragonpass",
  //   productId: PRODUCTID_DRAGONPASS, // temp this need to be change
  //   route: ROUTENAME_DRAGONPASSCARD,
  //   image: DragonCardImg,
  //   direction: "D",
  //   productType: "Departure",
  //   loginType: LOGIN_TYPE.dragon_pass,
  //   obiSource: "OBI-DIGICELBLACK" // temp this need to be change
  // }
];

export const DEFAULT_COOKIE = {
  "necessary": true,
  "functional": true
}

export const IS_PAYMENT_MODAL="iframe";

export const PARTNER_LOGIN_EXCLUSION = [
  "esite3@viponline",
  "agent@prioritypass",
  "agent@digiexecca"
];

export const AFFILIATE_SHOW_LINKS_REGEX = [
  /^\/$/,
  /^\/product-arrival$/,
  /^\/product-departure$/,
  /^\/product-bundle$/,
  /^\/registration$/,
  /^\/bookingSummary$/,
  /^\/payment$/,
  /^\/affiliate\/([^/]+)$/,
  /^\/success$/
];
export const REGULAR_PRODUCTS = [
  {
    route: ROUTENAME_ARRIVALBUNDLE,
    productId: PRODUCTID_ARRIVALBUNDLE,
  },
  {
    route: ROUTENAME_ARRIVAL,
    productId: PRODUCTID_ARRIVAL,
  },
  {
    route: ROUTENAME_DEPARTURE,
    productId: PRODUCTID_DEPARTURE,
  }
];

export const SEARCH_PARTNER = [
  { value: "A", label: "Confirmation Number" },
  { value: "B", label: "Reference Number" },
  { value: "C", label: "Contact Details" },
];
export const SESSION ={
  extendConfigrationBefore:5,
  extendModalLimit:3,
  sessionTime:55
}
export const PAGE_SIZE = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 30, label: '30' },
  { value: 40, label: '40' },
  { value: 50, label: '50' }
];  

export const TERMS_AND_CONDITIONS_FOLDER_PATH  = "static/terms-conditions/"

export const PRIVATE_AESKEY = "pnFCdYBqZwtbOSKvi8WGKA=="

export const IV_RANDOM_NUMBER = 16

export const CONFIRMATION_APIS = ["confirmcart", "addbillpayment", "confirmcreditbooking", "addgift", "addmembercard", "convertstandby", "topupaccount", "topupmembercard"];

export const SECRET_KEY = "H@8aAn@eTh)99]B"
export const SECRET_KEY_REDUX = "oV8'HA_NfD@(4Y~"
export const GA4_MEASUREMENT_ID = "G-B8EX5N6BQH"

export const TESTING_URLS = [
  "localhost",
  "obireact.mentem.in",
  "obi.reliablesoftjm.com",
  "obiuat.reliablesoftjm.com"
]

export const GROUP_BOOKING_TEMPLATE_PATH  = "static/tmp-group-booking-template.xlsx"
