import { Col, Modal, Row } from "react-bootstrap";
import CommonButton from "./CommonButton";
import { useTranslation } from "react-i18next";
import { createColumnHelper } from "@tanstack/react-table";
import { Table } from "./Table";
import React, { useEffect, useState } from "react";
// import { getmappedmembercards } from "../../redux/apiCalls";
import { myDateFormat, getLoggedInUserMappedMemberCards, getLoginDetails, translateConstantText } from "../commonComponents/commonFunctions"
import { MEMBERCARD_CARD_NUMBER_LABEL, MEMBERCARD_CARD_TYPE_LABEL } from "../../config/commonConstants";
import { logindetails as LD } from "../../redux/signInRedux";
import { useSelector } from "react-redux";
// import { cartData as CD } from "../../redux/cartRedux";
// import { useSelector } from "react-redux";

export default function SetLinkCardModal(props) {
  const { t } = useTranslation([
    "common",
    "payment",
    "memberandcardinformation",
  ]);
  // const cartData = useSelector(CD);
  const [selectedRow, setSelectedRow] = useState(null);
  const [linkCardData, setLinkCardData] = useState([]);
  const columnHelper = createColumnHelper();
  const hasMemberCard = true;
  const loginDetails = useSelector(LD);
  const columns = [
    columnHelper.accessor("cardselect", {
      cell: (info) => (
        <div style={{ textAlign: "left" }}>
          <input
            type="radio"
            name="selectSavedCard"
            id="selectSavedCard"
            value={info.row.original}
            checked={selectedRow === info.row.original}
            onChange={() => setSelectedRow(info.row.original)}
          />
        </div>
      ),
      header: t("memberandcardinformation:cardselect"),
    }),
    columnHelper.accessor("cardname", {
      cell: (info) => <div style={{ textAlign: "left" }}>{info.getValue()}</div>,
      header: t('memberandcardinformation:cardType',{membershipType: translateConstantText("MEMBERCARD_CARD_TYPE_LABEL")}),
    }),
    columnHelper.accessor("memberid", {
      cell: ({ getValue }) => <div style={{ textAlign: "left" }}>{getValue().match(/\d+/)}</div>,
      header: t('memberandcardinformation:cardNumber',{membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL")}),
      className: "right",
    }),
    columnHelper.accessor("membername", {
      cell: (info) => <div style={{ textAlign: "left" }}>{info.getValue()}</div>,
      header: t("memberandcardinformation:memberName"),
    }),
    columnHelper.accessor("balance", {
      cell: (info) => (info.row.original.showvalue === "Y" ?
        (<div style={{ textAlign: "right" }}>{info.getValue().toFixed(2)}</div>) :
        (<div style={{ textAlign: "right" }}>{myDateFormat(info.row.original.expirydate, "yyyyMMdd")}</div>)),
      header: t("memberandcardinformation:balance"),
    }),
  ];

  // const fetchData = async () => {
  //   let arr_Request = {}
  //   let arr_Cart = {};

  //   cartData?.map((product) => {
  //     const cartItem = product?.value.currentCartItem;
  //     const productid = cartItem?.productid.toLowerCase();
  //     arr_Cart[productid] = "Y";
  //   });

  //   arr_Request["cart"] = arr_Cart;
  //   var data = JSON.parse(JSON.stringify(arr_Request));

  //   try {
  //     const response = await getmappedmembercards(data);
  //     setLinkCardData([...response?.data]); // Create a new array with the fetched data
  //   } catch (error) {
  //     // console.error("Error fetching data:", error);
  //   } finally {
  //   }
  // };

  const fetchMappedMemberCards = async () => {
    const response = await getLoggedInUserMappedMemberCards(props?.productId);
    setLinkCardData(response);
  }

  useEffect(() => {
    getLoginDetails()?.sessionid && fetchMappedMemberCards();
  }, [loginDetails]);

  const handleData = () => {
    if (selectedRow) {
      props.setFromSelectedMemberCard(true)
      props.setStatus(null)
      props.onRowSelect(selectedRow);
      props.cancelModal()
    }
  };

  return (
    <>
      <Modal
        show={props.visible}
        onHide={props.cancelModal}
        size="md"
        style={{ zIndex: "9998" }}
        centered
        className="modal-lg"
        backdrop="static"
      >
        <Modal.Header closeButton><h3 className="fw-bold modalHeading">{t("memberCards")}</h3></Modal.Header>

        {hasMemberCard ? (
          // Render the table if data is available
          <Row className="p-3">
            <Col md={12}>
              <Table
                columns={columns}
                data={linkCardData}
                noDataMessage={t("noDataAvailable")}
              />
            </Col>
          </Row>
        ) : (
          // Render the message if no data is available
          <Row>
            <Col md={12} className="d-flex justify-content-center">
              <h3>{t("noMembercardLinked")}</h3>
            </Col>
          </Row>
        )}
        <Row className="p-3">
          <Col className="d-flex justify-content-end">
            <CommonButton
              classname="gradient-btn mx-1"
              title={t('select')}
              onclick={handleData}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
}
