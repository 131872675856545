import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate, Link, useOutletContext, useLocation } from "react-router-dom";
import CommonButton from "../components/commonComponents/CommonButton";
import { useEffect, useState } from "react";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from 'react-i18next';
import { authenticateStandby, getStandbybookings } from "../redux/apiCalls";
import Loader from "../components/commonComponents/Loader";
import { Table } from "../components/commonComponents/Table";
import { createColumnHelper } from "@tanstack/react-table"
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getProductName, HandleAPIError, isObjectEmpty } from "../components/commonComponents/commonFunctions";
import { showError } from "../components/commonComponents/Swal";
import MarketSelect from "../components/commonComponents/MarketSelect";
import { useSelector } from "react-redux";
import { selectedMarketId as SM } from "../redux/marketRedux";
import { logindetails as LD } from "../redux/signInRedux";

function StandByBookingList() {
  const { t } = useTranslation(['common', 'standbybookinglist']);
  const [StandByBooking, setStandByBooking] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const sendBroadcastMessage = useOutletContext();
  const selectedMarketId = useSelector(SM);
  const location = useLocation();
  const loginDetails = useSelector(LD);

  const standBYBookings = async (marketId) => {
    setLoading(true);
    try {
      const request = { 
        "marketid": marketId
      }
      const response = await getStandbybookings(request)
      if (response?.status === 0) {
        setStandByBooking(response?.data);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    loginDetails?.sessionid && standBYBookings(selectedMarketId);
  }, [loginDetails]);

  const handleSubmit = async (data) => {
    try {
      setLoading(true)
      const apiData = {
        "standbyid": data.standbyid,
        "confirmationnumber": data.confirmationnumber,
      }
      const response = await authenticateStandby(apiData);
      if (response === "") {
        showError(t("error"), t('standbybookinglist:invalidIdErrorMessage'));
      } else {
        if (response.status === 0) {
          navigate("/standby", { state:{ response:response, navigatedFrom : "StandByBookingList"}});
        }
        else {
          showError(t("error"), response?.statusMessage);
        }
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  };

  const columnHelper = createColumnHelper();

  const COLUMNS = [
    columnHelper.accessor("standbyid", {
      cell: info => <div style={{ textAlign: "left" }}>{info.getValue()}</div>,
      header: t('standbybookinglist:tracking'),
    }),
    columnHelper.accessor("productid", {
      cell: info => <div style={{ textAlign: "left" }}>{getProductName(info.getValue())}</div>,
      header: t('standbybookinglist:product'),
      className: "right"
    }),
    columnHelper.accessor("customername", {
      cell: info => <div style={{ textAlign: "left" }}>{info.getValue()}</div>,
      header: t('standbybookinglist:customerName'),
    }),
    columnHelper.accessor("paxcount", {
      cell: info => <div style={{ textAlign: "left" }}>{info.getValue()}</div>,
      header: t('standbybookinglist:pax'),
    }),
    columnHelper.accessor("status", {
      cell: info => <div style={{ textAlign: "left" }}>{info.getValue()}</div>,
      header: t('standbybookinglist:status'),
    }),
    columnHelper.accessor("view", {
      cell: info => (
        <div style={{ textAlign: "center" }}>
          {info.row.original.confirmationnumber !== "0" &&
          <FontAwesomeIcon
            className="ms/1 pointer icon-color"
            icon={faBars}
            size="lg"
            onClick={() => handleSubmit(info.row.original)}
          />
        }
        </div>
      ),
      header: "",
      enableSorting: false
    }),
  ]
  return (
    <>
      <section>
        <Container className="my-4">
          <Loader loading={loading} message={t('standbybookinglist:loaderMessage')} />
          <div className="my-3">
            <h3 className="d-flex justify-content-center heading heading">
              <span className="heading">{t('standbyBooking')}</span>
            </h3>
          </div>

          <div className="my-3 px-1 d-flex justify-content-end">
            <ExitButton />
          </div>

          <Card className="">
            <Card.Body>
            <MarketSelect
                sendBroadcastMessage={sendBroadcastMessage}
                initialCallbackFunction={() => {
                  setStandByBooking([]);
                }}
                callbackFunction={async (marketId) => {
                  standBYBookings(marketId);
                }}
                zIndex={false}
                location={location}
                editLabel={true}
              />
              <Row>
                <Col md={12}>
                  <Table columns={COLUMNS} data={StandByBooking} noDataMessage={t("noDataAvailable")} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
}

export default StandByBookingList;