import { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChild,
  faCircleInfo,
  faPersonBreastfeeding,
  faUser,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import CommonButton from "../components/commonComponents/CommonButton";
import AdultDetails from "../components/commonComponents/AdultDetails";
import ChildDetails from "../components/commonComponents/ChildDetails";
import ContactDetails from "../components/commonComponents/ContactDetails";
import { DIGICELBLACKCARD_LOGIN, EMAIL_POLICY, PRIORITYPASS_LOGIN, PRODUCTID_ARRIVALBUNDLE, PRODUCTID_DEPARTURE, PRODUCTID_DIGICELBLACKCARD, PRODUCTID_PRIORITYPASS, SALUTATION } from "../config/commonConstants";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectedMarket as currentMarket, selectedMarketCurrency } from "../redux/marketRedux";
import { dateFormatForDisplay, getMarketLoungenames, checkLoggedInUserIsLoungePartner, getLoggedInUsername, urlForRedirect, myDateFormat, getMarketLoungenamesWithAirport, translateArrayKeyValue, renderErrorMessages, divRef, scrollToDiv, checkCartItemUse, secondaryContactDetailsSchemaToggle, getProductId, validExpiryOrNot, getDistributorSubDistributorProfile, isObjectEmpty, isloungePartnerProductFromUserName, loungePartnerProductOperation, setDemographicInformationToRedux } from "../components/commonComponents/commonFunctions";
import { arrivaldata, departuredata } from "../redux/flightScheduleRedux";
import { cartUpdateData } from "../redux/cartRedux";
import { specialOccasions as SO } from "../redux/specialOccasionsRedux";
import { getspecialoccassions, setContact } from "../redux/apiCalls";
import { showError } from "../components/commonComponents/Swal";
import Loader from "../components/commonComponents/Loader";
import { getCurrentMonth, HandleAPIError } from "../components/commonComponents/commonFunctions";
import PriorityPassCard from "../components/commonComponents/PriorityPassCard";
import { cartData as CD } from "../redux/cartRedux";
import { paxCountBookingDetails as PCD } from "../redux/increasePaxCountRedux";
import { isEmptyArray, useFormik } from "formik";
import * as Yup from "yup";
import { logindetails as LD } from "../redux/signInRedux";
import ErrorMessages from "../components/commonComponents/ErrorMessages";
import { currentCart as CURR_CART } from "../redux/cartRedux";
import GreetSign from "../components/commonComponents/GreetSign";
import { demographicInformation as DI, clearDemographicInformation } from "../redux/demographicInformationRedux";


export default function BookingRegistration() {
  const paxCountBookingDetails = useSelector(PCD);
  const currentCart = useSelector(CURR_CART);
  const [isTitleChangeable,setIsTitleChangeable] = useState(true);
  const [salutation, setSalutation] = useState(translateArrayKeyValue(SALUTATION, "SALUTATION"))
  const location = useLocation();
  const propsData = location.state;
  const productid = propsData?.productid ? propsData?.productid : currentCart?.value?.currentCartItem?.productid;
  const currencyCode = useSelector(selectedMarketCurrency);
  const initialAdult = {
    title: salutation[0],
    firstName: "",
    lastName: "",
    email: "",
    dob: "",
    errors: {},
  };

  const initialChild = {
    title: salutation.filter(x => x.value === "MASTER")[0],
    firstName: "",
    lastName: "",
    dob: null,
    errors: {},
  };

  const initialGreetSign = {
    name: "",
    occasion: "",
    occasionDetail: "",
    errors: {},
  };

  const initialPrimaryContact = {
    heading: "",
    title: salutation[0],
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    phone: "",
    errors: {},
  };

  const initialSecondaryContact = {
    heading: "",
    title: salutation[0],
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    phone: "",
    errors: {},
  };

  const { t } = useTranslation(["common", "registration", "greetSign", "feedback"]);

  const initial = {
    adults: [],
    childs: [],
    infants: [],
    greetingDetails: [],
    primaryContactDetails: {
      primaryContactDetailsheading: "",
      title: salutation[0],
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
      phone: "",

    },
    secondaryContactDetails: {
      heading: "",
      title: salutation[0],
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
      phone: "",

    },
    priorityPassDetails: {
      cardType: "Priority Pass",
      name: "",
      cardNumber: "",
      cardMonth: getCurrentMonth(),
      cardYear: new Date()?.getFullYear()?.toString(),

    }
  };
  function validationFunction (){
  const validationSchema = Yup.object().shape({

    adults: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().trim().required(t("registration:errors.adultFirstName.required")),
        lastName: Yup.string().trim().required(t("registration:errors.adultLastName.required")),
        // email: Yup.string()
        //   .required(t("registration:errors.adultEmail.required"))
        //   .matches(EMAIL_POLICY, t("registration:errors.adultEmail.match"))
      })
    ),
    childs: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().trim().required(t("registration:errors.childFirstName.required")),
        lastName: Yup.string().trim().required(t("registration:errors.childLastName.required")),
        dob: Yup.string().trim().required(t("registration:errors.childDob.required")),
      })),
    infants:
      getProductId(productid) !== PRODUCTID_DEPARTURE &&
      Yup.array().of(
        Yup.object().shape({
          firstName: Yup.string().trim().required(t("registration:errors.infantFirstName.required")),
          lastName: Yup.string().trim().required(t("registration:errors.infantLastName.required")),
          dob: Yup.string().trim().required(t("registration:errors.infantDob.required")),
        })),
    greetingDetails: Yup.array().of(
      Yup.object().shape({
        occasionDetail: Yup.string()
          .test(
            'occasionDetail-validate',
            t("registration:errors.greetingDetailsOccasionDetail.required"),
            occasionDetail => {
              let flag = formik?.values?.greetingDetails[0]?.occasion?.value === "OTHER" && formik?.values?.greetingDetails[0]?.occasionDetail === "" ? true : false;
              if (flag) {
                return false
              }
              return true
            }
          ),
      })),
    primaryContactDetails:
    isObjectEmpty(paxCountBookingDetails) && Yup.object().shape({
        firstName: Yup.string().trim().required(t("registration:errors.primaryContactFirstName.required")),
        lastName: Yup.string().trim().required(t("registration:errors.primaryContactLastName.required")),
        email: Yup.string()
          .trim().required(t("registration:errors.primaryContactEmail.required"))
          .matches(EMAIL_POLICY, t("registration:errors.primaryContactEmail.match")),
        confirmEmail: Yup.string().trim().required(t("registration:errors.primaryContactConfirmEmail.required"))
          .matches(EMAIL_POLICY, t("registration:errors.primaryContactConfirmEmail.match"))
          .test(
            'email-validate',
            t("registration:errors.primaryContactConfirmEmailEqual.equal"),
            confirmEmail => {
              let flag = confirmEmail === formik.values.primaryContactDetails.email ? true : false;
              if (!flag) {
                return false
              }
              return true
            }
          ),
        phone: Yup.string().trim().required(t("registration:errors.primaryContactPhoneNumber.required")),
      }),
    secondaryContactDetails:secondaryContactDetails,
    //The age of the Adult must be 13 years old at the first travel date. Please re-enter under the correct classification. Proof of age will be required at the lounge.
    priorityPassDetails: checkLoggedInUserIsLoungePartner() ?
      Yup.object().shape({
        name: Yup.string().trim().required(t("registration:errors.priorityPassName.required")),
        cardNumber: Yup.string().required(t("registration:errors.priorityPassCardNumber.required")),
        cardMonth: Yup.string().test(
          'test-credit-card-expiration-date',
          t("registration:errors.priorityPassExpiry.required"),
          cardmonth => {
            let bookingDate = getBookingDate()
            return validExpiryOrNot(formik.values.priorityPassDetails.cardYear, cardmonth, bookingDate);          
          }
        ),
        cardYear: Yup.string().test(
          'test-credit-card-expiration-date',
          t(""),
          cardyear => {
            let bookingDate = getBookingDate()
            return validExpiryOrNot(cardyear, formik.values.priorityPassDetails.cardMonth, bookingDate);    
          }
        ),
      }) : "",
  });
  return validationSchema
}

  const formik = useFormik({
    initialValues: initial,
    validationSchema:validationFunction,
    initialTouched : {cardMonth : true, cardYear : true},
    onSubmit: () => { handleSubmit(formik.values) },
  });

  let currentPriorityPassDetails = {};

  
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cartItemId = propsData?.cartItemId ? propsData?.cartItemId : currentCart?.key;
  const selectedMarket = useSelector(currentMarket);
  const marketLoungenames = getMarketLoungenames(selectedMarket?.airports);
  const cartData = useSelector(CD);
  const tempCartItem = cartData?.find(item => item.key === cartItemId);
  const [adults, setAdults] = useState([]);
  const [childs, setChilds] = useState([]);
  const [infant, setInfant] = useState([]);
  const [primaryContactDetails, setPrimaryContactDetails] = useState(initialPrimaryContact);
  const [priorityPassDetails, setdPriorityPassDetails] = useState({
    cardType: "Priority Pass",
    name: "",
    cardNumber: "",
    cardMonth: getCurrentMonth(),
    cardYear: new Date()?.getFullYear()?.toString(),
    errors: {},
  });
  const [secondaryContactDetails, setSecondaryContactDetails] = useState(Yup.object().nullable());
  const [greetingDetail, setGreetingDetail] = useState([initialGreetSign]);
  const heading = [t("primaryContact"), t("secondaryContact")];
  const kidsType = [t("child"), t("infant")];
  const adata = useSelector(arrivaldata);
  const ddata = useSelector(departuredata);
  const [formValidity, setformValidity] = useState("");
  const [selectedOccasion, setSelectedOccasion] = useState("");
  const specialOccasions = useSelector(SO);
  const [loading, setLoading] = useState(false);
  let bookingData;
  const demographicInformation = useSelector(DI);

  // if (propsData?.productid)
  //   bookingData = (propsData?.productid === PRODUCTID_ARRIVALBUNDLE ? [propsData?.bookingDetail[0], propsData?.bookingDetail[1]] : [propsData?.bookingDetail])
  // else
  bookingData = (productid === PRODUCTID_ARRIVALBUNDLE ? [tempCartItem?.value?.bookingDetail[0], tempCartItem?.value?.bookingDetail[1]] : [tempCartItem?.value?.bookingDetail]);

  const distributorProfile = getDistributorSubDistributorProfile();
  const loginDetails = useSelector(LD);
  const infantallowed = distributorProfile?.infantallowed;

  const airports = selectedMarket?.airports;
  const marketLoungenameswithairport = getMarketLoungenamesWithAirport(selectedMarket?.airports);

  
  let redirectProps = {
    cartItemId: cartItemId,
    productid: loungePartnerProductOperation("getProductFromUserName", productid) || productid,
    arrivalscheduleid: tempCartItem?.value?.currentCartItem?.arrivalscheduleid,
    departurescheduleid: tempCartItem?.value?.currentCartItem?.departurescheduleid,
    adultCount: tempCartItem?.value?.adultCount,
    childCount: tempCartItem?.value?.childCount,
    infantCount: tempCartItem?.value?.infantCount,
    ...(!isObjectEmpty(paxCountBookingDetails) && { getBookingRequestData: tempCartItem?.value?.getBookingRequestData }),
  };

  currentPriorityPassDetails = cartData?.find((c) => c.key === cartItemId)?.value?.data?.passengerInfo?.prioritypass ?? "";

  useEffect(() => {
    getBookingDate();
    let i, j, k, l;
    if (tempCartItem?.value?.data?.passengerInfo !== "") {
      const a = [];
      for (i = 0; i < tempCartItem?.value?.adultCount; i++)
        a.push({ ...(tempCartItem?.value?.data?.passengerInfo?.adults[i] ? tempCartItem?.value?.data?.passengerInfo?.adults[i] : initialAdult) });
      formik.setFieldValue("adults", a);
      const b = [];
      for (j = 0; j < tempCartItem?.value?.childCount; j++)
        b.push({ ...(tempCartItem?.value?.data?.passengerInfo?.childs[j] ? tempCartItem?.value?.data?.passengerInfo?.childs[j] : initialChild) });
      formik.setFieldValue("childs", b);
      const infant = [];
      if (getProductId(productid) !== PRODUCTID_DEPARTURE) {
        for (j = 0; j < tempCartItem?.value?.infantCount; j++)
          infant.push({ ...(tempCartItem?.value?.data?.passengerInfo?.infant[j] ? tempCartItem?.value?.data?.passengerInfo?.infant[j] : initialChild) });
        formik.setFieldValue("infants", infant);
      }
      formik.setFieldValue("primaryContactDetails", { ...tempCartItem?.value?.data?.passengerInfo?.primaryContactDetails });
      formik.setFieldValue("secondaryContactDetails", { ...tempCartItem?.value?.data?.passengerInfo?.secondaryContactDetails });
      formik.setFieldValue("priorityPassDetails", { ...currentPriorityPassDetails });
      const greet = [];
      greet.push({ ...tempCartItem?.value?.data?.passengerInfo?.greetingDetail[0] });
      formik.setFieldValue("greetingDetails", greet);
      specialOccasions && isObjectEmpty(specialOccasions) && getspecialoccassions(dispatch);
    }
    else {
      const a = [];
      for (i = 0; i < tempCartItem?.value?.adultCount; i++) {
        if(!isObjectEmpty(demographicInformation)){
          a.push({ ...demographicInformation?.adults[i]})
        } else {
          a.push({ ...initialAdult})
        }
      }
      // a.push({ ...initialAdult });
      formik.setFieldValue("adults", a);
      const b = [];
      for (j = 0; j < tempCartItem?.value?.childCount; j++) {
        if(!isObjectEmpty(demographicInformation) && !isEmptyArray(demographicInformation?.childs)){
          b.push({ ...demographicInformation?.childs[j]})
        } else {
          b.push({ ...initialChild})
        }
      }
        // b.push({ ...initialChild });
      formik.setFieldValue("childs", b);
      const infant = [];
      if (getProductId(productid) !== PRODUCTID_DEPARTURE) {
        for (j = 0; j < tempCartItem?.value?.infantCount; j++) {
          if(!isObjectEmpty(demographicInformation) && !isEmptyArray(demographicInformation?.infants)){
            infant.push({ ...demographicInformation?.infants[j]})
          } else {
            infant.push({ ...initialChild})
          }
        }
        // infant.push({ ...initialChild });
        formik.setFieldValue("infants", infant);
      }
      
      if (!isObjectEmpty(paxCountBookingDetails)) {
        formik.setFieldValue("primaryContactDetails", { ...propsData?.primaryContactDetail });
        formik.setFieldValue("secondaryContactDetails", { ...propsData?.secondaryContactDetails });
      } else {
        if(!isObjectEmpty(demographicInformation)){
          formik.setFieldValue("primaryContactDetails", demographicInformation?.primaryContactDetails)
          formik.setFieldValue("secondaryContactDetails", demographicInformation.secondaryContactDetails);
        } else {
          formik.setFieldValue("primaryContactDetails", formik.values.primaryContactDetails)
          formik.setFieldValue("secondaryContactDetails", formik.values.secondaryContactDetails);
        } 
      }
      const greet = [];
      for (l = 0; l < 1; l++) {
        greet.push({ ...initialGreetSign });
      }
      if(!isObjectEmpty(demographicInformation)){
        formik.setFieldValue("greetingDetails", demographicInformation?.greetingDetails);
      } else {
        formik.setFieldValue("greetingDetails", greet);
      } 
      specialOccasions && isObjectEmpty(specialOccasions) && getspecialoccassions(dispatch);
    }
  }, []);

  useEffect(()=>{
    formik.setFieldValue("priorityPassDetails.cardMonth",formik.values.priorityPassDetails.cardMonth);
    formik.setFieldValue("priorityPassDetails.cardYear",formik.values.priorityPassDetails.cardYear);
  },[formik.values.priorityPassDetails.cardMonth,formik.values.priorityPassDetails.cardYear])

  const handleSubmit = async (values) => {
    let error = false;
    if (!error) {
      const passengerInfo = {
        "adults": formik.values.adults,
        "childs": formik.values.childs,
        "infant": formik.values.infants,
        "primaryContactDetails": { ...formik.values.primaryContactDetails },
        "secondaryContactDetails": { ...formik.values.secondaryContactDetails },
        "greetingDetail": formik.values.greetingDetails,
        "productid": productid,
        "cartItemId": cartItemId,
        ...(!isObjectEmpty(paxCountBookingDetails) && { getBookingRequestData: tempCartItem?.value?.getBookingRequestData }),
        ...(checkLoggedInUserIsLoungePartner() && { prioritypass: { ...formik.values.priorityPassDetails } }),
        ...(!isObjectEmpty(paxCountBookingDetails) && { bookingDetail: tempCartItem?.value?.bookingDetail }),
      }
      const data = { passengerInfo: passengerInfo, ...(checkLoggedInUserIsLoungePartner() && { prioritypass: "Y" }) }

      setLoading(true);
      try {
        if (isObjectEmpty(paxCountBookingDetails)) {
          const setContactRequest = {
            "contact": {
              "cartitemid": cartItemId,
              "title": formik.values.primaryContactDetails?.title?.value,
              "firstname": formik.values.primaryContactDetails?.firstName,
              "lastname": formik.values.primaryContactDetails?.lastName,
              "email": formik.values.primaryContactDetails?.email,
              "phone": formik.values.primaryContactDetails?.phone
            }
          }
          const response = await setContact(setContactRequest);
          if (response?.status === 0) {
            dispatch(cartUpdateData({ ...data }));
            setDemographicInformationToRedux(formik, dispatch)
            navigate("/bookingSummary");
          } else {
            showError(t("error"), response?.statusMessage);
          }
        }
        else {
          dispatch(cartUpdateData({ ...data }));
          navigate("/bookingSummary");
        }
      } catch (e) {
        HandleAPIError(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const getBookingDate = () => {
    let dates = [];
    bookingData?.forEach((item) => {
      if (isObjectEmpty(paxCountBookingDetails)) {
        dates.push(item?.flightDate);
      } else {
        dates?.push(item?.flightDate && myDateFormat(item?.flightDate, "yyyyMMdd", "MM/dd/yyyy"));
      }
    });
    const min = dates.reduce((acc, date) => { return acc && new Date(acc) < new Date(date) ? acc : date }, '')
    return min;
  }

  const getDirectionTranslationKey = (index) => {
    let translationKey = "departure";
    if (index === 0) {
      translationKey = "arrival";
    } else {
      translationKey = "departure";
    }
    return translationKey;
  }

  useEffect(() => {
    if (loginDetails?.sessionid) {
      checkCartItemUse(cartData, cartItemId, navigate, false);
    }
  }, [cartData, cartItemId, navigate, loginDetails]);

  useEffect(() => {
    secondaryContactDetailsSchemaToggle(setSecondaryContactDetails,formik?.values?.secondaryContactDetails)
  }, [formik.values.secondaryContactDetails]);

  const backButtonHandler = () => {
    if (!isObjectEmpty(paxCountBookingDetails)) {
      navigate("/increase-pax", { state: redirectProps })
    } else {
      navigate(urlForRedirect(productid), { state: redirectProps });
    }
    setDemographicInformationToRedux(formik, dispatch);
  }
  return (
    <>
      <section>
        <Loader loading={loading} />
        <Container className="my-4">
          <h3 className="my-3 d-flex justify-content-center heading">
            <span className="heading">
              {t("registration:registrationDetails")}
            </span>
          </h3>
          <Row className="my-3 d-flex align-content-left">
            <Col md={12} className="d-flex justify-content-end">
              {/* <Link
                to={ ?  : }
                state={redirectProps} className=""
               onClick={() => setDemographicInformationToRedux(formik, dispatch)}
                > */}
                <CommonButton
                  type="button"
                  classname="back mx-1"
                  title={t("back")}
                  onclick={() => backButtonHandler()}
                />
              {/* </Link> */}
              <ExitButton />
            </Col>
          </Row>
          <div className="my-3">
            <p className="field">
              {t("registration:groundTranspotationMessage") + marketLoungenames}
            </p>
            <p>{t("registration:spaceAvailabilityMessage")} </p>
          </div>
          <Card className="my-3">
            <Card.Body>
              {
                bookingData?.map((item, index) =>
                  item !== "" && <div key={index}>
                    <Row className="d-flex justify-content-center align-content-center mb-1">
                      <Col md={12} sm={12}>
                        <h4 className="mb-1">
                          <b>
                            {t('summary', { product: t(getDirectionTranslationKey(productid === PRODUCTID_DEPARTURE ? 1 : index)) })}
                          </b>
                        </h4>
                        <h5 className="mb-1">
                          <b>{t("registration:loungeService")}:</b>{" "}
                          {/* {t("clubKingston")} */}
                          {isObjectEmpty(paxCountBookingDetails) ? item?.lounge?.label : marketLoungenameswithairport.find(a => a?.value === item?.lounge)?.label}
                        </h5>
                      </Col>
                    </Row>
                    <Row className="d-flex justify-content-center align-content-center mb-1">
                      <Col md={6} sm={12}>
                        {isObjectEmpty(paxCountBookingDetails) ? <h5 className="mb-1">
                          {/* <b>{t("flightDateTime")}:</b> {new Date(item?.flightDate).toUTCString()} */}
                          {/* <b>{t("flightDateTime")}:</b> {format((new Date(item?.flightDate)), 'MM/dd/yyyy HH:mm:ss')} */}

                          <b>{t("flightDateTime")}:</b> {item?.flightDate && dateFormatForDisplay(item?.flightDate, "MM/dd/yyyy")} {item?.flightTime_hour}
                        </h5>
                          : <h5><b>{t("flightDateTime")}:</b> {item?.flightDate && (myDateFormat(item?.flightDate, "yyyyMMdd", "MM/dd/yyyy"))}{" "}{item?.flightTime_hour && (myDateFormat(item?.flightTime_hour, "HHmm", "HH:mm a"))}</h5>
                        }
                      </Col>
                      <Col md={3} sm={12}>
                        <h5 className="mb-1">
                          <b>{t("airline")}:</b>  {isObjectEmpty(paxCountBookingDetails) ? item?.airlineName?.label : item?.airlineName}
                        </h5>
                      </Col>
                      <Col md={3} sm={12}>
                        <h5 className="mb-1">
                          <b>{t("flightNumber")}:</b>  {isObjectEmpty(paxCountBookingDetails) ? item?.flightNumber?.label : item?.flightNumber}
                        </h5>
                      </Col>
                    </Row>
                   
                    {(tempCartItem?.value?.currentCartItem?.productid === PRODUCTID_ARRIVALBUNDLE ? index === 1 : index === 0) &&
                      <>
                        <Row className="d-flex justify-content-center align-content-center mb-1">
                          <Col md={12} sm={12}>
                            <h5 className="mb-1">
                              <b>{t("serviceFor")}: </b>
                              <FontAwesomeIcon
                                icon={faUser}
                                className="mx-1 icon-color"
                                size="sm"
                              />{" "}
                              {t("adults")}: {tempCartItem?.value?.adultCount}
                              <FontAwesomeIcon
                                icon={faChild}
                                size="sm"
                                className="mx-1  icon-color"
                              />{" "}
                              {t("children")}:  {tempCartItem?.value?.childCount}
                              {(infantallowed === "Y") &&
                                <>
                                  <FontAwesomeIcon
                                    icon={faPersonBreastfeeding}
                                    size="sm"
                                    className="mx-1  icon-color"
                                  />{" "}
                                  {t("infants")}: {tempCartItem?.value?.infantCount}
                                </>
                              }
                            </h5>
                          </Col>
                        </Row>
                        <Row className="d-flex justify-content-center align-content-center mb-1">
                          <Col md={12} sm={12}>
                            <h5 className="mb-1">
                              <b>{t('bookingsummary:totalAmount')}:</b> {currencyCode} {tempCartItem?.value?.currentCartItem?.retail.toFixed(2)}
                            </h5>
                          </Col>
                        </Row>
                      </>
                    }

                  </div>
                )}
            </Card.Body>
          </Card>
          
          <Card className="my-3" ref={divRef}>
            <Card.Body>
              <Row className="d-flex justify-content-around align-content-center">
                <Col>
                  <h4>{t("customerInformation")}</h4>
                </Col>
              </Row>
              {productid === PRODUCTID_DEPARTURE && (
                <h6 className="normalMessageColor">
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    className="me-1 icon-color"
                  />
                  {t("infantNameNotRequiredDeparture")}
                </h6>
              )}
              <h6 className="normalMessageColor">
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  className="me-1 icon-color"
                />
                {t("infantsComplimentary")}
              </h6>
              <h6 className="field">{t("fillAllFields")}</h6>
              <h5 className="">{t("registration:guestNameFillMessage")}</h5>
              <hr className="horizontalBar" />
              <Form onSubmit={(e) => scrollToDiv(e, formik)}>
                <ErrorMessages formik={formik} />
                {/* adult component  */}
                <div className="mt-4">
                  {formik?.values?.adults?.map((a, i) => (
                    <AdultDetails
                      key={i}
                      isMandatory={i === 0 ? true : false}
                      title={a?.title}
                      formik={formik}
                      index={i}
                      bookingDate={getBookingDate()}
                      onBlur={(key, e, title) => {
                        formik.handleBlur(e);
                        if (isObjectEmpty(paxCountBookingDetails)) {
                          if(key === "title" && isTitleChangeable){
                            setIsTitleChangeable(false);
                            formik.setFieldValue(`primaryContactDetails.${key}`,title);
                          }
                          if (key === "firstName" && formik.values.primaryContactDetails?.firstName === "") {
                            formik.setFieldValue(`primaryContactDetails.${key}`, e.target.value);
                          }
                          if (key === "lastName" && formik.values.primaryContactDetails?.lastName === "") {
                            formik.setFieldValue(`primaryContactDetails.${key}`, e.target.value);
                          }
                          if (key === "email" && formik.values.primaryContactDetails?.email === "") {
                            formik.setFieldValue(`primaryContactDetails.${key}`, e.target.value);
                          }
                          if (productid !== PRODUCTID_DEPARTURE) {
                            var ele_fname = formik?.values?.adults[0]?.firstName;
                            var ele_lname = formik?.values?.adults[0]?.lastName;
                            if (parseInt(tempCartItem?.value?.adultCount) + parseInt(tempCartItem?.value?.childCount) + parseInt(tempCartItem?.value?.infantCount) > 1) {
                              formik.setFieldValue(`greetingDetails.${0}.name`, ele_fname + " " + ele_lname + t('guests'));
                            } else {
                              formik.setFieldValue(`greetingDetails.${0}.name`, ele_fname + " " + ele_lname);
                            }
                          }
                        } else {
                          if (key === "firstName" && formik.values.primaryContactDetails?.firstName === "") {
                            formik.setFieldValue(`primaryContactDetails.${key}`, propsData?.primaryContactDetail.contactname.split(" ")[0]);
                          }
                          if (key === "lastName" && formik.values.primaryContactDetails?.lastName === "") {
                            formik.setFieldValue(`primaryContactDetails.${key}`, propsData?.primaryContactDetail.contactname.split(" ")[1]);
                          }
                          if (key === "email" && formik.values.primaryContactDetails?.email === "") {
                            formik.setFieldValue(`primaryContactDetails.${key}`, propsData?.primaryContactDetail.email);
                          }
                        }
                      }}
                      onChange={(key, val) => {
                        formik.setFieldValue(`adults.${i}.${key}`, val);
                      }}
                    />
                  ))}
                </div>
                {/* child component */}
                <div className="mt-4">
                  {formik.values.childs?.map((a, i) => (
                    <ChildDetails
                      key={i}
                      heading={kidsType[0]}
                      isMandatory={i === 0 ? true : false}
                      title={a?.title}
                      type={"child"}
                      formik={formik}
                      index={i}
                      bookingDate={getBookingDate()}
                      onBlur={(key, e) => {
                        formik.handleBlur(e);
                        if (isObjectEmpty(paxCountBookingDetails)) {
                          if (formik.values.adults.length === 0) {
                            if (key === "firstName" && formik.values.primaryContactDetails?.firstName === "") {
                              formik.setFieldValue(`primaryContactDetails.${key}`, e.target.value);
                            }
                            if (key === "lastName" && formik.values.primaryContactDetails?.lastName === "")
                              formik.setFieldValue(`primaryContactDetails.${key}`, e.target.value);
                            if (key === "email" && formik.values.primaryContactDetails?.email === "")
                              formik.setFieldValue(`primaryContactDetails.${key}`, e.target.value);
                            if (productid !== PRODUCTID_DEPARTURE) {
                              var ele_fname = formik?.values?.childs[0]?.firstName;
                              var ele_lname = formik?.values?.childs[0]?.lastName;
                              if ((parseInt(tempCartItem?.value?.adultCount) + parseInt(tempCartItem?.value?.childCount) + parseInt(tempCartItem?.value?.infantCount)) > 1)
                                formik.setFieldValue(`greetingDetails.${0}.name`, (ele_fname + " " + ele_lname + t('guests')));
                              else
                                formik.setFieldValue(`greetingDetails.${0}.name`, (ele_fname + " " + ele_lname));
                            }
                          }
                        } else {
                          if (formik.values.adults.length === 0) {
                            if (key === "firstName" && formik.values.primaryContactDetails?.firstName === "") {
                              formik.setFieldValue(`primaryContactDetails.${key}`, propsData?.primaryContactDetail.contactname.split(" ")[0]);
                            }
                            if (key === "lastName" && formik.values.primaryContactDetails?.lastName === "") {
                              formik.setFieldValue(`primaryContactDetails.${key}`, propsData?.primaryContactDetail.contactname.split(" ")[0]);
                            }
                            if (key === "email" && formik.values.primaryContactDetails?.email === "") {
                              formik.setFieldValue(`primaryContactDetails.${key}`, propsData?.primaryContactDetail.email);
                            }
                          }
                        }
                      }}
                      onChange={(key, val) => {
                        formik.setFieldValue(`childs.${i}.${key}`, val);
                      }}
                    />
                  ))}
                </div>
                {/*same  child component used for infant  */}
                <div className="mt-4">
                  {productid !== PRODUCTID_DEPARTURE &&
                    formik.values.infants?.map((a, i) => (
                      <ChildDetails
                        key={i}
                        type={"infant"}
                        heading={kidsType[1]}
                        isMandatory={i === 0 ? true : false}
                        title={a?.title}
                        index={i}
                        formik={formik}
                        bookingDate={getBookingDate()}
                        onBlur={(key, e) => {
                          formik.handleBlur(e);
                        }}
                        onChange={(key, val) => {
                          formik.setFieldValue(`infants.${i}.${key}`, val);
                        }}
                      />
                    ))}
                </div>
                {/* primary and secondary contact component  */}
                <div className="mt-4">
                  {isObjectEmpty(paxCountBookingDetails) && <ContactDetails
                    key={0}
                    isMandatory={true}
                    type={"primary"}
                    heading={heading[0]}
                    title={formik.values.primaryContactDetails?.title}
                    formik={formik}
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                    onChange={(key, val) => {
                      formik.setFieldValue(`primaryContactDetails.${key}`, val);
                    }}
                    onBlur={(key, e) => {
                      formik.handleBlur(e);
                    }}
                  />}

                  {isObjectEmpty(paxCountBookingDetails) && <ContactDetails
                    key={1}
                    isMandatory={false}
                    heading={heading[1]}
                    title={formik.values.primaryContactDetails?.title}
                    type={"secondary"}
                    formik={formik}
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                    onChange={(key, val) => {
                      formik.setFieldValue(`secondaryContactDetails.${key}`, val);
                    }}
                    onBlur={(key, e) => {
                      formik.handleBlur(e);
                    }}
                  />}
                </div>
                {isObjectEmpty(paxCountBookingDetails) && <div>
                  {formik?.values?.greetingDetails?.map((a, i) => (
                    <GreetSign
                      key={i}
                      selectedOccasion={selectedOccasion}
                      formik={formik}
                      productid={productid}
                      index={i}
                      onChange={(key, val) => {
                        formik.setFieldValue(`greetingDetails.${i}.${key}`, val);
                      }}
                      onBlur={formik.handleBlur}
                    />
                  ))}
                </div>}

                {checkLoggedInUserIsLoungePartner() && <Col md={12} sm={12}>
                  <PriorityPassCard
                    key={0}
                    ispriorityPass={getLoggedInUsername() === PRIORITYPASS_LOGIN.username ? true : false}
                    formik={formik}
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                    onChange={(key, val) => {
                      formik.setFieldValue(`priorityPassDetails.${key}`, val);
                    }}
                    onBlur={(key, e) => {
                      formik.handleBlur(e);
                    }}
                  />
                </Col>}
                <Row className="mt-4 ">
                  <Col md={12} sm={12} className="d-flex justify-content-end">
                    <CommonButton
                      type="submit"
                      classname=" gradient-btn mb-2 mx-1"
                      title={t("registration:proceedToCheckout")}
                    />
                  </Col>        
                </Row> 
              </Form>
            </Card.Body>
          </Card>
          <Row>
            <Col md={12} sm={12}>
              <Row>
                <Col
                  md={12}
                  sm={12}
                  className="d-flex justify-content-end"
                >
                  {/* <Link
                    to={!isObjectEmpty(paxCountBookingDetails) ? "/increase-pax" : urlForRedirect(productid)}
                    state={redirectProps}
                    className="mx-1 text-decoration-none"
                  > */}
                    <CommonButton
                      // type="submit"
                      classname="back mx-1"
                      title={t("back")}
                      onclick={() => backButtonHandler()}
                    />
                  {/* </Link> */}
                  <ExitButton />
                </Col>
              </Row>
            </Col>

          </Row>
        </Container>
      </section >
    </>
  );
}