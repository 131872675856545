import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SocialMediaIcon(props) {
  return (
    <a href={props.url} title={props.title} target="_blank" rel="noreferrer">
      <FontAwesomeIcon
        icon={props.iconType}
        size={props.size}
        style={{
          color: "white",
          padding: props.padding ? props.padding : "2px",
        }}
      />
    </a>
  );
}

export default SocialMediaIcon;
