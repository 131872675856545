import {useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import Select from "react-select";
import CommonButton from "../components/commonComponents/CommonButton";
import InputGroup from "react-bootstrap/InputGroup";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { addUpdateLinkQueue} from "../redux/apiCalls";
import { useNavigate } from "react-router-dom";
import Loader from "../components/commonComponents/Loader";
import { showError } from "../components/commonComponents/Swal";
import { selectedMarket as currentMarket, selectedMarketId as SMID } from "../redux/marketRedux";
import {
  HandleAPIError,
  divRef,
  getProductDropdownAccordingMarket,
  numericValidation,
  scrollToDiv,
  translateArrayKeyValue,
} from "../components/commonComponents/commonFunctions";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessages from "../components/commonComponents/ErrorMessages";
import { UPDATE_FLIGHT_CONFIRMATIONID_MAXLENGTH, CONFIRMATION_NUMBER_MAXLENGTH, REGEX_ALPHABET, REGEX_NUMERIC, SEARCH_PARTNER } from "../config/commonConstants";

function GetUpdateFlightDetailsLink() {
  const selectedMarket = useSelector(currentMarket);
  const { t } = useTranslation(["common", "getupdateflightdetailslink"]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const selectedMarketId = useSelector(SMID);
  const ARR_PRODUCTS = getProductDropdownAccordingMarket(selectedMarketId)
  const [searchBy, setSearchBy] = useState("")
  const validationSchema = () => {
    switch (searchBy) {
      case "A":
        return Yup.object().shape({
          searchBy: Yup.string().trim().required(
            t("getupdateflightdetailslink:errors.searchBy.required")
          ),
          product: Yup.string().trim().required(
            t("getupdateflightdetailslink:errors.product.required")
          ),
          confirmationNumber: Yup.string().trim().required(
            t("getupdateflightdetailslink:errors.confirmationNumber.required")
          )
        });
      case "B":
        return Yup.object().shape({
          searchBy: Yup.string().trim().required(
            t("getupdateflightdetailslink:errors.searchBy.required")
          ),
          product: Yup.string().trim().required(
            t("getupdateflightdetailslink:errors.product.required")
          ),
          referenceNumber: Yup.string().trim().required(
            t("getupdateflightdetailslink:errors.referenceNumber.required")
          ),
          contactName: Yup.string().trim().required(
            t("getupdateflightdetailslink:errors.contactName.required")
          ),          
        });

      default:
        return Yup.object().shape({
          searchBy: Yup.string().trim().required(
            t("getupdateflightdetailslink:errors.searchBy.required")
          ),
          product: Yup.string().trim().required(
            t("getupdateflightdetailslink:errors.product.required")
          )
        });
    }
  }

  const formik = useFormik({
    initialValues: {
      searchBy: "",
      product: "",
      confirmationNumber: "",
      referenceNumber: "",
      contactName: "",
    },
    validationSchema,
    onSubmit: () => {
      handleSubmit(formik.values);
    },
  });

  const handleSubmit = async (formik) => {
    const data = {
      "productid": formik.product,
      ...(formik.searchBy === "A" && { "confirmationnumber": formik.confirmationNumber }),
      "lookupid": formik.searchBy,
      ...(formik.searchBy === "B" && { "referencenumber": formik.referenceNumber }),
      ...(formik.searchBy === "B"  && { "contactname": formik.contactName })
    };

    const successInfo = {
      messageHeading: t("getupdateflightdetailslink:successInfoMessageHeading"),
      successMessage: t("getupdateflightdetailslink:successInfoSuccessMessage",
        {
          phone: selectedMarket?.contact?.phone,
          email: selectedMarket?.contact?.email,
          skype: selectedMarket?.contact?.skype,
        }
      ),
      jemPromotion: true,
      jePromotion: true,
    };

    try {
      setLoading(true);
      const response = await addUpdateLinkQueue(data);
      
      if (response?.status === 0)
        navigate("/common-success", { state: successInfo });
      else {
        showError(t("error"), response?.statusMessage);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  }

  function renderSearchByOptions() {
    const updatedSearchPartner = [...SEARCH_PARTNER];
    updatedSearchPartner.pop();
    return updatedSearchPartner;
  }

  return (
    <>
      <section>
        <Container className="my-4" ref={divRef}>
          <Loader loading={loading} message={t('getupdateflightdetailslink:loaderMessage')} />
          <h3 className="my-3 d-flex justify-content-center heading">
            <span className="heading">
              {t("getupdateflightdetailslink:updateFlightDetails")}
            </span>
          </h3>
          <Row className="my-3 d-flex justify-content-around align-content-left">
            <Col md={6}></Col>
            <Col md={6} className="d-flex justify-content-end">
              <ExitButton />
            </Col>
          </Row>
          <Card className="my-3"  >
            <Card.Body>
              <h6 className="field mb-3">
                {t('fillAllFields')}
              </h6>
              <Form onSubmit={(e) => scrollToDiv(e, formik)}>
                <ErrorMessages formik={formik} />
                <h5 className="d-flex justify-content-center">{t('getupdateflightdetailslink:updateBookingHeading')}</h5>
                <Row className="d-flex justify-content-center">
                  <Col lg={3} md={4} sm={12}>
                    <Form.Group className="my-2">
                      <Form.Label className="d-flex">
                        {t('getupdateflightdetailslink:searchBy')}
                        <span className="field">*</span>
                      </Form.Label>
                      <Select
                        name="searchBy"
                        options={translateArrayKeyValue(renderSearchByOptions(SEARCH_PARTNER),'SEARCHPARTNER')}
                        value={formik.values.searchBy.Label}
                        onChange={(d) => {
                          setSearchBy(d.value)
                          formik.setFieldValue("searchBy", d.value);
                        }}
                        onBlur={formik.handleBlur}
                        className={
                          formik.touched.searchBy && formik.errors.searchBy
                            ? "error rounded-2 my-0 mb-3"
                            : "my-0 mb-3"
                        }
                      />
                      {formik.touched.searchBy}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                  <Col lg={3} md={4} sm={12}>
                    <Form.Group className="my-2">
                      <Form.Label className="d-flex">
                        {t('getupdateflightdetailslink:product')}
                        <span className="field">*</span>
                      </Form.Label>
                      <Select
                        name="product"
                        options={ARR_PRODUCTS}
                        value={formik.values.product.Label}
                        onChange={(d) => {
                          formik.setFieldValue("product", d.value);
                        }}
                        onBlur={formik.handleBlur}
                        className={
                          formik.touched.product && formik.errors.product
                            ? "error rounded-2 my-0 mb-3"
                            : "my-0 mb-3"
                        }
                      />
                      {formik.touched.product}
                    </Form.Group>
                  </Col>
                </Row>
                {formik.values.searchBy === "A" &&
                  <Row className="d-flex justify-content-center">
                    <Col
                      lg={3} md={4} sm={12}
                      className="mb-3"
                    >
                      <Form.Label className="d-flex" htmlFor="ConfirmationNumber">
                        {t('getupdateflightdetailslink:confirmationNumber')}<span className="field">*</span>
                      </Form.Label>
                      <InputGroup className="d-flex">
                        <Form.Control
                          type="text"
                          name="confirmationNumber"
                          id="ConfirmationNumber"
                          placeholder={t('getupdateflightdetailslink:confirmationNumber')}
                          aria-describedby="inputGroupPrepend"
                          maxLength={CONFIRMATION_NUMBER_MAXLENGTH}
                          value={formik.values.confirmationNumber}
                          onChange={(e) => {
                            e.target.value =numericValidation(e.target.value);
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.confirmationNumber &&
                              formik.errors.confirmationNumber
                              ? "error my-0 mb-3"
                              : "my-0 mb-3"
                          }
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                }
                {formik.values.searchBy === "B" &&
                <>
                  <Row className="d-flex justify-content-center">
                    <Col
                      lg={3} md={4} sm={12}
                      className="mb-3"
                    >
                      <Form.Label className="d-flex" htmlFor="referenceNumber">
                        {t('getupdateflightdetailslink:referenceNumber')}<span className="field">*</span>
                      </Form.Label>
                      <InputGroup className="d-flex">
                        <Form.Control
                          type="text"
                          name="referenceNumber"
                          id="referenceNumber"
                          placeholder={t('getupdateflightdetailslink:referenceNumber')}
                          aria-describedby="inputGroupPrepend"
                          maxLength={CONFIRMATION_NUMBER_MAXLENGTH}
                          value={formik.values.referenceNumber}
                          onChange={(e) => {

                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.referenceNumber &&
                              formik.errors.referenceNumber
                              ? "error my-0 mb-3"
                              : "my-0 mb-3"
                          }
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                   <Row className="d-flex justify-content-center">
                   <Col
                     lg={3} md={4} sm={12}
                     className="mb-3"
                   >
                     <Form.Label className="d-flex" htmlFor="contactName">
                     {t('getupdateflightdetailslink:contactName')}<span className="field">*</span>
                     </Form.Label>
                     <InputGroup className="d-flex">
                       <Form.Control
                         type="text"
                         name="contactName"
                         id="contactName"
                         placeholder={t('getupdateflightdetailslink:contactName')}
                         aria-describedby="inputGroupPrepend"
                         maxLength={20}
                         value={formik.values.contactName}
                         onChange={(e) => {
                           e.target.value = e.target.value?.replace(REGEX_ALPHABET, "");
                           formik.handleChange(e);
                         }}
                         onBlur={formik.handleBlur}
                         className={
                           formik.touched.contactName &&
                             formik.errors.contactName
                             ? "error my-0 mb-3"
                             : "my-0 mb-3"
                         }
                       />
                     </InputGroup>
                   </Col>
                 </Row>
                 </>
                }
                <Row>
                  <Col md={12} className="d-flex justify-content-center">
                    <CommonButton
                      type="submit"
                      classname="gradient-btn "
                      size="md"
                      title={t('submit')}
                    />
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
}
export default GetUpdateFlightDetailsLink;

