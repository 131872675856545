import { createSlice } from "@reduxjs/toolkit";

const specialOccasionsSlice = createSlice({
  name: "specialOccasions",
  initialState: {
    specialOccasions: {},
    error: false,
  },
  reducers: {
    specialOccasionsFetchSuccess: (state, action) => {
      state.specialOccasions = action.payload;
    },
    specialOccasionsFetchFailure: (state) => {
      state.error = true;
    },
  },
});

export const { specialOccasionsFetchSuccess, specialOccasionsFetchFailure } = specialOccasionsSlice.actions;
export const specialOccasions = (state) => state.specialOccasionsRedux?.specialOccasions;
export default specialOccasionsSlice.reducer;