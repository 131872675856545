import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function HomeLoadingSkeleton() {
  return (
    <>
      <section>
        <Container className="my-4">
          <Row className="my-3 d-flex justify-content-center">
            <Col md={4}>
              <h3>
                <span className="heading p-3">
                  <Skeleton width="100%" height="40px" />
                </span>
              </h3>
            </Col>
          </Row>
          <Row className="my-3 d-flex justify-content-center">
            <Col md={4}>
              <h4>
                <span className="p-3">
                  <Skeleton width="100%" />
                </span>
              </h4>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container className="my-4 ">
          <Row className="d-flex justify-content-center">
            <Col md={4} className="mb-2 mb-md-0">
              <Card className="p-2 border rounded shadow">
                <Skeleton width="100%" height="30px" />
                <hr />
                <Skeleton width="100%" height="200px" />
                <Skeleton width="100%" height="150px" />
                <hr />
                <Skeleton width="100%" />
              </Card>
            </Col>
            <Col md={4} className="mb-2 mb-md-0">
              <Card className="p-2 border rounded shadow">
                <Skeleton width="100%" height="30px" />
                <hr />
                <Skeleton width="100%" height="200px" />
                <Skeleton width="100%" height="150px" />
                <hr />
                <Skeleton width="100%" />
              </Card>
            </Col>
            <Col md={4} className="mb-2 mb-md-0">
              <Card className="p-2 border rounded shadow">
                <Skeleton width="100%" height="30px" />
                <hr />
                <Skeleton width="100%" height="200px" />
                <Skeleton width="100%" height="150px" />
                <hr />
                <Skeleton width="100%" />
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container className="my-4">
          <Row className="d-flex justify-content-center">
            <Col md={4}>
              <h3>
                <span className="">
                  <Skeleton width="100%" height="40px" />
                </span>
              </h3>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col md={4}>
              <h4>
                <span className="">
                  <Skeleton width="100%" />
                </span>
              </h4>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col md={4}>
              <h3>
                <span className="">
                  <Skeleton width="100%" />
                </span>
              </h3>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col className="col-md-5 mb-2 mb-md-0">
              <Card className="p-2 border rounded shadow">
                <Skeleton width="100%" height="40px" />
                <hr />
                <Skeleton width="100%" height="200px" />
                <Skeleton width="100%" height="150px" />
                <hr />
                <Skeleton width="100%" />
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container className="my-4">
          <Row className="d-flex justify-content-center">
            <Col md={4}>
              <h3>
                <span className="">
                  <Skeleton width="100%" height="40px" />
                </span>
              </h3>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col md={4}>
              <h4>
                <span className="">
                  <Skeleton width="100%" />
                </span>
              </h4>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col md={4}>
              <h3>
                <span className="">
                  <Skeleton width="100%" />
                </span>
              </h3>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col className="col-md-5 mb-2 mb-md-0">
              <Card className="p-2 border rounded shadow">
                <Skeleton width="100%" height="40px" />
                <hr />
                <Skeleton width="100%" height="200px" />
                <Skeleton width="100%" height="150px" />
                <hr />
                <Skeleton width="100%" />
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container className="my-4 d-flex justify-content-center">
          <Card className="giftCardStyle">
            <Row>
              <Col sm={12} md={7}>
                <Card.Body>
                  <h3>
                    <Skeleton width="100%" />
                  </h3>
                  <div></div>
                  <div className="align-content-center">
                    <p>
                      <Skeleton width="100%" height="60px" />
                    </p>
                  </div>
                  <Skeleton width="100%" height="20px" />
                </Card.Body>
              </Col>
              <Col sm={12} md={5} className="justify-content-center">
                <Card.Body>
                  <Skeleton width="100%" height="70px" />
                  <Skeleton width="100%" height="70px" />
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Container>
      </section>
      <section className="bg-light">
        <Container fluid="xxl" >
          <Row>
            <Col md="12" className="mt-4">
              <Skeleton width="100%" height="70px" />
            </Col>
          </Row>
          <Row className="justify-content-center ">
            <Col md="4" className="mb-4">     
              <Skeleton width="100%" height="70px" />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
export default HomeLoadingSkeleton;