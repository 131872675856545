import { createSlice } from "@reduxjs/toolkit";

const flightSlice = createSlice({
  name: "getFlightSchedule",
  initialState: {
    data: null,
    flightArrivalData: {},
    flightDepartureData: {},
    error: false,
  },
  reducers: {
    scheduleFetchSuccess: (state, action) => {
      state.data = action.payload;
    },
    setflightArrivalData: (state, action) => {
      state.flightArrivalData = action.payload;
    },
    setflightDepartureData: (state, action) => {
      state.flightDepartureData = action.payload;
    },
    scheduleFetchFailure: (state) => {
      state.error = true;
    },
  },
});

export const {
  scheduleFetchSuccess,
  setflightArrivalData,
  setflightDepartureData,
  scheduleFetchFailure,
} = flightSlice.actions;

export const arrivaldata = (state) => state.flightScheduleRedux?.flightArrivalData;
export const departuredata = (state) => state.flightScheduleRedux?.flightDepartureData;

export default flightSlice.reducer;
