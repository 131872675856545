import React, { useState } from 'react';
import CryptoJS from 'crypto-js';
import { formatCreditCardExpiryFAC } from '../components/commonComponents/commonFunctions';
import { IV_RANDOM_NUMBER } from '../config/commonConstants';

const base64ToWordArray = (base64) => {
    return CryptoJS.enc.Base64.parse(base64);
};

const wordArrayToBase64 = (wordArray) => {
    return CryptoJS.enc.Base64.stringify(wordArray);
};

const encryptData = (data, iv, key) => {
  const value = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), base64ToWordArray(key), {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return value.ciphertext.toString(CryptoJS.enc.Base64)
}

export const encryptCardDetails = (cardholderDetails, key) => {
  const iv = CryptoJS.lib.WordArray.random(IV_RANDOM_NUMBER);
  const cardNumber = encryptData(cardholderDetails?.cardNumber, iv, key);
  const cardHolderName = encryptData(cardholderDetails?.name, iv, key);
  const cvv = encryptData(cardholderDetails?.cvv, iv, key);
  const expiryDate = encryptData(formatCreditCardExpiryFAC(cardholderDetails?.cardMonth, cardholderDetails?.cardYear), iv, key);

  return {
    iv: wordArrayToBase64(iv),
    cardNumber: cardNumber,
    cardHolderName: cardHolderName,
    cvv: cvv,
    expiryDate: expiryDate,
  };
};

export const decryptCardDetails = (encryptedCardNumber, key, iv) => {
    try {
        const ivWordArray = base64ToWordArray(iv);
        const keyWordArray = base64ToWordArray(key);
        const encrypted = base64ToWordArray(encryptedCardNumber);

        const decrypted = CryptoJS.AES.decrypt(
            { ciphertext: encrypted },
            keyWordArray,
            {
                iv: ivWordArray,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            }
        );

        return CryptoJS.enc.Utf8.stringify(decrypted);
    } catch (error) {
        console.error('Decryption error:', error);
        return null;
    }
};