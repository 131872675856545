import axios from "axios";
import router from './routeConfig'

//create and configure axios instance
export const appAxios = axios.create({});

// //intercepting the request and response
// appAxios.interceptors.request.use((conf) => {

// });

// appAxios.interceptors.response.use((response) => {
//     return response;
// }, (error) => {
//     if (error?.code === "ERR_NETWORK") {
//         router.navigate('/network-error')
//         // throw new UnauthorizedError('Unauthorized');
//     }
//     throw error;
// })
