  import '../styles/style.css';
import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import Switch from "react-switch";
import { Col, Row } from "react-bootstrap";
import { useCookies } from "react-cookie";
import CustomizeCookieModal from "./commonComponents/CustomizeCookieModal"
import { AFFILIATE_COOKIE_EXPIRE_TIME, DEFAULT_COOKIE } from '../config/commonConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCookieBite } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";
import { setAndRemovieCookie, translateConstantText } from './commonComponents/commonFunctions';

export default function CookieModal({cookieModalHandler}) {
  const { t } = useTranslation(["cookiemodal"]);

  const [visibleCookie, setvisibleCookie] = useState(false);
  const hideCustomCookieModal = () => setvisibleCookie(false);
  const showCustomCookieModal = () => setvisibleCookie(true);
    const handleAcceptAllCookie = () => {
    // accept all cookie handle
    const allowedCookies =  {
      "necessary": true,
      "functional": true,
      "showModal": false
    }
  
    if(visibleCookie){
      hideCustomCookieModal();
    }else{
      cookieModalHandler();
    }
    setAndRemovieCookie(allowedCookies);
  }

  const handleNecessaryCookie = () => {
    // necessary all cookie handle
    const allowedCookies =  {
      "necessary": true,
      "functional": false,
      "showModal": false
    }
    setAndRemovieCookie(allowedCookies);
    cookieModalHandler();
  }

  return (
    <>
      <CustomizeCookieModal 
        visibleCookie={visibleCookie} 
        cancelModal={hideCustomCookieModal} 
        cookieModalHandler={cookieModalHandler} 
        handleAcceptAllCookie={handleAcceptAllCookie} 
      />
      
      <section className='modal-overlay'>
        <div className='m-3 fixed-bottom cookie card shadow-none border border-dark-subtle border-1'>
          <div className="m-0 text-dark p-4 rounded ">
            <FontAwesomeIcon
              icon={faCookieBite}
              size="2xl"
              className="mx-1 mt-1 icon-color"
            />
            <div className='fw-bold py-1'>{t("cookiemodal:yourPrivacy")}</div>
            <div className='fs pb-2'>{t("cookiemodal:cookieModalText",{cookieText: translateConstantText("COOKIE_MODAL_TEXT")})}</div>
            <Row>
              <Col sm={6} className='pb-2'>
                <button type="button" className="btn btn-primary gradient btn-sm form-control p-2" onClick={handleAcceptAllCookie}>{t("cookiemodal:acceptAllCookies")}</button>
              </Col>
              <Col sm={6} className='pb-2'>
                <button type="button" className="btn btn-primary gradient btn-sm form-control p-2" onClick={handleNecessaryCookie}>{t("cookiemodal:necessaryCookiesOnly")}</button>
              </Col>
              <Col sm={12}>
                <button type="button" className="btn btn-primary gradient btn-sm form-control p-2" onClick={showCustomCookieModal}>{t("cookiemodal:customizeSetting")}</button>
              </Col>
            </Row>
          </div>
        </div>
      </section>    
    </>
  )
}
