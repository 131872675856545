import { useEffect, useState } from "react";
import { t } from "i18next";
import { findDuplicates } from "./commonFunctions";
function containsTrue(arr) {
  for (let i = 0; i < arr.length; i++) {
    if (Array.isArray(arr[i])) {
      if (containsTrue(arr[i])) {
        return true; 
      }
    } else if (arr[i] === true) {
      return true; 
    }
  }
  return false;
}


export default function ErrorMessages({ formik }) {
  const [duplicates, setDuplicates] = useState([]);
  const [showError,setShowError] = useState(false);
  useEffect(() => {
    if (formik?.values?.memberShipCards) {
      let arr = [];
      for (let i = 0; i < formik.values.memberShipCards.length; i++) {
        if (formik?.values?.memberShipCards[i]?.cardNumber) {
          arr.push(formik?.values?.memberShipCards[i]?.cardNumber)
        }
      }
      const duplicateElements = findDuplicates(arr);
      setDuplicates(duplicateElements);
    }
    let isError =Object.keys(formik.errors).map((k) => {
      if (formik.touched[k]) {
        if (Array.isArray(formik?.errors[k])) {
          return formik?.errors[k].map((o, i) => {
            if (o !== null && o !== undefined) {
              return Object?.keys(o)?.map((ky) => {
                if (formik?.touched?.[k]?.[i]?.[ky]) {
                  return true
                }
                return false
              });
            }
          });
        } else if (typeof formik?.errors[k] === "object") {
          return Object?.keys(formik?.errors[k])?.map((ky) => {
            if (formik?.touched[k][ky]) {
              return true
            }
            return false;
          });
        } else {
          return true
        }
      }
      return false;
    })
    setShowError(containsTrue(isError));
  }, [formik?.values?.memberShipCards,formik.errors,formik.touched])
  return (
   (showError ) && ( //!isObjectEmpty(formik.errors)
      <div className={formik.touched ? "bg-danger bg-opacity-10 rounded-1 mb-3 p-2" : ""}>
        <h5 className="text-danger "> {t("errorTitle")}</h5>
        {duplicates?.map((data, i) =>
          data &&
          <p className="text-danger ps-2 m-0 p-0" key={i}>{t("duplicatesCardNumberMessage",{cardNumber: data})}</p>
        )}
        {Object.keys(formik.errors).map((k) => {
          if (formik.touched[k]) {
            if (Array.isArray(formik?.errors[k])) {
              return formik?.errors[k].map((o, i) => {
                if (o !== null && o !== undefined) {
                  return Object?.keys(o)?.map((ky) => {
                    if (formik?.touched?.[k]?.[i]?.[ky]) {
                    
                      return (
                        <p
                          className="text-danger ps-2 m-0 p-0"
                          key={`${k}_${ky}`}
                        >
                          {formik?.errors[k].length > 1
                            ? formik.errors[k][i][ky]
                                ?.toString().replace("{var}"," #" +
                                (i + 1).toString())
                            : formik.errors[k][i][ky]?.toString().replace("{var}","")}
                        </p>
                      );
                    }
                    return undefined;
                  });
                }
              });
            } else if (typeof formik?.errors[k] === "object") {
              return Object?.keys(formik?.errors[k])?.map((ky) => {
                if (formik?.touched[k][ky]) {
                  return (
                    <p className="text-danger ps-2 m-0" key={`${k}_${ky}`}>
                      {formik.errors[k][ky]?.toString().replace("{var}","")}
                    </p>
                  );
                }
                return undefined;
              });
            } else {
              return (
                <p className="text-danger ps-2 m-0" key={k}>
                  {formik.errors[k]?.toString().replace("{var}","")}
                </p>
              );
            }
          }
          return undefined;
        })}
      </div>
    )
  );
}
