import { Card, Row, Col, Container, Tabs, Tab, Form, InputGroup } from "react-bootstrap";
import { getCreditBooking, confirmCreditBooking, getMemberCards, processMemberCard, setMarketId, clearVIPERCart } from "../redux/apiCalls";
// import { Card, Row, Col, Container } from "react-bootstrap";
// import { getCreditBooking, confirmCreditBooking, clearVIPERCart } from "../redux/apiCalls";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { useParams } from "react-router-dom";
import { showError } from "../components/commonComponents/Swal";
import { useTranslation } from "react-i18next";
import Loader from "../components/commonComponents/Loader";
import { validExpiryOrNot, convertAmountToUSDFloat, dateFormatForDisplay, divRef, externalLogin, getCurrencyCodeFromMarket, getCurrentMonth, getLanguageIdFromAirportName, getMarketIdFromAirportName, HandleAPIError, marketChangeBroadcast, renderErrorMessages, scrollToDiv, translateConstantText, getPassengerDetails, getProductName, getAdultMinimumAge, generatePaymentMenu, getDistributorSubDistributorProfile } from "../components/commonComponents/commonFunctions";
import BookingSummaryComponent from "../components/commonComponents/BookingSummaryComponent";
import CreditCard from "../components/commonComponents/CreditCard";
import { processCreditCardPayment } from "../utils/Fac";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { selectedMarketCurrency } from "../redux/marketRedux";
import { useDispatch, useSelector } from "react-redux";
import { CARD_CVV_LENGTH, DEFAULT_CURRENCYCODE, EMAIL_MAXLENGTH, EMAIL_POLICY, MEMBERCARD_CARD_NUMBER_LABEL, MEMBERCARD_CARD_TYPE_LABEL, MEMBERCARD_LABEL, PRODUCTID_DEPARTURE } from "../config/commonConstants";
// import { EMAIL_POLICY } from "../config/commonConstants";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PRODUCTID_ARRIVAL, PRODUCTID_ARRIVALBUNDLE } from "../config/commonConstants";
import LoadingSkeleton from "../components/commonComponents/LoadingSkeleton";
import ErrorMessages from "../components/commonComponents/ErrorMessages";
import VipMembershipCard from "../components/commonComponents/VipMembershipCard";
import ChangeMemberPinModal from "../components/commonComponents/ChangeMemberPinModal";
import ResetMemberPinModal from "../components/commonComponents/ResetMemberPinModal";
import useToggle from "../hooks/useToggle";
import md5 from "md5";
import { VIPER_Response_Codes } from "../config/viper-response-codes";
import { setSelectedMarket, selectedMarketId as SM } from "../redux/marketRedux";
import { logindetails as LD } from "../redux/signInRedux";
import ExitButton from "../components/commonComponents/ExitButton";
import { userDefault as UD } from "../redux/signInRedux";

function CreditBooking() {
  const { t } = useTranslation(["common", "creditbooking"]);
  const currencyCode = useSelector(selectedMarketCurrency);
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const { bookingKey } = useParams();
  const [loading, setLoading] = useState(true);
  const [orderDetail, setOrderDetail] = useState([]);
  const [apiResponse, setApiResponse] = useState([]);
  const [airportId, setAirportId] = useState("");
  const [responseGetMemberCards, setResponseGetMemberCards] = useState([]);
  const [vipMemberCardsList, setVipMemberCardsList] = useState([]);
  const [defaultTab, setDefaultTab] = useState();
  const [resetMemberPinStatus, setResetMemberPinStatus] = useState(true);
  const [isVisibleChangeMemberPin, setIsVisibleChangeMemberPin] = useToggle();
  const [isVisibleResetMemberPin, setIsVisibleResetMemberPin] = useState(false);
  const [status, setStatus] = useState(null);
  const [availableBalance, setAvailableBalance] = useState(0);
  const [linkCardStatus, setLinkCardStatus] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const sendBroadcastMessage = useOutletContext()
  const selectedMarketId = useSelector(SM);
  const [isFirstComplete,setIsFirstComplete] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [submitOnClickDisable, setSubmitOnClickDisable] = useState(false);
  const loginDetails = useSelector(LD);
  const [fromSelectedMemberCard, setFromSelectedMemberCard] = useState(false);
  const [convertMd5, setConvertMd5] = useState(false);
  const [amountObj, setAmountObj] = useState({
    balance: 0,
    chargedamount: 0,
    paidamount: 0,
    confirmationnumber: "",
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const [confirmationNumber,setConfirmationNumber]=useState("");
  const [paymentTypes, setPaymentTypes] = useState([]);
  const distributorProfile = getDistributorSubDistributorProfile();
  const userDefault = useSelector(UD);

  const formik_cardholderDetails = useFormik({
    initialValues: {
      amount: amountObj.chargedamount,
      confirmationEmail: "",
      copytoEmail: "",
      name: "",
      email: "",
      cardType: "VISA",
      cardProfile: { value: "GUESTCARD", label: "GUEST CARD" },
      cardNumber: "",
      cardMonth: getCurrentMonth(),
      cardYear: new Date()?.getFullYear()?.toString(),
      cvv: "",
    },
    validationSchema: Yup.object().shape({
      confirmationEmail: Yup.string()
        .trim().required(t("payment:errors.confirmationEmail.required"))
        .matches(EMAIL_POLICY, t("payment:errors.email.valid")),
      copytoEmail: Yup.string()
        .matches(EMAIL_POLICY, t("payment:errors.emailCopyTo.valid")),
      name: Yup.string().trim().required(t("payment:errors.name.required")),
      cardNumber: Yup.string()
        .required(t("payment:errors.cardNumber.required"))
        .matches(/^[0-9]+$/, t("payment:errors.cardNumber.match"))
        .length(16, t("payment:errors.cardNumber.length")),
      cvv: Yup.string().required(t("payment:errors.cvv.required"))
        .length(CARD_CVV_LENGTH, t("payment:errors.cvv.length")),
      email: Yup.string().trim().required(t("payment:errors.email.required"))
        .matches(EMAIL_POLICY, t("payment:errors.email.valid")),
      cardMonth: Yup.string().test(
        'test-credit-card-expiration-date',
        t("payment:errors.cardMonth.match"),
        tmpcardmonth => {
          return validExpiryOrNot(formik_cardholderDetails.values.cardYear, tmpcardmonth);
        }
      ),
      cardYear: Yup.string().test(
        'test-credit-card-expiration-date',
        t(""),
        tmpcardyear => {
          return validExpiryOrNot(tmpcardyear, formik_cardholderDetails.values.cardMonth);   
         }
      ),
    }),
    initialTouched : {cardMonth : true, cardYear : true},
    onSubmit: () => handleSubmit(formik_cardholderDetails.values, "CREDITCARD"),
  });

  const formik_vipmembershipcard = useFormik({
    initialValues: {
      amount: "",
      memberCards: "",
      cardNumber: "",
      email: "",
      cardHolderName: "",
      pin: "",
    },
    validationSchema: Yup.object().shape({
      cardHolderName: Yup.string().trim().required(t("payment:errors.memberShip.memberName.length")),
      cardNumber: Yup.string()
        .required(t("payment:errors.memberShip.cardNumber.required", { membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") }))
        .matches(/^[0-9]+$/, t("payment:errors.memberShip.cardNumber.match", { membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") }))
        .length(16, t("payment:errors.memberShip.cardNumber.length", { membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") })),
      pin: Yup.string().required(t("payment:errors.pin.required")),
      memberCards: Yup.mixed().required(t("payment:errors.memberCards.required", { membershipType: translateConstantText("MEMBERCARD_CARD_TYPE_LABEL") })),
    }),
    onSubmit: () => handleSubmit(formik_vipmembershipcard.values, "VIPMEMBERSHIPCARD"),
  });

  useEffect(()=>{
    formik_cardholderDetails.setFieldValue("cardMonth",formik_cardholderDetails.values.cardMonth);
    formik_cardholderDetails.setFieldValue("cardYear",formik_cardholderDetails.values.cardYear);
  },[formik_cardholderDetails.values.cardMonth,formik_cardholderDetails.values.cardYear])

  const changeMarket = async (airportid, cartClear) => {
    let request = {};
    let marketid = getMarketIdFromAirportName(airportid);
    let languageid = getLanguageIdFromAirportName(airportid);
    request = {
      "marketid": marketid,
      "languageid": languageid
    }
    if (marketid === selectedMarketId) {
      // Do nothing
    } else {
      dispatch(setSelectedMarket(marketid));
      try {
        setLoading(true);
        await setMarketId(request);
        await marketChangeBroadcast(sendBroadcastMessage, marketid, cartClear);
      } catch (e) {
        HandleAPIError(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const getCreditBookingDetails = async () => {
    const data = {
      "bookingkey": bookingKey,
    };
    try {
      const response = await getCreditBooking(data);
      if (response?.data?.paidamount >= response?.data?.chargedamount  ) {
        setSuccess(true);
      }
      if (response?.statusMessage !== "Sucesss" && response?.status !== 0) {
        setError(response?.statusMessage);
      } else {
        setAmountObj({
          balance: response.data.balance,
          chargedamount: response.data.chargedamount,
          paidamount: response.data.paidamount,
          confirmationnumber: response.data.confirmationnumber,
        });
        setOrderDetail(response.data?.orderdetail);
        setApiResponse(response.data);
        setConfirmationNumber(response.data.confirmationnumber);
        formik_cardholderDetails.setFieldValue("amount", response.data.balance)
        formik_vipmembershipcard.setFieldValue("amount", response.data.balance)
        if (response.data.orderdetail.productid === PRODUCTID_ARRIVALBUNDLE) {
          response.data.orderdetail.arrivalbundles.map(async (record) => {
            if (record.arrivalrecord) {
              await changeMarket(record.arrivalrecord.airportid, false);
              setAirportId(record.arrivalrecord.airportid);
            } else if (record.departurerecords) {
              record.departurerecords.forEach(async (departureRecord) => {
                await changeMarket(departureRecord.airportid, false);
                setAirportId(departureRecord.airportid);
              });
            }
          });
        } else if (response.data.orderdetail.productid === PRODUCTID_ARRIVAL) {
          response.data.orderdetail.arrivalrecords.map(
            async (arrivalrecord) => {
              await changeMarket(arrivalrecord.airportid, false);
              setAirportId(arrivalrecord.airportid);
            }
          );
        } else {
          response.data.orderdetail.departurerecords.map(
            async (departureRecord) => {
              await changeMarket(departureRecord.airportid, false);
              setAirportId(departureRecord.airportid);
            }
          );
        }
      }
    } catch (err) {
      HandleAPIError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(()=>{
    externalLogin(location,sendBroadcastMessage,navigate,dispatch,setIsFirstComplete)
  },[])

  useEffect(() => {
    if(isFirstComplete){
      loginDetails?.sessionid && getCreditBookingDetails();
    }
  }, [loginDetails,isFirstComplete]);

  const handleVIPMembershipHoldFunds = async () => {
    try {
      // setLoading(true);
      const processMemberCardHoldFundReponse = await processMemberCard({
        "action": "HOLDFUNDS",
        "memberid": formik_vipmembershipcard?.values?.memberCards?.value + formik_vipmembershipcard?.values?.cardNumber,
        "pin": linkCardStatus ? formik_vipmembershipcard?.values?.pin : md5(formik_vipmembershipcard?.values?.pin),
        "amount": currencyCode !== DEFAULT_CURRENCYCODE ? convertAmountToUSDFloat(formik_vipmembershipcard?.values?.amount) : formik_vipmembershipcard?.values?.amount
      });
      if (processMemberCardHoldFundReponse !== null) {
        return processMemberCardHoldFundReponse;
      } else {
        showError(t("error"), processMemberCardHoldFundReponse.statusMessage);
        return null;
      }
    } catch (error) {
      showError(t("error"), error.message);
      return null;
    } finally {
      // setLoading(false);
    }
  };

  const createCreditBookingRequest = async (objPayment, paymentType) => {
    let request = {};
    if (paymentType === "CREDITCARD") {
      request = {
        paymentrecord: {
          productid: orderDetail?.productid,
          confirmationnumber: apiResponse?.confirmationnumber,
          charged: "Y",
          creditcard: {
            cardtype: formik_cardholderDetails?.values.cardType,
            cardnumber: formik_cardholderDetails?.values.cardNumber.slice(-4),
            currency: currencyCode,
            cardholder: formik_cardholderDetails?.values.name,
            email: formik_cardholderDetails?.values.email,
            amount: objPayment.creditcard.amount,
            authorizationnumber: objPayment?.creditcard?.authorizationnumber,
          },
        },
      };
    } else if (paymentType === "VIPMEMBERSHIPCARD") {
      request = {
        paymentrecord: {
          "productid": orderDetail?.productid,
          "confirmationnumber": apiResponse?.confirmationnumber,
          "charged": "Y",
          "membercard": {
            "cardtype": formik_vipmembershipcard?.values?.memberCards?.value,
            "cardnumber": formik_vipmembershipcard?.values?.cardNumber,
            "cardholder": formik_vipmembershipcard?.values?.cardHolderName,
            "currency": DEFAULT_CURRENCYCODE,
            "amount": currencyCode !== DEFAULT_CURRENCYCODE ? convertAmountToUSDFloat(amountObj.balance) : amountObj.balance,
          },
        }
      };
    }
    return request;
  };

  const handleSubmit = async (values, paymentType) => {
    let objPayment = {};
    let callConfirmCreditBooking = false;
    if (paymentType === "CREDITCARD") {
      try {
        setLoading(true);
        setSubmitOnClickDisable(true);
        objPayment = {
          creditcard: {
            cardnumber: formik_cardholderDetails?.values?.cardNumber.slice(-4),
            authorizationnumber: "123456",
            amount: amountObj.balance,
          },
        };

        const creditBookingPaymentLogRequest = await createCreditBookingRequest(objPayment, paymentType);
        const paymentData = {
          source: "OBI-CREDITBOOKING",
          amount: amountObj.balance,
          cardholderDetails: formik_cardholderDetails?.values,
        };
        const responseCC = await processCreditCardPayment(paymentData, creditBookingPaymentLogRequest);
        objPayment = {
          creditcard: {
            cardnumber: responseCC?.data?.cardnumber,
            authorizationnumber: responseCC?.data?.authorizationnumber,
            amount: amountObj.balance,
          },
        }
        if (responseCC && Object.keys(responseCC).length !== 0 && responseCC?.data?.authorizationnumber && responseCC?.data?.authorizationnumber !== "") {
          callConfirmCreditBooking = true;
        };
      } catch (e) {
        HandleAPIError(e);
      } finally {
        setSubmitOnClickDisable(false);
        setLoading(false);
      }
    } else if (paymentType === "VIPMEMBERSHIPCARD") {
      const processMemberCardReponse = await handleVIPMembership();
      if (processMemberCardReponse.status === 0) {
        if (processMemberCardReponse?.data?.reset === "Y") {
          setIsVisibleChangeMemberPin();
          setAvailableBalance(processMemberCardReponse?.data?.amount);
        } else {
          const processMemberCardHoldFundReponse = await handleVIPMembershipHoldFunds();
          if (processMemberCardHoldFundReponse.status === 0) {
            callConfirmCreditBooking = true;
            objPayment = {};
          }
        }
      } else {
        showError(t("error"), processMemberCardReponse.statusMessage);
        setSubmitOnClickDisable(false);
      }
    }

    if (callConfirmCreditBooking === true) {
      try {
        setLoading(true);
        const request = await createCreditBookingRequest(objPayment, paymentType);
        const response = await confirmCreditBooking(request);
        const bookingInfo = getPassengerDetails(orderDetail);
        if (response) {
          if (response.status === 0) {
            const successInfo = {
              messageHeading: t("payment:successInfoCreditHeading"),
              successMessage: t("creditbooking:thanksForPaymentMessage", { currencyCode: getCurrencyCodeFromMarket(getMarketIdFromAirportName(airportId)), amount: amountObj.balance.toFixed(2), email: (paymentType === "creditcard") ? formik_cardholderDetails?.values?.confirmationEmail : apiResponse?.contactemail }),
              commonSuccessMessage: t('successpage:confirmationMessage', {
                confirmationnumber: response?.data?.confirmationnumber,
                club: bookingInfo.club,
                product: getProductName(orderDetail.productid),
                date: bookingInfo.date,
                adultCount: bookingInfo?.adultCount,
                childCount: bookingInfo?.childCount,
                infantCount: bookingInfo?.infantCount,
                email: (paymentType === "creditcard") ? formik_cardholderDetails?.values?.confirmationEmail : apiResponse?.contactemail,
                adultAge: getAdultMinimumAge()
              }),
              jePromotion: true,
              jemPromotion: true,
            };
            navigate("/common-success", { state: successInfo });
          } else {
            showError(t("error"), response.statusMessage);
            setSubmitOnClickDisable(true);
          }
        } else {
          setSubmitOnClickDisable(false);
        }
      } catch (e) {
        HandleAPIError(e);
        setSubmitOnClickDisable(false);
      } finally {
        // setSubmitOnClickDisable(true);
        setLoading(false);
      }
    }
  }

  const getMemberCardAvailableForPayment = async (productid) => {
    let getmembercardsRequest = {}
    if (productid === PRODUCTID_ARRIVAL) {
      getmembercardsRequest = { ...getmembercardsRequest, "arrivalonly": "Y" }
    }
    else if (productid === PRODUCTID_DEPARTURE) {
      getmembercardsRequest = { ...getmembercardsRequest, "departurelounge": "Y" }
    }
    else if (productid === PRODUCTID_ARRIVALBUNDLE) {
      getmembercardsRequest = { ...getmembercardsRequest, "arrivalbundle": "Y" }
    }

    const responseGetMemberCards = await getMemberCards(getmembercardsRequest);
    setResponseGetMemberCards(responseGetMemberCards?.data);
    let memberCardsArray = [];
    responseGetMemberCards?.data?.map(e => memberCardsArray.push({ value: e?.cardid, label: e?.cardname, showValue: e?.showvalue }));
    setVipMemberCardsList(memberCardsArray);
  }

  const verifyNameMemberCard = async (pin) => {
    try {
      setLoading(true);
      let response = null;
      const verifynameMemberCardReponse = await processMemberCard({
        "action": "VERIFYNAME",
        "memberid": formik_vipmembershipcard?.values?.memberCards?.value + formik_vipmembershipcard?.values?.cardNumber,
        "name": formik_vipmembershipcard?.values?.cardHolderName,
        "pin": pin,
      });
      if (verifynameMemberCardReponse?.status === 0) {
        if (verifynameMemberCardReponse?.data?.reset === "Y") {
          setIsVisibleChangeMemberPin();
        } else {
          setStatus(verifynameMemberCardReponse?.status);
          setAvailableBalance(verifynameMemberCardReponse?.data?.amount);
          return verifynameMemberCardReponse;
        }
      } else {
        showError(t("error"), verifynameMemberCardReponse?.statusMessage);
        setStatus(null); // Reset the status if there is an error
        // return null;
      }
      return response
    } catch (error) {
      showError(t("error"), error.message);
    } finally {
      setLoading(false);
    }
  }

  // const validateMemberCard = async (pin) => {
  //   try {
  //     setStatus(false);
  //     setLoading(true);
  //     let response = null;
  //     const validateMemberCardReponse = await processMemberCard({
  //       "action": "VALIDATE",
  //       "memberid": formik_vipmembershipcard?.values?.memberCards?.value + formik_vipmembershipcard?.values?.cardNumber,
  //       // "name": vipmembershipcardObject?.cardHolderName,
  //       "pin": pin,
  //       "allowexpired": "N"
  //     });
  //     if (validateMemberCardReponse?.status === 0) {
  //       if (validateMemberCardReponse?.data?.reset === "Y") {
  //         // reset modal displayed
  //         setIsVisibleChangeMemberPin();
  //       } else {
  //         setStatus(validateMemberCardReponse?.status);
  //         setAvailableBalance(validateMemberCardReponse?.data?.amount);
  //         response = validateMemberCardReponse;
  //       }
  //     } else if (linkCardStatus && validateMemberCardReponse?.status === VIPER_Response_Codes.MAPPED_MEMBERCARD_INVALID_PIN) {
  //       setIsVisibleResetMemberPin(true);
  //       setStatus(null); // Reset the status if there is an error
  //       // return null;
  //     } else {
  //       showError(t("error"), validateMemberCardReponse?.statusMessage);
  //       setStatus(null); // Reset the status if there is an error
  //       // return null;
  //     }
  //     return response;
  //   } catch (error) {
  //     showError(t("error"), error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // }


  const handleVIPMembership = async (newPin = "") => {
    let pin = "";
    if (newPin === "") {
      if (linkCardStatus === true && convertMd5 === true) {
        pin = md5(formik_vipmembershipcard?.values?.pin)
      }
      else {
        pin = linkCardStatus ? formik_vipmembershipcard?.values?.pin : md5(formik_vipmembershipcard?.values?.pin);
      }
    } else {
      pin = md5(newPin)
    }
    try {
      setStatus(false);
      setLoading(true);
      let action = fromSelectedMemberCard ? "VALIDATE" : "VERIFYNAME";
      let response = null;
      if (action === "VERIFYNAME") {
        response = await verifyNameMemberCard(pin);
      } else {
        // response = await validateMemberCard(pin)
      }
      return response;
    } catch (error) {
      showError(t("error"), error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  // const handleVIPMembershipVerifyName = async (newPin = "") => {
  //   let pin = newPin;
  //   if (newPin !== "") {
  //     pin = md5(newPin)
  //   }

  //   if (pin === "") {
  //     pin = linkCardStatus ? formik_vipmembershipcard?.values?.pin : md5(formik_vipmembershipcard?.values?.pin);
  //   }

  //   try {
  //     setStatus(false);
  //     setLoading(true);
  //     const validateMemberCardReponse = await processMemberCard({
  //       "action": "VALIDATE",
  //       "memberid": formik_vipmembershipcard?.values?.memberCards?.value + formik_vipmembershipcard?.values?.cardNumber,
  //       // "name": vipmembershipcardObject?.cardHolderName,
  //       "pin": pin,
  //       "allowexpired": "N"
  //     });
  //     if (validateMemberCardReponse?.status === 0) {
  //       if (validateMemberCardReponse?.data?.reset === "Y") {
  //         // reset modal displayed
  //         setIsVisibleChangeMemberPin();
  //       } else {
  //         const verifynameMemberCardReponse = await processMemberCard({
  //           "action": "VERIFYNAME",
  //           "memberid": formik_vipmembershipcard?.values?.memberCards?.value + formik_vipmembershipcard?.values?.cardNumber,
  //           "name": formik_vipmembershipcard?.values?.cardHolderName,
  //           "pin": pin,
  //         });
  //         if (verifynameMemberCardReponse?.status === 0) {
  //           setStatus(verifynameMemberCardReponse?.status);
  //           setAvailableBalance(verifynameMemberCardReponse?.data?.amount);
  //           return verifynameMemberCardReponse;
  //         } else {
  //           showError(t("error"), verifynameMemberCardReponse?.statusMessage);
  //           setStatus(null); // Reset the status if there is an error
  //           return null;
  //         }
  //       }
  //     } else if (linkCardStatus && validateMemberCardReponse?.status === VIPER_Response_Codes.MAPPED_MEMBERCARD_INVALID_PIN) {
  //       setIsVisibleResetMemberPin(true);
  //       setStatus(null); // Reset the status if there is an error
  //       return null;
  //     } else {
  //       showError(t("error"), validateMemberCardReponse?.statusMessage);
  //       setStatus(null); // Reset the status if there is an error
  //       return null;
  //     }
  //   } catch (error) {
  //     showError(t("error"), error.message);
  //     return null;
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    const allowedPaymentTypesDetail = generatePaymentMenu(distributorProfile, userDefault);
    setPaymentTypes(allowedPaymentTypesDetail);
  }, [distributorProfile, userDefault]);

  const handlePinSuccessAndVerify = async (newPin) => {
    formik_vipmembershipcard.setFieldValue("pin", newPin);
    await handleVIPMembership(newPin);
  };

  const handleSelect = async (key) => {
    if (key === "vipmembership") {
      getMemberCardAvailableForPayment(orderDetail.productid);
    }
  }

  return (
    <Card>
      <Loader loading={loading} message={t("loaderMessage")} />
      <ChangeMemberPinModal
        visible={isVisibleChangeMemberPin}
        linkCardStatus={linkCardStatus}
        setResetMemberPinStatus={setResetMemberPinStatus}
        message={"this is change password modal"}
        vipmembershipcardObject={formik_vipmembershipcard?.values}
        cancelModal={async () => {
          setIsVisibleChangeMemberPin(false);
          setIsVisibleResetMemberPin(false);
        }}
        onSuccess={handlePinSuccessAndVerify}
      />
      <ResetMemberPinModal
        visible={isVisibleResetMemberPin}
        setResetMemberPinStatus={setResetMemberPinStatus}
        vipmembershipcardObject={formik_vipmembershipcard?.values}
        setIsVisibleChangeMemberPin={async () => {
          setIsVisibleChangeMemberPin(true);
          setIsVisibleResetMemberPin(false);
        }}
        cancelModal={async () => {
          setIsVisibleResetMemberPin(false);
        }}
        setVipmembershipcardPin={async (newPin) => {
          formik_vipmembershipcard.setFieldValue("pin", newPin);
        }}
        onSuccess={handlePinSuccessAndVerify}
        setStatus={setStatus}
      />
      <section className="d-flex justify-content-center align-content-center mt-3">
        <h3 className="my-3 d-flex justify-content-center heading heading">
          <span className="heading">{t("creditbooking:creditBookingHeading")}</span>
        </h3>
      </section>
      {!error && amountObj?.paidamount < amountObj?.chargedamount  ? (
          <section className="d-flex justify-content-center">
            <Container className="my-4" ref={divRef}>
              <Row className="justify-content-end my-3">
                <Col md={6} className="d-flex justify-content-end mb-2">
                  <ExitButton />
                </Col>
              </Row>

              <Card className="p-4">
                <Row className="d-flex justify-content-start">
                  <Col md={4}>
                    <h6 className="field">{t('fillAllFields')}</h6>
                  </Col>
                </Row>
                <BookingSummaryComponent
                  renderType={"CREDIT-BOOKING"}
                  orderDetail={orderDetail}
                  productid={orderDetail.productid}
                  confirmationNumber={confirmationNumber}
                  primaryContact={apiResponse}
                  referenceNumber={apiResponse.referencenumber}
                />
                <hr />
                <Row className="d-flex justify-content-center align-content-center mb-1">
                  <Col md={4} sm={12}>
                    <h5 className="mb-1">
                      <b>{t("creditbooking:chargedAmount")}:</b>{" "}
                      {getCurrencyCodeFromMarket(
                        getMarketIdFromAirportName(airportId)
                      )}{" "}
                      {amountObj.chargedamount.toFixed(2)}
                    </h5>
                  </Col>
                  <Col md={4} sm={12}>
                    <h5 className="mb-1">
                      <b>{t("creditbooking:paidAmount")}: </b>{" "}
                      {getCurrencyCodeFromMarket(
                        getMarketIdFromAirportName(airportId)
                      )}{" "}
                      {amountObj.paidamount.toFixed(2)}
                    </h5>
                  </Col>
                  <Col md={4} sm={12}>
                    <h5 className="mb-1">
                      <b>{t("creditbooking:balance")}:</b>{" "}
                      {getCurrencyCodeFromMarket(
                        getMarketIdFromAirportName(airportId)
                      )}{" "}
                      {amountObj.balance.toFixed(2)}
                    </h5>
                  </Col>
                </Row>
              </Card>

              {/* <div className="mt-3">
                <h3>{t("payment")}</h3>
                <CreditCard
                  formik={formik_cardholderDetails}
                  isSubmitDisabled={
                    submitOnClickDisable ? submitOnClickDisable : false
                  }
                  onClick={(e) => {
                    scrollToDiv(e, formik_cardholderDetails);
                  }}
                  onChange={(key, val) => {
                    formik_cardholderDetails.setFieldValue(key, val);
                  }}
                  onBlur={(key, e) => {
                    formik_cardholderDetails.handleBlur(e);
                  }}
                />
              </div> */}
              <Tabs
                defaultActiveKey={defaultTab}
                id="justify-tab-example"
                className="mt-4"
                onSelect={(e) => handleSelect(e)}
            >
              {paymentTypes?.allowedPaymentTypes?.find((p) => p === "creditcard") && (
                <Tab eventKey="creditcard" title={t("payment:creditCard")}>
                  {loading ? <LoadingSkeleton /> :
                    <Row className="justify-content-center">
                      <Col md={8}>
                        <Form>
                          <ErrorMessages formik={formik_cardholderDetails} />
                          <Card className="bg-light bg-opacity-10 rounded-3 px-0 mb-2">
                            <Card.Body>
                              <Row className="g-3">
                                <Col md={6}>
                                  <Form.Label
                                    className="d-flex"
                                    htmlFor="validationCustomemail"
                                  >
                                    {t("payment:sendConfirmationEmailTo")}:
                                    <span className="field">*</span>
                                  </Form.Label>
                                  <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend">
                                      @
                                    </InputGroup.Text>
                                    <Form.Control
                                      type="email"
                                      name="confirmationEmail"
                                      id="validationCustomemail"
                                      placeholder={t("emailAddress")}
                                      value={
                                        formik_cardholderDetails.values
                                          .confirmationEmail
                                      }
                                      onBlur={formik_cardholderDetails.handleBlur}
                                      onChange={
                                        formik_cardholderDetails.handleChange
                                      }
                                      className={
                                        formik_cardholderDetails.touched
                                          .confirmationEmail &&
                                          formik_cardholderDetails.errors
                                            .confirmationEmail
                                          ? "error"
                                          : ""
                                      }
                                      aria-describedby="inputGroupPrepend"
                                      maxLength={EMAIL_MAXLENGTH}
                                      minLength={1}
                                    />
                                    <Form.Control.Feedback>
                                      {t("payment:looksGood")}
                                    </Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                      {t("chooseEmailAddress")}.
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                                <Col md={6}>
                                  <Form.Label htmlFor="validationCustomcopyemail">
                                    {t("payment:copyTo")} ({t("payment:optional")})
                                  </Form.Label>
                                  <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend">
                                      @
                                    </InputGroup.Text>
                                    <Form.Control
                                      type="email"
                                      name="copytoEmail"
                                      id="validationCustomcopyemail"
                                      placeholder={t("emailAddress")}
                                      aria-describedby="inputGroupPrepend"
                                      value={
                                        formik_cardholderDetails.values.copytoEmail
                                      }
                                      onBlur={formik_cardholderDetails.handleBlur}
                                      onChange={
                                        formik_cardholderDetails.handleChange
                                      }
                                      maxLength={EMAIL_MAXLENGTH}
                                      minLength={1}
                                      className={
                                        formik_cardholderDetails.touched
                                          .copytoEmail &&
                                          formik_cardholderDetails.errors.copytoEmail
                                          ? "error"
                                          : ""
                                      }
                                    />
                                    <Form.Control.Feedback>
                                      {t("payment:looksGood")}
                                    </Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                      {t("chooseEmailAddress")}.
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                                <Col md={"auto"}>
                                  <Form.Label
                                    className="d-flex"
                                    htmlFor="validationCustomPrice"
                                  >
                                    {t("amount")} ({currencyCode})
                                  </Form.Label>
                                  <Form.Control
                                    disabled
                                    id="validationCustomPrice"
                                    type="text"
                                    placeholder=""
                                    value={formik_cardholderDetails.values.amount.toFixed(
                                      2
                                    )}
                                    className=""
                                  />
                                  <Form.Control.Feedback>
                                    {t("payment:looksGood")}
                                  </Form.Control.Feedback>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                          <CreditCard
                            formik={formik_cardholderDetails}
                            isSubmitDisabled={
                              submitOnClickDisable ? submitOnClickDisable : false
                            }
                            onClick={(e) => {
                              scrollToDiv(e, formik_cardholderDetails);
                            }}
                            onChange={(key, val) => {
                              formik_cardholderDetails.setFieldValue(key, val);
                            }}
                            onBlur={(key, e) => {
                              formik_cardholderDetails.handleBlur(e);
                            }}
                          />
                        </Form>
                      </Col>
                    </Row>
                  }
                </Tab>
              )}
              {paymentTypes?.allowedPaymentTypes?.find((p) => p === "vipmembership") && (
                <Tab
                  eventKey="vipmembership"
                  title={t("payment:vipMembershipCard", {
                    vipMembershipLabel: translateConstantText("MEMBERCARD_LABEL"),
                  })}
                >
                  {loading ? <LoadingSkeleton /> :
                    <Form>
                      <ErrorMessages formik={formik_vipmembershipcard} />
                      <VipMembershipCard
                        type={"CREDIT-BOOKING"}
                        linkCardStatus={linkCardStatus}
                        vipMemberCardsList={vipMemberCardsList}
                        responseGetMemberCards={responseGetMemberCards}
                        // passwordChange={passwordHandler}
                        formik={formik_vipmembershipcard}
                        isSubmitDisabled={
                          submitOnClickDisable ? submitOnClickDisable : false
                        }
                        onClick={(e) => {
                          scrollToDiv(e, formik_vipmembershipcard);
                        }}
                        onChange={(key, val) => {
                          formik_vipmembershipcard.setFieldValue(key, val);
                        }}
                        resetMemberPinStatus={resetMemberPinStatus}
                        availableBalance={availableBalance}
                        submitOnClickDisable={submitOnClickDisable}
                        status={status}
                        setStatus={setStatus}
                        unsetLinkMemberCard={() => {
                          setStatus(null);
                          setLinkCardStatus(false);
                          formik_vipmembershipcard.resetForm();
                        }}
                        onSubmitButtonClick={async (event) => {
                          const processMemberCardResponse =
                            await handleVIPMembership();
                          if (processMemberCardResponse?.status === 0) {
                            setSelectedPaymentType("VIPMEMBERSHIPCARD");
                            handleSubmit(event, "VIPMEMBERSHIPCARD");
                          }
                        }}
                        onVerifyButtonClick={async (event) => {
                          if (
                            formik_vipmembershipcard.values.cardHolderName === "" ||
                            formik_vipmembershipcard.values.pin === ""
                          ) {
                            scrollToDiv(event, formik_vipmembershipcard);
                            return;
                          } else {
                            event?.preventDefault();
                            await handleVIPMembership();
                          }
                        }}
                      />
                    </Form>
                  }
                </Tab>
              )}
              </Tabs>
            </Container>
          </section>
        ) : (
          loading ? <LoadingSkeleton /> : 
          <section>
            <Container className="my-4 text-center">
              <Row className="justify-content-end my-3">
                <Col md={6} className="d-flex justify-content-end mb-2">
                  <ExitButton />
                </Col>
              </Row>
              <Card className="p-4">
                <Row>
                  <Col md={12} className="d-flex justify-content-center">
                    {error ? null : (
                      <h4 className="lh-base">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          size="3x"
                          className="mb-3"
                          color="green"
                        />
                      </h4>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={12} sm={12}>
                    {!error ? (
                      success ?
                        <div className="mb-3 my-bg-info ">
                          {t("creditbooking:payStatus")}
                        </div> :
                        <div className="mb-3 my-bg-info ">
                          {t("login:somethingWentWrong")}
                        </div>
                    ) : (
                      <div className="mb-3 my-bg-info">{error}</div>
                    )}
                  </Col>
                </Row>
              </Card>
            </Container>
          </section>
        )}
    </Card>
  );
}
export default CreditBooking;
