import { createSlice } from "@reduxjs/toolkit";

const paymentGatewaySlice = createSlice({
  name: "paymentGateway",
  initialState: {
    paymentGateway: {},
    error: false,
  },
  reducers: {
    paymentGatewayFetchSuccess: (state, action) => {
      state.paymentGateway = action.payload;
    },
    paymentGatewayFetchFailure: (state) => {
      state.error = true;
    },
  },
});

export const { paymentGatewayFetchSuccess, paymentGatewayFetchFailure } = paymentGatewaySlice.actions;
export const paymentGateway = (state) => state.paymentGatewayRedux?.paymentGateway;
export default paymentGatewaySlice.reducer;