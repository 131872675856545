import { useEffect } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { promotionLogin } from "../components/commonComponents/commonFunctions";
import { useDispatch } from "react-redux";
import HomeLoadingSkeleton from "../components/commonComponents/HomeLoadingSkeleton";
function Promotion() {
  const sendBroadcastMessage = useOutletContext();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const loginKey = location.pathname.split("/")[2];
    const request = {
      loginkey: loginKey,
      prospect: "N",
    };
    promotionLogin(location, sendBroadcastMessage, navigate, request, dispatch);
  }, []);
  return <HomeLoadingSkeleton />;
}
export default Promotion;
