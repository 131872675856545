import { useState } from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Select from "react-select";
import CommonButton from "../components/commonComponents/CommonButton";
import { selectedMarketId as SM, availableMarkets as AM, } from "../redux/marketRedux";
import { CONTACT_NUMBER_MAXLENGTH, EMAIL_MAXLENGTH, FIRST_NAME_MAXLENGTH, FLAG_URL, LAST_NAME_MAXLENGTH, REGEX_ALPHABET, USERNAME_MAXLENGTH } from "../config/commonConstants";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { addEditUser } from "../redux/apiCalls";
import Loader from "../components/commonComponents/Loader";
import { showError } from "../components/commonComponents/Swal";
import { useNavigate } from "react-router-dom";
import { HandleAPIError, divRef, numericValidation, renderErrorMessages, scrollToDiv, alphabetValidation } from "../components/commonComponents/commonFunctions";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessages from "../components/commonComponents/ErrorMessages";
import ExitButton from "../components/commonComponents/ExitButton";
//import { renderErrorMessages } from "../config/commonFunctions";


export default function SubscriberRegistration() {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(["common", "subscriberregistration"]);
  const navigate = useNavigate();

  const country_name_with_flag = [];
  const selectedMarketId = useSelector(SM);
  const availableMarkets = useSelector(AM);

  availableMarkets?.data?.markets?.map((m) => {
    return country_name_with_flag.push({
      value: m?.marketid,
      label: (
        <div>
          <img
            src={FLAG_URL + m?.image}
            width="30"
            className="d-inline-block align-left mx-1"
            alt={m?.marketname}
          />{" "}
          {m?.marketname}
        </div>
      ),
    });
  });
  const validationSchema = Yup.object().shape({
    username: Yup.string().trim().required(t("subscriberregistration:errors.userName.required")).max(USERNAME_MAXLENGTH),
    Email: Yup.string()
      .email(t("subscriberregistration:errors.email.valid"))
      .trim().required(t("subscriberregistration:errors.email.required"))
      .max(EMAIL_MAXLENGTH),
    firstName: Yup.string().trim().required(
      t("subscriberregistration:errors.firstName.required")
    ).max(FIRST_NAME_MAXLENGTH),
    LastName: Yup.string().trim().required(t("subscriberregistration:errors.lastName.required")).max(LAST_NAME_MAXLENGTH),
    Phone: Yup.string().trim().required(t("subscriberregistration:errors.phone.required")),
    MarketId: Yup.string().trim().required(t("subscriberregistration:errors.MarketId.required")),
  });
  /*  const handleFormSubmit = () => {
     handleSubmit(formik.values);
   }; */
  const formik = useFormik({
    initialValues: {
      username: "",
      Email: "",
      firstName: "",
      LastName: "",
      Phone: "",
      MarketId: selectedMarketId,
    },
    validationSchema,
    onSubmit: () => { handleSubmit(formik.values) },
  });

  const handleSubmit = async (formik) => {
    //  event.preventDefault();
    let isFormValid = true; // Add a variable to track form validity
    if (isFormValid) {
      // Check the updated formValidity variable
      const data = {
        "username": formik.username,
        "firstname": formik.firstName,
        "lastname": formik.LastName,
        "email": formik.Email,
        "marketid": formik.MarketId,
        "subscriber": "Y",
        "reportagent": "N",
        "adminuser": "N",
        "phone": formik.Phone,
      };
      setLoading(true);

      try {
        const response = await addEditUser(data, "N", "N");
        if (response?.status === 0) {
          const successInfo = {
            messageHeading: t("subscriberregistration:registertionComplete"),
            successMessage: t('subscriberregistration:successMessage')
          }
          navigate("/common-success", { state: successInfo });
        }
        else {
          showError(t("error"), response?.statusMessage);
        }
      } catch (e) {
        HandleAPIError(e);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <section>
        <Container className="my-4" ref={divRef}>
          <Loader
            loading={loading}
            message={t("subscriberregistration:loaderMessage")}
          />
          <h3 className="d-flex justify-content-center">
            <span className="heading">
              {t("subscriberregistration:subscriberRegistration")}
            </span>
          </h3>
          <Row className="d-flex justify-content-center">
            <Col lg="5">
              <div className="mt-2 d-flex justify-content-end">
                <ExitButton />
              </div>
              <Card className="bg-light bg-opacity-10 rounded-3 p-2 my-3">
                <Card.Body>
                  <Row className="d-flex justify-content-start">
                    <Col md={12}>
                      <h6 className="field">{t("fillAllFields")}</h6>
                    </Col>
                  </Row>
                  <ErrorMessages formik={formik} />
                  <Card.Text className="d-flex justify-content-center"></Card.Text>
                  <Form onSubmit={(e) => scrollToDiv(e, formik)}>
                    <Row>
                      <Col md="12" className="mb-3">
                        <Form.Label
                          className="d-flex"
                          htmlFor="validationCustomUsername"
                        >
                          {t("userName")}
                          <span className="field">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          id="validationCustomUsername"
                          placeholder={t("userName")}
                          maxLength={USERNAME_MAXLENGTH}
                          aria-describedby="inputGroupPrepend"
                          name="username"
                          value={formik.values.username}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "username",
                              e.target.value.replace(/[^a-zA-Z0-9\s]/g, "")
                            )
                          }
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.username && formik.errors.username
                              ? "error"
                              : ""
                          }
                        />
                        {formik.touched.username}
                      </Col>
                      <Col md="12" className="mb-3">
                        <Form.Label
                          className="d-flex"
                          htmlFor="validationCustomEmail"
                        >
                          {t("email")}
                          <span className="field">*</span>
                        </Form.Label>
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            @
                          </InputGroup.Text>
                          <Form.Control
                            type=""
                            id="validationCustomEmail"
                            placeholder={t("email")}
                            aria-describedby="inputGroupPrepend"
                            name="Email"
                            maxLength={EMAIL_MAXLENGTH}
                            value={formik.values.Email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.Email && formik.errors.Email
                                ? "error"
                                : ""
                            }
                          />
                        </InputGroup>
                        {formik.touched.Email}
                      </Col>
                      <Col md="12" className="mb-3">
                        <Form.Label
                          className="d-flex"
                          htmlFor="validationCustomFirstName"
                        >
                          {t("firstName")}
                          <span className="field">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t("firstName")}
                          aria-describedby="inputGroupPrepend"
                          name="firstName"
                          maxLength={FIRST_NAME_MAXLENGTH}
                          value={formik.values.firstName}
                          // onChange={(e) => {
                          //   e.target.value = e.target.value?.replace(REGEX_ALPHABET,"");
                          //   formik.handleChange(e);
                          // }}      
                          onChange={(e) => {
                            e.target.value = alphabetValidation(e.target.value)
                            formik.handleChange(e);
                          }}                    
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.firstName && formik.errors.firstName
                              ? "error"
                              : ""
                          }
                        />
                        {formik.touched.firstName}
                      </Col>
                      <Col md="12" className="mb-3">
                        <Form.Label
                          className="d-flex"
                          htmlFor="validationCustomLastName"
                        >
                          {t("lastName")}
                          <span className="field">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          id="validationCustomlastname"
                          placeholder={t("lastName")}
                          maxLength={LAST_NAME_MAXLENGTH}
                          aria-describedby="inputGroupPrepend"
                          name="LastName"
                          value={formik.values.LastName}
                          // onChange={(e)=>{
                          //   e.target.value = e.target.value.replace(REGEX_ALPHABET,"")
                          //   formik.handleChange(e)}}
                          onChange={(e) => {
                            e.target.value = alphabetValidation(e.target.value)
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.LastName && formik.errors.LastName
                              ? "error"
                              : ""
                          }
                        />
                        {formik.touched.LastName}
                      </Col>
                      <Col md="12" className="mb-3">
                        <Form.Label
                          className="d-flex"
                          htmlFor="validationCustomPhone"
                        >
                          {t("phone")}
                          <span className="field">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          id="validationCustomPhone"
                          placeholder={t("phone")}
                          aria-describedby="inputGroupPrepend"
                          maxLength={CONTACT_NUMBER_MAXLENGTH}
                          name="Phone"
                          value={formik.values.Phone}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "Phone",
                              numericValidation(e.target.value)
                            )
                          }
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.Phone && formik.errors.Phone
                              ? "error"
                              : ""
                          }
                        />                    
                        {formik.touched.Phone}
                      </Col>
                      <Col md="12" className="mb-3">
                        <Form.Label
                          className="d-flex"
                          htmlFor="validationCustomMarket"
                        >
                          {t("market")}
                          <span className="field">*</span>
                        </Form.Label>
                        <InputGroup className="d-flex">
                          <div
                            style={{
                              color: "black",
                              zIndex: "998",
                              width: "350px",
                            }}
                          >
                            <Select
                              name="MarketId"
                              value={country_name_with_flag.find(
                                (option) =>
                                  option.value === formik.values.MarketId
                              )}
                              autosize={true}
                              options={country_name_with_flag}
                              onChange={(d) => {
                                formik.setFieldValue("MarketId", d.value);
                              }}
                              onBlur={formik.handleBlur}
                              className={
                                formik.touched.MarketId &&
                                formik.errors.MarketId
                                  ? "error rounded-2 w-100"
                                  : "rounded-2 w-100"
                              }
                            />
                            {formik.touched.MarketId}
                          </div>
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="text-center">
                        <CommonButton
                          type="submit"
                          classname="gradient-btn "
                          size="md"
                          title={t("subscriberregistration:register")}
                        />
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
