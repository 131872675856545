import { Card, Row, Col, Container } from "react-bootstrap";
import {
  clearVIPERCart,
  getBookingKeyOrders,
  getSchedule,
  setMarketId,
  updateBooking,
} from "../redux/apiCalls";
import { useEffect, useState } from "react";
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import { showError, showSuccess } from "../components/commonComponents/Swal";
import BookingSummaryComponent from "../components/commonComponents/BookingSummaryComponent";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import Loader from "../components/commonComponents/Loader";
import {
  externalLogin,
  HandleAPIError,
  dateFormatForDisplay,
  divRef,
  getAirlineNames,
  getFlightNumbers,
  getLoginDetails,
  isObjectEmpty,
  myDateFormat,
  renderErrorMessages,
  scrollToDiv,
  isExternalLink,
  marketChangeBroadcast,
  getMarketIdFromAirportName,
  getLanguageIdFromAirportName,
} from "../components/commonComponents/commonFunctions";
import {
  LOUNGE_TYPE,
  PRODUCTID_ARRIVAL,
  PRODUCTID_ARRIVALBUNDLE,
  PRODUCTID_DEPARTURE,
} from "../config/commonConstants";
import CommonButton from "../components/commonComponents/CommonButton";
import { authed as AUTH } from "../redux/signInRedux";
import { useLocation } from "react-router-dom";
import ExitButton from "../components/commonComponents/ExitButton";
import {
  selectedMarket as currentMarket,
  setSelectedMarket,
  selectedMarketId as SM,
} from "../redux/marketRedux";
import { logindetails as LD } from "../redux/signInRedux";
import FlightDetailsComponent from "../components/commonComponents/FlightDetailsComponent";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessages from "../components/commonComponents/ErrorMessages";
import LoadingSkeleton from "../components/commonComponents/LoadingSkeleton";
import HtmlToReactConverter from "../components/commonComponents/HtmlToReactConverter";

function UpdateFlightDetails() {
  const initialBookingDetails = {
    lounge: "",
    flightDate: null,
    airlineName: "",
    airlineId: "",
    flightNumber: "",
    flightTime_hour: "",
    productid: "",
    errors: "",
  };

  const logindetails = useSelector(LD);
  const dispatch = useDispatch();
  const [showSubmit, setShowSubmit] = useState(false);
  const selectedMarket = useSelector(currentMarket);
  const { bookingKey } = useParams();
  const [arrivalRecords, setArrivalRecords] = useState([]);
  const [departureRecords, setDepartureRecords] = useState([]);
  const [orderDetail, setOrderDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const [bookingDetail, setBookingDetail] = useState([]);
  const [airlineId, setAirlineId] = useState([]);
  const [clubType, setClubType] = useState([]);
  const [flightDetails, setFlightDetails] = useState([]);
  const bookingType = ["Arrival Details", "Departure Details"];
  const [arrivalscheduleid, setArrivalscheduleid] = useState();
  const [departurescheduleid, setDeparturescheduleid] = useState();
  const [currentDate, setCurrentDate] = useState(Date());
  const [FlightDateCondition, setFlightDateCondition] = useState();
  const [error, setError] = useState("");
  const [tmparrivalscheduleid, setTmpArrivalscheduleid] = useState(null);
  const [tmpdeparturescheduleid, setTmpDeparturescheduleid] = useState(null);
  const [referenceNumber, setReferenceNumber] = useState("");
  const [confirmationNumber, setConfirmationNumber] = useState(0);
  const [primaryContact, setPrimaryContact] = useState("");
  const [secondaryContact, setSecondaryContact] = useState("");
  const [getBookingKeyOrdersResponse, setGetBookingKeyOrdersResponse] = useState(null);
  const sendBroadcastMessage = useOutletContext();
  const auth = useSelector(AUTH);
  const navigate = useNavigate();
  const location = useLocation();
  const updateFlightStateData = location.state;
  const allFlightsEditable = bookingKey ? getBookingKeyOrdersResponse?.allflightseditable : updateFlightStateData?.allFlightsEditable
  const productid = updateFlightStateData?.response?.data?.orderdetail?.productid;
  const selectedMarketId = useSelector(SM);

  const getDirection = (i, productid) => {
    const Direction =
      productid === PRODUCTID_ARRIVALBUNDLE
        ? LOUNGE_TYPE[i]?.value
        : productid === PRODUCTID_ARRIVAL
        ? LOUNGE_TYPE[0]?.value
        : LOUNGE_TYPE[1]?.value;
    return Direction;
  };

  const handleArrivalDepartureScheduleIdChange = async (
    scheduleid,
    i,
    productid
  ) => {
    if (productid === PRODUCTID_ARRIVALBUNDLE) {
      if (i === 0) {
        await setArrivalscheduleid(scheduleid);
        await setTmpArrivalscheduleid(scheduleid); // tmp for schedule id
        await setDeparturescheduleid(tmpdeparturescheduleid);
      } else {
        await setDeparturescheduleid(scheduleid);
        await setArrivalscheduleid(tmparrivalscheduleid);
        await setTmpDeparturescheduleid(scheduleid); // tmp for schedule id
      }
    } else if (productid === PRODUCTID_ARRIVAL && i === 0) {
      await setArrivalscheduleid(scheduleid);
    } else if (productid === PRODUCTID_DEPARTURE && i === 0) {
      await setDeparturescheduleid(scheduleid);
    }
  };

  const changeMarket = async (airportid, cartClear) => {
    let request = {};
    let marketid = getMarketIdFromAirportName(airportid);
    let languageid = getLanguageIdFromAirportName(airportid);
    request = {
      marketid: marketid,
      languageid: languageid,
    };
    if (marketid === selectedMarketId) {
      // Do nothing
    } else {
      dispatch(setSelectedMarket(marketid));
      try {
        setLoading(true);
        await setMarketId(request);
        await marketChangeBroadcast(sendBroadcastMessage, marketid, cartClear);
      } catch (e) {
        HandleAPIError(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const getFlightData = async () => {
    try {
      if (bookingKey) {
        // When user clicks the link from the email.
        const data = {
          bookingkey: bookingKey,
        };
        const response = await getBookingKeyOrders(data);
        if (response?.status !== 0) {
          setError(response?.statusMessage);
        } else {
          let tmpOrderDetail = response.data.orderdetail;
          setOrderDetail(tmpOrderDetail);
          setPrimaryContact(response?.data?.orderheader);
          setReferenceNumber(response.data.orderheader?.referencenumber);
          setConfirmationNumber(response.data.orderheader?.confirmationnumber);
          setGetBookingKeyOrdersResponse(response?.data?.orderheader);
          if (tmpOrderDetail.productid === PRODUCTID_ARRIVALBUNDLE) {
            let arrivalrecords = [];
            let departurerecords = [];
            tmpOrderDetail.arrivalbundles.map(async (arrivalbundle) => {
              arrivalrecords = arrivalbundle.arrivalrecord;
              departurerecords = arrivalbundle.departurerecords;
              setArrivalRecords([arrivalrecords]);
              setDepartureRecords(departurerecords);
              if (arrivalbundle.arrivalrecord) {
                await changeMarket(
                  arrivalbundle.arrivalrecord.airportid,
                  false
                );
              } else if (arrivalbundle.departurerecords) {
                arrivalbundle.departurerecords.forEach(
                  async (departureRecord) => {
                    await changeMarket(departureRecord.airportid, false);
                  }
                );
              }
            });
          } else if (tmpOrderDetail.productid === PRODUCTID_ARRIVAL) {
            tmpOrderDetail.arrivalrecords.map(async (arrivalrecord) => {
              await changeMarket(arrivalrecord.airportid, false);
            });
            setArrivalRecords(tmpOrderDetail.arrivalrecords);
          } else if (tmpOrderDetail.productid === PRODUCTID_DEPARTURE) {
            tmpOrderDetail.departurerecords.map(async (departureRecord) => {
              await changeMarket(departureRecord.airportid, false);
            });
            setDepartureRecords(tmpOrderDetail.departurerecords);
          }
        }
      } else {
        // From Subscriber booking / Distributor Update Flight Details Form
        const response = updateFlightStateData?.response;
        if (response?.status !== 0) {
          setError(response?.statusMessage);
        } else {
          let tmpOrderDetail;
          if (updateFlightStateData?.type === "UPDATE-FLIGHT-DETAILS-FORM") {
            tmpOrderDetail = response.data.bookings[0].orderdetail;
          } else {
            tmpOrderDetail = response.data.orderdetail;
          }
          setPrimaryContact(response?.data?.primary);
          setSecondaryContact(response?.data?.secondary);
          setOrderDetail(tmpOrderDetail);
          setReferenceNumber(response.data.referencenumber);
          setConfirmationNumber(response.data.confirmationnumber)

          if (tmpOrderDetail.productid === PRODUCTID_ARRIVALBUNDLE) {
            let arrivalrecords = [];
            let departurerecords = [];
            tmpOrderDetail.arrivalbundles.map(
              (arrivalbundle, key) => (
                (arrivalrecords = arrivalbundle.arrivalrecord),
                (departurerecords = arrivalbundle.departurerecords),
                setArrivalRecords([arrivalrecords]),
                setDepartureRecords(departurerecords)
              )
            );
          } else if (tmpOrderDetail.productid === PRODUCTID_ARRIVAL) {
            setArrivalRecords(tmpOrderDetail.arrivalrecords);
          } else if (tmpOrderDetail.productid === PRODUCTID_DEPARTURE) {
            setDepartureRecords(tmpOrderDetail.departurerecords);
          }
        }
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    bookingDetail: Yup.array().of(
      Yup.object().shape({
        // lounge: Yup.object().required(t("journeydetails:errors.lounge.required")),
        flightDate: Yup.date()
          .nullable()
          .test(
            "arrival-flightDate",
            productid === PRODUCTID_ARRIVALBUNDLE ||
              productid === PRODUCTID_ARRIVAL
              ? t("journeydetails:errors.arrivalFlightDate.required")
              : t("journeydetails:errors.departureFlightDate.required"),
            function (value) {
              return this.path !== "bookingDetail[0].flightDate" || value;
            }
          )
          .test(
            "deaprture-flightDate",
            t("journeydetails:errors.departureFlightDate.required"),
            function (value) {
              return this.path !== "bookingDetail[1].flightDate" || value;
            }
          ),
        airlineName: Yup.object()
          .test(
            "arrival-airlineName",
            productid === PRODUCTID_ARRIVALBUNDLE ||
              productid === PRODUCTID_ARRIVAL
              ? t("journeydetails:errors.arrivalAirlineName.required")
              : t("journeydetails:errors.deaptureAirlineName.required"),
            function (value) {
              return this.path !== "bookingDetail[0].airlineName" || value;
            }
          )
          .test(
            "deaprture-airlineName",
            t("journeydetails:errors.deaptureAirlineName.required"),
            function (value) {
              return this.path !== "bookingDetail[1].airlineName" || value;
            }
          ),
        flightNumber: Yup.object()
          .test(
            "arrival-flightNumber",
            productid === PRODUCTID_ARRIVALBUNDLE ||
              productid === PRODUCTID_ARRIVAL
              ? t("journeydetails:errors.arrivalFlightNumber.required")
              : t("journeydetails:errors.departureFlightNumber.required"),
            function (value) {
              return this.path !== "bookingDetail[0].flightNumber" || value;
            }
          )
          .test(
            "deaprture-flightNumber",
            t("journeydetails:errors.departureFlightNumber.required"),
            function (value) {
              return this.path !== "bookingDetail[1].flightNumber" || value;
            }
          ),
      })
    ),
  });

  const initial = {
    bookingDetail: [],
  };

  const formik = useFormik({
    initialValues: initial,
    validationSchema,
    onSubmit: () => {
      handleSubmit(formik.values);
    },
  });

  const prePopulatingFlightData = async () => {
    try {
      const a = [];
      arrivalRecords.forEach((item) => {
        let editable = item?.flighteditable === "Y" ? true : false
          
        editable && setShowSubmit(true);
        const initialBookingDetails = {
          lounge: item.airportid,
          flightDate: myDateFormat(
            item.flightdate,
            "yyyyMMdd HHmm",
            "MM/dd/yyyy"
          ),
          airlineName: {
            value: item?.airlineid,
            label: item?.airline,
          },
          airlineId: item.airlineid,
          flightNumber: {
            value: item?.flightid,
            label: item?.flightid,
          },
          flightTime_hour: myDateFormat(
            item.flightdate,
            "yyyyMMdd HHmm",
            "hh:mm a"
          ),
          errors: "",
          productid: orderDetail?.productid,
          direction: "",
          editable: editable,
          title: t('journeydetails:arrivalDetails')
        };
        a.push({ ...initialBookingDetails });
      });
      departureRecords.forEach((item) => {
        let editable = item?.flighteditable === "Y" ? true : false
        editable && setShowSubmit(true);
        const initialBookingDetails = {
          lounge: item.airportid,
          flightDate: myDateFormat(
            item.flightdate,
            "yyyyMMdd HHmm",
            "MM/dd/yyyy"
          ),
          airlineName: {
            value: item?.airlineid,
            label: item?.airline,
          },
          airlineId: item.airlineid,
          flightNumber: {
            value: item?.flightid,
            label: item?.flightid,
          },
          flightTime_hour: myDateFormat(
            item.flightdate,
            "yyyyMMdd HHmm",
            "hh:mm a"
          ),
          errors: "",
          productid: orderDetail?.productid,
          direction: "",
          editable: editable,
          title: t('journeydetails:departureDetails')
        };
        a.push({ ...initialBookingDetails });
      });
      formik.setFieldValue("bookingDetail", a);
      setBookingDetail(a);
    } catch (e) {
      HandleAPIError(e);
    } finally {
    }
  };

  const prePopulatingAirlineNamesAndFlightNumbers = async () => {
    bookingDetail.forEach(async (item, i) => {
      try {
        const getScheduleRequestData = {
          direction: getDirection(i, item.productid),
          airportid: item.lounge,
          traveldate: dateFormatForDisplay(item.flightDate, "yyyyMMdd"),
        };

        const response = await getSchedule(dispatch, getScheduleRequestData);
        if (response?.status === 0) {
          setFlightDetails([...flightDetails, response?.data]);
        } else {
          // showError(t("error"), response?.statusMessage);
        }
      } catch (e) {
        HandleAPIError(e);
      } finally {
      }
    });
  };

  const [isFirstComplete, setIsFirstComplete] = useState(false);

  useEffect(() => {
    externalLogin(
      location,
      sendBroadcastMessage,
      navigate,
      dispatch,
      setIsFirstComplete
    );
  }, []);

  useEffect(() => {
    if (isFirstComplete) {
      getLoginDetails()?.sessionid && getFlightData();
    }
  }, [logindetails, isFirstComplete]);

  useEffect(() => {
    bookingDetail.length === 0 && prePopulatingFlightData();
    (flightDetails.length === 0 || flightDetails.length === 1) &&
      prePopulatingAirlineNamesAndFlightNumbers();
  }, [
    arrivalRecords,
    departureRecords,
    flightDetails.length,
    bookingDetail.length,
    FlightDateCondition,
  ]);

  const createUpdateBookingRequest = async () => {
    let schedulelist = [];
    bookingDetail.forEach((item, i) => {
      if (item?.productid === PRODUCTID_ARRIVALBUNDLE) {
        let toscheduleid = i === 0 ? arrivalscheduleid : departurescheduleid;
        if (toscheduleid === null) {
          toscheduleid =
            i === 0
              ? arrivalRecords[0]?.scheduleid
              : departureRecords[0]?.scheduleid;
        }
        const schedule = {
          mngleg: getDirection(i, item?.productid),
          reservationid:
            i === 0
              ? arrivalRecords[0]?.lookupkey
              : departureRecords[0]?.lookupkey,
          fromscheduleid:
            i === 0
              ? arrivalRecords[0]?.scheduleid
              : departureRecords[0]?.scheduleid,
          toscheduleid: toscheduleid,
          ticketsconfirmed:
            i === 0
              ? arrivalRecords[0]?.ticketsrequested
              : departureRecords[0]?.ticketsrequested,
        };
        schedulelist.push(schedule);
      } else {
        let toscheduleid =
          item.productid === PRODUCTID_ARRIVAL
            ? arrivalscheduleid
            : departurescheduleid;
        if (toscheduleid === null) {
          toscheduleid =
            item.productid === PRODUCTID_ARRIVAL
              ? arrivalRecords[0]?.scheduleid
              : departureRecords[0]?.scheduleid;
        }
        const schedule = {
          mngleg: getDirection(i, item?.productid),
          reservationid:
            item.productid === PRODUCTID_ARRIVAL
              ? arrivalRecords[0]?.lookupkey
              : departureRecords[0]?.lookupkey,
          fromscheduleid:
            item.productid === PRODUCTID_ARRIVAL
              ? arrivalRecords[0]?.scheduleid
              : departureRecords[0]?.scheduleid,
          toscheduleid: toscheduleid,
          ticketsconfirmed:
            item.productid === PRODUCTID_ARRIVAL
              ? arrivalRecords[0]?.ticketsrequested
              : departureRecords[0]?.ticketsrequested,
        };
        schedulelist.push(schedule);
      }
    });

    return schedulelist;
  };

  const handleSubmit = async (values) => {
    // event.preventDefault();
    setLoading(true);
    let request = [];
    let error = false;
    if (!error) {
      let isBookingDetailUpdated = true;
      const schedulelist = await createUpdateBookingRequest();
      schedulelist.forEach((item) => {
        item?.toscheduleid === undefined
          ? (isBookingDetailUpdated = false)
          : (isBookingDetailUpdated = true);
      });
      try {
        if (!isBookingDetailUpdated) {
          showError(t("error"), t("updateBookingDetailError"));
        } else {
          request = { schedulelist: schedulelist };
          const response = await updateBooking(request);
          if (response?.status === 0) {
            await showSuccess(
              t("success"),
              t("mybookings:successFlightMessage")
            );
            navigate(-1);
          } else if (response?.status === 158 || response?.status === 166) {
            showError(t("updateBookingFailHeading"), <HtmlToReactConverter value={t('updateBookingFailMessage')} />);
          } else {
            showError(t("error"), response?.statusMessage);
          }
        }
      } catch (e) {
        HandleAPIError(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const setFlightDetailsForEdit = (data) => {
    // setFlightDetails(data);
    // setArrivalscheduleid(editScheduleId?.arrivalscheduleid);
    // setDeparturescheduleid(editScheduleId?.departurescheduleid);
  };

  return (
    <>
      <Container className="my-4" ref={divRef}>
        <Loader loading={loading} message={t("loaderMessage")} />
        {/* <section className="d-flex justify-content-center align-content-center mt-3"> */}
        <div className="my-3">
          <h3 className="my-3 d-flex justify-content-center heading heading">
            <span className="heading">{t("common:bookingUpdate")}</span>
          </h3>
        {/* </section> */}
        </div>
        {loading ? <LoadingSkeleton /> : 
          <>
            <div className="my-3 px-1 d-flex justify-content-end">
              {!bookingKey && (
                <CommonButton
                  classname="back mx-1"
                  title={t("back")}
                  onclick={() =>
                    updateFlightStateData?.type === "SUBSCRIBER-UPDATE-FLIGHT-DETAILS"
                      ? navigate(-1)
                      : navigate("/")
                  }
                />
              )}
              <ExitButton />
            </div>
            {error === "" ?
            <section className="d-flex justify-content-center">
              <Container className="my-3">
                <Card>
                  <Card.Body className="pe-1">
                    <Row className="d-flex justify-content-start">
                      <Col md={4}>
                        <h6 className="field">{t("fillAllFields")}</h6>
                      </Col>
                    </Row>
                    <ErrorMessages formik={formik} />
                    <BookingSummaryComponent
                      renderType={"UPDATE-FLIGHT-DETAILS"}
                      orderDetail={orderDetail}
                      productid={orderDetail.productid}
                      type={updateFlightStateData?.type}
                      referenceNumber={referenceNumber}
                      confirmationNumber={confirmationNumber}
                      primaryContact={primaryContact}
                      secondaryContact={secondaryContact}
                      allFlightsEditable={allFlightsEditable}
                    />
                  </Card.Body>
                </Card>
                {showSubmit && allFlightsEditable === "Y" && (
                  <Card className="my-4">
                    <Card.Body>
                      {formik?.values?.bookingDetail?.map((a, i) => (
                        <FlightDetailsComponent
                          setFlightDetailsForEdit={setFlightDetailsForEdit}
                          key={i}
                          isLoungeDisplay={false}
                          clubType={clubType}
                          productid={orderDetail.productid}
                          flightNames={getAirlineNames(flightDetails[i]?.airlines)}
                          flightNumbers={getFlightNumbers(
                            flightDetails[i]?.flights?.filter(
                              (f) => f.airlineid === airlineId[i]
                            )
                          )}
                          heading={bookingType[i]}
                          product
                          index={i}
                          direction={getDirection(i)}
                          lounge={formik?.values?.bookingDetail?.[i]?.lounge}
                          flightDate={formik?.values?.bookingDetail?.[i]?.flightDate}
                          airlineName={formik?.values?.bookingDetail?.[i]?.airlineName}
                          airlineId={formik?.values?.bookingDetail?.[i]?.airlineId}
                          flightNumber={formik?.values?.bookingDetail?.[i]?.flightNumber}
                          flightTime_hour={formik?.values?.bookingDetail?.[i]?.flightTime_hour}
                          editable={formik?.values?.bookingDetail?.[i]?.editable}
                          title={formik?.values?.bookingDetail?.[i]?.title}
                          scheduleId={handleArrivalDepartureScheduleIdChange}
                          errors={a.errors}
                          formik={formik}
                          onBlur={(key, e) => {
                            formik.handleBlur(e);
                          }}
                          onChange={(key, val) => {
                            if (key === "flightDate") {
                              const event = dateFormatForDisplay(val, "MM/dd/yyyy");
                              formik.setFieldValue(`bookingDetail.${i}.${key}`, event);
                            }
                            formik.setFieldValue(`bookingDetail.${i}.${key}`, val);
                          }}
                        />
                      ))}
                      {showSubmit && (
                        <Row className="my-3">
                          <Col className="d-flex justify-content-end">
                            <CommonButton
                              size="md"
                              classname="gradient-btn mx-2"
                              title={t("submitUpdatedInfo")}
                              onclick={(e) => scrollToDiv(e, formik)}
                            />
                          </Col>
                        </Row>
                      )}
                    </Card.Body>
                  </Card>
                )}
              </Container>
            </section>
            :
            <section>
              <Container className="my-4 text-center">
                <Card className="p-4">
                  <Row>
                    <Col md={12} className="d-flex justify-content-center"></Col>
                  </Row>
                  <Row>
                    <Col md={12} sm={12}>
                      <div className="mb-3 my-bg-info">{error}</div>
                    </Col>
                  </Row>
                </Card>
              </Container>
            </section>}
          </>
        }

      </Container>
    </>
  );
}
export default UpdateFlightDetails;
