import { useNavigate, useOutletContext } from "react-router-dom";
import CommonButton from "./CommonButton";
import useToggle from "../../hooks/useToggle";
import { useTranslation } from 'react-i18next';
import { clearSession, isSubDistributorProfile } from "../commonComponents/commonFunctions";
import { logOut } from "../../redux/apiCalls";
import Loader from "./Loader";
import { HandleAPIError } from "./commonFunctions";
import { showConfirm } from '../commonComponents/Swal';
import { useDispatch, useSelector } from "react-redux";
import { logindetails as LD } from "../../redux/signInRedux";
import { clearDemographicInformation } from "../../redux/demographicInformationRedux";
// import useBroadcastChannel from "../../hooks/useBroadcastChannel";
// import { BC_SESSION } from "../../config/commonConstants";
import { cartData as CD } from "../../redux/cartRedux";
import { giftCartData as GCD } from "../../redux/giftCartRedux";
import { clearSearchBooking } from "../../redux/searchBookingRedux";
import { paxCountClear } from "../../redux/increasePaxCountRedux";


function ExitButton(props) {
  const navigate = useNavigate();
  const sendBroadcastMessage = useOutletContext()
  const { t } = useTranslation(['common']);
  const loginDetails = useSelector(LD)
  const [loading, setLoading] = useToggle();
  const dispatch = useDispatch();
  const cartData = useSelector(CD);
  const giftCartData = useSelector(GCD);
  // const cancelHandler = () => {
  //   setIsVisibleExitModal(false);
  // };

  const OkHandler = async () => {
    setLoading(true)
    try {
      if (loginDetails?.sessionid) {
        const response = await logOut();
        if (response?.status === 0) {
          
        }
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
      dispatch(clearDemographicInformation());
      dispatch(clearSearchBooking());
      dispatch(paxCountClear());
    //  closeSession();
    // setIsVisibleExitModal();
    isSubDistributorProfile() ? navigate("/distributor-booking-user") : navigate("/");
    clearSession(sendBroadcastMessage);
    }
  };
  return (
    <>
      <Loader loading={loading} />
      <CommonButton
        size={props.size}
        onclick={async () => {
          let confirmed;
          let exitMessage = (cartData.length !== 0 || giftCartData.length !== 0) ? t("exitMessage") : t("exitMessageNoItem");
          // if (props?.messageType === "partial") {
            // confirmed = await showConfirm(t("confirm"), t("areYouSure"));
          // } else {
            confirmed = await showConfirm(t("confirm"), exitMessage);
          // }
          if (confirmed.isConfirmed) {
            OkHandler()
          }
        }}
        classname="exit"
        title={t('exit')}
      />
    </>
  );
}

export default ExitButton;