import {Col, Container} from "react-bootstrap";
import "../Error404.css";
import CommonButton from "../components/commonComponents/CommonButton";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHomeLg } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';

function Error404() {
  const { t } = useTranslation(['common','error404']);
  
  return (
    <>
      <section>
        <Container className="my-4 d-flex justify-content-center ">
            
            <div id="notfound">
            <div className="notfound">
            <div className="notfound-404">
            <h1 className="align-baseline">{t('error404:oops')}</h1>
            </div>
            <h2>{t('error404:pageNotFound')}</h2>
            <p>{t('error404:errorMessage')}</p>  
                <Link to="/" className="me-1 text-decoration-none">
                  <CommonButton
                    classname="btn-md gradient-btn"
                    title={t('error404:goToHomepage')}
                    icon={
                      <FontAwesomeIcon
                        icon={faHomeLg}
                        className="mx-2"
                        size="lg"
                        position="left"
                      />
                    }
                  />
                </Link>            
            </div>
            </div>
                  
        </Container>
      </section>
    </>
  );
}
export default Error404;
