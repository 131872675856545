import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

function Loader(props) {
  const { t } = useTranslation(["common", "manageuser"]);

  return (
    props.loading && (
      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.loading}
        className={"loader-model"}
        style={{ zIndex: "9999" }}
      >
        <Modal.Body>
          <div className="text-center">
            <img
              alt="Loading..."
              src={require("../../images/loadingImage.gif")}
              className={"loader-img"}
            />
            <h5 className="loader-msg mt-3">{props?.message || t('loaderMessage')}</h5>
          </div>
        </Modal.Body>
      </Modal>
    )
  );
}
export default Loader;