import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import sessionStorage from "redux-persist/es/storage/session";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";
import marketRedux from "../redux/marketRedux";
import signInRedux from "../redux/signInRedux";
import loaderRedux from "../redux/loaderRedux";
import flightScheduleRedux from "../redux/flightScheduleRedux";
import cartRedux from "../redux/cartRedux";
import specialOccasionsRedux from "../redux/specialOccasionsRedux";
import memberCardRedux from "../redux/memberCardRedux";
import affiliatesRedux from "../redux/affiliatesRedux";
import paymentGatewayRedux from "../redux/paymentGatewayRedux";
import loggedInUserDetailRedux from "../redux/loggedInUserDetailRedux";
import giftCartRedux from "../redux/giftCartRedux";
import increasePaxCountRedux from "../redux/increasePaxCountRedux";
import demographicInformationRedux from "../redux/demographicInformationRedux";

import standByRedux from "../redux/standByRedux"
import createMigrate from "redux-persist/es/createMigrate";
import { encryptTransform } from "redux-persist-transform-encrypt";
import partnerRedux from "../redux/partnerRedux";
import searchBookingRedux from "../redux/searchBookingRedux";
// import { SECRET_KEY_REDUX } from "../config/commonConstants";

const migrations = {
  0:(state)=>{
    return undefined
  },
}

const persistConfig = {
  key: "root",
  version: 0,
  storage,
  migrate: createMigrate(migrations, { debug: false }),
  transforms: [
    encryptTransform({
      secretKey: "oV8'HA_NfD@(4Y~",
      onError: function (error) {
        sessionStorage.removeItem('state')
      },
    }),
  ],
};

const rootReducer = combineReducers({
  marketRedux: marketRedux,
  loaderRedux: loaderRedux,
  flightScheduleRedux: flightScheduleRedux,
  signInRedux: signInRedux,
  cartRedux: cartRedux,
  specialOccasionsRedux: specialOccasionsRedux,
  affiliatesRedux: affiliatesRedux,
  paymentGatewayRedux: paymentGatewayRedux,
  memberCardRedux: memberCardRedux,
  loggedInUserDetailRedux: loggedInUserDetailRedux,
  giftCartRedux: giftCartRedux,
  increasePaxCountRedux: increasePaxCountRedux,
  standByRedux: standByRedux,
  demographicInformationRedux: demographicInformationRedux,
  partnerRedux: partnerRedux,
  searchBookingRedux: searchBookingRedux,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(createStateSyncMiddleware({
      blacklist: ["persist/PERSIST", "persist/REHYDRATE"],
    })),
});
initMessageListener(store);
// store.subscribe(() => saveToLocalStorage(store.getState()));

export const persistor = persistStore(store);

