import { Row, Col, Card, Container, Tabs, Tab, Form, InputGroup } from "react-bootstrap";
import { convertStandBy, getAuthorizedlogin, getMemberCards, processMemberCard, setMarketId } from "../redux/apiCalls";
import { useEffect, useState } from "react";
import { useNavigate, useLocation, Link, useOutletContext } from "react-router-dom";
import { showConfirm, showError } from "../components/commonComponents/Swal";
import BookingSummaryComponent from "../components/commonComponents/BookingSummaryComponent";
import { validExpiryOrNot, clearSession, convertAmountToUSDFloat, dateFormatForDisplay, divRef, getCurrencyCodeFromMarket, getCurrentMonth, getLanguageIdFromAirportName, getMarketIdFromAirportName, HandleAPIError, marketChangeBroadcast, scrollToDiv, translateConstantText, getDistributorSubDistributorProfile, generatePaymentMenu, isObjectEmpty, getPassengerDetails, getProductName, getAdultMinimumAge } from "../components/commonComponents/commonFunctions";
import { t } from "i18next";
import CreditCard from "../components/commonComponents/CreditCard";
import { processCreditCardPayment } from "../utils/Fac";
import { selectedMarketCurrency } from "../redux/marketRedux";
import { useDispatch, useSelector } from "react-redux";
import { CARD_CVV_LENGTH, DEFAULT_CURRENCYCODE, EMAIL_MAXLENGTH, EMAIL_POLICY, MEMBERCARD_CARD_NUMBER_LABEL, MEMBERCARD_CARD_TYPE_LABEL, MEMBERCARD_LABEL, PRODUCTID_ARRIVAL, PRODUCTID_ARRIVALBUNDLE, PRODUCTID_DEPARTURE } from "../config/commonConstants";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessages from "../components/commonComponents/ErrorMessages";
import CommonButton from "../components/commonComponents/CommonButton";
import ExitButton from "../components/commonComponents/ExitButton";
import {
  selectedMarketId as SM,
  setSelectedMarket,
} from "../redux/marketRedux";
import Loader from "../components/commonComponents/Loader";
import { cartData as CD } from "../redux/cartRedux";
import { giftCartData as GCD } from "../redux/giftCartRedux";
import LoadingSkeleton from "../components/commonComponents/LoadingSkeleton";
import useToggle from "../hooks/useToggle";
import ResetMemberPinModal from "../components/commonComponents/ResetMemberPinModal";
import ChangeMemberPinModal from "../components/commonComponents/ChangeMemberPinModal";
import md5 from "md5";
import { VIPER_Response_Codes } from "../config/viper-response-codes";
import VipMembershipCard from "../components/commonComponents/VipMembershipCard";
import { logindetails as LD, userDefault as UD } from "../redux/signInRedux";
import ManualAuthorizationCreditCard from "../components/commonComponents/ManualAuthotrizationCreditCard";
import ManualAuthorization from "../components/commonComponents/ManualAuthorization";
import Select from "react-select";
import { paxCountClear, paxCountBookingDetails as PCD } from "../redux/increasePaxCountRedux";
import MappedMemberCardModal from "../components/commonComponents/MappedMemberCardModal";


function StandByPayment() {
  const currencyCode = useSelector(selectedMarketCurrency);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [allowPayment, setAllowPayment] = useState(true);
  const [orderDetail, setOrderDetail] = useState([]);
  const [userData, setUserData] = useState([]);
  const [retailAmount, setRetailAmount] = useState(0.00);
  const [wholeSaleAmount, setWholeSaleAmount] = useState(0.00);
  const [error, setError] = useState("");
  const [submitOnClickDisable, setSubmitOnClickDisable] = useState(false);
  const [marketId, setStateMarketId] = useState("");
  const loginDetails = useSelector(LD)

  const sendBroadcastMessage = useOutletContext() 
  const selectedMarketId = useSelector(SM);
  const dispatch = useDispatch();
  const cartData = useSelector(CD);
  const giftCartData = useSelector(GCD);

  const [responseGetMemberCards, setResponseGetMemberCards] = useState([]);
  const [vipMemberCardsList, setVipMemberCardsList] = useState([]);
  const [defaultTab, setDefaultTab] = useState();
  const [resetMemberPinStatus, setResetMemberPinStatus] = useState(true);
  const [isVisibleChangeMemberPin, setIsVisibleChangeMemberPin] = useToggle();
  const [isVisibleResetMemberPin, setIsVisibleResetMemberPin] = useState(false);
  const [status, setStatus] = useState(null);
  const [availableBalance, setAvailableBalance] = useState(0);
  const [linkCardStatus, setLinkCardStatus] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const [showManualAuthPayment, setShowMannalAuthPayment] = useState(false);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const distributorProfile = getDistributorSubDistributorProfile();
  const [partnerReferenceNumber, setPartnerReferenceNumber] = useState("");
  const [fromSelectedMemberCard, setFromSelectedMemberCard] = useState(false);
  const [convertMd5, setConvertMd5] = useState(false);
  const [referenceNumber,setReferenceNumber]=useState("")
  // const sendBroadcastMessage = useOutletContext()
  // const selectedMarketId = useSelector(SM);
  const userDefault = useSelector(UD);
  const paxCountBookingDetails = useSelector(PCD);
  const [isVisibleMappedMemberCardModal, setIsVisibleMappedMemberCardModal] = useToggle();


  const standbyInfo = location.state.response; //StandByBookingList
  const navigatedFrom = location?.state?.navigatedFrom; //StandByBookingList

  const formik_cardholderDetails = useFormik({
    initialValues: {
      amount: userData?.retail,
      confirmationEmail: "",
      copytoEmail: "",
      name: "",
      email: "",
      cardType: "VISA",
      cardProfile: { value: "GUESTCARD", label: "GUEST CARD" },
      cardNumber: "",
      cardMonth: getCurrentMonth(),
      cardYear: new Date()?.getFullYear()?.toString(),
      cvv: "",
    },
    validationSchema: Yup.object().shape({
      confirmationEmail: Yup.string()
        .trim().required(t("payment:errors.confirmationEmail.required"))
        .matches(EMAIL_POLICY, t("payment:errors.email.valid")),
      copytoEmail: Yup.string()
        .matches(EMAIL_POLICY, t("payment:errors.emailCopyTo.valid")),
      name: Yup.string().trim().required(t("payment:errors.name.required")),
      cardNumber: Yup.string()
        .required(t("payment:errors.cardNumber.required"))
        .matches(/^[0-9]+$/, t("payment:errors.cardNumber.match"))
        .length(16, t("payment:errors.cardNumber.length")),
      cvv: Yup.string().required(t("payment:errors.cvv.required"))
          .length(CARD_CVV_LENGTH, t("payment:errors.cvv.length")),
      email: Yup.string().trim().required(t("payment:errors.email.required"))
        .matches(EMAIL_POLICY, t("payment:errors.email.valid")),

      cardMonth: Yup.string().test(
        'test-credit-card-expiration-date',
        t("payment:errors.cardMonth.match"),
        tmpcardmonth => {
          return validExpiryOrNot(formik_cardholderDetails.values.cardYear, tmpcardmonth);
        }
      ),
      cardYear: Yup.string().test(
        'test-credit-card-expiration-date',
        t(""),
        tmpcardyear => {
          return validExpiryOrNot(tmpcardyear, formik_cardholderDetails.values.cardMonth);    
         }
      ),
    }),
    initialTouched : {cardMonth : true, cardYear : true},
    onSubmit: () => handleSubmit(formik_cardholderDetails.values, "CREDITCARD"),
  });

  const formik_vipmembershipcard = useFormik({
    initialValues: {
      amount: 0,
      memberCards: "",
      cardNumber: "",
      email: "",
      cardHolderName: "",
      pin: "",
    },
    validationSchema: Yup.object().shape({
      cardHolderName: Yup.string().trim().required(t("payment:errors.memberShip.memberName.length")),
      cardNumber: Yup.string()
        .required(t("payment:errors.memberShip.cardNumber.required", {membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL")}))
        .matches(/^[0-9]+$/, t("payment:errors.memberShip.cardNumber.match", {membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL")}))
        .length(16, t("payment:errors.memberShip.cardNumber.length", {membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL")})),
      pin: Yup.string().required(t("payment:errors.pin.required")),
      memberCards: Yup.mixed().required(t("payment:errors.memberCards.required",{membershipType: translateConstantText("MEMBERCARD_CARD_TYPE_LABEL")})),
    }),
    onSubmit: () => handleSubmit(formik_vipmembershipcard.values, "VIPMEMBERSHIPCARD"),
  });

  const formik_manualAuthorization = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().trim().required(t("payment:errors.username.required")),
      password: Yup.string().trim().required(t("payment:errors.password.required")),
    }),
    onSubmit: paymentType => {
      handleSubmit(formik_manualAuthorization.values, "")
    },
  });

  const formik_manualAuthorization_Creditcard = useFormik({
    initialValues: {
      amount: retailAmount,
      confirmationEmail: userData?.contactemail,
      copytoEmail: "",
      name: "",
      email: "",
      cardType: "VISA",
      cardNumber: "",
      authorizationNumber: "",
    },
    validationSchema: Yup.object().shape({
      confirmationEmail: Yup.string()
        .trim().required(t("payment:errors.confirmationEmail.required"))
        .matches(EMAIL_POLICY, t("payment:errors.email.valid")),
      copytoEmail: Yup.string()
        .matches(EMAIL_POLICY, t("payment:errors.emailCopyTo.valid")),
      name: Yup.string().trim().required(t("payment:errors.name.required")),
      cardNumber: Yup.string()
        .required(t("payment:errors.cardNumber.required"))
        .matches(/^[0-9]+$/, t("payment:errors.cardNumber.match"))
        .length(4, t("payment:errors.cardNumber.length")),
      authorizationNumber: Yup.string().required(t("payment:errors.mannualAuthorizationNumber.required")),
      email: Yup.string()
        .trim().required(t("payment:errors.email.required"))
        .matches(EMAIL_POLICY, t("payment:errors.email.valid")),
    }),
    onSubmit: paymentType => {
      handleSubmit(formik_manualAuthorization_Creditcard.values, "MANUALAUTHORIZATION")
    },
  });

  const changeMarket = async (airportid, cartClear) => {
    let request = {};
    let marketid = getMarketIdFromAirportName(airportid);
    let languageid = getLanguageIdFromAirportName(airportid);
    request = {
      "marketid": marketid,
      "languageid": languageid
    }
    if (marketid === selectedMarketId) {
      // Do nothing
    } else {
      dispatch(setSelectedMarket(marketid));
      try {
          setLoading(true);
          await setMarketId(request);
          await marketChangeBroadcast(sendBroadcastMessage, marketid, cartClear);
        } catch (e) {
          HandleAPIError(e);
        } finally {
          setLoading(false);
        }    
    }
  };

  const setStateValues = async (authenticated) => {
    setUserData(authenticated);
    setWholeSaleAmount(authenticated.wholesale);
    setRetailAmount(authenticated.retail);
    setOrderDetail(authenticated.orderdetail);
    setStateMarketId(authenticated.marketid)
    setReferenceNumber(authenticated.referencenumber)
  };

  const getStandByData = async () => {
    const response = standbyInfo;
    if (response.status !== 0) {
      setError(response.statusMessage);
    }
    else {
      if(navigatedFrom === "StandByBookingList") {
        if (cartData.length === 0 && giftCartData.length === 0) {
          await changeMarket(response.data.authenticated.airportid, false);
          setStateValues(response.data.authenticated)
          setLoading(false);
        }
        else if (cartData.length !== 0 || giftCartData.length !== 0) {
          const confirmed = await showConfirm(
            t("header:changeMarketConfirmHeading"),
            t("header:changeMarketConfirmMessage")
          );
          if (confirmed.isConfirmed) {
            await changeMarket(response.data.authenticated.airportid, true)
            setStateValues(response.data.authenticated)
            setLoading(false);
          } else {
            navigate("/");
            setLoading(false);
          }
        } 
      } else {
        await changeMarket(response.data.authenticated.airportid, false);
        formik_cardholderDetails.setFieldValue("amount",response.data.authenticated.retail)
       // formik_vipmembershipcard.setFieldValue("amount",response.data.authenticated.retail)
        setStateValues(response.data.authenticated)
        setLoading(false);
      }
      formik_vipmembershipcard.setFieldValue("amount",response.data.authenticated.retail)
    }
  };

  const getAmount = (paymentType, cardProfile) => {
    if (paymentType === "CREDITCARD")
      return cardProfile === "GUESTCARD" ? retailAmount : wholeSaleAmount;
    else
      return wholeSaleAmount;
  }

  useEffect(() => {
    getStandByData();
    setAllowPayment(standbyInfo.data.authenticated.allowpayment);
  }, [loginDetails]);

  useEffect(() => {
    if (!isObjectEmpty(paxCountBookingDetails)) {
      dispatch(paxCountClear());
    }
  }, []);

  useEffect(() => {
    const allowedPaymentTypesDetail = generatePaymentMenu(distributorProfile, userDefault);
    setPaymentTypes(allowedPaymentTypesDetail);
  }, [distributorProfile, userDefault]);

  useEffect(() => {
    formik_cardholderDetails.setFieldValue("amount",retailAmount);
    formik_manualAuthorization_Creditcard.setFieldValue("amount",retailAmount);
    formik_manualAuthorization_Creditcard.setFieldValue("confirmationEmail",userData?.contactemail);
  }, [orderDetail]);

  useEffect(()=>{
    
    formik_cardholderDetails.setFieldValue("cardMonth",formik_cardholderDetails.values.cardMonth);
    formik_cardholderDetails.setFieldValue("cardYear",formik_cardholderDetails.values.cardYear);
  },[formik_cardholderDetails.values.cardMonth,formik_cardholderDetails.values.cardYear])


  const getManualAuthorizationApi = async (username, password) => {
    const data = {
      "username": username,
      "password": md5(password),
    };
    try {
      setLoading(true);
      const response = await getAuthorizedlogin(data);
      if (response?.status === 0) {
        setShowMannalAuthPayment(true)
      }
      else {
        showError(t("error"), response?.statusMessage);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleVIPMembershipHoldFunds = async () => {
    try {
      // setLoading(true);
      const processMemberCardHoldFundReponse = await processMemberCard({
        "action": "HOLDFUNDS",
        "memberid": formik_vipmembershipcard?.values?.memberCards?.value + formik_vipmembershipcard?.values?.cardNumber,
        "pin": linkCardStatus ? formik_vipmembershipcard?.values?.pin : md5(formik_vipmembershipcard?.values?.pin),
        "amount": currencyCode !== DEFAULT_CURRENCYCODE ? convertAmountToUSDFloat(formik_vipmembershipcard?.values?.amount) : formik_vipmembershipcard?.values?.amount
      });
      if (processMemberCardHoldFundReponse !== null) {
        return processMemberCardHoldFundReponse;
      } else {
        showError(t("error"), processMemberCardHoldFundReponse.statusMessage);
        return null;
      }
    } catch (error) {
      showError(t("error"), error.message);
      return null;
    } finally {
      // setLoading(false);
    }
  };

  const createStandbyBookingRequest = async (objPayment, paymentType) => {
    let request = {};
    if (paymentType === "CREDITCARD") {
      request = {
        standbyid: userData.standbyid,
        creditcard: {
          cardtype: formik_cardholderDetails?.values.cardType,
          currency: currencyCode,
          cardnumber: formik_cardholderDetails?.values.cardNumber.slice(-4),
          cardholder: formik_cardholderDetails?.values.name,
          amount: formik_cardholderDetails?.values?.amount,
          authorizationnumber: objPayment?.creditcard?.authorizationnumber,
        },
        paymenttype: paymentTypes.cardType === "both" ? formik_cardholderDetails?.values?.cardProfile?.value : paymentTypes.cardprofile[0].value,
      }
    } else if (paymentType === "VIPMEMBERSHIPCARD") {
      request = {
        "standbyid": userData.standbyid,
        "membercard": {
          "cardtype": formik_vipmembershipcard?.values?.memberCards?.value,
          "cardnumber": formik_vipmembershipcard?.values?.cardNumber,
          "cardholder": formik_vipmembershipcard?.values?.cardHolderName,
          "currency": DEFAULT_CURRENCYCODE,
          "amount": currencyCode !== DEFAULT_CURRENCYCODE ? convertAmountToUSDFloat(userData.retail) : userData.retail,
        },
        "paymenttype": "MEMBERCARD"
      };
    } else if (paymentType === "MANUALAUTHORIZATION") {
      request = {
        "standbyid": userData?.standbyid,
        "creditcard": {
          "cardtype": "VISA",
          "cardnumber": formik_manualAuthorization_Creditcard?.values.cardNumber,
          "cardholder": formik_manualAuthorization_Creditcard?.values?.name,
          "email": formik_manualAuthorization_Creditcard?.values?.email,
          "currency": currencyCode,
          "amount": objPayment?.creditcard?.amount,
          "authorizationnumber": objPayment?.creditcard?.authorizationnumber,
          "authorizedby": formik_manualAuthorization?.values?.username,
        },
        "paymenttype": "GUESTCARD"
      };
    }
    return request;
  };

  const handleSubmit = async (values, paymentType) => {
    // event?.preventDefault();
    let objPayment = {};
    let callConfirmStandbyPayment = false;
    if (paymentType === "CREDITCARD") {
      try {
        setLoading(true);
        setSubmitOnClickDisable(true);
        objPayment = {
          creditcard: {
            cardnumber: formik_cardholderDetails?.values?.cardNumber,
            authorizationnumber: "123456",
            amount: formik_cardholderDetails?.values?.amount,
          },
        };
        const createStandbyBookingLog = await createStandbyBookingRequest(objPayment, paymentType);
        const paymentData = {
          source: "OBI-STANDBY",
          amount: formik_cardholderDetails?.values?.amount,
          cardholderDetails: formik_cardholderDetails?.values,
        };
        const responseCC = await processCreditCardPayment(paymentData, createStandbyBookingLog);
        objPayment = {
          creditcard: {
            cardnumber: responseCC?.data?.cardnumber,
            authorizationnumber: responseCC?.data?.authorizationnumber,
            amount: formik_cardholderDetails?.values?.amount,
          },
        };

        if (responseCC &&Object.keys(responseCC).length !== 0 && responseCC?.data?.authorizationnumber && responseCC?.data?.authorizationnumber !== "") {
          callConfirmStandbyPayment = true;
        }
      } catch (e) {
        HandleAPIError(e);
      } finally {
        setLoading(false);
        setSubmitOnClickDisable(false);
      }
    } else if (paymentType === "VIPMEMBERSHIPCARD") {
      const processMemberCardReponse = await handleVIPMembership();
      if (processMemberCardReponse.status === 0) {
        if (processMemberCardReponse?.data?.reset === "Y") {
          setIsVisibleChangeMemberPin();
          setAvailableBalance(processMemberCardReponse?.data?.amount);
        } else {
          const processMemberCardHoldFundReponse = await handleVIPMembershipHoldFunds();
          if (processMemberCardHoldFundReponse.status === 0) {
            callConfirmStandbyPayment = true;
            objPayment = {};
          }
        }
      } else {
        showError(t("error"), processMemberCardReponse.statusMessage);
        setSubmitOnClickDisable(false);
      }
    } else if (paymentType === "MANUALAUTHORIZATION") {
      objPayment = {
        creditcard: {
          cardnumber: formik_manualAuthorization_Creditcard?.values?.cardNumber,
          authorizationnumber: formik_manualAuthorization_Creditcard?.values?.authorizationNumber,
          amount: userData.retail,
        },
      };
      callConfirmStandbyPayment = true;
    }

    if (callConfirmStandbyPayment === true) {
      try {
        setLoading(true);
        const request = await createStandbyBookingRequest(
          objPayment,
          paymentType
        );
        const response = await convertStandBy(request);
        const bookingInfo = getPassengerDetails(orderDetail);

        if (response?.status === 0) {
          const successInfo = {
            messageHeading: t("payment:successInfoMessageStandByHeading"),
            // successMessage: t("payment:successInfoSuccessMessage"),
            successMessage: t("successpage:thanksForPaymentMessage", {
              currencyCode: getCurrencyCodeFromMarket(marketId),
              amount: formik_cardholderDetails?.values?.amount?.toFixed(2),
              email: userData?.contactemail,
            }),
            commonSuccessMessage: t('successpage:confirmationMessage', {
              confirmationnumber: response?.data?.confirmationnumber,
              club: bookingInfo.club,
              product: getProductName(orderDetail.productid),
              date: bookingInfo.date,
              adultCount: bookingInfo?.adultCount,
              childCount: bookingInfo?.childCount,
              infantCount: bookingInfo?.infantCount,
              email: userData?.contactemail,
              adultAge: getAdultMinimumAge()
            }),
            jePromotion: true,
            jemPromotion: true,
          };
          navigate("/common-success", { state: successInfo });
          clearSession(sendBroadcastMessage);
        } else {
          showError(t("error"), response.statusMessage);
          setSubmitOnClickDisable(false);
        }
      } catch (e) {
        HandleAPIError(e);
      } finally {
        setSubmitOnClickDisable(true);
        setLoading(false);
      }
    }
  };
       
  const getMemberCardAvailableForPayment = async (productid) => {
    let getmembercardsRequest = {}
      if (productid === PRODUCTID_ARRIVAL){
        getmembercardsRequest = { ...getmembercardsRequest, "arrivalonly": "Y" }
      }
      else if (productid === PRODUCTID_DEPARTURE){
        getmembercardsRequest = { ...getmembercardsRequest, "departurelounge": "Y" }
      }
      else if (productid === PRODUCTID_ARRIVALBUNDLE){
        getmembercardsRequest = { ...getmembercardsRequest, "arrivalbundle": "Y" }
      }

    const responseGetMemberCards = await getMemberCards(getmembercardsRequest);
    setResponseGetMemberCards(responseGetMemberCards?.data);
    let memberCardsArray = [];
    responseGetMemberCards?.data?.map(e => memberCardsArray.push({ value: e?.cardid, label: e?.cardname, showValue: e?.showvalue }));
    setVipMemberCardsList(memberCardsArray);
  }

  const verifyNameMemberCard = async (pin) => {
    try {
      setLoading(true);
      let response = null;
      const verifynameMemberCardReponse = await processMemberCard({
        action: "VERIFYNAME",
        memberid:
          formik_vipmembershipcard?.values?.memberCards?.value +
          formik_vipmembershipcard?.values?.cardNumber,
        name: formik_vipmembershipcard?.values?.cardHolderName,
        pin: pin,
      });
      if (verifynameMemberCardReponse?.status === 0) {
        if (verifynameMemberCardReponse?.data?.reset === "Y") {
          setIsVisibleChangeMemberPin();
        } else {
          setStatus(verifynameMemberCardReponse?.status);
          setAvailableBalance(verifynameMemberCardReponse?.data?.amount);
          return verifynameMemberCardReponse;
        }
      } else {
        showError(t("error"), verifynameMemberCardReponse?.statusMessage);
        setStatus(null); // Reset the status if there is an error
        // return null;
      }
      return response;
    } catch (error) {
      showError(t("error"), error.message);
    } finally {
      setLoading(false);
    }
  };

  const validateMemberCard = async (pin) => {
    try {
      setStatus(false);
      setLoading(true);
      let response = null;
      const validateMemberCardReponse = await processMemberCard({
        action: "VALIDATE",
        memberid: formik_vipmembershipcard?.values?.memberCards?.value + formik_vipmembershipcard?.values?.cardNumber,
        // "name": vipmembershipcardObject?.cardHolderName,
        pin: pin,
        allowexpired: "N",
      });
      if (validateMemberCardReponse?.status === 0) {
        if (validateMemberCardReponse?.data?.reset === "Y") {
          // reset modal displayed
          setIsVisibleChangeMemberPin();
        } else {
          setStatus(validateMemberCardReponse?.status);
          setAvailableBalance(validateMemberCardReponse?.data?.amount);
          response = validateMemberCardReponse;
        }
      } else if (
        linkCardStatus &&
        validateMemberCardReponse?.status ===
          VIPER_Response_Codes.MAPPED_MEMBERCARD_INVALID_PIN
      ) {
        setIsVisibleResetMemberPin(true);
        setStatus(null); // Reset the status if there is an error
        // return null;
      } else {
        showError(t("error"), validateMemberCardReponse?.statusMessage);
        setStatus(null); // Reset the status if there is an error
        // return null;
      }
      return response;
    } catch (error) {
      showError(t("error"), error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleVIPMembership = async (newPin = "") => {
    let pin = "";
    if (newPin === "") {
      if (linkCardStatus === true && convertMd5 === true) {
        pin = md5(formik_vipmembershipcard?.values?.pin);
      } else {
        pin = linkCardStatus
          ? formik_vipmembershipcard?.values?.pin
          : md5(formik_vipmembershipcard?.values?.pin);
      }
    } else {
      pin = md5(newPin);
    }
    try {
      setStatus(false);
      setLoading(true);
      let action = fromSelectedMemberCard ? "VALIDATE" : "VERIFYNAME";
      let response = null;
      if (action === "VERIFYNAME") {
        response = await verifyNameMemberCard(pin);
      } else {
        response = await validateMemberCard(pin);
      }
      return response;
    } catch (error) {
      showError(t("error"), error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  // const handleVIPMembershipVerifyName = async (newPin = "") => {
  //   let pin = newPin;
  //   if (newPin !== "") {
  //     pin = md5(newPin)
  //   }

  //   if (pin === "") {
  //     pin = linkCardStatus ? formik_vipmembershipcard?.values?.pin : md5(formik_vipmembershipcard?.values?.pin);
  //   }

  //   try {
  //     setStatus(false);
  //     setLoading(true);
  //     const validateMemberCardReponse = await processMemberCard({
  //       "action": "VALIDATE",
  //       "memberid": formik_vipmembershipcard?.values?.memberCards?.value + formik_vipmembershipcard?.values?.cardNumber,
  //       // "name": vipmembershipcardObject?.cardHolderName,
  //       "pin": pin,
  //       "allowexpired": "N"
  //     });
  //     if (validateMemberCardReponse?.status === 0) {
  //       if (validateMemberCardReponse?.data?.reset === "Y") {
  //         // reset modal displayed
  //         setIsVisibleChangeMemberPin();
  //       } else {
  //         const verifynameMemberCardReponse = await processMemberCard({
  //           "action": "VERIFYNAME",
  //           "memberid": formik_vipmembershipcard?.values?.memberCards?.value + formik_vipmembershipcard?.values?.cardNumber,
  //           "name": formik_vipmembershipcard?.values?.cardHolderName,
  //           "pin": pin,
  //         });
  //         if (verifynameMemberCardReponse?.status === 0) {
  //           setStatus(verifynameMemberCardReponse?.status);
  //           setAvailableBalance(verifynameMemberCardReponse?.data?.amount);
  //           return verifynameMemberCardReponse;
  //         } else {
  //           showError(t("error"), verifynameMemberCardReponse?.statusMessage);
  //           setStatus(null); // Reset the status if there is an error
  //           return null;
  //         }
  //       }
  //     } else if (linkCardStatus && validateMemberCardReponse?.status === VIPER_Response_Codes.MAPPED_MEMBERCARD_INVALID_PIN) {
  //       setIsVisibleResetMemberPin(true);
  //       setStatus(null); // Reset the status if there is an error
  //       return null;
  //     } else {
  //       showError(t("error"), validateMemberCardReponse?.statusMessage);
  //       setStatus(null); // Reset the status if there is an error
  //       return null;
  //     }
  //   } catch (error) {
  //     showError(t("error"), error.message);
  //     return null;
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handlePinSuccessAndVerify = async (newPin) => {
    formik_vipmembershipcard.setFieldValue("pin", newPin);
    await handleVIPMembership(newPin);
  };

  const handleSelect = async (key) => {
    if (key === "vipmembership") {
      getMemberCardAvailableForPayment(orderDetail.productid);
    }
  }

  const [linkcardObject, setLinkcardObject] = useState({
    balance: 0,
    memberCards: "",
    cardNumber: "",
    cardType: "",
    email: "",
    cardHolderName: "",
    pin: "",
    showValue: "",
    expiryDate: "",
    errors: {},
  });

  const handleSelectedRowData = (rowData) => {
    setLinkcardObject({
      // cardHolderName: rowData.membername,
      // cardNumber: rowData.memberid,
      // cardType: rowData.cardname,
      balance: rowData.balance,
      showValue: rowData.showvalue,
      expiryDate: rowData.expirydate,
    });
    formik_vipmembershipcard.setValues({
      ...formik_vipmembershipcard.values,
      memberCards: vipMemberCardsList.find((item) => item.label === rowData.cardname),// rowData.cardname,
      cardNumber: rowData.memberid.slice(-16),
      cardHolderName: rowData.membername,
      pin: rowData.pin,
    })
    if (linkcardObject) {
      setLinkCardStatus(true);
    }
  };

  return (
    <>
      <Loader loading={loading} message={t("loaderMessage")} />
      <ChangeMemberPinModal
        visible={isVisibleChangeMemberPin}
        linkCardStatus={linkCardStatus}
        setResetMemberPinStatus={setResetMemberPinStatus}
        message={"this is change password modal"}
        vipmembershipcardObject={formik_vipmembershipcard?.values}
        cancelModal={async () => {
          setIsVisibleChangeMemberPin(false);
          setIsVisibleResetMemberPin(false);
        }}
        onSuccess={handlePinSuccessAndVerify}
      />
      <ResetMemberPinModal
        visible={isVisibleResetMemberPin}
        setResetMemberPinStatus={setResetMemberPinStatus}
        vipmembershipcardObject={formik_vipmembershipcard?.values}
        setIsVisibleChangeMemberPin={async () => {
          setIsVisibleChangeMemberPin(true);
          setIsVisibleResetMemberPin(false);
        }}
        cancelModal={async () => {
          setIsVisibleResetMemberPin(false);
        }}
        setVipmembershipcardPin={async (newPin) => {
          formik_vipmembershipcard.setFieldValue("pin", newPin);
        }}
        onSuccess={handlePinSuccessAndVerify}
        setStatus={setStatus}
      />
      <section className="d-flex justify-content-center align-content-center mt-3">
        <h3 className="my-3 d-flex justify-content-center heading heading">
          <span className="heading">{t("standByDetails")}</span>
        </h3>
      </section>
      {error === "" ? (
        <section className="d-flex justify-content-center">
          <Container className="my-4 " ref={divRef}>
            <div className="my-3 d-flex justify-content-end">
              <Link
                to={
                  navigatedFrom === "StandByBookingList"
                    ? "/standby-list"
                    : "/stand-by"
                }
                className=" mx-1 text-decoration-none"
              >
                <CommonButton classname="back" title={t("back")} />
              </Link>
              <ExitButton />
            </div>
            <Card className="p-3">
            {allowPayment === "Y" &&
              (<Row className="d-flex justify-content-start">
                <Col md={4}>
                  <h6 className="field">{t("fillAllFields")}</h6>
                </Col>
              </Row>)
            } 
              <BookingSummaryComponent
                renderType={"STANDBY-PAYMENT"}
                orderDetail={orderDetail}
                productid={orderDetail.productid}
                referenceNumber={referenceNumber}
                trackingNumber={userData?.standbyid}
              />
              <hr />
              <Row className="d-flex justify-content-center align-content-center mb-1">
                <Col md={12} sm={12}>
                  <h5 className="mb-1">
                    <b>{t("contactName")}: </b>
                    {userData?.contactname}
                  </h5>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center align-content-center mb-1">
                <Col md={12} sm={12}>
                  <h5 className="mb-1">
                    <b>{t("contactEmail")}: </b>
                    {userData?.contactemail}
                  </h5>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center align-content-center mb-1">
                <Col md={12} sm={12}>
                  <h5 className="mb-1">
                    <b>{t("contactNumber")}: </b>
                    {userData?.contactphone}
                  </h5>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center align-content-center mb-1">
                <Col md={12} sm={12}>
                  <h5 className="mb-1">
                    <b>{t("status")}: </b>
                    {userData?.status}
                  </h5>
                </Col>
              </Row>
              {allowPayment === "Y" && (
                <Row>
                  <Col md={4} sm={12}>
                    <h5 className="mb-1">
                      <b>{t("amount")}:</b>{" "}
                      {getCurrencyCodeFromMarket(marketId)}{" "}
                      {retailAmount?.toFixed(2)}
                    </h5>
                  </Col>
                </Row>
              )}
            </Card>
            {allowPayment === "Y" && (
              <div className="mt-3">
                <h3>{t("payment")}</h3>
                <Tabs
                  defaultActiveKey={defaultTab}
                  id="justify-tab-example"
                  className="mt-4"
                  onSelect={(e) => handleSelect(e)}
                >
                  <Tab eventKey="creditcard" title={t("payment:creditCard")}>
                    <Row className="justify-content-center">
                      <Col md={8}>
                        <Form>
                          <ErrorMessages formik={formik_cardholderDetails} />
                          <Card className="bg-light bg-opacity-10 rounded-3 px-0 mb-2">
                            <Card.Body>
                              <Row className="g-3">
                                <Col md={6}>
                                  <Form.Label
                                    className="d-flex"
                                    htmlFor="validationCustomemail"
                                  >
                                    {t("payment:sendConfirmationEmailTo")}:
                                    <span className="field">*</span>
                                  </Form.Label>
                                  <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend">
                                      @
                                    </InputGroup.Text>
                                    <Form.Control
                                      type="email"
                                      name="confirmationEmail"
                                      id="validationCustomemail"
                                      placeholder={t("emailAddress")}
                                      value={
                                        formik_cardholderDetails.values
                                          .confirmationEmail
                                      }
                                      onBlur={
                                        formik_cardholderDetails.handleBlur
                                      }
                                      onChange={
                                        formik_cardholderDetails.handleChange
                                      }
                                      className={
                                        formik_cardholderDetails.touched
                                          .confirmationEmail &&
                                          formik_cardholderDetails.errors
                                            .confirmationEmail
                                          ? "error"
                                          : ""
                                      }
                                      aria-describedby="inputGroupPrepend"
                                      maxLength={EMAIL_MAXLENGTH}
                                      minLength={1}
                                    />
                                    <Form.Control.Feedback>
                                      {t("payment:looksGood")}
                                    </Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                      {t("chooseEmailAddress")}.
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                                <Col md={6}>
                                  <Form.Label htmlFor="validationCustomcopyemail">
                                    {t("payment:copyTo")} (
                                    {t("payment:optional")})
                                  </Form.Label>
                                  <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend">
                                      @
                                    </InputGroup.Text>
                                    <Form.Control
                                      type="email"
                                      name="copytoEmail"
                                      id="validationCustomcopyemail"
                                      placeholder={t("emailAddress")}
                                      aria-describedby="inputGroupPrepend"
                                      value={
                                        formik_cardholderDetails.values
                                          .copytoEmail
                                      }
                                      onBlur={
                                        formik_cardholderDetails.handleBlur
                                      }
                                      onChange={
                                        formik_cardholderDetails.handleChange
                                      }
                                      maxLength={EMAIL_MAXLENGTH}
                                      minLength={1}
                                      className={
                                        formik_cardholderDetails.touched
                                          .copytoEmail &&
                                          formik_cardholderDetails.errors
                                            .copytoEmail
                                          ? "error"
                                          : ""
                                      }
                                    />
                                    <Form.Control.Feedback>
                                      {t("payment:looksGood")}
                                    </Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                      {t("chooseEmailAddress")}.
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                                {paymentTypes.cardType === "both" && paymentTypes?.cardprofile.length > 1 && <Col md="6">
                                  <Form.Label className="d-flex" htmlFor="validationCustomCardType">
                                    {t('cardProfile')} <span className="field">*</span>
                                  </Form.Label>
                                  <Select
                                    options={paymentTypes?.cardprofile}
                                    className="my-0"
                                    autosize={true}
                                    value={formik_cardholderDetails.values.cardProfile}
                                    id="payment_cardProfile"
                                    onBlur={(d) => formik_cardholderDetails.handleBlur}
                                    name="cardProfile"
                                    onChange={(d) => {
                                      formik_cardholderDetails.setFieldValue("cardProfile", d)
                                      formik_cardholderDetails.setFieldValue("amount", getAmount("CREDITCARD", d.value))
                                    }
                                    }
                                    getOptionValue={(v) => v.value}
                                    getOptionLabel={(v) => v.label}
                                  />
                                </Col >}
                                <Col md={"auto"}>
                                  <Form.Label
                                    className="d-flex"
                                    htmlFor="validationCustomPrice"
                                  >
                                    {t("amount")} ({currencyCode})
                                  </Form.Label>
                                  <Form.Control
                                    disabled
                                    id="validationCustomPrice"
                                    type="text"
                                    placeholder=""
                                    value={formik_cardholderDetails?.values?.amount?.toFixed(2)}
                                    className=""
                                  />
                                  <Form.Control.Feedback>
                                    {t("payment:looksGood")}
                                  </Form.Control.Feedback>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                          <CreditCard
                            formik={formik_cardholderDetails}
                            isSubmitDisabled={
                              submitOnClickDisable
                                ? submitOnClickDisable
                                : false
                            }
                            onClick={(e) => {
                              scrollToDiv(e, formik_cardholderDetails);
                            }}
                            onChange={(key, val) => {
                              formik_cardholderDetails.setFieldValue(key, val);
                            }}
                            onBlur={(key, e) => {
                              formik_cardholderDetails.handleBlur(e);
                            }}
                          />
                        </Form>
                      </Col>
                    </Row>
                  </Tab>
                  {paymentTypes?.allowedPaymentTypes?.find((p) => p === "vipmembership") && (
                    <Tab
                      eventKey="vipmembership"
                      title={t("payment:vipMembershipCard", {
                        vipMembershipLabel: translateConstantText("MEMBERCARD_LABEL"),
                      })}
                    >
                   
                      <Form>
                      {userDefault?.subscriber === "Y" &&
                        <MappedMemberCardModal
                          visible={isVisibleMappedMemberCardModal}
                          message={"this is change password modal"}
                          cancelModal={setIsVisibleMappedMemberCardModal}
                          productId={orderDetail.productid}
                          onRowSelect={handleSelectedRowData}
                          setStatus={setStatus}
                          setFromSelectedMemberCard={setFromSelectedMemberCard}
                        />
                      }
                        <ErrorMessages formik={formik_vipmembershipcard} />
                        <VipMembershipCard
                          // type={"StandBy-Payment"}
                          productid={orderDetail.productid}
                          linkCardStatus={linkCardStatus}
                          vipMemberCardsList={vipMemberCardsList}
                          responseGetMemberCards={responseGetMemberCards}
                          // passwordChange={passwordHandler}
                          formik={formik_vipmembershipcard}
                          isSubmitDisabled={
                            submitOnClickDisable ? submitOnClickDisable : false
                          }
                          onClick={(e) => {
                            scrollToDiv(e, formik_vipmembershipcard);
                          }}
                          onChange={(key, val) => {
                            formik_vipmembershipcard.setFieldValue(key, val);
                          }}
                          resetMemberPinStatus={resetMemberPinStatus}
                          availableBalance={availableBalance}
                          submitOnClickDisable={submitOnClickDisable}
                          status={status}
                          setStatus={setStatus}
                          Balance={linkcardObject.balance}
                          CardType={linkcardObject.cardType}
                          expiryDate={linkcardObject.expiryDate}
                          showValue={linkcardObject.showValue}

                          SelectedCard={() => {
                            setIsVisibleMappedMemberCardModal(true);
                          }}

                          unsetLinkMemberCard={() => {
                            setStatus(null);
                            setLinkCardStatus(false);
                            formik_vipmembershipcard.resetForm();
                          }}
                          onSubmitButtonClick={async (event) => {
                            const processMemberCardResponse =
                              await handleVIPMembership();
                            if (processMemberCardResponse?.status === 0) {
                              setSelectedPaymentType("VIPMEMBERSHIPCARD");
                              handleSubmit(event, "VIPMEMBERSHIPCARD");
                            }
                          }}
                          onVerifyButtonClick={async (event) => {
                            if (formik_vipmembershipcard.values.cardHolderName === "" || formik_vipmembershipcard.values.pin === "") {
                              scrollToDiv(event, formik_vipmembershipcard);
                              return;
                            } else {
                              
                              event?.preventDefault();
                              await handleVIPMembership();
                            }
                          }}
                        />
                      </Form>
                    </Tab>
                  )}
                  {paymentTypes?.allowedPaymentTypes?.find((p) => p === "manualauthorization") && (
                    <Tab
                      eventKey="manualauthorization"
                      title={t("payment:manualauthorization")}
                    >
                      {loading ? <LoadingSkeleton /> :
                        <Row className="justify-content-center">
                          <Col md={8}>
                            <Form autoComplete="new-password">
                              <ErrorMessages formik={formik_manualAuthorization} />
                              <div className="bg-danger bg-gradient bg-opacity-50 rounded-1"></div>
                              <div className="mt-4">
                                {showManualAuthPayment === false ? (
                                  <Form onSubmit={handleSubmit}>
                                    <ManualAuthorization
                                      formik={formik_manualAuthorization}
                                      onClick={(e) => {
                                        if (formik_manualAuthorization.values.username === "" || formik_manualAuthorization.values.password === "") {
                                          scrollToDiv(e, formik_manualAuthorization)
                                          return;
                                        }
                                        else {
                                          getManualAuthorizationApi(formik_manualAuthorization.values.username, formik_manualAuthorization.values.password);
                                        }
                                        scrollToDiv(e, formik_manualAuthorization)
                                      }}
                                      onChange={(key, val) => {
                                        formik_manualAuthorization.setFieldValue(key, val);
                                      }}
                                    />
                                  </Form>
                                ) : null}
                                {showManualAuthPayment === true ? (
                                  <Form>
                                    <ErrorMessages formik={formik_manualAuthorization_Creditcard} />
                                    <ManualAuthorizationCreditCard
                                      partnerReferenceNumber={partnerReferenceNumber}
                                      distributorProfileReferencenumberallowed={
                                        distributorProfile?.referencenumberallowed
                                      }
                                      options={paymentTypes?.cardprofile}
                                      formik={formik_manualAuthorization_Creditcard}
                                      onClick={(e) => {
                                        scrollToDiv(e, formik_manualAuthorization_Creditcard)
                                      }}
                                      onChange={(key, val) => {
                                        formik_manualAuthorization_Creditcard.setFieldValue(key, val);
                                      }}
                                    />
                                  </Form>
                                ) : null}
                              </div>
                            </Form>
                          </Col>
                        </Row>
                      }
                    </Tab>
                  )}
                </Tabs>
              </div>
            )}
          </Container>
        </section>
      ) : (
        <section>
          <Container className="my-4 text-center">
            <Card className="p-4">
              <Row>
                <Col md={12} className="d-flex justify-content-center"></Col>
              </Row>
              <Row>
                <Col md={12} sm={12}>
                  <div className="mb-3 my-bg-info">{error}</div>
                </Col>
              </Row>
            </Card>
          </Container>
        </section>
      )}
    </>
  );
}

export default StandByPayment;