import React, { useEffect } from 'react';
import { Col, Form, Row } from "react-bootstrap";
import {
  CUSTOMER_LOGIN,
  DIGICELBLACKCARD_LOGIN,
  GREET_NAME_CHARACTER_MAXLENGTH, PRIORITYPASS_LOGIN, PRODUCTID_DEPARTURE, SPECIAL_OCCASSION_TEXT_AREA_MAXLENGTH
} from "../../config/commonConstants";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandsHoldingCircle } from "@fortawesome/free-solid-svg-icons";
import { checkLoggedInUserIsLoungePartner, getSpecialOccasionsArray, loungePartnerProductOperation } from "./commonFunctions";
import { specialOccasions as SO } from "../../redux/specialOccasionsRedux";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { logindetails as LD, distributorProfile as DP } from "../../redux/signInRedux";
import CommonTextArea from "./CommonTextArea";

function GreetSign(props) {
  const specialOccasions = useSelector(SO);
  const arrivalReasons = getSpecialOccasionsArray(specialOccasions?.data?.arrivalreasons);
  const loginDetails = useSelector(LD);
  const distributorProfile = useSelector(DP);

  let checkBooking = "";
  if(loungePartnerProductOperation("isLoungePartnerLogin","","",loginDetails?.username)){
    checkBooking = checkLoggedInUserIsLoungePartner();
  }
  // if (loginDetails?.username === PRIORITYPASS_LOGIN.username) {
  //   checkBooking = checkLoggedInUserIsLoungePartner();
  // } else if (loginDetails?.username === DIGICELBLACKCARD_LOGIN.username) {
  //   checkBooking = checkLoggedInUserIsLoungePartner();
  // }

  let showLabelAccordingLoggedInUser = false;
  if ((loginDetails?.username !== CUSTOMER_LOGIN?.username) && (checkLoggedInUserIsLoungePartner() === false)) {
    showLabelAccordingLoggedInUser = true;
  }

  useEffect(() => {
    if (props.formik?.values?.greetingDetails?.[props.index]?.occasion?.value !== "OTHER") {
      props.formik.setFieldValue(`greetingDetails.${props.index}.occasionDetail`, "");
    }
  }, [props.formik.values.greetingDetails[props.index]?.occasion?.value]);

  return (

    <div>
      {" "}
      <Row>
        {props?.productid !== PRODUCTID_DEPARTURE && (
          <>
            <h5 className="d-flex ">
              <FontAwesomeIcon
                icon={faHandsHoldingCircle}
                size="lg"
                className="me-2 icon-color"
              />{" "}
              {t("greetsign:nameOnGreetSign")}
            </h5>
            <Col md={3} sm={12}>
              <Form.Group className="my-2" controlid="exampleForm.ControlInput1">
                <Form.Label>
                  {" "}
                  {t("greetsign:nameToShown")}
                  {/* <span className="field">*</span> */}
                </Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => props?.onChange("name", e?.target?.value)}
                  placeholder={t('name')}
                  maxLength={GREET_NAME_CHARACTER_MAXLENGTH}
                  name={`greetingDetails.${props?.index}.name`}
                  id="validationCustom01"
                  //  onBlur={(e) => props?.onBlur("name", e)}
                  onBlur={props?.onBlur}
                  minLength={1}
                  value={props?.formik?.values?.greetingDetails?.[props?.index]?.name}
                //  className={props?.formik?.touched["greetingDetails"]?.[props?.index]?.["name"] && props?.formik?.errors["greetingDetails"]?.[props?.index]?.["name"] ? "error rounded-2" : ""}

                />

              </Form.Group>
            </Col>
            <h6 className="normalMessageColor">
              {GREET_NAME_CHARACTER_MAXLENGTH} {t("greetsign:characters")}
            </h6>
            <div>
              <h6 className="my-bg-info">{t("greetsign:rightReservedMessage")}</h6>
            </div>
          </>
        )}

          {(distributorProfile.groupbookingallowed !== "Y") &&
            ((props.productid === PRODUCTID_DEPARTURE && loginDetails?.username !== CUSTOMER_LOGIN?.username && checkBooking !== true) || (props.productid !== PRODUCTID_DEPARTURE)) && ( 
                  <>
                    <Row>
                      <Col
                        md={4}
                        sm={12}
                        controlid="validationCutom01"
                        className="d-flex"
                      >
                        <Form.Label className="me-2">{showLabelAccordingLoggedInUser ?  t("greetsign:distributorSpecialOccasionlabel"): t("greetsign:specialOccasion")}</Form.Label>
                      </Col>
                      <Col md={4} sm={12}>
                        <Select
                          // className="ms-4 col-md-3 col-sm-12 "
                          name={`greetingDetails.${props.index}.occasion`}
                          value={props.formik?.values?.greetingDetails?.[props.index]?.occasion}
                          onChange={(d) => props.onChange("occasion", d)}
                          options={arrivalReasons}
                          defaultValue={arrivalReasons[0]}
                          onBlur={props.onBlur}
                        //  className={props.errors["occasion"] ? "date_invalid_border_color" : ""}
                        />
                      </Col>
                    </Row>

                    {/* {props.formik?.values?.greetingDetails?.[props.index]?.occasion.value === "OTHER" && (<Col
                      md={11}
                      className=""
                    >
                      <Form.Label htmlFor="exampleForm.ControlTextarea1">{showLabelAccordingLoggedInUser ? t("greetsign:distributorSpecialOccasionOccassionlabel") : t("greetsign:ifOther")}</Form.Label>
                      <Form.Control
                        as="textarea"
                        id="exampleForm.ControlTextarea1"
                        onChange={(e) => props.onChange("occasionDetail", e.target.value)}
                        rows={3}
                        name={`greetingDetails.${props.index}.occasionDetail`}
                        value={props.formik?.values?.greetingDetails?.[props.index]?.occasionDetail}
                        maxLength={SPECIAL_OCCASSION_TEXT_AREA_MAXLENGTH}
                        onBlur={props.onBlur}
                        className={props?.formik?.touched["greetingDetails"]?.[props.index]?.["occasionDetail"] && props?.formik?.errors["greetingDetails"]?.[props.index]?.["occasionDetail"] ? "error rounded-2" : ""}

                      />
                      <Form.Control.Feedback type="invalid">
                        {t('pleaseEnterOccasion')}
                      </Form.Control.Feedback>
                    </Col>)} */}
                    {props.formik?.values?.greetingDetails?.[props.index]?.occasion?.value === "OTHER" &&
                      (<CommonTextArea
                        id="occasionDetail"
                        name="occasionDetail"
                        mandatory={true}
                        event={true}
                        heading={showLabelAccordingLoggedInUser ? t("greetsign:distributorSpecialOccasionOccassionlabel") : t("greetsign:ifOther")}
                        md={11}
                        text={props.formik?.values?.greetingDetails?.[props.index]?.occasionDetail}
                        onBlur={props.onBlur}
                        onChange={(key,e)=>{
                          props.onChange(key,e)
                        }}
                        className={props?.formik?.touched["greetingDetails"]?.[props.index]?.["occasionDetail"] && props?.formik?.errors["greetingDetails"]?.[props.index]?.["occasionDetail"] ? "error rounded-2" : ""}
                        maxLength={SPECIAL_OCCASSION_TEXT_AREA_MAXLENGTH}
                      />
                    )}
                  </>
            )
          }
        </Row>
      </div>
  );
}

export default GreetSign;