import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import {
  LOUNGE_PARTNERS_PRODUCTS,
  PRODUCTID_PRIORITYPASS,
} from "../../config/commonConstants";
import ProductCard from "./ProductCard";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/esm/Container";
import { useSelector } from "react-redux";
import { selectedMarket as currentMarket } from "../../redux/marketRedux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function LoungePartnersCards(props) {
  const { t } = useTranslation(["home", "common"]);
  const [loungePartnerData, setLoungePartnerData] = useState([]);
  const [description, setDescription] = useState([]);
  const selectedMarket = useSelector(currentMarket);

  useEffect(() => {
    const tmpData = [];
    const uniqueProductIds = new Set();
    const priceList = props?.priceList || [];

    priceList.forEach((item) => {
      if (item?.productid === PRODUCTID_PRIORITYPASS) {
        setDescription(item?.description);
        LOUNGE_PARTNERS_PRODUCTS.forEach((data) => {
          if (
            selectedMarket[data?.productAllowed] === "Y" &&
            !uniqueProductIds.has(data.productId)
          ) {
            tmpData.push({
              product: data.product,
              productid: data.productId,
              image: data.image,
              productAllowed: data.productAllowed,
            });
            uniqueProductIds.add(data.productId);
          }
        });
      }
    });

    setLoungePartnerData(tmpData);
  }, [selectedMarket, props?.priceList]);

  return (
    <>
      {loungePartnerData.length > 0 && (
        <section>
          <Container className="my-4">
            <Card>
              <Card.Body>
                <h3 className="text-center mb-4">
                  <span className="heading">{t("home:loungePartners")}</span>
                </h3>
                <Row>
                  <Carousel
                    partialVisible={true}
                    centerMode={false}
                    responsive={{
                      superLargeDesktop: {
                        breakpoint: { max: 4000, min: 3000 },
                        items: 5,
                      },
                      desktop: {
                        breakpoint: { max: 3000, min: 1024 },
                        items: 3,
                      },
                      tablet: {
                        breakpoint: { max: 1024, min: 464 },
                        items: 2,
                      },
                      mobile: {
                        breakpoint: { max: 464, min: 0 },
                        items: 1,
                      },
                    }}
                    className="px-0 justify-content-center"
                  >
                    {loungePartnerData.map((data, index) => (
                      <Col md={12} key={index} className="px-2">
                        <div className="mb-4 mx-2">
                          {/* <h5 className='text-center'>{data.product}</h5> */}
                          <Row>
                            <Col className="col-md-12">
                              <ProductCard
                                title={t("bookDeparture")}
                                productid={data?.productid}
                                image={data?.image}
                                viewPrice={t("home:viewPrices")}
                              />
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    ))}
                  </Carousel>
                </Row>
                <Card.Text className="mx-2">{description}</Card.Text>
              </Card.Body>
            </Card>
          </Container>
        </section>
      )}
    </>
  );
}

export default LoungePartnersCards;
