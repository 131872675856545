import Modal from "react-bootstrap/Modal";
import { Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { LOUNGE_TYPE } from "../../config/commonConstants";
import { getMarketLoungenamesWithAirport, translateArrayKeyValue } from "./commonFunctions";
import { useTranslation } from "react-i18next";
import { selectedMarket as currentMarket } from "../../redux/marketRedux";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { getStopSell } from "../../redux/apiCalls";
import Loader from "./Loader";
import { createColumnHelper } from "@tanstack/react-table"
import { myDateFormat, HandleAPIError } from "./commonFunctions"
import { Table } from "./Table";

export default function SoldOutDataModal(props) {
  const cancelHandler = () => {
    props.cancelModal();
  };
  const { t } = useTranslation(["common", "soldoutdatamodal"]);
  const [loading, setLoading] = useState(true);
  const [Loungetype, setLoungeType] = useState(props.direction);
  const [stopsell, setStopsell] = useState([]);
  const selectedMarket = useSelector(currentMarket);
  const marketLoungenameswithairport = getMarketLoungenamesWithAirport(
    selectedMarket?.airports
  );
  const loungeTypeOptions = translateArrayKeyValue(LOUNGE_TYPE, "LOUNGE_TYPE")
  const columnHelper = createColumnHelper()
  const columns = [
    columnHelper.accessor("airportid", {
      cell: info => <div style={{ textAlign: "left" }}>{marketLoungenameswithairport.find((x) => x.value === info.getValue()).label}</div>,
      header: t('soldoutdatamodal:lounge')
    }),
    columnHelper.accessor("startdate", {
      cell: info => myDateFormat(info.getValue(),"yyyyMMddHHmm"),
      header: t('soldoutdatamodal:date')
    }),
    columnHelper.accessor("timeDuration", {
      cell: info => (
        <div>
          {myDateFormat(info.row.original.startdate, "yyyyMMddHHmm", "hh:mm a")} - {myDateFormat(info.row.original.enddate, "yyyyMMddHHmm", "hh:mm a")}      
        </div>
      ),
      header: t('soldoutdatamodal:timeDuration')
    }),
  ]

  const getStopSell_async = async () => {
    setLoading(true);
    try {
      const response = await getStopSell(Loungetype, selectedMarket?.marketid)
      if (response?.status === 0) {
        setStopsell(response.data.stopsell);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getStopSell_async();
  }, [Loungetype]);

  return (
    <>
      <Loader loading={loading} message={t('loaderMessage')}/>
      {loading === false && (
      <Modal
        show={true}
        onHide={cancelHandler}
        size="lg"
        style={{ zIndex: "9998" }}
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton><h3 closeButton className="fw-bold modalHeading">{selectedMarket?.marketname.toUpperCase() +
                  " - " +
                  t("soldoutdatamodal:soldOutDateTime")}</h3></Modal.Header>
        <Modal.Body>
          <Form className="">
            <h5 className="mb-3 alert alert-info text-align-justify">
              <b>{t("soldoutdatamodal:soldOutDataMessage")}</b>
            </h5>
            <Row>
              <Col md={3} sm={6} controlid="validationCustom01">
                <Form.Group className="">
                  <Select
                    className="my-0 mb-3"
                    options={loungeTypeOptions}
                    defaultValue={loungeTypeOptions.find(
                      (i) => i.value === Loungetype
                    )}
                    onChange={(d) => setLoungeType(d?.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <Card className="bg-light bg-opacity-10 rounded-3 my-3">
            <Card.Body className="p-2">
              <Table columns={columns} data={stopsell} noDataMessage={t("noDataAvailable")} />
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      )}
    </>
  );
}
