import MySwal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import i18n from "i18next";
import { faCircleCheck, faCircleInfo, faCircleXmark, faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import "bootstrap/dist/css/bootstrap.min.css";
import SwalTitleComponent from "./SwalTitleComponent";
import SwalHtmlComponent from "./SwalHtmlComponent";

const mySwal = withReactContent(MySwal)
const toastPosition = "center"

export const showSuccessToast = (
  title,
  message,
  timer = 2000,
  position = toastPosition
) => {
  return mySwal.fire({
    titleText: title,
    html: message,
    timer,
    position,
    allowOutsideClick: false,
    timerProgressBar: true,
    toast: true,
    icon: "success",
    showConfirmButton: false
  })
}

export const showErrorToast = (
  title,
  message,
  timer = 2000,
  position = toastPosition
) => {
  return mySwal.fire({
    titleText: title,
    html: message,
    timer,
    position,
    allowOutsideClick: false,
    timerProgressBar: true,
    toast: true,
    icon: "error",
    showConfirmButton: false
  })
}

export const showConfirm = (title = i18n.t('common:confirm'), message = i18n.t('common:areYouSure'), confirmButtonText = i18n.t('common:confirm'),
  cancelButtonText = i18n.t('common:cancel')) => {

  return mySwal.fire({
    title: <SwalTitleComponent icon={faCircleQuestion} title={title} color={"secondary"} />,
    html: <SwalHtmlComponent message={message} />,
    showCancelButton: true,
    showConfirmButton: true,
    confirmButtonColor: "#9f004f", //"#dc3545",
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    allowEnterKey: true,
    allowEscapeKey: true,
    allowOutsideClick: false,
    scrollbarPadding: false,
    customClass: {
      actions: "my-actions",
      confirmButton: "order-1 left-gap",
      cancelButton: "order-2",
    },
  })
}

export const showSuccess = (title = "", message = "") => {
  return mySwal.fire({
    title: <SwalTitleComponent icon={faCircleCheck} title={title} color={"success"}/>,
    html: <SwalHtmlComponent message={message}/>,
    showConfirmButton: true,
    confirmButtonText: i18n.t('common:ok'),
    confirmButtonColor: "#9f004f",
    scrollbarPadding: false,
    allowOutsideClick: false,
    customClass: {
      actions: "my-actions",
      confirmButton: "order-1 left-gap",
    },
  })
}

export const showError = (title , message) => {
  return mySwal.fire({
    title: <SwalTitleComponent icon={faCircleXmark} title={title} color={"danger"}/>,
    html: <SwalHtmlComponent message={message}/>,
    showConfirmButton: true,
    confirmButtonText: i18n.t('common:ok'),
    confirmButtonColor: "#9f004f",
    allowOutsideClick: false,
    customClass: {
      container: "swal-container",
      actions: "my-actions",
      confirmButton: "order-1 left-gap",
    },
    scrollbarPadding: false,
  });
}

export const showInfo = (title = "Info", message = "") => {
  return mySwal.fire({
    title: <SwalTitleComponent icon={faCircleInfo} title={title} color={"secondary"}/>,
    html: <SwalHtmlComponent message={message}/>,
    showConfirmButton: true,
    confirmButtonText: i18n.t('common:ok'),
    allowOutsideClick: false,
    confirmButtonColor: "#9f004f",
    scrollbarPadding: false,
    customClass: {
      actions: "my-actions",
      confirmButton: "order-1 left-gap",
    },
  })
}

export default mySwal;