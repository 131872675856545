import Container from "react-bootstrap/Container";
import SocialMediaIcon from "./commonComponents/SocialMediaIcon";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GoogleQR from "../images/GoogleQR.jpg";
import gplay from "../images/gplay.png";
import itunes from "../images/itunes.png";
import AppleQR from "../images/AppleQR.jpg";
import Link from "react-bootstrap/Anchor";
import { useTranslation } from "react-i18next";

import {
  faFacebookSquare,
  faPinterestSquare,
  faSquareXTwitter,
  faInstagramSquare,
  faYoutubeSquare,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const { t } = useTranslation(["common", "footer"]);

  return (
    <div className=" bg-dark">
      <Container>
        {/* <Row className="py-3">
          <Col sm={6} md={3} className="mb-3 mb-md-0">
            <ul className="list-unstyled">
              <li className="py-0 py-md-1">
                <Link to="#" className="text-white">
                  {t("footer:home")}
                </Link>
              </li>
              <li className="py-0 py-md-1">
                <Link to="#" className="text-white">
                  {t("footer:loungeInformation")}
                </Link>
              </li>
              <li className="py-0 py-md-1">
                <Link to="#" className="text-white">
                  {t("footer:accessMembership")}
                </Link>
              </li>
              <li className="py-0 py-md-1">
                <Link to="#" className="text-white">
                  {t("footer:airports")}{" "}
                </Link>
              </li>
              <li className="py-0 py-md-1">
                <Link to="#" className="text-white">
                  {t("footer:boardDirectors")}{" "}
                </Link>
              </li>
              <li className="py-0 py-md-1">
                <Link to="#" className="text-white">
                  {t("footer:privacy")}{" "}
                </Link>
              </li>
              <li className="py-0 py-md-1">
                <Link to="#" className="text-white">
                  {t("footer:content")}
                </Link>
              </li>
            </ul>
          </Col>
          <Col sm={6} md={3} className="mb-3 mb-md-0">
            <ul className="list-unstyled">
              <li className="py-0 py-md-1">
                <Link to="#" className="text-white">
                  {t("footer:seniorManagement")}
                </Link>
              </li>
              <li className="py-0 py-md-1">
                <Link to="#" className="text-white">
                  {t("footer:leadershipTeam")}
                </Link>
              </li>
              <li className="py-0 py-md-1">
                <Link to="#" className="text-white">
                  {t("footer:latestNews")}
                </Link>
              </li>
              <li className="py-0 py-md-1">
                <Link to="#" className="text-white">
                  {t("footer:brochuresForms")}
                </Link>
              </li>
              <li className="py-0 py-md-1">
                <Link to="#" className="text-white">
                  {t("footer:videos")}
                </Link>
              </li>
              <li className="py-0 py-md-1">
                <Link to="#" className="text-white">
                  {t("footer:testimonials")}
                </Link>
              </li>
            </ul>
          </Col>
          <Col sm={6} md={3} className="mb-3 mb-md-0">
            <ul className="list-unstyled">
              <li className="py-0 py-md-1">
                <Link to="#" className="text-white">
                  {t("footer:thanksForMakingThingBetter")}
                </Link>
              </li>
              <li className="py-0 py-md-1">
                <Link to="#" className="text-white">
                  {t("footer:faq")}
                </Link>
              </li>
              <li className="py-0 py-md-1">
                <Link to="#" className="text-white">
                  {t("footer:career")}
                </Link>
              </li>
              <li className="py-0 py-md-1">
                <Link to="#" className="text-white">
                  {t("footer:termsUse")}
                </Link>
              </li>
              <li className="py-0 py-md-1">
                <Link to="#" className="text-white">
                  {t("footer:cancellationPolicy")}
                </Link>
              </li>
            </ul>
          </Col>
          <Col sm={12} md={3}>
            <ul className="list-unstyled d-flex justify-centent-around px-0">
              <li>
                <SocialMediaIcon
                  size={"2xl"}
                  iconType={faFacebookSquare}
                  url={"https://www.facebook.com/clubmobay/"}
                  title={t("facebook")}
                />
              </li>
              <li>
                <SocialMediaIcon
                  size={"2xl"}
                  iconType={faSquareXTwitter}
                  url={"https://twitter.com/search?q=club%20mobay&src=typd"}
                  title={t("twitter")}
                />
              </li>
              <li>
                <SocialMediaIcon
                  size={"2xl"}
                  iconType={faPinterestSquare}
                  url={"https://in.pinterest.com/"}
                  title={t("google")}
                />
              </li>
              <li>
                <SocialMediaIcon
                  size={"2xl"}
                  iconType={faInstagramSquare}
                  url={"https://www.instagram.com/clubmobay/"}
                  title={t("instagram")}
                />
              </li>
              <li>
                <SocialMediaIcon
                  size={"2xl"}
                  iconType={faYoutubeSquare}
                  url={"https://www.youtube.com/channel/UCosxMPiyNvpBa6zR5cmabEQ"}
                  title={t("google")}
                />
              </li>
            </ul>
          </Col>
        </Row> */}
        <Row className="d-flex justify-content-center py-3">
        <Col md={1} className="d-flex justify-content-center px-0">
            <a href="/" className="text-white">
              {t("footer:home")}
            </a>
          </Col>
          <Col md={1} className="d-flex justify-content-center px-0">
            <a href="https://www.vipattractions.com/page/career" className="text-white" target="_blank">
              {t("footer:career")}
            </a>
          </Col>
          <Col md={1} className="d-flex justify-content-center px-0">
            <a href="https://www.vipattractions.com/page/terms" className="text-white" target="_blank">
              {t("footer:termsOfUse")}
            </a>
          </Col>
          <Col md={2} className="d-flex justify-content-center text-center px-0">
            <a href="https://www.vipattractions.com/page/cancellation-policy" className="text-white" target="_blank">
              {t("footer:cancellationPolicy")}
            </a>
          </Col>
          <Col md={1} className="d-flex justify-content-center px-0">
            <a href="https://www.vipattractions.com/page/privacy" className="text-white" target="_blank">
              {t("footer:privacy")}
            </a>
          </Col>
          <Col md={1} className="d-flex justify-content-center px-0">
            <a href="https://www.vipattractions.com/page/contact" className="text-white" target="_blank">
              {t("footer:contact")}
            </a>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center py-3">
          <Col md={6} lg={4} className="">
            <Row>
              <Col className="p-1 d-flex justify-content-center">
                <span className=" text-small text-white">
                  {t("footer:getYourLoungeBookingApp")}
                </span>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-center ">
                <a href="https://play.google.com/store/apps/details?id=com.reliable.rlbapp" target="_blank">
                  <img
                    src={gplay}
                    width="150"
                    className="d-inline-block p-1 align-center"
                    alt="Google Play"
                  />
                </a>
                <a href="https://apps.apple.com/jp/app/reliable-lounge-bookings/id1352180887?l=en" target="_blank">
                  <img
                    src={itunes}
                    width="150"
                    className="d-inline-block p-1 align-center"
                    alt="App Store"
                  />
                </a>
              </Col>
            </Row>
            <Row>
              <Col className="p-1 d-flex justify-content-center">
                <img
                  src={GoogleQR}
                  width="150"
                  className="d-inline-block p-1 align-center"
                  alt=""
                />
                <img
                  src={AppleQR}
                  width="150"
                  className="d-inline-block p-1 align-center"
                  alt=""
                />
              </Col>
            </Row>
          </Col>
          <Col md={6} lg={4} className="">
            <Row>
              <Col className="p-1 d-flex justify-content-center">
                <span className="text-small text-white">
                  {t("footer:downloadApp")}
                </span>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-center px-0">
                <a href="https://play.google.com/store/apps/details?id=co.codecreators.JamaicaExperience" target="_blank">
                  <img
                    src={gplay}
                    width="150"
                    className="d-inline-block p-1 align-center"
                    alt="Google Play"
                  />
                </a>
                <a href="https://apps.apple.com/us/app/jamaica-experiences/id1060263506" target="_blank">
                  <img
                    src={itunes}
                    width="150"
                    className="d-inline-block p-1 align-center"
                    alt="App Store"
                  />
                </a>
              </Col>
            </Row>
            <Row className="">
              <Col className="p-1 d-flex justify-content-center">
                <ul className="list-unstyled d-flex justify-centent-around px-0">
                  <li>
                    <SocialMediaIcon
                      size={"2xl"}
                      iconType={faFacebookSquare}
                      url={"https://www.facebook.com/clubmobay/"}
                      title={t("facebook")}
                    />
                  </li>
                  <li>
                     <SocialMediaIcon
                      size={"2xl"}
                      iconType={faSquareXTwitter}
                      url={"https://twitter.com/search?q=club%20mobay&src=typd"}
                      title={t("twitter")}
                    /> 
                  </li>
                  <li>
                    <SocialMediaIcon
                      size={"2xl"}
                      iconType={faPinterestSquare}
                      url={"https://in.pinterest.com/"}
                      title={t("google")}
                    />
                  </li>
                  <li>
                    <SocialMediaIcon
                      size={"2xl"}
                      iconType={faInstagramSquare}
                      url={"https://www.instagram.com/clubmobay/"}
                      title={t("instagram")}
                    />
                  </li>
                  <li>
                    <SocialMediaIcon
                      size={"2xl"}
                      iconType={faYoutubeSquare}
                      url={"https://www.youtube.com/channel/UCosxMPiyNvpBa6zR5cmabEQ"}
                      title={t("google")}
                    />
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>       
        <Row>
          <Col className="mb-3 d-flex justify-content-center ">
            <span className=" text-small text-white opacity-50">
              &copy; {new Date().getFullYear()}{" "}
              {t("footer:clubMobayAllRightsReserved")}
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
