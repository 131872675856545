import { faChild } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, Row } from "react-bootstrap";
import {
  DOB_YEARS,
  FIRST_NAME_MAXLENGTH,
  LAST_NAME_MAXLENGTH,
  MAX_DOB_DATE,
  REGEX_ALPHABET,
  SALUTATION
} from "../../config/commonConstants";
import OverlayTooltip from "./OverlayTooltip";
import { useState } from "react";
import DatepickerComponent from "./DatepickerComponent";
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import { alphabetValidation, dateDifferenceInYears, getAdultMinimumAge, getChildMinimumAge, translateArrayKeyValue } from "./commonFunctions";
import { showConfirm } from "./Swal";
import { getYear } from "date-fns";
import range from "lodash/range";

function ChildDetails(props) {
  const [childDob, setchildDob] = useState(new Date());
  const { t } = useTranslation(['common', 'childdetails']);

  const validateDateOfBirth = (val) => {
    let adultMinimum = getAdultMinimumAge();
    let childMinimum = getChildMinimumAge();
    const years = dateDifferenceInYears(props.bookingDate, val);
    if (props.type === "child") {
      if ((years < childMinimum || years > (adultMinimum))) {
        const confirmed = showConfirm(t('confirm'), t('childDobValidationError', { adultMinimum: adultMinimum, childMinimum: childMinimum }))
        if (confirmed.isConfirmed) {
          props.onChange("dob", "")
        }
        else {
          props.onChange("dob", "")
        }

      }
      else
        props.onChange("dob", val)
    }
    else if (props.type === "infant") {
      if ((years > childMinimum - 1)) {
        const confirmed = showConfirm(t('confirm'), t('infantDobValidationError', { childMinimum: childMinimum }))
        if (confirmed.isConfirmed) {
          props.onChange("dob", "")
        }
        else {
          props.onChange("dob", "")
        }
      }
      else
        props.onChange("dob", val)
    }
  }


  return (
    <div>
      <Row className="mt-3">
        <Col md="auto" controlid="validationCustom01">
          <Form.Label>
            {props.isMandatory === true ? (
              <Form.Label className="d-flex">
                <FontAwesomeIcon
                  icon={faChild}
                  className="me-2 icon-color"
                  size="lg"
                />
                {props.heading}
                <span className="field">*</span>
              </Form.Label>
            ) : (
              <Form.Label></Form.Label>
            )}
          </Form.Label>
          {/* <Form.Select
            value={props.title}
            className={props.index === 0 ? "" : "mt-2"}
            onChange={(e) => props.onChange("title", e.target.value)}
            aria-label="Default select example"
          >
           <option value="MR">{t('mr')}</option>
          <option value="MRS">{t('mrs')}</option>
          <option value="MISS">{t('miss')}</option>
          <option value="MASTER">{t('master')}</option>
          </Form.Select> */}
          <Select
            options={translateArrayKeyValue(SALUTATION, "SALUTATION")}
            className={props.isMandatory === true ? "my-0 select_width" : "mt-2 select_width"}
            value={props.title}
            onChange={(d) => props.onChange("title", d)}
          />
        </Col>
        <Col md="2" controlid="validationCustom01">
          <Form.Label></Form.Label>
          <Form.Control
            type="text"
            placeholder={t('firstName')}
            maxLength={FIRST_NAME_MAXLENGTH}
            minLength={1}
            name={props.type === "child" ? `childs.${props.index}.firstName` : `infants.${props.index}.firstName`}
            id={"validationCustom01" + props.index}
            onChange={(e) => {
              // const newValue = e.target.value.replace(REGEX_ALPHABET, "");
              props.onChange("firstName", alphabetValidation(e.target.value));
            }}
            onBlur={(e) => props.onBlur("firstName", e)}
            //  onBlur={props.onBlur}
            value={props.type === "child" ? props.formik?.values?.childs?.[props.index]?.firstName : props.formik?.values?.infants?.[props.index]?.firstName}
            className={props?.formik?.touched[props.type === "child" ? "childs" : "infants"]?.[props.index]?.["firstName"] && props?.formik?.errors[props.type === "child" ? "childs" : "infants"]?.[props.index]?.["firstName"] ? "error rounded-2 mt-3" : " mt-3"}
          />
          {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            Please Enter First Name.
          </Form.Control.Feedback> */}
        </Col>
        <Col md="2" controlid="validationCustom02">
          <Form.Label></Form.Label>
          <Form.Control
            type="text"
            placeholder={t('lastName')}
            maxLength={LAST_NAME_MAXLENGTH}
            minLength={1}
            name={props.type === "child" ? `childs.${props.index}.lastName` : `infants.${props.index}.lastName`}
            id="validationCustom01"
            onChange={(e) => {
              // const newValue=e.target.value.replace(REGEX_ALPHABET,"")
              props.onChange("lastName", alphabetValidation(e.target.value))
            }}
            onBlur={(e) => props.onBlur("lastName", e)}
            value={props.type === "child" ? props.formik?.values?.childs?.[props.index]?.lastName: props.formik?.values?.infants?.[props.index]?.lastName}
            className={props?.formik?.touched[props.type === "child" ? "childs" : "infants"]?.[props.index]?.["lastName"] && props?.formik?.errors[props.type === "child" ? "childs" : "infants"]?.[props.index]?.["lastName"] ? "error rounded-2 mt-3" : " mt-3"}

          />
   {/* onChange={(e) => {
              const newValue = e.target.value.replace(REGEX_ALPHABET, "");
               props.onChange("firstName", newValue);
              }}
            onBlur={(e) => props.onBlur("firstName", e)}
            //  onBlur={props.onBlur}
            value={props.type === "child" ? props.formik?.values?.childs?.[props.index]?.firstName : props.formik?.values?.infants?.[props.index]?.firstName} */}

          {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            Please Enter Last Name.
          </Form.Control.Feedback> */}
        </Col>
        <Col
          md="3"
          controlid="validationFormikadultDate"
          className="mt-2"
        >
          <Form.Label>
            <OverlayTooltip
              title={t('dateOfBirth')}
              msg={t('childdetails:childDetailAgeMessage',{minAge:getChildMinimumAge(),maxAge:getAdultMinimumAge()})}
            ></OverlayTooltip>
          </Form.Label>
          {/* <DatePicker
            onChange={(d) => props.onChange("dob", d)}
            value={props.dob}
          /> */}
          <div>
            <DatepickerComponent
              range={range(getYear(new Date()) - DOB_YEARS, getYear(new Date())+1, 1)}
              variableName="dob"
              onChangeProps={false}
              onchange={async (val) => {
                await validateDateOfBirth(val);
                //props.onChange("dob", val)
              }}
              showClearIcon={true}
              name={props.type === "child" ? `childs.${props.index}.dob` : `infants.${props.index}.dob`}
              onBlur={(e) => props.onBlur("dob", e)}
              maxDate={new Date()}
              minDate={MAX_DOB_DATE}
              value={props.type === "child" ? props.formik?.values?.childs?.[props.index]?.dob : props.formik?.values?.infants?.[props.index]?.dob}
              className={props?.formik?.touched[props.type === "child" ? "childs" : "infants"]?.[props.index]?.["dob"] && props?.formik?.errors[props.type === "child" ? "childs" : "infants"]?.[props.index]?.["dob"] ? "error rounded-2" : ""}
            />
           </div>
        </Col>
      </Row>
    </div>
  );
}

export default ChildDetails;
