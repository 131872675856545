import { Card, Col, Form, Row, Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import Password from "../components/commonComponents/Password";
import CommonButton from "../components/commonComponents/CommonButton";
import { useTranslation } from "react-i18next";
import Loader from "../components/commonComponents/Loader";
import { changeMemberCardPin, processMemberCard } from "../redux/apiCalls";
import md5 from "md5";
import { showError, showSuccess } from "../components/commonComponents/Swal";
import { useNavigate, useLocation } from "react-router-dom";
import Select from "react-select";
import { HandleAPIError, divRef, getMemberCardsArray, numericValidation, renderErrorMessages, scrollToDiv, translateConstantText } from "../components/commonComponents/commonFunctions";
import { MEMBERCARD_CARD_NUMBER_LABEL, MEMBERCARD_CARD_TYPE_LABEL, MEMBERCARD_NUMBER_MAXLENGTH, MEMBERCARD_PIN_MAXLENGTH } from "../config/commonConstants";
import ExitButton from "../components/commonComponents/ExitButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { memberCards as MC } from "../redux/memberCardRedux";
import { useSelector } from "react-redux";
import ErrorMessages from "../components/commonComponents/ErrorMessages";

function ChangeMemberPin() {
  const [MemberCardTypeOptions, setMemberCardTypeOptions] = useState([]);
  const { t } = useTranslation(["common", "memberandcardinformation"]);
  const memberCards = useSelector(MC);
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state;
  const stateVariable = location?.state?.memberCardData;
  const stateMemberCardProfileData = location?.state?.memberCardProfileData
  const [memberCardNumber, setMemberCardNumber] = useState();
  const [memberCardType, setMemberCardType] = useState({ value: "", label: "" });

  const validationSchema = Yup.object().shape({
    cardType: Yup.object().required(t("memberandcardinformation:errors.cardType.required", { membershipType: translateConstantText("MEMBERCARD_CARD_TYPE_LABEL") })),
    cardNumber: Yup.string()
      .required(t("memberandcardinformation:errors.cardNumber.required", { membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") }))
      .matches(/^[0-9]+$/, t("memberandcardinformation:errors.cardNumber.match", { membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") }))
      .length(16, t("memberandcardinformation:errors.cardNumber.length", { membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") })),
    oldPin: Yup.string().required(t("memberandcardinformation:errors.oldPin.required")).min(MEMBERCARD_PIN_MAXLENGTH).max(MEMBERCARD_PIN_MAXLENGTH),
    newPin: Yup.string().required(t("memberandcardinformation:errors.newPin.required")).min(MEMBERCARD_PIN_MAXLENGTH).max(MEMBERCARD_PIN_MAXLENGTH),
    confirmPin: Yup.string().required(t("memberandcardinformation:errors.confirmPin.required")).min(MEMBERCARD_PIN_MAXLENGTH).max(MEMBERCARD_PIN_MAXLENGTH)
      .oneOf([Yup.ref('newPin'), null], t("memberandcardinformation:errors.compareNewPin"))

  });
  const formik = useFormik({
    initialValues: {
      cardType: stateVariable ? stateVariable?.cardType : "",
      cardNumber: stateVariable ? stateVariable?.cardNumber : "",
      oldPin: "",
      newPin: "",
      confirmPin: ""
    },
    validationSchema,
    onSubmit: () => { handleSubmit(formik.values) },
  });
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (formiks) => {
    let memberCardTypeNumber = ""
    memberCardTypeNumber = formiks.cardType.value + formiks.cardNumber;
    const data = {
      "memberid": memberCardTypeNumber,
      "oldpin": md5(formiks.oldPin),
      "newpin": md5(formiks.newPin),
    };

    const arrGetValueResponse = {
      "action": "VALIDATE",
      "memberid": memberCardTypeNumber,
      "pin": md5(formiks.oldPin),
      "allowexpired": "N"
    }

    try {
      setLoading(true);
      const response = await processMemberCard(arrGetValueResponse);
      const memberProfileData = response?.data;
      if (response?.status === 0) {
        try {
          const response = await changeMemberCardPin(data);
          if (response?.status === 0) {
            const memberCardProfileData = {
              cardNumber: stateMemberCardProfileData?.cardNumber,
              cardTypeValue: stateMemberCardProfileData?.cardTypeValue,
              cardType: stateMemberCardProfileData?.cardType,
              showvalue: stateMemberCardProfileData?.showvalue,
              pin: formiks.newPin,
            }
            formik.resetForm();
            await showSuccess(response.statusMessage, t('memberandcardinformation:pinUpdateMessage'))
            if (locationState?.sendToMemberProfile === true) {
              navigate("/member-card/member-profile", { state: { memberProfileData, memberCardProfileData } });
            } else if (locationState?.sendToLinkMemberCard) {
              navigate("/mapmembercards", { state: { memberProfileData, memberCardProfileData } });
            }
          }
          else {
            showError(t("error"), response?.statusMessage);
          }
        } catch (e) {
          HandleAPIError(e);
        } finally {
          setLoading(false);
        }
      }
      else {
        showError(t("error"), response?.statusMessage);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }

  };

  const setMemberCardArray = async () => {
    const memberCardsArray = getMemberCardsArray(memberCards);
    setMemberCardTypeOptions(memberCardsArray);
  }

  useEffect(() => {
    setMemberCardArray();
  }, [memberCards]);

  useEffect(() => {
    setMemberCardNumber(stateVariable?.cardNumber);
    setMemberCardType(stateVariable?.cardType);
  }, []);

  return (
    <>
      <section>
        <Container className="my-4" ref={divRef}>
          <Loader loading={loading} message={t("loaderMessage")} />

          <h3 className="my-3 d-flex justify-content-center heading">
            <span className="heading">{t("memberandcardinformation:changeMemberPinHeader")}</span>
          </h3>
          <Row className="my-3 d-flex justify-content-end align-content-right">
            <Col md={12} className="d-flex justify-content-end">
              <ExitButton />
            </Col>
          </Row>
          <Form onSubmit={(e) => scrollToDiv(e, formik)}>
            <Row className="d-flex justify-content-center mb-3">
              <Col md={12}>
                <Card className="bg-light bg-opacity-10 rounded-3 ">
                  <Card.Body>     
                    <Row className="d-flex justify-content-start">
                      <Col md={5}>
                        <h6 className="field">{t('fillAllFields')}</h6>
                      </Col>
                   </Row>
                  <ErrorMessages formik={formik} />
                    <Row className="d-flex justify-content-center">
                      <Col md={4} sm={12} className="mb-3">
                        <Form.Group>
                          <Form.Label className="d-flex">
                            {t('memberandcardinformation:cardType', { membershipType: translateConstantText("MEMBERCARD_CARD_TYPE_LABEL") })}: {" "}
                            {stateVariable?.showLabel === "Y" ?
                              <b>{memberCardType.label}</b>
                              :
                              <span className="field">*</span>
                            }
                          </Form.Label>
                          {stateVariable?.showLabel !== "Y" &&
                            (<Select
                              options={MemberCardTypeOptions}
                              name="cardType"
                              value={formik.values.cardType}
                              onChange={(d) => formik.setFieldValue('cardType', d)}
                              onBlur={(d) => formik.handleBlur}
                              className={formik.touched.cardType && formik.errors.cardType ? " error rounded-2" : ""}
                            />
                            )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="d-flex justify-content-center">
                      <Col
                        md={4} sm={12}
                        className="mb-3"
                      >
                        <Form.Label className="d-flex" htmlFor="cardNumber">
                          {t('memberandcardinformation:cardNumber', { membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") })}: {" "}
                          {stateVariable?.showLabel === "Y" ?
                            <b> {memberCardNumber} </b>
                            :
                            <span className="field">*</span>
                          }
                        </Form.Label>
                        {stateVariable?.showLabel !== "Y" &&
                          (<Form.Control
                            type="text"
                            id="cardNumber"
                            name="cardNumber"
                            placeholder={t('cardNumber', { membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") })}
                            value={formik.values.cardNumber}
                            maxLength={MEMBERCARD_NUMBER_MAXLENGTH}
                            onChange={(e) => {
                              e.target.value = numericValidation(e.target.value)
                              formik.handleChange(e);
                              }}	
                            onBlur={formik.handleBlur}
                            className={formik.touched.cardNumber && formik.errors.cardNumber ? "error" : ""}
                          />)
                        }
                      </Col>
                    </Row>
                    <Row className="d-flex justify-content-center">
                      <Col md={4} sm={12} className="mb-3">
                        <Form.Label htmlFor="validationCustomOldPassword">
                          {t("memberandcardinformation:oldPin")}<span className="field">*</span>
                        </Form.Label>
                        <Password
                          placeholder={t('memberandcardinformation:oldPin')}
                          id="validationCustomOldPassword"
                          name="oldPin"
                          autoComplete="new-password"
                          value={formik.values.oldPin}
                          minLength={MEMBERCARD_PIN_MAXLENGTH}
                          maxLength={MEMBERCARD_PIN_MAXLENGTH}
                          onChange={(e) => {
                            e.target.value = numericValidation(e.target.value)
                            formik.handleChange(e);
                            }}	                          
                            onBlur={formik.handleBlur}
                          className={formik.touched.oldPin && formik.errors.oldPin ? "error rounded-2" : "rounded-2"}
                        />
                      </Col>
                    </Row>
                    <Row className="d-flex justify-content-center">
                      <Col md={4} sm={12} className="mb-3">
                        <Form.Label htmlFor="validationCustomNewPassword">
                          {t("memberandcardinformation:newPin")}<span className="field">*</span>
                        </Form.Label>
                        <Password
                          placeholder={t('memberandcardinformation:newPin')}
                          // passwordChange={oldPasswordHandler}
                          id="validationCustomNewPassword"
                          name="newPin"
                          autoComplete="new-password"
                          value={formik.values.newPin}
                          minLength={MEMBERCARD_PIN_MAXLENGTH}
                          maxLength={MEMBERCARD_PIN_MAXLENGTH}
                          onChange={(e) => {
                            e.target.value = numericValidation(e.target.value);
                            formik.handleChange(e);
                            }}	                          
                            onBlur={formik.handleBlur}
                          className={formik.touched.newPin && formik.errors.newPin ? "error rounded-2" : "rounded-2"}
                        />
                      </Col>
                    </Row>
                    <Row className="d-flex justify-content-center">
                      <Col md={4} sm={12} className="mb-3">
                        <Form.Label htmlFor="validationCustomConfirmPassword">
                          {t("memberandcardinformation:confirmPin")}<span className="field">*</span>
                        </Form.Label>
                        <Password
                          placeholder={t("memberandcardinformation:confirmPin")}
                          id="validationCustomConfirmPassword"
                          name="confirmPin"
                          autoComplete="new-password"
                          value={formik.values.confirmPin}
                          minLength={MEMBERCARD_PIN_MAXLENGTH}
                          maxLength={MEMBERCARD_PIN_MAXLENGTH}
                          onChange={(e) => {
                            e.target.value = numericValidation(e.target.value);
                            formik.handleChange(e);
                            }}	
                          onBlur={formik.handleBlur}
                          className={formik.touched.confirmPin && formik.errors.confirmPin ? "error rounded-2" : "rounded-2"}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="d-flex justify-content-center">
                        <CommonButton
                          type="submit"
                          classname="gradient-btn "
                          size="md"
                          title={t('memberandcardinformation:changePin')}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </section>
    </>
  );
}
export default ChangeMemberPin;