import { CUSTOMER_LOGIN, DIGICELBLACKCARD_LOGIN, DRAGONPASSCARD_LOGIN, PARTNER_LOGIN_EXCLUSION, PRIORITYPASS_LOGIN, PROMOTION_KEY_EXPIRY_TIME, VIPER_CONST, VIPER_LOG_CONST } from "../config/commonConstants";
import { marketFetchFailure, marketFetchSuccess, setJamaicanTime } from "./marketRedux";
import { logInServiceError, loginInServiceSuccess } from "./signInRedux";
import { specialOccasionsFetchSuccess, specialOccasionsFetchFailure } from "./specialOccasionsRedux";
import { paymentGatewayFetchSuccess, paymentGatewayFetchFailure } from "./paymentGatewayRedux";
import { _get, _post } from "../BaseApi";
import { resetCartData } from "./cartRedux";
import { memberCardValuesFetchFailure, memberCardValuesFetchSuccess, memberCardsFetchFailure, memberCardsFetchSuccess } from "./memberCardRedux";
import { getLoginDetails, encryptData, sessionChangeBroadcast, checkIfPartnerLogin} from "../components/commonComponents/commonFunctions";
import { setLoggedInUserDetail } from "./loggedInUserDetailRedux";
import CryptoJS from 'crypto-js';
import i18n from "i18next";
import { standByServiceSuccess, standByServiceError } from "./standByRedux"
import { store } from "../store/store";
import { Cookies } from "react-cookie";
import { useLocation } from "react-router-dom";
// import { store } from "../store/store";

const baseURL = VIPER_CONST.base_url;
const logURL = VIPER_LOG_CONST.base_url;
const cookies = new Cookies();

export const getMarkets = async (dispatch) => {
  let body = {
    username: VIPER_CONST.alwaysOnUsername,
    sessionid: VIPER_CONST.alwaysOnSessionid,
    request: {},
  };
  try {
    const response = await _post(`${baseURL}getmarkets`, body);

    dispatch(marketFetchSuccess(response));
    getJamaicanTime(true)
  } catch (err) {
    dispatch(marketFetchFailure());
  }
};

export const getSchedule = async (dispatch, request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };

  try {
    const response = await _post(`${baseURL}getschedule`, body);

    //  dispatch(scheduleFetchSuccess(response));
    return response;
  } catch (err) {
    dispatch(marketFetchFailure());
  }
};

export const reserveCartItem = async (dispatch, req) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: req,
  };

  const response = await _post(`${baseURL}reservecartitem`, body);
  return response;
};

export const confirmCart = async (dispatch, request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };

  const response = await _post(`${baseURL}confirmcart`, body);
  if (response?.status === 0) {
    dispatch(resetCartData());
  }
  return response;
};

export const login = async (dispatch, data, sendBroadcastMessage, standByLogin) => {
  let response = "";
  const state = store.getState();
  const body = {
    failstatus: 0,
    request: {
      username: data.username,
      password: data.password,
      marketid: data?.marketid,
      languageid: data?.languageid
    },
  };

  if (data.affiliateid !== "!" && data.affiliateid !== 0 && data.affiliateid !== "0") {
    body.request.affiliateid = data.affiliateid;
  }

  if (checkIfPartnerLogin() && !PARTNER_LOGIN_EXCLUSION.includes(data.username)) {
    body.request.distributorid = state?.partnerRedux?.partnerDetails?.distributorid;
  }

  try {
    response = await _post(`${baseURL}login`, body);
    if (response?.status === 0) {
      const responseData = response?.data;

      const loginDetail = {
        username: responseData.username,
        password: data.password,
        sessionid: response.data?.sessionid,
      };
      // ******** save login detail into local storage *********//
      if (responseData.username !== CUSTOMER_LOGIN?.username && responseData.username !== PRIORITYPASS_LOGIN?.username && responseData.username !== DIGICELBLACKCARD_LOGIN?.username  && responseData.username !== DRAGONPASSCARD_LOGIN?.username) {
        encryptData(loginDetail);
      }
      standByLogin ? dispatch(standByServiceSuccess(responseData)) : dispatch(loginInServiceSuccess(responseData));
      !standByLogin && sessionChangeBroadcast(sendBroadcastMessage);
    } else {
      standByLogin ? dispatch(standByServiceError(response.statusMessage)) : dispatch(logInServiceError(response.statusMessage))
    }
  }
  catch (err) {
    dispatch(logInServiceError(i18n.t('networkErrorMessage')));
  }
  return response;
};

export const getStopSell = async (direction, marketid) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: { direction: direction, marketid: marketid },
  };

  const response = await _post(`${baseURL}getstopsell`, body);
  return response;
};

export const addcommentcard = async (dispatch, data) => {
  const body = {
    username: VIPER_CONST.alwaysOnUsername,
    sessionid: VIPER_CONST.alwaysOnSessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}addcommentcard`, body);
  return response;
};

export const getspecialoccassions = async (dispatch) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: {},
  };

  try {
    const response = await _post(`${baseURL}getspecialoccassions`, body);
    if(response)
    dispatch(specialOccasionsFetchSuccess(response));
  } catch (err) {
    dispatch(specialOccasionsFetchFailure());
  }
};

export const getbillingdetails = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };
  const response = await _post(`${baseURL}getbillingdetails`, body);
  return response;
};

export const getOrders = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}getorders`, body);
  return response;
};

export const getStandbybookings = async (request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };

  const response = await _post(`${baseURL}getstandbybookings`, body);
  return response;
};

export const getCommissionPeriods = async (request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };

  const response = await _post(`${baseURL}getcommissionperiods`, body);
  return response;
};

export const getCommissionReports = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}getcommissiondetails`, body);
  return response;
};

export const getUserList = async () => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: {},
  };

  const response = await _post(`${baseURL}getusers`, body);
  return response;
};

export const addApplication = async (dispatch, data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}addapplication`, body);
  return response;
};

export const changePassword = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}changepassword`, body);
  return response;
};

export const logOut = async () => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: {},
  };

  const response = await _post(`${baseURL}logout`, body);
  return response;
};

export const cancelCartItem = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}cancelcartitem`, body);
  return response;
};

export const resetPassword = async (data, resetPassword) => {
  // const  = store.getState();
  let userName;
  let sessionId;

  if (resetPassword === "Y") {
    const loginDetail = getLoginDetails();
    userName = loginDetail?.username;
    sessionId = loginDetail?.sessionid;
  } else {
    userName = VIPER_CONST.alwaysOnUsername;
    sessionId = VIPER_CONST.alwaysOnSessionid;
  }

  const body = {
    username: userName,
    sessionid: sessionId,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}resetpassword`, body);
  return response;
};

export const addEditUser = async (data, editMode = "N", useLoggedInUserDetails = "Y") => {
  // const  = store.getState();
  let username;
  let sessionid;

  if (useLoggedInUserDetails === "Y") {
    const loginDetail = getLoginDetails();
    username = loginDetail?.username;
    sessionid = loginDetail?.sessionid;
  } else {
    username = VIPER_CONST.alwaysOnUsername;
    sessionid = VIPER_CONST.alwaysOnSessionid;
  }

  const body = {
    username: username,
    sessionid: sessionid,
    failstatus: 0,
    request: data,
  };

  let apiName = editMode === "Y" ? "edituser" : "adduser";

  const response = await _post(`${baseURL}` + apiName, body);
  return response;
};

export const addUpdateLinkQueue = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}addupdatelinkqueue`, body);
  return response;
};

export const processMagnaTransaction = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  // if (data.action === "REDEEMPOINTS") {
  //   response = {
  //     "username": "esite3@viponline",
  //     "sessionid": "00067459256813685552917838",
  //     "status": 0,
  //     "statusMessage": "Success",
  //     "severity": 0,
  //     "data": {
  //       "orderid": "0000031",
  //       "points": 3765,
  //       "value": 48.27
  //     }
  //   }
  // } else {
  const response = await _post(`${baseURL}processmagnatransaction`, body);
  // }
  return response;
};

export const addStandBy = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };
  const response = await _post(`${baseURL}addstandby`, body);
  return response;
};


export const getAffiliates = async (request, dispatch) => {
  let body = {
    username: VIPER_CONST.alwaysOnUsername,
    sessionid: VIPER_CONST.alwaysOnSessionid,
    request: request,
  };

  let response;
  response = await _post(`${baseURL}getaffiliates`, body);
  return response;
};

export const initAffiliateLog = async (request) => {
  let body = {
    username: VIPER_CONST.alwaysOnUsername,
    sessionid: VIPER_CONST.alwaysOnSessionid,
    request: request,
  };

  const response = await _post(`${baseURL}initaffiliatelog`, body);
  return response;
};

export const getBillingExtract = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}getbillingextract`, body);
  return response;
};

export const setContact = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}setcontact`, body);
  return response;
};

export const getOrderId = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };
  const response = await _post(`${baseURL}getorderid`, body);
  return response;
};

export const processCard = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };
  const response = await _post(`${baseURL}processcard`, body);
  return response;
}

export const getPaymentGateway = async (dispatch) => {
  let body = {
    username: VIPER_CONST.alwaysOnUsername,
    sessionid: VIPER_CONST.alwaysOnSessionid,
    request: {},
  };
  try {
    const response = await _post(`${baseURL}getpaymentgateway`, body);

    dispatch(paymentGatewayFetchSuccess(response.data));
  } catch (err) {
    dispatch(paymentGatewayFetchFailure());
  }
};

export const getMemberCardValues = async (dispatch) => {
  try {
    // const  = store.getState();
    const loginDetail = getLoginDetails();
    const body = {
      username: loginDetail?.username,
      sessionid: loginDetail?.sessionid,
      failstatus: 0,
      request: {},
    };
    const response = await _post(`${baseURL}getmembercardvalues`, body);

    dispatch(memberCardValuesFetchSuccess(response.data));
    return response;
  } catch (err) {
    dispatch(memberCardValuesFetchFailure());
  }
};

export const getMemberCards = async (data = {}, dispatch) => {
  try {
    // const  = store.getState();
    const loginDetail = getLoginDetails();

    const body = {
      username: loginDetail?.username,
      sessionid: loginDetail?.sessionid,
      failstatus: 0,
      request: data,
    };

    const response = await _post(`${baseURL}getmembercards`, body);

    dispatch && dispatch(memberCardsFetchSuccess(response.data));
    return response;
  } catch (err) {
    dispatch && dispatch(memberCardsFetchFailure());
  }
};

export const verifyMemberCards = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  try {
    const response = await _post(`${baseURL}verifymembercards`, body);

    // dispatch(memberCardsFetchSuccess(response.data));
    return response;
  } catch (err) {
    // dispatch(memberCardsFetchFailure());
  }
};

export const addConfirmationLog = async (request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };

  const response = await _post(`${baseURL}addconfirmationlog`, body);
  return response;
};

export const addMemberCard = async (request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };
  const response = await _post(`${baseURL}addmembercard`, body);
  return response;
};

export const getmappedmembercards = async (request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };

  const response = await _post(`${baseURL}getmappedmembercards`, body);
  return response;
};

export const mapmembercard = async (data, memberid) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}mapmembercard`, body);
  return response;
};

export const topUpMemberCard = async (request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };

  const response = await _post(`${baseURL}topupmembercard`, body);
  return response;
};

export const processMemberCard = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}processmembercard`, body);
  return response;
};

export const addBillPayment = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };
  const response = await _post(`${baseURL}addbillpayment`, body);
  return response;
};

export const addBillPaymentLog = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };
  const response = await _post(`${baseURL}addbillpaymentlog`, body);
  return response;
}

export const updateCardProfile = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}updatecardprofile`, body);
  return response;
};

export const getMemberCardTransactions = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}getmembercardtransactions`, body);
  return response;
};

export const getMemberCardImage = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}getmembercardimage`, body);
  return response;
};

export const changeMemberCardPin = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}changepin`, body);
  return response;
};

export const resetMemberCardPin = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}resetpin`, body);
  return response;
};

export const sendUnlockNotification = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}sendunlocknotification`, body);
  return response;
};

export const getBalance = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}getbalance`, body);
  return response;
};

export const manageFunds = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}managefunds`, body);
  return response;
};

export const getBookingKeyOrders = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,

  };

  const response = await _post(`${baseURL}getbookingkeyorders`, body);
  return response;
};

export const addTopupAccountLog = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}addtopupaccountlog`, body);
  return response;
}

export const topupAccount = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}topupaccount`, body);
  return response;
}

export const getCreditBooking = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}getcreditbooking`, body);
  return response;
};

export const addMemberCardLog = async (request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };
  const response = await _post(`${baseURL}addmembercardlog`, body);
  return response;
};

export const addTopupMemberCardLog = async (request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };
  const response = await _post(`${baseURL}addtopupmembercardlog`, body);
  return response;
};

export const authenticateStandby = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}authenticatestandby`, body);
  return response;
};

export const addCreditBookingPaymentLog = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}addcreditbookingpaymentlog`, body);
  return response;
}

export const confirmCreditBooking = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}confirmcreditbooking`, body);
  return response;
}

export const addConvertStandByLog = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}addconvertstandbylog`, body);
  return response;
}

export const convertStandBy = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}convertstandby`, body);
  return response;
}

export const updateBooking = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}updatebooking`, body);
  return response;
}

export const addGiftLog = async (request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };

  const response = await _post(`${baseURL}addgiftlog`, body);
  return response;
};

export const addGift = async (request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };

  const response = await _post(`${baseURL}addgift`, body);
  return response;
};

export const getDistributorRates = async () => {
  // const  = store.getState(); 
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: {},
  };

  const response = await _post(`${baseURL}getdistributorrates`, body);
  return response;
};

export const authenticateGift = async (data) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: data,
  };

  const response = await _post(`${baseURL}authenticategift`, body);
  return response;
};

export const redeemGift = async (request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };

  const response = await _post(`${baseURL}redeemgift`, body);
  return response;
};

export const addmanualbooking = async (request) => {
  // const  = store.getState(); 
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };

  const response = await _post(`${baseURL}addmanualbooking`, body);
  return response;
};

export const getDistributorList = async (request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();

  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };

  const response = await _post(`${baseURL}getdistributorlist`, body);
  return response;
};

export const getDistributorDetails = async (request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();

  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };

  const response = await _post(`${baseURL}getdistributordetails`, body);
  return response;
};

export const verifyReferenceNumber = async (request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };

  const response = await _post(`${baseURL}verifyreferencenumber`, body);
  return response;
};

export const getBookings = async (request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request
  };

  const response = await _post(`${baseURL}getbookings`, body);
  return response;
};

export const getBookingDetails = async (request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };

  const response = await _post(`${baseURL}getbookingdetails`, body);
  return response;
};

export const updateContactDetail = async (request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };
  const response = await _post(`${baseURL}updatecontactdetails`, body);
  return response;
};

export const getDistributorBooking = async (request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };
  const response = await _post(`${baseURL}getdistributorbookings`, body);
  return response;
};

export const setMarketId = async (request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();

  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };

  const response = await _post(`${baseURL}setmarketid`, body);
  return response;
};

export const addBookingLinkQueue = async (request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };
  const response = await _post(`${baseURL}addbookinglinkqueue`, body);
  return response;
};

export const linkBooking = async (request) => {
  const body = {
    username: VIPER_CONST.alwaysOnUsername,
    sessionid: VIPER_CONST.alwaysOnSessionid,
    failstatus: 0,
    request: request,
  };
  const response = await _post(`${baseURL}linkbooking`, body);
  return response;
};

export const getOrderDetails = async (request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };
  const response = await _post(`${baseURL}getorderdetails`, body);
  return response;
};

export const clearVIPERCart = async () => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: {},
  };

  const response = await _post(`${baseURL}clearcart`, body);
  return response;
};

export const unlockMembercard = async (request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };
  const response = await _post(`${baseURL}unlockmembercard`, body);
  return response;
};

export const altLogin = async (request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };
  const response = await _post(`${baseURL}altlogin`, body);
  return response;
};

export const confirmApplication = async (loginDetail, request) => {
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };
  const response = await _post(`${baseURL}confirmapplication`, body);
  return response;
};

export const getAuthorizedlogin = async (request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };
  const response = await _post(`${baseURL}getauthorizedlogin`, body);
  return response;
};

export const addGroupBooking = async (request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };
  const response = await _post(`${baseURL}addgroupbooking`, body);
  return response;
};

export const getFlightsCache = async () => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: {},
  };
  const response = await _post(`${baseURL}getflights`, body);
  return response;
};

export const processdiscountvoucher = async (request) => {
  // const  = store.getState();
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request,
  };
  const response = await _post(`${baseURL}processdiscountvoucher`, body);
  return response;
};

export const mergeStandBySession = async (productid, arrivalscheduleid, departurescheduleid, cartitemid, ticketsconfirmed) => {
  const state = store.getState();
  const bookingsessionid = state?.signInRedux?.logindetails?.sessionid;
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: {
      bookingsessionid,
      productid,
      arrivalscheduleid: arrivalscheduleid || 0,
      departurescheduleid: departurescheduleid || 0,
      cartitemid: cartitemid,
      ticketsconfirmed,
    }
  }
  const response = await _post(`${baseURL}mergestandbysession`, body);
  return response;
}

export const altLogin2 = async (dispatch, request, sendBroadcastMessage) => {
  let response = "";
  const body = {
    failstatus: 0,
    request: request
  };
  try {
    response = await _post(`${baseURL}altlogin`, body);
    if (response?.status === 0) {
      const responseData = response?.data;

      const loginDetail = {
        username: responseData.username,
        password: responseData.userdefault.password,
        sessionid: responseData?.sessionid,
      };
      const promotionLoginData = {
        loginkey: request?.loginkey,
        username: loginDetail?.username
      };
      cookies.set("promotionLoginData", promotionLoginData, { maxAge: PROMOTION_KEY_EXPIRY_TIME * 86400, path: "/" })
      // let affiliateData = state?.affiliatesRedux?.affiliateDetails;
      // if (affiliateData) {
      //   affiliateData.expiresAt = new Date(affiliateData.expiresAt)
      //   cookies.set("affiliateDataBackup",affiliateData,{ expires: affiliateData.expiresAt, path: "/" })
      //   cookies.remove("affiliateData", { path: "/" });
      // }
      // ******** save login detail into local storage *********//
      encryptData(loginDetail);
      dispatch(loginInServiceSuccess({ ...responseData, promotion: true }));
      sessionChangeBroadcast(sendBroadcastMessage);
    } else {
      dispatch(logInServiceError(response.statusMessage))
    }
  }
  catch (err) {
    dispatch(logInServiceError(i18n.t('networkErrorMessage')));
  }
  return response;
};

export const resendConfirmation = async (request) => {
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request
  };
  const response = await _post(`${baseURL}resendconfirmation`, body);
  return response;
};

export const extendSession = async () => {
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: {},
  };
  const response = await _post(`${baseURL}extendsession`, body);
  return response;
};

export const getProductCost = async (request) => {
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username,
    sessionid: loginDetail?.sessionid,
    failstatus: 0,
    request: request
  };
  const response = await _post(`${baseURL}getproductcost`, body);
  return response;
};

export const getJamaicanTime = async (save) => {
  const response = await _get(
    "https://worldtimeapi.org/api/timezone/America/Jamaica"
  );

  if (response) {
    if (save) {
      store.dispatch(setJamaicanTime(response.datetime));
    }
    return response;
  }
}
export const addLog = async (data) => {
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username??VIPER_CONST.alwaysOnUsername,
    sessionid: loginDetail?.sessionid??VIPER_CONST.alwaysOnSessionid,
    failstatus: 0,
    request: data,
  };
  const response = await _post(`${logURL}addlog`, body);
  return response;
};

export const sendMail = async (data) => {
  const loginDetail = getLoginDetails();
  const body = {
    username: loginDetail?.username??VIPER_CONST.alwaysOnUsername,
    sessionid: loginDetail?.sessionid??VIPER_CONST.alwaysOnSessionid,
    failstatus: 0,
    request: data,
  };
  const response = await _post(`${logURL}sendmail`, body);
  return response;
};