import { Card, Col, Form, Row, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import Password from "./Password";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";
import { mapmembercard, processMemberCard } from "../../redux/apiCalls";
import md5 from "md5";
import { showError, showSuccess } from "./Swal";
// import { useDispatch } from "react-redux";
// import { useNavigate, useLocation } from "react-router-dom";
import { HandleAPIError, divRef, numericValidation, renderErrorMessages, scrollToDiv, translateConstantText } from "./commonFunctions";
import { MEMBERCARD_CARD_NUMBER_LABEL, MEMBERCARD_CARD_TYPE_LABEL, MEMBERCARD_PIN_MAXLENGTH } from "../../config/commonConstants";
import CommonButton from "./CommonButton";
import { VIPER_Response_Codes } from "../../config/viper-response-codes";
import { useFormik } from "formik";
import { object, string } from "yup";
import ErrorMessages from "./ErrorMessages";

export default function ResetMemberPinModal(props) {
  const [memberCardNumber, setMemberCardNumber] = useState();
  const [memberName, setMemberName] = useState();
  const [memberCardType, setMemberCardType] = useState();
  const { t } = useTranslation(["common", "memberandcardinformation"]);


  const [loading, setLoading] = useState(false);

  const validationSchema = object().shape({
    newPin:string().trim().required(t("memberandcardinformation:errors.newPin.required")).min(MEMBERCARD_PIN_MAXLENGTH).max(MEMBERCARD_PIN_MAXLENGTH),
  })
  const formik = useFormik({
    initialValues:{
      newPin:""
    },
    validationSchema,
    onSubmit : ()=>{handleSubmit()}
  })
  const handleSubmit = async (event) => {
    // event.preventDefault();
    const data = {
      "action": "UPDATE",
      "memberid": memberCardType.value + memberCardNumber,
      "membername": memberName,
      "pin": md5(formik.values.newPin),
    };
    const arrGetValueResponse = {
      action: "VALIDATE",
      memberid: memberCardType.value + memberCardNumber,
      pin: md5(formik.values.newPin),
      allowexpired: "N"
    }
    try {
      setLoading(true);
      const response = await processMemberCard(arrGetValueResponse);
      if (response?.status === 0) {
        const response = await mapmembercard(data);
        if (response?.status === 0) {
          await showSuccess(response.statusMessage, t('memberandcardinformation:pinUpdateMessage'))
          props?.setConvertMd5(true);
          props?.setVipmembershipcardPin(formik.values.newPin);
          props?.onSuccess(formik.values.newPin)
          props?.setResetMemberPinStatus(true);
          props?.cancelModal();
          props?.setStatus(true);
        }
        else if (response?.status === VIPER_Response_Codes.MAPPED_MEMBERCARD_INVALID_PIN) {
          props?.setIsVisibleChangeMemberPin();
        } else {
          showError(t("error"), response?.statusMessage);
          props.setResetMemberPinStatus(false);
          props.setStatus(false)
        }
      }
      else {
        showError(t("error"), response?.statusMessage);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setMemberCardNumber(props.vipmembershipcardObject?.cardNumber);
    setMemberCardType(props.vipmembershipcardObject?.memberCards);
    setMemberName(props.vipmembershipcardObject?.cardHolderName);
  }, [props.vipmembershipcardObject]);

  return (
    <>
      <Modal
        show={props.visible}
        onHide={(status) => {
          props.setResetMemberPinStatus(false);
          props.cancelModal(status);
        }}
        size="md"
        style={{ zIndex: "9998" }}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton><h3 className="fw-bold modalHeading">{t("memberandcardinformation:invalidSavedPin")}</h3></Modal.Header>
        <Loader loading={loading} message={t("changepassword:Processing")} />
          <Modal.Body>
            <Form onSubmit={(e)=> scrollToDiv(e,formik)}  ref={divRef}>
              <Row className="d-flex justify-content-center mb-3">
                <Col md={10}>
                  <Card className="bg-light bg-opacity-10 rounded-3 ">
                    <Card.Body>
                    <Row className="d-flex justify-content-start">
                      <Col md={12}>
                        <h6 className="field">{t('fillAllFields')}</h6>
                      </Col>
                    </Row>
                    <ErrorMessages formik={formik} />
                      <Row className="d-flex justify-content-center">
                        <Col md={12} className="mb-1">
                          <Form.Label className="d-flex">
                          {t('memberandcardinformation:cardType',{membershipType: translateConstantText("MEMBERCARD_CARD_TYPE_LABEL")})}:&nbsp;<b>{memberCardType?.label}</b>
                          </Form.Label>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-center">
                        <Col md={12} className="mb-1" >
                          <Form.Label className="d-flex" htmlFor="cardNumber">
                          {t('memberandcardinformation:cardNumber',{membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL")})}:&nbsp;<b>{ memberCardNumber}</b>
                          </Form.Label>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-center">
                        <Col md={12} className="mb-1" >
                          <Form.Label className="d-flex" htmlFor="cardNumber">
                            {t('memberandcardinformation:memberName')}:&nbsp;<b>{memberName}</b>
                          </Form.Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12" className="mb-3" >
                          <Form.Label htmlFor="validationCustomNewPassword">
                            {t("memberandcardinformation:pin")}<span className="field">*</span>
                          </Form.Label>
                          <Password
                            id="validationCustomNewPassword"
                            placeholder={t("memberandcardinformation:pin")}
                            name="newPin"
                            value={formik.values.newPin}
                            maxLength={MEMBERCARD_PIN_MAXLENGTH}
                            onChange={(e) =>
                              formik.setFieldValue("newPin",numericValidation(e.target.value))
                            }
                            onBlur={formik.handleBlur}
                            className={formik.errors.newPin && formik.touched.newPin ? "error rounded":"rounded"}
                          />
                          
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className="d-flex justify-content-center">
                          <CommonButton
                            classname="gradient-btn mx-1"
                            title={t('memberandcardinformation:changePin')}
                            type="submit"
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

            </Form>
          </Modal.Body>
          {/* <Modal.Footer /> */}
      </Modal>
    </>
  );
}
