import { Card, Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import CommonButton from "../components/commonComponents/CommonButton";
import { useEffect, useState } from "react";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from 'react-i18next';
import { getbillingdetails, getBillingExtract } from "../redux/apiCalls";
import Loader from "../components/commonComponents/Loader";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logindetails as LD } from "../redux/signInRedux";
import { useSelector } from "react-redux";
import { createColumnHelper } from "@tanstack/react-table";
import { Table } from "../components/commonComponents/Table";
import { checkForLoggedInUserSessionExist, getDistributorSubDistributorProfile, myDateFormat, omit } from "../components/commonComponents/commonFunctions";
import { BILLING_URL, DEFAULT_CURRENCYCODE } from "../config/commonConstants";
import { HandleAPIError } from "../components/commonComponents/commonFunctions";
import { showError } from "../components/commonComponents/Swal";
import { CSVLink } from "react-csv";

function BillingReports() {
  const { t } = useTranslation(['common', 'billingreport']);
  const [billdetails, setBillingDetails] = useState([]);
  const navigate = useNavigate();
  const [billdetailsexport, setBillingDetailsExport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sorting, setSorting] = useState([])
  const loginDetails = useSelector(LD)
  const distributorProfile = getDistributorSubDistributorProfile();
  const distributorid = distributorProfile?.distributorid;  
  const currencyCode = distributorProfile?.currencycode ? distributorProfile.currencycode : DEFAULT_CURRENCYCODE;

  const billingDetails = async () => {
    let data = { "distributorid": distributorid };
    setLoading(true)
    try {
      const response = await getbillingdetails(data)
      if (response?.status === 0) {
        setBillingDetails(response.data.billdetails);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    checkForLoggedInUserSessionExist() !== "" && billingDetails();
  }, [loginDetails]);

  const downloadFile = async (sequenceId, extractType) => {
    let data = {
      "extractid": sequenceId,
      "extracttype": extractType,
      "streamdata": "N",
    };
    setLoading(true);

    try {
      const response = await getBillingExtract(data);
      if (response?.status === 0) {
        var filename = response.data.filename;
        if (filename.trim() !== "") {
          filename = BILLING_URL + response.data.filename;
          window.open(filename, "_blank", "noopener");
        }
      } else {
        showError(t("error"), response?.statusMessage);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  }

  const exportbilldetails = () => {
    const tmp = (billdetails.map((l) => {
      return omit(l, ['sequenceid', 'pdfexists'])
    }));

    setBillingDetailsExport(tmp);
    return billdetailsexport;
  }

  //   getBillingExtract(data)
  //     .then((response) => {
  //       if(response.status === 0){
  //         var filename = response.data.filename;
  //         if(filename.trim() !== "") {
  //           filename = BILLING_URL + response.data.filename;
  //           window.open(filename, '_blank', 'noopener');
  //         }
  //       }
  //     })
  //     .finally(() => setLoading(false));
  // }

  const columnHelper = createColumnHelper()

  const columns = [
    columnHelper.accessor("startdate", {
      cell: info => myDateFormat(info.getValue()),
      header: t('billingreport:startDate')
    }),
    columnHelper.accessor("enddate", {
      cell: info => myDateFormat(info.getValue()),
      header: t('billingreport:endDate')
    }),
    columnHelper.accessor("billdate", {
      cell: info => myDateFormat(info.getValue()),
      header: t('billingreport:billDate')
    }),
    columnHelper.accessor("duedate", {
      cell: info => myDateFormat(info.getValue()),
      header: t('billingreport:dueDate')
    }),
    columnHelper.accessor("openingbalance", {
      cell: info => <div style={{ textAlign: "right" }}>{info.getValue().toFixed(2)}</div>,
      header: t('billingreport:openingBalance')
    }),
    columnHelper.accessor("paymentamount", {
      cell: info => <div style={{ textAlign: "right" }}>{info.getValue().toFixed(2)}</div>,
      header: t('billingreport:payments'),
      className: "right"
    }),
    columnHelper.accessor("adjustmentamount", {
      cell: info => <div style={{ textAlign: "right" }}>{info.getValue().toFixed(2)}</div>,
      header: t('billingreport:adjustments')
    }),
    columnHelper.accessor("broughtforwardamount", {
      cell: info => <div style={{ textAlign: "right" }}>{info.getValue().toFixed(2)}</div>,
      header: t('billingreport:balance')
    }),
    columnHelper.accessor("billamount", {
      cell: info => <div style={{ textAlign: "right" }}>{info.getValue().toFixed(2)}</div>,
      header: t('billingreport:currentCharges')
    }),
    columnHelper.accessor("outstandingamount", {
      cell: info => <div style={{ textAlign: "right" }}>{info.getValue().toFixed(2)}</div>,
      header: t('billingreport:totalDue')
    }),
    columnHelper.accessor("pdfexists", {
      cell: info => info.getValue() === "Y" ? (
        <div style={{ textAlign: "center" }}>
          <FontAwesomeIcon
            className="ms/1 icon-color pointer"
            icon={faDownload}
            size="lg"
            onClick={() => downloadFile(info.cell.row.original.sequenceid, "pdf")}
          />
        </div>
      ) : "",
      header: t('billingreport:download'),
      enableSorting: false
    }),
    columnHelper.accessor("pdfexists", {
      id: "downloadexcel",
      cell: info => info.getValue() === "Y" ? (
        <div style={{ textAlign: "center" }}>
          <FontAwesomeIcon
            className="ms/1 icon-color pointer"
            icon={faDownload}
            size="lg"
            onClick={() => downloadFile(info.cell.row.original.sequenceid, "csv")}
          />
        </div>
      ) : "",
      header: t('billingreport:downloadExcel'),
      enableSorting: false
    }),
  ]

  const csv_headers = [
    { key: "startdate", label: t('billingreport:startDate') },
    { key: "enddate", label: t('billingreport:endDate') },
    { key: "billdate", label: t('billingreport:billDate') },
    { key: "duedate", label: t('billingreport:dueDate') },
    { key: "openingbalance", label: t('billingreport:openingBalance') },
    { key: "paymentamount", label: t('billingreport:payments') },
    { key: "adjustmentamount", label: t('billingreport:adjustments') },
    { key: "broughtforwardamount", label: t('billingreport:balance') },
    { key: "billamount", label: t('billingreport:currentCharges') },
    { key: "outstandingamount", label: t('billingreport:totalDue') },
  ];

  return (
    <>
      <section>
        <Container className="my-4">
          <Loader
            loading={loading}
            message={t("billingreport:loaderMessage")}
          />
          <div className="my-3">
            <h3 className="d-flex justify-content-center heading heading">
              <span className="heading">
                {t("billingreport:billingReport")}
              </span>
            </h3>
          </div>

          <div className="my-3 d-flex justify-content-end">
            {/* <Link to="/" className=" mx-1 text-decoration-none">
              <CommonButton classname="back" title={t("back")} />
            </Link> */}
            <ExitButton />
          </div>

          <Card className="">
            <Card.Body>
              <Row>
                <Col md={12}><p className="field">{t("billingreport:amountCurrencyDeclaration", {currencyCode:currencyCode})}</p></Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Table
                    columns={columns}
                    data={billdetails}
                    noDataMessage={t("noDataAvailable")}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {billdetails.length > 0 && (
            <Row className="my-3 d-flex justify-content-end">
              <Col md={"auto"} className="d-flex justify-content-end">
                <CommonButton
                  type="submit"
                  classname="gradient-btn me-1"
                  title={t("makePayment")}
                  disabled={billdetails.length === 0}
                  onclick={() =>
                    navigate("/billing-report/bill-payment", {
                      state: { billdetails: billdetails[0] },
                    })
                  }
                />

                <CSVLink
                  filename="billing_details.csv"
                  asyncOnClick={true}
                  onClick={exportbilldetails}
                  headers={csv_headers}
                  data={billdetailsexport}
                >
                  <CommonButton
                    type="button"
                    classname="gradient-btn"
                    title={t("billingreport:export")}
                  />
                </CSVLink>
              </Col>
            </Row>
          )}
        </Container>
      </section>
    </>
  );
}

export default BillingReports;
