import { Card, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import CommonButton from "../components/commonComponents/CommonButton";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function NetworkError() {
  const { t } = useTranslation(['networkerror']);

  return (
    <>
      <section>
        <Container className="my-4">
          <h3 className="my-3 d-flex justify-content-center text-center">
            <span className="heading">{t('networkerror:serverError')}</span>
          </h3>

          <Row>
            <Col md={12} className="mx-auto xl">
              <Card className="text-center mt-2">
                <Card.Body className="text-center">
                  <Row>
                    <Col md={12} className="d-flex justify-content-center">
                      <h5 className="lh-base">
                        <FontAwesomeIcon
                          icon={faCircleXmark}
                          size="3x"
                          className="mb-3"
                          color="red"
                        />
                        <br />
                        {t('networkerror:failedToConnect')}
                      </h5>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="m-4 d-flex justify-content-center">
                    <Link to="/" className="me-1 text-decoration-none">
                      <CommonButton
                        type="submit"
                        size="lg"
                        classname="gradient-btn mx-2"
                        title={t('goToHomePage')}
                      />
                    </Link>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>    
    </>
  );
}
export default NetworkError;
