import { Card, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import CommonButton from "../components/commonComponents/CommonButton";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createColumnHelper } from "@tanstack/react-table";
import { Table } from "../components/commonComponents/Table";
import React, { useEffect, useState } from "react";
import { getmappedmembercards, mapmembercard } from "../redux/apiCalls";
import { faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExitButton from "../components/commonComponents/ExitButton";
import { myDateFormat, HandleAPIError, isObjectEmpty, checkForLoggedInUserSessionExist, translateConstantText } from "../components/commonComponents/commonFunctions";
import Loader from "../components/commonComponents/Loader";
import { useSelector } from "react-redux/es";
import { logindetails as LD } from "../redux/signInRedux";
import { MEMBERCARD_CARD_NUMBER_LABEL, MEMBERCARD_CARD_TYPE_LABEL } from "../config/commonConstants";
import { showConfirm } from "../components/commonComponents/Swal";


export default function MappedMemberCardList() {
  const { t } = useTranslation(["common", "payment", "memberandcardinformation"]);

  const [linkCardData, setLinkCardData] = useState([]);
  const [loading, setLoading] = useState(false);

  const loginDetails = useSelector(LD);

  const tooltip = (tooltipText) => (
    <Tooltip id="tooltip-cart" className="tooltip" style={{ fontSize: "15px" }}>
      {t(`${tooltipText}`)}
    </Tooltip>
  );

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("cardname", {
      cell: (info) => (
        <div style={{ textAlign: "left" }}>{info.getValue()}</div>
      ),
      header: t('memberandcardinformation:cardType',{membershipType: translateConstantText("MEMBERCARD_CARD_TYPE_LABEL")}),
    }),
    columnHelper.accessor("memberid", {
      cell: ({ getValue }) => (
        <div style={{ textAlign: "left" }}>{getValue().match(/\d+/)}</div>
      ),
      header: t('memberandcardinformation:cardNumber',{membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL")}),
      className: "right",
    }),
    columnHelper.accessor("membername", {
      cell: (info) => (
        <div style={{ textAlign: "left" }}>{info.getValue()}</div>
      ),
      header: t("memberandcardinformation:memberName"),
    }),
    columnHelper.accessor("balance", {
      cell: (info) => (
        info.row.original.showvalue === "Y" ?
          (<div style={{ textAlign: "right" }}>{info.getValue().toFixed(2)}</div>) :
          (<div style={{ textAlign: "right" }}>{myDateFormat(info.row.original.expirydate, "yyyyMMdd")}</div>)
      ),
      header: t("memberandcardinformation:balance") + " (" + t('usd') + ") / " + t('expiryDate'),
    }),
    columnHelper.accessor("edit", {
      cell: info => (
        <div style={{ textAlign: "end" }}>
          <Link to="/mapmembercards" state={{ memberData: info.row.original , mode:"Edit" }}>
            <OverlayTrigger placement="bottom" overlay={tooltip("edit")}>
              <FontAwesomeIcon
                className="ms/1 pointer icon-color mx-2"
                icon={faPencil}
                size="lg"
              />
            </OverlayTrigger>
          </Link>
          <OverlayTrigger placement="bottom" overlay={tooltip("delete")}>
            <FontAwesomeIcon
              className="ms/1 pointer icon-color mx-2"
              icon={faTrashCan}
              size="lg"
              onClick={ async () => {
                const confirmed = await showConfirm(t('delete'), t('deleteMessage'))
                if(confirmed.isConfirmed) {
                  deleteLinkCard(info.row.original.memberid)
                }
              }}
            />
          </OverlayTrigger>
        </div>
      ),
      header: "",
      enableSorting: false
    }),
  ];

  const fetchData = async () => {
    try {
      setLoading(true);
      const data = {};
      const response = await getmappedmembercards(data);
      if (response?.status === 0) {
        setLinkCardData([...response?.data]);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  };

  const deleteLinkCard = async (memberid) => {
    const data = {
      action: "DELETE",
      memberid: memberid,
    };

    try {
      setLoading(true);
      await mapmembercard(data, memberid);
      // Remove the deleted card from linkCardData state
      setLinkCardData((prevData) =>
        prevData.filter((card) => card.memberid !== memberid)
      );
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    checkForLoggedInUserSessionExist() !== "" && fetchData();
  }, [loginDetails]);

  return (

    <Container className="my-4">
      <Loader loading={loading} />
      <h3 className="my-3  d-flex justify-content-center heading heading">
        <span className="heading">{t('memberandcardinformation:linkedMemberHeading')}</span>
      </h3>
      <Row className="my-3 d-flex justify-content-around align-content-left">
        <Col md={6}></Col>
        <Col md={6} className="d-flex justify-content-end">
          <ExitButton/>
        </Col>
      </Row>

      <Card className="my-3">
        <Row className="d-flex justify-content-center">
          <Col md={3} sm={12}>
          </Col>
        </Row>

        <Row className="p-3">
          <Col className="d-flex justify-content-start" md={6}>
            <Link to="/mapmembercards">
              <CommonButton
                classname="gradient-btn"
                title={t("memberandcardinformation:linkMemberCard")}
                size="md"
                type="button"
              />
            </Link>
          </Col>
        </Row>

        <Row className="p-3">
          <Col md={12}>
            <Table
              columns={columns}
              data={linkCardData}
              noDataMessage={t("noDataAvailable")}
            />
          </Col>
        </Row>
      </Card>
    </Container>
  );
}
