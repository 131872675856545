import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchBooking: {},
  error: false,
}

const searchBookingSlice = createSlice({
  name: "searchBookingRedux",
  initialState: initialState,
  reducers: {
    setSearchBooking: (state, action) => {
      state.searchBooking = action.payload;
    },
    clearSearchBooking: state => {
      return initialState;
    },
  },
});

export const { setSearchBooking, clearSearchBooking } = searchBookingSlice.actions;
export const searchBooking = (state) => state.searchBookingRedux?.searchBooking;

export default searchBookingSlice.reducer;