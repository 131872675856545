import React from "react";
import { Card, Container } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function LoadingSkeleton() {
  return (
    <Container className="my-4 ">
      <Card className=" p-4 border rounded shadow ">
        <Skeleton width="30%" />
        <Skeleton width="25%" />
        <hr />
        <Skeleton width="10%" />
        <Skeleton width="32%" />
        <Skeleton width="95%" />
        <Skeleton width="95%" />
        <Skeleton width="10%" />
        <Skeleton width="15%" />
        <hr />
        <Skeleton width="10%" />
        <Skeleton width="32%" />
        <Skeleton width="95%" />
        <Skeleton width="95%" />
        <Skeleton width="10%" />
      </Card>
    </Container>
  );
}

export default LoadingSkeleton;
