import React from 'react'

const SwalHtmlComponent = (props) => {
    return (
        <>
            <div className='text-start'>
                {props?.message}
            </div>
        </>
    )
}

export default SwalHtmlComponent 