import {
  faCalendarDays,
  faClock,
  faHotel,
  faPlaneDeparture,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import {
  PRODUCTID_ARRIVAL,
  PRODUCTID_ARRIVALBUNDLE,
  PRODUCTID_DEPARTURE,
  PRODUCTID_DIGICELBLACKCARD,
  PRODUCTID_PRIORITYPASS, AIRLINE_MAXLENGTH, FLIGHT_NUMBER_MAXLENGTH,
  MAX_FLIGHT_DATE,
  FLIGHT_YEARS
} from "../../config/commonConstants";
import DatepickerComponent from "./DatepickerComponent";
import { t } from "i18next";
import { loungePartnerProductOperation } from "./commonFunctions";
import { getYear } from "date-fns";
import range from "lodash/range";

function ManualBookingFlightDetailsComponent(props) {
  const [date, setDate] = useState(new Date());
  const getDirectionTranslationKey = () => {
    let translationKey = "departure";
    if (props.productid === PRODUCTID_ARRIVALBUNDLE || loungePartnerProductOperation("checkExistenceWithType", props.productid, "ArrivalBundle")) {
      if (props.index === 0) {
        translationKey = "arrival";
      } else {
        translationKey = "departure";
      }
    } else if (props.productid === PRODUCTID_ARRIVAL || loungePartnerProductOperation("checkExistenceWithType", props.productid, "Arrival")) {
      translationKey = "arrival";
    } else { // DEPARTURE, PRIORITY PASS, DIGICEL EXECUTIVE CARD
      translationKey = "departure";
    }

    return translationKey;
  }

  function clearData(location) {
    // eslint-disable-next-line default-case
    switch (location) {
      case "lounge": props.formik.setFieldValue(`bookingDetail.${props.index}.airlineName`, "");
        props.formik.setFieldValue(`bookingDetail.${props.index}.flightNumber`, "");
        props.formik.setFieldValue(`bookingDetail.${props.index}.flightTime`, "");

      case "flightDate": props.formik.setFieldValue(`bookingDetail.${props.index}.airlineName`, "");

      case "airlineName": props.formik.setFieldValue(`bookingDetail.${props.index}.flightNumber`, "");
        props.formik.setFieldValue(`bookingDetail.${props.index}.flightTime`, "");
    }
  }

  return (
    <div>
      <Row className="my-1">
        <Col md={"auto"} sm={12}>
          <h4 className="my-3">{t('enterBookingInformation', { direction: t(getDirectionTranslationKey()) })}
          </h4>
        </Col>
      </Row>
      <Row className="my-2">
        <Col md={5} sm={12}>
          <Form.Label className="d-flex">
            <FontAwesomeIcon
              icon={faHotel}
              size="lg"
              className="me-2 icon-color"
            />
            {t('selectLounge')} <span className="field">*</span>
          </Form.Label>

          <Form.Group className="">
            <Select
              options={props?.clubType}
              onChange={(d) => {
                clearData("lounge");
                props.onChange("lounge", d)
              }}
              name={`bookingDetail.${props.index}.lounge`}
              onBlur={(e) => props.onBlur("lounge", e)}
              value={props.formik?.values?.bookingDetail?.[props.index]?.lounge}
              className={props?.formik?.touched["bookingDetail"]?.[props.index]?.["lounge"] && props?.formik?.errors["bookingDetail"]?.[props.index]?.["lounge"] ? "error rounded-2" : ""}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={"auto"} sm={12}>
          <div>
            <h6 className="my-bg-info">
              {t('journeydetails:bookingInformationMessage')}
            </h6>
          </div>
          {props.productid === PRODUCTID_ARRIVAL && (
            <div>
              <h4 className="">{t('journeydetails:arrivalDetails')}</h4>

              <h6 className="my-bg-info">
                {t('journeydetails:importantNotice')}
              </h6>
            </div>
          )}
          {props.productid === PRODUCTID_DEPARTURE && (
            <h4 className="">{t('journeydetails:departureDetails')}</h4>
          )}
          {(loungePartnerProductOperation("checkExistence", props.productid)) && (
            <h4 className="">{t('mannualBookingTitle', { direction: t(getDirectionTranslationKey()) })}
            </h4>
          )}
          {props.productid === PRODUCTID_ARRIVALBUNDLE && (
            <h4 className=""> {t('heading', { heading: props?.heading })}{ }</h4>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={"auto"} sm={12}>
          <Form.Group className="my-2 ">
            <Form.Label className="d-flex">
              <FontAwesomeIcon
                icon={faCalendarDays}
                size="lg"
                className="my-0 me-1 icon-color"
              />
              {t('flightDate')}
              <span className="field">*</span>
            </Form.Label>
            <DatepickerComponent
              onchange={(val) => {
                clearData("flightDate");
                props.onChange("flightDate", val);
              }}
              minDate={new Date()}
              variableName="flightDate"
              name={`bookingDetail.${props.index}.flightDate`}
              range={range(getYear(new Date()), getYear(new Date()) + FLIGHT_YEARS, 1)}
              maxDate={MAX_FLIGHT_DATE}
              onBlur={(e) => props.onBlur("flightDate", e)}
              value={props.formik?.values?.bookingDetail?.[props.index]?.flightDate}
              className={props?.formik?.touched["bookingDetail"]?.[props.index]?.["flightDate"] && props?.formik?.errors["bookingDetail"]?.[props.index]?.["flightDate"] ? "error rounded-2" : ""}
            />
          </Form.Group>
        </Col>
        <Col md={4} sm={12}>
          <Form.Group className="my-2">
            <Form.Label className="d-flex">
              <FontAwesomeIcon
                icon={faPlaneDeparture}
                size="lg"
                className="me-1 icon-color"
              />
              {t('nameOfAirline')}
              <span className="field">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={t('manualbookingform:airlinePlaceHolder')}
              onChange={(e) => {
                clearData("airlineName");
                props.onChange("airlineName", e.target.value)
              }}
              name={`bookingDetail.${props.index}.airlineName`}
              onBlur={(e) => props.onBlur("airlineName", e)}
              value={props.formik?.values?.bookingDetail?.[props.index]?.airlineName}
              maxLength={AIRLINE_MAXLENGTH}
              className={props?.formik?.touched["bookingDetail"]?.[props.index]?.["airlineName"] && props?.formik?.errors["bookingDetail"]?.[props.index]?.["airlineName"] ? "error rounded-2" : ""}
            >
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={2} sm={12}>
          <Form.Group className="my-1">
            <Form.Label className="d-flex">
              <h6>{t('flightNumber')} <span className="field" >*</span></h6>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={t('manualbookingform:flightNumberPlaceHolder')}
              onChange={(e) => props.onChange("flightNumber", e.target.value.toUpperCase())}
              onBlur={(e) => props.onBlur("flightNumber", e)}
              name={`bookingDetail.${props.index}.flightNumber`}
              value={props.formik?.values?.bookingDetail?.[props.index]?.flightNumber}
              maxLength={FLIGHT_NUMBER_MAXLENGTH}
              className={props?.formik?.touched["bookingDetail"]?.[props.index]?.["flightNumber"] && props?.formik?.errors["bookingDetail"]?.[props.index]?.["flightNumber"] ? "error rounded-2" : ""}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col md={2} sm={12} className="d-flex flex-column">
          <Form.Group className="my-1">
            <Form.Label className="d-flex">
              <FontAwesomeIcon
                icon={faClock}
                size="lg"
                className="mx-2 icon-color"
              />
              <h6>{t('flightTime')} <span className="field">*</span></h6>
            </Form.Label>
            <div className="d-flex flex-row justify-content-center">
              <Form.Control
                type="time"
                onChange={(e) => props.onChange("flightTime", e.target.value)}
                name={`bookingDetail.${props.index}.flightTime`}
                onBlur={(e) => props.onBlur("flightTime", e)}
                value={props.formik?.values?.bookingDetail?.[props.index]?.flightTime}
                className={props?.formik?.touched["bookingDetail"]?.[props.index]?.["flightTime"] && props?.formik?.errors["bookingDetail"]?.[props.index]?.["flightTime"] ? "error rounded-2" : ""}
              ></Form.Control>
            </div>
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
}

export default ManualBookingFlightDetailsComponent;
