import { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChild,
  faCircleInfo,
  faPersonBreastfeeding,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import CommonButton from "../components/commonComponents/CommonButton";
import AdultDetails from "../components/commonComponents/AdultDetails";
import ChildDetails from "../components/commonComponents/ChildDetails";
import ContactDetails from "../components/commonComponents/ContactDetails";
import { EMAIL_POLICY, PRODUCTID_ARRIVAL, PRODUCTID_ARRIVALBUNDLE, PRODUCTID_DEPARTURE, SALUTATION } from "../config/commonConstants";
import GreetSign from "../components/commonComponents/GreetSign";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectedMarket as currentMarket, selectedMarketCurrency } from "../redux/marketRedux";
import { checkLoggedInUserIsLoungePartner, dateFormatForDisplay, divRef, getCurrentMonth, getMarketLoungenames, getMarketLoungenamesWithAirport, getProductId, scrollToDiv, secondaryContactDetailsSchemaToggle, translateArrayKeyValue, isObjectEmpty } from "../components/commonComponents/commonFunctions";
import { arrivaldata, departuredata } from "../redux/flightScheduleRedux";
import { specialOccasions as SO } from "../redux/specialOccasionsRedux";
// import { getspecialoccassions } from "../redux/apiCalls";
import { getspecialoccassions, setContact } from "../redux/apiCalls";
import { showError } from "../components/commonComponents/Swal";
import Loader from "../components/commonComponents/Loader";
import { HandleAPIError } from "../components/commonComponents/commonFunctions";
import { giftRedeemUpdateData } from "../redux/giftCartRedux";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessages from "../components/commonComponents/ErrorMessages";

export default function GiftRedeemCustomerInformation() {
  const [salutation, setSalutation] = useState(translateArrayKeyValue(SALUTATION,"SALUTATION"))
  const [isTitleChangeable,setIsTitleChangeable] = useState(true);
  const initialAdult = {
    title: salutation[0],
    firstName: "",
    lastName: "",
    email: "",
    dob: "",
    errors: {},
  };
  
  const initialChild = {
    title: salutation.filter(x => x.value === "MASTER")[0],
    firstName: "",
    lastName: "",
    dob: null,
    errors: {},
  };
  
  const initialGreetSign = {
    name: "",
    occasion: "",
    occasionDetail: "",
    errors: {},
  };
  
  const initialPrimaryContact = {
    heading: "",
    title: salutation[0],
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    phone: "",
    errors: {},
  };
  
  const initialSecondaryContact = {
    heading: "",
    title: salutation[0],
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    phone: "",
    errors: {},
  };

  const location = useLocation();
  const propsData = location.state; 
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "registration", "greetSign", "giftredeemcustomerinformation"]);
  const selectedMarket = useSelector(currentMarket);
  const marketLoungenames = getMarketLoungenames(selectedMarket?.airports);
  const [adults, setAdults] = useState([]);
  const [childs, setChilds] = useState([]);
  const [infant, setInfant] = useState([]);
  const [primaryContactDetails, setPrimaryContactDetails] = useState(initialPrimaryContact);
  const [secondaryContactDetails, setSecondaryContactDetails] = useState(Yup.object().nullable());
  const [greetingDetail, setGreetingDetail] = useState([initialGreetSign]);
  const heading = [t("primaryContact"), t("secondaryContact")];
  const kidsType = [t("child"), t("infant")];
  const adata = useSelector(arrivaldata);
  const ddata = useSelector(departuredata);
  //const data = [adata, ddata];
  const data = propsData.productid === PRODUCTID_ARRIVALBUNDLE ? [adata, ddata] : propsData.productid === PRODUCTID_ARRIVAL ? [adata] : [ddata];
  const [formValidity, setformValidity] = useState("");
  const [selectedOccasion, setSelectedOccasion] = useState("");
  const specialOccasions = useSelector(SO);
  const [loading, setLoading] = useState(false);
  let redirectURL = "/gift-redeem";
  const marketLoungenameswithairport = getMarketLoungenamesWithAirport(selectedMarket?.airports);
  const currencyCode = useSelector(selectedMarketCurrency);

  const getBookingDate = () => {
    let dates = [];
    data.forEach((item) => {
      dates.push(item.flightDate);
    })
    //const dates = ["2021-02-05", "2021-05-20", "2021-01-02"]
    const min = dates.reduce((acc, date) => { return acc && new Date(acc) < new Date(date) ? acc : date }, '')
    //  const max = dates.reduce((acc, date) => { return acc && new Date(acc) > new Date(date) ? acc : date }, '')
    return min;
  }

  const initial = {
    adults: [],
    childs: [],
    infants: [],
    greetingDetails: [],
    primaryContactDetails: {
      primaryContactDetailsheading: "",
      title: salutation[0],
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
      phone: "",

    },
    secondaryContactDetails: {
      heading: "",
      title: salutation[0],
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
      phone: "",

    },
    priorityPassDetails: {
      cardType: "Priority Pass",
      name: "",
      cardNumber: "",
      cardMonth: getCurrentMonth(),
      cardYear: new Date()?.getFullYear()?.toString(),

    }
  };

  const validationSchema = Yup.object().shape({

    adults: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().trim().required(t("registration:errors.adultFirstName.required")),
        lastName: Yup.string().trim().required(t("registration:errors.adultLastName.required")),
        // email: Yup.string()
        //   .required(t("registration:errors.adultEmail.required"))
        //   .matches(EMAIL_POLICY, t("registration:errors.adultEmail.match"))
        // email: Yup.string().email(t("registration:errors.adultEmail.match"))
        // .required(t("registration:errors.adultEmail.required")) 
        // dob: Yup.string().required(t("feedback:errors.firstName.required")),
      })
    ),
    childs: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().trim().required(t("registration:errors.childFirstName.required")),
        lastName: Yup.string().trim().required(t("registration:errors.childLastName.required")),
        dob: Yup.string().trim().required(t("registration:errors.childDob.required")),
      })),
    infants:
    getProductId(propsData.productid) !== PRODUCTID_DEPARTURE && 
    Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().trim().required(t("registration:errors.infantFirstName.required")),
        lastName: Yup.string().trim().required(t("registration:errors.infantLastName.required")),
        dob: Yup.string().trim().required(t("registration:errors.infantDob.required")),
      })),
    greetingDetails: Yup.array().of(
      Yup.object().shape({
        // name: Yup.string().required(t("feedback:errors.address1.required")),
        //  occasion: Yup.string().required(t("feedback:errors.firstName.required")),
        occasionDetail: Yup.string()
          .test(
            'occasionDetail-validate',
            t("registration:errors.greetingDetailsOccasionDetail.required"),
            occasionDetail => {
              let flag = formik?.values?.greetingDetails[0]?.occasion?.value === "OTHER" && formik?.values?.greetingDetails[0]?.occasionDetail === "" ? true : false;
              if (flag) {
                return false
              }
              return true
            }
          ),
      })),
    primaryContactDetails:
      Yup.object().shape({
        firstName: Yup.string().trim().required(t("registration:errors.primaryContactFirstName.required")),
        lastName: Yup.string().trim().required(t("registration:errors.primaryContactLastName.required")),
        email: Yup.string()
          .trim().required(t("registration:errors.primaryContactEmail.required"))
          .matches(EMAIL_POLICY, t("registration:errors.primaryContactEmail.match")),
        confirmEmail: Yup.string().trim().required(t("registration:errors.primaryContactConfirmEmail.required"))
          .matches(EMAIL_POLICY, t("registration:errors.primaryContactConfirmEmail.match"))
          .test(
            'email-validate',
            t("registration:errors.primaryContactConfirmEmailEqual.equal"),
            confirmEmail => {
              let flag = confirmEmail === formik.values.primaryContactDetails.email ? true : false;
              if (!flag) {
                return false
              }
              return true
            }
          ),
        phone: Yup.string().trim().required(t("registration:errors.primaryContactPhoneNumber.required")),
      }),
    secondaryContactDetails:secondaryContactDetails,
    //The age of the Adult must be 13 years old at the first travel date. Please re-enter under the correct classification. Proof of age will be required at the lounge.
    priorityPassDetails: checkLoggedInUserIsLoungePartner() ?
      Yup.object().shape({
        name: Yup.string().trim().required(t("registration:errors.priorityPassName.required")),
        cardNumber: Yup.string().required(t("registration:errors.priorityPassCardNumber.required")),
        // cardMonth: Yup.string().test(
        //   'test-credit-card-expiration-date',
        //   t("registration:errors.priorityPassExpMonth.required"),
        //   expirationDate => {
        //     var d = new Date(formik.values.priorityPassDetails.cardYear, (expirationDate).toString(1, 2) - 1, 30, 0, 0, 0, 0);
        //     let flag = (dateFormatForDisplay(d, "MM/dd/yyyy") < getBookingDate()) ? true : false;
        //     if (flag) {
        //       return false
        //     }
        //     return true
        //   }
        // ),
        cardYear: Yup.string().test(
          'test-credit-card-expiration-date',
          t("registration:errors.priorityPassExpYear.required"),
          expirationDate => {
            var d = new Date(expirationDate, (formik.values.priorityPassDetails.cardMonth).toString(1, 2) - 1, 30, 0, 0, 0, 0);
            let flag = (new Date(dateFormatForDisplay(d, "MM/dd/yyyy")) > new Date(getBookingDate())) ? true : false;
            if (!flag) {
              return false
            }
            return true
          }
        ),
      }) : "",
  });

  const formik = useFormik({
    initialValues: initial,
    validationSchema,
    onSubmit: () => { handleSubmit(formik.values) },
  });

  useEffect(() => {
    secondaryContactDetailsSchemaToggle(setSecondaryContactDetails,formik?.values?.secondaryContactDetails)
  }, [formik.values.secondaryContactDetails]);

  let redirectProps = {
    cartitemid: propsData.cartitemid,
    productid: propsData.productid,
    arrivalscheduleid: propsData.arrivalscheduleid,
    departurescheduleid: propsData.departurescheduleid,
    adultCount: propsData.adultCount,
    childCount: propsData.childCount,
    infantCount: propsData.infantCount,
    giftBookingInfo: propsData.giftBookingInfo,
    giftcartitemid: propsData?.giftcartitemid
  };

  useEffect(() => {
    let i, j, k, l;
    if (propsData?.currentPassengerInfo !== "") {
      const a = [];
      for (i = 0; i < propsData.adultCount.value; i++)
        a.push({ ...(propsData?.currentPassengerInfo?.adults[i] ? propsData?.currentPassengerInfo?.adults[i] : initialAdult) });
      // setAdults(a);
      formik.setFieldValue("adults", a);
      const b = [];
      for (j = 0; j < propsData.childCount.value; j++)
        b.push({ ...(propsData?.currentPassengerInfo?.childs[j] ? propsData?.currentPassengerInfo?.childs[j] : initialChild) });
      formik.setFieldValue("childs", b);
      const infant = [];
      if (getProductId(propsData?.productid) !== PRODUCTID_DEPARTURE) {
        for (j = 0; j < propsData.infantCount.value; j++) {
          infant.push({ ...(propsData?.currentPassengerInfo?.infant[j] ? propsData?.currentPassengerInfo?.infant[j] : initialChild) });
          formik.setFieldValue("infants", infant);
        }
      }
      formik.setFieldValue("primaryContactDetails", { ...propsData?.currentPassengerInfo?.primaryContactDetails });
      formik.setFieldValue("secondaryContactDetails", { ...propsData?.currentPassengerInfo?.secondaryContactDetails });


      const greet = [];
      greet.push({ ...propsData?.currentPassengerInfo?.greetingDetail[0] });
      // setGreetingDetail(greet);
      formik.setFieldValue("greetingDetails", greet);
      specialOccasions && isObjectEmpty(specialOccasions) && getspecialoccassions(dispatch);
    }
    else {
      const a = [];
      for (i = 0; i < propsData.adultCount.value; i++)
        a.push({ ...initialAdult });
      formik.setFieldValue("adults", a);
      const b = [];
      for (j = 0; j < propsData.childCount.value; j++)
        b.push({ ...initialChild });
      formik.setFieldValue("childs", b);
      const infant = [];
      if (getProductId(propsData?.productid) !== PRODUCTID_DEPARTURE) {
        for (j = 0; j < propsData.infantCount.value; j++) {
          infant.push({ ...initialChild });
          formik.setFieldValue("infants", infant);
        }
      }
      formik.setFieldValue("primaryContactDetails", formik.values.primaryContactDetails);
      formik.setFieldValue("secondaryContactDetails", formik.values.secondaryContactDetails);

      const greet = [];
      for (l = 0; l < 1; l++) greet.push({ ...initialGreetSign });
      // setGreetingDetail(greet);
      formik.setFieldValue("greetingDetails", greet);
      specialOccasions && isObjectEmpty(specialOccasions) && getspecialoccassions(dispatch);
    }
  }, []);


  const handleSubmit = async () => {

    let error = false;
    if (!error) {
      const passengerInfo = {
        "adults": formik.values.adults,
        "childs": formik.values.childs,
        "infant": formik.values.infants,
        "primaryContactDetails": { ...formik.values.primaryContactDetails },
        "secondaryContactDetails": { ...formik.values.secondaryContactDetails },
        "greetingDetail": formik.values.greetingDetails,
        "productid": propsData.productid,
        "cartitemid": propsData.cartitemid,
        "giftBookingInfo": propsData.giftBookingInfo,
        "giftcartitemid": propsData.giftcartitemid

      }
      const data = { passengerInfo: passengerInfo }
      const request = {
        contact: {
          "cartitemid": propsData.cartitemid,
          "title": formik.values.primaryContactDetails?.title?.value,
          "firstname": formik.values.primaryContactDetails?.firstName,
          "lastname": formik.values.primaryContactDetails?.lastName,
          "email": formik.values.primaryContactDetails?.email,
          "phone": formik.values.primaryContactDetails?.phone
        }
      }
      setLoading(true);
      try {
        const response = await setContact(request);
        if (response?.status === 0) {
          dispatch(giftRedeemUpdateData({ ...data }));
          navigate("/gift-redeem-summary", { state: { ...data } });
        } else {
          showError(t("error"), response?.statusMessage);
        }
      } catch (e) {
        HandleAPIError(e);
      } finally {
        setLoading(false);
      }

      // setContact(request)
      //   .then((res) => {
      //     if (res?.status === 0) {
      //       // card detail updated with user information
      //       dispatch(cartUpdateData({ ...data }));
      //       navigate("/bookingsummary", { state: { ...data } });
      //     }
      //     else {
      //       showError(t("error"), res?.statusMessage);
      //     }
      //   })
      //   .finally(() => setLoading(false));

    }
  };

  const getDirectionTranslationKey = (index) => {
    let translationKey = "departure";
    
    if (index === 0) {
      translationKey = "arrival";
    } else {
      translationKey = "departure";
    }
    return translationKey;
  }
  return (
    <>
      <section>
        <Loader loading={loading} />
        <Container className="my-4">
          <h3 className="my-3 d-flex justify-content-center heading">
            <span className="heading">
              {t("giftredeemcustomerinformation:registrationDetails")}
            </span>
          </h3>
          <Row className="my-3 d-flex align-content-left">
            <Col md={12} className="d-flex justify-content-end">
              <Link
                to={redirectURL}
                state={redirectProps} className="">
                <CommonButton
                  type="submit"
                  classname="back mx-1"
                  title={t("back")}
                />
              </Link>
              <ExitButton />
            </Col>
          </Row>
          <div className="my-3">
            <p className="field">
              {t("registration:groundTranspotationMessage") + marketLoungenames}
            </p>
            <p>{t("registration:spaceAvailabilityMessage")} </p>
          </div>
          <Card className="my-3">
            <Card.Body>
              {data?.map((item, index) =>
                  item !== "" && <div key={index}>
                    <Row className="d-flex justify-content-center align-content-center mb-1">
                      <Col md={12} sm={12}>
                        <h4 className="mb-1">
                          <b>
                            {t('summary', { product: t(getDirectionTranslationKey(propsData.productid === PRODUCTID_DEPARTURE ? 1 : index)) })}
                          </b>
                        </h4>
                        <h5 className="mb-1">
                          <b>{t("registration:loungeService")}:</b>{" "}
                          {marketLoungenameswithairport.find(a => a?.value === item?.lounge)?.label}
                        </h5>
                      </Col>
                    </Row>
                    <Row className="d-flex justify-content-center align-content-center mb-1">
                      <Col md={6} sm={12}>
                        <h5 className="mb-1">
                          {/* <b>{t("flightDateTime")}:</b> {new Date(item?.flightDate).toUTCString()} */}
                          {/* <b>{t("flightDateTime")}:</b> {format((new Date(item?.flightDate)), 'MM/dd/yyyy HH:mm:ss')} */}
                          <b>{t("flightDateTime")}:</b> {dateFormatForDisplay(item?.flightDate, "MM/dd/yyyy")} {item?.flightTime_hour}
                        </h5>
                      </Col>
                      <Col md={3} sm={12}>
                        <h5 className="mb-1">
                          <b>{t("airline")}:</b>  {item?.airlineName?.label}
                        </h5>
                      </Col>
                      <Col md={3} sm={12}>
                        <h5 className="mb-1">
                          <b>{t("flightNumber")}:</b>  {item?.flightNumber?.label}
                        </h5>
                      </Col>
                    </Row>
                    {(propsData.productid === PRODUCTID_ARRIVALBUNDLE ? index === 1 : index === 0) &&
                      <>
                        <Row className="d-flex justify-content-center align-content-center mb-1">
                          <Col md={12} sm={12}>
                            <h5 className="mb-1">
                              <b>{t("serviceFor")}: </b>
                              <FontAwesomeIcon
                                icon={faUser}
                                className="mx-1 icon-color"
                                size="sm"
                              />{" "}
                              {t("adults")}: {propsData?.adultCount?.value}
                              <FontAwesomeIcon
                                icon={faChild}
                                size="sm"
                                className="mx-1  icon-color"
                              />{" "}
                              {t("children")}:  {propsData?.childCount?.value}
                              <FontAwesomeIcon
                                icon={faPersonBreastfeeding}
                                size="sm"
                                className="mx-1  icon-color"
                              />{" "}
                              {t("infants")}: {propsData.infantCountNumber}
                            </h5>
                          </Col>
                        </Row>
                        {/* <Row className="d-flex justify-content-center align-content-center mb-1">
                          <Col md={12} sm={12}>
                            <h5 className="mb-1">
                              <b>{t('bookingsummary:totalAmount')}:</b> {currencyCode} {propsData?.retail?.toFixed(2)}
                            </h5>
                          </Col>
                        </Row> */}
                      </>
                    }
                  </div>
                )}
            </Card.Body>
          </Card>
          <Card className="my-3" ref={divRef}>
            <Card.Body>
              <Row className="d-flex justify-content-around align-content-center">
                <Col>
                  <h4>{t("customerInformation")}</h4>
                </Col>
              </Row>
              {propsData.productid === PRODUCTID_DEPARTURE && (
                <h6 className="normalMessageColor">
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    className="me-1 icon-color"
                  />
                  {t("infantNameNotRequiredDeparture")}
                </h6>
              )}
              <h6 className="normalMessageColor">
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  className="me-1 icon-color"
                />
                {t("infantsComplimentary")}
              </h6>
              <h6 className="field">{t("fillAllFields")}</h6>
              <h5 className="">{t("registration:guestNameFillMessage")}</h5>
              <hr className="horizontalBar" />
              <Form onSubmit={(e)=>scrollToDiv(e,formik)}>
                <ErrorMessages formik={formik} />
                {/* adult component  */}
                <div className="mt-4">
                  {formik.values.adults?.map((a, i) => (
                    <AdultDetails
                      key={i}
                      isMandatory={i === 0 ? true : false}
                      title={a.title}
                      formik={formik}
                      index={i}
                      bookingDate={getBookingDate()}
                      onBlur={(key, e ,title) => {
                        formik.handleBlur(e);
                        if(key === "title" && isTitleChangeable){
                          setIsTitleChangeable(false);
                          formik.setFieldValue(`primaryContactDetails.${key}`, title);
                        }
                        if (key === "firstName" && formik.values.primaryContactDetails?.firstName === "") {
                          formik.setFieldValue(`primaryContactDetails.${key}`, e.target.value);
                        }
                        if (key === "lastName" && formik.values.primaryContactDetails?.lastName === "")
                          formik.setFieldValue(`primaryContactDetails.${key}`, e.target.value);
                        if (key === "email" && formik.values.primaryContactDetails?.email === "")
                          formik.setFieldValue(`primaryContactDetails.${key}`, e.target.value);
                        if (propsData.productid !== PRODUCTID_DEPARTURE) {
                          var ele_fname = formik?.values?.adults[0]?.firstName;
                          var ele_lname = formik?.values?.adults[0]?.lastName;
                          if ((parseInt(propsData.adultCount.value) + parseInt(propsData.childCount.value) + parseInt(propsData.infantCount.value)) > 1)
                            formik.setFieldValue(`greetingDetails.${0}.name`, (ele_fname + " " + ele_lname + " " + t('guests')));
                          else
                            formik.setFieldValue(`greetingDetails.${0}.name`, (ele_fname + " " + ele_lname));
                        }
                      }}
                      onChange={(key, val) => {
                        formik.setFieldValue(`adults.${i}.${key}`, val);
                      }}
                    />
                  ))}

                </div>
                {/* child component */}
                <div className="mt-4">
                  {formik.values.childs?.map((a, i) => (
                    <ChildDetails
                      key={i}
                      heading={kidsType[0]}
                      isMandatory={i === 0 ? true : false}
                      title={a.title}
                      type={"child"}
                      formik={formik}
                      index={i}
                      bookingDate={getBookingDate()}
                      onBlur={(key, e,title) => {
                        formik.handleBlur(e);
                        if (formik.values.adults.length === 0) {
                          if(key === "title" && isTitleChangeable){
                            setIsTitleChangeable(false);
                            formik.setFieldValue(`primaryContactDetails.${key}`, title);
                          }
                          if (key === "firstName" && formik.values.primaryContactDetails?.firstName === "") {
                            formik.setFieldValue(`primaryContactDetails.${key}`, e.target.value);
                          }
                          if (key === "lastName" && formik.values.primaryContactDetails?.lastName === "")
                            formik.setFieldValue(`primaryContactDetails.${key}`, e.target.value);
                          if (key === "email" && formik.values.primaryContactDetails?.email === "")
                            formik.setFieldValue(`primaryContactDetails.${key}`, e.target.value);
                          if (propsData.productid !== PRODUCTID_DEPARTURE) {
                            var ele_fname = formik?.values?.childs[0]?.firstName;
                            var ele_lname = formik?.values?.childs[0]?.lastName;
                            if ((parseInt(propsData.adultCount.value) + parseInt(propsData.childCount.value) + parseInt(propsData.infantCount.value)) > 1)
                              formik.setFieldValue(`greetingDetails.${0}.name`, (ele_fname + " " + ele_lname + " " + t('guests')));
                            else
                              formik.setFieldValue(`greetingDetails.${0}.name`, (ele_fname + " " + ele_lname));
                          }
                        }
                      }}
                      onChange={(key, val) => {
                        formik.setFieldValue(`childs.${i}.${key}`, val);
                      }}
                    />
                  ))}
                </div>
                {/*same  child component used for infant  */}
                <div className="mt-4">
                  {propsData.productid !== PRODUCTID_DEPARTURE &&
                    formik.values.infants?.map((a, i) => (
                      <ChildDetails
                        key={i}
                        type={"infant"}
                        heading={kidsType[1]}
                        isMandatory={i === 0 ? true : false}
                        title={a.title}
                        index={i}
                        formik={formik}
                        bookingDate={getBookingDate()}
                        onBlur={(key, e) => {
                          formik.handleBlur(e);
                        }}
                        onChange={(key, val) => {
                          formik.setFieldValue(`infants.${i}.${key}`, val);
                        }}
                      />
                    ))}
                </div>
                {/* primary and secondary contact component  */}
                <div className="mt-4">
                  <ContactDetails
                    key={0}
                    isMandatory={true}
                    type={"primary"}
                    heading={heading[0]}
                    title={formik.values.primaryContactDetails.title}
                    formik={formik}
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                    onChange={(key, val) => {
                      formik.setFieldValue(`primaryContactDetails.${key}`, val);
                    }}
                    onBlur={(key, e) => {
                      formik.handleBlur(e);
                    }}
                  />

                  <ContactDetails
                    // key={1}
                    // isMandatory={false}
                    // heading={heading[1]}
                    // title={secondaryContactDetails.title}
                    // firstName={secondaryContactDetails.firstName}
                    // lastName={secondaryContactDetails.lastName}
                    // email={secondaryContactDetails.email}
                    // confirmEmail={secondaryContactDetails.confirmEmail}
                    // phone={secondaryContactDetails.phone}
                    // errors={secondaryContactDetails.errors}
                    // onChange={(key, val) => {
                    //   secondaryContactDetails[key] = val;
                    //   if (secondaryContactDetails.errors[key]) secondaryContactDetails.errors[key] = "";
                    //   setSecondaryContactDetails({ ...secondaryContactDetails });
                    // }}
                    key={1}
                    isMandatory={false}
                    heading={heading[1]}
                    title={formik.values.secondaryContactDetails.title}
                    type={"secondary"}
                    formik={formik}
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                    onChange={(key, val) => {
                      formik.setFieldValue(`secondaryContactDetails.${key}`, val);
                    }}
                    onBlur={(key, e) => {
                      formik.handleBlur(e);
                    }}
                  />
                </div>
                <div>
                  {(propsData.productid === PRODUCTID_ARRIVAL || propsData.productid === PRODUCTID_ARRIVALBUNDLE) && formik.values.greetingDetails?.map((a, i) => (
                    <GreetSign
                      // key={i}
                      // name={a.name}
                      // selectedOccasion={selectedOccasion}
                      // occasion={a.occasion}
                      // occasionDetail={a.occasionDetail}
                      // errors={a.errors}
                      // onChange={(key, val) => {
                      //   a[key] = val;
                      //   if (key === "occasion") {
                      //     setSelectedOccasion(val?.value);
                      //     a[key] = val;
                      //   }
                      //   if (a.errors[key]) a.errors[key] = "";
                      //   setGreetingDetail([...greetingDetail]);
                      // }}
                      key={i}
                      selectedOccasion={selectedOccasion}
                      formik={formik}
                      index={i}
                      onChange={(key, val) => {
                        formik.setFieldValue(`greetingDetails.${i}.${key}`, val);
                      }}
                      onBlur={formik.handleBlur}
                    />
                  ))}
                </div>

                <Row className="mt-4">
                  <Col md={12} sm={12} className="d-flex justify-content-end">
                    {/* <Link to="/bookingsummary">
                      <CommonButton
                        type="submit"
                        classname=" gradient-btn mb-2 mx-1"
                        title="PROCEED TO CHECKOUT"
                      />
                    </Link> */}
                    <CommonButton
                      type="submit"
                      classname="gradient-btn mb-2 mx-1"
                      title={t("registration:proceedToCheckout")}
                    />
                  </Col>
                </Row>
                {/* <Button type="submit">Submit form</Button> */}
              </Form>
            </Card.Body>
          </Card>
          <Row>
            <Col md={12} sm={12}>
              <Row>
                <Col
                  md={12}
                  sm={12}
                  className="d-flex justify-content-end"
                >
                  <Link
                    to={redirectURL}
                    state={redirectProps}
                    className="mx-1 text-decoration-none"
                  >
                    <CommonButton
                      type="submit"
                      classname="back"
                      title={t("back")}
                    />
                  </Link>
                  <ExitButton />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section >
    </>
  );
}
