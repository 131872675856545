import { useMemo, useEffect } from "react";

import { ORIGIN } from "../config/commonConstants";

function useBroadcastChannel(channelName, swalModal,navigate) {
  const broadcastChannel = useMemo(
    () => new BroadcastChannel(channelName),
    [channelName]
  );

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin === ORIGIN && event.source !== window) {
        swalModal(event.data.content.title, event.data.content.message).then((res) => {
          if (res.isConfirmed || res.dismiss) {
            navigate(event.data.redirectTo);
          }
        });
        console.info("Sender is:", event.data.sender);
      } else {
        console.warn("Received a message from an untrusted source.");
      }
    };

    broadcastChannel.addEventListener("message", handleMessage);
  }, [channelName, broadcastChannel]);

  const sendMessage = (data) => {
    broadcastChannel.postMessage(data);
  };

  return sendMessage;
}

export default useBroadcastChannel;