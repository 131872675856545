import { Card, Col, Container, OverlayTrigger, Row } from "react-bootstrap";
import { useNavigate, Link, useLocation, useOutletContext } from "react-router-dom";
import CommonButton from "../components/commonComponents/CommonButton";
import { useEffect, useState } from "react";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from "react-i18next";
import { getBookings, getBookingDetails, clearVIPERCart, resendConfirmation } from "../redux/apiCalls";
import Loader from "../components/commonComponents/Loader";
import { Table } from "../components/commonComponents/Table";
import { createColumnHelper } from "@tanstack/react-table"; 
import Tooltip from "react-bootstrap/Tooltip";
import {
  faPlaneDeparture,
  faAddressCard,
  faEnvelope,
  faPersonCirclePlus,
  faEnvelopeOpen,
  faMagnifyingGlassPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  checkForLoggedInUserSessionExist,
  getAirportName,
  getClubName,
  getProductName,
  HandleAPIError,
  isObjectEmpty,
} from "../components/commonComponents/commonFunctions";
import { showConfirm, showError, showSuccess } from "../components/commonComponents/Swal";
import { useDispatch, useSelector } from "react-redux";
import { logindetails as LD } from "../redux/signInRedux";
import MarketSelect from "../components/commonComponents/MarketSelect";
import { giftCartData as GCD, giftCartclear } from "../redux/giftCartRedux";
import { cartData as CD, clearCart } from "../redux/cartRedux";
import {
  selectedMarketId as SM,
} from "../redux/marketRedux";
import useToggle from "../hooks/useToggle";
import MyBookingDetailModal from "../components/commonComponents/MyBookingDetailModal";
import { clearSearchBooking, searchBooking as SB } from "../redux/searchBookingRedux";

function MyBookings() {
  const { t } = useTranslation(["common", "mybookings"]);
  const sendBroadcastMessage = useOutletContext()
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const giftCartData = useSelector(GCD);
  const cartData = useSelector(CD);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchBookingDetails = useSelector(SB);

  const [visibleSoldOut, setvisibleViewBooking] = useState(false);
  const hideViewBookingModal = () => setvisibleViewBooking(false);
  const showViewBookingModal = () => setvisibleViewBooking(true);
  const [bookingDetails,setBookingDetails]=useState();
  // const [primaryContact, setPrimaryContact] = useState();
  // const [secondaryContact, setSecondaryContact] = useState();

  const selectedMarketId = useSelector(SM);
  // const request = {marketid:selectedMarketId};
  const loginDetails = useSelector(LD);

  const getBookingApiCall = async (request) => {
    try {
      setLoading(true)
      const response = await getBookings(request);
      if (response?.status === 0) {
        setBookings(response?.data?.orders);
        // setPrimaryContact(response?.data?.primary);
        // setSecondaryContact(response?.data?.secondary);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  }

  const _getBookings = async (marketId) => {
    const request = {
      "marketid": marketId,
      ...(!isObjectEmpty(searchBookingDetails)) && searchBookingDetails.requestData
    }
    getBookingApiCall(request);
  }

  useEffect(() => {
    if(!isObjectEmpty(searchBookingDetails)){
        setBookings(searchBookingDetails.orderDetails);
        setLoading(false);
    } else {
      dispatch(clearSearchBooking());
      const request = {
        "marketid": selectedMarketId,
      }
      checkForLoggedInUserSessionExist() !== "" && getBookingApiCall(request);
    }
  }, [loginDetails]);

  const navigateToUpdateContactDetails = (data) => {
    navigate("/update-contact-details", { state: data });
  }

  const navigateToUpdateFlightDetails = async (data) => {
    const request = {
      "reservationid": data.reservationid,
      "productid": data.productid,
      "getschedule": "N"
    }
    try {
      setLoading(true);
      const response = await getBookingDetails(request);
      const type = "SUBSCRIBER-UPDATE-FLIGHT-DETAILS";
      const allFlightsEditable = data?.allflightseditable;
      if (response?.status === 0)
        navigate("/update-booking", { state: { response, type, allFlightsEditable} });
      else {
        showError(t("error"), response?.statusMessage);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  }

  const navigateToIncreasePax = (data) => {
    const getBookingRequestData = {
      "reservationid": data.reservationid,
      "productid": data.productid,
      "getschedule": "N",
      "allflightseditable": data.allflightseditable
    }
    const cardType = data.cardtype
    navigate("/increase-pax", { state: { getBookingRequestData: getBookingRequestData, cardType } });
  }

  const navigateIfEmptyCart = async () => {
    let shouldNavigate = true;
    if (cartData.length !== 0 || giftCartData.length !== 0) {
      const confirmed = await showConfirm(t("mybookings:cartNotEmptyHeading"), t("mybookings:cartNotEmptyMessage"));
      shouldNavigate = confirmed.isConfirmed;

      if (shouldNavigate) {
        loginDetails?.sessionid && await clearVIPERCart();
        dispatch(clearCart());
        dispatch(giftCartclear());
      }
    }

    return shouldNavigate;
  }

  const columnHelper = createColumnHelper();
  const COLUMNS = [
    columnHelper.accessor("productid", {
      cell: (info) => (
        <div style={{ textAlign: "left" }}>
          {getProductName(info.getValue())}
        </div>
      ),
      header: t("mybookings:product"),
    }),
    columnHelper.accessor("reservationid", {
      cell: (info) => (
        <div style={{ textAlign: "left" }}>{info.getValue()}</div>
      ),
      header: t("mybookings:confirmationnumber"),
    }),
    columnHelper.accessor("airportid", {
      id: "club",
      cell: (info) => (
        <div style={{ textAlign: "left" }}>{getClubName(info.getValue())}</div>
      ),
      header: t("mybookings:lounge"),
    }),
    columnHelper.accessor("airportid", {
      cell: (info) => (
        <div style={{ textAlign: "left" }}>{getAirportName(info.getValue())}</div>
      ),
      header: t("mybookings:airport"),
    }),

    columnHelper.accessor("view", {
      cell: (info) => (
        <div style={{ textAlign: "center" }}>
          {/* <Link to="/update-booking" className="text-decoration-none" state={{
              productid: info.row.original.productid,
              reservationid: info.row.original.reservationid,
            }} > */}
          <OverlayTrigger placement="bottom" overlay={tooltip("viewBookingDetailsTooltip")}>
            <FontAwesomeIcon
              className="ms/1 pointer icon-color mx-2"
              icon={faMagnifyingGlassPlus}
              size="lg"
              onClick={async (e) => {
                  showViewBookingModal()
                  setBookingDetails(info.row.original)
              }}
            />
          </OverlayTrigger>
          <OverlayTrigger placement="bottom" overlay={tooltip("updateFlightDetailsTooltip")}>
            <FontAwesomeIcon
              className="ms/1 pointer icon-color mx-2"
              icon={faPlaneDeparture}
              size="lg"
              onClick={async (e) => {
                if (await navigateIfEmptyCart()) {
                  navigateToUpdateFlightDetails(info.row.original)
                  
                }
              }}
            />
          </OverlayTrigger>
          <OverlayTrigger placement="bottom" overlay={tooltip("updateContactDetailsTooltip")}>
            <FontAwesomeIcon
              className="ms/1 pointer icon-color mx-2"
              icon={faAddressCard}
              size="lg"
              onClick={async () => {
                if (await navigateIfEmptyCart()) {
                  navigateToUpdateContactDetails(info.row.original)
                }
              }}
            />
          </OverlayTrigger>
          <OverlayTrigger placement="bottom" overlay={tooltip("increasePaxTooltip")}>
            {info.row.original?.editpax === "Y" ? (
              <FontAwesomeIcon
                className="ms/1 pointer icon-color mx-2"
                icon={faPersonCirclePlus}
                size="lg"
                onClick={async () => {
                  if (await navigateIfEmptyCart()) {
                    navigateToIncreasePax(info.row.original)
                  }
                }}
              />
            ) : (<FontAwesomeIcon
              className="edit_link_icon"
              icon={faPersonCirclePlus}
              size="lg"
            />)}
          </OverlayTrigger>
          <OverlayTrigger placement="bottom" overlay={tooltip("resendEmail")}>
          {info.row.original?.resendmail === "Y" ? (
              <FontAwesomeIcon
                className="ms/1 pointer icon-color mx-2"
                icon={faEnvelope}
                size="lg"
                onClick={() => {
                  emailSend(info.row.original);
                }}
              />
            ) : (<FontAwesomeIcon
              className="edit_link_icon"
              icon={faEnvelope}
              size="lg"
            />)}
          </OverlayTrigger>
        </div>
      ),
      header: "",
      enableSorting: false,
    }),
  ];

  const emailSend = async (element) => {
    try {
      let request = {
        "productid": element.productid,
        "confirmationnumber": element.reservationid,
      }
      setLoading(true)
      let resend = await resendConfirmation(request);
      if (resend.status === 0) {
        showSuccess(
          t("mybookings:emailSuccess.title"),
          t("mybookings:emailSuccess.message")
        );
      } else {
        showError(
          t("mybookings:resendEmailFailureHeading"),
          resend.statusMessage
        );
      }
    } catch (error) {
      HandleAPIError(error);
    } finally {
      setLoading(false);
    }
  }

  const tooltip = (tooltipText) => (
    <Tooltip id="tooltip-cart" className="tooltip" style={{ fontSize: "15px" }}>
      {t(`mybookings:${tooltipText}`)}
    </Tooltip>
  );

  return (
    <>
      <section>
        <Container className="my-4">
          <Loader loading={loading} message={t("mybookings:loaderMessage")} />
          {visibleSoldOut && (
            <MyBookingDetailModal detail={bookingDetails}
              message={""}
              cancelModal={hideViewBookingModal}
              // primaryContact={primaryContact}
              // secondaryContact={secondaryContact}
              // direction={getDirection(0)}
            />
          )}
          <div className="my-3">
            {/* <section className="d-flex justify-content-center align-content-center mt-3"> */}
            <h3 className="my-3 d-flex justify-content-center heading heading">
              <span className="heading">{!isObjectEmpty(searchBookingDetails) ? t("mybookings:searchBookingHeading") : t("mybookings:myBookings")}</span>
            </h3>
            {!isObjectEmpty(searchBookingDetails) &&
              <h5 className="my-3 d-flex justify-content-center heading heading">
                <span className="">
                  <b>{t("mybookings:searchBookingSubHeading", { searchBy: t(`getupdateflightdetailslink:${searchBookingDetails?.searchBookingHeading}`) })}:</b>
                  {t("mybookings:searchBookingParameter", { number: searchBookingDetails?.searchBookingParam })}
                </span>
                &nbsp;
                {searchBookingDetails?.searchBookingHeading_2 &&
                  <span className="">
                    <b>{t("mybookings:searchBookingSubHeading", { searchBy: t(`getupdateflightdetailslink:${searchBookingDetails?.searchBookingHeading_2}`) })}:</b>
                    {t("mybookings:searchBookingParameter", { number: searchBookingDetails?.searchBookingParam_2 })}
                  </span>
                }
              </h5>
            }
            {/* </section> */}
          </div>

          <div className="my-3 px-1 d-flex justify-content-end">
            {!isObjectEmpty(searchBookingDetails) &&
              <Link to={!isObjectEmpty(searchBookingDetails) ? "/search-booking" : "/"} className=" mx-1 text-decoration-none">
                <CommonButton classname="back" title={t("back")} />
              </Link>
            }
            <ExitButton />
          </div>

          <Card className="">
            <Card.Body>
                <MarketSelect
                  sendBroadcastMessage={sendBroadcastMessage}
                  initialCallbackFunction={() => {
                    setBookings([]);
                  }}
                  callbackFunction={async (marketId) => {
                    _getBookings(marketId);
                  }}
                  zIndex={false}
                  location={location}
                  editLabel={true}
                />
              <Row>
                <Col md={12}>
                  <Table
                    columns={COLUMNS}
                    data={bookings}
                    noDataMessage={t("noDataAvailable")}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
}

export default MyBookings;
