import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Card, Container, Form, InputGroup, Tab, Tabs } from "react-bootstrap";
import { useEffect, useState } from "react";
import CreditCard from "../components/commonComponents/CreditCard";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import CommonButton from "../components/commonComponents/CommonButton";
import { CARD_CVV_LENGTH, EMAIL_MAXLENGTH, EMAIL_POLICY, FIRST_NAME_MAXLENGTH, GIFT_FIRST_NAME_MAXLENGTH, GIFT_LAST_NAME_MAXLENGTH, LAST_NAME_MAXLENGTH, REGEX_ALPHABET } from "../config/commonConstants";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from 'react-i18next';
import { useFormik } from "formik";
import * as Yup from "yup";
import { HandleAPIError, validExpiryOrNot, clearSession, dateFormatForDisplay, divRef, getCurrentMonth, renderErrorMessages, scrollToDiv, getDistributorSubDistributorProfile, generatePaymentMenu, showCardType, alphabetValidation } from "../components/commonComponents/commonFunctions";
import Loader from "../components/commonComponents/Loader";
import { useSelector } from "react-redux";
import { showError } from "../components/commonComponents/Swal";
import { processCreditCardPayment } from "../utils/Fac";
import { addGift } from "../redux/apiCalls";
import { giftCartData as GCD } from "../redux/giftCartRedux";
import { selectedMarketCurrency } from "../redux/marketRedux";
import ErrorMessages from "../components/commonComponents/ErrorMessages";
import { userDefault as UD  } from "../redux/signInRedux";
import LoadingSkeleton from "../components/commonComponents/LoadingSkeleton";
import PartnerReferenceComponent from "../components/commonComponents/PartnerReferenceComponent";
import Select from "react-select";

function GiftPayment() {
  const [validated, setValidated] = useState(false);
  const sendBroadcastMessage = useOutletContext()
  const { t } = useTranslation(['common', 'giftpayment']);
  const currencyCode = useSelector(selectedMarketCurrency);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const distributorProfile = getDistributorSubDistributorProfile();
  const distributorid = distributorProfile?.distributorid;
  const giftCartData = useSelector(GCD);
  const [submitOnClickDisable, setSubmitOnClickDisable] = useState(false);
  const populateConfirmationEmail = () => {
    return (giftCartData.length > 0 ? giftCartData[0]?.recipient_email : "");
  }

  let totalRetailAmount = 0;
  let totalWholesaleAmount = 0;

  giftCartData.map((e) => {
    totalRetailAmount = totalRetailAmount + e.retailamount;
  });

  giftCartData.map((e) => {
    totalWholesaleAmount = totalWholesaleAmount + e.wholesaleamount;
  });
  const [selectedTab, setSelectedTab] = useState("invoice");
  const userDefault = useSelector(UD);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const [partnerReferenceNumber, setPartnerReferenceNumber] = useState("");
  const [partnerReferenceNumberForCreditCard, setPartnerReferenceNumberForCreditCard] = useState("");
  const [partnerReferenceNumberStatus, setPartnerReferenceNumberStatus] = useState(distributorProfile?.referencenumberallowed === "Y" ? false : true);
  const [partnerReferenceNumberStatusForCreditCard, setPartnerReferenceNumberStatusForCreditCard] = useState(distributorProfile?.referencenumberallowed === "Y" ? false : true);
  
  const handlPartnerReferenceNumberStatusForCreditCard = (value) => {
    setPartnerReferenceNumberStatusForCreditCard(value);
  }
  const handlPartnerReferenceNumberStatus = (value) => {
    setPartnerReferenceNumberStatus(value);
  }

  const focusOnReferenceInfoDivForInvoice = () => {
    const referenceNumberInputField1 = document.getElementById('reference-number1');
    if (referenceNumberInputField1) {
      referenceNumberInputField1.focus();
    }
  }
  const focusOnReferenceInfoDivForCreditCard = () => {
    const referenceNumberInputField2 = document.getElementById('reference-number2');
    if (referenceNumberInputField2) {
      referenceNumberInputField2.focus();
    }
  }

  const getAmount = (paymentType, cardProfile) => {
    if (paymentType === "CREDITCARD")
      return cardProfile === "GUESTCARD" ? totalRetailAmount : totalWholesaleAmount;
    else if (paymentType === "INVOICE")
      return totalWholesaleAmount;
    else
      return totalWholesaleAmount;
  }

  useEffect(() => {
    let totalAmount = 0;
    giftCartData.map((e) => {
      totalAmount = totalAmount + e.amount;
    });
    setAmount(totalAmount);
  }, [giftCartData]);


  const formik_cardholderDetails = useFormik({
    initialValues: {
      buyerFirstName: "",
      buyerLastName: "",
      buyerEmail: "",
      buyerConfirmEmail: "",
      amount: totalRetailAmount,
      confirmationEmail: populateConfirmationEmail(),
      copytoEmail: "",
      name: "",
      email: populateConfirmationEmail(),
      cardType: "VISA",
      cardProfile: { value: "GUESTCARD", label: "GUEST CARD" },
      cardNumber: "",
      cardMonth: getCurrentMonth(),
      cardYear: new Date()?.getFullYear()?.toString(),
      cvv: "",
    },
    validationSchema: Yup.object().shape({
      buyerFirstName: Yup.string().trim().required(t("giftpayment:errors.buyerFirstName.required")),
      buyerLastName: Yup.string().trim().required(t("giftpayment:errors.buyerLastName.required")),
      buyerEmail: Yup.string()
      .matches(EMAIL_POLICY, t("giftpayment:errors.buyerEmail.match"))
        .trim().required(t("giftpayment:errors.buyerEmail.required")),
      buyerConfirmEmail: Yup.string().trim().required(t("giftpayment:errors.buyerConfirmEmail.required"))
        .matches(EMAIL_POLICY, t("giftpayment:errors.buyerConfirmEmail.match"))
        .test(
          'email-validate',
          t("giftpayment:errors.buyerConfirmEmail.equal"),
          confirmEmail => {
            let flag = confirmEmail === formik_cardholderDetails.values.buyerEmail ? true : false;
            if (!flag) {
              return false
            }
            return true
          }
        ),
      confirmationEmail: Yup.string()
        .trim().required(t("payment:errors.confirmationEmail.required"))
        .matches(EMAIL_POLICY, t("payment:errors.email.valid")),
      copytoEmail: Yup.string()
        .matches(EMAIL_POLICY, t("payment:errors.emailCopyTo.valid")),
      name: Yup.string().trim().required(t("payment:errors.name.required")),
      cardNumber: Yup.string()
        .trim().required(t("payment:errors.cardNumber.required"))
        .matches(/^[0-9]+$/, t("payment:errors.cardNumber.match"))
        .length(16, t("payment:errors.cardNumber.length")),
      cvv: Yup.string().trim().required(t("payment:errors.cvv.required"))
          .length(CARD_CVV_LENGTH, t("payment:errors.cvv.length")),
      email: Yup.string().trim().required(t("payment:errors.email.required"))
        .matches(EMAIL_POLICY, t("payment:errors.email.valid")),

      cardMonth: Yup.string().test(
        'test-credit-card-expiration-date',
        t("payment:errors.cardMonth.match"),
        tmpcardmonth => {
          return validExpiryOrNot(formik_cardholderDetails.values.cardYear, tmpcardmonth);
        }
      ),
      cardYear: Yup.string().test(
        'test-credit-card-expiration-date',
        t(""),
        tmpcardyear => {
          return validExpiryOrNot(tmpcardyear, formik_cardholderDetails.values.cardMonth);     
        }
      ),
    }),
    initialTouched : {cardMonth : true, cardYear : true},
    onSubmit: () => handleSubmit(formik_cardholderDetails.values, "CREDITCARD"),
  });

  const formik_buyer = useFormik({
    initialValues: {
      buyerFirstName: "",
      buyerLastName: "",
      buyerEmail: "",
      buyerConfirmEmail: "",
    },
    validationSchema: Yup.object().shape({
      buyerFirstName: Yup.string().trim().required(t("giftpayment:errors.buyerFirstName.required")),
      buyerLastName: Yup.string().trim().required(t("giftpayment:errors.buyerLastName.required")),
      buyerEmail: Yup.string()
      .matches(EMAIL_POLICY, t("giftpayment:errors.buyerEmail.match"))
        .trim().required(t("giftpayment:errors.buyerEmail.required")),
      buyerConfirmEmail: Yup.string().trim().required(t("giftpayment:errors.buyerConfirmEmail.required"))
        .matches(EMAIL_POLICY, t("giftpayment:errors.buyerConfirmEmail.match"))
        .test(
          'email-validate',
          t("giftpayment:errors.buyerConfirmEmail.equal"),
          confirmEmail => {
            let flag = confirmEmail === formik_buyer.values.buyerEmail ? true : false;
            if (!flag) {
              return false
            }
            return true
          }
        ),
    }),
    initialTouched : {cardMonth : true, cardYear : true},
    onSubmit: () => handleSubmit(formik_buyer.values, "INVOICE"),
  });

  useEffect(() => {
    const allowedPaymentTypesDetail = generatePaymentMenu(distributorProfile, userDefault);
    if (allowedPaymentTypesDetail?.cardprofile[0].value === "DISTRIBUTORCARD") {
      formik_cardholderDetails.setFieldValue("amount", totalWholesaleAmount);
    } else {
      formik_cardholderDetails.setFieldValue("amount", totalRetailAmount);
    }
    if(allowedPaymentTypesDetail.allowedPaymentTypes) {
      const result = allowedPaymentTypesDetail.allowedPaymentTypes.find(item => item === "invoice");
      if(result === "invoice") {
        setSelectedTab("invoice")
      } else {
        setSelectedTab("creditcard")
      }
      
    }
    setPaymentTypes(allowedPaymentTypesDetail);
  }, []);

  useEffect(()=>{
    formik_cardholderDetails.setFieldValue("cardMonth",formik_cardholderDetails.values.cardMonth);
    formik_cardholderDetails.setFieldValue("cardYear",formik_cardholderDetails.values.cardYear);
  },[formik_cardholderDetails.values.cardMonth,formik_cardholderDetails.values.cardYear])

  const generatePaymentRequestData = (paymentType, objPayment) => {
    let paymentRequestData = {};
    if (paymentType === "INVOICE") {
      paymentRequestData = {
        "paymenttype": paymentType,
        "invoice": {
          "invoicedate": dateFormatForDisplay(new Date(), "yyyyMMdd"),
          "currency": currencyCode,
          "amount": getAmount(paymentType),
        },
      };
    } else if (paymentType === "CREDITCARD") {
      paymentRequestData = {
        "paymenttype": paymentTypes.cardType === "both" ? formik_cardholderDetails?.values?.cardProfile?.value : paymentTypes.cardprofile[0].value,
        "charged": "Y",
        "creditcard": {
          "cardtype": formik_cardholderDetails?.values.cardType,
          "cardnumber": formik_cardholderDetails?.values.cardNumber.slice(-4),
          "cardholder": formik_cardholderDetails?.values.name,
          "email": formik_cardholderDetails?.values.email,
          "currency": currencyCode,
          "amount": getAmount(paymentType, formik_cardholderDetails?.values?.cardProfile?.value),
          "authorizationnumber": objPayment?.creditcard?.authorizationnumber,
        },
      };
    }

    return paymentRequestData;
  };

  const generateAddGiftCardRequest = (paymentType, objPayment) => {
    let requestData = {};
    let cartItems = [];

    giftCartData.map(card => {
      cartItems.push({
        "packageid": parseInt(card.key) + 1,
        "image": "card" + card.key + ".png",
        "productid": card.productid,
        "airportid": card.loungeType,
        "arrivalairportid": card.loungeType,
        "departureairportid": card.loungeType,
        "adulttickets": card.adult_count.value,
        "childtickets": card.child_count.value,
        // "infanttickets": card.infant_count.value,
        "deliverydate": dateFormatForDisplay(card?.delivery_date, "yyyyMMdd"),
        "contactname": card.recipient_firstname_title + " " + card.recipient_firstname + " " + card.recipient_lastname,
        "contactemail": card.recipient_email,
        "sendername": card.purchase_firstname + " " + card.purchase_lastname, //card.purchase_firstname_title + 
        "message": card.message,
        "value": card.amount
      });
    });

    requestData = {
      "giftcart": {
        "firstname": selectedTab === "invoice" ? formik_buyer.values.buyerFirstName : formik_cardholderDetails.values.buyerFirstName,
        "lastname": selectedTab === "invoice" ? formik_buyer.values.buyerLastName : formik_cardholderDetails.values.buyerLastName,
        "email": selectedTab === "invoice" ? formik_buyer.values.buyerEmail : formik_cardholderDetails.values.buyerEmail,
        "distributorid": distributorid,
        "gifttype": "C",
        "cartitems": cartItems,
        "payment": generatePaymentRequestData(paymentType, objPayment)
      }
    }

    return requestData;
  }

  const handleSubmit = async (values, paymentType) => {
    let request = {};
    let error = false;
    let objPayment = {};
    let callAddGift = false;
    if(distributorProfile?.referencenumberallowed === "Y") {
      if (partnerReferenceNumber === "") {
        showError(t("error"), t("partnerReferenceError"));
        error = true;
      }
    }
 
    if (paymentType === "INVOICE") {
      objPayment = {};
      callAddGift = true;
    } else if (paymentType === "CREDITCARD") {
      try {
        setLoading(true);
        setSubmitOnClickDisable(true);
        // let objPayment = {}
        objPayment = {
          creditcard: {
            cardnumber: formik_cardholderDetails.values?.cardNumber.slice(-4),
            authorizationnumber: "123456",
            amount: getAmount(paymentType, formik_cardholderDetails?.values?.cardProfile?.value),
          },
        }

        const giftCardLogRequest = generateAddGiftCardRequest(objPayment);

        const paymentData = {
          "source": "OBI-GIFTCARD",
          "amount": getAmount(paymentType, formik_cardholderDetails?.values?.cardProfile?.value),
          "cardholderDetails": formik_cardholderDetails.values
        };

        const responseCC = await processCreditCardPayment(paymentData, giftCardLogRequest);
        objPayment = {
          creditcard: {
            cardnumber: responseCC?.data?.cardnumber,
            authorizationnumber: responseCC?.data?.authorizationnumber,
            amount: responseCC?.data.amount,
          }
        };
        if (objPayment && Object.keys(objPayment).length !== 0 && objPayment.creditcard.authorizationnumber && objPayment.creditcard.authorizationnumber != "") {
          callAddGift = true;
        } else {
          setSubmitOnClickDisable(false);
        }
      } catch (e) {
        HandleAPIError(e);
      } finally {
        setSubmitOnClickDisable(false);
        setLoading(false);
      }
    }

    if (callAddGift === true) {
      request = generateAddGiftCardRequest(paymentType, objPayment);
      try {
        setLoading(true);
        const response = await addGift(request);
        if (response.status === 0) {
          let successAmount = getAmount(paymentType, formik_cardholderDetails?.values?.cardProfile?.value);
          const successInfo = {
            confirmationnumber: response?.data?.confirmationnumber,
            currencyCode: currencyCode,
            amount: successAmount.toFixed(2),
            confirmationEmail: request.giftcart.email,
            userConfirmationEmail: "",
            successResponse: response?.data,
            cartData: giftCartData,
            userDeafultEmail: "",
          };
          //  closeSession();
          clearSession(sendBroadcastMessage);
          navigate("/gift-payment-success", { state: successInfo });
        }
        else {
          setLoading(false);
          showError(t("error"), response.statusMessage);
          setSubmitOnClickDisable(false);
        }
      } catch (error) {
        HandleAPIError(error);
        return null;
      } finally {
        setSubmitOnClickDisable(false);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <section>
        <Container className="my-4" ref={divRef}>
          <Loader loading={loading} message={t("loaderMessage")} />
          <h3 className="my-3 d-flex justify-content-center heading heading">
            <span className="heading">{t("giftpayment:payment")}</span>
          </h3>

          <div className="my-3  d-flex justify-content-end">
            <Link to="/giftSummary" className=" mx-1 text-decoration-none">
              <CommonButton classname="back" title={t("back")} />
            </Link>
            <ExitButton />
          </div>
          <Card className="px-0">
            <Card.Body>
              <Row className="d-flex justify-content-start">
                <Col md={4}>
                  <h6 className="field">{t("fillAllFields")}</h6>
                </Col>
              </Row>
              {selectedTab === "invoice" && <ErrorMessages formik={formik_buyer} />}
              {selectedTab === "creditcard" && <ErrorMessages formik={formik_cardholderDetails} />}
              {/* <ErrorMessages formik={formik_cardholderDetails} /> */}
              <Row className="justify-content-center">
                <Col md={11}>
                  <Form onSubmit={(e) => scrollToDiv(e, formik_cardholderDetails)}>
                    <h4 className="px-1 mb-3 ">
                      {t("giftpayment:billingInformation")}
                    </h4>
                    <Card className="bg-light  bg-opacity-10 rounded-3 px-0 mb-4">
                      <Card.Body>
                        <Row className="g-3">
                          <Col md={6} className="">
                            <Form.Label
                              className="d-flex"
                              htmlFor="validationBuyerFirstName"
                            >
                              {t("giftpayment:buyerFirstName")}
                              <span className="field">*</span>
                            </Form.Label>
                            <InputGroup className="d-flex">
                              <Form.Control
                                type="text"
                                id="buyerFirstName"
                                name="buyerFirstName"
                                placeholder={t("giftpayment:buyerFirstName")}
                                aria-describedby="inputGroupPrepend"
                                value={selectedTab === "invoice" ? formik_buyer.values.buyerFirstName : formik_cardholderDetails.values.buyerFirstName}
                                onChange={(e) => {
                                  e.target.value = alphabetValidation(e.target.value)
                                  selectedTab === "invoice" ? formik_buyer.handleChange(e) : formik_cardholderDetails.handleChange(e);
                                }}
                                onBlur={selectedTab === "invoice" ? formik_buyer.handleBlur : formik_cardholderDetails.handleBlur}
                                className={
                                  selectedTab === "invoice"
                                    ? (formik_buyer.touched.buyerFirstName && formik_buyer.errors.buyerFirstName ? "error" : "")
                                    : (formik_cardholderDetails.touched.buyerFirstName && formik_cardholderDetails.errors.buyerFirstName ? "error" : "")
                                }
                                
                                maxLength={GIFT_FIRST_NAME_MAXLENGTH}
                              />
                            </InputGroup>
                          </Col>
                          <Col md={6} className="">
                            <Form.Label
                              className="d-flex"
                              htmlFor="validationBuyerLastName"
                            >
                              {t("giftpayment:buyerLastName")}
                              <span className="field">*</span>
                            </Form.Label>
                            <InputGroup className="d-flex">
                              <Form.Control
                                type="text"
                                id="buyerLastName"
                                name="buyerLastName"
                                placeholder={t("giftpayment:buyerLastName")}
                                aria-describedby="inputGroupPrepend"
                                value={selectedTab === "invoice" ? formik_buyer.values.buyerLastName : formik_cardholderDetails.values.buyerLastName}
                                onChange={(e) => {
                                  e.target.value = alphabetValidation(e.target.value)
                                  selectedTab === "invoice" ? formik_buyer.handleChange(e) : formik_cardholderDetails.handleChange(e);
                                }}
                                onBlur={selectedTab === "invoice" ? formik_buyer.handleBlur : formik_cardholderDetails.handleBlur}
                                className={
                                  selectedTab === "invoice"
                                    ? (formik_buyer.touched.buyerLastName && formik_buyer.errors.buyerLastName ? "error" : "")
                                    : (formik_cardholderDetails.touched.buyerLastName && formik_cardholderDetails.errors.buyerLastName ? "error" : "")
                                }
                                maxLength={GIFT_LAST_NAME_MAXLENGTH}
                              />
                            </InputGroup>
                          </Col>
                          <Col md={6}>
                            <Form.Label
                              className="d-flex"
                              htmlFor="validationCustomemail"
                            >
                              {t("giftpayment:buyerEmail")}
                              <span className="field">*</span>
                            </Form.Label>
                            <InputGroup hasValidation>
                              <InputGroup.Text id="inputGroupPrepend">
                                @
                              </InputGroup.Text>
                              <Form.Control
                                type="email"
                                id="buyerEmail"
                                name="buyerEmail"
                                placeholder={t("emailAddress")}
                                aria-describedby="inputGroupPrepend"
                                value={selectedTab === "invoice" ? formik_buyer.values.buyerEmail : formik_cardholderDetails.values.buyerEmail}
                                onChange={selectedTab === "invoice" ? formik_buyer.handleChange : formik_cardholderDetails.handleChange}
                                onBlur={selectedTab === "invoice" ? formik_buyer.handleBlur : formik_cardholderDetails.handleBlur}
                                className={
                                  selectedTab === "invoice"
                                    ? (formik_buyer.touched.buyerEmail && formik_buyer.errors.buyerEmail ? "error" : "")
                                    : (formik_cardholderDetails.touched.buyerEmail && formik_cardholderDetails.errors.buyerEmail ? "error" : "")
                                }
                                maxLength={EMAIL_MAXLENGTH}
                                minLength={1}
                              />
                              <Form.Control.Feedback>
                                Looks good!
                              </Form.Control.Feedback>
                              <Form.Control.Feedback type="invalid">
                                {t("chooseEmailAddress")}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Col>
                          <Col md={6}>
                            <Form.Label
                              className="d-flex"
                              htmlFor="validationCustomConfirmemail"
                            >
                              {t("giftpayment:buyerConfirmEmail")}
                              <span className="field">*</span>
                            </Form.Label>
                            <InputGroup hasValidation>
                              <InputGroup.Text id="inputGroupPrepend">
                                @
                              </InputGroup.Text>
                              <Form.Control
                                type="email"
                                id="buyerConfirmEmail"
                                name="buyerConfirmEmail"
                                placeholder={t("emailAddress")}
                                aria-describedby="inputGroupPrepend"
                                value={selectedTab === "invoice" ? formik_buyer.values.buyerConfirmEmail : formik_cardholderDetails.values.buyerConfirmEmail}
                                onChange={selectedTab === "invoice" ? formik_buyer.handleChange : formik_cardholderDetails.handleChange}
                                onBlur={selectedTab === "invoice" ? formik_buyer.handleBlur : formik_cardholderDetails.handleBlur}
                                className={
                                  selectedTab === "invoice"
                                    ? (formik_buyer.touched.buyerConfirmEmail && formik_buyer.errors.buyerConfirmEmail ? "error" : "")
                                    : (formik_cardholderDetails.touched.buyerConfirmEmail && formik_cardholderDetails.errors.buyerConfirmEmail ? "error" : "")
                                }
                                maxLength={EMAIL_MAXLENGTH}
                                minLength={1}
                              />
                              <Form.Control.Feedback>
                                Looks good!
                              </Form.Control.Feedback>
                              <Form.Control.Feedback type="invalid">
                                {t("chooseEmailAddress")}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
            
                    <Tabs
                      activeKey={selectedTab}
                      id="justify-tab-example"
                      className=""
                      onSelect={(e) => setSelectedTab(e)}
                    >
                      {paymentTypes?.allowedPaymentTypes?.find(
                        (p) => p === "invoice"
                      ) && (
                          <Tab eventKey="invoice" title={t("payment:invoice")}>
                            {loading ? <LoadingSkeleton /> :
                              <Row className="justify-content-center">
                                <Col md={9}>
                                  <Form>
                                    <Row>
                                      <Col md={5}>
                                        <Form.Label
                                          className="d-flex"
                                          htmlFor="validationCustomPrice"
                                        >
                                          {t("amount")} ({currencyCode})
                                        </Form.Label>
                                        <Form.Control
                                          required
                                          disabled
                                          id="validationPrice"
                                          type="text"
                                          placeholder=""
                                          value={totalWholesaleAmount.toFixed(2)}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Row>
                                    {distributorProfile?.referencenumberallowed === "Y" && <Row>
                                      <Col md={5} className="mb-4 d-flex"  >
                                        <PartnerReferenceComponent
                                          id="reference-number1"
                                          paymentType="invoice"
                                          partnerReferenceNumber={partnerReferenceNumber}
                                          handlPartnerReferenceNumberStatus={handlPartnerReferenceNumberStatus}
                                          focusOnReferenceInfoDivForInvoice={focusOnReferenceInfoDivForInvoice}
                                          onchange={(val) => {
                                            setPartnerReferenceNumber(val);
                                            val.length === 0 && handlPartnerReferenceNumberStatus(false);
                                          }}
                                          onblur={(val) => {
                                            //   val === "" && handlPartnerReferenceNumberStatus(false);
                                          }}
                                        />
                                      </Col>
                                    </Row>}
                                    <Row>
                                      <Col md={5}>
                                        <CommonButton
                                          classname=" gradient-btn  w-100"
                                          title={t("submit")}
                                          size="md"
                                          // type="submit"
                                          disabled={submitOnClickDisable ? submitOnClickDisable : !partnerReferenceNumberStatus}
                                          onclick={(event) => {
                                            setSelectedPaymentType("INVOICE");
                                            // handleSubmit(event, "INVOICE");
                                            formik_cardholderDetails.resetForm();
                                            scrollToDiv(event, formik_buyer)
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  </Form>
                                </Col>
                              </Row>
                            }
                          </Tab>
                        )}
                      {paymentTypes?.allowedPaymentTypes?.find(
                        (p) => p === "creditcard"
                      ) && (
                          <Tab eventKey="creditcard" title={t("payment:creditCard")}>
                            {loading ? <LoadingSkeleton /> :
                              <Row className="justify-content-center">
                                <Col md={8}>
                                  <Form>
                                    <div className="mt-4">
                                      {
                                        <h4 className=" px-1 mb-3 ">
                                          {t("payment:confirmationDetails")}
                                        </h4>
                                      }
                                      <Card className="bg-light bg-opacity-10 rounded-3 px-0 mb-2">
                                        <Card.Body>
                                          <Row className="g-3">
                                            <Col md={6}>
                                              <Form.Label
                                                className="d-flex"
                                                htmlFor="validationCustomemail"
                                              >
                                                {t("payment:sendConfirmationEmailTo")}:
                                                <span className="field">*</span>
                                              </Form.Label>
                                              <InputGroup hasValidation>
                                                <InputGroup.Text id="inputGroupPrepend">
                                                  @
                                                </InputGroup.Text>
                                                <Form.Control
                                                  type="email"
                                                  name="confirmationEmail"
                                                  id="validationCustomemail"
                                                  placeholder={t('emailAddress')}
                                                  value={formik_cardholderDetails.values.confirmationEmail}
                                                  onBlur={formik_cardholderDetails.handleBlur}
                                                  onChange={formik_cardholderDetails.handleChange}
                                                  className={formik_cardholderDetails.touched.confirmationEmail && formik_cardholderDetails.errors.confirmationEmail ? "error" : ""}
                                                  aria-describedby="inputGroupPrepend"
                                                  maxLength={EMAIL_MAXLENGTH}
                                                  minLength={1}
                                                />
                                                <Form.Control.Feedback>
                                                  {t("payment:looksGood")}
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                  {t("chooseEmailAddress")}.
                                                </Form.Control.Feedback>
                                              </InputGroup>
                                            </Col>
                                            <Col md={6}>
                                              <Form.Label htmlFor="validationCustomcopyemail">
                                                {t("payment:copyTo")} (
                                                {t("payment:optional")})
                                              </Form.Label>
                                              <InputGroup hasValidation>
                                                <InputGroup.Text id="inputGroupPrepend">
                                                  @
                                                </InputGroup.Text>
                                                <Form.Control
                                                  type="email"
                                                  name="copytoEmail"
                                                  id="validationCustomcopyemail"
                                                  placeholder={t('emailAddress')}
                                                  aria-describedby="inputGroupPrepend"
                                                  value={formik_cardholderDetails.values.copytoEmail}
                                                  onBlur={formik_cardholderDetails.handleBlur}
                                                  onChange={formik_cardholderDetails.handleChange}
                                                  maxLength={EMAIL_MAXLENGTH}
                                                  minLength={1}
                                                  className={formik_cardholderDetails.touched.copytoEmail && formik_cardholderDetails.errors.copytoEmail ? "error" : ""}

                                                />
                                                <Form.Control.Feedback>
                                                  {t("payment:looksGood")}
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                  {t("chooseEmailAddress")}.
                                                </Form.Control.Feedback>
                                              </InputGroup>
                                            </Col>
                                            {distributorProfile?.referencenumberallowed === "Y" &&
                                              <Col md={6} className="d-flex">
                                                <Form.Group className="">
                                                  <Form.Label className="mt-5" htmlFor="validationCustomcopyemail">
                                                  </Form.Label>
                                                </Form.Group>
                                                <PartnerReferenceComponent
                                                  id="reference-number2"
                                                  paymentType="creditcard"
                                                  partnerReferenceNumber={partnerReferenceNumberForCreditCard}
                                                  handlPartnerReferenceNumberStatusForCreditCard={handlPartnerReferenceNumberStatusForCreditCard}
                                                  focusOnReferenceInfoDivForCreditCard={focusOnReferenceInfoDivForCreditCard}
                                                  onchange={(val) => {
                                                    setPartnerReferenceNumberForCreditCard(val);
                                                    setPartnerReferenceNumber(val);
                                                    val.length === 0 && handlPartnerReferenceNumberStatusForCreditCard(false);
                                                  }}

                                                />
                                              </Col>
                                            }
                                            {showCardType(paymentTypes)
                                              && <Col md="6">
                                                <Form.Label className="d-flex" htmlFor="validationCustomCardType">
                                                  {t('cardProfile')} <span className="field">*</span>
                                                </Form.Label>
                                                <Select
                                                  options={paymentTypes?.cardprofile}
                                                  className="my-0"
                                                  autosize={true}
                                                  value={formik_cardholderDetails.values.cardProfile}
                                                  id="payment_cardProfile"
                                                  onBlur={(d) => formik_cardholderDetails.handleBlur}
                                                  name="cardProfile"
                                                  onChange={(d) => {
                                                    formik_cardholderDetails.setFieldValue("cardProfile", d)
                                                    formik_cardholderDetails.setFieldValue("amount", getAmount("CREDITCARD", d.value))
                                                  }
                                                  }
                                                  getOptionValue={(v) => v.value}
                                                  getOptionLabel={(v) => v.label}
                                                  // isDisabled={!isObjectEmpty(paxCountBookingDetails) ? true : false}
                                                />
                                              </Col >
                                            }
                                            <Col md={"auto"}>
                                              <Form.Label
                                                className="d-flex"
                                                htmlFor="validationCustomPrice"
                                              >
                                                {t("amount")} ({currencyCode})
                                              </Form.Label>
                                              <Form.Control
                                                disabled
                                                id="validationCustomPrice"
                                                type="text"
                                                placeholder=""
                                                value={(formik_cardholderDetails.values.amount).toFixed(2)}
                                                className=""
                                              />
                                              <Form.Control.Feedback>
                                                {t("payment:looksGood")}
                                              </Form.Control.Feedback>
                                            </Col>
                                          </Row>
                                        </Card.Body>
                                      </Card>
                                      <CreditCard
                                        formik={formik_cardholderDetails}
                                        isSubmitDisabled={submitOnClickDisable ? submitOnClickDisable : partnerReferenceNumberStatusForCreditCard ? false : true}
                                        onClick={(e) => {
                                          scrollToDiv(e, formik_cardholderDetails)
                                          formik_buyer.resetForm();
                                        }}
                                        onChange={(key, val) => {
                                          formik_cardholderDetails.setFieldValue(key, val);
                                        }}
                                        onBlur={(key, e) => {
                                          formik_cardholderDetails.handleBlur(e);
                                        }}
                                      />
                                    </div>
                                  </Form>
                                </Col>
                              </Row>
                            }
                          </Tab>
                        )}
                    </Tabs>
                  </Form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
}

export default GiftPayment;