import CommonButton from "./CommonButton";
import { useTranslation } from 'react-i18next';
import Loader from "./Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { showConfirm } from "./Swal";

function DeleteButton(props) {
  const { t } = useTranslation(['common']);

  return (    
    <>
      {/* <Loader loading={loading} /> */}
      <CommonButton
        size={props.size}
        onclick={ async () => {
          const confirmed = await showConfirm(props.heading, props.message)
          if(confirmed.isConfirmed) {
            props.onClick()
          }
        }}
        classname="btn-md delete-btn"
        title={t('delete') }
        icon={
          <FontAwesomeIcon
            icon={faTrashCan}
            className="mx-1"
            size="sm"
            position="right"
          />
        }
      />
    </>
  );
}
export default DeleteButton;