import { useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { getBalance, topupAccount } from "../redux/apiCalls";
import { showError } from "../components/commonComponents/Swal";
import { HandleAPIError, validExpiryOrNot, clearSession, divRef, getCurrentMonth, getDistributorSubDistributorProfile, isObjectEmpty, renderErrorMessages, scrollToDiv, blockInvalidChar } from "../components/commonComponents/commonFunctions";
import { useSelector } from "react-redux";
import { distributorProfile as DP } from "../redux/signInRedux";
import { processCreditCardPayment } from "../utils/Fac";
import { useNavigate, useOutletContext } from "react-router-dom";
import CreditCard from "../components/commonComponents/CreditCard";
import Loader from "../components/commonComponents/Loader";
import { logindetails as LD } from "../redux/signInRedux";
import { useFormik } from "formik";
import { EMAIL_POLICY, CARD_CVV_LENGTH, DEFAULT_CURRENCYCODE} from "../config/commonConstants";
import { number, object, string } from "yup";
import ErrorMessages from "../components/commonComponents/ErrorMessages";

function TopUp() {
  const { t } = useTranslation(["common", "topup"]);
  const sendBroadcastMessage = useOutletContext()
  const loginDetails = useSelector(LD);
  const [distributorProfile] = useState(getDistributorSubDistributorProfile());
  const distributorid = distributorProfile?.distributorid;
  const [loading, setLoading] = useState(false);
  const [prepaidArray, setPrepaidArray] = useState([]);
  // const [amountObject, setAmountObject] = useState([]);
  const [bucketObject, setBucketObject] = useState([]);
  const [submitOnClickDisable, setSubmitOnClickDisable] = useState(false);

  // const [amount, setAmount] = useState(0);
  const navigate = useNavigate();

  const validationSchema = object().shape({
    name: string().trim().required(t("topup:errors.name.required")),
    amount: number().required(t("topup:errors.amount.required"))
    .min(0.01, t("topup:errors.amount.valid")),
    cardNumber: string()
      .required(t("topup:errors.cardNumber.required"))
      .matches(/^[0-9]+$/, t("topup:errors.cardNumber.match"))
      .length(16, t("topup:errors.cardNumber.length")),
    cvv: string().required(t("topup:errors.cvv.required")).length(CARD_CVV_LENGTH, t("payment:errors.cvv.length")),
    email: string().trim().required(t("topup:errors.email.required"))
      .matches(EMAIL_POLICY, t("topup:errors.email.valid")),
    cardMonth: string().test(
      'test-credit-card-expiration-date',
      t("payment:errors.cardMonth.match"),
      tmpcardmonth => {
        return validExpiryOrNot(formik.values.cardYear, tmpcardmonth);
      }
    ),
    cardYear: string().test(
      'test-credit-card-expiration-date',
      t(""),
      tmpcardyear => {
        return validExpiryOrNot(tmpcardyear, formik.values.cardMonth);    
       }
    ),
  })

  const formik = useFormik({
    initialValues: {
      amount: "",
      amountObject: [],
      bucketObject: [],
      name: "",
      email: "",
      cardType: "VISA",
      cardProfile: "",
      cardNumber: "",
      cardMonth: getCurrentMonth(),
      cardYear: (new Date()?.getFullYear()?.toString()),
      cvv: "",
    },
    validationSchema,
    initialTouched : {cardMonth : true, cardYear : true},
    onSubmit: () => handleSubmit(formik.values),
  });

  const handleGetBalance = async () => {

    try {
      setLoading(true);
      let getBalanceReponse = await getBalance({
        buckettype: "PREPAID",
        distributorid: distributorProfile?.distributorid
      });

      if (getBalanceReponse !== null) {
        if (distributorProfile.prepaidproductallowed === "Y") {
          setPrepaidArray(getBalanceReponse?.data?.buckets.filter(bucket => bucket.bucketid !== 'PREPAID'));
        } else {
          setPrepaidArray(getBalanceReponse?.data?.buckets.filter(bucket => bucket.bucketid === 'PREPAID'));
        }
      } else {
        showError(t("error"), getBalanceReponse.statusMessage);
        return null;
      }
    } catch (e) {
      HandleAPIError(e);
      return null;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loginDetails?.sessionid && handleGetBalance();
  }, [loginDetails]);

  useEffect(()=>{
    formik.setFieldValue("cardMonth",formik.values.cardMonth);
    formik.setFieldValue("cardYear",formik.values.cardYear);
  },[formik.values.cardMonth,formik.values.cardYear])

  const generateTopUpRequest = (objPayment) => {
    let topUpRequestData = {
      "topup": {
        "paymentgateway": "FAC",
        "distributorid": distributorid,
        "buckets": formik.values.bucketObject,
        "creditcard": {
          "cardnumber": formik.values.cardNumber.slice(-4),
          "cardholder": formik.values.name,
          "currency": DEFAULT_CURRENCYCODE,
          "cardtype": formik.values.cardType,
          "amount": formik.values.amount,
          "email": formik.values.email,
          "authorizationnumber": objPayment?.creditcard?.authorizationnumber,
        },
        "charged": "Y"
      }
    }

    return topUpRequestData;
  }

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setSubmitOnClickDisable(true);
      let objPayment = {};
      let objAddLogPayment = {
        creditcard: {
          cardnumber: formik.values?.cardNumber.slice(-4),
          authorizationnumber: "123456",
          amount: formik.values.amount,
        },
      };

      const addTopupLogRequest = generateTopUpRequest(objAddLogPayment);
      const paymentData = {
        "source": "OBI-TOPUP",
        "amount": formik.values.amount,
        "cardholderDetails": formik.values
      };
      const responseCC = await processCreditCardPayment(paymentData, addTopupLogRequest);
      if (responseCC && Object.keys(responseCC).length !== 0 && responseCC?.data?.authorizationnumber && responseCC?.data?.authorizationnumber !== "") {
        objPayment = {
          creditcard: {
            cardnumber: responseCC?.data?.cardnumber,
            authorizationnumber: responseCC?.data?.authorizationnumber,
            amount: formik.values.amount,
          }
        };
        let topUpRequest = generateTopUpRequest(objPayment);
        const response = await topupAccount(topUpRequest, objPayment);
        if (response.status === 0) {
          const successInfo = {
            messageHeading: t("topup:topupPayment"),
            successMessage: t("topup:successMessage", { currencyCode: DEFAULT_CURRENCYCODE, amount: formik.values.amount, email: formik.values.email })
          }
          clearSession(sendBroadcastMessage);
          navigate("/common-success", { state: successInfo });
        }
        else {
          showError(t("error"), response.statusMessage);
          setSubmitOnClickDisable(false);
        }
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setSubmitOnClickDisable(false);
      setLoading(false);
    }
  };

  const handleInputChange = (index, value, bucket) => {
    const updatedValues = [...formik.values.amountObject];
    updatedValues[index] = value;
    const bucketAndAmount = [...formik.values.bucketObject];
    bucketAndAmount[index] = { bucketid: bucket, amount: value };
    formik.setFieldValue("bucketObject", bucketAndAmount)
    formik.setFieldValue("amountObject", updatedValues)
    const parsedValues = updatedValues.map((val) => parseFloat(val));
    const amount = parsedValues.reduce((acc, val) => acc + (isNaN(val) ? 0 : val), 0);
    formik.setFieldValue("amount", amount);
  };

  return (
    <>
      <section>
        <Container className="my-4" ref={divRef}>
          <Loader loading={loading} message={t("loaderMessage")} />
          <h3 className="my-3 d-flex justify-content-center heading heading">
            <span className="heading">{t("topup:topUp")}</span>
          </h3>
          <div className="my-3 px-2 pt-0 d-flex justify-content-end">
            <ExitButton />
          </div>
          <Form>
            <Card className="rounded-3 p-4 mb-2 mx-2">
              <Row className="d-flex justify-content-start">
                <Col md={5}>
                  <h6 className="field">{t("fillAllFields")}</h6>
                </Col>
              </Row>
              <ErrorMessages formik={formik} />
              <Row className="g-3">
                <Col md={12}>
                  <table className="prepaid my-3">
                    <tbody>
                      <tr key={0}>
                        <th>{t("payment:accountType")}</th>
                        <th className="text-end">
                          {t("payment:accountBalance")}
                        </th>
                      </tr>
                      {prepaidArray?.map((data, i) => (
                        <tr key={i}>
                          <td>
                            {
                              distributorProfile?.buckets?.find(
                                (bucket) => bucket?.bucketid === data.bucketid
                              ).description
                            }
                          </td>
                          <td className="text-end">
                            {DEFAULT_CURRENCYCODE + " " + data?.amount.toFixed(2)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Col>
              </Row>
              <Row>
                {prepaidArray.map((bucket, i) => (
                  <Col lg={4} md={5} key={bucket.bucketid}>
                    <Form.Label className="d-flex">
                      {t("enterAmountForPrepaidAccount")}{" "}
                      {distributorProfile?.buckets?.find((b) => b?.bucketid === bucket.bucketid).description}{" "}
                      <span className="field">*</span>
                    </Form.Label>
                    <Form.Control
                      key={i}
                      required
                      name={
                        bucket.bucketid.toString().toLowerCase() + "_amount"
                      }
                      // name="amount"
                      id={bucket.bucketid.toString().toLowerCase() + "_amount"}
                      type="number"
                      placeholder={t("enterAmountForPrepaidAccount") + " " +
                      distributorProfile?.buckets?.find((b) => b?.bucketid === bucket.bucketid)?.description}                    
                      min="1"
                      value={formik.values.amountObject[i]}
                      // className="mb-3"
                      onKeyDown={blockInvalidChar}
                      onChange={(e) => {
                        handleInputChange(i, e.target.value, bucket.bucketid);
                      }}
                      onBlur={formik.handleBlur}
                      className={
                        formik.touched.amount && formik.errors.amount
                          ? "error mb-3"
                          : "mb-3"
                      }
                    />
                  </Col>
                ))}
              </Row>
            </Card>
            <Row className="justify-content-start my-3">
              <Col md={6} className="d-flex justify-content-start mb-2">
                <h4 className=" my-2 px-3 ">{t("topup:totalAmount")}</h4>
              </Col>
              <Col md="12">
                <Card className="rounded-3 p-4 mb-2 mx-2">
                  <Row>
                    <Col md={3}>
                      <Form.Label className="d-flex">
                        {t("amount")} ({DEFAULT_CURRENCYCODE})
                      </Form.Label>
                      <Form.Control
                        required
                        disabled
                        name="totalAmount"
                        type="text"
                        placeholder=""
                        value={formik.values.amount || "0.00"}
                        className="mb-3"
                      />
                    </Col>
                  </Row>
                </Card>

                <CreditCard
                  formik={formik}
                  // name={cardholderDetails.name}
                  // email={cardholderDetails.email}
                  // cardType={cardholderDetails.cardType}
                  // cardNumber={cardholderDetails.cardNumber}
                  // cardMonth={cardholderDetails.cardMonth}
                  // cardYear={cardholderDetails.cardYear}
                  // cvv={cardholderDetails.cvv}
                  // errors={cardholderDetails.errors}
                  isSubmitDisabled={
                    submitOnClickDisable ? submitOnClickDisable : false
                  }
                  onClick={(e) => scrollToDiv(e, formik)}
                  onChange={(key, val) => {
                    formik.setFieldValue(key, val);
                  }}
                  onBlur={(key, e) => {
                    formik.handleBlur(e);
                  }}
                />
              </Col>
            </Row>
          </Form>
        </Container>
      </section>
    </>
  );
}

export default TopUp;