import { Card, Col, Form, Row } from "react-bootstrap";
import CommonButton from "./CommonButton";
import Password from "./Password";
import { t } from "i18next";
import HtmlToReactConverter from "./HtmlToReactConverter";
import InputGroup from "react-bootstrap/InputGroup";
import { PASSWORD_MAXLENGTH, USERNAME_MAXLENGTH } from "../../config/commonConstants";

function ManualAuthorization(props) {

  const handleSubmit = async (event) => {
  }

  return (
    <Row className="justify-content-center">
      <Col md="10">
        <h4 className=" px-1 mb-3 ">
          {t("manualauthorization:manualauthorization")}
        </h4>

        <Card className="bg-success  bg-opacity-10 rounded-3 px-0 mb-2">
          <Card.Body>
            <Row>
              <Col>
                <HtmlToReactConverter
                  value={t(
                    "manualauthorization:manualauthorizationInformation"
                  )}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card className="bg-light bg-opacity-10 rounded-3 p-2 my-3">
          <Card.Body>
            <Row>
              <Col>

                <Row>
                  <Col md="12" className="mb-3 mt-3">
                    <Form.Label htmlFor="validationCustomUsername">
                      {t("login:username")}<span className="field">*</span>
                    </Form.Label>
                    <InputGroup className="d-flex">
                      <Form.Control
                        type="text"
                        name="username"
                        maxLength={USERNAME_MAXLENGTH}
                        value={props.formik.values.username}
                        onBlur={props.formik.handleBlur}
                        onChange={props.formik.handleChange}
                        id="validationCustomUsername"
                        placeholder={t("login:username")}
                        aria-describedby="inputGroupPrepend"
                        className={props.formik.touched.username && props.formik.errors.username ? "error" : ""}

                      // onChange={(e) =>
                      //   props.onChange("username", e.target.value)
                      // }
                      />
                    </InputGroup>
                  </Col>
                  <Col md="12" className="mb-3">
                    <Form.Label htmlFor="validationCustomPasswordForManual">
                      {t("login:password")}<span className="field">*</span>
                    </Form.Label>
                    <Password
                      placeholder={t("login:password")}
                      id="validationCustomPasswordForManual"
                      name="password"
                      maxLength={PASSWORD_MAXLENGTH}
                      autoComplete="new-password"
                      value={props.formik.values.password}
                      onBlur={props.formik.handleBlur}
                      onChange={props.formik.handleChange}
                      aria-describedby="inputGroupPrepend"
                      className={props.formik.touched.password && props.formik.errors.password ? "error" : ""}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="text-center">
                    <CommonButton

                      classname="gradient-btn "
                      size="md"
                      title={t("submit")}
                      onclick={props.onClick}
                    />
                  </Col>
                </Row>

              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default ManualAuthorization;
