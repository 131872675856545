import { Card, Col, Form, Row, Container } from "react-bootstrap";
import { useState } from "react";
import Password from "../components/commonComponents/Password";
import CommonButton from "../components/commonComponents/CommonButton";
import { useTranslation } from "react-i18next";
import Loader from "../components/commonComponents/Loader";
import { changePassword } from "../redux/apiCalls";
import md5 from "md5";
import { showError } from "../components/commonComponents/Swal";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/apiCalls";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  HandleAPIError,
  decryptData,
  divRef,
  passwordValidationErrorExpresssion,
  passwordValidationErrorMessages,
  scrollToDiv,
} from "../components/commonComponents/commonFunctions";
import { useFormik } from "formik";
import { ref, object, string } from "yup";
import { PASSWORD_MAXLENGTH, PASSWORD_POLICY } from "../config/commonConstants";
import ErrorMessages from "../components/commonComponents/ErrorMessages";
import { logindetails as LD} from "../redux/signInRedux";

function ForcePasswordChange(props) {
  const { t } = useTranslation(["common", "changepassword"]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sendBroadcastMessage = useOutletContext();
  const [loading, setLoading] = useState(false);
  const loginDetails = useSelector(LD)

  const handleSubmit = async (formik) => {
    let isFormValid = true;
    if (isFormValid) {
      let loginDetail = {};
      if (loginDetails?.sessionid) {
        loginDetail = await decryptData();
      }
      const data = {
        oldpassword: md5(formik.oldPassword),
        newpassword: md5(formik.newPassword),
      };
      setLoading(true);
      try {
        if (loginDetail?.password === data.oldpassword) {
          if (md5(formik.confirmPassword) === data.newpassword) {
            const response = await changePassword(data);
            const logindata = {
              username: loginDetail?.username,
              password: md5(formik.newPassword),
              affiliateid: 0,
            };

            if (response?.status === 0) {
              try {
                const response = await login(dispatch, logindata,sendBroadcastMessage);
                if (response?.status === 0) {
                  navigate("/", { replace: true });
                } else {
                  showError(t("error"), response.statusMessage);
                }
              } catch (e) {
                HandleAPIError(e);
              } finally {
                setLoading(false);
              }
            } else {
              showError(t("error"), response?.statusMessage);
            }
          }
        } else {
          showError(t("error"), t("changepassword:errors.oldPassword.incorrect"));
        }
      } catch (e) {
        HandleAPIError(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const validationSchema = object().shape({
    oldPassword: string().trim().required(
      t("changepassword:errors.oldPassword.required")
    ).max(PASSWORD_MAXLENGTH),
    newPassword: string()
      .test(
        "newPassword",
        passwordValidationErrorMessages(),
        passwordValidationErrorExpresssion()
      )
      .trim().required(t("changepassword:errors.newPassword.required"))
      .min(
        PASSWORD_POLICY.minLength,
        t("changepassword:errors.newPassword.lengthRequired", {
          minLength: PASSWORD_POLICY.minLength,
        })
      ).max(PASSWORD_MAXLENGTH),
    confirmPassword: string()
      .trim().required(t("changepassword:errors.confirmPassword.required"))
      .oneOf(
        [ref("newPassword")],
        t("changepassword:errors.confirmPassword.notMatched")
      ).max(PASSWORD_MAXLENGTH),
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: () => {
      handleSubmit(formik.values);
    },
  });

  return (
    <>
      <section>
        <Container className="my-4" ref={divRef}>
          <Loader loading={loading} message={t("changepassword:Processing")} />

          <h3 className="my-3 d-flex justify-content-center heading">
            <span className="heading">{t("changePassword")}</span>
          </h3>
          <h6 className="field px-3">
            {t('fillAllFields')}
          </h6>
          <Card className="my-3">
            <Card.Body>
              <Form onSubmit={(e)=>scrollToDiv(e,formik)}>
                <ErrorMessages formik={formik} />
                <Row className="d-flex justify-content-center mb-3">
                  <Col md={6}>
                    <Card className="bg-light bg-opacity-10 rounded-3 ">
                      <Card.Body>
                        <Row>
                          <Col md="12" className="mb-3">
                            <Form.Label htmlFor="validationCustomOldPassword">
                              {t("changepassword:oldPassword")}<span className="field">*</span>
                            </Form.Label>
                            <Password
                              name="oldPassword"
                              placeholder={t("changepassword:oldPassword")}
                              id="validationCustomOldPassword"
                              value={formik.values.oldPassword}
                              onChange={formik.handleChange}
                              maxLength={PASSWORD_MAXLENGTH}
                              onBlur={formik.handleBlur}
                              className={
                                formik.touched.oldPassword &&
                                formik.errors.oldPassword
                                  ? "error rounded"
                                  : ""
                              }
                            />
                            {formik.touched.oldPassword}
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12" className="mb-3">
                            <Form.Label htmlFor="validationCustomNewPassword">
                              {t("newPassword")}<span className="field">*</span>
                            </Form.Label>
                            <Password
                              name="newPassword"
                              id="validationCustomNewPassword"
                              placeholder={t("newPassword")}
                              value={formik.values.newPassword}
                              maxLength={PASSWORD_MAXLENGTH}
                              onChange={formik.handleChange}
                              className={
                                formik.touched.newPassword &&
                                formik.errors.newPassword
                                  ? "error rounded"
                                  : ""
                              }
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.newPassword}
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12" className="mb-3">
                            <Form.Label htmlFor="validationCustomConfirmPassword">
                              {t("confirmPassword")}<span className="field">*</span>
                            </Form.Label>
                            <Password
                              name="confirmPassword"
                              id="validationCustomConfirmPassword"
                              placeholder={t("confirmPassword")}
                              value={formik.values.confirmPassword}
                              onChange={formik.handleChange}
                              maxLength={PASSWORD_MAXLENGTH}
                              onBlur={formik.handleBlur}
                              className={
                                formik.touched.confirmPassword &&
                                formik.errors.confirmPassword
                                  ? "error rounded"
                                  : ""
                              }
                            />
                            {formik.touched.confirmPassword}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="d-flex justify-content-center">
                    <CommonButton
                      type="submit"
                      classname="gradient-btn "
                      size="md"
                      title={t("submit")}
                    />
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
}
export default ForcePasswordChange;
