import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loggedInUserDetails: null,
  subDistributorProfileData: {},
}

const loggedInUserDetailRedux = createSlice({
  name: "loggedInUser",
  initialState: initialState,
  reducers: {
    setLoggedInUserDetail: (state, action) => {
      state.loggedInUserDetails = action.payload;
    },
    clearLoggedInUserDetail: state => {
      return initialState;
    },
    clearSubDistributorProfile: state => {
      state.subDistributorProfileData = {};
    },
    setSubDistributorProfileForLastLoggedInUser: (state, action) => {
      state.subDistributorProfileData = action.payload;
    },
  },
});

export const {
  setLoggedInUserDetail, clearLoggedInUserDetail, setSubDistributorProfileForLastLoggedInUser, clearSubDistributorProfile
} = loggedInUserDetailRedux.actions;

export const loggedInUserDetails = (state) => state.loggedInUserDetailRedux?.loggedInUserDetails;
export const subDistributorProfileData = (state) => state.loggedInUserDetailRedux?.subDistributorProfileData;

export default loggedInUserDetailRedux.reducer;
