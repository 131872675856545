import Modal from "react-bootstrap/Modal";
import { Card, Col, Row } from "react-bootstrap";
import { HandleAPIError } from "./commonFunctions";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { getMemberCardTransactions } from "../../redux/apiCalls";
import Loader from "./Loader";
import { createColumnHelper } from "@tanstack/react-table"
import { myDateFormat } from "./commonFunctions"
import { Table } from "./Table";
import md5 from "md5";
import { showError } from "./Swal";

export default function GetTransactionsModal(props) {
  const cancelHandler = () => {
    props.cancelModal();
  };

  const { t } = useTranslation(["common", "memberandcardinformation"]);

  const [loading, setLoading] = useState(false);
  const [GetTransactons, setGetTransactons] = useState([]);

  const columnHelper = createColumnHelper()
  const columns = [
    columnHelper.accessor("transactiondate", {
      cell: info => myDateFormat(info.getValue(),"dd MMM yyyy hh:mm a"),
      header: t('memberandcardinformation:date')
    }),
    columnHelper.accessor("transactiontype", {
      cell: info => <div style={{ textAlign: "left" }}>{info.getValue()}</div>,
      header: t('memberandcardinformation:descriptions')
    }),
    columnHelper.accessor("transactionamount", {
      cell: info => { 
        let colorClass = "";
        let plusMinusSymbol = "";
        if (info.row.original.transactionamount < 0) {
          colorClass = "color-minus";
          plusMinusSymbol = "-";
        } else {
          colorClass = "color-plus";
          plusMinusSymbol = "+";
        }
        return (<div className={colorClass} style={{ textAlign: "right" }}>{plusMinusSymbol}{Math.abs(info.getValue()).toFixed(2) }</div>) 
      },
      header: t('memberandcardinformation:amount') + " (" + t('usd') + ")"
    }),
    ...(props.data?.showValue === "Y" ? [{
          id: "runningbalance",
          ...columnHelper.accessor("runningbalance", {
            cell: info => <div style={{ textAlign: "right" }}>{info.getValue().toFixed(2)}</div>,
            header: t('memberandcardinformation:balance') + " (" + t('usd') + ")"
          })
        }
      ]
    : [])
  ]

  async function MemberCardTransactions() {
    const data = {
      "memberid": props.data.memberCardTypeId + props.data.memberCardNumber,
      "pin": md5(props.data.pin),
    }
    
    try {
      setLoading(true);
      const response = await getMemberCardTransactions(data);
      if (response?.status === 0) {
        setGetTransactons(response?.data);
      }
      else {
        showError(t("error"), response?.statusMessage);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    MemberCardTransactions();
  }, []);

  return (
    <>
      <Loader loading={loading} message={t('loaderMessage')}/>
      <Modal
        show={true}
        onHide={cancelHandler}
        size="lg"
        style={{ zIndex: "9998" }}
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton><h3 className="fw-bold modalHeading">{t("memberandcardinformation:transactions")}</h3></Modal.Header>
        <Modal.Body>
          <Card className="bg-light bg-opacity-10 rounded-3 my-3">
            <Card.Body className="p-2">
              <Table columns={columns} data={GetTransactons} noDataMessage={t("noDataAvailable")} />
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}
