import { Card, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectedMarketCurrency } from "../../redux/marketRedux";
import CommonButton from "./CommonButton";
import { t } from "i18next";
import Select from "react-select";
import InputGroup from "react-bootstrap/InputGroup";
import {
  MANUAL_AUTH_CARD_NUMBER_MAXLENGTH,
  EMAIL_MAXLENGTH,
  NAME_LENGTH, MANUAL_AUTH_AUTHORIZATION_NUMBER_MAXLENGTH,REGEX_NUMERIC
} from "../../config/commonConstants";
import visa from "../../images/visa.svg";
import mastercard from "../../images/mastercard.svg";
import PartnerReferenceComponent from "./PartnerReferenceComponent";
import { numericValidation, alphanumericWithout } from "./commonFunctions";

function ManualAuthorizationCreditCard(props) {
  const currencyCode = useSelector(selectedMarketCurrency);

  return (
    <>
      <Row className="justify-content-center">
        <Col md="10">
          <div className="mt-4">
            {
              <h4 className=" px-1 mb-3 ">
                {t("payment:confirmationDetails")}
              </h4>
            }
            <Card className="bg-light bg-opacity-10 rounded-3 px-0 mb-2">
              <Card.Body>
                <Row className="g-3">
                  <Col md={6}>
                    <Form.Label
                      className="d-flex"
                      htmlFor="validationCustomemail"
                    >
                      {t("payment:sendConfirmationEmailTo")}:
                      <span className="field">*</span>
                    </Form.Label>
                    <InputGroup hasValidation>
                      <InputGroup.Text id="inputGroupPrepend">
                        @
                      </InputGroup.Text>
                      <Form.Control
                        type="email"
                        id="validationCustomemail"
                        placeholder={t("emailAddress")}
                        // onChange={(e) =>
                        //   props.onChange("confirmationEmail", e.target.value)
                        // }
                        aria-describedby="inputGroupPrepend"
                        maxLength={EMAIL_MAXLENGTH}
                        minLength={1}
                        name="confirmationEmail"
                        value={props.formik.values.confirmationEmail}
                        onBlur={props.formik.handleBlur}
                        onChange={props.formik.handleChange}
                        className={props.formik.touched.confirmationEmail && props.formik.errors.confirmationEmail ? "error" : ""}

                      />
                      <Form.Control.Feedback>
                        {t("payment:looksGood")}
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {t("chooseEmailAddress")}.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                  <Col md={6}>
                    <Form.Label htmlFor="validationCustomcopyemail">
                      {t("payment:copyTo")} ({t("payment:optional")})
                    </Form.Label>
                    <InputGroup hasValidation>
                      <InputGroup.Text id="inputGroupPrepend">
                        @
                      </InputGroup.Text>
                      <Form.Control
                        type="email"
                        id="validationCustomcopyemail"
                        placeholder={t("emailAddress")}
                        aria-describedby="inputGroupPrepend"
                        maxLength={EMAIL_MAXLENGTH}
                        minLength={1}
                        name="copytoEmail"
                        value={props.formik.values.copytoEmail}
                        onBlur={props.formik.handleBlur}
                        onChange={props.formik.handleChange}
                        className={props.formik.touched.copytoEmail && props.formik.errors.copytoEmail ? "error" : ""}
                      />
                      <Form.Control.Feedback>
                        {t("payment:looksGood")}
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {t("chooseEmailAddress")}.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                  {props.distributorProfileReferencenumberallowed === "Y" && (
                    <Col md={"auto"}>
                      <PartnerReferenceComponent
                        id="reference-number2"
                        partnerReferenceNumber={props.partnerReferenceNumber}
                        onChange={(val) =>
                          props.onChange("partnerReferenceNumber", val)
                        }
                      // onBlur={(val) => {
                      //   props.validatePartnerReferenceNumber(val);
                      // }}
                      />
                    </Col>
                  )}
                  {props.paymentTypesCardType === "both" &&
                    props.paymentTypesCardProfileLength > 1 && (
                      <Col md="6">
                        <Form.Label
                          className="d-flex"
                          htmlFor="validationCustomCardType"
                        >
                          {t("cardProfile")} <span className="field">*</span>
                        </Form.Label>
                        <Select
                          options={props.options}
                          autosize={true}
                          getOptionValue={(v) => v.value}
                          getOptionLabel={(v) => v.label}
                          onChange={(d) => props.onChange("cardProfile", d)}
                          value={props.formik.values.cardProfile}
                          name="memberCards"
                          onBlur={(d) => props.formik.handleBlur}
                          className={props.formik.touched.cardProfile && props.formik.errors.cardProfile ? " error rounded-2" : ""}
                        />
                      </Col>
                    )}
                  <Col md={"auto"}>
                    <Form.Label
                      className="d-flex"
                      htmlFor="validationCustomPrice"
                    >
                      {t("amount")} ({currencyCode})
                    </Form.Label>
                    <Form.Control
                      disabled
                      id="validationCustomPrice"
                      type="text"
                      placeholder=""
                      value={props.formik.values?.amount?.toFixed(2)}
                      className=""
                    />
                    <Form.Control.Feedback>
                      {t("payment:looksGood")}
                    </Form.Control.Feedback>
                  </Col>
                  <Col md={"auto"}>
                    <Form.Label
                      className="d-flex"
                    >
                      {t("payment:manualAuthorizationMessage")}
                    </Form.Label>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md="10">
          <div>
            <h4 className=" py-3 px-1  ">{t("cardholderDetails")}</h4>
            <Card className="bg-light  bg-opacity-10 rounded-3 px-0 mb-2">
              <Card.Body>
                <div className="d-sm-flex justify-content-sm-between align-items-center mb-3">
                  <h5 className="mb-2 mb-sm-0">{t("paymentcard:weAccept")}:</h5>
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                      {" "}
                      <img src={visa} height="30px" alt="visa" />
                    </li>
                    <li className="list-inline-item">
                      {" "}
                      <img src={mastercard} height="30px" alt="mastercard" />
                    </li>
                  </ul>
                </div>
                <hr className="horizontalBar" />

                <Row className="g-3">
                  <Col md="6">
                    <Form.Label
                      className="d-flex"
                      htmlFor="validationCustomCardUserName"
                    >
                      {t("cardHolderName")}
                      <span className="field">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      name="name"
                      id="validationCustomCardUserName"
                      type="text"
                      placeholder={t("cardHolderName")}
                      maxLength={NAME_LENGTH}
                      minLength={1}
                      value={props.formik.values.name}
                      onBlur={props.formik.handleBlur}
                      onChange={props.formik.handleChange}
                      aria-describedby="inputGroupPrepend"
                      className={props.formik.touched.name && props.formik.errors.name ? "error" : ""}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Col>

                  <Col md="6">
                    <Form.Label
                      className="d-flex"
                      htmlFor="validationCustomcardemail"
                    >
                      {" "}
                      {t("cardholderEmail")}
                      <span className="field">*</span>
                    </Form.Label>
                    <InputGroup hasValidation>
                      <InputGroup.Text id="inputGroupPrepend">
                        @
                      </InputGroup.Text>
                      <Form.Control
                        type="email"
                        id="validationCustomcardemail"
                        placeholder={t("emailAddress")}
                        aria-describedby="inputGroupPrepend"
                        maxLength={EMAIL_MAXLENGTH}
                        minLength={1}
                        name="email"
                        value={props.formik.values.email}
                        onBlur={props.formik.handleBlur}
                        onChange={props.formik.handleChange}
                        className={props.formik.touched.email && props.formik.errors.email ? "error" : ""}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                  <Col md="6">
                    <Form.Label
                      className="d-flex"
                      htmlFor="validationCustomCardNumber"
                    >
                      {t("cardNumberLast4Digits")} <span className="field">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      id="validationCustomCardNumber"
                      type="text"
                      placeholder={t("creditCardNumber")}
                      minLength={MANUAL_AUTH_CARD_NUMBER_MAXLENGTH}
                      maxLength={MANUAL_AUTH_CARD_NUMBER_MAXLENGTH}
                      // value={cardNumber}
                      //  onChange={cardnumberChange}
                      name="cardNumber"
                      value={props.formik.values.cardNumber}
                      onBlur={props.formik.handleBlur}
                      onChange={ (e) => {
                        props.onChange("cardNumber",numericValidation(e.target.value))
                        }
                      }
                      className={props.formik.touched.cardNumber && props.formik.errors.cardNumber ? "error" : ""}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Col>
                  <Col md="6">
                    <Form.Label
                      className="d-flex"
                      htmlFor="validationCustomauthrizationNumber"
                    >
                      {t("authorizationNumber")} <span className="field">*</span>
                    </Form.Label>
                    <Form.Control
                      id="validationCustomauthrizationNumber"
                      type="text"
                      placeholder={t("authorizationNumber")}
                      // value={cardNumber}
                      //  onChange={cardnumberChange}
                      maxLength={MANUAL_AUTH_AUTHORIZATION_NUMBER_MAXLENGTH}
                      name="authorizationNumber"
                      value={props.formik.values.authorizationNumber}
                      onBlur={props.formik.handleBlur}
                      onChange={(e) => {
                        props.onChange("authorizationNumber", alphanumericWithout(e.target.value))
                      }
                      }
                      aria-describedby="inputGroupPrepend"
                      className={props.formik.touched.authorizationNumber && props.formik.errors.authorizationNumber ? "error" : ""}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Col>

                  <Col md="12">
                    <CommonButton
                      classname="gradient-btn w-100"
                      title={t("submit")}
                      size="md"
                      // type="submit"
                      onclick={props.onClick}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default ManualAuthorizationCreditCard;
