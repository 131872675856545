import { store } from "../store/store";
import { showError } from "../components/commonComponents/Swal";
import {
  HandleAPIError,
  addlog,
  formatCreditCardExpiryFAC,
} from "../components/commonComponents/commonFunctions";
import { getOrderId, processCard } from "../redux/apiCalls";
import i18n from "i18next";
import {IS_PAYMENT_MODAL, PRIVATE_AESKEY} from  '../config/commonConstants';
import { encryptCardDetails } from "./Encryptit-aes";

export let facProcess3DSPayment; 

if(IS_PAYMENT_MODAL==="popup"){
 facProcess3DSPayment = async (HtmlData) => {
    return new Promise((resolve, reject) => {
      const state = store.getState();
      const paymentGatewayDetail = state?.paymentGatewayRedux.paymentGateway;
  
      let redirecturl2 = paymentGatewayDetail?.redirecturl2;
      //  https://nigeria.reliablesoftjm.com/VIPERWS/powertranzcallback
  
      let width = 900;
      let height = 600;
  
      let left = window.outerWidth / 2 + window.screenX - ( width / 2)
      let top = window.outerHeight / 2 + window.screenY - ( height / 2)
  
      let paymentWindow = window.open("", "_blank", "width=" + width + ",height="+height + ", top=" + top + ", left=" + left);
  
      if (!paymentWindow) {
        paymentWindow.close()
        resolve({
          success: false,
          statusMessage:i18n.t("browserPopingError"),
          data: {},
        });
      }
        
      paymentWindow.document.write(
        `<div style="margin: auto; width: 50%; border: 1px solid #EEE; padding: 10px; border-radius: 10px;right: 50%; bottom: 50%;transform: translate(50%,50%);position: absolute;"><p style="text-align: center;"><span style="font-weight: bold; font-size: 1.2em;">${i18n.t("waitMessage")} </span><br/>${i18n.t("paymentProcessingError")}</p>`
      );
      paymentWindow.document.write(HtmlData);
  
      let paymentInterval= setInterval(function () {
        if (paymentWindow.closed) {
          clearInterval(paymentInterval);
          resolve({
            success: false,
            windowClosed: true,
            statusMessage: i18n.t("windowClosedError"),
            data: {},
          });
        }
      }, 2000);
  
      window.addEventListener("message",function (ev) {
          let postData = ev.data;
          let responseData = {};
          if (postData.originUrl === redirecturl2) {
            if (postData.ResponseCode === 1) {
              clearInterval(paymentInterval);
              responseData = {
                responsecode: postData.ResponseCode,
                authorizationnumber: postData.AuthCode,
                originalresponsecode: postData.OriginalResponseCode,
                cardnumber: postData.PaddedCardNo,
                referencenumber: postData.ReferenceNo,
                reasoncode: postData.ReasonCode,
                reasoncodedescription: postData.ReasonCodeDesc,
              };
              paymentWindow.close();
              window.removeEventListener("message", function (ev) {});
              resolve({
                success: true,
                statusMessage: "Success",
                data: responseData,
              });
            } else {
              clearInterval(paymentInterval);
              window.removeEventListener("message", function (ev) {});
  
              paymentWindow.close();
  
              responseData = {
                responsecode: postData.ResponseCode,
                authorizationnumber: postData.AuthCode,
                originalresponsecode: postData.OriginalResponseCode,
                cardnumber: postData.PaddedCardNo,
                referencenumber: postData.ReferenceNo,
                reasoncode: postData.ReasonCode,
                reasoncodedescription: postData.ReasonCodeDesc,
              };
  
              resolve({
                success: false,
                statusMessage: responseData.reasoncodedescription,
                data: responseData,
              });
            }
          }
        },
        false
      );
    });
  }; 
}
else{
  facProcess3DSPayment = async (HtmlData, setLoading) => {
    return new Promise((resolve, reject) => {
      const state = store.getState();
      const paymentGatewayDetail = state?.paymentGatewayRedux.paymentGateway;
  
      let redirecturl2 = paymentGatewayDetail?.redirecturl2;
  
      // Create modal
      const modal = document.createElement("div");
      modal.classList.add("modal", "fade");
      modal.id = "myModal";
      modal.role = "dialog";
      modal.style.zIndex = "19999";
      modal.innerHTML = `
      <div class="modal-dialog modal-lg modal-dialog-centered">
      
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header justify-content-end py-0">
            
            <button type="button" class="close btn" aria-label="Close">
              <span aria-hidden="true" class="fs-4">×</span>
            </button>
          </div>
          <div class="modal-body" style="height:400px; display:flex;            justify-content:center; align-items:center">
            <div id="loading_screen">
              <div id="loader" class="p-3" style="height:'100%';width:'100%'">
                <div class="loader mx-auto"></div>
              </div>
              <h4 id="message" class="text-center lh-base ">
                ${i18n.t("waitMessage")} <br />${i18n.t("paymentProcessingError")}
              </h4>
            </div>
          </div>
        </div>
      
      </div>
      `;
  
      var elements = document.getElementsByClassName("App");
      if (elements.length > 0) {
        var appElement = elements[0]; // Assuming you want to append to the first element with class "App"
        appElement.appendChild(modal);
      } else {
        console.error("No elements found with class 'App'");
      }
      
      (function openModal() {
        document.getElementById("myModal").style.display = "block";
        document.getElementById("myModal").classList.add("show");
      })();
      // Create iframe
      const iframe = document.createElement("iframe");
      iframe.style.width = "100%";
      iframe.style.height = "100%";
  
      iframe.srcdoc = HtmlData; // Load HtmlData into iframe

      let i = 0;
      iframe.addEventListener("load", function () {
        if (i === 1) {
          const screen = document.getElementById("loading_screen");
  
          modal.querySelector(".modal-body").removeChild(screen);
          iframe.style.display = "block";
        }
        i++;
      });
      iframe.style.display = "none";
      modal.querySelector(".modal-body").appendChild(iframe);
      modal.querySelector(".close.btn").addEventListener("click", closeModal);
      // Define paymentInterval
      let paymentInterval;
  
      // Listen for messages from iframe
      function handleMessage(ev) {
        let postData = ev.data;
        let responseData = {};
        if (postData.originUrl === redirecturl2) {
          clearInterval(paymentInterval);
  
          if (postData.ResponseCode === 1) {
            responseData = {
              responsecode: postData.ResponseCode,
              authorizationnumber: postData.AuthCode,
              originalresponsecode: postData.OriginalResponseCode,
              cardnumber: postData.PaddedCardNo,
              referencenumber: postData.ReferenceNo,
              reasoncode: postData.ReasonCode,
              reasoncodedescription: postData.ReasonCodeDesc,
            };
            closeModal();
            window.removeEventListener("message", handleMessage);
            resolve({
              success: true,
              statusMessage: "Success",
              data: responseData,
            });
          } else {
            responseData = {
              responsecode: postData.ResponseCode,
              authorizationnumber: postData.AuthCode,
              originalresponsecode: postData.OriginalResponseCode,
              cardnumber: postData.PaddedCardNo,
              referencenumber: postData.ReferenceNo,
              reasoncode: postData.ReasonCode,
              reasoncodedescription: postData.ReasonCodeDesc,
            };
            closeModal();
            window.removeEventListener("message", handleMessage);
            resolve({
              success: false,
              statusMessage: responseData.reasoncodedescription,
              data: responseData,
            });
          }
        }
      }
      
      window.addEventListener("message", handleMessage);
      
      // Close modal if the iframe is closed
      const checkModalClose = setInterval(function () {
        if (!document.body.contains(modal)) {
          clearInterval(checkModalClose);
          resolve({
            success: false,
            windowClosed: true,
            statusMessage: i18n.t("windowClosedError"),
            data: {},
          });
        }
      }, 2000);
    });
  };
}

export function closeModal() {
  // setLoading(true)
  const modal = document.getElementById("myModal");
  if (modal) {
    modal.style.display = "none";
    modal.classList.remove("show");
    modal.remove();
  }
}


export const processCreditCardPayment = async (paymentData, addLogRequest) => { 
  try {
    let data = {
      source: paymentData.source,
      amount: paymentData.amount,
    };
    const orderIdResponse = await getOrderId(data);
    let encryptedCardDetails = encryptCardDetails(paymentData?.cardholderDetails, PRIVATE_AESKEY);

    if (
      !orderIdResponse ||
      orderIdResponse.data.orderid === "" ||
      orderIdResponse.data.orderid === null ||
      orderIdResponse.status !== 0
    ) {
      orderIdResponse.statusMessage &&
        showError(i18n.t("error"), orderIdResponse.statusMessage);
    } else {
      let orderId = orderIdResponse?.data?.orderid;

      addlog(paymentData.source, orderId, addLogRequest);

      const processCardRequest = {
        orderid: orderId,
        actiontype: "CHARGECARD",
        creditcard: {
          cardtype: paymentData.cardholderDetails.cardType,
          cardnumber: encryptedCardDetails?.cardNumber,
          cardholder: encryptedCardDetails?.cardHolderName,
          expirydate: encryptedCardDetails?.expiryDate,
          cvv: encryptedCardDetails?.cvv,
          amount: paymentData.amount,
          iv: encryptedCardDetails?.iv
        },
      };
      const processCardResponse = await processCard(processCardRequest);

      if (processCardResponse?.status === 0) {
        let facResponse;
        if (processCardResponse.data?.html) {
          facResponse = await facProcess3DSPayment(
            processCardResponse?.data.html
          );
        } else {
          facResponse = processCardResponse;
          facResponse.data.orderid = orderId;
        }
        if (
          facResponse.data?.authorizationnumber &&
          facResponse.data.authorizationnumber !== ""
        ) {
          return facResponse;
        } else {
          if (!facResponse.windowClosed) {
            await showError(i18n.t("error"), facResponse?.statusMessage);
          }
          return processCardResponse;
        }
      } else {
        await showError(i18n.t("error"), processCardResponse?.statusMessage);
        return processCardResponse;
      }
    }
  } catch (e) {
    HandleAPIError(e);
  }
};
