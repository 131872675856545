import '../../styles/style.css';
import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";
import Switch from "react-switch";
import { Col, Row } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { DEFAULT_COOKIE } from '../../config/commonConstants';
import { useTranslation } from "react-i18next";
import { setAndRemovieCookie } from './commonFunctions';

export default function ShowCookieModal({visibleCookie, cancelModal, cookieModalHandler, handleAcceptAllCookie}) {
  const [functional, setFunctional] = useState(DEFAULT_COOKIE.functional);
  const { t } = useTranslation(["cookiemodal"]);

  const handleConfirmChoicesModal = () => {
    const allowedCookies =  {
        "necessary": true,
        "functional": functional,
        "showModal": false
    }
    setAndRemovieCookie(allowedCookies);
    cookieModalHandler();
    cancelModal();
  }

    return (
      <>
        <Modal
          show={visibleCookie}
          onHide={cancelModal}
          size="md"
          style={{ zIndex: "9999" }}
          centered
           backdrop="static"
        >
          <Modal.Header closeButton>
            <h3 className='m-0 fw-bold modalHeading'>{t("cookiemodal:cookieSetting")}</h3>
          </Modal.Header>
          <Modal.Body>
            <div className='mb-3'>
              <span className='d-block'>{t("cookiemodal:customizeCookieText")}</span>
            </div>
            <div className="d-flex justify-content-between mb-3">
              <span>{t("cookiemodal:necessary")}
              </span>
              <Switch
                className="react-switch"
                checked={DEFAULT_COOKIE.necessary}
                disabled
                height={23}
                onChange={(nextChecked) => {
                }}
              />
            </div>
            <div className="d-flex justify-content-between mb-3">
              <span>{t("cookiemodal:functionalCookies")}
              </span>
              <Switch
                onChange={(nextChecked) => {
                  setFunctional(nextChecked)
                }}
                checked={functional}
                className="react-switch"
                height={23}
              />
            </div>
          </Modal.Body>
          <Modal.Footer className='justify-content-start'>
            <Row>
              <Col sm={'auto'} className='pb-2'>
                <button type="button" className="btn btn-primary gradient btn-sm form-control p-2" onClick={handleConfirmChoicesModal}>{t("cookiemodal:confirmMyChoices")}</button>
              </Col>
              <Col sm={'auto'}>
                <button type="button" className="btn btn-primary exit-btn shadow-none btn-sm form-control p-2" onClick={cancelModal}>{t("cookiemodal:cancel")}</button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      </>
    );
}
