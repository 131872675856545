import { createSlice } from "@reduxjs/toolkit";
import { getAffiliateExpiryTime } from "../components/commonComponents/commonFunctions";

const affiliatesSlice = createSlice({
  name: "affiliates",
  initialState: {
    affiliates: null,
    error: false,
    affiliateDetails: {},
    expiresAt: null,
    marketId: null,
  },
  reducers: {
    affiliatesFetchSuccess: () => {
    },
    affiliatesFetchFailure: (state) => {
      state.error = true;
    },
    saveAffiliateDetails: (state, action) => {
      state.affiliateDetails = action.payload;
      state.expiresAt = getAffiliateExpiryTime();
    },
    setAffiliateMarketId:(state,action)=>{
      state.marketId = action.payload
    },
    resetAffiliates: (state) => {
      state.affiliateDetails = {};
      state.expiresAt = null;
      state.marketId = null;
    }
  },
});

export const { affiliatesFetchSuccess, affiliatesFetchFailure, saveAffiliateDetails, resetAffiliates,setAffiliateMarketId } = affiliatesSlice.actions;
export const affiliates = (state) => state.affiliatesRedux?.affiliates;
export const affiliateDetails = (state) => state.affiliatesRedux?.affiliateDetails;
export const expiresAt = (state) => state.affiliatesRedux?.expiresAt;
export const marketId = (state) => state.affiliatesRedux?.marketId;
export default affiliatesSlice.reducer;