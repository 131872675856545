import { Card, Col, Container, Row } from "react-bootstrap";
import CommonButton from "./CommonButton";
import giftCard1 from "../../images/gift-card-1.png";
import giftCard2 from "../../images/gift-card-2.png";
import { Link } from "react-router-dom";
import { t } from "i18next";

function EGiftCard(props) {
  return (
    <Container className="d-flex justify-content-center">
      <Card className="giftCardStyle">
        <Row>
          <Col sm={12} md={7}>
            <Card.Body>
              <h3>{t('eGiftCArd')}</h3>
              <div className="align-content-center">
                <p>
                  {t('home:giftCardDescription')}
                </p>
              </div>
              <Link to={props.isDisable ? "" : "/giftcard"} className="text-decoration-none">
                <CommonButton
                  type="submit"
                  classname="gradient-btn w-100"
                  title={t('home:getStarted')}
                  disabled={props?.isDisable}
                />
              </Link>
            </Card.Body>
          </Col>
          <Col sm={12} md={5} className=" justify-content-center">
            <img
              src={giftCard1}
              height="200"
              width="124"
              className=" p-1 mx-3 align-center"
              alt="Gift Card"
            />
            <img
              src={giftCard2}
              height="100"
              width="147"
              className="d-inline-block p-1 mx-3 align-center"
              alt="Gift Card"
            />
          </Col>
        </Row>
        {/* <Row>
          <Col md={6} className="d-flex justify-content-center " >
            <div className="d-grid gap-2 p-1 mb-2">
              <Link
                to="/giftcard"
                className="text-decoration-none"
              >
                <CommonButton
                  type="submit"
                  classname="gradient-btn "
                  title="GET STARTED"
                />
              </Link>
            </div>
          </Col>
        </Row> */}
      </Card>
    </Container>
  );
}

export default EGiftCard;
