import { createSlice } from "@reduxjs/toolkit";
import { getAffiliateExpiryTime, getPartnerExpiryTime } from "../components/commonComponents/commonFunctions";

const partnerSlice = createSlice({
  name: "partnerRedux",
  initialState: {
    error: false,
    partnerDetails: {},
    expiresAt: null,
  },
  reducers: {
    savePartnerDetails: (state, action) => {
      state.partnerDetails = action.payload;
      state.expiresAt = getPartnerExpiryTime();
    }, 
    resetPartner: (state) => {
      state.partnerDetails = {};
      state.expiresAt = null;
    }
  },
});

export const {savePartnerDetails, resetPartner } = partnerSlice.actions;
export const partnerDetails = (state) => state.partnerRedux?.partnerDetails;
export const expiresAt = (state) => state.partnerRedux?.expiresAt;
export default partnerSlice.reducer;