import { Card, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import CommonButton from "../components/commonComponents/CommonButton";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { selectedMarket as currentMarket } from "../redux/marketRedux";
import HtmlToReactConverter from "../components/commonComponents/HtmlToReactConverter";
import { useSelector } from "react-redux";
import { PRODUCTID_ARRIVALBUNDLE } from "../config/commonConstants";
import { getAdultMinimumAge, getMarketLoungenamesWithAirport, getProductName, isSubDistributorProfile, myDateFormat } from "../components/commonComponents/commonFunctions";
import Promotions from "../components/commonComponents/Promotions";

function Success() {
  const { t } = useTranslation(['common', 'successpage']);
  const selectedMarket = useSelector(currentMarket);
  const location = useLocation();
  const successInfo = location.state;

  const marketLoungenameswithairport = getMarketLoungenamesWithAirport(
    selectedMarket?.airports
  );

  const getLoungeNameWithAirpot = (value) => {
    return marketLoungenameswithairport.find(a => a?.value === value)?.label;
  }

  const redirectURL = () => {
    let link = isSubDistributorProfile() ? "/distributor-booking-user" : "/";
    if (successInfo?.isPaxCountUpdate && successInfo?.isPaxCountUpdateFromSearchBooking) {
      link = "/search-booking";
    } else if (successInfo?.isPaxCountUpdate){
      link = "/my-bookings";
    }
    return link;
  }

  const getBookedClubName = (e) => {
    let clubName = "";
    const bookingDetail = successInfo.cartData?.find((c) => c?.key === e?.cartitemid)?.value?.bookingDetail;
    if (!successInfo?.isPaxCountUpdate) {
      if (e?.productid === PRODUCTID_ARRIVALBUNDLE)
        clubName = (bookingDetail[0]?.lounge?.label + " " + t('and') + " " + bookingDetail[1]?.lounge?.label);
      else
        clubName = (bookingDetail?.lounge?.label);
    }
    else {
      if (e?.productid === PRODUCTID_ARRIVALBUNDLE)
        clubName = getLoungeNameWithAirpot((bookingDetail[0]?.lounge)) + " " + t('and') + " " + getLoungeNameWithAirpot(bookingDetail[1]?.lounge);
      else
        clubName = getLoungeNameWithAirpot(bookingDetail?.lounge);
    }
    return clubName;
  }

  const getBookedDate = (e) => {
    let date = "";
    const inputDateFormat = !successInfo?.isPaxCountUpdate ? "MM/dd/yyyy" : "yyyyMMdd";
    const bookingDetail = successInfo.cartData?.find((c) => c?.key === e?.cartitemid)?.value?.bookingDetail;
    
    if (e?.productid === PRODUCTID_ARRIVALBUNDLE) {
      const arrivalFlightTime = !successInfo?.isPaxCountUpdate ? bookingDetail[0]?.flightTime_hour : myDateFormat(bookingDetail[0]?.flightTime_hour, "HHmm", "HH:mm a");
      const departureFlightTime = !successInfo?.isPaxCountUpdate ? bookingDetail[1]?.flightTime_hour : myDateFormat(bookingDetail[1]?.flightTime_hour, "HHmm", "HH:mm a");
      const arrivalDate = myDateFormat(bookingDetail[0]?.flightDate, inputDateFormat, "MM/dd/yyyy") + " " + arrivalFlightTime;
      const departureDate = myDateFormat(bookingDetail[1]?.flightDate, inputDateFormat, "MM/dd/yyyy") + " " + departureFlightTime;

      date = arrivalDate + " " + t('and') + " " + departureDate;
    }
    else {
      if (!successInfo?.isPaxCountUpdate) {
        date = bookingDetail?.flightDate + " " + bookingDetail?.flightTime_hour;
      }
      else {
        date = myDateFormat(bookingDetail?.flightDate, "yyyyMMdd", "MM/dd/yyyy") + " " + myDateFormat(bookingDetail?.flightTime_hour, "HHmm", "HH:mm a");
      }
    }
    return date;
  }

  const getChildCount = (e) => {
    let childCount = "";
    childCount = successInfo.cartData?.find((c) => c?.key === e?.cartitemid)?.value?.childCount;
    return childCount;
  }

  const getAdultCount = (e) => {
    let adultCount = "";
    adultCount = successInfo.cartData?.find((c) => c?.key === e?.cartitemid)?.value?.adultCount;
    return adultCount;
  }

  const getInfantCount = (e) => {
    let infantCount = "";
    infantCount = successInfo.cartData?.find((c) => c?.key === e?.cartitemid)?.value?.infantCount;
    return infantCount;
  }

  return (
    <>
      <section>
        <Container className="my-4">
          <h3 className="my-3 d-flex justify-content-center text-center">
            <span className="heading">{t('successpage:paymentReservationComplete')}</span>
          </h3>
          <Row>
            <Col md={12} className="mx-auto xl">
              <Card className="text-center mt-2">
                <Card.Body className="text-center">
                  <Row>
                    <Col md={12} className="d-flex justify-content-center">
                      <h4 className="lh-base">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          size="3x"
                          className="mb-3"
                          color="green"
                        />
                      </h4>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12} className="d-flex justify-content-center">
                      <h5><HtmlToReactConverter value={t('successpage:thanksForPaymentMessage', { currencyCode: successInfo?.currencyCode, amount: successInfo?.amount.toFixed(2), email: successInfo?.selectedPaymentType === "INVOICE" ? successInfo?.userDeafultEmail : successInfo?.confirmationEmail })} /></h5>
                    </Col>
                  </Row>
                  {successInfo?.successResponse?.map((e, index) => (
                    <Card key={index} className="mt-3 mb-3 my-bg-info">
                      <Row>
                        <Col md={12} className="d-flex justify-content-center">
                          <h5 className="mb-1 lh-base">
                            <HtmlToReactConverter value={t('successpage:confirmationMessage', {
                              confirmationnumber: e?.confirmationnumber,
                              // club: !successInfo?.isPaxCountUpdate ? ((e?.productid === PRODUCTID_ARRIVALBUNDLE)
                              //   ? (successInfo.cartData?.find((c) => c?.key === e?.cartitemid)?.value?.bookingDetail[0]?.lounge?.label + " " + t('and') + " " + successInfo.cartData?.find((c) => c?.key === e?.cartitemid)?.value?.bookingDetail[1]?.lounge?.label) : (successInfo.cartData?.find((c) => c?.key === e?.cartitemid)?.value?.bookingDetail?.lounge?.label))
                              //   :
                              //   ((e?.productid === PRODUCTID_ARRIVALBUNDLE)
                              //     ? getLoungeNameWithAirpot((successInfo.cartData?.find((c) => c?.key === e?.cartitemid)?.value?.bookingDetail[0]?.lounge)) + " " + t('and') + " " + getLoungeNameWithAirpot(successInfo.cartData?.find((c) => c?.key === e?.cartitemid)?.value?.bookingDetail[1]?.lounge) : getLoungeNameWithAirpot(successInfo.cartData?.find((c) => c?.key === e?.cartitemid)?.value?.bookingDetail?.lounge)),
                              club: getBookedClubName(e),
                              product: getProductName(e?.productid),
                              // date: (e?.productid === PRODUCTID_ARRIVALBUNDLE)
                              //   ? (myDateFormat(successInfo.cartData?.find((c) => c?.key === e?.cartitemid)?.value?.bookingDetail[0]?.flightDate, !successInfo?.isPaxCountUpdate ? "MM/dd/yyyy" : "yyyyMMdd", "MM/dd/yyyy")) + " " + (successInfo.cartData?.find((c) => c?.key === e?.cartitemid)?.value?.bookingDetail[0]?.flightTime_hour)
                              //   + " " + t('and') + " " + (myDateFormat(successInfo.cartData?.find((c) => c?.key === e?.cartitemid)?.value?.bookingDetail[1]?.flightDate, !successInfo?.isPaxCountUpdate ? "MM/dd/yyyy" : "yyyyMMdd", "MM/dd/yyyy")) + " " + (successInfo.cartData?.find((c) => c?.key === e?.cartitemid)?.value?.bookingDetail[1]?.flightTime_hour)
                              //   :
                              //   !successInfo?.isPaxCountUpdate ?
                              //     successInfo.cartData?.find((c) => c?.key === e?.cartitemid)?.value?.bookingDetail?.flightDate + " " + successInfo.cartData?.find((c) => c?.key === e?.cartitemid)?.value?.bookingDetail?.flightTime_hour
                              //     :
                              //     myDateFormat(successInfo.cartData?.find((c) => c?.key === e?.cartitemid)?.value?.bookingDetail?.flightDate, "yyyyMMdd", "MM/dd/yyyy")

                              //     + " " + myDateFormat(successInfo.cartData?.find((c) => c?.key === e?.cartitemid)?.value?.bookingDetail?.flightTime_hour, "HHmm", "HH:mm"),
                              date: getBookedDate(e),
                              adultCount: getAdultCount(e),
                              childCount: getChildCount(e),
                              infantCount: getInfantCount(e),
                              email: successInfo?.userConfirmationEmail,
                              adultAge: getAdultMinimumAge()
                            })} />
                          </h5>
                        </Col>
                      </Row>
                    </Card>))}
                  <Row>
                    <Col md={12} className="d-flex justify-content-center">
                      <p>
                        {t('successpage:presentEmailUponArrival')}
                      </p>
                    </Col>
                  </Row>
                  <Promotions
                    jePromotion={true}
                    jemPromotion={true}
                  />
                  <Row>
                    <Col className="m-4 d-flex justify-content-center">
                      <Link to={redirectURL()} className="me-1 text-decoration-none">
                        <CommonButton
                          type="button"
                          size="lg"
                          classname="gradient-btn mx-2"
                          title={t('return')}
                        />
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="d-flex justify-content-center">
                      <h5 className="lead mb-3 lh-base">
                        <HtmlToReactConverter value={t('successpage:contactBottomArea', { phone: selectedMarket?.contact?.phone, email: selectedMarket?.contact?.email, skype: selectedMarket?.contact?.skype })} />
                      </h5>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Success;