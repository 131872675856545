import { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { userDefault as UD, logindetails as LD } from "../../redux/signInRedux";
import { selectedMarketCurrency } from "../../redux/marketRedux";
import CommonButton from "./CommonButton";
import memberCard from "../../images/member-card.png";
import Password from "./Password";
import {
  MEMBERCARD_NUMBER_MAXLENGTH,
  NAME_LENGTH,
  VIP_MEMBERSHIP_CARD_CONTACT,
  VIP_MEMBERSHIP_CARD_CONTACT2,
  VIP_MEMBERSHIP_CARD_EMAIL,
  MEMBERCARD_PIN_MAXLENGTH, DEFAULT_CURRENCYCODE, MEMBERCARD_CARD_TYPE_LABEL, MEMBERCARD_CARD_NUMBER_LABEL, MEMBERCARD_DROPDOWN_TEXT, MEMBERCARD_LABEL, REGEX_ALPHABET
} from "../../config/commonConstants";
import { t } from "i18next";
import HtmlToReactConverter from "./HtmlToReactConverter";
import Select from "react-select";
import { getLoggedInUserMappedMemberCards, convertAmountToUSD, myDateFormat, translateConstantText, numericValidation, alphabetValidation } from "./commonFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

function VipMembershipCard(props) {
  const userDefault = useSelector(UD);
  const currencyCode = useSelector(selectedMarketCurrency);
  const [mappedMemberCards, setMappedMemberCards] = useState([]);
  const loginDetails = useSelector(LD)

  const fetchMappedMemberCards = async () => {
    const response = await getLoggedInUserMappedMemberCards(props?.productid)
    setMappedMemberCards(response);
  }

  useEffect(() => {
    if(userDefault?.subscriber === "Y") {
      loginDetails?.sessionid && fetchMappedMemberCards();
    }
  }, [loginDetails]);

  return (
    <Row className="justify-content-center">
      <Col md="8">

        <h4 className=" px-1 mb-3 ">{t('vipmembershipcard:vipMembershipCard', { vipMembershipLabel: translateConstantText("MEMBERCARD_LABEL") })}</h4>

        <Card className="bg-success  bg-opacity-10 rounded-3 px-0 mb-2">
          <Card.Body>
            <Row>
              <Col md={7} sm={12}>
                <HtmlToReactConverter value={t('vipmembershipcard:vipMembershipCardInformation', { contact1: VIP_MEMBERSHIP_CARD_CONTACT, contact2: VIP_MEMBERSHIP_CARD_CONTACT2, email: VIP_MEMBERSHIP_CARD_EMAIL, vipMembershipLabel: translateConstantText("MEMBERCARD_DROPDOWN_TEXT") })} />
              </Col>
              <Col md={5} sm={6}>
                <Card.Img
                  variant="top"
                  src={memberCard}
                  className="img-responsive center-block"
                  alt=""
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card className="bg-light  bg-opacity-10 rounded-3 px-0 mb-2  ">
          <Card.Body >
            <Row>
              {
                currencyCode !== DEFAULT_CURRENCYCODE &&
                <Col md="auto">
                  <Form.Label className="d-flex" htmlFor="validationCustom04">
                    {t('amount')} ({currencyCode}) 
                  </Form.Label>
                  <Form.Control
                    id="validationCustom04"
                    disabled
                    type="text"
                    name="amount"
                    placeholder=""
                    value={props.formik.values.amount.toFixed(2)}
                    onChange={(e) => props.onChange("tmpamount", e.target.value)}
                    className=""
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Col>
              }
              <Col md="auto">
                <Form.Label className="d-flex" htmlFor="validationCustom04">
                  {t('amount')} ({t("usd")})
                </Form.Label>
                <Form.Control
                  id="validationCustom04"
                  disabled
                  type="text"
                  placeholder=""
                  name="amount"
                  value={convertAmountToUSD(props.formik.values.amount)}
                  onChange={(e) => props.onChange("amount", e.target.value)}
                  className=""
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <h4 className=" py-3 px-1 ">{t('cardholderDetails')}</h4>
        <Card className="bg-light  bg-opacity-10 rounded-3 px-0 mb-2">
          <Card.Body>
            {props.type !== "CREDIT-BOOKING" &&
              (userDefault?.subscriber === "Y" && mappedMemberCards.length > 0) ?
                <Row>
                  <Col md="11" className="mb-3" >
                    <CommonButton
                      type="button"
                      classname="gradient-btn "
                      title={t('selectSavedCards')}
                      size="md"
                      onclick={(e) => { props.SelectedCard() }}
                    />
                  </Col>
                  {props?.linkCardStatus === true &&
                    <Col md="1" className="">
                      <CommonButton
                        classname="btn-md delete-btn me-2"
                        onclick={props.unsetLinkMemberCard}
                        icon={
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="mx-1"
                            size="sm"
                            position="right"
                          />
                        }
                      />
                    </Col>
                  }
                </Row>
              : null
            }
            <Row className="g-3">
              {props?.linkCardStatus === false ?
                <>
                  <Col md="6">
                    <Form.Label className="d-flex" htmlFor="validationCustom02">
                      {t('cardType', { membershipType: translateConstantText("MEMBERCARD_CARD_TYPE_LABEL") })}{" "}
                      <span className="field">*</span>
                    </Form.Label>
                    <Select
                      onChange={(d) => props.onChange("memberCards", d)}
                      options={props.vipMemberCardsList}
                      value={props.formik.values.memberCards}
                      name="memberCards"
                      onBlur={(d) => props.formik.handleBlur}
                      className={props.formik.touched.memberCards && props.formik.errors.memberCards ? " error rounded-2" : ""}
                    />
                  </Col >
                  <Col md="6">
                    <Form.Label className="d-flex" htmlFor="validationCustomVipCardNumber">
                      {t('cardNumber', { membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") })}<span className="field">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t('memberandcardinformation:cardNumber', { membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") })}
                      id="validationCustomVipCardNumber"
                      name="cardNumber"
                      value={props.formik.values.cardNumber}
                      onBlur={props.formik.handleBlur}
                      onChange={(e) => {
                        props.onChange("cardNumber", numericValidation(e.target.value))
                      }}
                      className={props.formik.touched.cardNumber && props.formik.errors.cardNumber ? "error" : ""}

                      maxLength={MEMBERCARD_NUMBER_MAXLENGTH}

                    />
                    {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {t('enterValidCardNumber')}
                      </Form.Control.Feedback> */}
                  </Col >
                  <Col md="6" >
                    <Form.Label className="d-flex" htmlFor="validationCustomVipCardUserName">
                      {t('cardHolderNameMemberShip')}<span className="field">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="validationCustomVipCardUserName"
                      placeholder={t('cardHolderNameMemberShip')}
                      maxLength={NAME_LENGTH}
                      minLength={1}
                      name="cardHolderName"
                      value={props.formik.values.cardHolderName}
                      onBlur={props.formik.handleBlur}
                      onChange={(e) => {
                        // e.target.value = e.target.value?.replace(REGEX_ALPHABET, "");
                        props.onChange("cardHolderName", alphabetValidation(e.target.value))
                      }}
                      className={props.formik.touched.cardHolderName && props.formik.errors.cardHolderName ? "error" : ""}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {t('enterCardHolderName')}
                    </Form.Control.Feedback>
                  </Col >
                  <Col md="2">
                    <Form.Label className="d-flex" htmlFor="validationCustom03">
                      {t('pin')} <span className="field">*</span>
                    </Form.Label>
                    <Password
                      passwordChange={props.passwordChange}
                      minLength={MEMBERCARD_PIN_MAXLENGTH}
                      maxLength={MEMBERCARD_PIN_MAXLENGTH}
                      placeholder={t('pin')}
                      id="validationCustom03"
                      name="pin"
                      autoComplete="new-password"
                      value={props.formik.values.pin}
                      onChange={(e) => {
                        props.onChange("pin",numericValidation(e.target.value))
                      }}
                      onBlur={props.formik.handleBlur}
                      className={props.formik.touched.pin && props.formik.errors.pin ? "error rounded-2" : "rounded-2"}

                    //  onChange={(e) => props.onChange("pin", e.target.value.replace(REGEX_NUMERIC, ""))}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Col >

                  {/* 
                  {props.memberCards?.showvalue === "N" ? (
                    <CommonButton
                      classname="gradient-btn  w-100"
                      title={t('submit')}
                      size="md"
                      type="button"
                      onclick={props.onSubmitButtonClick}
                    />
                  ) : (
                    <Col md="12" controlid="validationCustom04">
                      <CommonButton
                        classname="gradient-btn  w-100"
                        title={t('verify')}
                        size="md"
                        onclick={props.onVerifyButtonClick}
                      />
                    </Col >
                  )}
                  {/* {(props.resetMemberPinStatus && props?.responseGetMemberCards?.find(e => e?.cardid === props?.memberCards?.value)?.showvalue === "Y") ? /}
                  {props.status === 0 ?
                    < Col md="12" controlid="validationCustom04">
                      {props.memberCards?.showvalue === "Y" ? (
                        <Form.Label className="ms-1 d-flex" htmlFor="validationCustom03">
                          {t('availableBalance')}  {props.availableBalance.toFixed(2)}
                        </Form.Label>) : null}
                      {props.availableBalance < props.amount && (
                        <Form.Label className="d-flex field" htmlFor="validationCustom03">
                          {t('insufficientBalance')}
                        </Form.Label>
                      )}
                      {props.memberCards?.showvalue === "Y" ? (
                        (props.availableBalance > props.amount &&
                          <CommonButton
                            classname="gradient-btn mt-2 w-100"
                            title={t('submit')}
                            size="md"
                            type="button"
                            disabled={props.submitOnClickDisable}
                            onclick={props.onClick}
                          />)) : null}
                    </Col > : null}
                   */}
                </>
                :
                <>
                  <Col md="6" >
                    <Form.Label className="d-flex" htmlFor="validationCustomVipCardUserName">
                      {t('cardHolderNameMemberShip')} : {props.formik.values.cardHolderName}
                    </Form.Label>
                  </Col >
                  <Col md="6">
                    <Form.Label className="d-flex" htmlFor="validationCustom04">
                      {t('cardType', { membershipType: translateConstantText("MEMBERCARD_CARD_TYPE_LABEL") })} : {props.formik.values.memberCards.label}
                    </Form.Label>
                  </Col >
                  <Col md="6">
                    <Form.Label className="d-flex" htmlFor="validationCustomVipCardNumber">
                      {t('cardNumber', { membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") })} : {props.formik.values.cardNumber}
                    </Form.Label>
                  </Col >
                  <Col md="6">
                    {props.showValue === "Y" ? (
                      <Form.Label className="d-flex" htmlFor="validationCustom04">
                        {t('balance')}: {t('usd')} {" "} {props.Balance.toFixed(2)}
                      </Form.Label>) : (
                      <Form.Label className="d-flex" htmlFor="validationCustom04">
                        {t('expiryDate')}:  {myDateFormat(props.expiryDate, "yyyyMMdd")}
                      </Form.Label>
                    )}
                  </Col>
                  {/* {props.showValue === "Y" ? (
                    <Col md="12" controlid="validationCustom04">
                      <CommonButton
                        classname="gradient-btn  w-100"
                        title={t('verify')}
                        size="md"
                        onclick={props.resetMemberPinStatus ? props.onVerifyButtonClick : props.onVerifyButtonClick}
                      />
                    </Col >
                  ) : (
                    <CommonButton
                      classname="gradient-btn  w-100"
                      title={t('submit')}
                      size="md"
                      type="button"
                      onclick={props.resetMemberPinStatus ? props.onSubmitButtonClick : props.onSubmitButtonClick}
                    />)}
                  {props.status === 0 ? < Col md="12" controlid="validationCustom04">
                    <Col md="6">
                      {props.showValue === "Y" ? (
                        <Form.Label className="d-flex" htmlFor="validationCustom03">
                          {t('availableBalance')}  {props.Balance.toFixed(2)}
                        </Form.Label>) : null}

                      {props.Balance < props.amount && (
                        <Form.Label className="d-flex field" htmlFor="validationCustom03">
                          {t('insufficientBalance')}
                        </Form.Label>
                      )}
                    </Col >
                    {props.showValue === "Y" ? (
                      props.Balance >= props.amount && (
                        <CommonButton
                          classname="gradient-btn  w-100"
                          title={t('submit')}
                          size="md"
                          type="button"
                          onclick={props.onClick}
                        />)) : null}
                  </Col > : null} */}
                </>
              }
              <Col md="12" controlid="validationCustom04">
                {props.formik.values.memberCards?.showValue === "N" ? (
                  <CommonButton
                    classname="gradient-btn  w-100"
                    title={t('submit')}
                    size="md"
                    type="button"
                    onclick={props.onSubmitButtonClick}
                  />
                ) : (
                  <CommonButton
                    classname="gradient-btn  w-100"
                    title={t('verify')}
                    size="md"
                    onclick={props.onVerifyButtonClick}
                  //  onclick={props.onClick}
                  />
                )}
              </Col >
              {props.formik.values.memberCards?.showValue === "Y" &&
                (props.status === 0 &&
                  < Col md="12" controlid="validationCustom04">
                    <Col md="6">
                      {props.formik.values?.memberCards?.showValue === "Y" && (
                        <Form.Label className="ms-2 d-flex" htmlFor="validationCustom03">
                          {t('availableBalance')}  {props.availableBalance.toFixed(2)}
                        </Form.Label>)}
                      {props.availableBalance < props.formik.values.amount && (
                        <Form.Label className="d-flex field" htmlFor="validationCustom03">
                          {t('insufficientBalance')}
                        </Form.Label>
                      )}
                    </Col >
                    {props.formik.values.memberCards?.showValue && (
                      props.availableBalance >= props.formik.values.amount && (
                        <CommonButton
                          classname="gradient-btn mt-2 w-100"
                          title={t('submit')}
                          size="md"
                          disabled={props.submitOnClickDisable}
                          onclick={props.onClick}
                        />))}
                  </Col >
                )
              }
            </Row>
          </Card.Body>
        </Card>

      </Col>
    </Row>
  );
}

export default VipMembershipCard;
