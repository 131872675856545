import React from "react";

function HtmlToReactConverter(props) {
  const createMarkup = (value) => {
    const backendHtmlString = value;
    return { __html: backendHtmlString };
  };

  return <div dangerouslySetInnerHTML={createMarkup(props.value)} />;
}

export default HtmlToReactConverter;
