import { Card, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import CommonButton from "../components/commonComponents/CommonButton";
import { useEffect, useState } from "react";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from 'react-i18next';
import { getUserList, addEditUser } from "../redux/apiCalls";
import Loader from "../components/commonComponents/Loader";
import { faPencil, faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { userDefault as UD, logindetails as LD } from "../redux/signInRedux";
import { useSelector } from "react-redux";
import { resetPassword } from "../redux/apiCalls";
import { createColumnHelper } from "@tanstack/react-table"
import { Table } from "../components/commonComponents/Table";
import AddUserModal from "../components/commonComponents/AddUserModal";
import useToggle from "../hooks/useToggle";
import { showSuccess, showError } from "../components/commonComponents/Swal";
import { HandleAPIError, checkForLoggedInUserSessionExist } from "../components/commonComponents/commonFunctions";

function ManageUser() {
  const { t } = useTranslation(['common', 'manageuser']);
  const [userlist, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const loginDetails = useSelector(LD)

  const [userData, setUserData] = useState({});
  const [isVisibleAdduser, setIsVisibleAdduser] = useToggle();

  const Resetpassword = async(username) => {
    const data = {
      "username": username
    };
    var resetpassword = "Y";
    try {
      const response = await resetPassword(data, resetpassword);
      if (response?.status === 0){
        showSuccess(response.statusMessage,t('manageuser:resetPasswordSuccessMessage'))
      }
      else {
        showError(t("error"), response?.statusMessage);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  };

  const userDefault = useSelector(UD);
  const manageuser = userDefault?.manageuser

  const showAddUserModal = () => {
    setUserData({});
    setIsVisibleAdduser();
  }

  const showEditUserModal = (user) => {
    user.editMode = "Y";
    setUserData(user);
    setIsVisibleAdduser()
  }

  const fetchUserList = async () => {
    setLoading(true)
    try {
      const response = await getUserList();
      if (response?.status === 0){
        setUserList(response.data.users);
      }
      else {
        showError(t("error"), response?.statusMessage);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  }

  const handleParentSubmit = async(data, editMode = "N") => {

    try {
      const response = await addEditUser(data, editMode);
      if (response?.status === 0){
        let successMessage = editMode === "Y" ? t('manageuser:editSuccessUserMessage') : t('manageuser:addSuccessUserMessage');
        showSuccess(response?.statusMessage, successMessage);
        fetchUserList();
      }
      else {
        showError(t("error"), response?.statusMessage);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  };

  const tooltip = (tooltipText) => (
    <Tooltip id="tooltip-cart" className="tooltip" style={{ fontSize: "15px" }}>
      {t(`mybookings:${tooltipText}`)}
    </Tooltip>
  );

  useEffect(() => {
    checkForLoggedInUserSessionExist() !== "" && fetchUserList()
  }, [loginDetails]);

  const columnHelper = createColumnHelper()

  const columns = [
    columnHelper.accessor("username", {
      cell: info => <div style={{ textAlign: "left" }}>{info.getValue()}</div>,
      header: t('manageuser:name')
    }),
    columnHelper.accessor("firstname", {
      cell: info => <div style={{ textAlign: "left" }}>{info.getValue()}</div>,
      header: t('manageuser:firstName')
    }),
    columnHelper.accessor("lastname", {
      cell: info => <div style={{ textAlign: "left" }}>{info.getValue()}</div>,
      header: t('manageuser:lastName')
    }),
    columnHelper.accessor("email", {
      cell: info => <div style={{ textAlign: "left" }}>{info.getValue()}</div>,
      header: t('manageuser:Email')
    }),
    columnHelper.accessor("phone", {
      cell: info => <div style={{ textAlign: "left" }}>{info.getValue()}</div>,
      header: t('manageuser:phone')
    }),
    columnHelper.accessor("reportagent", {
      cell: info => <div style={{ textAlign: "left" }}>{info.getValue() === "Y" ? "Yes" : "No"}</div>,
      header: t('manageuser:reportAgent'),
      className: "right"
    }),
    columnHelper.accessor("adminuser", {
      cell: info => <div style={{ textAlign: "left" }}>{info.getValue() === "Y" ? "Yes" : "No"}</div>,
      header: t('manageuser:manageUser')
    }),
    columnHelper.accessor("status", {
      cell: info => <div style={{ textAlign: "left" }}>{info.getValue() === "A" ? "Active" : "Inactive"}</div>,
      header: t('manageuser:staus')
    }),
    columnHelper.accessor("changepassword", {
      cell: info => (
        <div style={{ textAlign: "center" }}>
          <OverlayTrigger placement="bottom" overlay={tooltip("resetPassword")}>
            <FontAwesomeIcon
              className="ms/1 pointer icon-color mx-2"
              icon={faRotate}
              size="lg"
              onClick={() => Resetpassword(info.row.original.username)}
            />
          </OverlayTrigger>
          <OverlayTrigger placement="bottom" overlay={tooltip("edit")}>
            <FontAwesomeIcon
              className="ms/1 pointer icon-color mx-2"
              icon={faPencil}
              size="lg"
              onClick={() => {showEditUserModal(info.row.original) }}
            />
          </OverlayTrigger>
        </div>
      ),
      header: "",
      enableSorting: false
    }),
  ]

  return (
    <>
      <AddUserModal
        visible={isVisibleAdduser}
        message={""}
        cancelModal={setIsVisibleAdduser}
        formData={userData}
        handleSubmit={handleParentSubmit}
      />
      <section>
        <Container className="my-4">
          <Loader loading={loading} message={t("common:loaderMessage")} />
          <div className="my-3">
            <h3 className="d-flex justify-content-center heading heading">
              <span className="heading">
                {t("manageuser:manageUserHeading")}
              </span>
            </h3>
          </div>
          <div className="my-3 px-1 d-flex justify-content-end">
            {manageuser === "Y" ? (
              <CommonButton
                type="submit"
                classname="gradient-btn mx-1"
                title={t("manageuser:addUser")}
                onclick={() => {
                  showAddUserModal();
                }}
              />
            ) : (
              ""
            )}
            {/* <Link to="/" className=" mx-1 text-decoration-none">
              <CommonButton classname="back" title={t("back")} />
            </Link> */}

            <ExitButton />
          </div>

          <Card className="">
            <Card.Body>
              {manageuser === "Y" ? (
                <Row>
                  <Col md={12}>
                    <Table
                      columns={columns}
                      data={userlist}
                      noDataMessage={t("noDataAvailable")}
                    />
                  </Col>
                </Row>
              ) : (
                <Card className="mb-3 my-bg-info">
                  <Row>
                    <Col md={12} className="d-flex justify-content-center">
                      <h5 className="d-flex justify-content-center">
                        {t("manageuser:manageUserMessage")}
                      </h5>
                    </Col>
                  </Row>
                </Card>
              )}
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
}

export default ManageUser;
