import { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLockOpen, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import Password from "../components/commonComponents/Password";
import Loader from "../components/commonComponents/Loader";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import CommonButton from "../components/commonComponents/CommonButton";
import { useDispatch, useSelector } from "react-redux";
import { clearVIPERCart, getAffiliates, login, setMarketId } from "../redux/apiCalls";
import md5 from "md5";
import ForgotPasswordModal from "../components/commonComponents/ForgotPasswordModal";
import useToggle from "../hooks/useToggle";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { cartData as CD, clearCart } from "../redux/cartRedux";
import { HandleAPIError, checkIfPartnerLogin, divRef, isObjectEmpty, scrollToDiv, sendToHome } from "../components/commonComponents/commonFunctions";
import { useTranslation } from "react-i18next";
import { showConfirm, showError } from "../components/commonComponents/Swal";
import { error as ERR,logindetails as LD } from "../redux/signInRedux";
import { selectedMarketId as SMID, selectedMarketLanguage as SML, setSelectedMarket } from "../redux/marketRedux";
import { giftCartData as GCD, giftCartclear } from "../redux/giftCartRedux";
import { paxCountClear } from "../redux/increasePaxCountRedux";
import { clearSubDistributorProfile } from "../redux/loggedInUserDetailRedux";
import ErrorMessages from "../components/commonComponents/ErrorMessages";
import { PASSWORD_MAXLENGTH, USERNAME_MAXLENGTH } from "../config/commonConstants";
import { savePartnerDetails } from "../redux/partnerRedux";


let form_title, sign_up_link, sign_up_text;
export default function Login() {
  const { t } = useTranslation("common", "login");
  const sendBroadcastMessage = useOutletContext();
  const [loading, setLoading] = useState(false);
  const selectedMarketId = useSelector(SMID);
  const selectedMarketLanguage = useSelector(SML);
  const cartData = useSelector(CD);
  const giftCartData = useSelector(GCD);
  const loginDetail = useSelector(LD)

  const navigate = useNavigate();
  const dispatch = useDispatch();
  let error = useSelector(ERR);

  if (window.location.pathname === "/subscriber-login") {
    form_title = t("login:subscriberLogin");
    sign_up_text = t("login:notYetMember");
    sign_up_link = "/subscriber-registration";
  } else {
    form_title = t("login:partnerLogin");
    sign_up_text = t("login:notYetPartner");
    sign_up_link = "/partner-registration";
  }
  const validationSchema = Yup.object().shape({
    username: Yup.string().trim().required(t("login:errors.userName.required")),
    password: Yup.string().trim().required(t("login:errors.password.required")).max(PASSWORD_MAXLENGTH),
  });
  const [isVisibleForgotPassword, setIsVisibleForgotPassword] = useToggle();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema,
    onSubmit: () => handleSubmit(formik.values),
  });

  const handleSubmit = async (values) => {
    const data = {
      "username": values.username,
      "password": md5(values.password),
      "marketid": selectedMarketId,
      "languageid": selectedMarketLanguage,
      "affiliateid": 0,
    };

    if (!isObjectEmpty(loginDetail) && (loginDetail?.username === formik.username)) {
      navigate("/", { replace: true });
      setLoading(false);
    } else {
      try {
        setLoading(true);
        var doLogin = true;
        if (cartData.length > 0 || giftCartData.length > 0) {
          let showConfirmresponse = await showConfirm(t("warning"), t("login:confirmLoginHeading"));
          doLogin = showConfirmresponse?.isConfirmed;
          doLogin && await clearVIPERCart();
        }
        if (doLogin) {
          dispatch(clearCart());
          dispatch(giftCartclear());
          dispatch(paxCountClear());
          dispatch(clearSubDistributorProfile());
          const res = await login(dispatch, data,sendBroadcastMessage);
          if (res.status === 0) {
            if (res?.data?.marketid === selectedMarketId) {
              // do nothing 
            } else {
              const data = {
                "marketid": res?.data?.marketid,
                "languageid": res?.data?.languageid,
              };
              setMarketId(data)
              dispatch(setSelectedMarket(res?.data?.marketid));
            }

            if (res.data.distributorprofile.distributorbookingallowed === "Y") {
              navigate("/distributor-booking-user", { replace: true });
            } else {
              navigate("/", { replace: true });
            }
          } else {
            showError(t("error"), res.statusMessage);
          }
        }
      } catch (e) {
        HandleAPIError(e);
      } finally {
        setLoading(false);
      }
    }
  };

  let { partnerId } = useParams();
  partnerId = parseInt(partnerId);

  async function _getAffiliates() {
    try {
      setLoading(true);
      const request = { affiliateid: partnerId };
      const partnerData = await getAffiliates(request);
      dispatch(savePartnerDetails(partnerData?.data?.affiliates[0]));
    } catch (e) {
      HandleAPIError(e);
    }
    finally {
      setLoading(false);
    }
  }
  useEffect(() => {
   if (partnerId ) {
    _getAffiliates();
   }
  }, [partnerId]);


  useEffect(() => {
    sendToHome(navigate)
   }, []);

  return (
    <>
      <ForgotPasswordModal
        visible={isVisibleForgotPassword}
        message={"this is forgot password modal"}
        cancelModal={setIsVisibleForgotPassword}
      />

      <section>
        <Container className="my-4" ref={divRef}>
          {error !== null && <div className="d-flex justify-content-center bg-danger bg-gradient bg-opacity-50 rounded-1">
            {/* <h5 className="my-3 d-flex justify-content-center">{error}</h5> */}
          </div>}
          <Loader loading={loading} message={t('login:loaderMessage')} />
          <Row className="d-flex justify-content-center">
            <Col lg="4">
              <Card className="bg-light bg-opacity-10 rounded-3 p-2 my-3">
                <Card.Body>
                  <h3 className="d-flex justify-content-center">
                    {form_title}
                  </h3>
                  <Card.Text className="d-flex justify-content-center">
                    {t("login:loginWithInformation")}
                  </Card.Text>
                  <hr className="horizontalBar" />
                    <Row className="d-flex justify-content-start">
                        <Col md={12}>
                          <h6 className="field">{t('fillAllFields')}</h6>
                        </Col>
                    </Row>

                  <ErrorMessages formik={formik} />
                  <Form onSubmit={(e)=>scrollToDiv(e,formik)}>
                    <Row>
                      <Col md="12" className="mb-3 mt-3">
                        <Form.Label htmlFor="validationCustomUsername">
                          {t("login:username")}<span className="field">*</span>
                        </Form.Label>
                        <InputGroup className="d-flex">
                          <Form.Control
                            type="text"
                            id="validationCustomUsername"
                            placeholder={t("login:username")}
                            aria-describedby="inputGroupPrepend"
                            name="username"
                            maxLength={USERNAME_MAXLENGTH}
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={formik.touched.username && formik.errors.username ? "error" : ""}
                          />
                          {formik.touched.username}
                        </InputGroup>
                      </Col>
                      <Col md="12" className="mb-3">
                        <Form.Label htmlFor="validationCustomPassword">
                          {t("login:password")}<span className="field">*</span>
                        </Form.Label>
                        <Password
                          name={"password"}
                          maxLength = {PASSWORD_MAXLENGTH}
                          value={formik.values.password}
                          placeholder={t("login:password")}
                          onChange={formik.handleChange}
                          id={"validationCustomPassword"}
                          onBlur={formik.handleBlur}
                          className={formik.touched.password && formik.errors.password ? "error" : ""}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="text-center">
                        <CommonButton
                          type="submit"
                          classname="gradient-btn "
                          size="md"
                          title={t("login:login")}
                        />
                      </Col>
                    </Row>

                    {!checkIfPartnerLogin() &&
                    <Row className="my-3">
                      <Col md={"12"}>
                        <Card.Text>
                          <a
                            href={sign_up_link}
                            id="signup"
                            className=" text-decoration-none "
                          >
                            <FontAwesomeIcon
                              icon={faUserPlus}
                              size="lg"
                              className="mx-1 icon-color"
                            />
                            {sign_up_text}
                          </a>
                        </Card.Text>
                      </Col>
                    </Row>}
                    <Row className="my-2">
                      <Col md={"12"}>
                        <Card.Text>
                          <a href="#" id="forgotpassword" className=" text-decoration-none" onClick={setIsVisibleForgotPassword}>
                            <FontAwesomeIcon
                              icon={faLockOpen}
                              size="lg"
                              className="mx-1 icon-color"
                            />
                            {t("login:forgotPassword")}
                          </a>
                        </Card.Text>
                      </Col>
                    </Row>
                  </Form>

                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
