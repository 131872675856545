import { createSlice } from "@reduxjs/toolkit";

const memberCardSlice = createSlice({
  name: "memberCard",
  initialState: {
    memberCards:[],
    memberCardValues: [],
    error: false,
  },
  reducers: {
    memberCardValuesFetchSuccess: (state, action) => {
      state.memberCardValues = action.payload;
    },
    memberCardValuesFetchFailure: (state) => {
      state.error = true;
    },
    memberCardsFetchSuccess: (state, action) => {
      state.memberCards = action.payload;
    },
    memberCardsFetchFailure: (state) => {
      state.error = true;
    },
  },
});

export const { memberCardValuesFetchSuccess, memberCardValuesFetchFailure, memberCardsFetchSuccess, memberCardsFetchFailure } = memberCardSlice.actions;
export const memberCardValues = (state) => state.memberCardRedux?.memberCardValues;
export const memberCards = (state) => state.memberCardRedux?.memberCards;

export default memberCardSlice.reducer;