import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CommonButton from "./CommonButton";
import { alphanumericWithout, getSubDistributorId } from "./commonFunctions";
import { verifyReferenceNumber } from "../../redux/apiCalls";
import { useState } from "react";
import Loader from "./Loader";
import { showError } from "./Swal";
import PartnerReferenceNumberBookingModal from "./PartnerReferenceNumberBookingModal";
import { PARTNER_REFERENCE_NUMBER_MAXLENGTH } from "../../config/commonConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

function PartnerReferenceComponent(props) {
  const { t } = useTranslation(["common"]);
  const [verifyResponseData, setVerifyResponseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibleReferenceBookingModal, setVisibleReferenceBookingModal] = useState(false);
  const [validReferenceNumber, setValidReferenceNumber] = useState(false);

  const handlePartnerReferenceNumberStatus = (status) => {
    props.paymentType === "creditcard" ? props.handlPartnerReferenceNumberStatusForCreditCard(status) : props.handlPartnerReferenceNumberStatus(status);
  }

  const hideReferenceBookingModal = () => {
    handlePartnerReferenceNumberStatus(true);
    setValidReferenceNumber(true);
    setVisibleReferenceBookingModal(false)
  };
  const showReferenceBookingModal = () => setVisibleReferenceBookingModal(true);

  const noButtonClickHandler = async () => {
    handlePartnerReferenceNumberStatus(false);
    setValidReferenceNumber(false);
    setVisibleReferenceBookingModal(false);
    await props.paymentType === "creditcard" ? props.focusOnReferenceInfoDivForCreditCard() : props.focusOnReferenceInfoDivForInvoice();

  }

  const validatePartnerReferenceNumber = async (partnerReferenceNumber) => {
    if (partnerReferenceNumber !== "") {
      try {
        setLoading(true);
        let request = { "referencenumber": partnerReferenceNumber, "distributorid": getSubDistributorId() }
        const verifyReferenceNumberReponse = await verifyReferenceNumber(request);
        if (verifyReferenceNumberReponse.status === 0) {
          if (verifyReferenceNumberReponse?.data?.hasOwnProperty("referencenumber")) {
            handlePartnerReferenceNumberStatus(true);
            setValidReferenceNumber(true);
            return verifyReferenceNumberReponse?.data?.referencenumber;
          }
          else {
            setVerifyResponseData(verifyReferenceNumberReponse?.data?.reservations);
            showReferenceBookingModal();
            handlePartnerReferenceNumberStatus(false);
            setValidReferenceNumber(false);
            return null;
          }
        }
        else
          showError(t("error"), verifyReferenceNumberReponse.statusMessage);
      } catch (error) {
        showError(t("error"), error.message);
        return null;
      } finally {
        setLoading(false);
      }
    }
    else
      showError(t("error"), t("partnerReferenceError"));
  }

  return (
    <div>
      <Loader loading={loading} message={t("loaderMessage")} />
      {visibleReferenceBookingModal && (
        <PartnerReferenceNumberBookingModal
          message={""}
          verifyResponseData={verifyResponseData}
          cancelModal={hideReferenceBookingModal}
          noButtonClickHandler={noButtonClickHandler}
          direction={"A"}
        />
      )}

      <Form.Group className="">
        <Form.Label htmlFor="validationCustomcopyemail">
          {t('partnerReference')}<span className="field">*</span>
        </Form.Label>
        <Row md={12}>
          <Col md={7}>
            <Form.Control
              type="text"
              id={props.id}
              value={props.partnerReferenceNumber}
              onChange={(e) => {
                e.target.value = alphanumericWithout(e.target.value).toUpperCase();
                props.onchange(e.target.value);
                setValidReferenceNumber(false);
                handlePartnerReferenceNumberStatus(false);
              }
              }
              className="mx-0"
              maxLength={PARTNER_REFERENCE_NUMBER_MAXLENGTH}
              placeholder=""
            />

          </Col>
          <Col md={5}>
            {!validReferenceNumber ?
              <CommonButton
                classname=" gradient-btn"
                title={t('verify')}
                size="md"
                onclick={() => validatePartnerReferenceNumber(props.partnerReferenceNumber)}
              />
              :
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="me-2 mt-1"
                size="xl"
                color="green"
              />
            }
          </Col>
        </Row>
      </Form.Group>
    </div>
  );
}

export default PartnerReferenceComponent;
