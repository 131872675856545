import { useState } from "react";
import { Card, Container, Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faChampagneGlasses,
  faCircleInfo,
  faChild,
  faPersonBreastfeeding
} from "@fortawesome/free-solid-svg-icons";
import CommonButton from "../components/commonComponents/CommonButton";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from "react-i18next";
import { availableMarkets as AM, selectedMarketCurrency, selectedMarket as currentMarket ,selectedMarketId as SM  } from "../redux/marketRedux";

import { FLAG_URL, PRODUCTID_ARRIVAL, PRODUCTID_ARRIVALBUNDLE, PRODUCTID_DEPARTURE } from "../config/commonConstants";
import { useDispatch, useSelector } from "react-redux";
import { HandleAPIError, clearSession, dateFormatForDisplay, getMarketLoungenames, getMarketLoungenamesWithAirport, checkLoggedInUserIsLoungePartner, getTermsandConditionsPdfFilename } from "../components/commonComponents/commonFunctions";
import { arrivaldata, departuredata } from "../redux/flightScheduleRedux";
import Loader from "../components/commonComponents/Loader";
import ContactDetailsDisplay from "../components/commonComponents/ContactDetailsDisplay";
import PriorityPassDetailsDisplay from "../components/commonComponents/PriorityPassDetailsDisplay";
import { giftRedeemData as GRD } from "../redux/giftCartRedux";
import { showError } from "../components/commonComponents/Swal";
import { confirmCart, redeemGift } from "../redux/apiCalls";
import Switch from "react-switch";
import useToggle from "../hooks/useToggle";
import IframePdfModal from "../components/commonComponents/IframePdfModal";

export default function GiftRedeemSummary() {
  const currencyCode = useSelector(selectedMarketCurrency);
  const selectedMarket = useSelector(currentMarket);
  const sendBroadcastMessage = useOutletContext()
  const adata = useSelector(arrivaldata);
  const giftRedeemData = useSelector(GRD);
  const ddata = useSelector(departuredata);
  const data = [adata, ddata];
  const location = useLocation();
  const passengerInfo = location.state;
  const { t } = useTranslation(["common", "giftredeemsummary", "giftredeemsuccess"]);
  const country_name_with_flag = [];
  const availableMarkets = useSelector(AM);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const marketLoungenameswithairport = getMarketLoungenamesWithAirport(selectedMarket?.airports);
  const dispatch = useDispatch();
  const selectedMarketId = useSelector(SM);

  const [isChecked, setIsChecked] = useState(false);
  const [isVisibleTermsAndConditionModal, setIsVisibleTermsAndConditionModal] = useToggle();
  let amount = 0;
  giftRedeemData.map(e => {
    amount = amount + e.value?.currentCartItem?.retail;
  });

  const populateConfirmationEmail = () => {
    return (giftRedeemData.length > 0 ? giftRedeemData[0]?.value?.data?.passengerInfo?.primaryContactDetails?.email : "");
  }

  const showWarningIfError = () => {
     if (!isChecked) {
      showError(t("error"), t('checkboxWarning'));
    }else if (isChecked){
      handleSubmit();
    }
  };


  const generateConfirmCartRequest = () => {
    let request = {};
    const cartArray = [];
    let giftCartItemId = 0;

    giftRedeemData.map((e) => {
      const passengersArray = [];
      giftCartItemId = e.value.data?.passengerInfo?.giftcartitemid
      e?.value?.data?.passengerInfo?.adults.forEach((a) => {
        passengersArray.push({
          passengertype: "ADULT",
          title: a?.title.value,
          firstname: a?.firstName,
          lastname: a?.lastName,
          email: a?.email,
          phone: e?.value?.data?.passengerInfo?.primaryContactDetails?.phone,
          dob: a?.dob ? dateFormatForDisplay(a?.dob, "yyyyMMdd") : "",
        });
      });

      e?.value?.data?.passengerInfo?.childs.forEach((a) => {
        passengersArray.push({
          passengertype: "CHILD",
          title: a?.title.value,
          firstname: a?.firstName,
          lastname: a?.lastName,
          email: a?.email,
          phone: e?.value?.data?.passengerInfo?.primaryContactDetails?.phone,
          dob: a?.dob ? dateFormatForDisplay(a?.dob, "yyyyMMdd") : "",
        });
      });

      e?.value?.data?.passengerInfo?.infant.forEach((a) => {
        passengersArray.push({
          passengertype: "INFANT",
          title: a?.title.value,
          firstname: a?.firstName,
          lastname: a?.lastName,
          email: a?.email,
          phone: e?.value?.data?.passengerInfo?.primaryContactDetails?.phone,
          dob: a?.dob ? dateFormatForDisplay(a?.dob, "yyyyMMdd") : "",
        });
      });

      let infantCount = parseInt(e?.value?.infantCount.value);

      cartArray.push({
        cartitemid: e?.value?.currentCartItem?.cartitemid,
        productid: e?.value?.currentCartItem?.productid,
        referencenumber: "",
        arrivalscheduleid: e?.value?.currentCartItem?.arrivalscheduleid,
        departurescheduleid: e?.value?.currentCartItem?.departurescheduleid,
        adulttickets: e?.value?.data?.passengerInfo?.adults?.length,
        childtickets: e?.value?.data?.passengerInfo?.childs?.length,
        infanttickets: infantCount, // e?.value?.data?.passengerInfo?.infant?.length,
        optional: {
          specialoccasion: e?.value?.data?.passengerInfo?.greetingDetail[0]?.occasion?.value,
          occasioncomment: e?.value?.data?.passengerInfo?.greetingDetail[0]?.occasionDetail,
          paddlename: e?.value?.data?.passengerInfo?.greetingDetail[0]?.name
        },
        passengers: passengersArray,
        primarycontact: {
          title:
            e?.value?.data?.passengerInfo?.primaryContactDetails?.title.value,
          firstname:
            e?.value?.data?.passengerInfo?.primaryContactDetails?.firstName,
          lastname:
            e?.value?.data?.passengerInfo?.primaryContactDetails?.lastName,
          email: e?.value?.data?.passengerInfo?.primaryContactDetails?.email,
          phone: e?.value?.data?.passengerInfo?.primaryContactDetails?.phone,
        },
        secondarycontact: {
          title:
            e?.value?.data?.passengerInfo?.secondaryContactDetails?.title.value,
          firstname:
            e?.value?.data?.passengerInfo?.secondaryContactDetails?.firstName,
          lastname:
            e?.value?.data?.passengerInfo?.secondaryContactDetails?.lastName,
          email: e?.value?.data?.passengerInfo?.secondaryContactDetails?.email,
          phone: e?.value?.data?.passengerInfo?.secondaryContactDetails?.phone,
        },
        amount: amount,
      });
    });

    request = {
      giftcartitemid: giftCartItemId,
      cart: cartArray,
      payment: {
        paymenttype: "INVOICE",
        invoice: {
          invoicedate: dateFormatForDisplay(new Date(), "yyyyMMdd"),
          currency: currencyCode,
          amount: amount,
        },
      }
    }
    return request;
  };

  const handleSubmit = async (event) => {
    // event.preventDefault();
    let request = {};
    giftRedeemData.map((item) => {
      request = {
        "cartitemid": item?.value?.data?.passengerInfo?.giftcartitemid,
        "adulttickets": item.value.adultCount.value,
        "childtickets": item.value.childCount.value,
        "infanttickets": item.value.infantCount.value
      };
    });
    try {
      setLoading(true);
      const response = await redeemGift(request);
      if (response?.status === 0) {
        setLoading(true);
        let confirmCartRequest = generateConfirmCartRequest();
        const res = await confirmCart(dispatch, confirmCartRequest);
        const successInfo = {
          currencyCode: currencyCode,
          amount: amount?.toFixed(2),
          confirmationEmail: populateConfirmationEmail(),
          userConfirmationEmail: "",
          successResponse: response?.data,
          cartData: giftRedeemData,
          confirmationnumber: res?.data?.response[0]?.confirmationnumber,
          userDeafultEmail: "",
        };
        // closeSession();
        clearSession(sendBroadcastMessage);
        navigate("/gift-redeem-success", { state: successInfo });
      }
      else {
        setLoading(false);
        showError(t("error"), response.statusMessage);
      }

    } catch (e) {
      HandleAPIError(e);
      return null;
    } finally {
      setLoading(false);
    }
  };

  availableMarkets?.data?.markets?.map((m) => {
    country_name_with_flag.push({
      value: m?.marketid,
      label: (
        <div style={{ width: "210px" }}>
          <img
            src={FLAG_URL + m?.image}
            width="30"
            className="d-inline-block align-left mx-1"
            alt={m?.marketname}
          />{" "}
          {m?.marketname}
        </div>
      ),
    });
  });

  const checkIfUserLoungePartner = checkLoggedInUserIsLoungePartner() ? "lounge-partner"  : "regular"

  const getDirectionTranslationKey = (index) => {
    let translationKey = "departure";
    if (index === 0) {
      translationKey = "arrival";
    } else {
      translationKey = "departure";
    }

    return translationKey;
  }

  const getDirectionTranslationKey2 = (productid) => {
    let translationKey = "departure";
    if (productid === PRODUCTID_ARRIVALBUNDLE) {
      translationKey = "arrivalDeparture";
    } else if (productid === PRODUCTID_ARRIVAL) {
      translationKey = "arrival";
    } else { // DEPARTURE, PRIORITY PASS, DIGICEL EXECUTIVE CARD
      translationKey = "departure";
    }

    return translationKey;
  }

  return (
    <>
      <IframePdfModal
        visible={isVisibleTermsAndConditionModal}
        cancelModal={() => setIsVisibleTermsAndConditionModal(false)}
        onclick={setIsVisibleTermsAndConditionModal}
        title={t("termsConditions")}
        filename={getTermsandConditionsPdfFilename(checkIfUserLoungePartner, selectedMarketId)}
      />
      <section>
        <Container className="mt-4">
          <Loader loading={loading} />
          <h3 className="my-3 d-flex justify-content-center heading heading">
            <span className="heading">{t('giftredeemsummary:giftRedeemSummary')}</span>
          </h3>
          <div className="px-1 d-flex justify-content-end">
            <Link to="/gift-redeem-user-info"
              state={{
                "adultCount": giftRedeemData[0]?.value?.adultCount,
                "childCount": giftRedeemData[0]?.value?.childCount,
                "infantCount": giftRedeemData[0]?.value?.infantCount,
                "infantCountNumber": giftRedeemData[0]?.value?.infantCount.value,
                "productid": giftRedeemData[0]?.value?.currentCartItem?.productid,
                "arrivalscheduleid": giftRedeemData[0]?.value?.currentCartItem?.arrivalscheduleid,
                "departurescheduleid": giftRedeemData[0]?.value?.currentCartItem?.departurescheduleid,
                "cartitemid": giftRedeemData[0]?.key,
                "currentPassengerInfo": giftRedeemData[0]?.value?.data?.passengerInfo,
                "giftBookingInfo": giftRedeemData[0]?.value?.data?.passengerInfo?.giftBookingInfo,
                "giftcartitemid": giftRedeemData[0]?.value?.data?.passengerInfo?.giftcartitemid,
                "retail": giftRedeemData[0]?.value?.currentCartItem?.retail
              }}
              className=" mx-1 text-decoration-none">
              <CommonButton classname="back" title={t('back')} />
            </Link>
            <ExitButton />  
          </div>
        </Container>
      </section>
      <section>
        <Container className="my-3">
          {
            giftRedeemData?.map((product, index) =>

              <Card key={index} className="">

                <Card.Body>
                  {
                    product?.value?.currentCartItem?.productid === PRODUCTID_ARRIVALBUNDLE
                    &&
                    [product?.value?.bookingDetail[0], product?.value?.bookingDetail[1]]?.map((item, index) =>
                      item !== "" &&
                      <div key={index}>
                        <Row className="d-flex justify-content-center align-content-center mb-1">
                          <Col md={12} sm={12}>
                            <h4 className="my-2">
                              <b>
                                {t('summary', { product: t(getDirectionTranslationKey(index)) })}
                              </b>
                            </h4>
                            <h5 className="mb-1">
                              <b>{t('giftredeemsummary:serviceCategory')}:</b> {marketLoungenameswithairport.find(a => a?.value === item?.lounge)?.label}
                            </h5>
                          </Col>
                        </Row>
                        <Row className="d-flex justify-content-center align-content-center mb-1">
                          <Col md={5} sm={12}>
                            <h5 className="mb-1">
                              <b>{t('flightDateTime')}:</b>
                              {/* {dateFormatForDisplay(item?.flightDate, "MM/dd/yyyy")} */}
                              {(dateFormatForDisplay(item?.flightDate, "MM/dd/yyyy"))}
                              {" "}
                              {item?.flightTime_hour}
                            </h5>
                          </Col>
                          <Col md={4} sm={12}>
                            <h5 className="mb-1">
                              <b>{t('airline')}:</b>  {item?.airlineName?.label}
                            </h5>
                          </Col>
                          <Col md={3} sm={12}>
                            <h5 className="mb-1">
                              <b>{t('flightNumber')}:</b>  {item?.flightNumber?.label}
                            </h5>
                          </Col>
                        </Row>
                        {(index === 1) &&
                          <>
                            <Row className="d-flex justify-content-center align-content-center mb-1">
                              <Col md={12} sm={12}>
                                <h5 className="mb-1">
                                  <b>{t("serviceFor")}: </b>
                                  <FontAwesomeIcon
                                    icon={faUser}
                                    className="mx-1 icon-color"
                                    size="sm"
                                  />{" "}
                                  {t("adults")}: {product?.value?.adultCount.value}
                                  <FontAwesomeIcon
                                    icon={faChild}
                                    size="sm"
                                    className="mx-1  icon-color"
                                  />{" "}
                                  {t("children")}:  {product?.value?.childCount.value}
                                  <FontAwesomeIcon
                                    icon={faPersonBreastfeeding}
                                    size="sm"
                                    className="mx-1  icon-color"
                                  />{" "}
                                  {t("infants")}: {product?.value?.infantCount.value}
                                </h5>
                              </Col>
                            </Row>
                            {/* <Row className="d-flex justify-content-center align-content-center mb-1">
                              <Col md={12} sm={12}>
                                <h5 className="mb-1">
                                  <b>{t('giftredeemsummary:totalAmount')}:</b> {currencyCode} {" "} {product?.value?.currentCartItem?.retail?.toFixed(2)}
                                </h5>
                              </Col>
                            </Row> */}
                          </>
                        }
                      </div>)
                  }
                  {
                    product?.value?.currentCartItem?.productid !== PRODUCTID_ARRIVALBUNDLE &&
                    < div key={index}>
                      <Row className="d-flex justify-content-center align-content-center mb-1">
                        <Col md={12} sm={12}>
                          <h4 className="my-2">
                            <b>
                              {t('summary', { product: t(getDirectionTranslationKey2(product?.value?.currentCartItem?.productid === PRODUCTID_ARRIVAL ? PRODUCTID_ARRIVAL : PRODUCTID_DEPARTURE)) })}
                            </b>
                          </h4>
                          <h5 className="mb-1">
                            <b>{t('giftredeemsummary:serviceCategory')}: </b> {marketLoungenameswithairport.find(a => a?.value === product?.value?.bookingDetail?.lounge)?.label}
                          </h5>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-center align-content-center mb-1">
                        <Col md={5} sm={12}>
                          <h5 className="mb-1">
                            <b>{t('flightDateTime')}:</b>
                            {(dateFormatForDisplay(product?.value?.bookingDetail?.flightDate, "MM/dd/yyyy"))}
                            {" "}
                            {product?.value?.bookingDetail?.flightTime_hour}
                          </h5>
                        </Col>
                        <Col md={4} sm={12}>
                          <h5 className="mb-1">
                            <b>{t('airline')}:</b>  {product?.value?.bookingDetail?.airlineName?.label}
                          </h5>
                        </Col>
                        <Col md={3} sm={12}>
                          <h5 className="mb-1">
                            <b>{t('flightNumber')}:</b>  {product?.value?.bookingDetail?.flightNumber?.label}
                          </h5>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-center align-content-center mb-1">
                        <Col md={12} sm={12}>
                          <h5 className="mb-1">
                            <b>{t("serviceFor")}: </b>
                            <FontAwesomeIcon
                              icon={faUser}
                              className="mx-1 icon-color"
                              size="sm"
                            />{" "}
                            {t("adults")}: {product?.value?.adultCount.value}
                            <FontAwesomeIcon
                              icon={faChild}
                              size="sm"
                              className="mx-1  icon-color"
                            />{" "}
                            {t("children")}:  {product?.value?.childCount.value}
                            <FontAwesomeIcon
                              icon={faPersonBreastfeeding}
                              size="sm"
                              className="mx-1  icon-color"
                            />{" "}
                            {t("infants")}: {product?.value?.infantCount.value}
                          </h5>
                        </Col>
                      </Row>
                      {/* <Row className="d-flex justify-content-center align-content-center mb-1">
                        <Col md={12} sm={12}>
                          <h5 className="mb-1">
                            <b>{t('giftredeemsummary:totalAmount')}:</b> {currencyCode} {product?.value?.currentCartItem?.retail.toFixed(2)}
                          </h5>
                        </Col>
                      </Row> */}
                    </div>
                  }
                  <Row className="d-flex justify-content-center align-content-center mb-2">
                    <Col md={12} sm={12}>
                      <h5 className="my-2">
                        <b>{t('giftredeemsummary:otherInformation')}</b>
                      </h5>
                      <h6 className="mb-1">{t('giftredeemsummary:infantSeatsComplimentary')}</h6>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-start align-content-center mb-2">
                    {product?.value?.data?.passengerInfo?.adults?.map((item, index) =>
                      <div key={index}>
                        <Col md={"auto"} sm={12} key={index}>
                          <h5>
                            <FontAwesomeIcon
                              icon={faUser}
                              className="mx-1 icon-color"
                              size="sm"
                            />{" "}
                            <b>{t('adult')}: </b>{item?.title?.label}{" "}{item?.firstName}{" "}{item?.lastName}
                          </h5>
                        </Col>
                      </div>
                    )}
                    {product?.value?.data?.passengerInfo?.childs?.map((item, index) =>
                      <div key={index}>
                        <Col md={"auto"} sm={12} key={index}>
                          <h5>
                            <FontAwesomeIcon
                              icon={faUser}
                              className="mx-1 icon-color"
                              size="sm"
                            />{" "}
                            <b>{t('child')}: </b>{item?.title?.label}{" "}{item?.firstName}{" "}{item?.lastName}
                          </h5>
                        </Col>
                      </div>
                    )}
                    {product?.value?.data?.passengerInfo?.infant?.map((item, index) =>
                      <div key={index}>
                        <Col md={"auto"} sm={12} key={index}>
                          <h5>
                            <FontAwesomeIcon
                              icon={faUser}
                              className="mx-1 icon-color"
                              size="sm"
                            />{" "}
                            <b>{t('infant')}: </b>{item?.title?.label}{" "}{item?.firstName}{" "}{item?.lastName}
                          </h5>
                        </Col>
                      </div>

                    )}
                  </Row>

                  <ContactDetailsDisplay contactType="Primary" contactDetails={product?.value?.data?.passengerInfo?.primaryContactDetails} />
                  <ContactDetailsDisplay contactType="Secondary" contactDetails={product?.value?.data?.passengerInfo?.secondaryContactDetails} />

                  {
                    (product?.value?.data?.passengerInfo?.productid === PRODUCTID_ARRIVAL || product?.value?.data?.passengerInfo?.productid === PRODUCTID_ARRIVALBUNDLE) && product?.value?.data?.passengerInfo?.greetingDetail?.map((item, index) =>
                      <div key={index}>
                        <Row className="d-flex justify-content-center align-content-center mb-2">
                          <Col md={12} sm={12}>
                            <h5 className="mb-1">
                              <b>{t('giftredeemsummary:otherInformation')}</b>
                            </h5>
                          </Col>
                        </Row>
                        <Row className="d-flex justify-content-start align-content-center mb-1">
                          <Col md={6} sm={12}>
                            <h5>
                              <FontAwesomeIcon
                                icon={faUser}
                                className="mx-1 icon-color"
                                size="sm"
                              />{" "}
                              <b>{t('bookingsummary:WelcomeSign')}: </b>{item?.name}
                              {/* <b>{t('giftredeemsummary:WelcomeSign')}: </b>{passengerInfo?.primaryContactDetails?.firstName}{" "}{passengerInfo?.primaryContactDetails?.lastName} */}
                            </h5>
                          </Col>
                          {item?.occasion?.value && <Col md={6} sm={12}>
                            <h5>
                              <FontAwesomeIcon
                                icon={faChampagneGlasses}
                                className="mx-1 icon-color"
                                size="sm"
                              />{" "}
                              <b>{t('occasion')}: </b>{item?.occasion?.value}
                            </h5>
                          </Col>}
                          {item?.occasionDetail && <Col md={12} sm={12}>
                            <h5>
                              <FontAwesomeIcon
                                icon={faCircleInfo}
                                size="sm"
                                className="mx-1 icon-color"
                              />
                              <b>{t('occasionDetail')}: </b>{item?.occasionDetail}
                            </h5>
                          </Col>
                          }
                        </Row>
                      </div>
                    )}

                  {product?.value?.data?.passengerInfo?.prioritypass ? <PriorityPassDetailsDisplay priorityPassDetails={product?.value?.data?.passengerInfo?.prioritypass} /> : ""}
                </Card.Body>
              </Card>

            )}
        </Container>
      </section >
      <section>
        <Container className="mb-4">
          <Card>
            <Card.Body>
            <Row>
                    <Col md="12" >
                      <Form>
                        <div className="my-bg-info">
                          <Form.Label className="d-flex">
                          <Switch
                              checked={isChecked}
                              onChange={() => setIsChecked((prev) => !prev)}
                              className="react-switch me-2"
                              height={25}
                            />
                            <Link to="#" className=" text-decoration-none" onClick={setIsVisibleTermsAndConditionModal}> {t('iAgree')}</Link>
                          </Form.Label>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                   <Row className="">
                    <Col className="d-flex justify-content-end pt-2">
                      <CommonButton
                        classname=" gradient-btn"
                        title={t('confirm')}
                        // onclick={handleSubmit}
                        onclick={() => showWarningIfError()}
                      />
                    </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
}
