import { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import Select from "react-select";
import CommonButton from "../components/commonComponents/CommonButton";
import InputGroup from "react-bootstrap/InputGroup";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../components/commonComponents/Loader";
import Password from "../components/commonComponents/Password";
import { processMemberCard } from "../redux/apiCalls";
import { HandleAPIError, divRef, getMemberCardsArray, numericValidation, renderErrorMessages, scrollToDiv, translateConstantText } from "../components/commonComponents/commonFunctions";
import { showError } from "../components/commonComponents/Swal";
import md5 from "md5";
import { MEMBERCARD_CARD_NUMBER_LABEL, MEMBERCARD_CARD_TYPE_LABEL, MEMBERCARD_NUMBER_MAXLENGTH, MEMBERCARD_PIN_MAXLENGTH } from "../config/commonConstants";
import { useFormik } from "formik";
import * as Yup from "yup";
import { memberCards as MC } from "../redux/memberCardRedux";
import ErrorMessages from "../components/commonComponents/ErrorMessages";

function MemberCardForm() {
  const { t } = useTranslation(["common", "memberandcardinformation"]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [MemberCardTypeArr, setMemberCardTypeArr] = useState([]);
  const memberCards = useSelector(MC);
  const validationSchema = Yup.object().shape({
    cardType: Yup.object().required(t("memberandcardinformation:errors.cardType.required", { membershipType: translateConstantText("MEMBERCARD_CARD_TYPE_LABEL") })),
    cardNumber: Yup.string()
      .required(t("memberandcardinformation:errors.cardNumber.required", { membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") }))
      .matches(/^[0-9]+$/, t("memberandcardinformation:errors.cardNumber.match", { membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") }))
      .length(16, t("memberandcardinformation:errors.cardNumber.length", { membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") })),
    pin: Yup.string()
      .required(t("memberandcardinformation:errors.pin.required"))
      .matches(/^[0-9]+$/, t("memberandcardinformation:errors.pin.match"))
      .min(MEMBERCARD_PIN_MAXLENGTH).max(MEMBERCARD_PIN_MAXLENGTH),
  });
  const formik = useFormik({
    initialValues: {
      cardType: "",
      cardNumber: "",
      pin: ""
    },
    validationSchema,
    onSubmit: () => { handleSubmit(formik.values) },
  });

  const handleSubmit = async (formik) => {
    const memberCardData = {
      cardType: formik.cardType,
      cardNumber: formik.cardNumber,
      pin: formik.pin,
      showLabel: "Y"
    }
    const data = {
      "action": "VALIDATE",
      "memberid": formik.cardType.value + formik.cardNumber,
      "pin": md5(formik.pin),
      "allowexpired": "Y"
    }
    const memberCardProfileData = {
      cardNumber: formik.cardNumber,
      cardTypeValue: formik.cardType.value,
      cardType: formik.cardType.label,
      showvalue: formik.cardType.showvalue,
      pin: formik.pin,
    }
    try {
      setLoading(true);
      const response = await processMemberCard(data);
      if (response?.status === 0) {
        if (response?.data?.reset === "Y") {
          const sendToMemberProfile = true;
          navigate("/member-card/change-pin", { state: { sendToMemberProfile, data, memberCardData, memberCardProfileData } });
        } else {
          const memberProfileData = response?.data;
          navigate("/member-card/member-profile", { state: { memberProfileData, memberCardProfileData } });
        }
      }
      else {
        showError(t("error"), response?.statusMessage);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  }

  const setMemberCardArray = () => {
    const memberCardsArray = getMemberCardsArray(memberCards);
    setMemberCardTypeArr(memberCardsArray);
  }

  useEffect(() => {
    setMemberCardArray();
  }, [memberCards]);

  return (
    <>
      <section>
        <Container className="my-4" ref={divRef}>
          <Loader loading={loading} message={t('memberandcardinformation:loaderMessage')} />

          <h3 className="my-3 d-flex justify-content-center heading">
            <span className="heading">{t('memberandcardinformation:memberProfile')}</span>
          </h3>
          <Row className="my-3 d-flex justify-content-around align-content-left">
            <Col md={6}></Col>
            <Col md={6} className="d-flex justify-content-end">
              <ExitButton />
            </Col>
          </Row>
          <Card className="my-3"  >
            <Card.Body>
              <Row className="d-flex justify-content-start">
                <Col md={5}>
                  <h6 className="field">{t('fillAllFields')}</h6>
                </Col>
              </Row>
              <ErrorMessages formik={formik} />
              <Form onSubmit={(e) => scrollToDiv(e, formik)}>
                <Row className="d-flex justify-content-center">
                  <Col lg={3} md={4} sm={12}>
                    <Form.Group className="my-2">
                      <Form.Label className="d-flex">
                        {t('memberandcardinformation:cardType', { membershipType: translateConstantText("MEMBERCARD_CARD_TYPE_LABEL") })}
                        <span className="field">*</span>
                      </Form.Label>
                      <Select
                        options={MemberCardTypeArr}
                        name="cardType"
                        value={formik.values.cardType}
                        onChange={(d) => formik.setFieldValue('cardType', d)}
                        onBlur={(d) => formik.handleBlur}
                        className={formik.touched.cardType && formik.errors.cardType ? "error rounded-2" : "my-0 mb-3"}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                  <Col
                    lg={3} md={4} sm={12}
                    className="mb-3"
                  >
                    <Form.Label className="d-flex" htmlFor="CardNumber">
                      {t('memberandcardinformation:cardNumber', { membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") })}<span className="field">*</span>
                    </Form.Label>
                    <InputGroup className="d-flex">
                      <Form.Control
                        type="text"
                        id="cardNumber"
                        name="cardNumber"
                        placeholder={t('memberandcardinformation:cardNumber', { membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") })}
                        value={formik.values.cardNumber}
                        maxLength={MEMBERCARD_NUMBER_MAXLENGTH}
                        onChange={(e) => formik.setFieldValue("cardNumber", numericValidation(e.target.value))}
                        onBlur={formik.handleBlur}
                        className={formik.touched.cardNumber && formik.errors.cardNumber ? "error" : ""}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                  <Col
                    lg={3} md={4} sm={12}
                    className="mb-3"
                  >
                    <Form.Label className="d-flex" htmlFor="Pin">
                      {t('memberandcardinformation:pin')}<span className="field">*</span>
                    </Form.Label>
                    <InputGroup className="d-flex">
                      <Password
                        placeholder={t('memberandcardinformation:pin')}
                        // passwordChange={oldPasswordHandler}
                        id="Pin"
                        name="pin"
                        value={formik.values.pin}
                        minLength={MEMBERCARD_PIN_MAXLENGTH}
                        maxLength={MEMBERCARD_PIN_MAXLENGTH}
                        onChange={(e) => formik.setFieldValue("pin", numericValidation(e.target.value))}
                        autoComplete="new-password"
                        onBlur={formik.handleBlur}
                        className={formik.touched.pin && formik.errors.pin ? "error rounded-2" : "rounded-2"}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="d-flex justify-content-center">
                    <CommonButton
                      type="submit"
                      classname="gradient-btn "
                      size="md"
                      title={t('memberandcardinformation:getProfile')}
                    />
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
}

export default MemberCardForm;
