import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  paxCountBookingDetails: {},
  error: false,
}


const increasePaxCountSlice = createSlice({
  name: "paxCountBookingDetails",
  initialState: initialState,
  reducers: {
    paxCountBookingDetailsFetchSuccess: (state, action) => {
      state.paxCountBookingDetails = action.payload;
    },
    paxCountBookingDetailsFetchFailure: (state) => {
      state.error = true;
    },
    paxCountClear: state => {
      return initialState;
    },
    setCardType: (state, action) => {
      state.cardType = action.payload
    }
  },
});

export const { paxCountBookingDetailsFetchSuccess, paxCountBookingDetailsFetchFailure, paxCountClear, setCardType } = increasePaxCountSlice.actions;
export const paxCountBookingDetails = (state) => state.increasePaxCountRedux?.paxCountBookingDetails;
export const cardType = (state) => state.increasePaxCountRedux?.cardType;
export default increasePaxCountSlice.reducer;