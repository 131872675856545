import { Card, Col, Container, Row } from "react-bootstrap";
import CommonButton from "../components/commonComponents/CommonButton";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Promotions from "../components/commonComponents/Promotions";
import HtmlToReactConverter from "../components/commonComponents/HtmlToReactConverter";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import { isSubDistributorProfile } from "../components/commonComponents/commonFunctions";
import { cartData  as CD } from "../redux/cartRedux";
import { useSelector } from "react-redux";

function SuccessCommon() {
  const { t } = useTranslation(['common']);
  const location = useLocation();
  const cartData = useSelector(CD)
  let successInfo;
  if (location.state.form === "partner-form" || location.state.form === "standbybookingform"){
    successInfo = location?.state?.successinfo;
  } else {
    successInfo = location?.state;
  }
  const partnerInfo = location?.state?.response?.data
  let jePromotion = successInfo?.jePromotion ? successInfo?.jePromotion : true;
  let jemPromotion = successInfo?.jemPromotion ? successInfo?.jemPromotion : true;
  let affiliateLink = partnerInfo?.affiliatelink;

  const redirectURL = () => {
    let link = isSubDistributorProfile() ? "/distributor-booking-user" : "/";
    if(location.state.form === 'standbybookingform' && cartData.length > 0){
      link = "/bookingSummary"
    }
    return link;
  }
  
  const showPartnerFormInfo = () =>{
    return (
      <>
        <h5 className="lh-base">
          <HtmlToReactConverter value={successInfo?.successMessage} />
          <b>{t("partnerCode")}</b> : {partnerInfo?.partnercode} <br />
          <b>{t("affiliateid")}</b> : {partnerInfo?.affiliateid} <br />
          <b>{t("affiliateIdUrl")}</b>: {partnerInfo?.affiliatelink}
          <CopyToClipboard text={affiliateLink}>
            <FontAwesomeIcon
              icon={faCopy}
              className="mx-3 pointer"
              size="lg"
              title={t("copyLink")}
            />
          </CopyToClipboard>
        </h5>
      </>
    );
  }

  useEffect(() => {
  }, []);

  return (
    <>
      <section>
        <Container className="my-4">
          <h3 className="my-3 d-flex justify-content-center text-center">
            <span className="heading"><HtmlToReactConverter value={successInfo?.messageHeading} /></span>
          </h3>
          <Row>
            <Col md={12} className="mx-auto xl">
              <Card className="text-center mt-2">
                <Card.Body className="text-center">
                  <Row>
                    <Col md={12} className="d-flex justify-content-center">
                      <h4 className="lh-base">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          size="3x"
                          className="mb-3"
                          color="green"
                        />
                      </h4>
                    </Col>
                  </Row>
                  {successInfo?.commonSuccessMessage ?
                    <>
                      <Row>
                        <Col md={12} className="d-flex justify-content-center">
                          {location.state.form === "partner-form" ? showPartnerFormInfo() :
                            (<h5 className="lh-base">
                              <HtmlToReactConverter value={successInfo?.successMessage} />
                            </h5>)}
                        </Col>
                      </Row>
                      <Card className="mt-3 mb-3 my-bg-info">
                        <Row>
                          <Col md={12} className="d-flex justify-content-center">
                            <h5 className="mb-1 lh-base">
                              <HtmlToReactConverter value={successInfo?.commonSuccessMessage} />
                            </h5>
                          </Col>
                        </Row>
                      </Card>
                    </>
                    : 
                    <Card className="mb-3 my-bg-info">
                      <Row>
                        <Col md={12} className="d-flex justify-content-center">
                          {location.state.form === "partner-form" ? showPartnerFormInfo() :
                            (<h5 className="lh-base">
                              <HtmlToReactConverter value={successInfo?.successMessage} />
                            </h5>)}
                        </Col>
                      </Row>
                    </Card>
                    }
                  <Promotions
                    jePromotion={jePromotion}
                    jemPromotion={jemPromotion}
                  />
                  <Row>
                    <Col className="mt-3 d-flex justify-content-center">
                      <Link to={redirectURL()} className="me-1 text-decoration-none">
                        <CommonButton
                          type="button"
                          size="lg"
                          classname="gradient-btn mx-2"
                          title={t('return')}
                        />
                      </Link>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
export default SuccessCommon;
